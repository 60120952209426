import { Box } from '@remote-com/norma';

import { SkeletonBox } from '@/src/components/SkeletonBox';
import ContentContainer from '@/src/components/Ui/ContentContainer';
import { type PageHeaderProps, PageHeader } from '@/src/components/Ui/Layout/PageHeader';

export function PaywallPageSkeleton({
  pageHeader,
  isLoading,
  error,
}: {
  pageHeader: PageHeaderProps;
  isLoading: boolean;
  error?: unknown;
}) {
  return (
    <ContentContainer data-dd-privacy="allow">
      <PageHeader
        siteTitle={pageHeader.siteTitle || null}
        title={pageHeader.title}
        subtitle={pageHeader.subtitle}
        pill={pageHeader.pill}
      />
      <SkeletonBox isLoading={isLoading} error={error}>
        <Box />
      </SkeletonBox>
    </ContentContainer>
  );
}
