export const ARE_BANK_ROUTING_CODES = [
  { label: 'Abu Dhabi Commercial Bank - 600310101', value: '600310101' },
  { label: 'Al Ahli Bank Of Kuwait K.S.C. - 200420101', value: '200420101' },
  { label: 'Rafidain Bank - 400510101', value: '400510101' },
  { label: 'Arab African International Bank - 900720101', value: '900720101' },
  { label: 'Al Masraf - 100810101', value: '100810101' },
  { label: 'Arab Bank - 000910101', value: '000910101' },
  { label: 'Bank Melli Iran - 901020101', value: '901020101' },
  { label: 'Bank of Baroda - 801120101', value: '801120101' },
  { label: 'Bank of Sharjah - 401230101', value: '401230101' },
  { label: 'Bank Saderat Iran - 901320124', value: '901320124' },
  { label: 'Banque Banorient France - 001420151', value: '001420151' },
  { label: 'Banque Misr - 001510102', value: '001510102' },
  { label: 'Credit Agricole Corporate and Investment Bank - 301620101', value: '301620101' },
  { label: 'Al Khaliji France S.A. - 201720101', value: '201720101' },
  { label: 'BNP Paribas - 401810101', value: '401810101' },
  { label: 'Barclays Bank - 401920110', value: '401920110' },
  { label: 'HSBC Bank Middle East - 302020120', value: '302020120' },
  { label: 'Citibank NA - 102120101', value: '102120101' },
  { label: 'Commercial Bank International PSC - 002220101', value: '002220101' },
  { label: 'Commercial Bank of Dubai - 102320150', value: '102320150' },
  { label: 'Dubai Islamic Bank - 802420101', value: '802420101' },
  { label: 'El Nilein Bank - 002510101', value: '002510101' },
  { label: 'EmiratesNBD Bank PJSC - 202620103', value: '202620103' },
  { label: 'First Abu Dhabi Bank - Erstwhile FGB - 102710102', value: '102710102' },
  { label: 'Habib Bank Limited - 102820111', value: '102820111' },
  { label: 'Habib Bank AG Zurich - 302920101', value: '302920101' },
  { label: 'Investbank PSC - 503030102', value: '503030102' },
  { label: 'Janata Bank - 103110110', value: '103110110' },
  { label: 'Mashreqbank - 203320101', value: '203320101' },
  { label: 'Emirates Islamic Bank PJSC - 703420114', value: '703420114' },
  { label: 'First Abu Dhabi Bank - 803510106', value: '803510106' },
  { label: 'National Bank Of Bahrain - 203610101', value: '203610101' },
  { label: 'National Bank Of Fujairah - 703820101', value: '703820101' },
  { label: 'National Bank of Oman - 903910101', value: '903910101' },
  { label: 'RAK Bank - 104060106', value: '104060106' },
  { label: 'Sharjah Islamic Bank - 404130101', value: '404130101' },
  { label: 'National Bank Of Umm Al Qaiwain - 104251001', value: '104251001' },
  { label: 'Industrial and Commercial Bank of China - 804310101', value: '804310101' },
  { label: 'Standard Chartered Bank - 504420120', value: '504420120' },
  { label: 'United Arab Bank - 904630101', value: '904630101' },
  { label: 'United Bank Ltd. - 604720106', value: '604720106' },
  { label: 'Emirates Investment Bank - 004820101', value: '004820101' },
  { label: 'Deutsche Bank - 204910101', value: '204910101' },
  { label: 'Abu Dhabi Islamic Bank - 405010101', value: '405010101' },
  { label: 'Al Hilal Bank - 105310101', value: '105310101' },
  { label: 'Doha Bank - 705420101', value: '705420101' },
  { label: 'The Saudi National Bank - 605520101', value: '605520101' },
  { label: 'National Bank Of Kuwait - 505620101', value: '505620101' },
  { label: 'Ajman Bank - 805740101', value: '805740101' },
  { label: 'Wio Bank PJSC - 808610001', value: '808610001' },
  { label: 'KEB HANA Bank - 408910101', value: '408910101' },
  { label: 'Bank Of China - 309010188', value: '309010188' },
  { label: 'MCB Bank Limited - 209120101', value: '209120101' },
  { label: 'Gulf International Bank B.S.C. - 509210001', value: '509210001' },
  { label: 'INTESA SANPAOLO - 309314334', value: '309314334' },
  { label: 'BOK International Bank - 209410101', value: '209410101' },
  { label: 'ZAND BANK - 809610000', value: '809610000' },
  { label: 'Al Maryah Community Bank - 009710001', value: '009710001' },
  { label: 'Agricultural Bank of China - 709820785', value: '709820785' },
  { label: 'Bank Alfalah Limited - 009920501', value: '009920501' },
  { label: 'Dubai First - 610010101', value: '610010101' },
  { label: 'International Development Bank - 313020001', value: '313020001' },
  { label: 'Ruya Community Islamic Bank LLC - 413240101', value: '413240101' },
  { label: 'BUNA (AMF) - 145110101', value: '145110101' },
  { label: 'DFM PG SETTLEMENT A/C - 175210101', value: '175210101' },
  { label: 'Fee related to GRC Insurance Industry - 378910101', value: '378910101' },
  { label: 'ABU DHABI SECURITIES EXCHANGE - 579010101', value: '579010101' },
  { label: 'EMIRATES DEVELOPMENT BANK - 680910101', value: '680910101' },
  { label: 'Abu Dhabi Pension Fund - 485610101', value: '485610101' },
  { label: 'Federal Tax Authority - 386810000', value: '386810000' },
  { label: 'Federal Tax Authority Unregistered Tax Payers - 387310101', value: '387310101' },
  { label: 'INSURANCE SECTOR - FEES AND CHARGES COLLECTIONS - 089210101', value: '089210101' },
  { label: 'Euroclear Bank SA/NV - 892010101', value: '892010101' },
  { label: 'AP- Intermediary account Mbill - 792110101', value: '792110101' },
  { label: 'BCCI ABU DHABI - 193010101', value: '193010101' },
  { label: 'Unclaimed Funds Management Account - 798710000', value: '798710000' },
  { label: 'UAE CBDC Clearing and Settlement - 899110101', value: '899110101' },
  { label: 'Payments and Refunds for CB services - 599510001', value: '599510001' },
];
