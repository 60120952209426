export const marketingCookies = {
  impact_clickid: 'remote_clickid',
  impact_irclickid: 'remote_irclickid',
  impact_irpid: 'remote_utm_mediaid',
  impact_irmpname: 'remote_utm_mediapartner',
  impact_sharedid: 'remote_sharedid',
  impact_subid1: 'remote_subid1',
  remote_utm_campaign: 'remote_utm_campaign',
  remote_utm_content: 'remote_utm_content',
  remote_utm_source: 'remote_utm_source',
  remote_utm_medium: 'remote_utm_medium',
  remote_utm_term: 'remote_utm_term',
};
