import Script from 'next/script';

import { staticAssetUrl } from '@/src/helpers/general';

import { useContentSecurityPolicyStore } from './ContentSecurityPolicy';

/**
 * This component handles inline scripts that were originally rendered in _app.js.
 * We moved them to a separate component so we can access nonce value.
 */
export function InlineScripts() {
  const { nonce } = useContentSecurityPolicyStore((state) => state);

  if (!nonce) {
    return null;
  }

  return (
    <>
      <Script
        nonce={nonce}
        id="release-version"
        dangerouslySetInnerHTML={{
          __html: `window.__RELEASE_VERSION = "${process.env.RELEASE}"`,
        }}
      />
      {/* The following snippet is required to prevent
          https://remote-com.slack.com/archives/C013NGS9G7K/p1639761780068700 */}
      <Script
        nonce={nonce}
        id="prevent-submit"
        src={staticAssetUrl(`/scripts/prevent-submit.js`)}
      />
    </>
  );
}
