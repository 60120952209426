import { accountsSwitchingEvents, trackEvent } from '@remote-com/analytics';
import { Text, DropdownMenu, Box } from '@remote-com/norma';
import { IconV2OutlineArrowCornerCw } from '@remote-com/norma/icons/IconV2OutlineArrowCornerCw';
import { IconV2OutlineGift } from '@remote-com/norma/icons/IconV2OutlineGift';
import { IconV2OutlinePlus } from '@remote-com/norma/icons/IconV2OutlinePlus';
import { IconV2OutlinePromotion } from '@remote-com/norma/icons/IconV2OutlinePromotion';
import { IconV2OutlineSettings } from '@remote-com/norma/icons/IconV2OutlineSettings';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ButtonInline } from '@/src/components/Button';
import { StyledSeparator } from '@/src/components/Collapsible/Collapsible.styled';
import { ModalActionable } from '@/src/components/Modal/ModalActionable';
import { useUserContext } from '@/src/components/UserProvider';
import type { UserContextUser } from '@/src/components/UserProvider/context';
import {
  ACCOUNT_SETTINGS_ROUTE,
  ADMIN_ACCOUNT_SETTINGS_ROUTE,
  FEEDBACK_ROUTE,
} from '@/src/constants/routes';
import { getPreferredName } from '@/src/domains/account/helpers';
import { useUserLinkedAccounts } from '@/src/domains/account/hooks/useUserLinkedAccounts';
import { useUserProfiles } from '@/src/domains/account/hooks/useUserProfiles';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { AccountMenuItem } from '@/src/domains/navigation/account-menu/AccountMenuItem';
import { AccountMenuTrigger } from '@/src/domains/navigation/account-menu/AccountMenuTrigger';
import { useProfilesMenuTooltip } from '@/src/domains/navigation/profiles-menu/hooks/useProfilesMenuTooltip';
import { ProfilesMenu } from '@/src/domains/navigation/profiles-menu/ProfilesMenu';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import {
  isAdmin,
  isEmployee,
  isUserOnboarded,
  userCan,
} from '@/src/domains/registration/auth/helpers';
import { ProfileAddModal } from '@/src/domains/registration/auth/ProfileAddModal';
import { useModalContext } from '@/src/hooks/useModalContext';
import { useSignOut } from '@/src/hooks/useSignOut';

const StyledAccountMenu = styled(DropdownMenu)`
  /* Sidebar menu on mobile */
  margin: 0 0 ${({ theme }) => -theme.space[2]}px ${({ theme }) => -theme.space[3]}px;
  min-width: calc(${({ theme }) => `100vw - ${theme.space[5]}px`});

  /* Top navigation menu on larger screen */
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    min-width: 350px;
  }
`;

const getTriggerMenuUserProps = (user: UserContextUser | undefined) =>
  user?.realUser
    ? {
        imageSrc: user.realUser.profilePicture || undefined,
        name: user.realUser.name || user.realUser.email || ' ',
      }
    : {
        imageSrc: user?.profilePicture,
        name: getPreferredName(user) || user?.email || ' ',
      };

export const AccountMenu = ({
  displayLabel,
  setSwitchingProfileVisible,
}: {
  displayLabel?: string;
  setSwitchingProfileVisible: (val: boolean) => void;
}) => {
  const oneAccountMultiAccountEnabled = useIsFeatureFlagEnabled(
    'one_account_multi_account_linking'
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profilesMenuOpen, setProfilesMenuOpen] = useState(false);
  const [accountsMenuOpen, setAccountsMenuOpen] = useState(false);
  const router = useRouter();
  const signOut = useSignOut();
  const { user } = useUserContext();
  const isAdminUser = isAdmin(user);
  const isEmployeeUser = isEmployee(user);
  const isOnboardedUser = isUserOnboarded(user);
  const isImpersonated = !!user?.realUser;

  const { hasMultipleProfiles } = useUserProfiles();
  const { hasMultipleLinkedAccounts, hasReachedMaxLinkedAccounts } = useUserLinkedAccounts();

  const displayFeedbackMenuItem =
    !isImpersonated &&
    !userCan('read', Resources.employer.anonymous_feedback_management, user) &&
    isEmployeeUser &&
    isOnboardedUser;
  const displayReferralMenuItem = !isImpersonated && !isAdminUser && isOnboardedUser;

  const { shouldShowTooltip, closeTooltip } = useProfilesMenuTooltip();
  const { showModal, hideModal } = useModalContext();

  const handleDropdownOpenChange = (open: boolean) => {
    setDropdownOpen(open);

    // Set the local storage key on open to prevent the Profiles tooltip from showing again
    if (shouldShowTooltip && !open) {
      closeTooltip();
    }
  };

  // Close the dropdown on route change
  useEffect(() => {
    setDropdownOpen(false);
  }, [router]);

  // Close the profiles menu when the dropdown is closed
  useEffect(() => {
    if (!dropdownOpen) {
      setProfilesMenuOpen(false);
    }
  }, [dropdownOpen]);

  // Close the accounts menu when the dropdown is closed
  useEffect(() => {
    if (!dropdownOpen) {
      setAccountsMenuOpen(false);
    }
  }, [dropdownOpen]);

  // Open the dropdown when the Profiles Menu tooltip is shown
  useEffect(() => {
    if (shouldShowTooltip) {
      setDropdownOpen(true);
    }
  }, [shouldShowTooltip]);

  return (
    <StyledAccountMenu
      trigger={
        <AccountMenuTrigger
          {...getTriggerMenuUserProps(user)}
          tone={user?.initialsColor}
          displayLabel={displayLabel}
          data-testid="account-menu-trigger"
        />
      }
      onOpenChange={handleDropdownOpenChange}
      open={dropdownOpen}
    >
      {!isImpersonated && oneAccountMultiAccountEnabled && hasMultipleLinkedAccounts && (
        <>
          <Text variant="2xsSemiBold" color="grey.600" ml={4} mt={4} mb={2}>
            SIGNED IN AS
          </Text>
          <ProfilesMenu
            isAccountsMenu
            open={accountsMenuOpen}
            setSwitchingProfileVisible={setSwitchingProfileVisible}
            onOpenChange={(open) => {
              if (open) {
                setProfilesMenuOpen(false);
              }
              setAccountsMenuOpen(open);
            }}
          />
          <StyledSeparator />
        </>
      )}
      {!isImpersonated && hasMultipleProfiles && (
        <>
          {oneAccountMultiAccountEnabled && (
            <Text variant="2xsSemiBold" color="grey.600" ml={4} mt={4} mb={2}>
              PROFILE
            </Text>
          )}
          <ProfilesMenu
            open={profilesMenuOpen}
            setSwitchingProfileVisible={setSwitchingProfileVisible}
            onOpenChange={(open) => {
              if (open) {
                setAccountsMenuOpen(false);
              }
              setProfilesMenuOpen(open);
            }}
            isAccountsMenu={false}
          />
          <StyledSeparator />
        </>
      )}

      <Box overflow="hidden">
        {!isImpersonated && isOnboardedUser && (
          <AccountMenuItem
            href={isAdminUser ? ADMIN_ACCOUNT_SETTINGS_ROUTE : ACCOUNT_SETTINGS_ROUTE}
            Icon={IconV2OutlineSettings}
            trackingKey="account-settings"
          >
            Manage account
          </AccountMenuItem>
        )}
        {!isImpersonated && oneAccountMultiAccountEnabled && (
          <AccountMenuItem
            onClick={() => {
              if (hasReachedMaxLinkedAccounts) {
                showModal({
                  // @ts-expect-error
                  component: ModalActionable,
                  modalProps: {
                    confirmLabel: 'Close',
                    showCancel: false,
                    onClose: hideModal,
                    onConfirm: hideModal,
                    description: (
                      <Text>
                        <Text variant="smMedium" as="span">
                          You have reached the limit of 10 accounts.
                        </Text>
                        <Text variant="sm" as="span">
                          {' '}
                          To add a new account, please remove an existing one in your{' '}
                          <ButtonInline
                            asTag="a"
                            onClick={() => {
                              hideModal();
                            }}
                            href={
                              isAdminUser ? ADMIN_ACCOUNT_SETTINGS_ROUTE : ACCOUNT_SETTINGS_ROUTE
                            }
                          >
                            Account settings
                          </ButtonInline>
                          .
                        </Text>
                      </Text>
                    ),
                    title: `Account limit reached`,
                    tone: 'primary',
                  },
                });
                return;
              }
              setSwitchingProfileVisible(true);
              setDropdownOpen(false);
              trackEvent(accountsSwitchingEvents.ADD_ACCOUNT_CLICKED);
              showModal({
                component: ProfileAddModal,
                modalProps: {
                  visible: true,
                  onSuccessAuth: () => {
                    router.reload();
                  },
                  onClose: () => {
                    setSwitchingProfileVisible(false);
                    hideModal();
                  },
                  isAccountSwitching: false,
                },
              });
            }}
            Icon={IconV2OutlinePlus}
            trackingKey="add-account"
            data-testid="add-account"
          >
            Add account
          </AccountMenuItem>
        )}
        {oneAccountMultiAccountEnabled && <StyledSeparator />}
        {displayFeedbackMenuItem && (
          <AccountMenuItem
            href={FEEDBACK_ROUTE}
            Icon={IconV2OutlinePromotion}
            trackingKey="feedback"
          >
            Share my feedback
          </AccountMenuItem>
        )}
        {displayReferralMenuItem && (
          <AccountMenuItem
            href={`${ACCOUNT_SETTINGS_ROUTE}?selectedTab=referral-program`}
            Icon={IconV2OutlineGift}
            trackingKey="referral-program"
          >
            Refer a friend
          </AccountMenuItem>
        )}
        <AccountMenuItem
          Icon={IconV2OutlineArrowCornerCw}
          onSelect={signOut}
          trackingKey="logout"
          hasDivider={
            oneAccountMultiAccountEnabled && (displayFeedbackMenuItem || displayReferralMenuItem)
          }
        >
          Log out
        </AccountMenuItem>
      </Box>
    </StyledAccountMenu>
  );
};
