import {
  makeDateFormatFn as makeFormatFn,
  EMPTY_DATE,
  ensureTimezoned,
  convertUtcStringToDateObj,
  getUserTimezone,
} from '@remote-com/norma';
import {
  add,
  addBusinessDays,
  addDays,
  compareAsc,
  differenceInDays,
  eachMonthOfInterval,
  formatDistance,
  formatDistanceToNowStrict,
  intervalToDuration,
  isFuture,
  isPast,
  isSameYear,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
  parse,
  parseISO,
} from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import type { ValueOf } from 'type-fest';

import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DUE_DATE_COLOR,
  EMPLOY_START_DATE,
  ERROR_DUE_DATE_COLOR,
  MISSING_DUE_DATE_COLOR,
  monthOptions,
  WARNING_DUE_DATE_COLOR,
} from '@/src/constants/dates';
import { friendlyPlaceholderDash } from '@/src/helpers/general';

import { getSingularPluralUnit } from './i18n/copy';

/**
 * @deprecated - use directly from Norma
 */
export {
  addTimeIfMissing,
  convertLocalTimeStringToDateObj,
  convertUtcStringToDateObj,
  ensureTimezoned,
  formatFullMonth,
  formatFullMonthDay,
  formatFullMonthDayOrdinal,
  getUserTimezone,
  hasTimezone,
  normalizeMonth,
} from '@remote-com/norma';

export function isValidDate(dateObject: Date) {
  return new Date(dateObject).toString() !== 'Invalid Date';
}

/**
 * @deprecated - use convertUtcStringToDateObj or convertLocalTimeStringToDateObj instead
 */
export function makeDateFromInput(
  datetime: string | number | Date,
  options: { convertToLocalTime?: boolean } = {}
) {
  try {
    if (
      !datetime ||
      datetime === 'no' ||
      (typeof datetime === 'string' && /^\d{4}$/.test(datetime))
    ) {
      return null;
    }

    // Date constructor parses yyyy-mm and yyyy-m dates differently, so
    // we normalize it here.
    // dddd-m → dddd-mm conversion
    if (typeof datetime === 'string' && /^\d{4}-\d{1}$/.test(datetime)) {
      const [year, month] = datetime.split('-');
      datetime = `${year}-0${month}`;
    }

    // we assume that all string dates are coming from backend, where we store them in UTC
    let timeZone = typeof datetime === 'string' && 'utc';
    if (options.convertToLocalTime) {
      // in some scenarios where user actions are needed (eg: payments) we will
      // need to show the correct date value in the user's local time
      timeZone = getUserTimezone();
    }
    const date = new Date(datetime);

    // Starting from v2 `date-fns` throw `RangeError` when attempting to format an invalid
    // date: such errors could appear when mounting `DatePickerField` with a string value that
    // does not look like a date. To prevent that, short-circuit when `date` is an "Invalid Date".
    //
    // See:
    // - https://github.com/date-fns/date-fns/blob/master/CHANGELOG.md#200---2019-08-20
    if (!isValid(date)) {
      return null;
    }

    return timeZone ? utcToZonedTime(date, timeZone) : date;
  } catch (e) {
    return null;
  }
}

type MakeFormatFnOptions = { isSourceInUtc: boolean; formatInLocalTime: boolean; timezone: string };
export type DateFormatterFn = (
  datetime: string | number | Date,
  options?: MakeFormatFnOptions
) => string;

export const formatDay = makeFormatFn('do'); // "1st"
export const formatDaySingleDigit = makeFormatFn('d'); // "1"
export const formatDayTwoDigits = makeFormatFn('dd'); // "01"
export const formatDayMonth = makeFormatFn('do MMM'); // "1st Jan"
export const formatMonth = makeFormatFn('MMM'); // "Jan"
export const formatMonthDay = makeFormatFn('MMM d'); // "Jan 1"
export const formatWeekdayFullMonth = makeFormatFn('eeee, do MMMM'); // "Monday, 1st January"
export const formatFullWeekdayShortMonthDay = makeFormatFn('eeee, MMM dd'); // "Monday, Jan 01"
export const formatFullMonthYear = makeFormatFn('MMMM, yyyy'); // "January, 2023"
export const formatDayYear = makeFormatFn('d, yyyy'); // "1, 2023"
export const formatMonthDayYear = makeFormatFn('MMM d, y'); // "Jan 1, 2023"
export const formatFullMonthDayYear = makeFormatFn('MMMM d, y'); // "January 1, 2023"
export const formatFullMonthDayYearHourMinutes = makeFormatFn('MMMM d, y, HH:mm'); // "January 1, 2023, 15:14"
export const formatYear = makeFormatFn('yyyy'); // "2023"
export const formatYearMonthDay = makeFormatFn('yyyy-MM-dd'); // "2023-01-01"
export const formatYearMonthDayTime = makeFormatFn('yyyy-MM-dd HH:mm:ss'); // "2023-01-01 15:14:00"
export const formatYearMonthDayTimeTimezone = makeFormatFn('yyyy-MM-dd HH:mm:ss OOOO'); // "2023-01-01 15:14:00 GMT-7"
export const formatYearMonthDayICS = makeFormatFn('yyyyMMdd');
export const formatTimeICS = makeFormatFn('HHmmss');
export const formatDayMonthYear = makeFormatFn('dd-MM-yyyy'); // "01-01-2023"
export const formatFullDate = makeFormatFn('do MMM y'); // "1st Jan 2023"
export const formatDayFullMonthYear = makeFormatFn('do MMMM y'); // "1st January 2023"
export const formatYearMonth = makeFormatFn('yyyy-MM'); // "2023-01"
export const formatYearMonthCompact = makeFormatFn('MM/yy'); // "01/23"
export const formatYearMonthSmall = makeFormatFn('yyyy-MMM'); // "2023-Jan"
export const formatShortMonthYear = makeFormatFn('MMM yyyy'); // "Jan 2023"
export const formatDayShortMonth = makeFormatFn('MMM dd'); // "Jan 01"
export const formatShortDayShortMonth = makeFormatFn('MMM d'); // "Jan 1"
export const formatDayShortMonthYear = makeFormatFn('MMM dd, yyyy'); // "Jan 01, 2023"
export const formatShortDayShortMonthYear = makeFormatFn('MMM d, yyyy'); // "Jan 1, 2023"
export const formatDayDateShortMonth = makeFormatFn('EEE, MMM dd'); // "Mon, Jan 01"
export const formatDayDateShortMonthYear = makeFormatFn('EEE, MMM dd, yyyy'); // "Mon, Jan 01, 2023"
export const formatDayDateShortMonthYearTime = makeFormatFn('EEE, MMM dd, yyyy HH:mm:ss'); // "Mon, Jan 01, 2023 15:14:00"
export const formatWeekDayDayOfMonthMonthShort = makeFormatFn('E, LLL d'); // "Mon, Jan 1"
export const formatDayOfTheWeek = makeFormatFn('EEE'); // "Mon"
export const formatShortDayOfTheWeek = makeFormatFn('EEEEEE'); // "Mo"
export const formatFullDayOfTheWeek = makeFormatFn('eeee'); // "Monday"
export const formatTimezone = makeFormatFn('OO'); // "GMT-7"

export const friendlyYearMonth = makeFormatFn('LLLL yyyy'); // "January 2023"
export const formatPayrollPeriodDate = makeFormatFn('MMMM yyyy'); // "January 2023"
export const formatPayrollDateLabel = makeFormatFn('LLLL do, u'); // "January 1st, 2023"
export const formatPayslipTime = makeFormatFn('MMMM y'); // "January 2023"
export const formatHolidayDate = makeFormatFn('iii, MMMM d'); // "Mon, January 1"
export const formatPaymentEventDate = makeFormatFn('yyyy-MM-dd HH:mm:ss'); // "2023-01-01 15:14:00"
export const formatLastActivityDate = makeFormatFn('MMM d, y HH:mm'); // "Jan 1, 2023 15:14"
export const formatTimestamp = makeFormatFn('p, PPP'); // "3:14 PM, January 1st, 2023"
export const formatTime = makeFormatFn('p'); // "3:14 PM"
export const formatTime24 = makeFormatFn('HH:mm'); // "15:14"
export const formatTime24Seconds = makeFormatFn('HH:mm:ss'); // "15:14:00"
export const formatLastUpdatedDate = makeFormatFn('MMM d, yyyy HH:mm'); // "Jan 1, 2023 15:14"
export const formatLastUpdatedDateTimezone = makeFormatFn('MMM d, yyyy HH:mm z'); // "Jan 1, 2023 15:14 UTC"
export const formatLastUpdatedTimeTimezone = makeFormatFn('hh:mm aa z'); // "10:15 AM UTC"
export const formatTimestampConversational = makeFormatFn('PPpp'); // "January 1st, 2023 at 3:14 PM"
export const formatQuarterYear = makeFormatFn('QQQ yyyy'); // "Q1 2023"
export const formatWeekdayMonthDay = makeFormatFn('E, dd'); // "Mon, 01"
export const formatMonthDayOrdinalTimeTimezone = makeFormatFn('MMMM do hh:mmaa z'); // "January 1st 3:14PM UTC"
export const formatHoursMinutes12HourClock = makeFormatFn('h:mm aaaa'); // "3:14 PM"
export const formatMonthDayHourTimezone = makeFormatFn('MMM d, h a z'); // "Jan 1, 3 PM UTC"
export const formatFullWeekdayDayMonthYear = makeFormatFn('EEEE, d MMMM yyyy'); // "Monday, 1 January 2023"

export function getYearOptions({
  startingYear = EMPLOY_START_DATE,
  includeBlankOption = true,
  includeMonths = false,
  extraYears = 1,
} = {}) {
  let year = new Date().getUTCFullYear() + extraYears;

  const result: { label: string | number; value: string | number }[] = includeBlankOption
    ? [
        {
          label: 'All',
          value: '',
        },
      ]
    : [];

  while (year >= startingYear) {
    if (includeMonths) {
      for (let i = 0; i < monthOptions.length; i++) {
        result.push({
          label: `${monthOptions[i].label} ${year}`,
          // "- 1" because Date() uses 0 index months
          value: formatYearMonth(new Date(year, monthOptions[i].value - 1, 1)),
        });
      }
    } else {
      result.push({
        label: year,
        value: year,
      });
    }

    year -= 1;
  }

  return result;
}

const defaultConfig = {
  formatValue: (date: string | number | Date) =>
    formatYearMonth(date, { isSourceInUtc: false, formatInLocalTime: true }),
  formatLabel: (date: string | number | Date) =>
    friendlyYearMonth(date, { isSourceInUtc: false, formatInLocalTime: true }),
  extraMonths: 4,
  start: new Date(EMPLOY_START_DATE, 0, 1),
  sorting: 'descending',
};

export function getYearMonthOptions(config = {}) {
  const { formatValue, formatLabel, extraMonths, start, sorting } = {
    ...defaultConfig,
    ...config,
  };
  const yearMonthOptions = eachMonthOfInterval({
    start,
    end: add(new Date(), { months: extraMonths }),
  }).map((date) => ({
    label: formatLabel(date),
    value: formatValue(date),
  }));

  return sorting === 'descending' ? yearMonthOptions.reverse() : yearMonthOptions;
}

export const invoicedYearMonthOptions = [
  {
    label: 'Not invoiced yet',
    value: '',
  },
  ...getYearMonthOptions(),
];

/*
 * Returns a formatted date range using a separator, 'to' is the default separator. Example: formatDateRange('2020-03-03', '2021-04-30') returns 'Mar 3, 2020 to Apr 30, 2021'
 * Example using a given separator: formatDateRange('2020-03-03', '2021-04-30', '-') returns 'Mar 3, 2020 - Apr 30, 2021'
 * By default, if both dates are on the same year, the year on the formatted start date is omitted. Example: formatDateRange('2022-03-03', '2022-04-30') returns 'Mar 3 to Apr 30, 2022'
 * Optionally accepts a 'formatFn' argument, that overrides the default date formatting. Example: formatDateRange('2022-03-03', '2022-04-30', 'to', formatMonthDay) returns 'Mar 3 to Apr 30'
 */
export function formatDateRange(
  start: string | number | Date,
  end: string | number | Date,
  separator: string = 'to',
  formatFn?: DateFormatterFn,
  formatterOptions?: MakeFormatFnOptions
) {
  const formatStartDate =
    formatFn || (isSameYear(new Date(start), new Date(end)) ? formatMonthDay : formatMonthDayYear);
  const formatEndDate = formatFn || formatMonthDayYear;
  return `${formatStartDate(start, formatterOptions)} ${separator} ${formatEndDate(
    end,
    formatterOptions
  )}`;
}

/**
 * Append local time (midnight) without an UTC offset.
 * When the UTC offset representation is absent, date-only forms are interpreted as a UTC time and date-time forms are interpreted as a local time.
 * @param {string} date Date in date only form. Example 2021-06-10
 * @returns {Date}
 */
export function dateStringToLocalTime(date: string) {
  return new Date(`${date}T00:00:00.000`);
}

export const getDateAsUTC = (date: number | Date) => {
  const dateFormatted = format(date, DEFAULT_DATE_FORMAT);
  return zonedTimeToUtc(dateFormatted, getUserTimezone());
};

export const getCurrentDateAsUTC = () => {
  return getDateAsUTC(new Date());
};

export const getTodayAsUTCWithDefaultFormat = () => {
  return format(getCurrentDateAsUTC(), DEFAULT_DATE_FORMAT);
};

export const getYesterdayAsUTCWithDefaultFormat = () => {
  const yesterday = addDays(new Date(), -1);
  return format(zonedTimeToUtc(yesterday, getUserTimezone()), DEFAULT_DATE_FORMAT);
};

export const getDateWithBusinessDays = (date = new Date(), amount: number) => {
  return addBusinessDays(date, amount);
};

/**
 * Returns the time in 12-hour format with "a.m." or "p.m.".
 * We should not use AM/PM and the clock shouldn't include a 0 if the hour is a single digit
 * e.g. 9:02 a.m. not 09:03 a.m.
 *
 * @param {string} time - The time in "HH:mm" format.
 * @returns {string} The time in 12-hour format with "a.m." or "p.m." (e.g., "10:07 p.m.").
 */
export const getHoursMinutes12HourClock = (time: string) => {
  return formatHoursMinutes12HourClock(new Date(parse(time, 'HH:mm', new Date())), {
    formatInLocalTime: true,
  });
};

/**
 * Returns the time in 12-hour format with "AM" or "PM".
 * The clock shouldn't include a 0 if the hour is a single digit
 * e.g. 9:02 AM not 09:03 AM
 *
 * @param {string} time - The time in "HH:mm" format.
 * @returns {string} The time in 12-hour format with "a.m." or "p.m." (e.g., "10:07 p.m.").
 */
export const getHoursMinutes12HourClockCapital = (time: string) => {
  return formatTime(new Date(parse(time, 'HH:mm', new Date())), {
    formatInLocalTime: true,
  });
};

export const getDateWithTime = (date: string, time?: string | null) =>
  `${formatMonthDayYear(date)} ${time ? getHoursMinutes12HourClockCapital(time) : ''}`;

/**
 * Returns the provided date with the [ISO-8601 (YYYY-MM-DD)](https://en.wikipedia.org/wiki/ISO_8601) format.
 *
 * @param {Date} date - date to convert. Defaults to `new Date()`. Example: `new Date('2020-01-01T04:00:00Z')`.
 * @returns {String} - string with the converted value from the provided date. Example: `2020-01-01`.
 */
export const dateToISO8601 = (date = new Date()) => {
  return date.toISOString().substring(0, 10);
};

/**
 * Returns the color used to indicate the urgency of a due date.
 * @param {string} date - Due date
 * @returns {string} - color to render the date in.
 */
export function getDueDateColor(date: string | number | Date) {
  if (!date) return MISSING_DUE_DATE_COLOR;

  const dueDate = new Date(date);

  if (isToday(dueDate) || isTomorrow(dueDate)) {
    return WARNING_DUE_DATE_COLOR;
  }
  if (isPast(dueDate)) {
    return ERROR_DUE_DATE_COLOR;
  }
  return DEFAULT_DUE_DATE_COLOR;
}

/**
 * Returns a date string formatted relative to today. Eg. Today, Tomorrow.
 * @param {string} dateString - Date to format
 * @typedef {Object} getFormattedDateOptions
 * @property {(date: Date) => string} [formatFuture] - function to format date if its in the future.
 * @property {(date: Date) => string} [formatPast] - function to format date if its in the past.
 * @property {makeFormatFnOptions} [formatDateOptions] - options passed to format date function if no relative formatting applies.
 * @param {getFormattedDateOptions} options
 * @returns {String} Formatted string
 */
export function getRelativeFormattedDate(
  dateString: string,
  {
    formatFuture,
    formatPast,
    formatDateOptions,
  }: {
    formatFuture?: (date: Date) => string;
    formatPast?: (date: Date) => string;
    formatDateOptions?: {
      isSourceInUtc?: boolean;
      formatInLocalTime?: boolean;
    };
  } = {}
) {
  if (!dateString) return 'n/a';

  const date = new Date(dateString);

  if (isToday(date)) {
    return 'Today';
  }
  if (isTomorrow(date)) {
    return 'Tomorrow';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  if (isFunction(formatFuture) && isFuture(date)) {
    return formatFuture(date);
  }
  if (isFunction(formatPast) && isPast(date)) {
    return formatPast(date);
  }

  return formatYearMonthDay(date, formatDateOptions);
}

/**
 * Given a data, returns current age.
 * @param {string|number|Date} birthdate - Format YYYY-MM-DD
 * @returns {Number} - age
 */
export function getAge(birthdate: string | number | Date) {
  if (!birthdate) return undefined;
  const birthday = new Date(birthdate);
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * Given a startDate and endDate, returns the difference in months.
 * @param {string} startDate - Format YYYY-MM-DD
 * @param {string} endDate - Format YYYY-MM-DD
 * @returns {number|null} - difference in months
 */
export function getMonthDifference(startDate: string, endDate: string) {
  const utcStartDate = convertUtcStringToDateObj(startDate);
  const utcEndDate = convertUtcStringToDateObj(endDate);

  return utcStartDate && utcEndDate
    ? utcEndDate?.getMonth() -
        utcStartDate?.getMonth() +
        12 * (utcEndDate?.getFullYear() - utcStartDate?.getFullYear())
    : null;
}

/**
 * Given a targetDate returns the difference between the current day or a given baseDate.
 * Return examples: "in 1 day", "in about 2 months", "1 year ago", etc.
 * @param {string} targetDate - Format YYYY-MM-DD
 * @param {string|undefined} baseDate - Format YYYY-MM-DD
 * @returns {string|null} - difference in locale text
 */
export function getDayDifferenceFrom(
  targetDate: string,
  baseDate: string = getTodayAsUTCWithDefaultFormat()
) {
  const utcBaseDate = convertUtcStringToDateObj(baseDate);
  const utcTargetDate = convertUtcStringToDateObj(targetDate);
  if (utcTargetDate && utcBaseDate) {
    const diff = differenceInDays(utcTargetDate, utcBaseDate);

    // otherwise will format as "less than a minute ago"
    if (diff === 0) {
      return 'Today';
    }

    return formatDistance(utcTargetDate, utcBaseDate, { addSuffix: true });
  }

  return null;
}

/**
 * Returns date n days before provided date input
 * @param {string | number | Date} date
 * @param {number} number
 * @returns {number | '—'} Timestamp or in case the date before cannot be calculated, '-' is returned
 */
export function getDateNDaysBefore(date: string | number | Date, number: number): Date | '—' {
  const newDate = addDays(new Date(date), -1 * number);

  if (!!date && newDate instanceof Date && !Number.isNaN(newDate.valueOf())) {
    return newDate;
  }
  return '—';
}

/**
 * Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
 * @param {string} dateLeft - Format YYYY-MM-DD
 * @param {string} dateRight - Format YYYY-MM-DD
 * @returns {number|null} - return 1 (dateLeft > dateRight), -1 (dateLeft < dateRight), 0 (dateLeft == dateRight) or null
 */
export function compareDates(dateLeft: string, dateRight: string) {
  const utcDateLeft = convertUtcStringToDateObj(dateLeft);
  const utcDateRight = convertUtcStringToDateObj(dateRight);

  if (utcDateRight && utcDateLeft) {
    return compareAsc(utcDateLeft, utcDateRight);
  }

  return null;
}

/**
 * Formats a given ISO date string as a localized "time ago" string.
 * @param {string} date - The ISO date string to format.
 * @param {string} fallback - The fallback string to return if the date is empty.
 * @returns {string} The formatted "time ago" string, adjusted for local time.
 */
export const formatDateAsLocalizedTimeAgo = (
  date: string,
  fallback: string = EMPTY_DATE
): string => {
  if (!date) return fallback;

  return formatDistanceToNowStrict(parseISO(ensureTimezoned(date)), {
    addSuffix: true,
  });
};

/**
 * Returns the difference in years, months and days between to days, human readable.
 * @param {string} startDate
 * @param {string} endDate
 * @returns {string} The formatted human readable difference between dates. (eg: 1 year, 6 months and 17 days.)
 */
export const getRelativeFormattedDateDifference = (startDate: Date, endDate: Date): string => {
  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  });
  const yearStringFormat = duration.years === 1 ? 'year' : 'years';
  const monthsStringFormat = duration.months === 1 ? 'month' : 'months';
  const daysStringFormat = duration.days === 1 ? 'day' : 'days';

  const durationInYears = duration.years
    ? `${duration.years} ${yearStringFormat}, ${duration.months} ${monthsStringFormat} and ${duration.days} ${daysStringFormat}.`
    : '';
  const durationInMonths = duration.months
    ? `${duration.months} ${monthsStringFormat} and ${duration.days} ${daysStringFormat}.`
    : '';
  const durationInDays =
    duration.days || duration.days === 0 ? `${duration.days} ${daysStringFormat}.` : '';

  return durationInYears || durationInMonths || durationInDays;
};

const TIME_UNIT = {
  SECOND: 'second',
  MINUTE: 'minute',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
} as const;

export type TimeUnit = ValueOf<typeof TIME_UNIT>;

const accessibleSeparator = ({
  isAccessible = true,
  separator = '-',
}: {
  isAccessible?: boolean;
  separator?: string;
}) => {
  return isAccessible ? ' to ' : separator;
};

/**
 * Formats a time range e.g. '1-2 weeks', with support for screen-readers e.g. '1 to 2 weeks'
 */
export const formatTimeRange = ({
  startNumber,
  endNumber,
  timeUnit,
  separator = '-',
  isAccessible = true,
}: {
  startNumber: number;
  endNumber: number;
  timeUnit: TimeUnit;
  separator?: string;
  isAccessible?: boolean;
}) => {
  if (startNumber === endNumber) {
    return startNumber === 1 ? `${startNumber} ${timeUnit}` : `${startNumber} ${timeUnit}s`;
  }

  return `${startNumber}${accessibleSeparator({
    isAccessible,
    separator,
  })}${endNumber} ${timeUnit}s`;
};

export type TimeInterval = {
  hours: number;
  minutes: number;
  seconds?: number;
};

type TimeIntervalDisplayOptions = {
  showZeroMinutes?: boolean;
  isAccessible?: boolean;
  friendlyLabel?: boolean;
};

function getInvalidDurationDisplay(options?: TimeIntervalDisplayOptions): string {
  if (options?.isAccessible) {
    return '0 hours';
  }
  return options?.friendlyLabel ? friendlyPlaceholderDash : '0h';
}

const DURATION_UNITS = {
  hour: {
    full: 'hour',
    short: 'h',
    plural: 'hours',
  },
  minute: {
    full: 'minute',
    short: 'm',
    plural: 'minutes',
  },
  second: {
    full: 'second',
    short: 's',
    plural: 'seconds',
  },
};

function normalizeDuration(duration: TimeInterval): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  // Convert everything to minutes first
  let totalMinutes = duration.hours * 60 + duration.minutes;
  let seconds = duration.seconds || 0;

  // Handle seconds conversion
  if (seconds >= 60) {
    totalMinutes += Math.floor(seconds / 60);
    seconds %= 60;
  }

  // Convert back to hours, minutes and seconds
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes, seconds };
}

export function formatAccessibleDuration(duration: TimeInterval): string {
  const { hours, minutes, seconds } = normalizeDuration(duration);

  const components = [
    { value: hours || 0, unit: 'hour' as const },
    { value: minutes || 0, unit: 'minute' as const },
    { value: seconds || 0, unit: 'second' as const },
  ];

  return components
    .filter(({ value }) => value > 0)
    .map(({ value, unit }) =>
      getSingularPluralUnit(value, DURATION_UNITS[unit].full, DURATION_UNITS[unit].plural, false)
    )
    .join(' ');
}

export function formatCompactDuration(
  duration: TimeInterval,
  options: TimeIntervalDisplayOptions = {}
): string {
  const { hours, minutes, seconds } = normalizeDuration(duration);
  const parts: string[] = [];

  if (hours > 0) {
    parts.push(`${hours}h`);
    // Add minutes only if they exist or showZeroMinutes is true
    if (minutes > 0 || options.showZeroMinutes) {
      parts.push(`${minutes}m`);
    }
  } else if (minutes > 0) {
    parts.push(`${minutes}m`);
    // Only show seconds if there are no hours and minutes are less than 10
    if (seconds > 0 && minutes < 10) {
      parts.push(`${seconds}s`);
    }
  } else if (seconds >= 0) {
    parts.push(`${seconds}s`);
  }

  return parts.join(' ');
}

export function displayDuration(
  duration: TimeInterval | null | undefined,
  options?: TimeIntervalDisplayOptions
): string {
  // Handle invalid input
  const invalidInput = !duration || (isNil(duration.hours) && isNil(duration.minutes));
  if (invalidInput) {
    return getInvalidDurationDisplay(options);
  }

  const hours = duration.hours ?? 0;
  const minutes = duration.minutes ?? 0;
  const seconds = duration.seconds ?? 0;

  // Return early if duration is zero
  if (hours === 0 && minutes === 0 && seconds === 0) {
    return getInvalidDurationDisplay(options);
  }

  // Format based on accessibility option
  return options?.isAccessible
    ? formatAccessibleDuration(duration)
    : formatCompactDuration(duration, options);
}
