import type { EngineeringTeamName } from './teams';

/**
 * This map is used to determine which team(s) are responsible for a given route. null means that the route is not associated with a specific team.
 */
export const routeTeamsMap: Record<string, EngineeringTeamName | EngineeringTeamName[] | null> = {
  '/account-locked': 'Identity & Access Management',
  '/api-integration-new-password-send': 'Powered by Remote',
  '/confirm-changed-account': 'Identity & Access Management',
  '/confirm-email-change': 'Identity & Access Management',
  '/confirm-notification-email-change': 'Identity & Access Management',
  '/dashboard/account-settings': 'Identity & Access Management',
  '/dashboard/benefits': 'Benefits Experience',
  '/dashboard/benefits/allianz': 'Benefits Experience',
  '/dashboard/benefits/kota/[legalEntitySlug]': 'Benefits Integrations',
  '/dashboard/benefits/kota/[legalEntitySlug]/consent': 'Benefits Integrations',
  '/dashboard/benefits/renewals': 'Benefits Experience',
  '/dashboard/benefits/simply-insured/[legalEntitySlug]': 'Benefits Experience',
  '/dashboard/benefits/simply-insured/[legalEntitySlug]/consent': 'Benefits Integrations',
  '/dashboard/billing/[slug]': 'Billing Experience',
  '/dashboard/billing/contacts': null,
  '/dashboard/billing': 'Billing Experience',
  '/dashboard/billing/outstanding-payments/[slug]': 'Employee Payments',
  '/dashboard/billing/outstanding-payments/[slug]/invoice/[invoiceSlug]': 'Billing Experience',
  '/dashboard/billing/outstanding-payments/[slug]/receipt/[receiptSlug]': 'Billing Experience',
  '/dashboard/career-journal': 'Performance Management',
  '/dashboard/career-journal/performance-cycle/[cycleSlug]': 'Performance Management',
  '/dashboard/career-journal/performance-cycle/[cycleSlug]/snapshot/[snapshotInstanceSlug]/assessment/[assessmentSlug]':
    'Performance Management',
  '/dashboard/clients': 'Contractor Experience',
  '/dashboard/company-feedback/[slug]': 'Performance Management',
  '/dashboard/company-feedback': 'Performance Management',
  '/dashboard/company-verification': null,
  '/dashboard/company': 'Web Experience',
  '/dashboard/onboarding/company/checklists/[checklistShortIdentifier]':
    'Company and People Foundations',
  '/dashboard/company/sso-manage': 'Identity & Access Management',
  '/dashboard/company/sso-setup': 'Identity & Access Management',
  '/dashboard/company-settings': 'Web Experience',
  '/dashboard/company-settings/active-products': [
    'EOR XP',
    'Contractor Experience',
    'Payroll Onboarding',
  ],
  '/dashboard/company-settings/company-information': 'Company and People Foundations',
  '/dashboard/company-settings/contractor-subscriptions': 'Contractor Management',
  '/dashboard/company-settings/custom-fields': 'Workforce Planning',
  '/dashboard/company-settings/custom-leave-policies': 'Time & Attendance',
  '/dashboard/company-settings/inactive-products': [
    'EOR XP',
    'Contractor Experience',
    'Payroll Onboarding',
  ],
  '/dashboard/company-settings/talent-plans': 'Remote Talent',
  '/dashboard/company-settings/bio': 'Remote Talent',
  '/dashboard/company-settings/legal-entities': 'Payroll Onboarding',
  '/dashboard/company-settings/org-chart': 'Workforce Planning',
  '/dashboard/company-settings/payments': 'Contractor Payments',
  '/dashboard/company-settings/pricing-plans': 'Pricing',
  '/dashboard/company-settings/pricing-plans/[planSlug]': 'Pricing',
  '/dashboard/company-settings/sso': 'Identity & Access Management',
  '/dashboard/company-settings/team-plans': 'Pricing',
  '/dashboard/company-settings/time-tracking': 'Time & Attendance',
  '/dashboard/company-settings/admin-users': 'Identity & Access Management',
  '/dashboard/company-settings/roles-permissions': 'Identity & Access Management',
  '/dashboard/company-settings/departments': 'Workforce Planning',
  '/dashboard/company-settings/departments/[departmentSlug]': 'Workforce Planning',
  '/dashboard/compensation-benchmarking': 'Market Insights',
  '/dashboard/talent-insights`': 'Market Insights',
  '/dashboard/complete-onboarding': 'Company and People Foundations',
  '/dashboard/confirm-implementation-fee/[resourceSlug]': 'Payroll Onboarding',
  '/dashboard/confirm-team-plan/[teamPlanSlug]': 'Pricing',
  '/dashboard/confirm-tiered-plan/[companyProductTierSlug]': 'Pricing',
  '/dashboard/contractor-plan-change/[employmentSlug]': 'Contractor Management',
  '/dashboard/contracts-viewer/[slug]': 'Contracts & Documents',
  '/dashboard/contracts/[contractDocumentSlug]': ['Contractor Management', 'Contracts & Documents'],
  '/dashboard/cost-approval-requests/[slug]': 'EOR XP',
  '/dashboard/deposit-methods/bank-accounts/[slug]/edit': 'Employee Payments',
  '/dashboard/deposit-methods/bank-accounts/new': 'Employee Payments',
  '/dashboard/deposit-methods': 'Employee Payments',
  '/dashboard/deposits': 'Employee Payments',
  '/dashboard/documents': 'Contracts & Documents',
  '/dashboard/documents/files': 'Contracts & Documents',
  '/dashboard/documents/tax-management': 'Contracts & Documents',
  '/dashboard/documents/1099-nec-forms': 'Contracts & Documents',
  '/dashboard/documents/templates': 'Contracts & Documents',
  '/dashboard/documents/template/[slug]': 'Contracts & Documents',
  '/dashboard/employment-requests': 'Employee Lifecycle',
  '/dashboard/employment-requests/new/[requestType]/[employmentSlug]': 'Employee Lifecycle',
  '/dashboard/expenses': 'Expense Management',
  '/dashboard/expenses/mobile-app/apple': 'Expense Management',
  '/dashboard/expenses/mobile-app/google': 'Expense Management',
  '/dashboard/expenses/review': 'Expense Management',
  '/dashboard/feedback/[slug]': 'Performance Management',
  '/dashboard/file-preview': null,
  '/dashboard/fill-form/[slug]': 'Contracts & Documents',
  '/dashboard/handbook/[id]': 'EOR XP',
  '/dashboard/handbook': 'EOR XP',
  '/dashboard/incentives': 'Payroll Self-Serve Processing',
  '/dashboard': 'Web Experience',
  '/dashboard/company-settings/integrations/remote-api': 'Powered by Remote',
  '/dashboard/company-settings/integrations/[name]': 'Customer Accounting Integrations',
  '/dashboard/company-settings/integrations/[name]/sync-summary':
    'Customer Accounting Integrations',
  '/dashboard/company-settings/integrations': 'Customer Accounting Integrations',
  '/dashboard/company-settings/integrations/netsuite': 'Customer Accounting Integrations',
  '/dashboard/company-settings/integrations/netsuite/mapping-preferences':
    'Customer Accounting Integrations',
  '/dashboard/company-settings/integrations/netsuite/subsidiary-preferences':
    'Customer Accounting Integrations',
  '/dashboard/company-settings/integrations/quickbooks/mapping-preferences':
    'Customer Accounting Integrations',
  '/dashboard/company-settings/integrations/quickbooks/inactive-contractors':
    'Customer Accounting Integrations',
  '/dashboard/invoices/[contractorInvoiceSlug]': 'Contractor Experience',
  '/dashboard/invoices': 'Contractor Experience',
  '/dashboard/invoices/new': 'Contractor Experience',
  '/dashboard/invoices/upload': 'Contractor Experience',
  '/dashboard/invoices/edit/[slug]': 'Contractor Experience',
  '/dashboard/legal-entities/[slug]/add-signatory': 'Payroll Onboarding',
  '/dashboard/legal-entities/[slug]': 'Payroll Onboarding',
  '/dashboard/legal-entities/[slug]/onboarding-tasks': 'Payroll Onboarding',
  '/dashboard/legal-entities/[slug]/setup-pay-schedule': 'Payroll Onboarding',
  '/dashboard/legal-entities/add': 'Payroll Onboarding',
  '/dashboard/legal-entities/[slug]/upload-documents': 'Payroll Onboarding',
  '/dashboard/legal-entities/[slug]/employee-upload-documents': 'Payroll Onboarding',
  '/dashboard/manage-2fa': 'Identity & Access Management',
  '/dashboard/marketplace': 'Customer Accounting Integrations',
  '/dashboard/offboarding': 'Employee Lifecycle',
  '/dashboard/pay-details/[contractSlug]': 'Payroll Self-Serve Processing',
  '/dashboard/pay-elements/create': 'Payroll Self-Serve Processing',
  '/dashboard/pay-elements/create/inline': 'Payroll Self-Serve Processing',
  '/dashboard/pay-elements/edit/[slug]': 'Payroll Self-Serve Processing',
  '/dashboard/company-settings/payment-methods/ach/[slug]/success': 'Employee Payments',
  '/dashboard/company-settings/payment-methods/ach/new': 'Employee Payments',
  '/dashboard/company-settings/payment-methods/ach/new/account-details': 'Employee Payments',
  '/dashboard/company-settings/payment-methods/ach/new/trustly/cancel': 'Fraud & Risk',
  '/dashboard/company-settings/payment-methods/ach/new/trustly': 'Fraud & Risk',
  '/dashboard/company-settings/payment-methods/cards/[slug]/update': 'Employee Payments',
  '/dashboard/company-settings/payment-methods': 'Employee Payments',
  '/dashboard/company-settings/payment-methods/new': 'Employee Payments',
  '/dashboard/org-chart': 'Workforce Planning',
  '/dashboard/payment-requests': ['Contractor Payments', 'Contractor Management'],
  '/dashboard/payment-requests/prefunding/add': 'Contractor Payments',
  '/dashboard/payment-requests/schedules': ['Contractor Payments', 'Contractor Management'],
  '/dashboard/payment-requests/transactions': ['Contractor Payments', 'Contractor Management'],
  '/dashboard/payment-requests/scheduled-invoices/bulk/generate-csv': 'Contractor Management',
  '/dashboard/payment-requests/scheduled-invoices/bulk/preview': 'Contractor Management',
  '/dashboard/payment-requests/scheduled-invoices/bulk/progress/[batchId]': 'Contractor Management',
  '/dashboard/payment-requests/scheduled-invoices/bulk/validate-csv': 'Contractor Management',
  '/dashboard/payment-requests/invoices/[invoiceSlug]': [
    'Contractor Payments',
    'Contractor Management',
  ],
  '/dashboard/payroll/employee-payments': 'Payroll Self-Serve Processing',
  '/dashboard/company-settings/payroll': 'Payroll Onboarding',
  '/dashboard/payroll/[payrollSlug]/details': 'Payroll Self-Serve Processing',
  '/dashboard/payroll/[payrollSlug]/payouts': 'Employee Payments',
  '/dashboard/payroll': 'Payroll Self-Serve Processing',
  '/dashboard/payslips': 'Payroll Self-Serve Processing',
  '/dashboard/payslips/[payrollOutputSlug]': 'Payroll Data and Platform',
  '/dashboard/people/[employmentSlug]': 'Company and People Foundations',
  '/dashboard/people/[employmentSlug]/offboarding': 'Employee Lifecycle',
  '/dashboard/people/[employmentSlug]/salary': 'Payroll Onboarding',
  '/dashboard/people/add': ['EOR XP', 'Payroll Onboarding'],
  '/dashboard/people/edit/[employmentType]/[employmentSlug]': ['EOR XP', 'Payroll Onboarding'],
  '/dashboard/people/amendments/new': 'Employee Lifecycle',
  '/dashboard/people/amendments/history': 'Employee Lifecycle',
  '/dashboard/people/hiring': ['EOR XP', 'Payroll Onboarding'],
  '/dashboard/people/hiring/progress-board': ['EOR XP', 'Workforce Planning'],
  '/dashboard/people': 'Company and People Foundations',
  '/dashboard/people/add-reporting-lines': 'Workforce Planning',
  '/dashboard/people/org-chart': 'Workforce Planning',
  '/dashboard/people/world-map': ['Company and People Foundations', 'Workforce Planning'],
  '/dashboard/people/link-accounts': 'Workforce Planning',
  '/dashboard/people?selectedTab=org-chart': 'Workforce Planning',
  '/dashboard/people/new/[employmentType]/[employmentSlug]/contract-details': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/dashboard/people/new/[employmentType]/[employmentSlug]': ['EOR XP', 'Payroll Onboarding'],
  '/dashboard/people/new/[employmentType]/bulk': ['EOR XP', 'Payroll Onboarding'],
  '/dashboard/people/new/[employmentType]/bulkupload/[productType]': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/dashboard/people/new/[employmentType]/bulkupload': ['EOR XP', 'Payroll Onboarding'],
  '/dashboard/people/new/[employmentType]/generate-csv': 'EOR XP',
  '/dashboard/people/new/[employmentType]': ['EOR XP', 'Payroll Onboarding'],
  '/dashboard/people/new/full_time/pre-hire-check/[employmentSlug]/details': 'EOR XP',
  '/dashboard/people/new/full_time/pre-hire-check/[employmentSlug]': 'EOR XP',
  '/dashboard/people/new/full_time/pre-hire-check': 'EOR XP',
  '/dashboard/people/pre-hire/[employmentSlug]': 'EOR XP',
  '/dashboard/performance': 'Performance Management',
  '/dashboard/performance/cycle/[cycleSlug]': 'Performance Management',
  '/dashboard/performance/gateway/cycle/[cycleSlug]': 'Performance Management',
  '/dashboard/performance/cycle/[cycleSlug]/edit': 'Performance Management',
  '/dashboard/performance/cycle/[cycleSlug]/calibration/[calibrationEventSlug]':
    'Performance Management',
  '/dashboard/performance/cycle/[cycleSlug]/calibration/[calibrationEventSlug]/group/[calibrationGroupSlug]':
    'Performance Management',
  '/dashboard/performance/create-cycle': 'Performance Management',
  '/dashboard/personal-details': null,
  '/dashboard/personal-equity': 'Equity Management',
  '/dashboard/processors': null,
  '/dashboard/products/start-trial/[productEmploymentType]': 'Remote Talent',
  '/dashboard/products/[productIdentifier]/plans': 'Remote Talent',
  '/dashboard/products/[productIdentifier]/plans/[productPriceSlug]/changes': 'Remote Talent',
  '/dashboard/products/[productIdentifier]/plans/[productPriceSlug]/checkout': 'Employee Payments',
  '/dashboard/products/[productIdentifier]/plans/[productPriceSlug]/checkout/status':
    'Employee Payments',
  '/dashboard/projects/[projectSlug]/edit': 'Contractor Experience',
  '/dashboard/remote-payments/[entityType]/[entitySlug]': 'Fraud & Risk',
  '/dashboard/remote-payments/landing': 'Fraud & Risk',
  '/dashboard/reports': 'Analytics & Reporting',
  '/dashboard/roles-permissions/[slug]': 'Identity & Access Management',
  '/dashboard/roles-permissions/create': 'Identity & Access Management',
  '/dashboard/subscriptions/[planSlug]': 'Pricing',
  '/dashboard/subscriptions': 'Pricing',
  '/dashboard/support-monitor': 'Web Experience',
  '/dashboard/support-monitor/[ticketId]': 'Web Experience',
  '/dashboard/switch-to-freelancer-profile': 'Contractor Experience',
  '/dashboard/talent/company/profile/edit': 'Remote Talent',
  '/dashboard/talent/company/profile/completed': 'Remote Talent',
  '/dashboard/talent': 'Remote Talent',
  '/dashboard/talent/jobs/[jobSlug]/checkout': 'Remote Talent',
  '/dashboard/talent/jobs/[jobSlug]/edit': 'Remote Talent',
  '/dashboard/talent/jobs/[jobSlug]/view': 'Remote Talent',
  '/dashboard/talent/jobs/edit/[jobSlug]': 'Remote Talent',
  '/dashboard/talent/jobs': 'Remote Talent',
  '/dashboard/talent/jobs/new': 'Remote Talent',
  '/dashboard/talent/jobs/publish-success': 'Remote Talent',
  '/dashboard/talent/sourcing': 'Remote Talent',
  '/dashboard/talent/sourcing/add/candidate': 'Remote Talent',
  '/dashboard/talent/sourcing/edit/candidate': 'Remote Talent',
  '/dashboard/talent/subscription/plans': 'Remote Talent',
  '/dashboard/tasks': null,
  '/dashboard/tax-compliance': 'EOR XP',
  '/dashboard/tce-calculator': 'Market Insights',
  '/dashboard/team-performance': 'Performance Management',
  '/dashboard/team-performance/cycle/[slug]': 'Performance Management',
  '/dashboard/team-performance/cycle/[slug]/snapshot/[slug]/instance/[slug]/assessment/[slug]':
    'Performance Management',
  '/dashboard/team-performance/cycle/[slug]/calibration/[calibrationEventSlug]/group/[calibrationGroupSlug]':
    'Performance Management',
  '/dashboard/team-performance/feedback/[employmentSlug]': 'Performance Management',
  '/dashboard/team-plans/[slug]': 'Pricing',
  '/dashboard/time-off': 'Time & Attendance',
  '/dashboard/time-off/bulk/requests': 'Time & Attendance',
  '/dashboard/time-off/bulk/requests-history': 'Time & Attendance',
  '/dashboard/time-off/requests': 'Time & Attendance',
  '/dashboard/time-off/policy': 'Time & Attendance',
  '/dashboard/time-tracking': 'Time & Attendance',
  '/dashboard/todos': 'Web Experience',
  '/dashboard/users-permissions/[userSlug]': 'Identity & Access Management',
  '/dashboard/users-permissions': 'Identity & Access Management',
  '/dashboard/withdrawal-methods/bank-accounts/[slug]/edit': 'Contractor Payments',
  '/dashboard/withdrawal-methods/bank-accounts/new': 'Contractor Payments',
  '/dashboard/withdrawal-methods': 'Contractor Payments',
  '/dashboard/withdrawal-methods/nium/new': 'Contractor Payments',
  '/dashboard/withdrawal-methods/stripe-connect/complete-onboarding': 'Contractor Payments',
  '/dashboard/withdrawal-methods/stripe-connect/onboarding': 'Contractor Payments',
  '/dashboard/withdrawal-methods/stripe-connect/refresh': 'Contractor Payments',
  '/dashboard/withdrawal-methods/stripe-connect/reset-onboarding': 'Contractor Payments',
  '/dashboard/company-settings/workflows/[slug]': 'Workflow Engine',
  '/dashboard/company-settings/workflows/new': 'Workflow Engine',
  '/dashboard/company-settings/workflows': 'Workflow Engine',
  '/knowledge/facts/new': 'Information Infrastructure',
  '/knowledge': 'Information Infrastructure',
  '/onboarding/company': 'Company and People Foundations',
  '/onboarding/create-password': null,
  '/onboarding/employee/[employmentSlug]/additional-documents': 'EOR XP',
  '/onboarding/employee/[employmentSlug]/administrative-details/information': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/onboarding/employee/[employmentSlug]/business-information': 'Contractor Experience',
  '/onboarding/employee/[employmentSlug]/employment-eligibility': 'EOR XP',
  '/onboarding/employee/[employmentSlug]/payment-details': 'Employee Payments',
  '/onboarding/employee/[employmentSlug]/personal-details/emergency-contact': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/onboarding/employee/[employmentSlug]/personal-details/home-address': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/onboarding/employee/[employmentSlug]/personal-details/information': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/onboarding/employee/[employmentSlug]/tasks': ['EOR XP', 'Payroll Onboarding'],
  '/onboarding/employee/[employmentSlug]/verify-identity': ['EOR XP', 'Payroll Onboarding'],
  '/onboarding/freelancer/administrative-details/information': 'Contractor Experience',
  '/onboarding/freelancer': 'Contractor Experience',
  '/onboarding/freelancer/personal-details/home-address': 'Contractor Experience',
  '/onboarding/freelancer/personal-details/information': 'Contractor Experience',
  '/onboarding/freelancer/tasks': 'Contractor Experience',
  '/onboarding/freelancer/verify-identity': 'Contractor Experience',
  '/onboarding/invitation-already-accepted': 'Contractor Experience',
  '/onboarding/verify-email': null,
  '/pending-tasks/employee/[employmentSlug]/additional-documents': 'EOR XP',
  '/pending-tasks/employee/[employmentSlug]/administrative-details/information': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/pending-tasks/employee/[employmentSlug]/business-information': 'Contractor Experience',
  '/pending-tasks/employee/[employmentSlug]/payment-details': 'Employee Payments',
  '/pending-tasks/employee/[employmentSlug]/personal-details/emergency-contact': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/pending-tasks/employee/[employmentSlug]/personal-details/home-address': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/pending-tasks/employee/[employmentSlug]/personal-details/information': [
    'EOR XP',
    'Payroll Onboarding',
  ],
  '/pending-tasks/employee/[employmentSlug]/tasks': ['EOR XP', 'Payroll Onboarding'],
  '/pending-tasks/employee/[employmentSlug]/verify-identity': null,
  '/recover-password': 'Identity & Access Management',
  '/reset-password': 'Identity & Access Management',
  '/rivendell/account-settings': 'Identity & Access Management',
  '/rivendell/adjustments': null,
  '/rivendell/awesome-demos': 'PLG',
  '/rivendell/awesome-demos/new': 'PLG',
  '/rivendell/awesome-demos/details/[slug]': 'PLG',
  '/rivendell/benefits-editor': 'Benefits Core',
  '/rivendell/benefits': 'Benefits Core',
  '/rivendell/benefits/costs': 'Benefits Core',
  '/rivendell/benefits/costs/bulk/[type]': 'Benefits Core',
  '/rivendell/benefits/offers/bulk/[type]': 'Benefits Core',
  '/rivendell/benefits/setup': 'Benefits Core',
  '/rivendell/benefits/setup/bulk/[type]': 'Benefits Core',
  '/rivendell/beta-features-users': 'Payroll Self-Serve Processing',
  '/rivendell/billables': 'Billing Platform',
  '/rivendell/billing/[slug]': 'Billing Platform',
  '/rivendell/billing/events': 'Billing Platform',
  '/rivendell/billing': 'Billing Platform',
  '/rivendell/bulk-contract-amendments/[slug]': 'Employee Lifecycle',
  '/rivendell/companies/[slug]': null,
  '/rivendell/companies/[slug]/people/full_time/bulk': 'EOR XP',
  '/rivendell/companies/[slug]/legal-entities/[legalEntitySlug]/bulk': 'Payroll Onboarding',
  '/rivendell/companies/[slug]/legal-entities/new': 'Payroll Onboarding',
  '/rivendell/companies': null,
  '/rivendell/compensation-benchmarking': 'Market Insights',
  '/rivendell/config-settings/[slug]': null,
  '/rivendell/config-settings': null,
  '/rivendell/contractors': ['Contractor Management', 'Contractor Experience'],
  '/rivendell/contracts-editor/contract/[slug]': 'Contracts & Documents',
  '/rivendell/contracts-editor/template/[slug]': 'Contracts & Documents',
  '/rivendell/contracts/amendments/bulk/generate-csv': 'Employee Lifecycle',
  '/rivendell/contracts/amendments/bulk/review': 'Employee Lifecycle',
  '/rivendell/contracts/amendments/bulk/select-template': 'Employee Lifecycle',
  '/rivendell/contracts/amendments/bulk/submit': 'Employee Lifecycle',
  '/rivendell/contracts/amendments/bulk/upload-csv': 'Employee Lifecycle',
  '/rivendell/contracts/automations/[type]': 'Employee Lifecycle',
  '/rivendell/contracts': ['Contracts & Documents', 'Contractor Management'],
  '/rivendell/cost-approval-requests/[slug]/cost': 'EOR XP',
  '/rivendell/cost-approval-requests/[slug]/edit': 'EOR XP',
  '/rivendell/cost-approval-requests/[slug]': 'EOR XP',
  '/rivendell/cost-approval-requests/create': 'EOR XP',
  '/rivendell/cost-approval-requests': 'EOR XP',
  '/rivendell/countries/[countryCode]/forms/[formType]/[productType]/[slug]':
    'Information Infrastructure',
  '/rivendell/countries/details/[slug]': 'Information Infrastructure',
  '/rivendell/countries/form-editor': 'Information Infrastructure',
  '/rivendell/countries': 'Information Infrastructure',
  '/rivendell/data-changes': 'Payroll Self-Serve Processing',
  '/rivendell/data-exports': 'Payroll Integrations',
  '/rivendell/data-exports/templates/[slug]/data-transform-configuration': 'Payroll Integrations',
  '/rivendell/data-exports/templates/[slug]/edit': 'Payroll Integrations',
  '/rivendell/data-exports/templates/[slug]/preview': 'Payroll Integrations',
  '/rivendell/data-exports/templates/new': 'Payroll Integrations',
  '/rivendell/data-exports/exports/new/': 'Payroll Integrations',
  '/rivendell/data-exports/exports/[slug]/edit': 'Payroll Integrations',
  '/rivendell/employment-fees': 'Pricing',
  '/rivendell/employment-requests': 'Employee Lifecycle',
  '/rivendell/expenses': 'Expense Management',
  '/rivendell/expenses/processing': 'Expense Management',
  '/rivendell/fraud-controls': 'Fraud & Risk',
  '/rivendell/fraud-controls/kyb-dashboard': 'Fraud & Risk',
  '/rivendell/fraud-controls/country-policies': 'Fraud & Risk',
  '/rivendell/fraud-controls/severance-reserves-policy': 'Fraud & Risk',
  '/rivendell/fraud-controls/policy-for-industry-sectors': 'Fraud & Risk',
  '/rivendell/freelancers': 'Contractor Experience',
  '/rivendell/handbook/[id]': 'EOR XP',
  '/rivendell/handbook': 'EOR XP',
  '/rivendell/incentives': 'Payroll Self-Serve Processing',
  '/rivendell/incentives/processing': 'Payroll Data and Platform',
  '/rivendell': 'Web Experience',
  '/rivendell/inter-company-invoices/[slug]': 'Tax & Accounting',
  '/rivendell/inter-company-invoices': 'Tax & Accounting',
  '/rivendell/inter-company-invoices/transactions': 'Tax & Accounting',
  '/rivendell/inter-company-invoices/vat-groups': 'Tax & Accounting',
  '/rivendell/invoices/[slug]': 'Billing Platform',
  '/rivendell/knowledge-database/country/[regionSlug]': 'Information Infrastructure',
  '/rivendell/knowledge-database/facts/[name]': 'Information Infrastructure',
  '/rivendell/knowledge-database/facts/schema-editor': 'Information Infrastructure',
  '/rivendell/knowledge-database': 'Information Infrastructure',
  '/rivendell/legal-entities/[slug]/add-signatory': 'Payroll Onboarding',
  '/rivendell/legal-entities/[slug]': 'Payroll Onboarding',
  '/rivendell/legal-entities/[slug]/onboarding-tasks': 'Payroll Onboarding',
  '/rivendell/legal-entities/[slug]/setup-pay-schedule': 'Payroll Onboarding',
  '/rivendell/legal-entities': 'Payroll Onboarding',
  '/rivendell/legal-entities/[slug]/upload-documents': 'Payroll Onboarding',
  '/rivendell/legal-entities/[slug]/employee-upload-documents': 'Payroll Onboarding',
  '/rivendell/legal-entities/templates/[slug]': 'Payroll Onboarding',
  '/rivendell/manage-2fa': 'Identity & Access Management',
  '/rivendell/custom-notifications': ['Web Experience', 'Ai Knowledge Management'],
  '/rivendell/custom-notifications/new': ['Web Experience', 'Ai Knowledge Management'],
  '/rivendell/mobility/generate-document/[slug]': 'EOR XP',
  '/rivendell/mobility/generate-document/[slug]/review': 'EOR XP',
  '/rivendell/mobility': 'EOR XP',
  '/rivendell/mobility/pre-hire-assessment/[slug]': 'EOR XP',
  '/rivendell/mobility/right-to-work/[slug]': 'EOR XP',
  '/rivendell/mobility/right-to-work/[slug]/lifecycle': 'EOR XP',
  '/rivendell/off-payslip-costs/[slug]': null,
  '/rivendell/off-payslip-costs/create': null,
  '/rivendell/off-payslip-costs': null,
  '/rivendell/offboarding/[userSlug]': 'Employee Lifecycle',
  '/rivendell/offboarding': 'Employee Lifecycle',
  '/rivendell/onboarding': null,
  '/rivendell/pay-details/[contractSlug]': [
    'Payroll Data and Platform',
    'Payroll Self-Serve Processing',
  ],
  '/rivendell/pay-elements/bulk/[type]': 'Payroll Data and Platform',
  '/rivendell/pay-elements/create': 'Payroll Data and Platform',
  '/rivendell/pay-elements/create/inline': 'Payroll Data and Platform',
  '/rivendell/pay-elements/edit/[slug]': 'Payroll Data and Platform',
  '/rivendell/payroll-calendars/[slug]/edit': 'Payroll Self-Serve Processing',
  '/rivendell/payroll-calendars/[slug]': 'Payroll Self-Serve Processing',
  '/rivendell/payroll-calendars': 'Payroll Self-Serve Processing',
  '/rivendell/payroll-calendars/new': 'Payroll Self-Serve Processing',
  '/rivendell/payroll-outputs': 'Payroll Data and Platform',
  '/rivendell/payroll-reports': 'Payroll Integrations',
  '/rivendell/payrolls/[slug]/adjustments': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/checks': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/data-changes': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/documents': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/employees': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/expenses': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/files-review': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/files': ['Payroll Data and Platform', 'Payroll Onboarding'],
  '/rivendell/payrolls/[slug]/gross-to-net-reports/[gtn-slug]': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/incentives': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/billing-breakdown': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]': ['Payroll Data and Platform', 'Payroll Onboarding'],
  '/rivendell/payrolls/[slug]/outputs-mapping': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/outputs': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/pay-elements': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/payments': 'Employee Payments',
  '/rivendell/payrolls/[slug]/payslips': 'Payroll Data and Platform',
  '/rivendell/payrolls/[slug]/time-off': 'Payroll Data and Platform',
  '/rivendell/payrolls/bulk': 'Payroll Data and Platform',
  '/rivendell/payrolls': 'Payroll Data and Platform',
  '/rivendell/payslips/[slug]': 'Payroll Data and Platform',
  '/rivendell/payslips': 'Payroll Data and Platform',
  '/rivendell/people/bulk/[type]': 'Payroll Data and Platform',
  '/rivendell/people/convert': 'Payroll Onboarding',
  '/rivendell/people/create': null,
  '/rivendell/people': null,
  '/rivendell/people/new/[productType]/[slug]': 'Payroll Onboarding',
  '/rivendell/pricing': 'Pricing',
  '/rivendell/roles-permissions/[slug]': 'Identity & Access Management',
  '/rivendell/roles-permissions/create': 'Identity & Access Management',
  '/rivendell/service-costs/[slug]': 'Billing Platform',
  '/rivendell/service-costs/create': 'Billing Platform',
  '/rivendell/service-costs/edit/[slug]': 'Billing Platform',
  '/rivendell/service-costs': 'Billing Platform',
  '/rivendell/talent/company-profiles/[companySlug]/edit': 'Remote Talent',
  '/rivendell/talent/company-profiles/[companySlug]': 'Remote Talent',
  '/rivendell/talent': 'Remote Talent',
  '/rivendell/talent/jobs/[jobSlug]/edit': 'Remote Talent',
  '/rivendell/talent/jobs/[jobSlug]': 'Remote Talent',
  '/rivendell/tce-calculator': 'Market Insights',
  '/rivendell/team-plans/[slug]': 'Pricing',
  '/rivendell/team-plans': 'Pricing',
  '/rivendell/time-off/bulk/[type]': 'Time & Attendance',
  '/rivendell/time-off/bulk/requests': 'Time & Attendance',
  '/rivendell/time-off/bulk/requests-history': 'Time & Attendance',
  '/rivendell/time-off': 'Time & Attendance',
  '/rivendell/time-tracking': 'Time & Attendance',
  '/rivendell/users-roles': 'Identity & Access Management',
  '/rivendell/users/[userSlug]': null,
  '/sign-in': 'Identity & Access Management',
  '/sign-in/session-redirect-no-auth': 'Identity & Access Management',
  '/sign-in/setup-2fa-no-auth': 'Identity & Access Management',
  '/sign-in/setup-2fa': 'Identity & Access Management',
  '/sign-in/sso-redirect': 'Identity & Access Management',
  '/sign-in/unlock-account-expired': 'Identity & Access Management',
  '/sign-in/verify-2fa': 'Identity & Access Management',
  '/sign-up': ['Contractor Experience', 'Remote Talent', 'Company and People Foundations'],
  '/sign-up/[registrationFlow]': 'Company and People Foundations',
  '/sso/sign-in': 'Identity & Access Management',
  '/unlock-account': 'Identity & Access Management',
  '/api/legacy-feature-flags': 'Development Experience',
  '/api/feature-flags-tooling': 'Development Experience',
};
