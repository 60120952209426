import type { GetResponse } from '@remote-com/data-layer';
import camelCase from 'lodash/camelCase';

import type { ReportsApi } from '@/src/api/config/employ/reports.types';
import { MonthRangeFilter, AutoCell } from '@/src/components/Table';
import { getCustomFieldCell } from '@/src/domains/employment/employer/customFieldFilterHelpers';
import { sentenceCase } from '@/src/domains/knowledge/facts/utils';
import { formatTimeTaken } from '@/src/domains/reports/helpers';
import { formatPayrollPeriodDate } from '@/src/helpers/date';

import { StatusCell, TimeTakenCell } from './cells';

export const monthColumn = (hasStatus = false) => ({
  Header: 'Month',
  id: 'payoutMonth',
  accessor: ({ payoutMonth }: { payoutMonth: string }) => formatPayrollPeriodDate(payoutMonth),
  Cell: hasStatus ? StatusCell : AutoCell,
  disableSortBy: true,
  Filter: (props: { column: Object }) => <MonthRangeFilter {...props} data-testid="month-filter" />,
});

export const customFieldColumn = ({
  definition,
  options,
}: {
  definition: GetResponse<'/api/v1/employer/custom-field-definitions'>['data']['customFieldDefinitions'][number];
  options?: Record<string, unknown>;
}) => {
  const slug = camelCase(definition.slug);
  const id = `customField${slug}`;
  const normalizedId = `customField${slug[0].toUpperCase()}${slug.slice(1)}`;

  return {
    Header: definition.name,
    id,
    accessor: (columns: ReportsApi.ReportData) => {
      const field = columns[normalizedId] as ReportsApi.CustomField | undefined;
      return field?.value ?? '-';
    },
    Cell: getCustomFieldCell(definition),
    disableSortBy: true,
    disableFilters: true,
    disableExport: true,
    disableInView: true,
    ...options,
  };
};

export const leaveColumn = ({
  definition,
}: {
  definition: { name: string; totalDaysTaken: number };
}) => ({
  Header: sentenceCase(definition.name),
  id: definition.name,
  accessor: 'timeTaken',
  Cell: TimeTakenCell,
  exportData: ({ timeTaken }: { timeTaken: { daysTaken: number; hoursTaken: number } }) =>
    formatTimeTaken(timeTaken.daysTaken, timeTaken.hoursTaken),
  disableSortBy: true,
  disableFilters: true,
});
