import { useGet } from '@remote-com/data-layer';
import capitalize from 'lodash/capitalize';

import { getLegalEntityLabel } from '@/src/domains/billing/helpers';
import { getEmployeeContractLabel } from '@/src/domains/payroll/helpers';
import { buildProjectName } from '@/src/domains/projects/helpers';

const useSelectedNameFromTable = (
  tableProps,
  columnId,
  failedMessageLabel,
  { path: queryPath, slugKey = 'slug' },
  getNameFromResponseFunction,
  searchByNonePlaceholder = 'None',
  getSlugFromFilterValue = (value) => value,
  queryOptions = {}
) => {
  const value = tableProps.tableState.filters?.find((filter) => filter.id === columnId)?.value;
  const slug = getSlugFromFilterValue(value);

  const isSlugValid = Boolean(slug) && slug !== 'none';
  const { isLoading, isIdle, isError, data } = useGet(queryPath, {
    params: { pathParams: slugKey ? { [slugKey]: slug } : {} },
    options: {
      ...queryOptions,
      enabled: isSlugValid && (queryOptions.enabled ?? true),
    },
  });

  if (slug === 'none') {
    return searchByNonePlaceholder;
  }

  if (isIdle) {
    return null;
  }

  if (isLoading) {
    return 'Loading…';
  }

  if (isError) {
    return failedMessageLabel;
  }

  return data ? getNameFromResponseFunction(data) : undefined;
};

/**
 *
 * useSelectedUserNameFromTable
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useSelectedUserNameFromTable = (tableProps, columnId = 'user_slug') => {
  const userName = useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading user',
    { path: '/api/v1/rivendell/users/[slug]', slugKey: 'slug' },
    (userResponse) => userResponse.data.name
  );

  return userName;
};

/**
 *
 * useSelectedEmploymentUserNameFromTable
 *
 * Only available for employers.
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useSelectedEmploymentUserNameFromTable = (
  tableProps,
  columnId = 'employment_slug',
  getSlugFromFilterValue
) => {
  const userName = useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading employee',
    { path: '/api/v1/employer/onboarding/employments/[slug]', slugKey: 'slug' },
    (employmentResponse) => employmentResponse?.data?.user?.name,
    'None',
    getSlugFromFilterValue
  );

  return userName;
};

/**
 *
 * useSelectedCompanyNameFromTable
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useSelectedCompanyNameFromTable = (tableProps, columnId = 'company_slug') => {
  const companyName = useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading company',
    { path: '/api/v1/rivendell/companies/[slug]', slugKey: 'slug' },
    (companyResponse) => companyResponse.data.name
  );

  return companyName;
};

/**
 *
 * useSelectedCountryNameFromTable
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useSelectedCountryNameFromTable = (tableProps, columnId = 'country_slug') => {
  const companyName = useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading country',
    { path: '/api/v1/countries/[slug]', slugKey: 'slug' },
    (countryResponse) => countryResponse.data.name
  );

  return companyName;
};

/**
 *
 * useSelectedPayrollRunNameFromTable
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useSelectedPayrollRunNameFromTable = (tableProps, columnId = 'payroll_run_slug') => {
  const payrollRunName = useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading payroll run',
    { path: '/api/v1/rivendell/payroll-runs/[slug]', slugKey: 'slug' },
    (payrollRun) => payrollRun.data.name,
    'Not in payroll run'
  );

  return payrollRunName;
};

/**
 *
 * useNameFromContractInTable
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useNameFromContractInTable = (tableProps, columnId = 'contract_slug') => {
  const payrollRunName = useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed to load user',
    { path: '/api/v1/rivendell/contracts/[slug]', slugKey: 'slug' },
    (response) => getEmployeeContractLabel(response.data)
  );

  return payrollRunName;
};

export const useSelectedInvoiceFromTable = (tableProps, columnId = 'remote_invoice_slug') => {
  return useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading invoice',
    {
      path: '/api/v1/rivendell/invoice-reports/[slug]',
      slugKey: 'slug',
    },
    ({ data: invoiceReport }) => {
      const { remoteInvoice, creditNote, slug, status } = invoiceReport;

      const invoiceCapitalizedStatus = `${capitalize(status)} - ${slug.slice(0, 6).toUpperCase()}`;
      const formattedNumber = remoteInvoice?.formattedNumber ?? creditNote?.formattedNumber;

      return formattedNumber
        ? `${invoiceCapitalizedStatus} - ${formattedNumber}`
        : invoiceCapitalizedStatus;
    }
  );
};

/**
 *
 * useSelectedCheckedByUserNameFromTable
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useSelectedCheckedByUserNameFromTable = (tableProps, columnId = 'checked_by') => {
  const userName = useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading user',
    { path: '/api/v1/rivendell/users/[slug]', slugKey: 'slug' },
    (userResponse) => userResponse.data.name,
    'Unassigned'
  );

  return userName;
};

/**
 *
 * useSelectedLegalEntityNameFromTable
 *
 * Results of useTableFetcher hook
 * @param {object} tableProps
 * Optional parameter to find table filter value
 * @param {string} columnId
 *
 */
export const useSelectedLegalEntityNameFromTable = (tableProps, columnId = 'legal_entity_slug') => {
  return useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading legal entity',
    { path: '/api/v1/rivendell/legal-entities/[slug]', slugKey: 'slug' },
    (legalEntityResponse) => getLegalEntityLabel(legalEntityResponse.data)
  );
};

export const useSelectedMultiLegalEntityNamesFromTable = (
  tableProps,
  columnId = 'legal_entity_slug'
) => {
  return useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading legal entity',
    { path: '/api/v1/rivendell/legal-entities/[slug]', slugKey: 'slug' },
    (legalEntityResponse) => getLegalEntityLabel(legalEntityResponse?.data)
  );
};

export const useSelectedLegalEntityNameFromTableAsEmployer = (
  tableProps,
  columnId = 'legal_entity_slug'
) => {
  return useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading legal entity',
    { path: '/api/v1/employer/legal-entities/[legalEntitySlug]', slugKey: 'legalEntitySlug' },
    (legalEntityResponse) => getLegalEntityLabel(legalEntityResponse.data)
  );
};

export const useSelectedProjectNameFromTableAsEmployer = (
  tableProps,
  columnId = 'projectSlug',
  queryOptions = {}
) => {
  return useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading project',
    { path: '/api/v1/employer/projects/[projectSlug]', slugKey: 'projectSlug' },
    (res) => buildProjectName(res.data),
    undefined,
    undefined,
    queryOptions
  );
};

export const useSelectedProductNamesFromTable = (tableProps, columnId = 'product_slugs') => {
  const slugs = tableProps.tableState.filters?.find((filter) => filter.id === columnId)?.value;

  return useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading products',
    { path: '/api/v1/rivendell/pricing/products' },
    ({ data: { products = [] } }) => {
      return products
        .filter((product) => slugs.includes(product.slug) || slugs.includes(product.identifier))
        .map((product) => product.name)
        .join(', ');
    }
  );
};

export const useSelectedPayElementNameFromTable = (
  tableProps,
  columnId = 'legalEntityPayElementSlug'
) => {
  return useSelectedNameFromTable(
    tableProps,
    columnId,
    'Failed loading pay element',
    {
      path: '/api/v1/rivendell/legal-entity-pay-elements/[payElementSlug]',
      slugKey: 'payElementSlug',
    },
    (payElementResponse) => payElementResponse.data.name
  );
};
