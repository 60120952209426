import { Tooltip } from '@remote-com/norma';
import type { TooltipProps, ButtonIconProps } from '@remote-com/norma';
import { IconV2OutlineStar } from '@remote-com/norma/icons/IconV2OutlineStar';
import { IconV2SolidStar } from '@remote-com/norma/icons/IconV2SolidStar';
import { useRouter } from 'next/router';
import { forwardRef, useEffect, useRef } from 'react';

import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { PATH_REMOVE_LIST } from '@/src/domains/userSettings/config';
import { useUserSettingsFavoriteMutate } from '@/src/domains/userSettings/hooks/useUserSettingsFavoriteMutate';
import { useOptimisticUpdate } from '@/src/hooks/useOptimisticUpdate';

import { StyledFavoriteButton } from './UserSettingsFavoriteButton.styled';
import { useUserSettingsFavorites } from './UserSettingsFavoritesContext';

export type UserSettingsFavoriteButtonProps = {
  path: string | { pathname: string; query?: string | undefined };
  name: string;
  debounceDelay?: number;
  Trigger?: React.ElementType;
} & Pick<ButtonIconProps, 'size'>;

function stringifyPath(
  path: string | { pathname: string; query?: string | undefined } = ''
): string {
  if (typeof path === 'string') {
    return path;
  }

  const { pathname, query } = path;
  const queryString = query ? `?${new URLSearchParams(query).toString()}` : '';

  // Combine pathname and query string
  return `${pathname}${queryString}`;
}

/**
 * This component is for toggling the favorite status of a route.
 *
 * We use a local state to optimistically update the UI and then debounce the request
 * once the user has stopped interacting with the button. This is to prevent a feedback delay
 * between the user clicking the button and the UI updating.
 */
// I want to accept a custom icon that defaults to the star icon
export function UserSettingsFavoriteButton({
  path,
  name,
  debounceDelay = 1000,
  size,
  Trigger = StyledFavoriteButton,
}: UserSettingsFavoriteButtonProps) {
  const router = useRouter();
  const currentPath = router?.pathname;
  const isSidebarFavoritesEnabled = useIsFeatureFlagEnabled('hackathon_sidebar_favorites');
  const pathString = stringifyPath(path);
  const isInRemoveList = PATH_REMOVE_LIST.includes(pathString || currentPath);
  const { isPathFavorited, getFavoriteSlug, userSettingsFavorites } = useUserSettingsFavorites();
  const { setFavorited, deleteFavorited } = useUserSettingsFavoriteMutate({});
  const triggerRef = useRef<HTMLButtonElement>(null);

  const favoriteSlug = getFavoriteSlug(pathString);

  const { value: localIsFavorited, updateValue: setLocalIsFavorited } = useOptimisticUpdate({
    initialValue: isPathFavorited(pathString),
    currentValue: isPathFavorited(pathString),
    onUpdate: async (newValue) => {
      if (newValue) {
        await setFavorited.mutateAsync({
          bodyParams: {
            path: pathString,
            name,
            sortOrder: userSettingsFavorites?.length ?? 0,
          },
        });
      } else if (favoriteSlug) {
        await deleteFavorited.mutateAsync({ pathParams: { slug: favoriteSlug } });
      }
    },
    debounceDelay,
  });

  useEffect(() => {
    setLocalIsFavorited(isPathFavorited(pathString));
  }, [isPathFavorited, setLocalIsFavorited, pathString]);

  const toggleIsFavorited = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setLocalIsFavorited(!localIsFavorited);
  };

  const tooltipProps: Pick<TooltipProps, 'type' | 'animationDelay' | 'interactive'> = {
    type: 'caption',
    animationDelay: 200,
    interactive: false,
  };

  const Favorited = forwardRef<HTMLButtonElement, ButtonIconProps>((props, ref) => (
    <Tooltip
      label="Remove from favorites"
      {...tooltipProps}
      triggerTarget={triggerRef?.current}
      ref={ref}
    >
      <IconV2SolidStar {...props} />
    </Tooltip>
  ));

  const NotFavorited = forwardRef<HTMLButtonElement, ButtonIconProps>((props, ref) => (
    <Tooltip
      label="Add to favorites"
      {...tooltipProps}
      triggerTarget={triggerRef?.current}
      ref={ref}
    >
      <IconV2OutlineStar {...props} />
    </Tooltip>
  ));

  return isSidebarFavoritesEnabled && !isInRemoveList ? (
    <Trigger
      isFavorited={localIsFavorited}
      onClick={toggleIsFavorited}
      aria-label={localIsFavorited ? 'Remove from favorites' : 'Add to favorites'}
      Icon={localIsFavorited ? Favorited : NotFavorited}
      size={size}
      ref={triggerRef}
    />
  ) : null;
}
