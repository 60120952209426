import { differenceInDays, differenceInMonths, isFuture, isPast, parseISO } from 'date-fns';
import capitalize from 'lodash/capitalize';
import kebabCase from 'lodash/kebabCase';
import type { ValueOf } from 'type-fest';

import type { LegalEntityApi } from '@/src/api/config/employ/legalEntity.types';
import type { Currency } from '@/src/api/config/employ/shared.types';
import { isFreeTrialActive } from '@/src/domains/paidContractorProduct/employer/helpers';
import { getPricingPlanAttributes } from '@/src/domains/pricing/admin/cards/adminPricingPlanCard/utils';
import { type BillingCycle } from '@/src/domains/pricing/admin/cards/eor/EORPlanDrawer/utils';
import {
  billingCycleTypes,
  billingFeeValues,
  modifiedPricingPlanStatus,
  pricingPlanStatus,
  pricingPlanStatusBadgeColors,
  pricingPlanStatusLabels,
} from '@/src/domains/pricing/admin/constants';
import { PRODUCT_PRICING_FREQUENCY, PRODUCT_TIERS } from '@/src/domains/pricing/constants';
import {
  isProductContractorPlus,
  isProductContractorProtect,
  isProductEOR,
  isProductEORAnnual,
  isProductGlobalPayroll,
  isProductRecruit,
} from '@/src/domains/pricing/helpers';
import {
  eorPlanProductTypeIdentifiers,
  pricingPlanIdentifiers,
  pricingPlanProductTypes,
  pricingRevisions,
  pricingRevisionValues,
  recruitPlanTypes,
} from '@/src/domains/pricingPlans/common/constants';
import type {
  AnnualPlanCreditStatus,
  CompanyLegalEntity,
  EORPlanProductType,
  ModifiedPricingPlan,
  ModifiedPricingPlanBillableEntity,
  ModifiedPricingPlanStatus,
  PricingPlan,
  PricingPlanProductAccess,
  PricingPlanProductType,
  PricingPlanStatus,
} from '@/src/domains/pricingPlans/common/PricingPlans.types';
import { friendlyMoneyWithCurrencyCode } from '@/src/helpers/currency';
import {
  convertUtcStringToDateObj,
  formatFullMonth,
  formatFullMonthDayYear,
  formatShortDayShortMonthYear,
} from '@/src/helpers/date';
import { convertToPercent } from '@/src/helpers/discounts';
import { staticAssetUrl } from '@/src/helpers/general';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

export const getDefaultLegalEntity = (entities: CompanyLegalEntity[]) => {
  return entities?.find((entity) => entity?.isDefault) as CompanyLegalEntity;
};

export const modifyPricingPlanByIdentifier = (
  plan: PricingPlan,
  entities?: CompanyLegalEntity[]
) => {
  const { product } = plan.productPrice;
  const productPriceName = plan.productPrice.name;

  const all = product.identifier.split(':');
  let productType = all[4] as PricingPlanProductType;
  let planType = all[4] as ModifiedPricingPlan['planType'];
  const tier = all[5] as ModifiedPricingPlan['tier'];

  const isEORMonthly = isProductEOR(product, {
    frequency: PRODUCT_PRICING_FREQUENCY.MONTHLY,
  });

  if (isProductEORAnnual(product) && productPriceName.includes('Non-Core')) {
    planType = pricingPlanIdentifiers.EOR_LOW_COST;
  }

  if (isEORMonthly) {
    planType = pricingPlanIdentifiers.EOR_MONTHLY;

    if (productPriceName.includes('$399') || productPriceName.includes('Non-Core')) {
      planType = pricingPlanIdentifiers.EOR_MONTHLY_LOW_INCOME;
    }
  }

  if (isProductGlobalPayroll(product)) {
    productType = pricingPlanProductTypes.GLOBAL_PAYROLL;
    planType = pricingPlanIdentifiers.GLOBAL_PAYROLL;
  }

  if (isProductContractorPlus(product)) {
    planType = pricingPlanIdentifiers.CONTRACTOR_PLUS;
  }

  if (isProductContractorProtect(product)) {
    planType = pricingPlanIdentifiers.CONTRACTOR_OF_RECORD;
  }

  if (isProductRecruit(product)) {
    productType = pricingPlanProductTypes.RECRUIT;

    if (productPriceName === recruitPlanTypes.FREE_TRIAL) {
      planType = pricingPlanIdentifiers.RECRUIT_FREE_TRIAL;
    }

    if (productPriceName === recruitPlanTypes.ESSENTIAL) {
      planType = pricingPlanIdentifiers.RECRUIT_ESSENTIAL;
    }

    if (productPriceName === recruitPlanTypes.GROWTH) {
      planType = pricingPlanIdentifiers.RECRUIT_GROWTH;
    }

    if (productPriceName === recruitPlanTypes.STRATEGIC) {
      planType = pricingPlanIdentifiers.RECRUIT_STRATEGIC;
    }
  }

  return {
    ...plan,
    productType,
    planType,
    tier,
    // A billable entity is a billing-specific representation of a legal entity. They are used to establish the relationship between a pricing plan and its associated legal entity
    // Backend returns billable entity information and to identify the legal entity, the entityIdentifier is included.
    // To get the full legal entity details including entity name, country etc, we get the list of company entities and compare the entityIdentifier to get the full entity details.
    // The current implementation below overrides the billableEntity with the full legal entity details which is a bit confusing
    // To fix, we should rename the billableEntity to legalEntity and keep the billableEntity as is
    // TODO: Rename billableEntity to legalEntity and leave billableEntity as is
    billableEntity: entities?.find(
      (entity) => entity?.slug === plan?.billableEntity?.entityIdentifier
    ),
    durationInYears: plan?.durationInYears ?? 1,
  } as ModifiedPricingPlan;
};

export const getPricingPlanLabels = (productType: PricingPlanProductType) => {
  return {
    eor: {
      monthlyFee: 'Base monthly fee per employee',
    },
    contractor: {
      monthlyFee: 'Base monthly fee per contractor',
      totalInvoice: 'Estimated total this cycle',
    },
    global_payroll: {
      monthlyFee: 'Monthly cost per employee',
      totalInvoice: 'Estimated total this cycle',
    },
    remote_talent: {
      totalInvoice: 'Estimated total this cycle',
    },
    recruit: {},
  }[productType];
};

export const getPricingPlanAssets = (plan: ModifiedPricingPlan) => {
  const { planType, productType } = plan;

  const eorInfo = () => {
    return {
      tone: 'brand',
      globeBackground: staticAssetUrl('/images/eor.png'),
      infoBackground: staticAssetUrl('/images/eor-info.png'),
      infoImage: staticAssetUrl('/images/eor-info-image.png'),
    } as const;
  };

  const contractorInfo = () => {
    const { isContractorPlus } = getPricingPlanAttributes(planType);

    return {
      tone: isContractorPlus ? 'fuchsia' : 'purple',
      globeBackground: staticAssetUrl(
        isContractorPlus
          ? '/images/contractors/contractor-mg-plus.png'
          : '/images/contractors/contractor-mg.png'
      ),
      infoBackground: '',
      infoImage: '',
    } as const;
  };

  const recruitInfo = () => {
    return {
      tone: 'green',
      globeBackground: '',
      infoBackground: staticAssetUrl('/images/recruit-info.png'),
      infoImage: staticAssetUrl('/images/recruit-info-image.png'),
    } as const;
  };

  return {
    eor: eorInfo(),
    contractor: contractorInfo(),
    global_payroll: {
      tone: 'yellow',
      globeBackground: '',
      infoBackground: '',
      infoImage: '',
    } as const,
    remote_talent: {
      tone: 'green',
      globeBackground: '',
      infoBackground: '',
      infoImage: '',
    } as const,
    recruit: recruitInfo(),
  }[productType];
};

export const getSubscriptionInfoTestIds = (name: string) => {
  return {
    labelId: `${kebabCase(name)}-label`,
    valueId: `${kebabCase(name)}-value`,
    rowId: `${kebabCase(name)}-row`,
    unitId: `${kebabCase(name)}-unit`,
  };
};

export const getProductDescription = (plan: ModifiedPricingPlan) => {
  const productFrequency = capitalize(plan.productPrice.product.frequency);

  const eorCreditTitle = `${Number(plan?.durationInYears)} Year credit plan`;
  const frequencyTitle = `${productFrequency} plan`;

  const daysLeft = differenceInDays(new Date(plan?.freeTrial?.endDate!), new Date()) + 1;

  const recruitFreeTrialTitle = isFreeTrialActive(plan)
    ? `${getSingularPluralUnit(daysLeft, 'day', 'days', false)} remaining`
    : '';

  return {
    eor: eorCreditTitle,
    eor_low_cost: eorCreditTitle,
    eor_monthly: frequencyTitle,
    eor_monthly_low_income: frequencyTitle,
    contractor: `${productFrequency} billing based on activity`,
    contractor_plus: `Fixed ${productFrequency} billing`,
    contractor_of_record: frequencyTitle,
    global_payroll: frequencyTitle,
    remote_talent: `Fixed price per job`,
    recruit_free_trial: recruitFreeTrialTitle,
    recruit_essential: frequencyTitle,
    recruit_growth: frequencyTitle,
    recruit_strategic: frequencyTitle,
  }[plan.planType];
};

// The passed `summaryPeriod` is in `MM-yyyy` format.
export const formatSubscriptionSummaryPeriod = (summaryPeriod: string) => {
  return formatFullMonth(convertUtcStringToDateObj(summaryPeriod) as Date);
};

export const getAnnualPlanCreditStatus = ({
  noOfEmployees,
  monthsLeft,
  availableCredits,
}: {
  noOfEmployees: number;
  monthsLeft: number;
  availableCredits: number;
}): AnnualPlanCreditStatus => {
  const seatsNeededToCompleteAnnualPlan = noOfEmployees * monthsLeft;

  if (availableCredits === 0) {
    return 'no-credits-available';
  }

  if (seatsNeededToCompleteAnnualPlan <= availableCredits) {
    return 'credits-available';
  }

  return 'limited-credits-available';
};

export const getProductTitle = ({
  productName,
  withPrefix = true,
  prefix = 'EOR',
  isEORMonthly = false,
}: {
  productName: string;
  prefix?: string;
  withPrefix?: boolean;
  isEORMonthly?: boolean;
}) => {
  let product = productName.includes('Non-Core') ? 'Low-income' : 'Core';

  if (isEORMonthly) {
    product =
      productName.includes('$399') || productName.includes('Non-Core') ? 'Low-income' : 'Core';
  }

  return withPrefix ? `${prefix} ${product}` : product;
};

export const pricingPlanMoneyDisplay = (value: number | null, currency?: Currency) =>
  friendlyMoneyWithCurrencyCode(value, currency, 'right');

const calculateDiscount = (plan: ModifiedPricingPlan) => {
  const { amount: unitPrice, currency } = plan.unitPrice;
  const basePrice = plan.basePrice.amount;
  const selectedBillingCycle = plan?.billingFrequency as BillingCycle;
  const totalAmount = unitPrice * Number(plan?.totalSeats);

  const hasBillingFee =
    selectedBillingCycle === billingCycleTypes.MONTHLY_WITH_EXTRA_FEE ||
    selectedBillingCycle === billingCycleTypes.QUARTERLY_WITH_EXTRA_FEE;

  const billingFeePercentageValue =
    selectedBillingCycle === billingCycleTypes.MONTHLY_WITH_EXTRA_FEE
      ? billingFeeValues.MONTHLY
      : billingFeeValues.QUARTERLY;

  const billingFeeActualValue = (totalAmount * billingFeePercentageValue) / 100;

  const discountAmount = basePrice - unitPrice;

  const hasDiscount = discountAmount > 0;
  const discountPercentage = convertToPercent({ value: discountAmount / basePrice });

  return {
    discountAmount,
    hasDiscount,
    discountPercentage,
    billingFee: {
      value: hasBillingFee ? billingFeeActualValue : 0,
      percentage: hasBillingFee ? billingFeePercentageValue : 0,
      formatted: pricingPlanMoneyDisplay(hasBillingFee ? billingFeeActualValue : 0, currency),
    },
  };
};

export const calculateCost = (plan: ModifiedPricingPlan) => {
  const rawBasePrice = plan.basePrice.amount;

  const { currency, amount } = plan.unitPrice;

  const { discountAmount, hasDiscount, discountPercentage, billingFee } = calculateDiscount(plan);

  const costPerEmployee = pricingPlanMoneyDisplay(amount, currency);
  const basePrice = pricingPlanMoneyDisplay(rawBasePrice, currency);

  const estimatedTotal = pricingPlanMoneyDisplay((plan.billableUnits || 0) * amount, currency);
  const totalAmountBilled = pricingPlanMoneyDisplay(plan?.totalAmount?.amount as number, currency);

  const formattedDiscountAmount = pricingPlanMoneyDisplay(discountAmount, currency);

  return {
    discountAmount,
    formattedDiscountAmount,
    /** @deprecated - use costPerEmployeeNew instead */
    costPerEmployee,
    costPerEmployeeNew: {
      value: amount,
      formatted: pricingPlanMoneyDisplay(amount, currency),
    },
    hasDiscount,
    /** @deprecated - use basePriceNew instead */
    basePrice,
    basePriceNew: {
      value: rawBasePrice,
      formatted: pricingPlanMoneyDisplay(rawBasePrice, currency),
    },
    estimatedTotal,
    totalAmountBilled,
    discountPercentage,
    currency,
    billingFee,
  };
};

export const getAnnualPlanDetails = (plan: ModifiedPricingPlan) => {
  const startDate = new Date(plan.startDate);
  const endDate = new Date(plan.endDate!);
  const today = new Date();

  const hasPlanStarted = isPast(startDate);
  const totalMonths = 12 * Number(plan.durationInYears);

  const monthsLeft = differenceInMonths(endDate, hasPlanStarted ? today : startDate);

  const noOfEmployees = plan?.totalUnits || 0;
  const totalCredits = plan?.totalSeats || 0;
  const usedCredits = plan?.usedSeats || 0;
  const availableCredits = totalCredits - usedCredits;

  const excessCredits = Math.max(totalCredits - noOfEmployees * totalMonths, 0);

  return {
    excessCredits,
    monthsLeft,
    availableCredits,
    noOfEmployees,
    creditStatus: getAnnualPlanCreditStatus({
      noOfEmployees,
      monthsLeft,
      availableCredits,
    }),
  };
};

export const calculateDiscountCostFromPercentage = (props: {
  percentage?: number;
  amount: number;
}) => {
  const { percentage, amount } = props || {};

  if (amount && percentage && percentage <= 1) {
    return amount - amount * percentage;
  }

  return amount;
};

export const isNewRevision = (revision?: string) => {
  return Boolean(revision) && revision !== pricingRevisionValues[2022];
};

export const isLegacyRevision = (revision?: string) => {
  return Boolean(revision) && revision === pricingRevisionValues[2022];
};

export const getModifiedPricingPlanStatus = (plan: ModifiedPricingPlan) => {
  const isStartDatePast = isPast(parseISO(plan.startDate));
  const isEndDateFutureOrDoesNotExist = isFuture(parseISO(plan.endDate!)) || plan.endDate === null;
  // We don't have an active status for pricing plans so we need to derive it so we can hide/display certain information.
  // The plan is active if it has started (i.e. in the past) and has no end date or the end date is in the future.
  const isPlanActive = isStartDatePast && isEndDateFutureOrDoesNotExist;
  const isEORAnnual = isProductEORAnnual(plan.productPrice.product);

  if (!isEORAnnual && plan?.status === pricingPlanStatus.ACCEPTED) {
    return modifiedPricingPlanStatus.ACTIVE;
  }

  if (isEORAnnual && plan?.status === pricingPlanStatus.ACCEPTED && isPlanActive) {
    return modifiedPricingPlanStatus.ACTIVE;
  }

  return plan.status;
};

export const getPricingPlanStatusLabel = ({
  planStatus,
}: {
  planStatus: PricingPlanStatus | ModifiedPricingPlanStatus;
}) => {
  return {
    label: pricingPlanStatusLabels[planStatus],
    color: pricingPlanStatusBadgeColors[planStatus],
  };
};

export const isModifiedPricingPlanEOR = (
  planType: ModifiedPricingPlan['planType'],
  options?: {
    frequency?: ValueOf<typeof PRODUCT_PRICING_FREQUENCY>;
    productType?: EORPlanProductType;
  }
) => {
  const isMonthly =
    planType === pricingPlanIdentifiers.EOR_MONTHLY ||
    planType === pricingPlanIdentifiers.EOR_MONTHLY_LOW_INCOME;

  const isAnnual =
    planType === pricingPlanIdentifiers.EOR || planType === pricingPlanIdentifiers.EOR_LOW_COST;

  const isAnnualCore = planType === pricingPlanIdentifiers.EOR;
  const isAnnualLowIncome = planType === pricingPlanIdentifiers.EOR_LOW_COST;
  const isMonthlyCore = planType === pricingPlanIdentifiers.EOR_MONTHLY;
  const isMonthlyLowIncome = planType === pricingPlanIdentifiers.EOR_MONTHLY_LOW_INCOME;

  if (options?.frequency === PRODUCT_PRICING_FREQUENCY.MONTHLY) {
    if (options?.productType === eorPlanProductTypeIdentifiers.CORE) {
      return isMonthlyCore;
    }

    if (options?.productType === eorPlanProductTypeIdentifiers.LOW_INCOME) {
      return isMonthlyLowIncome;
    }
    return isMonthly;
  }

  if (options?.frequency === PRODUCT_PRICING_FREQUENCY.ANNUAL) {
    if (options?.productType === eorPlanProductTypeIdentifiers.CORE) {
      return isAnnualCore;
    }

    if (options?.productType === eorPlanProductTypeIdentifiers.LOW_INCOME) {
      return isAnnualLowIncome;
    }

    return isAnnual;
  }

  return isAnnual || isMonthly;
};

export const isModifiedPricingPlanContractor = (
  planType: ModifiedPricingPlan['planType'],
  options?: {
    tier?: ModifiedPricingPlan['tier'];
  }
) => {
  if (options?.tier === PRODUCT_TIERS.PLUS) {
    return planType === pricingPlanIdentifiers.CONTRACTOR_PLUS;
  }

  if (options?.tier === PRODUCT_TIERS.STANDARD) {
    return planType === pricingPlanIdentifiers.CONTRACTOR;
  }

  return (
    planType === pricingPlanIdentifiers.CONTRACTOR ||
    planType === pricingPlanIdentifiers.CONTRACTOR_PLUS
  );
};

export const isModifiedPricingPlanContractorOfRecord = (
  planType: ModifiedPricingPlan['planType']
) => {
  return planType === pricingPlanIdentifiers.CONTRACTOR_OF_RECORD;
};

export const isModifiedPricingPlanRemotePayroll = (planType: ModifiedPricingPlan['planType']) => {
  return planType === pricingPlanIdentifiers.GLOBAL_PAYROLL;
};

export const isModifiedPricingPlanRemoteTalent = (planType: ModifiedPricingPlan['planType']) => {
  return planType === pricingPlanIdentifiers.REMOTE_TALENT;
};

export const isModifiedPricingPlanRecruit = (
  planType: ModifiedPricingPlan['planType'],
  options?: {
    type: ValueOf<typeof recruitPlanTypes>;
  }
) => {
  if (options) {
    const { type } = options;

    switch (type) {
      case recruitPlanTypes.FREE_TRIAL:
        return planType === pricingPlanIdentifiers.RECRUIT_FREE_TRIAL;
      case recruitPlanTypes.ESSENTIAL:
        return planType === pricingPlanIdentifiers.RECRUIT_ESSENTIAL;
      case recruitPlanTypes.GROWTH:
        return planType === pricingPlanIdentifiers.RECRUIT_GROWTH;
      case recruitPlanTypes.STRATEGIC:
        return planType === pricingPlanIdentifiers.RECRUIT_STRATEGIC;
      default:
        return false;
    }
  }

  return (
    planType === pricingPlanIdentifiers.RECRUIT_FREE_TRIAL ||
    planType === pricingPlanIdentifiers.RECRUIT_ESSENTIAL ||
    planType === pricingPlanIdentifiers.RECRUIT_GROWTH ||
    planType === pricingPlanIdentifiers.RECRUIT_STRATEGIC
  );
};

export const getLatestPricingRevision = () => {
  return pricingRevisions.find((revision) => revision.isLatest);
};

export const modifyLegalEntity = (entity: LegalEntityApi.LegalEntity) => {
  return {
    slug: entity?.slug,
    name: entity?.name,
    desiredCurrency: entity?.desiredCurrency,
    localCurrency: entity?.localCurrency,
    country: {
      name: entity?.address.country.name,
      slug: entity?.address.country.slug,
      code: entity?.address.country.code,
      features: entity?.address.country.features,
    },
    isDefault: entity?.isDefault,
    status: entity?.status,
    settings: entity?.settings,
  } as CompanyLegalEntity;
};

export const getLegalEntityCurrencyBySlug = ({
  entitySlug,
  entities,
}: {
  entitySlug: string;
  entities: CompanyLegalEntity[];
}) => {
  const entity = entities?.find((entityInfo) => entityInfo?.slug === entitySlug);

  return entity?.desiredCurrency;
};

export const sortEntitiesByDefaultLegalEntity = (entities: ModifiedPricingPlanBillableEntity[]) => {
  const defaultEntity = getDefaultLegalEntity(entities);

  const entityListWithoutDefault =
    entities?.filter((entity) => entity?.slug !== defaultEntity?.slug) || [];

  return [defaultEntity, ...entityListWithoutDefault];
};

export const getFrequencyAttributes = (frequency: ValueOf<typeof PRODUCT_PRICING_FREQUENCY>) => {
  const isAnnual = frequency === PRODUCT_PRICING_FREQUENCY.ANNUAL;
  const isMonthly = frequency === PRODUCT_PRICING_FREQUENCY.MONTHLY;

  return {
    isAnnual,
    isMonthly,
  };
};

export const sortPricingPlansByCreatedDate = (plans?: ModifiedPricingPlan[]) => {
  return plans?.sort((a, b) => {
    return new Date(a.insertedAt).getTime() - new Date(b.insertedAt).getTime();
  });
};

export const getEmploymentPlanWithCorrectEntityDetails = (
  plan: ModifiedPricingPlan,
  managementFeeCurrencyCode: string
) => {
  const isPlanWithDifferentCurrency = plan?.unitPrice?.currency?.code !== managementFeeCurrencyCode;

  if (!isPlanWithDifferentCurrency || !plan?.pricesByEntity) return plan;

  const entityInfo = plan?.pricesByEntity?.find((entity) => {
    return entity?.basePrice?.currency?.code === managementFeeCurrencyCode;
  });

  if (!entityInfo) return plan;

  return {
    ...plan,
    basePrice: entityInfo?.basePrice,
    unitPrice: {
      amount: entityInfo?.prices?.[0]?.amount,
      currency: entityInfo?.basePrice?.currency,
    },
    companyPrices: entityInfo?.prices,
  };
};

export const getPricingPlanAttributesByProductType = (productType: PricingPlanProductType) => {
  const isEOR = productType === pricingPlanProductTypes.EOR;
  const isRemotePayroll = productType === pricingPlanProductTypes.GLOBAL_PAYROLL;
  const isContractor = productType === pricingPlanProductTypes.CONTRACTOR;
  const isRecruit = productType === pricingPlanProductTypes.RECRUIT;
  const isRemoteTalent = productType === pricingPlanProductTypes.REMOTE_TALENT;

  return {
    isEOR,
    isRemotePayroll,
    isContractor,
    isRecruit,
    isRemoteTalent,
  };
};

export const getTiersInfo = ({
  productType,
  tiers,
  unitPriceAmount,
  currency,
}: {
  tiers: {
    amount: number;
    lowerBound: number;
    upperBound: number | null;
  }[];
  productType: PricingPlanProductType;
  currency: Currency;
  unitPriceAmount: number;
}) => {
  const isTiered = tiers?.length > 1;
  const currentTier = tiers?.findIndex((price) => price.amount === unitPriceAmount) + 1;

  return isTiered
    ? [
        ...tiers?.map((price, index) => {
          const tier = index + 1;
          const lowerBoundLabel = price.lowerBound;
          const upperBoundLabel = price.upperBound ? `-${price.upperBound}` : '+';
          const peopleLabel = getPricingPlanAttributesByProductType(productType).isEOR
            ? 'rate per employee'
            : 'price per contractor';

          return {
            title: `Tier ${tier} ${peopleLabel} (${lowerBoundLabel}${upperBoundLabel})`,
            value: pricingPlanMoneyDisplay(price.amount, currency),
          };
        }),

        {
          title: 'Current tier',
          value: `Tier ${currentTier}`,
        },
      ]
    : [];
};

export const pricingPlanDateDisplay = (
  value: Parameters<typeof formatFullMonthDayYear>[0],
  format: 'short' | 'long' = 'long'
) => (format === 'long' ? formatFullMonthDayYear(value) : formatShortDayShortMonthYear(value));

export const getRecruitUsageDetails = (plan: ModifiedPricingPlan) => {
  const defaultValues = { used: 0, limit: 0, providedByPlans: [] };

  if (!plan?.productAccess || Object.keys(plan.productAccess).length === 0)
    return {
      jobPostsUsed: {
        values: defaultValues,
        formatted: 0,
      },
      talentInsightSearchesUsed: {
        values: defaultValues,
        formatted: 0,
      },
    };

  const { talentJobAd, talentInsightSearch } = plan.productAccess as PricingPlanProductAccess;

  const { isRecruitGrowth } = getPricingPlanAttributes(plan.planType);

  const talentInsightSearchLimit = isRecruitGrowth ? 'Unlimited' : talentInsightSearch.limit;

  return {
    jobPostsUsed: {
      values: talentJobAd,
      formatted: `${talentJobAd.used} of ${talentJobAd.limit}`,
    },
    talentInsightSearchesUsed: {
      values: {
        ...talentInsightSearch,
        limit: talentInsightSearchLimit,
      },
      formatted: `${talentInsightSearch.used} of ${talentInsightSearchLimit}`,
    },
  };
};
