import type { ValueOf } from 'type-fest';

export const CONTRACT_ORIGIN_TYPES = {
  REMOTE_CONTRACT: 'remote_contract',
  PROVIDED_BY_CUSTOMER: 'provided_by_customer',
  CUSTOM_REMOTE_CONTRACT: 'custom_remote_contract',
} as const;

export type ContractOrigin = ValueOf<typeof CONTRACT_ORIGIN_TYPES>;

export const REMOTE_CONTRACT_ORIGIN_TYPES = new Set<ContractOrigin>([
  CONTRACT_ORIGIN_TYPES.REMOTE_CONTRACT,
  CONTRACT_ORIGIN_TYPES.CUSTOM_REMOTE_CONTRACT,
]);

export const CUSTOMER_CONTRACT_ORIGIN_TYPES = new Set<ContractOrigin>([
  CONTRACT_ORIGIN_TYPES.PROVIDED_BY_CUSTOMER,
]);

export const PURCHASE_TYPES = {
  PAYG: 'PAYG',
  TEAM_PLAN: 'TEAM_PLAN',
  PREMIUM_PLAN: 'PREMIUM_PLAN',
  SLA_TEAM_PLAN: 'SLA_TEAM_PLAN',
  CONTRACTOR_STANDARD: 'CONTRACTOR_STANDARD',
  CONTRACTOR_PLUS: 'CONTRACTOR_PLUS',
  CONTRACTOR_PROTECT: 'CONTRACTOR_PROTECT',
  EOR_ANNUAL_PLAN: 'EOR_ANNUAL_PLAN',
} as const;

export type PurchaseType = ValueOf<typeof PURCHASE_TYPES>;

export type ContractorPurchaseType = Extract<
  PurchaseType,
  'CONTRACTOR_STANDARD' | 'CONTRACTOR_PLUS' | 'CONTRACTOR_PROTECT'
>;
