import type { API } from '@/src/api/config/api.types';
import type { ColumnType } from '@/src/components/Table/types.ts';

type Filters = API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters'];
type GeneratedFilter = {
  id: string;
  value: boolean | number | string | string[];
};

export const getApplicableFilters = (
  filters: Filters,
  tableColumns: Array<ColumnType>
): Filters => {
  return filters
    .map<GeneratedFilter | null>((f) => {
      const value = f.value === 'false' ? false : f.value;
      const camelCaseId = f.id.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
      const lowerCaseId = f.id.toLowerCase();
      const dataPrefixedId = `data-${f.id.toLowerCase()}`;
      const col = tableColumns.find((c) => {
        return (
          c.id === f.id || c.id === camelCaseId || c.id === lowerCaseId || c.id === dataPrefixedId
        );
      });

      if (col?.id && col.canFilter) {
        return {
          id: col.id,
          value,
        };
      }

      return null;
    })
    .filter((f): f is GeneratedFilter => f !== null);
};

export const mergeFilters = (currentFilters: Filters, newFilters: Filters) => {
  const filterMap = new Map();

  if (currentFilters) {
    currentFilters.forEach((filter) => {
      filterMap.set(filter.id, filter);
    });
  }

  // Add new filters, overwriting if the same id exists
  newFilters.forEach((filter) => {
    filterMap.set(filter.id, filter);
  });

  return Array.from(filterMap.values());
};
