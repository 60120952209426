import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';

import { Box, Stack } from '../../layout';
import type { ColorPropsWithCustomPalette } from '../../utils/types';
import { Text } from '../text';

import type {
  HeadingIconColor,
  HeadingIconFontSize,
  HeadingIconShape,
  HeadingIconSize,
} from './HeadingIcon';

export const headingIconColor: {
  [key in HeadingIconColor]: {
    background: ColorPropsWithCustomPalette['color'] & string;
    text: ColorPropsWithCustomPalette['color'] & string;
  };
} = {
  bayoux: { background: 'grey.200', text: 'grey.600' },
  fuchsia: { background: 'fuchsia.200', text: 'fuchsia.800' },
  cerise: { background: 'pink.200', text: 'pink.600' },
  cerulean: { background: 'blueLight.200', text: 'blueLight.500' },
  darkTurquoise: { background: 'cyan.100', text: 'cyan.700' },
  deYork: { background: 'green.100', text: 'green.500' },
  irisBlue: { background: 'brand.50', text: 'brand.600' },
  lynch: { background: 'grey.50', text: 'grey.500' },
  yellow: { background: 'yellow.100', text: 'yellow.400' },
  radiance: { background: 'brand.100', text: 'brand.500' },
  redPink: { background: 'red.200', text: 'red.600' },
  salmon: { background: 'orange.100', text: 'orange.400' },
  purple: { background: 'purple.100', text: 'purple.600' },
  green: { background: 'green.100', text: 'green.600' },
  cyan: { background: 'cyan.100', text: 'cyan.600' },
};

const iconSizeInPixels: {
  [key in HeadingIconSize]: number;
} = {
  sm: 16,
  md: 20,
  lg: 32,
};

const containerSizeInPixels: {
  [key in HeadingIconSize]: number;
} = {
  sm: 28,
  md: 36,
  lg: 48,
};

export const HeadingContainer = styled(Stack).attrs({
  direction: 'row',
  forwardedAs: 'header',
  gap: 4,
})`
  align-items: center;
`;

const defaultMinWidthInPixels: number = 36;

type HeadingIconContainerProps = {
  $color: HeadingIconColor;
  $shape: HeadingIconShape;
  $size: HeadingIconSize;
};

export const HeadingIconContainer = styled(Box).attrs(({ $color }: HeadingIconContainerProps) => ({
  bg: headingIconColor[$color].background,
  color: headingIconColor[$color].text,
}))<HeadingIconContainerProps>`
  ${({ $size, $shape }) => css`
    border-radius: ${$shape === 'circle' ? '50%' : '10px'};
    display: grid;
    flex-shrink: 0;
    height: ${containerSizeInPixels[$size] || defaultMinWidthInPixels}px;
    place-items: center;
    width: ${containerSizeInPixels[$size] || defaultMinWidthInPixels}px;
  `}
`;

type IconStyledProps = {
  $color: HeadingIconColor;
  $size: HeadingIconSize;
};

export const IconStyled = styled.svg<IconStyledProps>`
  ${({ $size }) => css`
    width: ${iconSizeInPixels[$size]}px;
  `}
`;

const headingTextSizes: {
  [key in HeadingIconFontSize]: keyof DefaultTheme['typography'];
} = {
  sm: 'smMedium',
  md: 'baseMedium',
  lg: 'lgMedium',
};

type HeadingTextProps = {
  $size: HeadingIconFontSize;
};

export const HeadingText = styled(Text).attrs(({ $size }: HeadingTextProps) => ({
  forwardedAs: 'h2',
  variant: headingTextSizes[$size],
}))<HeadingTextProps>``;
