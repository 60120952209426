import { useGetInfinite } from '@remote-com/data-layer';
import { InputSelectComponents } from '@remote-com/norma';
import flatMap from 'lodash/flatMap';
import { useEffect, useState, useRef, useMemo, forwardRef } from 'react';
import { components } from 'react-select';
import { ThemeConsumer, ThemeProvider } from 'styled-components';

import { SelectField } from '@/src/components/Ui/Form/formikIntegration/SelectField';
import { isTest } from '@/src/helpers/general';
import { useDebounce } from '@/src/hooks/useDebounce';

const EMPTY_SEARCH_QUERY = '';
const TYPING_DEBOUNCE_TIMEOUT = isTest() ? 0 : 250;

/**
 * The only way to change the native properties of the input element in react-select is to pass a
 * custom input component with different props. The reason is these properties are hardcoded in the
 * input specification in the library.
 *
 * https://github.com/JedWatson/react-select/issues/3500#issuecomment-480410333
 *
 * The reason for this fix is because chrome ignores the property autocomplete="off" and for this
 * use case, we really need to disable autocomplete.
 *
 * For more information about Google decision on ignoring autocomplete, check:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164
 */
const InputWithAutocompleteFix = ({ autoComplete, ...props }) => {
  return (
    <InputSelectComponents.Input
      {...props}
      autoComplete="fixing-autocomplete"
      data-testid={props.id}
    />
  );
};

function LoadingIndicator(props) {
  return (
    <InputSelectComponents.LoadingIndicator data-testid="search-select-field-loader" {...props} />
  );
}

function LastOption(props) {
  const [observerEnabled, setObserverEnabled] = useState(true);
  const ref = useRef(null);

  const { current } = ref;
  const { onMenuScrollToBottom } = props.selectProps;

  useEffect(() => {
    if (current && observerEnabled && typeof IntersectionObserver === 'function') {
      const observer = new IntersectionObserver(
        (entries) => {
          const visible = entries.some((intersection) => intersection.intersectionRatio > 0);

          if (visible) {
            if (typeof onMenuScrollToBottom === 'function') {
              onMenuScrollToBottom();
            }

            // Stop observer once the last option is visible, this will prevent
            // continuous calls to `onMenuScrollToBottom` if the user looks
            // at the bottom of the list for a while.
            setObserverEnabled(false);
          }
        },
        {
          root: current.parentNode,
          // Start preloading at least 5-6 items above.
          rootMargin: '120px 0px 0px 0px',
        }
      );

      observer.observe(current);

      return () => observer.disconnect();
    }

    return () => {};
  }, [current, observerEnabled, onMenuScrollToBottom]);

  return (
    <div ref={ref}>
      <components.Option {...props} />
    </div>
  );
}

// Custom `Option` element is used to track when last element of the list
// is almost visible in the viewport - the aim is to fix the shortcoming of
// `react-select` that does not fire `onMenuScrollToBottom` callback for
// keyboard navigation, see https://github.com/JedWatson/react-select/issues/4537
function Option(props) {
  const { options, data } = props;
  const lastOptionData = options[options.length - 1];

  if (data === lastOptionData) {
    return <LastOption {...props} />;
  }

  return <components.Option {...props} />;
}

function flattenSelectedOptions(selectFn) {
  return (response) => {
    return {
      options: flatMap(response.pages, (page) => {
        const { data } = selectFn(page);
        return data;
      }),
    };
  };
}

function getNextPageParam(lastPage) {
  if (lastPage?.data?.totalPages > lastPage?.data?.currentPage) {
    return lastPage?.data.currentPage + 1;
  }

  // Return `undefined` to signal that the end of list is reached.
  return undefined;
}

/**
 * @typedef {Object} Props
 * @property {function} [onChange]
 * @property {function} [onInputChange]
 * @property {function} [onMenuOpen]
 * @property {function} [transformValue]
 * @property {boolean} [normalizeComparison=true]
 * @property {string} [initialSearchQuery='']
 * @property {boolean} [disableReactSelectSearch=false] - Useful for when you only want the backend filtering to work
 * @property {Array} [defaultOptions=[]]
 * @property {boolean} [isLoadingDefaultOptions]
 * @property {Array} [initialOptions=[]] - To handle client values like - no items or all items
 * @property {boolean} [clearPinnedOptions] - Disable the default behavior of adding the previously selected option to the new options list. Useful for when field is cleared and options list is updated
 * @property {boolean} [onlyLoadQueryOnMenuOpen] - Useful when you have a lot of the same `PaginatedSearchSelectField` component on the same page and you want to reduce over-fetching
 * @property {Object} [query]
 * @property {string} [searchQueryAlias='query'] - The search query param can differ between endpoints. This prop allows for different key values for the search query input value.
 * @property {Object} [selectFieldProps]
 */
/**
 * PaginatedSearchSelectField
 *
 * @type{React.FC<Props & import('@/types').WildcardProps>}
 */

export const PaginatedSearchSelectField = forwardRef(
  (
    {
      onChange,
      onInputChange,
      onMenuOpen,
      transformValue,
      normalizeComparison = true,
      initialSearchQuery = EMPTY_SEARCH_QUERY,
      // Useful for when you only want the backend filtering to work
      disableReactSelectSearch = false,
      defaultOptions = [],
      isLoadingDefaultOptions,
      // Initial options are used to handle client values like - no items or all items
      initialOptions = [],
      // Disable the default behavior of adding the previously selected option to the new options list
      // Useful for when field is cleared and options list is updated
      clearPinnedOptions,
      // Useful when you have a lot of the same `PaginatedSearchSelectField` component on the same page
      // and you want to reduce over-fetching
      onlyLoadQueryOnMenuOpen,
      query,
      // The search query param can differ between endpoints. This prop allows for different key values for the search query input value.
      searchQueryAlias = 'query',
      ...selectFieldProps
    },
    ref
  ) => {
    // Existing/pre-defined options should always be present in the list: `SelectField`
    // component relies on presence of the selected option in the options list.
    const [pinnedOptions, setPinnedOptions] = useState(defaultOptions);
    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const [menuWasOpened, setMenuWasOpened] = useState(false);
    const debouncedOnInput = useDebounce(setSearchQuery, TYPING_DEBOUNCE_TIMEOUT);

    const { select, ...queryOptions } = query?.options || {};

    const memorizedSelect = useMemo(
      () => flattenSelectedOptions(select || ((res) => res)),
      [select]
    );

    const infiniteQuery = useGetInfinite(query.path, {
      params: {
        queryParams: {
          ...(searchQuery ? { [searchQueryAlias]: searchQuery } : {}),
          ...(query.params?.queryParams || {}),
        },
        ...(query.params?.pathParams && { pathParams: query.params.pathParams }),
      },
      options: {
        // Prevent paginated queries from going stale to avoid re-requesting
        // several pages when user returns to a previously entered search
        // query.
        staleTime: Infinity,
        // Memoize paginated response transformation into a flat options list
        // by providing a stable reference to a selector function, see
        // https://tkdodo.eu/blog/react-query-data-transformations#3-using-the-select-option
        select: memorizedSelect,
        getNextPageParam,
        // Keep previous data while fetching new dataset to improve UX, see
        // https://react-query.tanstack.com/guides/paginated-queries#better-paginated-queries-with-keeppreviousdata
        keepPreviousData: true,
        enabled: onlyLoadQueryOnMenuOpen ? menuWasOpened : true,
        ...queryOptions,
      },
    });

    function onSelectFieldChange(newValue, meta, previousValue) {
      const isClearAction = meta?.action === 'clear';

      if (typeof onChange === 'function') {
        onChange(newValue, meta, previousValue, searchQuery);
      }

      setPinnedOptions(isClearAction ? [] : [newValue]);
    }

    function getOptionValue(option) {
      if (typeof transformValue === 'function') {
        const optionValue = transformValue(option);

        if (normalizeComparison) {
          return optionValue?.toString() ?? '';
        }

        return optionValue;
      }

      return option;
    }

    // We want to apply initial options, only if search is not triggered
    const prependOptions = searchQuery ? [] : initialOptions;

    // Construct options list: take loaded data options and append
    // pinned options - the latter helps `SelectField` to work in
    // `isControlled` mode.
    const baseOptions = Array.isArray(infiniteQuery.data?.options)
      ? [...prependOptions, ...infiniteQuery.data.options.slice()]
      : [];

    const optionValues = new Set(baseOptions.map(getOptionValue));

    const options = [
      // If a pinned option is not already available in the loaded list,
      // append it to the end.
      ...pinnedOptions
        // flatMap is used because if the "multiple" prop is true, then option is an array
        .flatMap((option) => (Array.isArray(option) ? option : [option]))
        .filter((option) => !optionValues.has(getOptionValue(option))),
      ...baseOptions,
    ];

    useEffect(() => {
      if (!isLoadingDefaultOptions && defaultOptions.length > 0) {
        setPinnedOptions(defaultOptions);
      }
    }, [defaultOptions, isLoadingDefaultOptions]);

    useEffect(() => {
      if (clearPinnedOptions) {
        setPinnedOptions([]);
      }
    }, [clearPinnedOptions]);

    return (
      <ThemeConsumer>
        {(theme) => (
          <ThemeProvider theme={{ withSearchIcon: theme.variant === 'outline' }}>
            <SelectField
              components={{
                Input: InputWithAutocompleteFix,
                LoadingIndicator,
                Option,
              }}
              isLoading={infiniteQuery.isFetching || isLoadingDefaultOptions}
              normalizeComparison={normalizeComparison}
              onChange={onSelectFieldChange}
              onInputChange={(newValue, actionMeta) => {
                debouncedOnInput(newValue);

                if (typeof onInputChange === 'function') {
                  onInputChange(newValue, actionMeta);
                }
              }}
              onMenuScrollToBottom={() =>
                infiniteQuery.fetchNextPage({
                  cancelRefetch: false,
                })
              }
              onMenuOpen={() => {
                setMenuWasOpened(true);
                onMenuOpen?.();
              }}
              options={options}
              transformValue={transformValue}
              filterOption={disableReactSelectSearch ? () => true : undefined}
              ref={ref}
              {...selectFieldProps}
            />
          </ThemeProvider>
        )}
      </ThemeConsumer>
    );
  }
);
