import type { Url } from 'url';

import { Stack, Box, Text, Pill } from '@remote-com/norma';
import type { PillTone } from '@remote-com/norma';
import isString from 'lodash/isString';
import { useRouter } from 'next/router';
import type { ElementType, ReactNode, ComponentProps } from 'react';
import { useTheme } from 'styled-components';
import type { RequireAtLeastOne } from 'type-fest';

import SiteTitle from '@/src/components/SiteTitle';
import { BackButtonAsIcon } from '@/src/components/Ui/BackButtonAsIcon/BackButtonAsIcon';
import { PageHeaderReports } from '@/src/components/Ui/Layout/PageHeader/PageHeaderReports';
import type { ReportCategory } from '@/src/domains/reports/types';
import { UserSettingsFavoriteButton } from '@/src/domains/userSettings/UserSettingsFavoriteButton';
import { useWindowWidth } from '@/src/hooks/useWindowWidth';

import { TopNavigationMenu } from '../TopNavigationMenu';

import {
  Header,
  HeaderPageTitleWrapper,
  PageSubtitle,
  PageTitle,
  WrapperSubtitleBreadcrumbs,
} from './PageHeader.styled';
import { PageHeaderSettings } from './PageHeaderSettings';

type SettingsItems = ComponentProps<typeof PageHeaderSettings>['items'];

const PageLogo = ({ logo }: { logo: ReactNode }) => {
  const props = { width: '48px', height: '48px', borderRadius: '50%' };

  if (typeof logo === 'string') {
    return <Box {...props} as="img" alt="" src={logo} />;
  }

  return <>{logo}</>;
};

type PillProps = {
  label: string;
  tone: PillTone;
  Icon?: ElementType;
};

export type PageHeaderPillProps = PillProps | PillProps[];

type PageTitleBlockProps = {
  title: ReactNode;
  count?: number;
  pill?: PageHeaderPillProps;
  logo?: ReactNode;
  settings?: SettingsItems;
  reportsCategory?: ReportCategory;
};

const PageTitleBlock = ({
  title,
  count,
  pill,
  logo,
  settings,
  reportsCategory,
}: PageTitleBlockProps) => {
  const router = useRouter();
  const allPills = Array.isArray(pill) ? pill : [pill];
  const pathWithPathParams = router?.asPath?.split('?')[0];

  return (
    <>
      {logo ? <PageLogo logo={logo} /> : null}
      {title && <PageTitle>{title}</PageTitle>}
      {count ? (
        <Text variant="lg" color="grey.600" data-testid="page-title-count">
          ({count})
        </Text>
      ) : null}
      {allPills.map((currentPill) =>
        currentPill ? (
          <Pill key={currentPill.label} tone={currentPill.tone} Icon={currentPill?.Icon}>
            {currentPill.label}
          </Pill>
        ) : null
      )}
      <PageHeaderReports reportsCategory={reportsCategory} />
      {typeof title === 'string' && pathWithPathParams ? (
        <UserSettingsFavoriteButton path={pathWithPathParams} name={title} />
      ) : null}
      <PageHeaderSettings items={settings} />
    </>
  );
};

export type Href = Partial<Pick<Url, 'pathname' | 'query'>>;

export type PageHeaderProps = RequireAtLeastOne<
  {
    'data-testid'?: string;
    actions?: ReactNode;
    breadcrumbs?: ReactNode;
    count?: number;
    customGoBack?: () => void;
    href?: Href | string;
    hrefTitle?: string;
    isLinkBack?: boolean;
    logo?: ReactNode;
    pill?: PageHeaderPillProps;
    settings?: SettingsItems;
    reportsCategory?: ReportCategory;
    siteTitle?: ReactNode;
    subtitle?: ReactNode;
    title?: ReactNode;
  },
  'siteTitle' | 'title'
>;

export const PageHeader = ({
  title,
  subtitle,
  siteTitle,
  count,
  pill,
  href,
  hrefTitle,
  isLinkBack,
  logo,
  actions,
  breadcrumbs,
  customGoBack,
  settings,
  reportsCategory,
  ...attrs
}: PageHeaderProps) => {
  const { breakpointValues } = useTheme();
  const { windowWidth } = useWindowWidth();
  const isLargeScreen = windowWidth && windowWidth >= breakpointValues.medium;
  return (
    <>
      <SiteTitle>{String(siteTitle || title)}</SiteTitle>
      <Header {...attrs}>
        <HeaderPageTitleWrapper>
          {href ? (
            <Box mr={3}>
              <BackButtonAsIcon
                ariaLabel={hrefTitle || 'Go back'}
                href={href}
                isLinkBack={isLinkBack}
                size="sm"
                tone="secondary"
                customGoBack={customGoBack}
              />
            </Box>
          ) : null}
          <Stack direction="row" gap="8px" alignItems="center" flexGrow="1">
            <PageTitleBlock
              title={title}
              count={count}
              pill={pill}
              logo={logo}
              settings={settings}
              reportsCategory={reportsCategory}
            />
          </Stack>

          {actions ? (
            <Box mr={[0, 3]} flexShrink={0}>
              {actions}
            </Box>
          ) : null}

          {isLargeScreen && (
            <Box>
              <TopNavigationMenu />
            </Box>
          )}
        </HeaderPageTitleWrapper>
        {subtitle || breadcrumbs ? (
          <WrapperSubtitleBreadcrumbs $logo={logo} $href={href}>
            {subtitle ? (
              <PageSubtitle forwardedAs={isString(subtitle) ? 'span' : 'div'}>
                {subtitle}
              </PageSubtitle>
            ) : null}
            {breadcrumbs || null}
          </WrapperSubtitleBreadcrumbs>
        ) : null}
      </Header>
    </>
  );
};
