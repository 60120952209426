import { useState, useEffect, useCallback } from 'react';

import { useDebounce } from './useDebounce';

type UseOptimisticUpdateProps<T> = {
  initialValue: T;
  onUpdate: (newValue: T) => Promise<void>;
  debounceDelay?: number;
  currentValue?: T;
};

export function useOptimisticUpdate<T>({
  initialValue,
  onUpdate,
  debounceDelay = 1000,
  currentValue,
}: UseOptimisticUpdateProps<T>) {
  const [localValue, setLocalValue] = useState<T>(initialValue);

  useEffect(() => {
    if (currentValue !== undefined) {
      setLocalValue(currentValue);
    }
  }, [currentValue]);

  const debouncedMutate = useDebounce(async (newValue: T) => {
    if (currentValue !== undefined && newValue === currentValue) {
      return;
    }

    try {
      await onUpdate(newValue);
    } catch (error) {
      setLocalValue((prev) => prev);
    }
  }, debounceDelay);

  const updateValue = useCallback(
    (newValue: T) => {
      setLocalValue(newValue);
      debouncedMutate(newValue);
    },
    [debouncedMutate]
  );

  return { value: localValue, updateValue };
}
