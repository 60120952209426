import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import lowerCase from 'lodash/lowerCase';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';

import type { API } from '@/src/api/config/api.types';

import type { DimensionWithSubdimensions, DimensionWithRelationship } from './types';

/**
 * Gets a flat list of dimension keys from a dimension structure.
 */
export function extractFactKeys(dimensions: API.Knowledge.Dimensions) {
  const dimensionNames: string[] = [];

  function extractNames(dims: API.Knowledge.Dimensions): void {
    Object.keys(dims).forEach((key) => {
      if (Object.hasOwn(dims, key)) {
        dimensionNames.push(key);
        if (dims[key].subdimensions) {
          extractNames(dims[key].subdimensions!);
        }
      }
    });
  }

  extractNames(dimensions);
  return dimensionNames;
}

/**
 * Gets a flat list of unique target dimension identifiers from a dimension structure.
 */
export function extractTargetDimensions(dimensions: API.Knowledge.Dimensions) {
  const dimensionNames: string[] = [];

  function extractTargetId(dims: API.Knowledge.Dimensions): void {
    Object.entries(dims).forEach(([key, dim]) => {
      const targetId = dim.target_fact_identifier || key;
      if (!dimensionNames.includes(targetId)) {
        dimensionNames.push(targetId);
      }
      if (dim.subdimensions) {
        extractTargetId(dim.subdimensions);
      }
    });
  }

  extractTargetId(dimensions);
  return dimensionNames;
}

export function flattenDimensions(dimensions: API.Knowledge.Dimensions): API.Knowledge.Dimensions {
  return Object.entries(dimensions).reduce((acc, [key, dimension]) => {
    if (isEmpty(dimension.subdimensions)) {
      return { ...acc, [key]: dimension };
    }
    return { ...acc, [key]: dimension, ...flattenDimensions(dimension.subdimensions) };
  }, {} as API.Knowledge.Dimensions);
}

type FilteredDimensions = {
  [key: string]: API.Knowledge.FactValueDetails<object>[];
};

function buildFilteredDimensions(
  dimensions: {
    [key: string]: API.Knowledge.FactValueDetails<{ [key: string]: any }>[];
  },
  parentKey: string,
  dimensionPrimaryKey: string,
  valueForMapKey: any
): FilteredDimensions {
  const res: FilteredDimensions = {};
  Object.entries(dimensions).forEach((curr) => {
    const [currentKey, values] = curr;

    if (currentKey !== parentKey) {
      const filteredValues = values.filter((i) => {
        // Make sure i.dimensions is properly typed to allow indexing with strings
        const parentDimension = i.dimensions[parentKey] as any[];
        return parentDimension?.filter((j) => j[dimensionPrimaryKey] === valueForMapKey).length > 0;
      });

      res[currentKey] = filteredValues;
    }
  });
  return res;
}

/**
 * Takes a list of fact values and their dimensions and
 * builds a map to show the hierarchy of the dimensions.
 *
 * i.e. if you have two dimensions of "countries" and "regions"
 * you will get an association of one country and all the regions relevant to it.
 */
export function buildDimensionValueMappings(
  dimensions: {
    [key: string]: API.Knowledge.FactValueDetails<object>[];
  },
  dimensionStructure: API.Knowledge.Dimensions,
  factsList: API.Knowledge.Fact[]
) {
  const factsMap = factsList.reduce((acc, fact) => {
    acc[fact.identifier] = fact;
    return acc;
  }, {} as { [key: string]: API.Knowledge.Fact });

  const map = new Map();

  Object.entries(dimensionStructure ?? {}).forEach(([key, value]) => {
    const entries = dimensions[value.target_fact_identifier!].map((i) => {
      const { dimensionPrimaryKey } = factsMap[value.target_fact_identifier!];
      const valueForMapKey = (i.valueRaw as Record<string, object>)[dimensionPrimaryKey];

      const filteredDimensions = buildFilteredDimensions(
        dimensions,
        value.target_fact_identifier!,
        dimensionPrimaryKey,
        valueForMapKey
      );

      const nestedEntries = buildDimensionValueMappings(
        filteredDimensions,
        value.subdimensions!,
        factsList
      );

      return [valueForMapKey, { ...nestedEntries, ...i.valueRaw }];
    });
    map.set(key, Object.fromEntries(entries));
  });

  return Object.fromEntries(map);
}

export type BuildDimensionValueMappingsReturnType = ReturnType<typeof buildDimensionValueMappings>;

type CleanedDimensions = {
  [k: string]: { subdimensions?: CleanedDimensions; value: string | object }[];
};

/**
 * Takes up the relevant dimensions passed from the fact value form and cleans up the payload
 * removing everything except the value and subdimensions.
 *  */
export function cleanupRelevancyPayload(
  dimensions: API.Knowledge.DimensionValue,
  factDimensions: API.Knowledge.Dimensions
): CleanedDimensions {
  return Object.fromEntries(
    Object.entries(factDimensions).map(([key, value]) => {
      return [
        key,
        dimensions?.[key]
          ? dimensions[key]!.map((i) => {
              return {
                value: i.value,
                ...(value.subdimensions
                  ? {
                      subdimensions: cleanupRelevancyPayload(i.subdimensions!, value.subdimensions),
                    }
                  : {}),
              };
            })
          : [],
      ];
    })
  );
}

/*
 * Used to find the parent-child relations between dimensions
 * and build a tree structure from them. Used to determine what parents
 * will need to be associated when using a child dimension.
 *
 * i.e. this will help determine "countries" is needed for a fact if "regions" is required
 * since it is a parent of "regions".
 */
export function getDimensionsLayout(
  dimensions: API.Knowledge.Fact[]
): DimensionWithSubdimensions[] {
  const dimensionMap: {
    [key: string]: DimensionWithSubdimensions;
  } = {};

  // Create a map of dimensions by their identifier
  dimensions.forEach((dimension) => {
    dimensionMap[dimension.identifier] = { ...dimension, subdimensions: [] };
  });

  // Build the tree by connecting subdimensions
  dimensions.forEach((dimension) => {
    Object.keys(dimension.dimensions).forEach((key) => {
      if (dimensionMap[key]) {
        dimensionMap[key].subdimensions?.push(dimensionMap[dimension.identifier]);
      }
    });
  });

  // Find the root dimensions (dimensions with no parent)
  const rootDimensions = dimensions.filter((dimension) => {
    return !Object.values(dimensionMap).some((d) =>
      d.subdimensions?.some((sd) => sd.identifier === dimension.identifier)
    );
  });

  return rootDimensions.map((dimension) => dimensionMap[dimension.identifier]);
}

// For facts with multiple of the same dimension
// Joins dimensions by parentIdentifier and rootDimensionIdentifier to create nested payload structure
export function createDimensionsPayload(
  dimensions: {
    [k: string]: DimensionWithRelationship;
  },
  rootDimensionIdentifier?: string
): API.Knowledge.Dimensions {
  if (!dimensions) return {};

  return Object.values(dimensions).reduce((acc, d) => {
    if (
      (!d.parentIdentifier && !rootDimensionIdentifier) ||
      d.parentIdentifier === rootDimensionIdentifier
    ) {
      const subdimensions = createDimensionsPayload(dimensions, d.identifier);

      return {
        ...acc,
        [d.identifier]: {
          ...pick(d, ['required', 'target_fact_identifier']),
          ...(isEmpty(subdimensions) ? {} : { subdimensions }),
        },
      };
    }
    return acc;
  }, {});
}

export function getDimensionValueSlugs(
  factValue: API.Knowledge.FactValueDetails<object>,
  dimension: API.Knowledge.Dimension,
  dimensionValue: Record<string, any>
) {
  const dimensionSlugs = factValue?.dimensionSlugs || {};
  const targetFactIdentifier = dimension.target_fact_identifier!;

  if (!dimensionSlugs[targetFactIdentifier]) return undefined;

  const targetFactData = dimensionSlugs[targetFactIdentifier];
  const { dimension_primary_key: primaryKey, slugs: targetFactSlugs } = targetFactData;

  const factValueIdentifier = dimensionValue?.[primaryKey];
  return targetFactSlugs[factValueIdentifier as string];
}

export function sentenceCase(str?: string) {
  return capitalize(lowerCase(str));
}

export function titleCase(str?: string) {
  return startCase(camelCase(str));
}

export function isFactDimension(fact: API.Knowledge.Fact) {
  return !!fact.dimensionPrimaryKey;
}

/**
 * Parses values to be displayed in the timeline.
 * Simple arrays fields (multiple select fields) are joined with a comma.
 * Remaining fields are returned as is.
 */
export function parseTimelineArrayValues(values: object) {
  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      if (Array.isArray(value) && value.length > 0 && !isPlainObject(value[0])) {
        return [key, value.join(', ')];
      }
      return [key, value];
    })
  );
}
