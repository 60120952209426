import pLimit from 'p-limit';
import { useCallback, useEffect, useReducer, useRef } from 'react';

const buildConcurrentFetchInitialState = (limit) => ({
  limit,
  idle: false,
  completed: false,
  fetching: false,
  totalCount: 0,
  pendingCount: limit.current.pendingCount,
  activeCount: limit.current.activeCount,
  completedCount: 0,
  errorCount: 0,
  results: undefined,
  errors: undefined,
});

function concurrentFetchReducer(state, action) {
  switch (action.type) {
    case 'START_CONCURRENT_FETCH':
      return {
        ...state,
        completed: false,
        fetching: true,
        pedingCount: 0,
        activeCount: 0,
        completedCount: 0,
        errorCount: 0,
        totalCount: action.requestParamsList.length,
        results: undefined,
        errors: undefined,
      };

    case 'FETCH_SUCCESS':
      return {
        ...state,
        pendingCount: state.limit.current.pendingCount,
        activeCount: state.limit.current.activeCount,
        completedCount: state.completedCount + 1,
        results: [...(state.results || []), { result: action.result, item: action.item }],
      };

    case 'FETCH_ERROR':
      return {
        ...state,
        pendingCount: state.limit.current.pendingCount,
        activeCount: state.limit.current.activeCount,
        errorCount: state.errorCount + 1,
        errors: [...(state.errors || []), { error: action.error, item: action.item }],
      };

    case 'END_CONCURRENT_FETCH':
      return {
        ...state,
        fetching: false,
        completed: true,
      };

    case 'RESET':
      return buildConcurrentFetchInitialState(state.limit);

    default:
      return state;
  }
}

/**
 * When a lot of requests need to be sent to the backend this function will make sure only a max number get sent to the BE at the same time to not overload the backend
 *
 * @param {Object} params
 * @param {Function} [params.onCompletion] Callback executed when everything is completed
 * @param {number} [params.concurrency] Max number of concurrent calls. Defaults to 4
 */
const useConcurrentFetchCalls = ({ concurrency = 4, onCompletion } = {}) => {
  const controller = useRef(new AbortController());
  const limit = useRef(pLimit(concurrency));
  const [state, dispatch] = useReducer(
    concurrentFetchReducer,
    buildConcurrentFetchInitialState(limit)
  );
  // Clean up any ongoing and future promises
  const cleanup = useCallback(() => {
    limit.current.clearQueue();
    controller.current.abort();
  }, []);

  useEffect(() => {
    return () => cleanup();

    // Make 100% sure this only runs at mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function run({ requestParamsList, requestFn }) {
    dispatch({ type: 'START_CONCURRENT_FETCH', requestParamsList });

    await Promise.allSettled(
      requestParamsList.map((item, index) =>
        limit.current(() =>
          requestFn(item, controller.current.signal, index)
            .then((result) => dispatch({ type: 'FETCH_SUCCESS', result, item }))
            .catch((error) => dispatch({ type: 'FETCH_ERROR', error, item }))
        )
      )
    );

    dispatch({ type: 'END_CONCURRENT_FETCH' });

    if (onCompletion) onCompletion(state.results, state.errors);
  }

  async function reset() {
    cleanup();
    controller.current = new AbortController();
    dispatch({ type: 'RESET' });
  }

  return {
    run,
    reset,
    ...state,
  };
};

export default useConcurrentFetchCalls;
