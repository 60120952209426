import type { LinkProps } from 'next/link';
import { forwardRef } from 'react';
import type { ElementType, MouseEventHandler, ComponentProps } from 'react';
import type { RequireAtLeastOne } from 'type-fest';

import { ButtonIcon } from '@/src/components/Button';
import { DropdownMenu, MenuItem } from '@/src/components/DropdownMenu';
import type { $TSFixMe } from '@/types';

const Trigger = forwardRef<$TSFixMe, ComponentProps<typeof ButtonIcon>>(
  ({ Icon, name, size = 'sm', tone = 'secondary', variant = 'ghost', ...props }, ref) => {
    return (
      <ButtonIcon
        Icon={Icon}
        label={name}
        ref={ref}
        size={size}
        tone={tone}
        variant={variant}
        {...props}
      />
    );
  }
);

type PageHeaderQuickLinksProps = {
  icon: ElementType;
  items?: RequireAtLeastOne<
    {
      label: string;
      href: LinkProps['href'];
      onClick?: MouseEventHandler;
    },
    'href' | 'onClick'
  >[];
  label: string;
};

export const PageHeaderQuickLinks = ({
  icon,
  items,
  label,
  ...props
}: PageHeaderQuickLinksProps) => {
  if (!items || items.length === 0) {
    return null;
  }

  if (items.length === 1) {
    return <Trigger Icon={icon} {...items[0]} {...props} />;
  }

  return (
    <DropdownMenu align="start" trigger={<Trigger Icon={icon} label={label} {...props} />}>
      {items.map(({ label: menuItemLabel, ...menuItemProps }, index) => (
        <MenuItem key={index} {...menuItemProps}>
          {menuItemLabel}
        </MenuItem>
      ))}
    </DropdownMenu>
  );
};
