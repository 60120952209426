// Add data types to window.navigator ambiently for implicit use in the entire project. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />

import { useGet } from '@remote-com/data-layer';
import { getRandomColorForInitialsImage } from '@remote-com/norma';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useContext, useState, useMemo, useEffect, useRef } from 'react';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';

import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { transformPermissions } from '@/src/domains/account/helpers';
import { isAdmin, isEmployer, isUserOnboarded } from '@/src/domains/registration/auth/helpers';
import { taskStatus } from '@/src/domains/tasks/constants';
import { fetchUserTasks } from '@/src/domains/tasks/services';
import { fetchUserCache } from '@/src/domains/userCache/services';
import { captureException } from '@/src/helpers/captureException';
import { isUserOnMacOS } from '@/src/helpers/general';
import { isZendeskURL } from '@/src/helpers/security';
import { getZendeskSSOUrl } from '@/src/helpers/zendesk';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';
import { transformLoginsResponse } from '@/src/services/User';
import type { $TSFixMe } from '@/types';

import { getUserDestination } from '../../domains/registration/auth/helpers/redirects';

import type { UserContextUser } from './context';
import UserContext from './context';

export type UserAccountResponse = UserAccount;

type TUserProvider = {
  user?: UserContextUser;
  // to avoid fixing all the tests that pass a user through renderWithWrapper, we enable this props in tests
  isTestingMode: boolean;
  children: ReactNode;
};

const UserProvider = ({ user: testUser, isTestingMode = false, children }: TUserProvider) => {
  const { pathname, push, query } = useRouter();
  const [timeToSignOutRemaining, setTimeToSignOutRemaining] = useState<number | undefined>();
  const [channelReference, setChannelReference] = useState();
  const [userAuthData, setUserAuthenticationData] = useState<{
    email: string;
    password: string;
    isInternalUser: boolean;
    originPathname: string;
  }>();
  const [isUserSignedOut, setIsUserSignedOut] = useState<boolean>();
  const [redirectAuthenticatedUser, setRedirectAuthenticatedUser] = useState();
  const userTasks = useRef<any[]>();

  const {
    data: account,
    refetch,
    isLoading,
    isFetched: isUserAccountFetched,
  } = useGet('/api/v1/account', {
    options: {
      select: transformPermissions, // permissions data must be formatted to snake_case as done in /services/User#getUser
      retry: false,
      enabled: !isTestingMode,
      onError: (error: unknown) => {
        if (error instanceof Error && 'status' in error) {
          setIsUserSignedOut((error as any).status === 401);
        }
      },
    },
  });

  const { data: accountCache } = useQuery('user-cache', () => fetchUserCache(account.data), {
    enabled: !!account,
  });

  const { data: loginMethods, refetch: refetchLogins } = useGet('/api/v1/account/logins', {
    options: {
      enabled: !!account || !!testUser,
      select: transformLoginsResponse,
      retry: false,
    },
  });

  async function getUserRedirect() {
    if (
      !userTasks.current &&
      isUserOnboarded(account.data) &&
      !isEmployer(account.data) &&
      !isAdmin(account.data)
    ) {
      const { data: tasksData } = await fetchUserTasks({
        queryParams: { status: taskStatus.CREATED },
      });
      userTasks.current = tasksData;
    } else {
      userTasks.current = [];
    }

    // check for zendesk
    if (isZendeskURL(query.return_to)) {
      try {
        const zendeskSSOUrl = await getZendeskSSOUrl(query.return_to);
        window.location.href = zendeskSSOUrl;
      } catch (e) {
        console.error('Could not redirect to Zendesk');
        return;
      }
    }
    // TODO: enable this again when we start using them, https://linear.app/remote/issue/TAJ-338/[placeholder]-candidate-user-access-to-employ
    // const { data: profilesData } = await getUserProfiles();
    const userDestination = await getUserDestination({
      user: account.data,
      context: { pathname },
      tasks: userTasks.current,
      userProfiles: [],
    });

    setRedirectAuthenticatedUser(userDestination);

    if (userDestination && userDestination !== pathname) {
      push(userDestination);
    }
  }

  const user = useMemo(() => {
    if (isTestingMode) return testUser;
    if (!account) return undefined;

    return {
      ...account.data,
      ...(accountCache && { userCache: accountCache }),
      isOnMacOS: isUserOnMacOS(),
      initialsColor: getRandomColorForInitialsImage(account.data.name),
    };
  }, [isTestingMode, testUser, account, accountCache]);

  useEffect(() => {
    // Reset the redirectAuthenticatedUser when pathname changes
    setRedirectAuthenticatedUser(undefined);
  }, [pathname]);

  useEffect(() => {
    // When pathname changes and user is authenticated, check if user should be redirected
    if (account?.data.slug) {
      // eslint-disable-next-line no-autofix/no-floating-promise/no-floating-promise
      getUserRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, account?.data.slug]);

  async function refreshUser() {
    try {
      const [userData] = await Promise.all([refetch(), refetchLogins()]);
      return userData.data.data;
    } catch (e) {
      captureException(`error refreshing user: ${e}`);
      return {};
    }
  }

  function setInactivityTimeRemaining(time: number) {
    setTimeToSignOutRemaining(time);
  }

  function setChannelRef(ch: any) {
    setChannelReference(ch);
  }

  function setUserAuthData({
    email,
    password,
    isInternalUser,
    originPathname,
  }: {
    email: string;
    password: string;
    isInternalUser: boolean;
    originPathname: string;
  }) {
    setUserAuthenticationData({ email, password, isInternalUser, originPathname });
  }

  const value = {
    user,
    isUserSignedOut,
    isLoading,
    loginMethods,
    refreshUser,
    setInactivityTimeRemaining,
    timeToSignOutRemaining,
    setChannelRef,
    channelReference,
    userAuthData,
    setUserAuthData,
  };

  // Note: This is not ideal but due to the way tests were set up, where a user is passed directly to UserContext Provider,
  // we don't need to go through the account fetching and therefore the logic bellow is not used in tests.
  // The solution would be to refactor all the tests and set user using through MSW.
  if (isTestingMode) {
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
  }

  if (isUserAccountFetched) {
    // render the app if:
    // - user is authenticated and there is no redirectAuthenticatedUser,
    // - or if user is not authenticated
    if ((account && redirectAuthenticatedUser === null) || !account) {
      return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
    }
    return null;
  }

  return null;
};

export const useUserContext = () => useContext(UserContext);

/**
 * Performs a mutation an update user on success, both in React.context and in the DOM
 * @param {Function} mutationFn function that performs an asynchronous task and returns a promise.
 * @param {*} options useMutation options
 * @returns
 */
export function useMutationAndUpdateUser(
  mutationFn: () => Promise<any>,
  options: { onSuccess?: (data: $TSFixMe) => void; onError?: (err: $TSFixMe) => void } = {}
) {
  const { refreshUser } = useUserContext();
  return useCustomMutation(mutationFn, {
    ...options,
    onSuccess: async (data: $TSFixMe) => {
      try {
        await refreshUser?.();
        options.onSuccess?.(data);
      } catch (err) {
        options?.onError?.(err);
      }
    },
  });
}

export default UserProvider;
