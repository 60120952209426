import { useField } from 'formik';
import { type ComponentProps } from 'react';

import type { LegalEntityApi } from '@/src/api/config/employ/legalEntity.types';
import { useUserCan } from '@/src/components/UserCan';
import { getLegalEntityLabel } from '@/src/domains/legalEntities/helpers';
import { useLegalEntitiesAdmin } from '@/src/domains/legalEntities/hooks/useLegalEntitiesData';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getDataWithRenamedProperty } from '@/src/helpers/api';
import usePrevious from '@/src/hooks/usePrevious';

import { PaginatedSearchSelectField } from '../PaginatedSearchSelectField';

type Props = ComponentProps<typeof PaginatedSearchSelectField> & {
  filters: Record<string, any>;
  countrySlug?: string;
};

export function LegalEntityMultiselect({ filters = {}, countrySlug, ...selectFieldProps }: Props) {
  const previousCountrySlug = usePrevious(countrySlug);
  const hasCountryChanged = previousCountrySlug !== countrySlug;
  const { userCan } = useUserCan();
  const hasPermissions =
    userCan('read', Resources.legal_entities) && userCan('read', Resources.remote_entities);

  const [{ value }] = useField(selectFieldProps.name);

  // Grab previously selected legal entity slugs
  const legalEntitySlugs = value?.map((option: string) => option).filter(Boolean) ?? [];

  // Fetch data regarding those legal entities (otherwise they might not be available in the first page of the options list)
  const legalEntitiesQueries = useLegalEntitiesAdmin(legalEntitySlugs, {
    enabled: Array.isArray(legalEntitySlugs) && legalEntitySlugs.length > 0,
  });

  // Check if any of the queries are loading
  const isLoading = legalEntitiesQueries.some((query) => query.isLoading);

  // Map legal entities to be used as default options (will be set as already selected)
  const defaultOptions =
    legalEntitiesQueries
      .map((query) => query.data)
      .filter(Boolean)
      .map((option) => ({
        label: getLegalEntityLabel(option as LegalEntityApi.LegalEntity),
        value: (option as LegalEntityApi.LegalEntity).slug,
      })) || [];

  // Use a Set to easily check if a legal entity slug is already selected
  const legalEntitySlugSet = new Set<string>(legalEntitySlugs);

  return (
    <PaginatedSearchSelectField
      data-testid="legal-entity-select-field"
      query={{
        path: '/api/v1/rivendell/legal-entities',
        params: {
          queryParams: filters,
        },
        options: {
          select: (res: LegalEntityApi.LegalEntitiesResponse) => {
            const options = getDataWithRenamedProperty(res, 'remoteEntities')
              .data.map((option: LegalEntityApi.LegalEntity) => ({
                label: getLegalEntityLabel(option),
                value: option.slug,
              }))
              .filter(
                // here we filter out the options that are already set as defaultOptions
                (option: { label: string; value: string }) => !legalEntitySlugSet.has(option.value)
              );

            return {
              ...res,
              data: options,
            };
          },
          enabled: hasPermissions,
        },
      }}
      loadingMessage={() => 'Loading legal entities…'}
      isLoadingDefaultOptions={isLoading}
      defaultOptions={defaultOptions}
      // In some cases, we get a new list of legal entities when country filter changes
      // This allows us to to NOT have the default behavior of adding the previously selected option to the new options list
      clearPinnedOptions={hasCountryChanged}
      isControlled
      isDisabled={!hasPermissions}
      description={
        !hasPermissions ? 'You do not have permissions to view legal entities' : undefined
      }
      multiple
      {...selectFieldProps}
    />
  );
}
