import getConfig from 'next/config';

import { USER_TYPE } from '@/src/domains/registration/auth/constants';
import { getUserRole, isAdmin } from '@/src/domains/registration/auth/helpers';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { getFromUserCache } from '@/src/domains/userCache/helpers';
import { debug } from '@/src/helpers/general';

const {
  publicRuntimeConfig: { SPRIG_ENABLED, SPRIG_ENVIRONMENT_ID },
} = getConfig();

let sprigInstance;

const isActive = () => SPRIG_ENABLED && SPRIG_ENVIRONMENT_ID;

export const getUserType = (user) => {
  const { role, employeePersona } = getUserRole(user);
  return employeePersona || role;
};

const shouldPreventInitialization = (user) => {
  return (
    !isActive() ||
    !user ||
    isAdmin(user) ||
    getUserType(user) === USER_TYPE.CANDIDATE ||
    getUserType(user) === USER_TYPE.FREELANCER ||
    !!sprigInstance
  );
};

function identifySprigUser(user) {
  try {
    sprigInstance.setUserId(user.slug);

    const userType = getUserType(user);
    const companyData = getFromUserCache(user, userCacheKeys.COMPANY_DATA);

    sprigInstance.setAttributes({
      companyName: user.company,
      companySlug: companyData?.slug,
      employmentStatus: user.activeEmployment?.status,
      userRole: user.teamMember?.role,
      userStatus: user.status,
      userType,
    });
    debug(`Sprig::identification completed`);
  } catch (e) {
    debug(`Sprig::identification failed`);
    console.error(e);
  }
}

export async function initSprig(user) {
  if (shouldPreventInitialization(user)) {
    return;
  }
  const { sprig, SprigEvent } = await import('@sprig-technologies/sprig-browser');

  try {
    sprigInstance = sprig.configure({
      environmentId: SPRIG_ENVIRONMENT_ID,
    });

    identifySprigUser(user);

    // Prevent third party libraries like radix-ui (our drawers) making the surveys not clickable
    sprigInstance.addListener(SprigEvent.SurveyPresented, () => {
      document.body.style.setProperty('pointer-events', 'auto', 'important');
    });

    debug(`Sprig::script loaded`);
  } catch (e) {
    debug(`Sprig::failed loading script`);
    console.error(e);
  }
}

export function logoutSprigUser() {
  if (!isActive() || !sprigInstance) return;

  try {
    sprigInstance.logoutUser();
    debug('Sprig::logged out user');
  } catch (e) {
    console.error(e);
  }
}

export function trackSprigEvent(eventName) {
  if (!isActive() || !sprigInstance) return;

  try {
    sprigInstance('track', eventName);
    debug('Sprig::tracked event - ', eventName);
  } catch (e) {
    console.error(e);
  }
}

// Used for testing
export const resetSprigInstance = () => {
  sprigInstance = null;
};
