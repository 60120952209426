import type { GetPath, TableOptions, UseGetOptions } from '@remote-com/data-layer';
import { useGet, useGetForTable } from '@remote-com/data-layer';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

import type { LegalEntityApi } from '@/src/api/config/employ/legalEntity.types';
import { useUserCan } from '@/src/components/UserCan';
import { useEmployerComplianceSettingsAll } from '@/src/domains/employment/hooks';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';

type LegalEntitiesData = {
  queryParams?: LegalEntityApi.LegalEntitiesRequest['queryParams'];
  options?: UseGetOptions<'/api/v1/employer/legal-entities'>;
};

export default function useLegalEntitiesData(
  queryParams: LegalEntitiesData['queryParams'] = {},
  options: LegalEntitiesData['options'] = {}
) {
  const { userCan } = useUserCan();

  const enabled = options.enabled ?? true;

  return useGet('/api/v1/employer/legal-entities', {
    params: { queryParams },
    options: {
      refetchOnWindowFocus: false,
      ...options,
      select: (res) => res.data,
      enabled: enabled && userCan('read', Resources.employer.legal_entities),
    },
  });
}

export const useLegalEntitiesDataWithComplianceSettings = (
  queryParams: LegalEntitiesData['queryParams'] = {},
  options: LegalEntitiesData['options'] = {},
  shouldFetchComplianceSettings = true
) => {
  const {
    data: complianceSettings,
    isLoading: isComplianceSettingsLoading,
    isError: isComplianceSettingsError,
  } = useEmployerComplianceSettingsAll({ options: { enabled: shouldFetchComplianceSettings } });

  const { data, isLoading, isError } = useLegalEntitiesData(queryParams, options);
  const legalEntitiesData = {
    ...data,
    legalEntities: data?.legalEntities?.map((legalEntity) => {
      return {
        ...legalEntity,
        // transforming the content of complianceSettings to camelCase - it is not being transformed at data-layer level - we do not want slugs to be transformed
        complianceSettings: mapKeys(complianceSettings?.[legalEntity.slug], (_, k) => camelCase(k)),
      };
    }),
  };
  return {
    legalEntitiesData,
    isLoading: isLoading || isComplianceSettingsLoading,
    isError: isError || isComplianceSettingsError,
  };
};

type LegalEntitiesTableData = {
  queryParams?: { [key: string]: string };
  tableOptions?: TableOptions<
    '/api/v1/employer/legal-entities',
    'legalEntities',
    LegalEntityApi.LegalEntitiesRequest
  >;
  queryOptions?: UseGetOptions<'/api/v1/employer/legal-entities'>;
};

export function useLegalEntitiesTableData({
  queryParams = {},
  queryOptions = {},
  tableOptions = {},
}: LegalEntitiesTableData) {
  return useGetForTable('/api/v1/employer/legal-entities', {
    params: { queryParams },
    options: { ...queryOptions },
    tableOptions: {
      withQS: true,
      dataProperty: 'legalEntities',
      globalFilterQueryKey: 'query',
      ...tableOptions,
    },
  });
}

type LegalEntityParams<KPath extends GetPath> = {
  legalEntitySlug: string;
  options?: UseGetOptions<KPath>;
};

export type LegalEntityEmployerData = LegalEntityApi.LegalEntityResponse['data'];

export function useLegalEntityData({
  legalEntitySlug,
  options = {},
}: LegalEntityParams<'/api/v1/employer/legal-entities/[legalEntitySlug]'>) {
  return useGet('/api/v1/employer/legal-entities/[legalEntitySlug]', {
    params: {
      pathParams: { legalEntitySlug },
    },
    options: {
      ...options,
      select: ({ data }) => data,
    },
  });
}

export function useLegalEntityAdministrativeDetails({
  legalEntitySlug,
  options = {},
}: LegalEntityParams<'/api/v1/employer/legal-entities/[legalEntitySlug]/administrative-details'>) {
  return useGet('/api/v1/employer/legal-entities/[legalEntitySlug]/administrative-details', {
    params: {
      pathParams: { legalEntitySlug },
    },
    options: {
      ...options,
      select: ({ data }) => data,
    },
  });
}

export function useLegalEntityBankAccountDetails({
  legalEntitySlug,
  options = {},
}: LegalEntityParams<'/api/v1/employer/legal-entities/[legalEntitySlug]/bank-account'>) {
  return useGet('/api/v1/employer/legal-entities/[legalEntitySlug]/bank-account', {
    params: {
      pathParams: { legalEntitySlug },
    },
    options: {
      ...options,
      select: ({ data }) => data,
    },
  });
}

export const useIsNiumEnabledForLegalEntity = ({
  legalEntitySlug,
  options = {},
}: LegalEntityParams<'/api/v1/employer/legal-entities/[legalEntitySlug]/nium-eligibility'>) =>
  useGet('/api/v1/employer/legal-entities/[legalEntitySlug]/nium-eligibility', {
    params: {
      pathParams: { legalEntitySlug },
    },
    options: {
      ...options,
      select: ({ data }) => {
        return data;
      },
    },
  });
