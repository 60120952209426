import type { BadgeType, PillToneV2 } from '@remote-com/norma';
import type { Entries } from 'type-fest';

import type { PaymentStatus } from '@/src/domains/payments/constants';
import { paymentStatus } from '@/src/domains/payments/constants';
import type { UserAdminOrEmployer } from '@/src/domains/registration/auth/constants';
import { USER_TYPE as userRoles } from '@/src/domains/registration/auth/constants';

type BadgeProps = {
  label: string;
  type: BadgeType;
  pillTone?: PillToneV2;
  labelExtra?: string;
};

export type BadgePropsByStatus = Record<PaymentStatus, BadgeProps>;

const paymentProcessingBadge: BadgeProps = {
  label: 'Payment processing',
  type: 'pending',
  pillTone: 'warning',
};

const paymentAwaitingPaymentBadge: BadgeProps = {
  label: 'Awaiting payment',
  type: 'pending',
  pillTone: 'warning',
};

const paymentFailedBadge: BadgeProps = {
  label: 'Payment failed',
  type: 'error',
  pillTone: 'error',
};

const paymentClosedBadge: BadgeProps = {
  label: 'Closed',
  type: 'active',
  pillTone: 'success',
};

const CREDIT_STATUS_CLOSED_TOOLTIP = 'The credit has been fully applied to an invoice or refunded.';

export const badgePropsForEmployer = (): BadgePropsByStatus => ({
  [paymentStatus.COMPLETED]: { label: 'Paid', type: 'active', pillTone: 'success' },
  [paymentStatus.CREATED]: {
    label: 'Due',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.DISPUTED]: {
    label: 'Disputed',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.SHORT_PAID_IN]: {
    label: 'Partially paid',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.PAY_IN_FAILED]: paymentFailedBadge,
  [paymentStatus.PARTIAL_FAILURE]: paymentFailedBadge,
  [paymentStatus.BLOCKED]: paymentFailedBadge,
  [paymentStatus.CANCELLED]: { label: 'Cancelled', type: 'inactive', pillTone: 'error' },
  [paymentStatus.DELETED]: { label: 'Deleted', type: 'inactive', pillTone: 'error' },
  [paymentStatus.SCHEDULED]: {
    label: 'Auto-payment',
    type: 'info',
    pillTone: 'warning',
  },
  [paymentStatus.PAID_IN]: paymentProcessingBadge,
  [paymentStatus.COLLECTING]: paymentProcessingBadge,
  [paymentStatus.MARKED_COLLECTING]: paymentAwaitingPaymentBadge,
  [paymentStatus.ENQUEUED]: paymentProcessingBadge,
  [paymentStatus.PAY_OUT_FAILED]: paymentProcessingBadge,
  [paymentStatus.PROCESSING]: paymentProcessingBadge,
  [paymentStatus.MANUAL_PAYOUT]: paymentProcessingBadge,
  [paymentStatus.CREDITED]: {
    ...paymentClosedBadge,
    labelExtra: CREDIT_STATUS_CLOSED_TOOLTIP,
  },
  [paymentStatus.APPLIED_OFF_PLATFORM]: {
    ...paymentClosedBadge,
    labelExtra: CREDIT_STATUS_CLOSED_TOOLTIP,
  },
  [paymentStatus.REFUNDED]: {
    ...paymentClosedBadge,
    labelExtra: CREDIT_STATUS_CLOSED_TOOLTIP,
  },
  [paymentStatus.CREDIT_DUE]: {
    label: 'Credit available',
    type: 'info',
    pillTone: 'warning',
  },
  // Billing Document only labels
  [paymentStatus.CLOSED]: {
    ...paymentClosedBadge,
    labelExtra: CREDIT_STATUS_CLOSED_TOOLTIP,
  },
  [paymentStatus.CREDIT_AVAILABLE]: {
    label: 'Credit available',
    type: 'info',
    pillTone: 'warning',
  },
  [paymentStatus.OVERDUE]: {
    label: 'Overdue',
    type: 'error',
    pillTone: 'error',
  },
  [paymentStatus.PAY_OUT_SCHEDULED]: {
    label: 'Pay out scheduled',
    type: 'info',
    pillTone: 'warning',
  },
});

export const employerPaymentStatusesByGroup = [
  {
    label: 'Payments',
    options: [
      paymentStatus.COMPLETED,
      paymentStatus.CREDITED,
      paymentStatus.CREATED,
      paymentStatus.DISPUTED,
      paymentStatus.SHORT_PAID_IN,
      paymentStatus.PAY_IN_FAILED,
      paymentStatus.PARTIAL_FAILURE,
      paymentStatus.BLOCKED,
      paymentStatus.CANCELLED,
      paymentStatus.DELETED,
      paymentStatus.SCHEDULED,
      paymentStatus.PAID_IN,
      paymentStatus.COLLECTING,
      paymentStatus.ENQUEUED,
      paymentStatus.PAY_OUT_FAILED,
      paymentStatus.PROCESSING,
      paymentStatus.MANUAL_PAYOUT,
      paymentStatus.PAY_OUT_SCHEDULED,
    ],
  },
  {
    label: 'Credits',
    options: [
      paymentStatus.CREDITED,
      paymentStatus.APPLIED_OFF_PLATFORM,
      paymentStatus.REFUNDED,
      paymentStatus.CREDIT_DUE,
    ],
  },
] as const;

export const billingDocumentEmployerPaymentStatusesByGroup = [
  {
    label: 'Payments',
    options: [
      paymentStatus.COMPLETED,
      paymentStatus.CREATED,
      paymentStatus.OVERDUE,
      paymentStatus.DISPUTED,
      paymentStatus.SHORT_PAID_IN,
      paymentStatus.PAY_IN_FAILED,
      paymentStatus.PARTIAL_FAILURE,
      paymentStatus.BLOCKED,
      paymentStatus.CANCELLED,
      paymentStatus.DELETED,
      paymentStatus.SCHEDULED,
      paymentStatus.PAID_IN,
      paymentStatus.COLLECTING,
      paymentStatus.ENQUEUED,
      paymentStatus.PAY_OUT_FAILED,
      paymentStatus.PROCESSING,
      paymentStatus.MANUAL_PAYOUT,
      paymentStatus.MARKED_COLLECTING,
    ],
  },
  {
    label: 'Credits',
    options: [paymentStatus.CLOSED, paymentStatus.CREDIT_AVAILABLE],
  },
] as const;

// For admins
export const detailedBadgeProps: BadgePropsByStatus = {
  [paymentStatus.BLOCKED]: { label: 'Blocked', type: 'error', pillTone: 'error' },
  [paymentStatus.CANCELLED]: { label: 'Cancelled', type: 'inactive', pillTone: 'error' },
  [paymentStatus.COLLECTING]: {
    label: 'Collecting',
    type: 'pending',
    pillTone: 'warning',
  },
  [paymentStatus.MARKED_COLLECTING]: {
    label: 'Awaiting payment',
    type: 'pending',
    pillTone: 'warning',
  },
  [paymentStatus.COMPLETED]: { label: 'Paid', type: 'active', pillTone: 'success' },
  [paymentStatus.CREATED]: {
    label: 'Payment due',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.CREDITED]: paymentClosedBadge,
  [paymentStatus.APPLIED_OFF_PLATFORM]: {
    label: 'Credit applied (manually)',
    type: 'active',
    pillTone: 'success',
  },
  [paymentStatus.CREDIT_DUE]: {
    label: 'Credit due',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.REFUNDED]: paymentClosedBadge,
  [paymentStatus.DELETED]: { label: 'Deleted', type: 'inactive', pillTone: 'error' },
  [paymentStatus.ENQUEUED]: { label: 'Enqueued', type: 'pending', pillTone: 'warning' },
  [paymentStatus.PAID_IN]: { label: 'Paid In', type: 'info', pillTone: 'success' },
  [paymentStatus.PAY_IN_FAILED]: {
    label: 'Pay In Failed',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.PAY_OUT_FAILED]: {
    label: 'Pay Out Failed',
    type: 'error',
    pillTone: 'error',
  },
  [paymentStatus.PARTIAL_FAILURE]: {
    label: 'Partial failure',
    type: 'error',
    pillTone: 'error',
  },
  [paymentStatus.PROCESSING]: {
    label: 'Processing',
    type: 'pending',
    pillTone: 'warning',
  },
  [paymentStatus.SCHEDULED]: {
    label: 'Payment scheduled',
    type: 'info',
    pillTone: 'warning',
  },
  [paymentStatus.SHORT_PAID_IN]: {
    label: 'Short paid',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.DISPUTED]: {
    label: 'Disputed',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.MANUAL_PAYOUT]: {
    label: 'Manual Pay Out',
    type: 'pending',
    pillTone: 'warning',
  },
  // Billing Document only labels
  [paymentStatus.CLOSED]: paymentClosedBadge,
  [paymentStatus.CREDIT_AVAILABLE]: {
    label: 'Credit due',
    type: 'attention',
    pillTone: 'warning',
  },
  [paymentStatus.OVERDUE]: {
    label: 'Overdue',
    type: 'error',
    pillTone: 'error',
  },
  [paymentStatus.PAY_OUT_SCHEDULED]: {
    label: 'Pay out scheduled',
    type: 'info',
    pillTone: 'warning',
  },
};

export function getPropsForRole(userRole: UserAdminOrEmployer): BadgePropsByStatus {
  switch (userRole) {
    case userRoles.EMPLOYER:
      return badgePropsForEmployer();
    case userRoles.ADMIN:
      return detailedBadgeProps;
  }
}

export function getProps(propsArray: BadgePropsByStatus, status: PaymentStatus): BadgeProps {
  const props = propsArray[status];

  if (!props) {
    // This should not be needed, and as far as I know this is not needed in
    // production, but there are a lot of tests that send undefined, null, or
    // simply invalid statuses (because those tests are not using typescript).
    // Once those tests are fixed, we should remove this.
    return { label: '-', type: 'inactive' };
  }

  return {
    label: props.label,
    type: props.type,
    pillTone: props.pillTone,
    labelExtra: props.labelExtra ?? '',
  };
}

/**
 * Given a outstanding payment status and a user role, returns the label and
 * badge type to be shown to that user to communicate the status of the
 * outstanding payment.
 * @param {String} status - from src/domains/payment/constants
 * @param {String} userRole - from src/domains/registration/auth/constants
 * @return {{label: string, type: string}} Label and Badge type
 */
export function getPaymentStatusBadgeProps(status: PaymentStatus, userRole: UserAdminOrEmployer) {
  return getProps(getPropsForRole(userRole), status);
}

export function getPaymentStatusLabel(status: PaymentStatus, userRole: UserAdminOrEmployer) {
  return getPaymentStatusBadgeProps(status, userRole).label;
}

export function getPaymentStatusBadgeType(status: PaymentStatus, userRole: UserAdminOrEmployer) {
  return getPaymentStatusBadgeProps(status, userRole).type;
}

/**
 * Given a label, it return the list of statuses that have that label.
 * @param {String} statusLabel
 * @param {String} userRole - from src/domains/registration/auth/constants
 * @return {Array}
 */
export function getPaymentStatusesByLabel(
  statusLabel: string,
  userRole: UserAdminOrEmployer
): PaymentStatus[] {
  const props = getPropsForRole(userRole);

  const entries = Object.entries(props) as Entries<typeof props>;

  return entries.filter(([, value]) => value.label === statusLabel).map(([key]) => key);
}

/**
 * Given an array of labels, it return the list of statuses that have those labels.
 * @param {Array} array of statusLabels
 * @param {String} userRole - from src/domains/registration/auth/constants
 * @return {Array}
 */
export function getPaymentStatusesByLabelArray(
  statusLabelArray: string[],
  userRole: UserAdminOrEmployer
): PaymentStatus[] {
  const props = getPropsForRole(userRole);

  const entries = Object.entries(props) as Entries<typeof props>;

  return entries.filter(([, value]) => statusLabelArray.includes(value.label)).map(([key]) => key);
}

export function getLabelsFromStatusArray(
  statusArray: string[],
  userRole: UserAdminOrEmployer
): string[] {
  const props = getPropsForRole(userRole);
  const entries = Object.entries(props) as Entries<typeof props>;
  const filtered = entries
    .filter(([key]) => statusArray.includes(key))
    .map(([, value]) => value.label);
  // Payment statuses can share label value despite different underlying status e.g. 'Payment failed'
  return [...new Set(filtered)];
}

/**
 * There are some status that we don't allow to be selected when editing the
 * status of an outstanding payment. Examples:
 * - 'completed': because the completion of a payment is a whole process on the backend,
 * which triggers many automations for the contractor and for the employer. Thus this
 * status should not be set manually.
 * - 'processing': because this status is being set by the backend in the background and should
 * not be set manually.
 * - 'paid_in' for Stripe ACH Credit Transfers, as if customer wrongly pays into Remote account instead, admins shouldn't be able to apply the payment and attempt payout
 *  */
const EDITABLE_PAYMENT_STATUS_OPTIONS = [
  paymentStatus.BLOCKED,
  paymentStatus.CREATED,
  paymentStatus.CREDITED,
  paymentStatus.PAID_IN,
  paymentStatus.SHORT_PAID_IN,
  paymentStatus.PAY_IN_FAILED,
  paymentStatus.PAY_OUT_FAILED,
] as const;

type EditablePaymentStatus = (typeof EDITABLE_PAYMENT_STATUS_OPTIONS)[number];

const generateAllowedStatuses = (
  isPaidWithStripeTransfer: boolean
): Array<EditablePaymentStatus> => {
  return EDITABLE_PAYMENT_STATUS_OPTIONS.filter((status) => {
    return !(
      isPaidWithStripeTransfer &&
      (status === paymentStatus.PAID_IN || status === paymentStatus.SHORT_PAID_IN)
    );
  });
};

type EditablePaymentStatusOptions = {
  value: EditablePaymentStatus;
  label: string;
  badgeType: BadgeType;
};

/**
 * Returns an array of options to be used to edit statuses of outstanding
 * payments. It assumes the user is an admin.
 */
export function getStatusAdminEditOptions({
  isPaidWithStripeTransfer = false,
} = {}): Array<EditablePaymentStatusOptions> {
  const allowedStatuses = generateAllowedStatuses(isPaidWithStripeTransfer);

  return allowedStatuses.map((status) => {
    const detailedBadge = detailedBadgeProps[status];

    return { value: status, label: detailedBadge.label, badgeType: detailedBadge.type };
  });
}

export const PAYMENT_COMPLETED_STATUSES = [
  paymentStatus.COMPLETED,
  paymentStatus.CREDITED,
  paymentStatus.REFUNDED,
];

export const PAYMENT_WITH_ERROR_STATUSES: Partial<PaymentStatus>[] = [
  paymentStatus.BLOCKED,
  paymentStatus.DISPUTED,
  paymentStatus.PARTIAL_FAILURE,
  paymentStatus.PAY_IN_FAILED,
  paymentStatus.PAY_OUT_FAILED,
  paymentStatus.SHORT_PAID_IN,
];

export function sortBadgesByStatusTypeAndLabel<
  T extends string,
  U extends string,
  V extends Array<{ badgeType: T; label: U }>
>(badgeProps: V) {
  return badgeProps.sort(
    (a, b) => a.badgeType.localeCompare(b.badgeType) || a.label.localeCompare(b.label)
  );
}

export type BadgeOption = {
  label: string;
  value: string;
  badgeType: BadgeType;
  statuses: PaymentStatus[];
};

export const getStatusFilterOptions = (userRole: UserAdminOrEmployer): BadgeOption[] => {
  const statusFilters = (Object.entries(getPropsForRole(userRole)) as Entries<BadgePropsByStatus>)
    .map(([key, { label, type }]) => ({
      value: label,
      label,
      badgeType: type,
      status: key,
    }))
    .reduce<
      Array<{
        value: string;
        label: string;
        badgeType: BadgeType;
        statuses: Array<PaymentStatus>;
      }>
    >((optionsArray, optionWithStatus) => {
      const existing = optionsArray.find((option) => option.label === optionWithStatus.label);

      if (existing) {
        existing.statuses.push(optionWithStatus.status);
        return optionsArray;
      }

      return [
        ...optionsArray,
        {
          value: optionWithStatus.value,
          label: optionWithStatus.label,
          badgeType: optionWithStatus.badgeType,
          statuses: [optionWithStatus.status],
        },
      ];
    }, []);

  return sortBadgesByStatusTypeAndLabel(statusFilters);
};
