import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import cls from 'cls-hooked';
import get from 'lodash/get';
import set from 'lodash/set';
import snakecaseKeys from 'snakecase-keys';

import { getParamsAsFormData } from '@/src/helpers/api';
import { isDev, error } from '@/src/helpers/general';
import { SERVICE_CONFIG, getServiceConfiguration, SERVICES_KEYS } from '@/src/services/defaults';

import { onAuthFail } from './onAuthFail';
import { transformJsonSchema as transformJsonSchemaFn } from './transformJsonSchema';
// Value obtained by analyzing the slowest response-time for Tiger
const SERVER_TIMEOUT = 5000;

// In early 2024 there was a bug in benefits caused by snakecaseKeys behaviour not inline with what BE expects.
//
// Keys including number suffixes, such as `health2024` transformed with library were unaffected (stayed untransformed),
// while BE expected `health_2024`. Since this was in contract_details.details update, we've ended with several
// broken records in the database.
//
// Setting splitRegexp on `snakecaseKeys` library makes consistent with the BE.
// See also: https://github.com/blakeembrey/change-case/issues/276
const SPLIT_REGEXP = [
  // split between lowercase letters and uppercase/digits (e.g. health2024 -> health_2024)
  /([a-z])([A-Z0-9])/g,
  // split between digits and uppercase letters afterwards (e.g. 1099Form -> 1099_form)
  /([0-9])([A-Z])/g,
  // split between the uppercase letter and uppercase+lowercase combination (e.g. agreesToPTOAmount -> agrees_to_pto_amount)
  /([A-Z])([A-Z][a-z])/g,
];

const getAxiosTimeout = () => {
  const isClient = typeof window !== 'undefined';

  if (!isClient) {
    return SERVER_TIMEOUT;
  }

  return 0;
};

/* -------------------------------------------------------------------------- */
/*                               Axios instance                               */
/* -------------------------------------------------------------------------- */

/**
 *
 * @deprecated use makeApiService
 * This applies only to methods .get, .post, .patch, .put, and .delete
 * Know more at: https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/2635
 */
export const ApiClient = axios.create({
  baseURL: SERVICE_CONFIG.TIGER.baseURL,
  withCredentials: true,
  headers: SERVICE_CONFIG.TIGER.headers,
  timeout: getAxiosTimeout(),
});

ApiClient.interceptors.request.use((config) => {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    const contextNamespace = cls.getNamespace('context');
    const cookie = contextNamespace?.get('cookie');

    if (cookie) {
      config.headers.Cookie = cookie;
    }
  }

  return config;
});

/* -------------------------------------------------------------------------- */
/*                               Interceptors                                 */
/* -------------------------------------------------------------------------- */

let interceptorReference = null;

export function installAuthInterceptor() {
  if (interceptorReference === null) {
    interceptorReference = ApiClient.interceptors.response.use((resp) => resp, onAuthFail);
  }
}

export function removeAuthInterceptor() {
  ApiClient.interceptors.response.eject(interceptorReference);
  interceptorReference = null;
}

export const transformResponse = (data, allOptions) => {
  const { transformJsonSchema, ...options } = allOptions || {};

  let response = camelcaseKeys(data, options);

  if (transformJsonSchema) {
    // transformJsonSchema can be a boolean or an object. If the latter, the object is used as config
    const config = transformJsonSchema === true ? {} : transformJsonSchema;
    const {
      // By default all endpoints include the JSON Schema at data.schema, but
      // some of them are in a different path, so we need to allow that override.
      // Check unit tests to see examples.
      jsonSchemaPath = 'data.schema',
      ...transformConfig
    } = config;
    const jsonSchema = get(data, jsonSchemaPath);

    if (!jsonSchema) {
      error(
        'The endpoint response does not have a JSON Schema at "data.schema". Double-check the response. If your schema is in a different path, set it at the it at serviceOptions transformJsonSchema.jsonSchemaPath config.'
      );
    } else {
      const schemaTransformed = transformJsonSchemaFn(jsonSchema, transformConfig);
      response = set(response, jsonSchemaPath, schemaTransformed);
    }
  }

  return response?.data ? response.data : response;
};

/**
 * @typedef {Object} ApiServiceConfig
 * @property {String} path - the server URL that will be used for the request
 * @property {String} method - request method to be used when making the request. Defaults to GET.
 * @property {Boolean} asFormData - converts the payload to FormData format. Defaults to true.
 * @property {Boolean} deepTransformResponse - camelCase recursively a response. Defaults to true.
 * @property {Object} transformResponseOptions - camelcase-keys options
 * @property {Object} transformParamsOptions - snakecase-keys options
 * @property {Boolean|Object} transformJsonSchema - Transforms JSON Schema to camelCase with specific rules.
 * @property {String[]} transformJsonSchema.excludedFields - Excludes fields from transformation.
 * @property {String} transformJsonSchema.jsonSchemaPath - The path where the JSON Schema is located. Default is "data.schema".
 * @property {import('axios').ParamsSerializerOptions} [paramsSerializer] - paramsSerializer config of axios
 * @property {Boolean} toSnakeCase - snakeCase body/path/query parameters
 * @property {String} version - which API version be use. Defaults to v1.
 * @property {Boolean} ignoreTransformResponse - if true, the BE response won't be transformed. Useful for fetching non-JSON data. Defaults to false.
 * @property {Boolean} useAuthentication - if true, set access token as `Authorization` header when available. Defaults to false.
 */

/**
 * API Service factory.
 *
 * Factory function aimed to simplify and unify service creation and service parameters.
 *
 * We would also remove one nested `data` property,
 * so that the response data would be located at the root level of the response.
 *
 * Example:
 * const filesService = = makeApiService({ path: '/files' })
 *
 * ...
 * const { data, status } = await filesService({ queryParams: { ... }});
 *
 * data.files → [...]
 * data.totalPages →  4;
 * status → 200
 *
 * @param {ApiServiceConfig} serviceConfiguration
 * @returns {function} ApiService function
 */

export function makeApiService(serviceConfiguration) {
  const {
    path,
    method = 'get',
    asFormData = true,
    deepTransformResponse = true,
    toSnakeCase = true,
    transformResponseOptions = {},
    transformParamsOptions = {},
    transformJsonSchema = false,
    paramsSerializer,
    version,
    service = SERVICES_KEYS.TIGER,
    ignoreTransformResponse = false,
    skipCustomAuthInterceptor = false,
    useAuthentication = false,
    responseType = null,
    timeout,
  } = serviceConfiguration;

  return async (requestConfig = {}) => {
    const {
      pathParams,
      queryParams,
      bodyParams,
      headers: requestHeaders,
      signal,
      overrideAsFormData,
    } = requestConfig;

    const isFormDataPayload = overrideAsFormData ?? asFormData;

    const url = path.replace(/\[(.*?)\]/g, (_, $1) => pathParams[$1]);

    const {
      defaultVersion,
      availableVersions,
      headers: serviceHeaders,
      ...serviceConfig
    } = getServiceConfiguration(service);

    if (version && !availableVersions.includes(version)) {
      console.error(`API version '${version}' is not supported by this API service.`);
    }

    const urlPrefix = (version || defaultVersion) ?? '';

    const axiosRequestConfig = {
      method,
      url: `${urlPrefix}${url}`,
      responseType,
      transformResponse: ignoreTransformResponse
        ? (rawResponse) => rawResponse
        : // Make use of Axios' built-in JSON parsing and error-handling setup.
          axios.defaults.transformResponse.concat((data) =>
            transformResponse(data, {
              ...transformResponseOptions,
              transformJsonSchema,
              deep: deepTransformResponse,
            })
          ),
      signal,
      skipCustomAuthInterceptor,
      useAuthentication,
      timeout,
      paramsSerializer,
      ...serviceConfig,
    };

    if (serviceHeaders === null || requestHeaders === null) {
      axiosRequestConfig.headers = null;
    } else {
      axiosRequestConfig.headers = {
        ...serviceHeaders,
        ...requestHeaders,
      };
    }

    // there's one edge case in the app where we need to skip snakecaseKeys. More details https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/2999
    if (queryParams) {
      axiosRequestConfig.params = toSnakeCase
        ? snakecaseKeys(queryParams, transformParamsOptions)
        : queryParams;
    }

    if (bodyParams) {
      const bodyParamsPayload = toSnakeCase
        ? snakecaseKeys(bodyParams, {
            parsingOptions: { splitRegexp: SPLIT_REGEXP },
            ...transformParamsOptions,
          })
        : bodyParams;

      // for initial implementation, we would encode every single
      // body in FormData. In some cases this might be conditional,
      // so feel free to add this if you need it.
      axiosRequestConfig.data = isFormDataPayload
        ? getParamsAsFormData(bodyParams, toSnakeCase, transformParamsOptions)
        : bodyParamsPayload;
    }

    return ApiClient.request(axiosRequestConfig);
  };
}

/**
 * Similar API to the makeApiService, this function allows creating mocked versions of the APIs, which is
 * handy while backend API is still in development.
 * @param {*} _
 * @param {*} mockResponse
 * @returns
 */
export function makeMockedApiService({ path, method }, mockResponse) {
  return ({ pathParams, queryParams, bodyParams } = {}) => {
    if (console && isDev()) {
      // eslint-disable-next-line no-console
      console.log(`Calling mocked api ${method} ${path}`, {
        pathParams,
        queryParams,
        bodyParams,
      });
    }

    return new Promise((resolve) => {
      setTimeout(() => resolve(mockResponse), 200);
    });
  };
}
