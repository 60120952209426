import Image from 'next/image';
import type { ElementType, ReactNode, ComponentProps } from 'react';
import type { Merge } from 'type-fest';

import {
  Container,
  Body,
  Title,
  Description,
  Actions,
  SecondaryLink,
  Illustration,
} from '@/src/components/AppShell/theme/NoResults';
import type { publicAssetPaths } from '@/src/constants/publicAssetPaths';
import { staticAssetUrl } from '@/src/helpers/general';

type IllustrationSrc = (typeof publicAssetPaths)[number];

type Props = Merge<
  ComponentProps<typeof Container>,
  {
    query?: string;
    Illustration?: ElementType;
    illustrationSrc?: IllustrationSrc;
    description?: ReactNode;
    title?: ReactNode;
    actions?: ReactNode;
    secondaryLinkProps?: {
      href: string;
      label: string;
    };
    children?: ReactNode;
  }
>;

export function NoResults({
  Illustration: IllustrationImage,
  illustrationSrc,
  query = '',
  description = 'Try changing your filters or adjusting your search.',
  title,
  actions,
  secondaryLinkProps,
  children,
  ...rest
}: Props) {
  const finalTitle = title || `No results found ${query.length > 0 ? `for ${query}` : ''}`;

  return (
    <Container data-testid="noResultsMessage" {...rest}>
      {IllustrationImage ? (
        <Illustration as={IllustrationImage} />
      ) : (
        <Image
          src={staticAssetUrl(illustrationSrc || '/images/illustrations/no-results.webp')}
          alt=""
          width={232}
          height={180}
        />
      )}
      <Body>
        <Title>{finalTitle}</Title>
        <Description>{description}</Description>
        {secondaryLinkProps && (
          <SecondaryLink label={secondaryLinkProps.label} href={secondaryLinkProps.href} />
        )}
      </Body>
      {actions && <Actions>{actions}</Actions>}
    </Container>
  );
}
