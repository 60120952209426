import isEmpty from 'lodash/isEmpty';

import type { API } from '@/src/api/config/api.types';
import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import { userCanOperators } from '@/src/components/UserCan/UserCan';
import type { PermissionRule, UserCanOperators } from '@/src/components/UserCan/UserCan';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import defaultFeatureMap from '@/src/config/roleAuthorization';
import { companyStatus, companySignupSource } from '@/src/domains/companies/constants';
import { employmentType } from '@/src/domains/employment/constants';
import { productEmploymentTypes } from '@/src/domains/pricing/constants';
import {
  employeePersona as employeePersonaEnum,
  employeePersonaLabels,
  signUpSourceType,
  companyAdminValues,
  USER_TYPE,
  userTypeLabels,
  userStatus,
  TWO_FACTOR_AUTHENTICATION_ENABLED,
} from '@/src/domains/registration/auth/constants';
import type { Permissions } from '@/src/domains/registration/auth/constants/permissions';
import { permissions } from '@/src/domains/registration/auth/constants/permissions';
import { termsOfServiceLocation } from '@/src/domains/registration/terms-of-service/constants';
import { userCacheKeys } from '@/src/domains/userCache/constants';
import { getFromUserCache } from '@/src/domains/userCache/helpers';
import type { $TSFixMe } from '@/types';
/**
 * Helper function to verify if the user already completed the onboarding.
 * @param {Object} user
 */
export function isUserOnboarded(user: $TSFixMe) {
  return ![userStatus.INITIATED, userStatus.CREATED].includes(user?.status);
}

/**
 * Helper function to verify if the the user had access to the dashboard.
 * @param {Object} user
 */

export function isUserAbleToAccessDashboard(user: $TSFixMe) {
  if (user.role === USER_TYPE.EMPLOYER) {
    /**
     * If the user is still in the signup process (part one) or has not confirmed their email address,
     * and they haven’t selected any product intentions, we should prevent them from accessing the dashboard.
     *
     * Otherwise, we use the companyStatus to verify that the company exists and can access the dashboard.
     */
    if (
      user?.companyStatus === companyStatus.INITIATED ||
      user?.companyStatus === companyStatus.PENDING
    ) {
      return !!user?.companySignupProductPreference;
    }

    return !!user?.companyStatus;
  }
  if (user.role === USER_TYPE.CANDIDATE) {
    /**
     * Candidates that are able to login can always access the dashboard, there are no
     * onboarding tasks for candidates and in the future they should not even use Employ
     * at all.
     * Not allowing candidates access to the dashboard would cause a redirect loop.
     * This is because `getUserOnboardingPage` would return the dashboard again as
     * onboarding page, since there is none for candidates.
     */
    return true;
  }
  return isUserOnboarded(user);
}

export const isEmployerMissingEmailConfirmation = (user: $TSFixMe) => {
  return (
    user?.role === USER_TYPE.EMPLOYER &&
    user?.status === userStatus.CREATED &&
    user?.companyStatus === companyStatus.INITIATED
  );
};

/**
 * Helper function to verify if the user's company is active.
 * @param {Object} user
 */
export function isCompanyActive(user: $TSFixMe) {
  return user?.companyStatus === companyStatus.ACTIVE;
}

/**
 * Helper function to verify if the user's company is in review.
 * @param {Object} user
 */
export function isCompanyInReview(status: $TSFixMe) {
  return status === companyStatus.REVIEW;
}

/**
 * Helper function to verify if the user's company is in a pending state.
 * @param {Object} user
 */
export function isCompanyPending(user: $TSFixMe) {
  return user?.companyStatus === companyStatus.PENDING;
}

/**
 * Helper function to verify if the user's company is only using Remote Talent.
 *
 * A company using only Talent is identified by only having completed
 * the first step of the two-part signup flow, and having signed up through
 * Remote Talent, indicated by the signup source.
 *
 * After completing the second step of the two-part signup flow the company can
 * use all remaining feature as well, leaving its Talent only status.
 * @param {Object} user
 */
export function isTalentCompany(user: $TSFixMe) {
  return (
    (isEmployerMissingEmailConfirmation(user) || isCompanyPending(user)) &&
    user?.companySignupSource === companySignupSource.REMOTE_TALENT
  );
}

/**
 * Employers can only access the dashboard once they've created a company.
 * @param {Object} user
 */
export function isEmployerWithCompanyCreated(user: $TSFixMe) {
  return user?.role === USER_TYPE.EMPLOYER && !!user.company;
}

/**
 * Helper function to verify if the user's company has accepted the terms of service.
 * @param {Object} company
 */
export function hasCompanyAcceptedTermsOfService(company: $TSFixMe) {
  return !!company?.termsOfServiceAcceptedAt;
}

export function isCompanyTermsOfServiceOffPlatform(company: $TSFixMe) {
  return company?.termsOfServiceLocation === termsOfServiceLocation.OFF_PLATFORM;
}

export function hasCompanySignedMSA(company: $TSFixMe) {
  return !!company?.msaEffectiveDate;
}

/**
 * Helper function to verify if the user's company has accepted the terms of service.
 * Or is Eligible to accept the terms of service
 * @param {Object} company
 */
export function isCompanyEligibleForTermsOfService(company: $TSFixMe) {
  return hasCompanyAcceptedTermsOfService(company) || isCompanyInReview(company?.status);
}

function getEmployeePersona(activeEmployment: $TSFixMe, signupSource: $TSFixMe) {
  if (activeEmployment?.type === employmentType.FULL_TIME) {
    return employeePersonaEnum.EMPLOYEE;
  }

  if (activeEmployment?.type === employmentType.DIRECT) {
    return employeePersonaEnum.EMPLOYEE;
  }

  if (signupSource === signUpSourceType.SELF_SIGN_UP) {
    return employeePersonaEnum.FREELANCER;
  }

  return employeePersonaEnum.CONTRACTOR;
}

/**
 * Get user role
 *
 * @param {Object} user The user object
 */
export function getUserRole(user: UserAccount) {
  const employeePersona = ((userRole) => {
    switch (userRole) {
      case USER_TYPE.EMPLOYEE:
        return getEmployeePersona(user?.activeEmployment, user?.signupSource);
      case USER_TYPE.FREELANCER:
        return employeePersonaEnum.FREELANCER;
      case USER_TYPE.CANDIDATE:
        return employeePersonaEnum.CANDIDATE;
      default:
        return null;
    }
  })(user?.role);

  return {
    role: user?.role,
    teamMember: user?.teamMember,
    employeePersona,
  };
}

export function isEmployee(user: UserAccount | undefined) {
  if (!user) {
    return false;
  }

  const { employeePersona } = getUserRole(user);
  return employeePersona === employeePersonaEnum.EMPLOYEE;
}

export function isCandidate(user: UserAccount | undefined) {
  if (!user) {
    return false;
  }

  const { employeePersona } = getUserRole(user);
  return employeePersona === employeePersonaEnum.CANDIDATE;
}

export const isCandidateMissingEmailConfirmation = (user: UserAccount | undefined) => {
  if (!user) {
    return false;
  }
  const { employeePersona } = getUserRole(user);
  return employeePersona === employeePersonaEnum.CANDIDATE && user?.status === userStatus.CREATED;
};

export function isContractor(user: UserAccount | undefined) {
  if (!user) {
    return false;
  }

  const { employeePersona } = getUserRole(user);
  return employeePersona === employeePersonaEnum.CONTRACTOR;
}

// Return true if a user has contractor role and signed-up by themselves
export function isFreelancer(user: $TSFixMe) {
  const { employeePersona } = getUserRole(user);
  return employeePersona === employeePersonaEnum.FREELANCER;
}

export function isServiceProvider(user: $TSFixMe) {
  return user.role === USER_TYPE.SERVICE_PROVIDER;
}

export function isEmployer(user: $TSFixMe) {
  return user?.role === USER_TYPE.EMPLOYER;
}

/**
 * Internal Remote (People Team) employer user
 * @param {Object} user
 * @returns {Boolean} Whether the user is an internal employer
 */
export function isInternalEmployer(user: $TSFixMe) {
  return isEmployer(user) && !!getFromUserCache(user, userCacheKeys.COMPANY_DATA)?.isInternal;
}

/**
 * Internal Remote employee user
 * @param {Object} user
 * @returns {Boolean} Whether the user is an internal employee
 */
export function isInternalEmployee(user: $TSFixMe) {
  return isEmployee(user) && user.isInternal;
}

/**
 * Direct employee user
 * @param {Object} user
 * @returns {Boolean} Whether the user is a direct employee
 */
export function isDirectEmployee(user: $TSFixMe) {
  return isEmployee(user) && user?.activeEmployment?.type === employmentType.DIRECT;
}

export function isAdmin(user: $TSFixMe) {
  return user?.role === USER_TYPE.ADMIN;
}

export function isEmployerAdmin(user: $TSFixMe) {
  return isEmployer(user) && user?.teamMember?.role === companyAdminValues.ADMIN;
}

export function isCompanyOwner(user: $TSFixMe) {
  return user?.teamMember?.role === companyAdminValues.OWNER;
}

export function isEmployerAdminOrCompanyOwner(user: $TSFixMe) {
  return isEmployerAdmin(user) || isCompanyOwner(user);
}

export function isLegalRepresentative(user: $TSFixMe) {
  return !!user?.teamMember?.legalRepresentative;
}

export function isUserGlobalPayroll(user: $TSFixMe) {
  return user?.activeEmployment?.product?.employmentType === productEmploymentTypes.GLOBAL_PAYROLL;
}

export function isSLATeamPlanCustomer(company: $TSFixMe) {
  return company?.hasTeamDeal;
}

export function isSCA2FARequired(user: API.UserAccountResponse['data'] | undefined) {
  return user?.scaRequirements?.twoFactorRequired;
}

export function isActivityTracked(user: API.UserAccountResponse['data'] | undefined) {
  return user?.scaRequirements?.activityTracking;
}

/**
 * @deprecated
 *
 * Check if user has a role which allows to perform operation on feature
 *
 * @param operation The kind of operation allowed. create, read, update, delete
 * @param resource The resource id. E.g. personal_details
 * @param user The user object
 * @param user.role The user role
 * @param permissionsMap Fallback permissions map `{ role: resource_id: [ operation, ] }`
 */
function userRoleCan<PermissionName extends keyof Permissions>(
  operation: keyof Permissions[PermissionName],
  resource: PermissionName,
  user: $TSFixMe = {},
  permissionsMap?: Record<string, any>
) {
  const { role, employeePersona } = getUserRole(user);

  if (!role) {
    return false;
  }

  let allowedFeatures = null;
  const features = permissionsMap || defaultFeatureMap;

  if (employeePersona !== null) {
    allowedFeatures = features.employeePersona[employeePersona] || {};
  } else {
    // @ts-expect-error breaks because "role" is any
    allowedFeatures = features[role] || {};
  }

  const hasFeature = typeof allowedFeatures[resource] !== 'undefined';

  return hasFeature && allowedFeatures[resource].includes(operation);
}

export type AdminPermissionName = keyof Omit<Permissions, 'employer'>;
export type EmployerPermissionName = keyof Permissions['employer'];
export type PermissionName = AdminPermissionName | EmployerPermissionName;

type AdminPermissions<Resource extends AdminPermissionName> = keyof Permissions[Resource];

type EmployerPermissions<Resource> = Resource extends keyof Permissions['employer']
  ? keyof Permissions['employer'][Resource]
  : never;

export type PermissionOperation<Resource extends PermissionName> =
  | AdminPermissions<Resource>
  | EmployerPermissions<Resource>;

/**
 * Check if user can perform operation on feature
 *
 * @param operation The kind of operation allowed. create, read, update, delete
 * @param resource The resource id. E.g.  benefits
 * @param user The user object
 * @param user.role The user role
 * @param permissionsMap Fallback permissions map `{ role: resource_id: [ operation, ] }`
 */
export function userCan<TPermissionName extends PermissionName>(
  operation: PermissionOperation<TPermissionName>,
  resource: TPermissionName,
  user: $TSFixMe = {},
  permissionsMap?: Record<string, any>
): boolean {
  // Check if current user role has been migrated to Permissions SSoT (https://gitlab.com/groups/remote-com/-/epics/312)
  // and if not forward to old permission check method

  if (isEmpty(user.permissions)) {
    return userRoleCan(operation, resource, user, permissionsMap);
  }

  // In case of a breaking change in tiger permission system, resource might come undefined
  if (process.env.NODE_ENV !== 'production' && typeof resource === 'undefined') {
    throw new Error('Invalid permission check: missing "resource" argument');
  }

  // Check if feature and operation are supported by Tiger
  const resourceOperations = permissions[resource];

  if (!resourceOperations || !resourceOperations[operation]) {
    // while permission migration to tiger is happening, return false
    // after migration is done we should just return false and log it to DD
    // debug(`Warning: Invalid operation ${operation} on feature ${feature}.`);
    return false;
  }

  return !!(user.permissions[resource] || []).find(
    (permission: $TSFixMe) => permission === operation
  );
}

/**
 * Format user role
 *
 * Transforms service_provider into Service provider
 * @param {String} role The user role
 */
export function formatUserRole(user: UserAccount | undefined) {
  if (!user) {
    return '';
  }

  const { role, employeePersona } = getUserRole(user);

  if (employeePersona !== null) {
    return employeePersonaLabels[employeePersona];
  }

  return userTypeLabels[role] ?? '';
}

export function hasCompanyProduct(companyData: $TSFixMe = {}, productEmploymentType: $TSFixMe) {
  const filterProducts = (product: $TSFixMe) => product.employmentType === productEmploymentType;

  return companyData?.products?.filter(filterProducts).length > 0;
}

/**
 * Append query params object to a url
 * @param {string} url
 * @param {Object} query - next/router query object
 * @returns {string} Url with query params
 */
export function urlAppendQuery(url: $TSFixMe, query = {}) {
  const queryAsString = new URLSearchParams(query).toString();
  return queryAsString ? `${url}?${queryAsString}` : url;
}

export const isContractorDeactivated = (user: $TSFixMe = {}) =>
  isContractor(user) && user.status === userStatus.INACTIVE;

export const checkPermissions = (
  user?: UserAccountResponse,
  userPermissions?: PermissionRule[],
  operator: UserCanOperators = userCanOperators.ALL,
  additionalCondition: boolean = true
): boolean => {
  if (!additionalCondition) return false;
  if (isEmpty(userPermissions) || !userPermissions) return true;
  if (operator === userCanOperators.ALL) {
    return userPermissions.every(([operation, resourceName]) =>
      userCan(operation, resourceName, user)
    );
  }
  return userPermissions.some(([operation, resourceName]) =>
    userCan(operation, resourceName, user)
  );
};

/**
 * Checks if the provided error object indicates a two-factor authentication error.
 *
 * @param errorObject - The error object to check.
 * @returns `true` if the error object indicates a two-factor authentication error, otherwise `false`.
 */
export function isTwoFactorAuthenticationError(errorObject: $TSFixMe) {
  const errorData = errorObject?.response;
  const hasTwoFactorAuthentication =
    errorData?.status === 401 && errorData?.data?.code === TWO_FACTOR_AUTHENTICATION_ENABLED;

  return hasTwoFactorAuthentication;
}
