import type { GetResponse, UsePostOptions, UseDeleteOptions } from '@remote-com/data-layer';
import { createQueryKey, usePost, useDelete } from '@remote-com/data-layer';
import { toast } from '@remote-com/norma';
import { useQueryClient } from 'react-query';

const favoritesPath = '/api/v1/account/settings/favorites' as const;
const favoritesDeletePath = '/api/v1/account/settings/favorites/[slug]' as const;

type FavoritePostOptions = UsePostOptions<typeof favoritesPath> & {
  onUpdate?: (bookmarked: boolean) => unknown;
};

type FavoriteDeleteOptions = UseDeleteOptions<typeof favoritesDeletePath> & {
  onUpdate?: (bookmarked: boolean) => unknown;
};

type UseUserSettingsFavoriteMutateOptions = Omit<
  FavoritePostOptions | FavoriteDeleteOptions,
  'onMutate' | 'onError'
>;

export function useUserSettingsFavoriteMutate({
  onUpdate,
  ...options
}: UseUserSettingsFavoriteMutateOptions) {
  const queryClient = useQueryClient();
  const getFavoritesQueryKey = createQueryKey(favoritesPath);
  function updateQueryData(path: string, name: string, sortOrder: number) {
    queryClient.setQueriesData<GetResponse<typeof favoritesPath> | undefined>(
      getFavoritesQueryKey,
      (prev) => {
        if (!prev) {
          return undefined;
        }

        return {
          data: [{ path, name, slug: name, sortOrder }, ...(prev.data || [])],
        };
      }
    );
  }

  const setFavorited = usePost<typeof favoritesPath>(favoritesPath, {
    ...(options as FavoritePostOptions),
    onMutate: async ({ bodyParams: { path, name, sortOrder } }) => {
      updateQueryData(path, name, sortOrder);
    },
    onSuccess: () => {
      toast.success('Favorite added');
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: getFavoritesQueryKey, exact: true });
    },
  });

  const deleteFavorited = useDelete<typeof favoritesDeletePath>(favoritesDeletePath, {
    ...(options as FavoriteDeleteOptions),
    onMutate: async ({ pathParams: { slug } }) => {
      queryClient.setQueriesData<GetResponse<typeof favoritesPath> | undefined>(
        getFavoritesQueryKey,
        (prev) => {
          if (!prev) {
            return undefined;
          }

          return {
            data: prev.data?.filter((favorite) => favorite.slug !== slug),
          };
        }
      );
    },
    onSuccess: () => {
      toast.success('Favorite removed');
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: getFavoritesQueryKey, exact: true });
    },
  });

  return { setFavorited, deleteFavorited };
}
