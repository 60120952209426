/* eslint-disable import/no-extraneous-dependencies */
import type { Options as CamelCaseKeysOptions } from 'camelcase-keys';
import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext } from 'react';
import type { Options as SnakeCaseKeysOptions } from 'snakecase-keys';

import { createServiceOptions } from './utils';

type ServiceConfigProps = {
  method?: string;
  path?: string;
  version?: string;
  asFormData: string;
  deepTransformResponse: boolean;
  transformJsonSchema:
    | boolean
    | {
        jsonSchemaPath: string;
        excludedFields: string[];
      };
  transformResponseOptions: CamelCaseKeysOptions;
  transformParamsOptions: SnakeCaseKeysOptions;
  toSnakeCase: boolean;
  ignoreTransformResponse: boolean;
};

type TApiServiceContext = (config: ServiceConfigProps) => (requestConfig: unknown) => Promise<any>;

export type HttpMethod = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

const ApiServiceContext = createContext<TApiServiceContext>(
  (() => () => Promise.resolve({})) as TApiServiceContext
);

export const useApiService = () => {
  const apiService = useContext(ApiServiceContext);

  const createService = useCallback(
    (path: string, method: HttpMethod) => {
      const serviceOptions = createServiceOptions(path, method);
      // @ts-expect-error need to convert data-layer/utils.js to TS
      return apiService(serviceOptions);
    },
    [apiService]
  );

  return {
    createService,
  };
};

export const ApiServiceProvider = ({
  apiService,
  children,
}: PropsWithChildren<{
  apiService: (config: ServiceConfigProps) => (requestConfig: unknown) => Promise<any>;
}>) => {
  return <ApiServiceContext.Provider value={apiService}>{children}</ApiServiceContext.Provider>;
};

export default ApiServiceContext;
