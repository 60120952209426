/**
 * Feature Flags are forced through cookies so we can read them on SSR
 */
import { Box, Stack, Text, Tooltip, toast } from '@remote-com/norma';
import { IconAlertTriangle } from '@remote-com/norma/icons/IconAlertTriangle';
import Cookies from 'js-cookie';
import getConfig from 'next/config';
import { useTheme } from 'styled-components';

import { OutlineFail, OutlineSuccess } from '@/src/components/FeedbackIcons';

import type { LegacyFeatureFlagsData, FeatureFlagData, Environment } from './types';

const {
  publicRuntimeConfig: { ENVIRONMENT },
} = getConfig();

export const currentEnv: Environment = ENVIRONMENT !== 'not-set' ? ENVIRONMENT : 'dev';

// Important: this prefix is used to identify the feature flags cookies and it's used by both the legacy and the new feature flags system.
// If, for some reason, the prefix needs to be changed, it should be changed in both systems.
export const FEATURE_FLAG_COOKIE_PREFIX = 'FLAG_';

export const WARNING_COOKIE = 'HIGH_NUMBER_OF_OVERRIDES_WARNING';

/**
 * Creates the name of the cookie for a feature flag override.
 * @param {String} flag - feature flag name
 * @returns
 */
export const getFlagCookieName = (flag: string) => `${FEATURE_FLAG_COOKIE_PREFIX}${flag}`;

/**
 * Function that calculates the domain (not subdomain) of the current hostname.
 * @returns {String} - Current domain
 */
export const getDomainOfHostname = (hostname: string) => {
  const parts = hostname.split('.');

  // Simple TLD (e.g., .com, .org, .net)
  if (parts.length <= 2) {
    return hostname;
  }

  // Case of employ.remote.com, for example
  const tldPartCount = parts[parts.length - 2].length <= 3 ? 2 : 1;

  const domain = parts.slice(parts.length - tldPartCount - 1).join('.');

  return domain;
};

/**
 * Force a feature flag to a given status (active or inactive)
 *
 * @param {String} flag - The name of the flag to force
 * @param {Boolean} newStatus - The desired status of the forced flag
 * @param {Boolean} apiStatus - The "real" (aka API) value of the flag
 * @returns
 */
export const forceFlag = (flag: string, newStatus: boolean, apiStatus: boolean) => {
  const domain = getDomainOfHostname(window.location.hostname);
  const COOKIE_NAME = getFlagCookieName(flag);
  const cookieOptions = { domain };

  if (apiStatus === newStatus) {
    Cookies.remove(COOKIE_NAME, cookieOptions);
    return;
  }

  Cookies.set(COOKIE_NAME, newStatus.toString(), cookieOptions);
};

/**
 * Clear all feature flag overrides
 */
export const clearOverrides = () => {
  const domain = getDomainOfHostname(window.location.hostname);
  const cookies = Object.entries(Cookies.get()).filter(([name]) =>
    name.startsWith(FEATURE_FLAG_COOKIE_PREFIX)
  );
  cookies.forEach(([name]) => {
    Cookies.remove(name, { domain });
  });
};

/**
 * Checks if a flag is forced
 *
 * @param {String} flag - The name of the flag to check
 * @returns {Boolean}
 */
export const isFlagForced = (flag: string) => {
  const COOKIE_NAME = getFlagCookieName(flag);
  return !!Cookies.get(COOKIE_NAME);
};

/**
 * Return forced flag value
 *
 * @param {String} flag - The name of the flag to check
 * @returns {Boolean}
 */
export const getFlagOverwrittenValue = (flag: string) => {
  const COOKIE_NAME = getFlagCookieName(flag);
  const cookieValue = Cookies.get(COOKIE_NAME);

  if (!cookieValue) return null;

  return cookieValue === 'true';
};

/**
 * Get all forced feature flags in development mode
 *
 * @returns {Object} - Object containing all forced feature flags
 */
export const getForcedFlagsInDevMode = () => {
  const cookies = Object.entries(Cookies.get())
    .filter(([name]) => name.startsWith(FEATURE_FLAG_COOKIE_PREFIX))
    .reduce((acc, [name, value]) => {
      const flag = name.replace(`${FEATURE_FLAG_COOKIE_PREFIX}_`, '');
      // @ts-expect-error
      acc[flag] = {
        enabled: value === 'true',
      };
      return acc;
    }, {});
  return cookies;
};

/**
 * Convert the data structure of the feature flags from the deprecated system to the new system
 */
export function convertLegacyDataStructureToNewSystem(
  flags: LegacyFeatureFlagsData['flags']
): FeatureFlagData[] {
  return Object.keys(flags).map((key) => ({
    key,
    enabled: flags[key] === true,
    audience: null,
    rollout_percentage: 0,
  }));
}

/**
 * Handle server exception, both reporting the error and also displaying a toast message to the user
 */
export const handleError = (exceptionError?: string, errorFromDeprecatedSystem?: boolean) => {
  const errorMsg = errorFromDeprecatedSystem
    ? 'Error while fetching (deprecated) feature flags for current environment'
    : 'Error while fetching /config endpoint on current environment';
  toast.error(errorMsg);

  if (exceptionError) {
    // eslint-disable-next-line no-console
    return console.error(exceptionError);
  }
};

/**
 * Renders the proper icon for a flag status on an environment
 * */
const showStatusIcon = (status?: boolean | null) => {
  if (status === null) {
    return null;
  }

  return status ? <OutlineSuccess width="18" /> : <OutlineFail width="18" />;
};

export const FlagStatusCell = ({ enabled }: { enabled: boolean | null }) => {
  const { colors } = useTheme();

  return (
    <Stack gap={3} direction="row" justifyContent="space-between" width="90px">
      {enabled === null ? (
        <Tooltip label="There was an error fetching the flag status for this environment">
          <Stack direction="row" gap={3}>
            <IconAlertTriangle color={colors.error} width="16px" /> <Text>N/A</Text>
          </Stack>
        </Tooltip>
      ) : (
        <Box>{enabled ? 'Enabled' : 'Disabled'}</Box>
      )}
      {showStatusIcon(enabled)}
    </Stack>
  );
};

export const getFeatureFlagOverrideCookies = () => {
  const featureFlagOverrideCookies = Object.entries(Cookies.get()).filter(([name]) =>
    name.startsWith(FEATURE_FLAG_COOKIE_PREFIX)
  );

  return {
    cookies: featureFlagOverrideCookies,
    total: featureFlagOverrideCookies.length,
  };
};

export const isCookieWarningDismissed = () => {
  return !!Cookies.get(WARNING_COOKIE);
};

export const dismissCookieWarning = () => {
  Cookies.set(WARNING_COOKIE, 'true', { expires: 30 });
};
