import type { ProductEmploymentType } from '@/src/api/config/api.types';
import type {
  ProductUsage,
  ProductUsageAndAccess,
} from '@/src/api/config/employ/productUsageAndAccess.types';
import { START_TRIAL_PRODUCT_PLAN_ROUTE } from '@/src/constants/routes';
import {
  PRODUCT_ACCESS_STATE,
  PURCHASE_PRODUCT_URLS,
  PRODUCT_USAGE_STATUS,
} from '@/src/domains/productUsageAndAccess/constants';
import type {
  ProductAccessState,
  FeatureAccessName,
} from '@/src/domains/productUsageAndAccess/types';

/**
 * Find the current or previous product usage for a given employment type.
 * @param productEmploymentType - Legacy naming used to group products.
 * @param products - The products to search through
 * @returns The product usage for the given employment type
 */
export const findProductUsageByEmploymentType = (
  productEmploymentType: ProductEmploymentType,
  products: ProductUsage[]
) => {
  return products.find((product) => product.employmentType === productEmploymentType);
};

/**
 * Determines the access state of a product and its features based on subscription and usage data.
 *
 * This utility evaluates multiple factors to determine the current access state:
 * 1. Subscription Status:
 *    - Whether the product is active, paused, or inactive
 * 2. Trial Status:
 *    - If a trial is active or has expired
 * 3. Feature Usage:
 *    - Whether usage limits have been exceeded for specific features
 *
 * @param params.productUsageAndAccess - Product usage and access data from the API
 * @param params.productEmploymentType - Product type (e.g., 'Recruit')
 * @param params.featureName - Optional Specific feature to check access for
 *
 * @returns ProductAccessState indicating the current access state of the product and or feature.
 */
export function getProductAndFeatureAccessState({
  productUsageAndAccess,
  productEmploymentType,
  featureName,
}: {
  productUsageAndAccess: ProductUsageAndAccess | undefined;
  productEmploymentType: ProductEmploymentType;
  featureName?: FeatureAccessName;
}): ProductAccessState {
  const product = findProductUsageByEmploymentType(
    productEmploymentType,
    productUsageAndAccess?.products || []
  );

  if (!product || !product.activeOrLastActivePricingPlan) {
    return PRODUCT_ACCESS_STATE.NEVER_SUBSCRIBED;
  }

  const feature = featureName ? productUsageAndAccess?.access?.[featureName] : undefined;

  const isFeatureUsageExceeded =
    feature &&
    'used' in feature &&
    'limit' in feature &&
    typeof feature.used === 'number' &&
    typeof feature.limit === 'number' &&
    feature.used >= feature.limit;

  switch (product.status) {
    case PRODUCT_USAGE_STATUS.PAUSED:
      return PRODUCT_ACCESS_STATE.PLAN_PAUSED;

    case PRODUCT_USAGE_STATUS.INACTIVE:
      return product.activeOrLastActivePricingPlan?.isTrial
        ? PRODUCT_ACCESS_STATE.FREE_TRIAL_EXPIRED
        : PRODUCT_ACCESS_STATE.PLAN_EXPIRED;

    case PRODUCT_USAGE_STATUS.ACTIVE: {
      const baseState = product.activeOrLastActivePricingPlan?.isTrial
        ? PRODUCT_ACCESS_STATE.FREE_TRIAL_ACTIVE
        : PRODUCT_ACCESS_STATE.PLAN_ACTIVE;

      return isFeatureUsageExceeded ? PRODUCT_ACCESS_STATE.FEATURE_USAGE_EXCEEDED : baseState;
    }

    default:
      return PRODUCT_ACCESS_STATE.NEVER_SUBSCRIBED;
  }
}

export function getRedirectUrl(
  productAccessState: ProductAccessState | undefined,
  productEmploymentType: ProductEmploymentType,
  product: ProductUsage | undefined
) {
  if (!productAccessState) return undefined;

  if (productAccessState === PRODUCT_ACCESS_STATE.NEVER_SUBSCRIBED) {
    return START_TRIAL_PRODUCT_PLAN_ROUTE.replace('[productEmploymentType]', productEmploymentType);
  }

  const getHref = PURCHASE_PRODUCT_URLS[productAccessState as keyof typeof PURCHASE_PRODUCT_URLS];
  return product ? getHref(productEmploymentType) : undefined;
}
