import Cookies from 'js-cookie';

import { useUserContext } from '@/src/components/UserProvider';
import { permissionsSignatureCookieName } from '@/src/domains/registration/auth/constants';

export function PermissionSignature() {
  const { user } = useUserContext();
  const permissionsSignature = user?.permissionsSignature;
  // This cookies is used to store a signature of the user permissions sent by Tiger.
  // This is used for deciding on escalating or suppressing permissions mismatch errors.
  // See https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/30170 for details
  if (permissionsSignature) {
    Cookies.set(permissionsSignatureCookieName, permissionsSignature, {
      sameSite: 'lax',
      expires: 30,
    });
  }

  return null;
}
