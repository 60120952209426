import { usePost } from '@remote-com/data-layer';

export const usePostFiltersFromAiService = () => {
  const { mutateAsync, data, isError, isLoading } = usePost(
    '/remote-ai/api/v1/generate/smart-search-filter',
    {
      retry: false,
    }
  );

  return { data, isError, isLoading, mutateAsync };
};
