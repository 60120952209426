import { usePost, type UsePostOptions } from '@remote-com/data-layer';
import { toast } from '@remote-com/norma';
import { useRouter } from 'next/router';

import type { ProductEmploymentType } from '@/src/api/config/api.types';
import { PAY_FOR_PLAN_ROUTE } from '@/src/constants/routes';
import type { ModifiedPricingPlan } from '@/src/domains/pricingPlans/common/PricingPlans.types';
import { getPricingPlanAttributesByProductType } from '@/src/domains/pricingPlans/common/utils';
import { useInvalidateTodosQuery } from '@/src/domains/todos/hooks';

export const useCreatePricingPlanQuote = () => {
  const router = useRouter();
  const createProductQuoteMutation = usePost('/api/v1/employer/outstanding-payments/product-quote');

  function mutate({
    productPriceSlug,
    productEmploymentType,
    options,
    intent = 'upgrade',
  }: {
    productPriceSlug: string;
    productEmploymentType: ProductEmploymentType;
    options?: UsePostOptions<'/api/v1/employer/outstanding-payments/product-quote'>;
    intent?: 'upgrade' | 'downgrade';
  }) {
    createProductQuoteMutation.mutate(
      { bodyParams: { productPriceSlug } },
      {
        onSuccess: ({ data }) => {
          router.push({
            pathname: PAY_FOR_PLAN_ROUTE,
            query: {
              productEmploymentType,
              productPriceSlug,
              outstandingPaymentSlug: data.slug,
              intent,
            },
          });
        },
        onError: () => {
          toast.error(
            'We were unable to create a quote for your new plan. Please try again or contact help@remote.com for assistance.'
          );
        },
        ...options,
      }
    );
  }

  return {
    ...createProductQuoteMutation,
    mutate,
  };
};

export const useAcceptPricingPlan = ({
  plan,
  onSuccess,
}: {
  plan: ModifiedPricingPlan;
  onSuccess: () => void;
}) => {
  const { slug: planSlug, productType, productPrice } = plan;
  const { isRecruit } = getPricingPlanAttributesByProductType(productType);

  const acceptPricingPlanMutation = usePost('/api/v1/employer/pricing-plans/[planSlug]/accept');

  // For Recruit plans only
  const createProductQuoteMutation = useCreatePricingPlanQuote();

  const { isLoading, error } = isRecruit ? createProductQuoteMutation : acceptPricingPlanMutation;

  const { invalidateTodosQuery } = useInvalidateTodosQuery();

  const handleAcceptPricingPlan = () => {
    const productPriceSlug = productPrice.slug;

    if (isRecruit) {
      createProductQuoteMutation.mutate({
        productPriceSlug,
        productEmploymentType: productPrice.product.employmentType,
      });
    } else {
      acceptPricingPlanMutation.mutate(
        {
          pathParams: {
            planSlug,
          },
        },
        {
          onSuccess: () => {
            invalidateTodosQuery();

            onSuccess();
          },
        }
      );
    }
  };

  return {
    handleAcceptPricingPlan,
    isLoading,
    error,
  };
};
