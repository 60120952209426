import { Box, Pill, Stack, Text } from '@remote-com/norma';
import type { PillTone } from '@remote-com/norma';
import { IconArrowRight } from '@remote-com/norma/icons/IconArrowRight';

import { Button } from '@/src/components/Button';
import ContentContainer from '@/src/components/Ui/ContentContainer';
import {
  type PageHeaderProps as PageHeaderComponentProps,
  PageHeader,
} from '@/src/components/Ui/Layout/PageHeader';
import type { PublicAssetPath } from '@/src/constants/publicAssetPaths';
import { staticAssetUrl } from '@/src/helpers/general';

import { StyledImage, ContentWrapper } from './PaywallPage.styled';

export type PageHeaderProps = PageHeaderComponentProps;

export type BasePillProps = {
  text: string;
  icon: React.ComponentType;
  tone: PillTone;
};

export type BaseCtaProps = {
  href: string;
  text: string;
  trackNavigation?: string[];
};

export type BaseImgProps = {
  url: PublicAssetPath;
  alt: string;
};

export type BaseContentProps = {
  title: string;
  description: string;
  img: BaseImgProps;
  Footer?: React.ComponentType;
};

export type PaywallPageProps = BaseContentProps & {
  pill: BasePillProps;
  title: string;
  description: string;
  cta: BaseCtaProps & { isHidden?: boolean };
  Footer?: React.ComponentType<any>;
  Callout?: React.ComponentType;

  pageHeader: PageHeaderProps;
};

export function PaywallPage({
  pill,
  title,
  description,
  cta,
  img,
  Footer,
  Callout,
  pageHeader,
}: PaywallPageProps) {
  return (
    <ContentContainer data-dd-privacy="allow">
      {pageHeader && (
        <PageHeader
          siteTitle={pageHeader.siteTitle || null}
          title={pageHeader.title}
          subtitle={pageHeader.subtitle}
          pill={pageHeader.pill}
        />
      )}
      {Callout && <Callout />}
      <Stack
        direction="row"
        bg="blank"
        justifyContent="space-between"
        borderRadius={16}
        height="420px"
      >
        <Box display="flex" flexDirection="column" justifyContent="center" pl={32} flexGrow={1}>
          <ContentWrapper>
            <Pill Icon={pill.icon} tone={pill.tone}>
              {pill.text}
            </Pill>
            <Text variant="xlMedium" as="h2">
              {title}
            </Text>
            <Text variant="sm" color="grey.600" as="p">
              {description}
            </Text>
            {!cta.isHidden && (
              <Button
                trackNavigation={cta.trackNavigation}
                href={cta.href}
                IconAfter={IconArrowRight}
              >
                {cta.text}
              </Button>
            )}
            {Footer && <Footer />}
          </ContentWrapper>
        </Box>
        <Box>
          <StyledImage
            width={420}
            height={420}
            src={staticAssetUrl(img.url)}
            alt={img.alt}
            priority
          />
        </Box>
      </Stack>
    </ContentContainer>
  );
}
