import { EMPTY_INDICATOR } from '@remote-com/norma';
import startsWith from 'lodash/startsWith';
import toLower from 'lodash/toLower';

import { decodeQueryParam } from '@/src/components/Table/helpers';
import { productEmploymentTypes } from '@/src/domains/pricing/constants';
import { CONTRACTOR_RATE_TYPES, CONTRACTOR_RATE_TYPE_UNITS } from '@/src/domains/team/constants';
import { convertToCents, friendlyMoneyWithCurrencyCode } from '@/src/helpers/currency';
import { fetchCompanies } from '@/src/services/Admin';
import { fetchAllCountries } from '@/src/services/Country';

/**
 * Get the company product resource.
 * @param {Array} products
 * @param {string} productName
 */
export function findCompanyProduct(products, productName) {
  return products?.find((product) => product?.employmentType === productName);
}

/**
 * Verify if the company has the product enabled.
 * @param {Array} products
 * @param {string} productName
 */
export function hasProductEnabled(products, productName) {
  const product = findCompanyProduct(products, productName);
  return product ? product.active : false;
}

export function isEmployedThroughGlobalPayroll(employmentType) {
  return employmentType === productEmploymentTypes.GLOBAL_PAYROLL;
}

// Did not include Contract Status or Remote Entity
const AVAILABLE_FILTER_KEYS = {
  COUNTRY: 'country',
  COMPANY: 'company',
  EMPLOYMENT_TYPE: 'type',
  EMPLOYMENT_STATUS: 'status',
};

/**
 * Separate the search query from the filters
 * @param {Array} queryArr
 * @returns {Array} original query string and array of custom filters
 */
export const separateQueryAndFilters = (queryArr = []) => {
  const query = queryArr.filter((splitTerm) => !startsWith(splitTerm, '~')).join(' ');
  const filters = queryArr
    .filter((splitTerm) => startsWith(splitTerm, '~'))
    .map((item) => decodeQueryParam(item));
  return [query, filters];
};

/**
 * Get company from provided name
 * @param {String} companyName
 * @returns {Object}
 */
async function findCompanyByName(companyName) {
  const { data } = await fetchCompanies({
    queryParams: { name: companyName?.replaceAll('"', ''), pageSize: 1 },
  });
  return data.companies[0];
}

/**
 * Add to object if new item has value
 * @param {Object} originalFilters original object
 * @param {String} newFilterKey key for obj
 * @param {String} newFilterValue value for obj
 * @returns {Object}
 */
const addQueryItem = (originalFilters, newFilterKey, newFilterValue) =>
  !newFilterValue ? originalFilters : { ...originalFilters, [newFilterKey]: newFilterValue };

/**
 * Get Country from country name
 * @param {Array} countries list of countries
 * @param {Array} countryName name to match on
 * @returns {Object}
 */
export const getCountryCode = (countries, countryName) =>
  countries?.find((country) => toLower(country?.name) === toLower(countryName));

/**
 * Loop over filter items to return correct query params for People search
 * @param {Object} filters
 * @returns {Object}
 */
export const getParsedFilters = async (filters) => {
  let parsedFilters = {};
  // Need for/of loop here so it is possible to await API results
  // eslint-disable-next-line no-restricted-syntax
  for (const filterItem of filters) {
    const [filterKey, filterValue] = filterItem.substring(1).split(':');
    if (filterValue === '') {
      break;
    }
    switch (filterKey) {
      case AVAILABLE_FILTER_KEYS.COUNTRY:
        {
          const {
            data = [],
            // eslint-disable-next-line no-await-in-loop
          } = await fetchAllCountries();
          const foundCountry = getCountryCode(data, filterValue);
          parsedFilters = addQueryItem(parsedFilters, filterKey, foundCountry?.code);
        }

        break;
      case AVAILABLE_FILTER_KEYS.COMPANY:
        {
          // This one is tricky since it's a search and could return multiple results
          // User could type "Tyrell" and it could return "Tyrell Corp." and "Tyrell Inc."
          // Will use current implementation from filter component and select the first result
          // eslint-disable-next-line no-await-in-loop
          const company = await findCompanyByName(filterValue);
          parsedFilters = addQueryItem(parsedFilters, filterKey, company?.slug);
        }
        break;
      case AVAILABLE_FILTER_KEYS.EMPLOYMENT_TYPE:
        // User will type employee but filter needs full_time
        if (toLower(filterValue) === 'employee') {
          parsedFilters = addQueryItem(parsedFilters, filterKey, 'full_time');
        } else if (toLower(filterValue) === 'contractor') {
          parsedFilters = addQueryItem(parsedFilters, filterKey, filterValue);
        }
        break;
      case AVAILABLE_FILTER_KEYS.EMPLOYMENT_STATUS:
        parsedFilters = addQueryItem(parsedFilters, filterKey, filterValue);
        break;
      default:
        break;
    }
  }
  return parsedFilters;
};

/**
 * Parse special filter values for Advanced People search
 * @param {Object} queryParams
 * @returns {Object}
 */
export const queryParser = async (queryParams) => {
  const { query: userTextSearchParams } = queryParams;
  if (!userTextSearchParams) {
    return queryParams;
  }
  // This will allow the user to add spaces to the query
  // Accepted: ~company:"Tyrell Corp"
  const queryArr = userTextSearchParams?.split(/ +(?=(?:(?:[^"]*"){2})*[^"]*$)/g);
  const [originalQuery, filters] = separateQueryAndFilters(queryArr);
  const parsedFilters = await getParsedFilters(filters);

  return { query: originalQuery, ...parsedFilters };
};

export const formatSelectedCountries = (countries = [], value, valueField = 'slug') =>
  countries
    .filter((country) => value.includes(country[valueField]))
    .map((country) => country.name)
    .join(', ');

export function formatContractorRate(contractorRate) {
  if (!contractorRate) {
    return EMPTY_INDICATOR;
  }

  const formattedAmount = friendlyMoneyWithCurrencyCode(
    convertToCents(contractorRate.amount.amount),
    contractorRate.amount.currency
  );

  return contractorRate.type === CONTRACTOR_RATE_TYPES.ONE_OFF
    ? formattedAmount
    : `${formattedAmount}/${CONTRACTOR_RATE_TYPE_UNITS[contractorRate.type]}`;
}

/**
 * Format an array of filter values into a comma separated list of labels.
 * @param {Object} labelsMap object mapping filter values to their corresponding labels.
 * @param {Array} values array of selected filter values.
 * @returns {String} comma-separated, formatted labels.
 */
export const formatFilterLabelList = (labelsMap, values) => {
  if (Array.isArray(values)) {
    return values.map((value) => labelsMap[value] || value).join(', ');
  }

  return labelsMap[values];
};
