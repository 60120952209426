import type { BadgeType } from '@remote-com/norma';

export const customDiscountOption = { value: 'custom', label: 'Custom' };

export const removeDiscountOption = { value: 'remove', label: 'Remove discount' };

export const subscriptionModalVariants = {
  EDIT: 'edit',
  CREATE: 'create',
} as const;

export const MODAL_VARIANTS = {
  CANCEL: 'CANCEL',
  DELETE: 'DELETE',
  SHARE: 'SHARE',
  VIEW: 'VIEW',
};

export const managePricingPlansInfo = [
  {
    title: 'When sharing a pricing plan',
    instructions: [
      'Once shared with a customer, the pricing plan\'s status changes to "Awaiting customer approval."',
      'After sharing, the plan cannot be removed, only terminated.',
    ],
  },
  {
    title: 'When editing a pricing plan',
    instructions: [
      'A plan can only be edited if its status is "Created" or "Awaiting customer approval."',
      'If the plan has been shared and accepted, but needs modification due to incorrect details or customer rejection, admin can terminate the shared plan and a new plan with the correct details must be created and shared with the customer.',
    ],
  },
  {
    title: 'When terminating/deleting a pricing plan',
    instructions: [
      'If the status is "Created," the admin can hard delete the plan.',
      'If the status is "Awaiting customer approval," the admin can terminate the plan before it is accepted. This action also removes the notification from the customer\'s dashboard, as the plan can no longer be accepted.',
      'When the status is "Active", indicating the customer has accepted the plan, the admin can terminate the plan and the customer can no longer add employees to the plan.',
    ],
  },
];

export const billingCycleTypes = {
  MONTHLY: 'monthly',
  MONTHLY_WITH_EXTRA_FEE: 'monthly_with_extra_fee',
  ANNUAL: 'annual',
  QUARTERLY: 'quarterly',
  QUARTERLY_WITH_EXTRA_FEE: 'quarterly_with_extra_fee',
  ONE_TIME: 'one_time',
} as const;

export const billingCycleOptions = [
  {
    label: 'Annual',
    value: billingCycleTypes.ANNUAL,
  },
  {
    label: 'Quarterly',
    summaryLabel: 'Quarterly cycle with 2.5% fee',
    value: billingCycleTypes.QUARTERLY_WITH_EXTRA_FEE,
    optionInfo: 'Charge customers 2.5% fee',
  },
  {
    label: 'Quarterly',
    value: billingCycleTypes.QUARTERLY,
    optionInfo: 'Do not charge customers 2.5% fee',
  },
];

export const pricingPlanStatus = {
  CREATED: 'created',
  SHARED: 'shared',
  ACCEPTED: 'accepted',
  TERMINATED: 'terminated',
} as const;

export const modifiedPricingPlanStatus = {
  ...pricingPlanStatus,
  ACTIVE: 'active',
} as const;

export const pricingPlanStatusLabels = {
  [modifiedPricingPlanStatus.CREATED]: 'Created',
  [modifiedPricingPlanStatus.SHARED]: 'Awaiting customer approval',
  [modifiedPricingPlanStatus.TERMINATED]: 'Terminated',
  [modifiedPricingPlanStatus.ACCEPTED]: 'Accepted',
  [modifiedPricingPlanStatus.ACTIVE]: 'Active',
};

export const pricingPlanStatusBadgeColors: Record<string, BadgeType> = {
  [modifiedPricingPlanStatus.CREATED]: 'info',
  [modifiedPricingPlanStatus.SHARED]: 'pending',
  [modifiedPricingPlanStatus.ACCEPTED]: 'pending',
  [modifiedPricingPlanStatus.TERMINATED]: 'error',
  [modifiedPricingPlanStatus.ACTIVE]: 'active',
};

export const eorMonthlyTiers = [1, 2, 3, 4, 5] as const;
export const contractorMonthlyTiers = [1, 2, 3, 4, 5, 6] as const;

export const MAX_ANNUAL_PLANS = 2;

export const planDrawerActionLabels = {
  CREATE: 'create',
  EDIT: 'edit',
} as const;

export const planDurationOptions = [
  {
    label: '1 year',
    value: 1,
  },
  {
    label: '2 years',
    value: 2,
  },
  {
    label: '3 years',
    value: 3,
  },
];

export const billingFeeValues = {
  MONTHLY: 5,
  QUARTERLY: 2.5,
} as const;
