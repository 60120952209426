import Decimal from 'decimal.js';
import isNil from 'lodash/isNil';

import type { Currency, Country, ExMoneyValue } from '@/src/api/config/employ/shared.types';
import { friendlyLabel, error } from '@/src/helpers/general';

import type { AdminContract } from '../api/config/employ/contract.types';

const NUMBER_FORMAT_LOCALE = 'en';
const DEFAULT_IMPLIED_CURRENCY_CODE = 'USD';

// List of currencies, whose value of CurrencyDigits mismatches between the minor
// unit fraction described in ISO4217 and the implementation in Chrome, see
// https://issues.chromium.org/issues/41480713
const CURRENCY_DIGITS_MISMATCHING_CURRENCIES: Record<string, number> = {
  AFN: 2,
  ALL: 2,
  IQD: 3,
  IRR: 2,
  KPW: 2,
  LAK: 2,
  LBP: 2,
  MGA: 2,
  MMK: 2,
  MRO: 2,
  RSD: 2,
  SLL: 2,
  SOS: 2,
  SYP: 2,
  YER: 2,
};

export const currencyFormatRegex = new RegExp(
  /^(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$/
);

export const currencyIntegerOnlyFormatRegex = new RegExp(
  /(?=\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?$/
);

export const currencyNegativeFormatRegex = new RegExp(
  /^-(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)$/
);

export const currencyIntegerOnlyNegativeFormatRegex = new RegExp(
  /-(?=\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?$/
);

// Round to 2 decimal places using "round half to even" (bankers' rounding)
// Uses Decimal.js to ensure precise decimal arithmetic and rounding behavior
export function round(value: number): number {
  const decimalValue = new Decimal(value);
  return decimalValue.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber();
}

/**
 * "2311.9" -> "2311.90", "31435.4234" -> "31435.42"
 * Useful for CSV exports for keeping consistency with Excel
 * @param {Number} value - value to be converted
 * @returns Value rounded to 2 digits always
 */
export function roundWithPreserveZeros(value: number) {
  const roundedNumber = round(value);
  return roundedNumber.toLocaleString(NUMBER_FORMAT_LOCALE, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  });
}

export function isValidCost(value: string) {
  return currencyFormatRegex.test(value);
}

export function isValidIntegerOnlyCost(value: string) {
  return currencyIntegerOnlyFormatRegex.test(value);
}

export function isValidNegativeCost(value: string) {
  return currencyNegativeFormatRegex.test(value);
}

export function isValidIntegerOnlyNegativeCost(value: string) {
  return currencyIntegerOnlyNegativeFormatRegex.test(value);
}

/**
 * Because amounts from forms will be as in US format, with commas, we need to convert them
 * to floats first to convert them to cents.
 */
export function convertToValidCost(value: string) {
  return parseFloat(value.replace(/,/g, ''));
}

export function convertToCents(amount?: number | string | string[] | null | boolean) {
  if (
    isNil(amount) ||
    amount === '' ||
    Number.isNaN(amount) ||
    Array.isArray(amount) ||
    typeof amount === 'boolean'
  ) {
    return null;
  }

  let validAmount: number;

  if (typeof amount === 'string') {
    validAmount = convertToValidCost(amount);
  } else {
    validAmount = amount;
  }

  return round(validAmount * 100);
}

/**
 *
 * The majority of the money values we deal with are in cents,
 * this converts it.
 */
export function convertFromCents(amount?: number | string | null) {
  if (isNil(amount) || Number.isNaN(amount)) return null;

  let normalizedValue: number;

  if (typeof amount === 'string') {
    normalizedValue = convertToValidCost(amount || '0');
  } else {
    normalizedValue = amount;
  }

  return round(normalizedValue / 100);
}

function friendlyError(message: string) {
  error(message);
  return friendlyLabel(null);
}

function getCurrencyFractionDigits(currencyCode: string) {
  // Chrome's currency formatting uses a different source of minor unit fraction configuration
  // for some currencies than the one used in ISO4217 standard. It's a long standing issue that
  // has not yet been resolved, so for now we'll maintain the list of currencies where minor
  // unit fraction was not implemented properly. See https://issues.chromium.org/issues/41480713
  if (currencyCode in CURRENCY_DIGITS_MISMATCHING_CURRENCIES) {
    return CURRENCY_DIGITS_MISMATCHING_CURRENCIES[currencyCode];
  }

  // `minimumFractionDigits` and `maximumFractionDigits` are `undefined` in all other
  // cases to use `Intl.NumberFormat`'s native implementation of currency amount rounding
  // that's based on the ISO4217 standard, see https://tc39.es/ecma402/#sec-currencydigits
  return undefined;
}

/**
 * Formats the amount according to the currency.
 * It should not be used to directly render amounts of money, as it will
 * throw an error if the value is still undefined (because it's loading),
 * use the "friendlyMoney" function for that.
 *
 * @param {Float} amount - the value (not in cents)
 * @param {String} currencyCode - currency code (will influence the symbol shown)
 * @param {Object} [options] - optional formatting options.
 * @param {Boolean} [options.roundToMajorCurrencyUnit] - whether to round to the major currency unit (e.g. 1 USD instead of 1.00 USD)
 * @returns The formatted currency amount as a string, or null if the amount is null.
 */
export function formatCurrency(
  amount: number | null | undefined,
  currencyCode: string,
  options?: { roundToMajorCurrencyUnit?: boolean }
): string | null {
  if (amount === undefined) {
    return friendlyError('Missing "value" on the formatCurrency function');
  }
  if (!currencyCode) {
    return friendlyError('Missing "currencyCode" on the formatCurrency function');
  }
  if (amount === null) {
    return null;
  }

  const currencyFractionDigits = getCurrencyFractionDigits(currencyCode);

  return new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: options?.roundToMajorCurrencyUnit ? 0 : currencyFractionDigits,
    minimumFractionDigits: options?.roundToMajorCurrencyUnit ? 0 : currencyFractionDigits,
  }).format(amount);
}

/**
 *
 * The majority of the money values we deal with are in cents,
 * this converts it before calling the function above.
 * It should not be used to directly render amounts of money, as it will
 * throw an error if the value is still undefined (because it's loading),
 * use the "friendlyMoney" function for that.
 *
 * @param {Number} amountInCents - the value
 * @param {Object} currency - currency object which should contain a "code" property
 */
function formatMoney(amountInCents: number | string, currency: Partial<Pick<Currency, 'code'>>) {
  if (amountInCents === undefined) {
    return friendlyError('Undefined "amountInCents" parameter on the formatMoney function.');
  }
  if (!currency || !currency.code) {
    return friendlyError('Missing "currency" object or currency code on the formatMoney function');
  }
  if (amountInCents === null) {
    return null;
  }

  return formatCurrency(convertFromCents(amountInCents), currency?.code);
}

/**
 * Extracts the numeric part of the formatted string, excluding the currency symbol or code,
 * see https://stackoverflow.com/a/68550501
 */
function joinNumberFormatPartsButExcludeCurrency(parts: Intl.NumberFormatPart[]) {
  return parts
    .reduce((acc, part) => {
      if (['currency', 'literal'].includes(part.type)) {
        return acc;
      }
      return `${acc}${part.value}`;
    }, '')
    .trim();
}

/**
 *
 * The majority of the money values we deal with are in cents,
 * this converts it before calling the function above.
 *
 * This function will not care about the currency for cases like CSV export where the currency code is rendered somewhere else already
 *
 * @param {Number | string} amountInCents - the value
 * @param {Object} [options] - Optional parameters for formatting.
 * @param {String} [options.impliedCurrencyCode] - The implied currency code to determine the correct number of fraction digits.
 * @param {Boolean} [options.roundToMajorCurrencyUnit] - whether to round to the major currency unit (e.g. 1 USD instead of 1.00 USD)
 * @returns The formatted amount as a string.
 */
export function formatMoneyWithoutCurrency(
  amountInCents: number | string | null,
  options?: { impliedCurrencyCode?: string; roundToMajorCurrencyUnit?: boolean }
) {
  if (amountInCents === undefined) {
    return friendlyError(
      'Undefined "amountInCents" parameter on the formatMoneyWithoutCurrency function.'
    );
  }

  if (amountInCents === null) {
    return null;
  }

  const amount = convertFromCents(amountInCents);

  if (amount === null) {
    return null;
  }

  const currencyCode = options?.impliedCurrencyCode ?? DEFAULT_IMPLIED_CURRENCY_CODE;
  const currencyFractionDigits = getCurrencyFractionDigits(currencyCode);

  return joinNumberFormatPartsButExcludeCurrency(
    new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: options?.roundToMajorCurrencyUnit ? 0 : currencyFractionDigits,
      minimumFractionDigits: options?.roundToMajorCurrencyUnit ? 0 : currencyFractionDigits,
    }).formatToParts(amount)
  );
}

/**
 * Transform an amount in cents to a monetary value without its currency
 * nor grouping separators (e.g., commas)
 *
 * @param {Number} amountInCents The amount to format
 * @param {Object} [options] - Optional parameters for formatting.
 * @param {String} [options.impliedCurrencyCode] - The implied currency code to determine the correct number of fraction digits.
 * @returns The formatted amount as a string.
 *
 * @example
 * formatMoneyWithoutCurrencyAndGroups(4520) // returns '45.20'
 *
 * @example
 * formatMoneyWithoutCurrencyAndGroups(240000) // returns '2400.00'
 */
export function formatMoneyWithoutCurrencyAndGroups(
  amountInCents: number | null,
  options?: { impliedCurrencyCode?: string }
) {
  const amount = convertFromCents(amountInCents);

  const currencyCode = options?.impliedCurrencyCode ?? DEFAULT_IMPLIED_CURRENCY_CODE;
  const currencyFractionDigits = getCurrencyFractionDigits(currencyCode);

  return joinNumberFormatPartsButExcludeCurrency(
    new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, {
      useGrouping: false,
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: currencyFractionDigits,
      maximumFractionDigits: currencyFractionDigits,
    }).formatToParts(amount === null ? 0 : amount)
  );
}

/**
 *
 * Renders a user-friendly label if the value is null-ish or the formatted value
 *
 * @param {String|Number|null} amountInCents - the value
 * @param {Object|undefined} currency - currency object which should contain a "code" property
 * @param {String} [placeholder] - the value to be returned if amountInCents is null
 */
export function friendlyMoney(
  amountInCents?: string | number | null,
  currency?: Partial<Pick<Currency, 'code'>> | null,
  placeholder?: string
) {
  if (amountInCents == null || Number.isNaN(amountInCents)) {
    return friendlyLabel(null, placeholder);
  }
  if (!currency) {
    return friendlyError('Missing "currency" object on the friendlyMoney function');
  }
  return friendlyLabel(formatMoney(amountInCents, currency), placeholder);
}

/**
 *
 * Renders a user-friendly label if the value is null-ish or the formatted value
 * This function will not render the currency symbol
 *
 * @param {Number|null|undefined} amountInCents - the value
 * @param {Object|String} [options] - optional parameters for formatting.
 * @param {String} [options.impliedCurrencyCode] - the implied currency code to determine the correct number of fraction digits.
 * @param {String} [options.placeholder] - the value to be returned if amountInCents is null
 */
export function friendlyMoneyWithoutCurrency(
  amountInCents?: string | number | null,
  options?: { impliedCurrencyCode?: string; placeholder?: string } | string
) {
  // Support for legacy usage where the second parameter was the optional placeholder.
  const opts = typeof options === 'string' ? { placeholder: options } : options;

  if (amountInCents == null || Number.isNaN(amountInCents)) {
    return friendlyLabel(null, opts?.placeholder);
  }
  return friendlyLabel(
    formatMoneyWithoutCurrency(amountInCents, {
      impliedCurrencyCode: opts?.impliedCurrencyCode,
    }),
    opts?.placeholder
  );
}

/**
 *
 * Renders a user-friendly label if the value is null-ish or the formatted value
 *
 * @param {number | string | null} amount - the value
 * @param {Object|undefined} currency - currency object which should contain a "code" property
 * @param {'left' | 'right'} [position] - where the currency code should be rendered. Left by default. Can also be 'right'.
 */
export function friendlyMoneyWithCurrencyCode(
  amount: number | string | null,
  currency: { code: string | null } | undefined,
  position: 'left' | 'right' = 'left'
) {
  const currencyCode = currency?.code || '';
  const hasAmount = amount != null && !Number.isNaN(amount);
  const showCurrencyCode = hasAmount && currencyCode;

  const maybeLeftAssignCode = showCurrencyCode && position === 'left' ? `${currencyCode} ` : '';
  const maybeRightAssignCode = showCurrencyCode && position === 'right' ? ` ${currencyCode}` : '';

  return `${maybeLeftAssignCode}${friendlyMoneyWithoutCurrency(amount, {
    impliedCurrencyCode: currency?.code ?? DEFAULT_IMPLIED_CURRENCY_CODE,
  })}${maybeRightAssignCode}`;
}

/**
 * Returns the amount in a user-friendly format with the currency symbol. Eg: €1,274.87
 *
 * @param {(number | string | null)} amount
 * @param {(Partial<Currency> | string | null)} [currency]
 * @return {*}
 */
export function friendlyMoneyWithCurrencySymbol(
  amount: number | string | null,
  currency?: Partial<Currency> | string | null
) {
  if (typeof currency === 'string' || isNil(currency)) {
    return friendlyMoneyWithoutCurrency(amount, {
      impliedCurrencyCode: currency ?? DEFAULT_IMPLIED_CURRENCY_CODE,
    });
  }

  return `${currency?.symbol || ''}${friendlyMoneyWithoutCurrency(amount, {
    impliedCurrencyCode: currency?.code,
  })}`;
}

export function friendlyMoneyWithCurrencyCodeAndSymbol(
  amount: number | string | null,
  currency?: Partial<Currency> | string | null
) {
  if (typeof currency === 'string' || isNil(currency)) {
    return friendlyMoneyWithoutCurrency(amount, {
      impliedCurrencyCode: currency ?? DEFAULT_IMPLIED_CURRENCY_CODE,
    });
  }

  return `${currency?.symbol || ''}${friendlyMoneyWithoutCurrency(amount, {
    impliedCurrencyCode: currency?.code,
  })} ${currency?.code || ''}`;
}

type FriendlyMoneyPair = {
  convertedAmount?: number | null;
  convertedCurrency?: Pick<Currency, 'code'> | null;
  sourceAmount?: number | null;
  sourceCurrency?: Pick<Currency, 'code'> | null;
};

/**
 *
 * Renders a user-friendly label if the value is null-ish or
 * the formatted converted and source amounts
 *
 * @param {Object} money - contains all the converted/source info
 */
export function friendlyMoneyPair(money?: FriendlyMoneyPair | number | null): string {
  const nullLabel = `${friendlyLabel(null)} (${friendlyLabel(null)})`;

  if (isNil(money) || typeof money === 'number') {
    return nullLabel;
  }

  if (
    isNil(money.convertedAmount) ||
    isNil(money.sourceAmount) ||
    isNil(money.convertedCurrency) ||
    isNil(money.sourceCurrency)
  ) {
    return nullLabel;
  }

  const convertedMoney = friendlyLabel(formatMoney(money.convertedAmount, money.convertedCurrency));

  const sourceMoney = friendlyLabel(formatMoney(money.sourceAmount, money.sourceCurrency));

  return `${convertedMoney} (${sourceMoney})`;
}

export function floatToPercent(floatValue: number) {
  return `${floatValue * 100}%`;
}

export function getCurrencyFromActiveContract(
  contracts: Pick<AdminContract, 'active' | 'country'>[]
) {
  if (!contracts || contracts.length === 0) return null;

  const activeContract = contracts.find((contract) => contract.active);
  return activeContract?.country?.currency?.code ?? null;
}

export function getCurrencyByCountrySlug(countrySlug: string, countries: Country[]) {
  return countries?.find((country) => country.slug === countrySlug)?.currency;
}

export function getCurrencyByCountryCode(countryCode: string, countries: Country[]) {
  return countries?.find((country) => country.code === countryCode)?.currency;
}

/**
 * Get the currency object of a certain currency code
 */
export function getCurrencyByCode(currencyCode: string, currencies: Currency[]) {
  return currencies?.find((currency) => currency.code === currencyCode);
}

/**
 *
 * Renders the value with the currency and formatted with K or M
 * the formatted converted and source amounts
 * 70000 USD -> $70.00K
 * @param {Number} amount - quantity to format
 * @param {String} currencyCode - The currencyCode to know what currencySymbol we need to render
 * @param {Number} fractionDigits - (optional) how many fractionDigits we want
 */
export function shortMoneyFormatter(
  amount: number,
  currencyCode: string,
  fractionDigits: number = 2
) {
  const formattedValue = friendlyMoney(amount, { code: currencyCode });
  const currencyValue = formattedValue.replace(/[\d,.]+/g, '').trim();

  if (amount < 1000) {
    return `${currencyValue}${Intl.NumberFormat(NUMBER_FORMAT_LOCALE, {
      currency: currencyCode,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(amount)}`;
  }

  const value = Intl.NumberFormat(NUMBER_FORMAT_LOCALE, {
    notation: 'compact',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);

  return `${currencyValue}${value}`;
}

export function isExMoneyValue(value?: ExMoneyValue | unknown): value is ExMoneyValue {
  if (value) {
    return typeof value === 'object' && 'currency' in value && 'amount' in value;
  }
  return false;
}

/**
 * Used to format an amount to a plain string
 * @param amount string | number | ExMoneyValue
 * @param fallbackCurrency | Currency
 * @returns string
 */
export function formatAmountWithCurrency(
  amount: string | number | ExMoneyValue,
  fallbackCurrency: Currency
) {
  return isExMoneyValue(amount)
    ? friendlyMoney(amount.amount, { code: amount.currency })
    : friendlyMoney(amount, fallbackCurrency);
}

/**
 * Used to format an amount to MoneyCell params
 * @param amount string | number | ExMoneyValue
 * @param fallbackCurrency | Currency
 * @returns string
 */
export function formatAmountToMoneyCellParams(
  amount: string | number | ExMoneyValue,
  fallbackCurrency: Currency
) {
  return isExMoneyValue(amount)
    ? { amount: amount.amount, currency: { code: amount.currency } }
    : { amount, currency: fallbackCurrency };
}

/**
 * Used to format amount without currency when exporting invoice breakdown table to CSV.
 * @param amount string | number | ExMoneyValue
 * @param fallbackCurrency | Currency
 * @returns string
 */
export function formatAmountWithoutCurrency(
  amount: string | number | ExMoneyValue,
  fallbackCurrency: Currency
) {
  return isExMoneyValue(amount)
    ? friendlyMoneyWithoutCurrency(amount.amount, {
        impliedCurrencyCode: amount.currency,
        placeholder: '',
      })
    : friendlyMoneyWithoutCurrency(amount, {
        impliedCurrencyCode: fallbackCurrency.code,
        placeholder: '',
      });
}
