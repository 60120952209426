import { IconV2OutlineGraph } from '@remote-com/norma/icons/IconV2OutlineGraph';

import { PageHeaderQuickLinks } from '@/src/components/Ui/Layout/PageHeader/PageHeaderQuickLinks';
import { useUserCan } from '@/src/components/UserCan';
import { REPORTS_DETAILS_ROUTE } from '@/src/constants/routes';
import { REPORT_LIST } from '@/src/domains/reports/constants';
import type { ReportCategory } from '@/src/domains/reports/types';

type PageHeaderReportsProps = {
  reportsCategory?: ReportCategory;
};

export const PageHeaderReports = ({ reportsCategory }: PageHeaderReportsProps) => {
  const userCan = useUserCan();

  const data = reportsCategory
    ? REPORT_LIST.find((category) => category.category === reportsCategory)
    : null;

  if (!data || !userCan?.userCan('read', data.permission)) {
    return null;
  }

  const reportsItems = data.reportTemplates.map((report) => ({
    label: report.name,
    href: {
      pathname: REPORTS_DETAILS_ROUTE,
      query: {
        slug: report.slug,
      },
    },
  }));

  return (
    <PageHeaderQuickLinks
      data-appcues-page-header-reports
      icon={IconV2OutlineGraph}
      items={reportsItems}
      label="Reports"
    />
  );
};
