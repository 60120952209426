import { useGet } from '@remote-com/data-layer';
import { createContext, useContext, useCallback, useMemo } from 'react';
import type { UseQueryResult } from 'react-query';

import type { ProductEmploymentType } from '@/src/api/config/api.types';
import type {
  ProductUsage,
  ProductUsageAndAccess,
} from '@/src/api/config/employ/productUsageAndAccess.types';
import { useUserContext } from '@/src/components/UserProvider';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import {
  getProductAndFeatureAccessState,
  findProductUsageByEmploymentType,
} from '@/src/domains/productUsageAndAccess/helpers';
import type {
  FeatureAccess,
  ProductAccessState,
  FeatureAccessName,
} from '@/src/domains/productUsageAndAccess/types';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { isEmployer, userCan } from '@/src/domains/registration/auth/helpers';

/**
 * Context for accessing product usage and access states.
 */
type ProductUsageAndAccessContextType = {
  /**
   * Query result containing product usage response from `/api/v1/product-features`
   */
  query: UseQueryResult<ProductUsageAndAccess, unknown>;

  /**
   * Function to get a product and its related access state
   */

  getProductAndAccessState: ({
    productEmploymentType,
    featureName,
  }: {
    productEmploymentType: ProductEmploymentType;
    featureName?: FeatureAccessName;
  }) => {
    productAccessState: ProductAccessState | undefined;
    product: ProductUsage | undefined;
  };

  /**
   * Function to get information about a feature access and its usage
   */
  getFeatureUsage: (featureName: FeatureAccessName) => FeatureAccess | undefined;

  /**
   * Indicates whether the current user has permission to create pricing plans
   */
  canUserCreatePricingPlan: boolean;
};

const ProductUsageAndAccessContext = createContext<ProductUsageAndAccessContextType | undefined>(
  undefined
);

export const ProductUsageAndAccessContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isRecruitPackageEnabled = useIsFeatureFlagEnabled('recruit_package');

  const { user } = useUserContext();

  const query = useGet('/api/v1/product-features', {
    options: {
      select: (response) => response?.data,
      // Temporarily restricting to employers only, as employee use-cases don't exist yet.
      enabled: isRecruitPackageEnabled && isEmployer(user),
    },
  });

  // Using direct permission check instead of useUserCan hook to avoid test mocking issues
  // The userCan hook is often mocked in tests which leads to failures in tests
  const canUserCreatePricingPlan = userCan('create', Resources.pricing_plans, user ?? {});

  const getProductAndAccessState = useCallback(
    ({
      productEmploymentType,
      featureName,
    }: {
      productEmploymentType: ProductEmploymentType;
      featureName?: FeatureAccessName;
    }) => {
      if (query.isLoading || query.error) {
        return { productAccessState: undefined, product: undefined };
      }

      return {
        productAccessState: getProductAndFeatureAccessState({
          productUsageAndAccess: query.data,
          productEmploymentType,
          featureName,
        }),
        product: findProductUsageByEmploymentType(
          productEmploymentType,
          query.data?.products || []
        ),
      };
    },
    [query.data, query.isLoading, query.error]
  );

  const getFeatureUsage = useCallback(
    (featureName: FeatureAccessName) => {
      if (query.isLoading || query.error) {
        return undefined;
      }

      return featureName ? query.data?.access?.[featureName] : undefined;
    },
    [query.data, query.isLoading, query.error]
  );

  const contextValue = useMemo(
    () => ({
      query,
      getProductAndAccessState,
      canUserCreatePricingPlan,
      getFeatureUsage,
    }),
    [query, getProductAndAccessState, getFeatureUsage, canUserCreatePricingPlan]
  );

  return (
    <ProductUsageAndAccessContext.Provider value={contextValue}>
      {children}
    </ProductUsageAndAccessContext.Provider>
  );
};

export const useProductUsageAndAccessContext = () => {
  const context = useContext(ProductUsageAndAccessContext);

  if (context === undefined) {
    throw new Error(
      'useProductUsageAndAccessContext must be used within ProductUsageAndAccessContextProvider'
    );
  }

  return context;
};
