import { useGet } from '@remote-com/data-layer';
import { useMemo } from 'react';

import { useUserContext } from '@/src/components/UserProvider';
import { useUser } from '@/src/components/UserProvider/context';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { userCan } from '@/src/domains/registration/auth/helpers';

export const useIsZuoraMigratedCompany = (companySlug: string = '') => {
  const { user } = useUserContext();

  const { userIsAdmin } = useUser();

  const isValidSlug = !!companySlug;

  const customerCanCheckIsMigratedToZuora =
    !userIsAdmin &&
    isValidSlug &&
    (userCan('read', Resources.employer.billing, user) ||
      userCan('read', Resources.employer.company, user));

  const companyIsMigratedToZuoraCustomer = useGet(
    '/api/v1/billing-platform/employer/companies/[companySlug]',
    {
      params: { pathParams: { companySlug } },
      options: {
        select: ({ data }) => data,
        enabled: customerCanCheckIsMigratedToZuora,
      },
    }
  );

  const adminCanCheckCustomerIsMigratedToZuora =
    userIsAdmin && isValidSlug && userCan('read', Resources.billing, user);

  const companyIsMigratedToZuoraAdmin = useGet(
    '/api/v1/billing-platform/rivendell/companies/[companySlug]',
    {
      params: { pathParams: { companySlug } },
      options: {
        select: ({ data }) => data,
        enabled: adminCanCheckCustomerIsMigratedToZuora,
      },
    }
  );

  // Handle the case when the feature is not enabled
  const defaultState = useMemo(
    () => ({
      data: { isZuoraMigrated: false, isPriceMigrated: false }, // Default to false until the feature flag is enabled
      isLoading: false,
      isError: false,
      isIdle: true, // To avoid unnecessary call to employer dashboard API
    }),
    []
  );

  if (adminCanCheckCustomerIsMigratedToZuora) {
    return companyIsMigratedToZuoraAdmin;
  }

  if (customerCanCheckIsMigratedToZuora) {
    return companyIsMigratedToZuoraCustomer;
  }

  return defaultState;
};
