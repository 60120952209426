import { IconV2DuotoneCreditCard } from '@remote-com/norma/icons/IconV2DuotoneCreditCard';

import type { ProductEmploymentType } from '@/src/api/config/api.types';
import { useCreatePricingPlanQuote } from '@/src/domains/pricingPlans/confirmPricingPlan/queries';
import { friendlyKeyToLabel } from '@/src/helpers/general';

export const FailedSubscriptionPaymentTodoBody = ({
  data,
}: {
  data: {
    details: { productPriceSlug: string; subscriptionType: ProductEmploymentType };
  };
}) => {
  const { productPriceSlug, subscriptionType } = data.details;

  const createProductQuoteMutation = useCreatePricingPlanQuote();

  return {
    ...data,
    Icon: IconV2DuotoneCreditCard,
    category: 'finance',
    title: `Retry payment for ${friendlyKeyToLabel(subscriptionType)} subscription renewal`,
    description: `Your payment for your ${friendlyKeyToLabel(
      subscriptionType
    )} subscription renewal failed.
        Please retry the payment with another card.`,
    onClick: () =>
      createProductQuoteMutation.mutate({
        productPriceSlug,
        productEmploymentType: subscriptionType,
      }),
  };
};
