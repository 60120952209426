import {
  AccessTokenStorage,
  createInterceptorManager,
  AccessTokenProvider as AuthAccessTokenProvider,
} from '@remote-com/auth';
import type { ReactNode } from 'react';

import type { SessionJwtApi } from '@/src/api/config/employ/session.types';
import { useUserContext } from '@/src/components/UserProvider';
import ApiClient from '@/src/services/ApiClient';
import { makePost } from '@/src/services/ApiClient/functions/makeRequest';

import { isAdmin } from './helpers';

const axiosInterceptorManager = createInterceptorManager(ApiClient);

axiosInterceptorManager.installOnAuthRequestInterceptor();

export type Scope = 'talent' | 'talent_admin';
export const SCOPE = {
  TALENT: 'talent',
  TALENT_ADMIN: 'talent_admin',
} as const;

function makeFetcher(scope: Scope) {
  return () => makePost('/api/v1/session/jwt', { bodyParams: { scope } }).then(({ data }) => data);
}

const fetcher: Record<Scope, () => Promise<SessionJwtApi.SessionJwtResponse['data']>> = {
  talent: makeFetcher('talent'),
  talent_admin: makeFetcher('talent_admin'),
};

type AccessTokenProviderProps = { children: ReactNode };

/**
 * Component to interact with access token for Remote Talent.
 */
export function AccessTokenProvider({ children }: AccessTokenProviderProps) {
  const { user } = useUserContext();

  const scope = isAdmin(user) ? SCOPE.TALENT_ADMIN : SCOPE.TALENT;
  // We have to make sure the fetcher is set on the first render so that child
  // components can start using it in their fetch calls. This prevents us from
  // setting it in an effect.
  // Since the fetcher is not connected to any state and will not cause any new
  // render we can set it on every render without affecting the performance or
  // causing an infinite loop.
  if (user) {
    AccessTokenStorage.setFetcher(fetcher[scope]);
  }
  return <AuthAccessTokenProvider>{children}</AuthAccessTokenProvider>;
}
