import { accountsSwitchingEvents, trackEvent } from '@remote-com/analytics';
import { captureException } from '@remote-com/analytics/src/rudderstack/captureException';
import { usePost } from '@remote-com/data-layer';
import { toast } from '@remote-com/norma';
import type { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import type { KeyboardEvent } from 'react';
import { useCallback, useRef } from 'react';

import type { UserLinkedAccount } from '@/src/api/config/api.types';
import { Collapsible } from '@/src/components/Collapsible';
import { useUserContext } from '@/src/components/UserProvider';
import { useSwitchProfile } from '@/src/domains/account/hooks/useSwitchProfile';
import { useUserProfiles } from '@/src/domains/account/hooks/useUserProfiles';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { useProfilesMenuTooltip } from '@/src/domains/navigation/profiles-menu/hooks/useProfilesMenuTooltip';
import { PaginatedProfilesMenu } from '@/src/domains/navigation/profiles-menu/PaginatedProfilesMenu';
import type { SSO_ERROR_KEYS } from '@/src/domains/registration/auth/constants';
import {
  LINKED_ACCOUNT_STATUS,
  SSO_ERROR_MESSAGES,
} from '@/src/domains/registration/auth/constants';
import { ProfileAddModal } from '@/src/domains/registration/auth/ProfileAddModal';
import { getUserTimezone } from '@/src/helpers/date';
import { useModalContext } from '@/src/hooks/useModalContext';

import { useUserLinkedAccounts } from '../../account/hooks/useUserLinkedAccounts';

import { ProfilesMenuCollapsibleItem } from './ProfilesMenuCollapsibleItem';
import { ProfilesMenuCollapsibleTrigger } from './ProfilesMenuCollapsibleTrigger';

type ProfilesMenuProps = {
  open: boolean;
  isAccountsMenu?: boolean;
  renderSeparator?: boolean;
  onOpenChange: (open: boolean) => void;
  setSwitchingProfileVisible(val: boolean): void;
};

type SSOErrors = AxiosError<{
  message: string;
  code: string | (typeof SSO_ERROR_KEYS)[keyof typeof SSO_ERROR_KEYS];
}>;

export const ProfilesMenu = ({
  open,
  onOpenChange,
  renderSeparator,
  isAccountsMenu = false,
  setSwitchingProfileVisible,
}: ProfilesMenuProps) => {
  const {
    selectedProfile,
    profiles: { list: profilesList, totalPages },
  } = useUserProfiles();

  const { showModal, hideModal } = useModalContext();
  const router = useRouter();
  const { user } = useUserContext();
  const { shouldShowTooltip, closeTooltip } = useProfilesMenuTooltip();
  const ssoCallbackTigerEnabled = useIsFeatureFlagEnabled('sso_callback_tiger');

  const { linkedAccountsWithoutCurrent, selectedAccount } = useUserLinkedAccounts();

  const { mutate: switchAccountMutation } = usePost('/api/v1/session/accounts', {
    onSuccess: () => {
      router.reload();
    },
    onError: (err) => {
      toast.error(
        'Something went wrong when switching to linked account. Please try again or contact support for help.'
      );
      captureException(err);
    },
  });

  const { mutate: signInSSOMutation } = usePost(
    ssoCallbackTigerEnabled ? '/api/v1/sso/alt/redirect' : '/api/v1/sso/redirect',
    {
      onSuccess: ({ data }) => {
        // redirect to the URL that has been returned to complete SSO auth flow
        if (data?.url) {
          router.push(data.url);
        }
      },
      onError: (error) => {
        const errObject = error as SSOErrors;
        const errorMessageData = errObject?.response?.data;
        const errorMessageCode = errObject?.response?.data?.code || '';

        // (iam-team) - TODO: We need uniform error handling across SSO endpoints where we rely on "code" only, this is supporting current state of affairs where we sometimes have message for some errors and no code.
        if (errorMessageData?.message) {
          toast.error(errorMessageData?.message);
          return;
        }

        const ssoErrorMessage =
          SSO_ERROR_MESSAGES[errorMessageCode] || SSO_ERROR_MESSAGES.GENERIC_SSO_ERROR;

        toast.error(ssoErrorMessage);
        captureException(error);
      },
    }
  );

  const searchInputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      onOpenChange(isOpen);
      if (!isOpen) {
        // We need to set a timeout because we're running into issues with our triggerRef not being updated.
        setTimeout(() => {
          // Focus the profile menu trigger after closing the menu.
          triggerRef.current?.focus();
        }, 100);
      }

      // Set the local storage key on open to prevent the tooltip from showing again
      if (isOpen && shouldShowTooltip) {
        closeTooltip();
      }

      if (isOpen) {
        trackEvent(
          isAccountsMenu
            ? accountsSwitchingEvents.ACCOUNT_SWITCHER_EXPANDED_CLICKED
            : accountsSwitchingEvents.PROFILE_SWITCHER_EXPANDED_CLICKED
        );
      }
    },
    [onOpenChange, shouldShowTooltip, closeTooltip, isAccountsMenu]
  );

  const { handleSwitchProfile } = useSwitchProfile({
    onMutate: () => {
      handleOpenChange(false);
    },
  });

  const handleProfileItemSelect = (profileIndex: number) => {
    if (profileIndex === selectedProfile?.profileIndex) {
      handleOpenChange(false);
      return;
    }

    handleSwitchProfile(profileIndex);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape' && open) {
      // Prevents the parent dropdown menu from closing when the user is closing this menu
      event.preventDefault();
      handleOpenChange(false);
    }

    if (event.key === 'ArrowDown' && totalPages > 1 && open) {
      // Focus the search input when the user presses the down arrow key on the trigger with paginated profiles menu.
      searchInputRef.current?.focus();
    }
  };

  async function handleSwitchAccount(account: UserLinkedAccount) {
    // ignore if user is clicking on currently signed in account
    if (account.email === selectedAccount()?.email) {
      handleOpenChange(false);
      return;
    }

    trackEvent(accountsSwitchingEvents.ACCOUNT_SWITCHER_ACCOUNT_CLICKED);

    // if we have SSO then we go through straight to idp redirect without an extra step of opening modal
    if (
      account.authenticationMethod === 'sso' &&
      account.status === LINKED_ACCOUNT_STATUS.SIGNED_OUT
    ) {
      const timezone = getUserTimezone();
      signInSSOMutation({
        bodyParams: {
          email: account.email,
          timezone,
          linking_accounts: true,
        },
      });
      return;
    }

    // check if accounts is signed_out and if so, prompt user to authenticate, upon success this will authenticate and re-link account and refresh the page
    if (account.status === LINKED_ACCOUNT_STATUS.SIGNED_OUT) {
      setSwitchingProfileVisible(true);
      showModal({
        component: ProfileAddModal,
        modalProps: {
          visible: true,
          onSuccessAuth: () => {
            router.reload();
          },
          onClose: () => {
            setSwitchingProfileVisible(false);
            hideModal();
          },
          email: account.email,
          authenticationMethod: account.authenticationMethod,
          isAccountSwitching: true,
        },
      });
      return;
    }

    // account is signed in, proceed with account switching
    switchAccountMutation({
      bodyParams: {
        account_slug: account.slug,
      },
    });
  }

  if (!isAccountsMenu && !selectedProfile) return null;

  return (
    <Collapsible
      renderSeparator={renderSeparator}
      trigger={
        <ProfilesMenuCollapsibleTrigger
          open={open}
          profile={selectedProfile}
          account={selectedAccount()}
          ref={triggerRef}
          isStandaloneButton={totalPages > 1 && open}
          isAccountsMenu={isAccountsMenu}
        />
      }
      open={open}
      onOpenChange={handleOpenChange}
      onKeyDown={handleKeyDown}
    >
      {isAccountsMenu && (
        <>
          {linkedAccountsWithoutCurrent?.map((account) => (
            <ProfilesMenuCollapsibleItem
              isAccountsMenu
              key={account.slug}
              onSelect={() => handleSwitchAccount(account)}
              account={account}
              checked={account.email === user?.email}
            />
          ))}
        </>
      )}
      {totalPages > 1 && !isAccountsMenu && (
        <PaginatedProfilesMenu
          onSelect={handleProfileItemSelect}
          triggerRef={triggerRef}
          searchInputRef={searchInputRef}
        />
      )}
      {totalPages <= 1 && !isAccountsMenu && (
        <>
          {profilesList.map((profile) => (
            <ProfilesMenuCollapsibleItem
              isAccountsMenu={false}
              key={profile.userSlug}
              onSelect={() => handleProfileItemSelect(profile.profileIndex)}
              profile={profile}
              checked={profile.userSlug === selectedProfile?.userSlug}
            />
          ))}
        </>
      )}
    </Collapsible>
  );
};
