import { useGet } from '@remote-com/data-layer';

import { useUserProfiles } from '@/src/domains/account/hooks/useUserProfiles';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';

export const useUserLinkedAccounts = () => {
  const oneAccountMultiAccountEnabled = useIsFeatureFlagEnabled(
    'one_account_multi_account_linking'
  );

  const { selectedProfile } = useUserProfiles();
  const { data: linkedAccountsData, isLoading: isLoadingLinkedAccountsData } = useGet(
    '/api/v1/session/accounts',
    {
      options: {
        select: (res) => {
          let accounts = res?.data;
          // we want to always have current account as first in the list so we first find it in our list of added accounts comparing to current user and filter out and re-add to the beginning of the list
          if (selectedProfile && accounts) {
            const currentlySignedInAccount = accounts.find(
              (account) => account.slug === selectedProfile.accountSlug
            );
            if (currentlySignedInAccount) {
              accounts = accounts.filter((account) => account.slug !== selectedProfile.accountSlug);
              accounts.unshift(currentlySignedInAccount);
            }
          }
          return accounts;
        },
        enabled: oneAccountMultiAccountEnabled,
      },
    }
  );

  function checkIfAccountIsLinked(email: string) {
    return linkedAccountsData?.find((account) => account.email === email);
  }

  function getSelectedAccount() {
    return (
      linkedAccountsData &&
      linkedAccountsData?.find((account) => account.slug === selectedProfile?.accountSlug)
    );
  }

  return {
    linkedAccountsData,
    linkedAccountsWithoutCurrent:
      linkedAccountsData &&
      linkedAccountsData?.filter((account) => account.slug !== selectedProfile?.accountSlug),
    accountIsLinked: checkIfAccountIsLinked,
    selectedAccount: getSelectedAccount,
    hasMultipleLinkedAccounts: linkedAccountsData && linkedAccountsData?.length > 1,
    hasReachedMaxLinkedAccounts: linkedAccountsData && linkedAccountsData?.length >= 10,
    isLoading: isLoadingLinkedAccountsData,
  } as const;
};
