import { CHECKBOX_WIDTH } from '@/src/components/Table/helpers/constants';

import { CheckboxCell, ToggleAllCheckbox } from '../Cells';

export const CHECKBOX_COLUMN_ID = 'selection';

export function injectRowSelectionCheckbox(columns, visible) {
  const sticky =
    columns[0]?.sticky === 'left'
      ? {
          sticky: 'left',
        }
      : {};
  return [
    visible && {
      ...sticky,
      id: CHECKBOX_COLUMN_ID,
      Header: (props) => {
        const {
          disabledToggleAllCheckboxTooltip,
          disableToggleAllCheckbox,
          onAllRowsSelectableChange,
        } = props;

        const isDataEmpty = !props.data?.length;

        return (
          <ToggleAllCheckbox
            {...props}
            disabled={disableToggleAllCheckbox || isDataEmpty}
            disabledCheckboxTooltip={disabledToggleAllCheckboxTooltip}
            onAllRowsSelectableChange={onAllRowsSelectableChange}
          />
        );
      },
      disableSortBy: true,
      Cell: (props) => {
        const { disabledCheckboxTooltip, onRowSelectableChange, row } = props;

        const isCheckboxDisabled = props.isCheckboxDisabled ?? (() => false);

        // tooltip can be a static string or a function that returns a string at runtime
        const checkboxDisabledTooltip =
          typeof disabledCheckboxTooltip === 'function'
            ? disabledCheckboxTooltip
            : () => disabledCheckboxTooltip;

        const tooltip = checkboxDisabledTooltip(row);

        const disabled = isCheckboxDisabled(row);
        return (
          <CheckboxCell
            {...props}
            disabled={disabled}
            disabledCheckboxTooltip={tooltip}
            onRowSelectableChange={onRowSelectableChange}
          />
        );
      },
      style: {
        width: `${CHECKBOX_WIDTH}px`,
      },
    },
    ...columns,
  ].filter((column) => Boolean(column));
}
