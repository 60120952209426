// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneNotificationUnread = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path opacity={0.32} d="M9.25 2.25c-2.8 0-4.2 0-5.27.54a5 5 0 0 0-2.19 2.19c-.54 1.07-.54 2.47-.54 5.27v4.5c0 2.8 0 4.2.54 5.27a5 5 0 0 0 2.19 2.18c1.07.55 2.47.55 5.27.55h4.5c2.8 0 4.2 0 5.27-.55a5 5 0 0 0 2.18-2.18c.55-1.07.55-2.47.55-5.27v-2.21c0-.27 0-.4-.03-.51a1 1 0 0 0-.75-.75c-.1-.03-.24-.03-.5-.03-1.6 0-2.4 0-3.06-.15a6 6 0 0 1-4.51-4.51c-.15-.66-.15-1.46-.15-3.05 0-.27 0-.4-.03-.51a1 1 0 0 0-.75-.75c-.1-.03-.24-.03-.5-.03H9.24Z" /><path fillRule="evenodd" clipRule="evenodd" d="M5.25 11c0-.41.34-.75.75-.75h6a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Zm0 4c0-.41.34-.75.75-.75h3a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75Z" /><path d="M19 1.25a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneNotificationUnread);
export { ForwardRef as IconV2DuotoneNotificationUnread };