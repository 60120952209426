import { BoxedIcon, Pill, Stack, Text, Box, Skeleton } from '@remote-com/norma';
import type { BoxedIconTone } from '@remote-com/norma';
import { IconArrowRight } from '@remote-com/norma/icons/IconArrowRight';
import type { ElementType } from 'react';
import styled from 'styled-components';

import { StandaloneLink } from '@/src/components/Button';

type UsageCardProps = {
  icon?: { Icon: ElementType; tone: BoxedIconTone };
  title: string | JSX.Element;
  description: string | JSX.Element;
  cta?: {
    href: string;
    label: string;
  };
  badge?: string;
};

const Card = styled(Stack).attrs({
  bg: 'blank',
  p: 5,
  borderRadius: 12,
  direction: 'row',
  gap: 5,
  alignItems: 'center',
})`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    flex-direction: column;
  }
`;

export function SkeletonUsageCard() {
  return (
    <Card>
      <Skeleton variant="rect" width={40} height={40} />
      <Stack>
        <Skeleton variant="text" width={250} />
        <Skeleton variant="text" width={450} />
      </Stack>
      <Box ml="auto">
        <Skeleton variant="text" width={140} />
      </Box>
    </Card>
  );
}

export function UsageCard({ icon, title, description, cta, badge }: UsageCardProps) {
  return (
    <Card>
      {icon && <BoxedIcon Icon={icon.Icon} tone={icon.tone} />}
      <Stack>
        <Box display="flex" gap={2}>
          <Text variant="smMedium" color="grey.900" aria-live="polite">
            {title}
          </Text>
          <Pill tone="neutralLight">{badge}</Pill>
        </Box>
        <Text variant="xs" color="grey.600" aria-live="polite">
          {description}
        </Text>
      </Stack>
      <Box ml="auto">
        {cta && (
          <StandaloneLink IconAfter={IconArrowRight} href={cta?.href}>
            {cta?.label}
          </StandaloneLink>
        )}
      </Box>
    </Card>
  );
}
