import getConfig from 'next/config';
import Head from 'next/head';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { create } from 'zustand';

import { isFlagActive } from '@/src/components/FeatureFlag';

import {
  ALLOWED_MARKETING_ORIGINS,
  generateCSPHeader,
  generateNonce,
  shouldShowMarketingCSP,
} from './helpers';

const {
  publicRuntimeConfig: { EXTRA_ALLOWED_MARKETING_ORIGINS },
} = getConfig();

export const useContentSecurityPolicyStore = create<{
  nonce: string | null;
  cspHeader: string | null;
  setCSP: ({ cspHeader, nonce }: { cspHeader: string; nonce: string }) => void;
}>((set) => ({
  nonce: null,
  cspHeader: null,
  setCSP: ({ nonce, cspHeader }) => set(() => ({ nonce, cspHeader })),
}));

/**
 * This component is responsible for setting Content-Security-Policy meta tag
 * and defer loading of the app until the CSP is set.
 */
export function ContentSecurityPolicy({ children }: { children: ReactNode }) {
  const { cspHeader, setCSP } = useContentSecurityPolicyStore((state) => state);
  const isCSPEnabled = isFlagActive('CSP_ENABLED');

  useEffect(() => {
    if (isCSPEnabled) {
      const generatedNonce = generateNonce();
      // Content-Security-Policy
      const cspHeaderContent = shouldShowMarketingCSP(window.location.pathname)
        ? generateCSPHeader(generatedNonce, {
            scriptSrc: ALLOWED_MARKETING_ORIGINS.concat(
              EXTRA_ALLOWED_MARKETING_ORIGINS.split(' ').filter(Boolean)
            ),
          })
        : generateCSPHeader(generatedNonce);

      // Set nonce to scripts and styles dynamically created with Webpack, see https://webpack.js.org/guides/csp/
      // eslint-disable-next-line no-underscore-dangle
      window.__webpack_nonce__ = generatedNonce;
      setCSP({ nonce: generatedNonce, cspHeader: cspHeaderContent });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isCSPEnabled) {
    return <>{children}</>;
  }

  return cspHeader ? (
    <>
      <Head>
        <meta httpEquiv="Content-Security-Policy" content={cspHeader} />
      </Head>
      {children}
    </>
  ) : null;
}
