// @ts-nocheck
// DO NOT EDIT! Generated by compress-v2.mjs
import type { SVGProps } from "react";
import { Ref, forwardRef } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const IconV2DuotoneStars = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps, ref: Ref<SVGSVGElement>) => <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" width={24} ref={ref} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path d="M9.5 6.75a.75.75 0 0 0-1.5 0C8 9.65 5.65 12 2.75 12a.75.75 0 0 0 0 1.5c2.9 0 5.25 2.35 5.25 5.25a.75.75 0 0 0 1.5 0c0-2.9 2.35-5.25 5.25-5.25a.75.75 0 0 0 0-1.5A5.25 5.25 0 0 1 9.5 6.75Z" /><path d="M18.78 17.28a.28.28 0 0 0-.56 0c0 1.07-.87 1.94-1.94 1.94a.28.28 0 0 0 0 .56c1.07 0 1.94.87 1.94 1.94a.28.28 0 0 0 .56 0c0-1.07.87-1.94 1.94-1.94a.28.28 0 0 0 0-.56 1.94 1.94 0 0 1-1.94-1.94Z" /><path opacity={0.32} fillRule="evenodd" clipRule="evenodd" d="M18.5 3c.21 0 .39.17.39.39 0 1.5 1.22 2.72 2.72 2.72a.39.39 0 1 1 0 .78A2.72 2.72 0 0 0 18.9 9.6a.39.39 0 1 1-.78 0c0-1.5-1.22-2.72-2.72-2.72a.39.39 0 1 1 0-.78c1.5 0 2.72-1.22 2.72-2.72 0-.22.18-.39.39-.39Z" /></svg>;
const ForwardRef = forwardRef(IconV2DuotoneStars);
export { ForwardRef as IconV2DuotoneStars };