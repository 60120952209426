import { Box, toast } from '@remote-com/norma';
import { IconSearch } from '@remote-com/norma/icons/IconSearch';
import { useLayoutEffect, useState } from 'react';

import type { API } from '@/src/api/config/api.types';
import ProgressBar from '@/src/components/ProgressBar';
import { usePostFiltersFromAiService } from '@/src/components/Table/Components/Toolbar/SmartSearch/hooks';
import type { ColumnType } from '@/src/components/Table/types.ts';
import { useSavedSearchValue } from '@/src/hooks/useSavedSearchValue';

import { getApplicableFilters, mergeFilters } from './helpers';
import { Body, Icon, Input, ModalStyled, Wrapper } from './SmartSearch.styled';

type Filters = API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters'];

type SmartSearchInputProps = {
  onInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

function SmartSearchInput({ onInput, onKeyDown }: SmartSearchInputProps) {
  return (
    <Wrapper>
      <Icon as={IconSearch} />
      <Input
        type="search"
        placeholder="What are you looking for?"
        autoFocus
        onInput={onInput}
        onKeyDown={onKeyDown}
      />
    </Wrapper>
  );
}

type SmartSearchProps = {
  onClose: () => void;
  apiPath: string;
  setAllFilters?: (filters: Filters) => void;
  transformFilters: (filters: Filters) => Filters;
  columns: Array<ColumnType>;
  setGlobalFilter?: (filter: string) => void;
  currentFilters: Filters;
};

export default function SmartSearch({
  onClose,
  apiPath,
  setAllFilters,
  transformFilters,
  columns,
  setGlobalFilter,
  currentFilters,
}: SmartSearchProps) {
  const { setSearchTerm } = useSavedSearchValue();
  const [userQuery, setUserQuery] = useState('');
  const { isLoading, isError, mutateAsync } = usePostFiltersFromAiService();
  const columnNames = columns
    .map((c) => c.id)
    .filter((id) => id !== 'actions')
    .filter(Boolean) as string[];

  useLayoutEffect(() => {
    if (isError) {
      toast.error('An error ocurred, some results might be missing.');
    }
  }, [isError]);

  const handleSort = (
    sort: NonNullable<API.RemoteAi.GenerateSmartSearchFilter.Response['data']['sort']>
  ) => {
    const column = columns.find((c) => c.id === sort.sortBy);

    if (!sort.sortBy || !column?.toggleSortBy) {
      // If no valid sort or column found, maintain current sort state
      return;
    }

    // Clear any existing sorts before applying new one
    columns.forEach((c) => {
      if (c.id !== sort.sortBy && c.clearSortBy) {
        c.clearSortBy();
      }
    });

    column.toggleSortBy(sort.order === 'desc');
  };

  const handleFilterResults = (applicableFilters: Filters, totalFilters: number) => {
    if (applicableFilters.length === 0) {
      toast.error("There's been an issue applying desired view");
      onClose();
      return;
    }

    if (applicableFilters.length === totalFilters) {
      toast.success('Filters applied to the current view');
    } else {
      toast.success(
        'Some filters might not be applicable to the current view. Please apply them manually.'
      );
    }
    onClose();
  };

  const handlePressEnter = async () => {
    const response = await mutateAsync({
      bodyParams: {
        userQuery,
        endpoint: apiPath,
        columnNames,
      },
    });

    const responseFilters = response.data.filters;
    const responseSort = response.data.sort;
    const responseGlobalFilter = response.data.globalFilter;

    const applicableFilters = getApplicableFilters(responseFilters, columns);
    const combinedFilters = mergeFilters(currentFilters, applicableFilters);

    setAllFilters?.(transformFilters(combinedFilters));

    if (responseGlobalFilter) {
      setGlobalFilter?.(responseGlobalFilter);
      setSearchTerm(responseGlobalFilter);
    }

    if (responseSort) {
      handleSort(responseSort);
    }

    handleFilterResults(applicableFilters, responseFilters.length);
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      onClose();
    }
    if (e.key === 'Enter') {
      handlePressEnter();
    }
  };

  return (
    <ModalStyled visible onCancel={onClose} showCloseIcon={false} showFooter={false}>
      <SmartSearchInput
        onInput={(e) => {
          setUserQuery(e.target.value);
        }}
        onKeyDown={handleKeyDown}
      />
      <Body>{isLoading ? <ProgressBar height="4px" /> : <Box height="4px" />}</Body>
    </ModalStyled>
  );
}
