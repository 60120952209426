import { Box } from '@remote-com/norma';
import { IconCommentDots } from '@remote-com/norma/icons/IconCommentDots';
import { IconV2DuotoneActivityBox } from '@remote-com/norma/icons/IconV2DuotoneActivityBox';
import { IconV2DuotoneAlarmClockCheck } from '@remote-com/norma/icons/IconV2DuotoneAlarmClockCheck';
import { IconV2DuotoneAlertCircle } from '@remote-com/norma/icons/IconV2DuotoneAlertCircle';
import { IconV2DuotoneBank } from '@remote-com/norma/icons/IconV2DuotoneBank';
import { IconV2DuotoneBilling } from '@remote-com/norma/icons/IconV2DuotoneBilling';
import { IconV2DuotoneBookOpen } from '@remote-com/norma/icons/IconV2DuotoneBookOpen';
import { IconV2DuotoneCalendarDates } from '@remote-com/norma/icons/IconV2DuotoneCalendarDates';
import { IconV2DuotoneCalendarEvent } from '@remote-com/norma/icons/IconV2DuotoneCalendarEvent';
import { IconV2DuotoneCalendarPlus } from '@remote-com/norma/icons/IconV2DuotoneCalendarPlus';
import { IconV2DuotoneCalendarQuestion } from '@remote-com/norma/icons/IconV2DuotoneCalendarQuestion';
import { IconV2DuotoneCheckCircle } from '@remote-com/norma/icons/IconV2DuotoneCheckCircle';
import { IconV2DuotoneCoffee } from '@remote-com/norma/icons/IconV2DuotoneCoffee';
import { IconV2DuotoneCommentDots } from '@remote-com/norma/icons/IconV2DuotoneCommentDots';
import { IconV2DuotoneCreditCardFailed } from '@remote-com/norma/icons/IconV2DuotoneCreditCardFailed';
import { IconV2DuotoneDollarCircle } from '@remote-com/norma/icons/IconV2DuotoneDollarCircle';
import { IconV2DuotoneEditBox } from '@remote-com/norma/icons/IconV2DuotoneEditBox';
import { IconV2DuotoneFileCheck } from '@remote-com/norma/icons/IconV2DuotoneFileCheck';
import { IconV2DuotoneFileDelete } from '@remote-com/norma/icons/IconV2DuotoneFileDelete';
import { IconV2DuotoneFileInvoice } from '@remote-com/norma/icons/IconV2DuotoneFileInvoice';
import { IconV2DuotoneFileText } from '@remote-com/norma/icons/IconV2DuotoneFileText';
import { IconV2DuotoneFolderOpen } from '@remote-com/norma/icons/IconV2DuotoneFolderOpen';
import { IconV2DuotoneGift } from '@remote-com/norma/icons/IconV2DuotoneGift';
import { IconV2DuotoneGraph } from '@remote-com/norma/icons/IconV2DuotoneGraph';
import { IconV2DuotoneLock } from '@remote-com/norma/icons/IconV2DuotoneLock';
import { IconV2DuotoneMail } from '@remote-com/norma/icons/IconV2DuotoneMail';
import { IconV2DuotoneMinusCircle } from '@remote-com/norma/icons/IconV2DuotoneMinusCircle';
import { IconV2DuotoneNoteText } from '@remote-com/norma/icons/IconV2DuotoneNoteText';
import { IconV2DuotonePaperclip } from '@remote-com/norma/icons/IconV2DuotonePaperclip';
import { IconV2DuotoneReceipt } from '@remote-com/norma/icons/IconV2DuotoneReceipt';
import { IconV2DuotoneRocket } from '@remote-com/norma/icons/IconV2DuotoneRocket';
import { IconV2DuotoneShieldCheck } from '@remote-com/norma/icons/IconV2DuotoneShieldCheck';
import { IconV2DuotoneStructureDense } from '@remote-com/norma/icons/IconV2DuotoneStructureDense';
import { IconV2DuotoneStructurePlus } from '@remote-com/norma/icons/IconV2DuotoneStructurePlus';
import { IconV2DuotoneTimesFull } from '@remote-com/norma/icons/IconV2DuotoneTimesFull';
import { IconV2DuotoneUserCheck } from '@remote-com/norma/icons/IconV2DuotoneUserCheck';
import { IconV2DuotoneUserMinus } from '@remote-com/norma/icons/IconV2DuotoneUserMinus';
import { IconV2DuotoneUserPlus } from '@remote-com/norma/icons/IconV2DuotoneUserPlus';
import { IconV2OutlineFileText } from '@remote-com/norma/icons/IconV2OutlineFileText';
import { IconV2OutlinePayrollInputs } from '@remote-com/norma/icons/IconV2OutlinePayrollInputs';
import { IconV2SolidDollarSign } from '@remote-com/norma/icons/IconV2SolidDollarSign';
import kebabCase from 'lodash/kebabCase';
import getConfig from 'next/config';
import { encodeUrlParams } from 'react-url-modal';

import {
  ACCOUNT_SETTINGS_ROUTE,
  ADMIN_COST_APPROVAL_REQUESTS_DETAILS_ROUTE,
  ADMIN_PAYROLL_RUN_ROUTE,
  ADMIN_USER_DETAILS_ROUTE,
  BENEFITS_ROUTE,
  CAREER_JOURNAL_ROUTE,
  COMPANY_CONTRACTOR_SUBSCRIPTIONS_ROUTE,
  CONTRACTOR_INVOICE_ROUTE,
  COMPANY_SSO_ROUTE,
  DASHBOARD_COST_CALCULATOR_ROUTE,
  DASHBOARD_ROUTE,
  DOCUMENTS_ROUTE,
  EMPLOYMENT_REQUESTS_ROUTE,
  EXPENSES_ROUTE,
  FEEDBACK_THREAD_ROUTE,
  HIRING_ROUTE,
  INVOICES_ROUTE,
  LEGAL_ENTITIES_PROFILE_ROUTE,
  LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE,
  NEW_EMPLOYMENT_TYPE_ROUTE,
  PAYMENT_REQUESTS_ROUTE,
  PAYROLL_RUN_DETAILS_ROUTE,
  PAYSLIPS_ROUTE,
  PERSONAL_PROFILE_ROUTE,
  REMOTE_TERMS_OF_SERVICE,
  SETUP_BILLING_ROUTE,
  TEAM_ROUTE,
  TIME_OFF_ROUTE,
  TIME_TRACKING,
  USER_PROFILE_ROUTE,
  WITHDRAWAL_METHODS_ROUTE,
  REPORTS_DETAILS_ROUTE,
  ADMIN_EDIT_CONTRACT_DRAFT_ROUTE,
  CONTRACT_ROUTE_V2,
  PROJECTS_ROUTE,
  TEAM_PERFORMANCE_DIRECT_REPORT_FEEDBACK_ROUTE,
} from '@/src/constants/routes';
import { SSO_SUPPORT_PAGE_URL } from '@/src/domains/companies/sso/constants';
import { formatInvoiceAmount } from '@/src/domains/contractorInvoices/fxHelpers';
import { usStates } from '@/src/domains/countries/constants';
import { getFlagSrc } from '@/src/domains/countries/helpers';
import { CountryFlag } from '@/src/domains/countries/styles';
import { DOCUMENT_TYPE_LABELS } from '@/src/domains/documents/constants';
import { employmentType as employmentTypes } from '@/src/domains/employment/constants';
import { EXPIRING_DOCUMENT_TYPES } from '@/src/domains/expiringDocuments/constants';
import { getFriendlyDocumentTypeName } from '@/src/domains/expiringDocuments/employee/flows/common';
import { IN_APP_NOTIFICATION_TYPES } from '@/src/domains/inAppNotifications/constants';
import {
  ItemContentDetails,
  NotificationItemDescription,
  NotificationItemDescriptionEmphasis,
  NotificationItemTitle,
} from '@/src/domains/inAppNotifications/InAppNotifications.styled';
import { InAppNotificationsError } from '@/src/domains/inAppNotifications/InAppNotificationsError';
import { NotificationItemAvatar } from '@/src/domains/inAppNotifications/NotificationItemAvatar';
import { NotificationItemIcon } from '@/src/domains/inAppNotifications/NotificationItemIcon';
import { TEAM_PAGE_TABS } from '@/src/domains/team/employer/constants';
import { formatTableDateRange } from '@/src/domains/timeTracking/helpers';
import { captureException } from '@/src/helpers/captureException';
import { friendlyMoney, friendlyMoneyWithCurrencyCode } from '@/src/helpers/currency';
import {
  formatDayDateShortMonth,
  formatFullDate,
  formatMonthDayYear,
  formatTime,
  formatYearMonthDay,
} from '@/src/helpers/date';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

function catchMissingNotificationTypeError(notificationType) {
  const message = `Notification ${notificationType} not supported. Tiger notification exists but counterpart Dragon config does not.`;
  const {
    publicRuntimeConfig: { ENVIRONMENT },
  } = getConfig();
  const isDev = ENVIRONMENT !== 'production';
  // if DEV
  if (isDev) {
    // eslint-disable-next-line no-console
    console.warn(message);
  } else {
    // PROD -> datadog
    captureException(new InAppNotificationsError(message));
  }
}

function catchMissingNotificationDataError(notificationType, missingProperties) {
  const message = `Notification ${notificationType} data missing. Property "${missingProperties.join()}" not found in "resource" object.`;
  const {
    publicRuntimeConfig: { ENVIRONMENT },
  } = getConfig();
  const isDev = ENVIRONMENT !== 'production';
  // if DEV
  if (isDev) {
    // eslint-disable-next-line no-console
    console.error(message);
  } else {
    // PROD -> datadog
    captureException(new InAppNotificationsError(message));
  }
}

function checkAndRetrieveData(notificationData, requiredKeys) {
  const data = {
    dataMissing: false,
  };
  requiredKeys.forEach((requiredKey) => {
    data[requiredKey] = notificationData?.resource?.[requiredKey];
    if (!data[requiredKey]) {
      data.dataMissing = true;
      catchMissingNotificationDataError(notificationData.type, [requiredKey]);
    }
  });
  return data;
}

export const getNotificationContent = (notificationData) => {
  switch (notificationData.type) {
    case IN_APP_NOTIFICATION_TYPES.SET_BILLING_CONTACTS: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneMail} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Add a billing representative</NotificationItemTitle>
            <NotificationItemDescription>
              You can invite someone to Remote or set a default email address for invoices.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: SETUP_BILLING_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EXPLORE_DOCUMENT_MANAGEMENT: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneStructurePlus} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Explore Remote's document management tool</NotificationItemTitle>
            <NotificationItemDescription>
              Easily create, send, sign, and store documents for employees and contractors without
              the need for third-party software or manual messiness.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CUSTOM_NOTIFICATION: {
      const { header, description, sendAt, dataMissing } = checkAndRetrieveData(notificationData, [
        'header',
        'description',
        'sendAt',
      ]);

      if (dataMissing) {
        return null;
      }

      const { link } = notificationData.resource;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneRocket} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{`${header}`}</NotificationItemTitle>
            <NotificationItemDescription>{description}</NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: link,
        },
        sendAt,
      };
    }
    case IN_APP_NOTIFICATION_TYPES.SSO_CONFIG_ENABLED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneShieldCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your company turned on single sign-on (SSO)
            </NotificationItemTitle>
            <NotificationItemDescription>
              This allows you to securely log in to Remote using your company email address and
              password.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: SSO_SUPPORT_PAGE_URL,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.SSO_DOMAINS_VERIFIED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneLock} tone="cyan" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your domains have been verified for Single Sign-on (SSO)
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: COMPANY_SSO_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TERMS_OF_SERVICE_UPDATED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneBookOpen} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>We updated our Terms of Service</NotificationItemTitle>
            <NotificationItemDescription>
              There is no action you need to take, but we encourage you to review the updated Terms
              of Service.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: REMOTE_TERMS_OF_SERVICE,
          },
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CAREER_DEVELOPMENT_FEEDBACK_RECEIVED: {
      const { name } = checkAndRetrieveData(notificationData, ['name']);
      if (!name) return null;
      const profilePicture = notificationData?.resource?.profile_picture; // It's OK for this to be null.

      return {
        avatar: <NotificationItemAvatar name={name} src={profilePicture} />,
        body: <NotificationItemTitle>{name} shared feedback with you</NotificationItemTitle>,
        link: {
          href: {
            pathname: CAREER_JOURNAL_ROUTE,
            query: { selectedTab: 'feedback' },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CAREER_DEVELOPMENT_FEEDBACK_DIRECT_REPORT_RECEIVED: {
      const {
        dataMissing,
        authorName,
        directReportName,
        directReportProfilePicture,
        directReportEmploymentSlug,
      } = checkAndRetrieveData(notificationData, [
        'authorName',
        'directReportName',
        'directReportEmploymentSlug',
      ]);
      if (dataMissing) return null;

      return {
        avatar: <NotificationItemAvatar name={directReportName} src={directReportProfilePicture} />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {directReportName} received feedback from {authorName}
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: TEAM_PERFORMANCE_DIRECT_REPORT_FEEDBACK_ROUTE,
            query: { employmentSlug: directReportEmploymentSlug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.COMPANY_FEEDBACK_EMPLOYER_NEW_FEEDBACK: {
      const { slug } = checkAndRetrieveData(notificationData, ['slug']);
      if (!slug) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconCommentDots} tone="green" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>A team member shared feedback</NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: FEEDBACK_THREAD_ROUTE,
            query: { slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.COMPANY_FEEDBACK_EMPLOYER_NEW_REPLY: {
      const { slug } = checkAndRetrieveData(notificationData, ['slug']);
      if (!slug) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconCommentDots} tone="green" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              A team member replied to a feedback thread
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: FEEDBACK_THREAD_ROUTE,
            query: { slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.COMPANY_FEEDBACK_EMPLOYEE_NEW_REPLY: {
      const { companyName, slug } = checkAndRetrieveData(notificationData, ['companyName', 'slug']);
      if (!slug) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconCommentDots} tone="green" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {companyName
                ? `${companyName} responded to your feedback`
                : 'Your feedback received a response'}
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: FEEDBACK_THREAD_ROUTE,
            query: { slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_INVOICE_PAID: {
      const { number, slug } = checkAndRetrieveData(notificationData, ['number', 'slug']);
      if (!slug) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{`Invoice #${number} was approved!`}</NotificationItemTitle>
            <NotificationItemDescription>
              The payment is processing and should reach your account soon.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: CONTRACTOR_INVOICE_ROUTE,
            query: { contractorInvoiceSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_INVOICE_PAYOUT_REPROCESSED: {
      const { number, slug } = checkAndRetrieveData(notificationData, ['number', 'slug']);
      if (!slug) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{`Invoice #${number} was reprocessed!`}</NotificationItemTitle>
            <NotificationItemDescription>
              The payment is processing with the updated withdrawal method and should reach your
              account soon.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: CONTRACTOR_INVOICE_ROUTE,
            query: { contractorInvoiceSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_INVOICE_APPROVED_CONTRACTOR: {
      const { amount, currency, companyName, slug } = checkAndRetrieveData(notificationData, [
        'amount',
        'currency',
        'companyName',
        'slug',
      ]);

      const friendlyAmount = friendlyMoney(amount, currency);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>An invoice was approved</NotificationItemTitle>
            <NotificationItemDescription>
              {`${companyName} approved your invoice for ${friendlyAmount}. We’ll let you know when they pay the invoice.`}
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: CONTRACTOR_INVOICE_ROUTE,
            query: { contractorInvoiceSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_SUBSCRIPTION_COSTS: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Now you can check the running total of your contractor subscriptions invoice at any
              point during the month.
            </NotificationItemTitle>
            <NotificationItemDescription>
              The payment is processing and should reach your account soon.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: COMPANY_CONTRACTOR_SUBSCRIPTIONS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EARLY_PAYROLL_CUTOFF: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2OutlinePayrollInputs} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Early payroll deadline for December</NotificationItemTitle>
            <NotificationItemDescription>
              With the upcoming holidays, please upload all payroll inputs by
            </NotificationItemDescription>
            <NotificationItemDescriptionEmphasis>
              11:59 p.m. UTC on Dec 8.
            </NotificationItemDescriptionEmphasis>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.INACTIVE_CONTRACTORS_DEACTIVATED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneUserMinus} tone="brand" />,
        body: (
          <ItemContentDetails>
            <Box>
              <NotificationItemTitle>Inactive contractors were deactivated.</NotificationItemTitle>
              <NotificationItemDescription>
                If you want to reactivate them, you can do so from your Team page.
              </NotificationItemDescription>
            </Box>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: TEAM_ROUTE,
            query: { selectedTab: TEAM_PAGE_TABS.ARCHIVED },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.NEW_TAX_DOCUMENT_1042_S: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <Box>
              <NotificationItemTitle>
                We generated 1042-S tax forms for your contractors who live outside of the United
                States.
              </NotificationItemTitle>
            </Box>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
            query: {
              selectedTab: 'tax',
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACT_DOCUMENT_AWAITING_VIEW: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <Box>
              <NotificationItemTitle>Your contract was amended.</NotificationItemTitle>
            </Box>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYMENT_VERIFICATION_LETTER_DOCUMENT: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <Box>
              <NotificationItemTitle>Your employment letter is ready.</NotificationItemTitle>
            </Box>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.FIXED_TERM_CONTRACT_EXPIRING_SOON_EMPLOYEE: {
      const { companyName, contractEndDate } = checkAndRetrieveData(notificationData, [
        'companyName',
        'contractEndDate',
      ]);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneAlertCircle} tone="brand" />,
        body: (
          <ItemContentDetails>
            <Box>
              <NotificationItemTitle>
                Your contract with {companyName} is expiring
              </NotificationItemTitle>
              <NotificationItemDescription>
                Your contract with {companyName} is expiring on{' '}
                {formatMonthDayYear(contractEndDate)}. Please review your employment agreement and
                discuss your options with {companyName}.
              </NotificationItemDescription>
            </Box>
          </ItemContentDetails>
        ),
        link: {
          href: { pathname: PERSONAL_PROFILE_ROUTE, query: { selectedTab: 'job-pay' } },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_BLOCKED_BY_NIUM_ONBOARDING: {
      const { companyName } = checkAndRetrieveData(notificationData, ['companyName']);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneShieldCheck} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>You can’t add a withdrawal method yet</NotificationItemTitle>
            <NotificationItemDescription>
              Your client {companyName} is finishing their onboarding with Remote Payments. Until
              then, you are unable to add a withdrawal method to your account or to start raising
              invoices. We’ll email you once ready to let you know when you can continue.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }

    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_CAN_NOW_ADD_NIUM_WITHDRAWAL_METHODS: {
      const { companyName } = checkAndRetrieveData(notificationData, ['companyName']);
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneShieldCheck} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              You can now add a withdrawal method and start invoicing
            </NotificationItemTitle>
            <NotificationItemDescription>
              You can now add your withdrawal method to Remote and start invoicing {companyName}
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: WITHDRAWAL_METHODS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_INVOICE_REJECTED: {
      if (!notificationData.resource) return null;
      const { number, employerComment, rejectedByRemote } = notificationData.resource;
      const formattedAmount = formatInvoiceAmount(notificationData.resource);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>An invoice was declined</NotificationItemTitle>
            <NotificationItemDescription>
              Your invoice {number} for {formattedAmount} was declined
              {rejectedByRemote ? ' by Remote.' : '.'} Reason: {employerComment || 'none'}. Please
              raise a new one if needed.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: INVOICES_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_INVOICE_EXTERNALLY_PAID: {
      const { amount, currency, companyName, slug, dataMissing } = checkAndRetrieveData(
        notificationData,
        ['amount', 'currency', 'companyName', 'slug']
      );
      if (dataMissing) return null;

      const humanAmount = friendlyMoney(amount, currency);
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {`Nice! Your invoice for ${humanAmount} was paid.`}
            </NotificationItemTitle>
            <NotificationItemDescription>
              {companyName} paid it using a method outside of Remote.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: CONTRACTOR_INVOICE_ROUTE,
            query: { contractorInvoiceSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYEE_PROBATION_ENDING_REMINDER: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneUserCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Here’s a friendly reminder that your probation ends soon.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: PERSONAL_PROFILE_ROUTE,
            query: { selectedTab: 'job-pay' },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACT_DOCUMENT_SIGNED: {
      const { count = 1 } = notificationData.resource || {};

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {getSingularPluralUnit(
                count,
                'new contract was signed!',
                'new contracts were signed!',
                false
              )}
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.COST_CALCULATOR_AVAILABLE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneStructureDense} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Explore employment costs around the world with our cost calculator
            </NotificationItemTitle>
            <NotificationItemDescription>
              Tell us about who you want to hire and see the total cost of employment.{' '}
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DASHBOARD_COST_CALCULATOR_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.MARKETING_CAMPAIGN: {
      const { title, description, dataMissing } = checkAndRetrieveData(notificationData, [
        'title',
        'description',
      ]);

      if (dataMissing) {
        return null;
      }

      const { notificationLink } = notificationData.resource;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneRocket} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{`${title}`}</NotificationItemTitle>
            <NotificationItemDescription>{description}</NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: notificationLink,
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.DELETED_AUTO_PAYMENT: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCreditCardFailed} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Auto-pay has been turned off for some contractors
            </NotificationItemTitle>
            <NotificationItemDescription>
              This is temporary while we roll out a new payments network. We'll let you know when
              auto-pay is available again.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: `${PAYMENT_REQUESTS_ROUTE}?modal=setup-automatic-invoice-payments`,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.DECLINED_EXPENSE: {
      const { amount, currency, dataMissing } = checkAndRetrieveData(notificationData, [
        'amount',
        'currency',
      ]);
      if (dataMissing) return null;
      const expenseAmount = friendlyMoney(amount, currency);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneReceipt} tone="greenLight" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your expense for {expenseAmount} was declined
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: { pathname: EXPENSES_ROUTE, query: { selectedTab: 'declined' } },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.DIRECT_EMPLOYEE_COMPLETED_ONBOARDING: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneRocket} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Welcome to Remote! 🎉</NotificationItemTitle>
            <NotificationItemDescription>
              Feel free to explore our platform and update your profile.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: PERSONAL_PROFILE_ROUTE,
            query: { selectedTab: 'personal' },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.FIRST_PAYMENT_INFO: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneBookOpen} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>How you’ll get paid 💰</NotificationItemTitle>
            <NotificationItemDescription>
              Find the answers to all your questions about salary payments.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: 'https://support.remote.com/hc/en-us/articles/5638878014733-When-will-the-employee-get-paid-if-they-re-onboarded-after-the-payroll-cutoff',
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYER_MANAGED_DOCUMENT_AWAITING_VIEW: {
      const { companyName } = checkAndRetrieveData(notificationData, ['companyName']);

      const notificationItemTitle = companyName
        ? `${companyName} shared a document with you.`
        : 'A document was shared with you.';

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{notificationItemTitle}</NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.GENERIC_EMPLOYMENT_DOCUMENT_AWAITING_VIEW: {
      const { templateName } = checkAndRetrieveData(notificationData, ['templateName']);

      if (!templateName) {
        return null;
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your {templateName || ''} document is ready to view.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.GLOBAL_PAYROLL_EMPLOYER_ACCOUNT_PENDING: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2SolidDollarSign} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Welcome to Global Payroll!</NotificationItemTitle>
            <NotificationItemDescription>
              Hang tight while we set up your account. We’ll be in touch soon.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PAYROLL_LEGAL_ENTITY_REFUND_ISSUED: {
      const { amount, currency, legalEntity } = notificationData.details || {};
      const { payrollRunSlug, recommendedFundingDate } = notificationData.resource || {};
      const { name: legalEntityName = 'your legal entity' } = legalEntity || {};

      return {
        avatar: <NotificationItemIcon Icon={IconV2OutlineFileText} tone="green" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Refund issued to {legalEntityName} for an overpayment
            </NotificationItemTitle>
            <NotificationItemDescription>
              We have refunded overpayment of{' '}
              {friendlyMoneyWithCurrencyCode(amount, currency, 'right')} back to you.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: PAYROLL_RUN_DETAILS_ROUTE,
            query: {
              payrollSlug: payrollRunSlug,
              modal: 'employer-payroll-run-receipt',
              params: encodeUrlParams({ payrollRunSlug, recommendedFundingDate }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.NEW_TAX_DOCUMENT_1099_NEC_CONTRACTOR:
    case IN_APP_NOTIFICATION_TYPES.NEW_TAX_DOCUMENT_1099_NEC_NON_EMPLOYEE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Your 1099-NEC tax form is ready.</NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.NEW_TAX_DOCUMENT_1099_NEC_EMPLOYER: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              We generated 1099-NEC tax forms for your US-based contractors.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
            query: {
              selectedTab: 'tax',
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.ONBOARDING_RESERVE_INVOICE_PENDING: {
      const { count, employeeName } = notificationData.resource || {};

      if (!count && !employeeName) {
        return null;
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            {count ? (
              <NotificationItemTitle>
                We're preparing reserve invoices for{' '}
                {
                  // Reserve invoices can be created for both full-time employees and contractors.
                  getSingularPluralUnit(count, 'new hire', 'new hires', false)
                }
              </NotificationItemTitle>
            ) : (
              <NotificationItemTitle>
                We're preparing a reserve invoice for {employeeName}
              </NotificationItemTitle>
            )}
          </ItemContentDetails>
        ),
        link: {
          href: 'https://support.remote.com/hc/en-us/articles/12695731865229',
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.ONBOARDING_RESERVE_PAYMENT_PROCESSING: {
      const { count, employeeName } = notificationData.resource || {};

      if (!count && !employeeName) {
        return null;
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneActivityBox} tone="purple" />,
        body: (
          <ItemContentDetails>
            {count ? (
              <NotificationItemTitle>
                We’re processing your reserve payment for{' '}
                {
                  // Reserve invoices can be created for both full-time employees and contractors.
                  getSingularPluralUnit(count, 'new hire', 'new hires', false)
                }
                .
              </NotificationItemTitle>
            ) : (
              <NotificationItemTitle>
                We’re processing your reserve payment for {employeeName}
              </NotificationItemTitle>
            )}
          </ItemContentDetails>
        ),
        link: {
          href: 'https://support.remote.com/hc/en-us/articles/4411287893901-How-to-pay-your-invoice',
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.ONBOARDING_RESERVE_PAID: {
      const { count, name } = notificationData.resource || {};

      if (!count && !name) {
        return null;
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileInvoice} tone="purple" />,
        body: (
          <ItemContentDetails>
            {count ? (
              <>
                <NotificationItemTitle>
                  Reserve payments processed for{' '}
                  {
                    // Reserve invoices can be created for both full-time employees and contractors.
                    getSingularPluralUnit(count, 'new hire', 'new hires', false)
                  }
                </NotificationItemTitle>
                <NotificationItemDescription>
                  Thank you for completing the reserve payments!
                </NotificationItemDescription>
              </>
            ) : (
              <>
                <NotificationItemTitle>Reserve payment processed</NotificationItemTitle>
                <NotificationItemDescription>
                  Thank you for completing the reserve payment for {name}!
                </NotificationItemDescription>
              </>
            )}
          </ItemContentDetails>
        ),
        link: {
          href: 'https://support.remote.com/hc/en-us/articles/4411287893901-How-to-pay-your-invoice',
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PRE_HIRE_DETAILS_REQUEST_DECLINED: {
      const { employeeName, employmentSlug, dataMissing } = checkAndRetrieveData(notificationData, [
        'employeeName',
        'employmentSlug',
      ]);
      if (dataMissing) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileDelete} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {employeeName}’s pre-hire assessment was declined
            </NotificationItemTitle>
            <NotificationItemDescription>
              We’re sorry to say that they are unable to work with Remote. See the assessment
              results.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            query: {
              modal: 'pre-hire-check-results',
              params: encodeUrlParams({
                slug: employmentSlug,
              }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMOTE_MARKETPLACE_ANNOUNCEMENT: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCheckCircle} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Visit the Remote Marketplace to access exclusive discounts on our trusted partners’
              products and services
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: 'https://marketplace.remote.com/',
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMOTE_WORK_REQUEST_APPROVED: {
      const { employeeName, slug, dataMissing } = checkAndRetrieveData(notificationData, [
        'employeeName',
        'slug',
      ]);
      if (dataMissing) return null;
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCheckCircle} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your request to work remotely was approved
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: EMPLOYMENT_REQUESTS_ROUTE,
            query: {
              modal: 'remote-work-approval-drawer',
              params: encodeUrlParams({ slug, employeeName }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMOTE_WORK_REQUEST_DECLINED_BY_MANGER: {
      const { slug, dataMissing } = checkAndRetrieveData(notificationData, ['slug']);
      if (dataMissing) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneTimesFull} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your manager declined your request to work remotely
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: EMPLOYMENT_REQUESTS_ROUTE,
            query: {
              modal: 'remote-work-approval-drawer',
              params: encodeUrlParams({ slug }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMOTE_WORK_REQUEST_DECLINED_BY_REMOTE: {
      const { slug, dataMissing } = checkAndRetrieveData(notificationData, ['slug']);
      if (dataMissing) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneTimesFull} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your request to work remotely was declined
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: EMPLOYMENT_REQUESTS_ROUTE,
            query: {
              modal: 'remote-work-approval-drawer',
              params: encodeUrlParams({ slug }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TIMEOFF_UPDATED: {
      const { slug, dataMissing } = checkAndRetrieveData(notificationData, ['slug']);
      if (dataMissing) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCalendarEvent} tone="orange" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Your time off request was updated</NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: TIME_OFF_ROUTE,
            query: {
              modal: 'timeoff-details',
              params: encodeUrlParams({ slug }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.AUTOMATIC_TIMEOFF_HOLIDAY_CREATED: {
      const { slug, dataMissing, startDate, metadata } = checkAndRetrieveData(notificationData, [
        'slug',
        'startDate',
        'metadata',
      ]);
      if (dataMissing) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCalendarPlus} tone="orange" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>New public holiday added</NotificationItemTitle>
            <NotificationItemDescription>
              {metadata?.name} will be celebrated on {formatDayDateShortMonth(startDate)}.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: TIME_OFF_ROUTE,
            query: {
              modal: 'timeoff-details',
              params: encodeUrlParams({ slug }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TRAVEL_LETTER_REQUEST_DECLINED_BY_REMOTE: {
      const { slug, dataMissing } = checkAndRetrieveData(notificationData, ['slug']);
      if (dataMissing) return null;
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileDelete} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your request for a travel letter was declined
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: EMPLOYMENT_REQUESTS_ROUTE,
            query: {
              modal: 'travel-letter-approval-drawer',
              params: encodeUrlParams({ slug }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TRAVEL_LETTER_REQUEST_DECLINED_BY_MANAGER: {
      const { slug, dataMissing } = checkAndRetrieveData(notificationData, ['slug']);
      if (dataMissing) return null;
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileDelete} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your manager declined your request for a travel letter
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: EMPLOYMENT_REQUESTS_ROUTE,
            query: {
              modal: 'travel-letter-approval-drawer',
              params: encodeUrlParams({ slug }),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TRAVEL_LETTER_REQUEST_APPROVED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your manager approved your request for a travel letter
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.US_SECTION_125_NOTICE: {
      const { noticeName } = notificationData.resource || {};

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneNoteText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              You have an annual notice{noticeName ? `: ${noticeName}` : ''}
            </NotificationItemTitle>
            <NotificationItemDescription>
              Please save it for your records. No further action is needed.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYER_EMPLOYEE_WITH_SKIPPED_FIELDS: {
      const { slug, name } = notificationData.resource || {};
      if (!slug) {
        catchMissingNotificationDataError(notificationData.type, ['slug']);
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneEditBox} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {name || 'An employee'} is missing details required for onboarding
            </NotificationItemTitle>
            <NotificationItemDescription>
              We reached out to them to avoid delays to their start date.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: HIRING_ROUTE,
            query: {
              'filters[0][id]': 'query',
              'filters[0][value]': slug,
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYER_PROBATION_ENDING_REMINDER: {
      const { probationPeriodEndDate, employeeName, employmentSlug, dataMissing } =
        checkAndRetrieveData(notificationData, [
          'probationPeriodEndDate',
          'employeeName',
          'employmentSlug',
        ]);

      if (dataMissing) {
        return null;
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneUserCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {employeeName}'s probation period ends on {formatMonthDayYear(probationPeriodEndDate)}
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: { pathname: USER_PROFILE_ROUTE, query: { employmentSlug, selectedTab: 'job' } },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PAYSLIP: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneBilling} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>It’s pay day! 🎉</NotificationItemTitle>
            <NotificationItemDescription>
              Your most recent payslip is available. Please allow a few days for your bank to
              process the payment.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: PAYSLIPS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PAYROLL_RUN_CREATED: {
      const { payrollRunName, payrollRunSlug } = notificationData.resource;
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCalendarDates} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>The run {payrollRunName} was created</NotificationItemTitle>
            <NotificationItemDescription>View run.</NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_PAYROLL_RUN_ROUTE,
            query: {
              slug: payrollRunSlug,
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYER_PAYROLL_RUN_CREATED: {
      const { payrollRunSlug, cutoffDate, legalEntity } = notificationData.resource;

      if (!legalEntity || !payrollRunSlug) return null;

      const country = legalEntity.country.name;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCalendarDates} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              A new payroll run has been created for {country}{' '}
              <Box display="inline" verticalAlign="top">
                <CountryFlag src={getFlagSrc(country)} alt={`${country} flag`} />
              </Box>
              .
            </NotificationItemTitle>
            {cutoffDate && (
              <NotificationItemDescription>
                Remember to add all payroll inputs by {formatMonthDayYear(cutoffDate)}.
              </NotificationItemDescription>
            )}
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: PAYROLL_RUN_DETAILS_ROUTE,
            query: {
              payrollSlug: payrollRunSlug,
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PAYROLL_RUN_INPUTS_SUBMISSION_CUTOFF_DATE: {
      const { cutoffDate, count } = notificationData.resource;
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneDollarCircle} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              You have {count} payroll{' '}
              {getSingularPluralUnit(count, 'deadline', 'deadlines', false, false)} approaching
            </NotificationItemTitle>
            <NotificationItemDescription>
              Upload all payroll inputs by 11:59 p.m. UTC on {formatMonthDayYear(cutoffDate)}.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.ONBOARDING_RESERVE_REQUIRED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneBank} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {/* Note: reserve payments can be required for both full-time employees and contractors */}
              Reserve payment required to add a new hire.
            </NotificationItemTitle>
            <NotificationItemDescription>Read about reserve payments.</NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: 'https://support.remote.com/hc/en-us/articles/12695731865229',
          },
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TIMESHEET_WITH_OVERTIME_APPROVED: {
      const { startDate, endDate, dataMissing } = checkAndRetrieveData(notificationData, [
        'startDate',
        'endDate',
      ]);
      if (dataMissing) return null;
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneAlarmClockCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Your overtime was approved!</NotificationItemTitle>
            <NotificationItemDescription>
              Your manager approved your timesheet with overtime hours for the week of{' '}
              {formatTableDateRange(startDate, endDate)}.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: TIME_TRACKING,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TIMESHEET_SENT_BACK_UPDATE: {
      const { startDate, endDate, slug } = checkAndRetrieveData(notificationData, [
        'startDate',
        'endDate',
        'slug',
      ]);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneAlarmClockCheck} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Your timesheet was returned</NotificationItemTitle>
            <NotificationItemDescription>
              A change request has been made for your timesheet for the week of{' '}
              {formatTableDateRange(startDate, endDate)}.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: TIME_TRACKING,
            query: {
              tab: 'tracking',
              slug,
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.BENEFITS_EMPLOYEE_ALLIANZ: {
      const { name } = notificationData.resource || {};

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneGift} tone="pink" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {name ? `${name} b` : 'B'}enefits enrollment is open!{' '}
            </NotificationItemTitle>
            <NotificationItemDescription>
              If you want to enroll for May 2023 - April 2024 benefits coverage, enroll before May
              30, 2023. If you opted out of benefits for the 2022-2023 policy year, that status will
              be maintained unless action is taken to enroll.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: BENEFITS_ROUTE,
            trackNavigation: ['dashboard-notification', 'view_benefit_page'],
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.ADD_NEW_EMPLOYEE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Add your first hire 🎉</NotificationItemTitle>
            <NotificationItemDescription>
              Ready to grow your global team? Start adding new employees or contractors today.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.AUTOMATED_BILLING_UPDATE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Changes to Employer of Record Invoicing</NotificationItemTitle>
            <NotificationItemDescription>
              Beginning in November you’ll receive your invoice at the end of each month, after
              employee salaries have been paid, rather than at the beginning of the month.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_MANAGEMENT_LAUNCH: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Our latest product updates make it easier than ever to onboard, manage, and pay
              contractors in 170+ countries around the world.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.DEPOSIT_NOT_REQUIRED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>10% refundable deposit no longer needed</NotificationItemTitle>
            <NotificationItemDescription>
              We are committed to offering our customers fair and transparent pricing with no
              minimums, hidden fees or upfront charges. We listened to your feedback and{' '}
              <strong>
                as of October 25 we are no longer collecting a 10% refundable deposit from
                customers.
              </strong>
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYEE_INVOICES_PAGE_RENAME: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>All your service invoices in one place</NotificationItemTitle>
            <NotificationItemDescription>
              You can now find invoices for employees on the Service invoices page.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYEE_HEALTH_INSURANCE_POLICY_EXPIRED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneGift} tone="fuchsia" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Your health benefits policy has expired</NotificationItemTitle>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYEE_EXTERNAL_BENEFITS_AVAILABLE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneGift} tone="fuchsia" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your company offered you health benefits! 🩺
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EMPLOYEE_HEALTH_INSURANCE_POLICY_CANCELLED: {
      const {
        details: { cancellationDate },
      } = notificationData;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneGift} tone="fuchsia" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your health benefits are no longer active as of {formatMonthDayYear(cancellationDate)}
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EXPIRING_DOCUMENT_ANNOUNCEMENT: {
      const { expiringDocument, dataMissing } = checkAndRetrieveData(notificationData, [
        'expiringDocument',
      ]);
      if (dataMissing) return null;
      const documentName = getFriendlyDocumentTypeName(expiringDocument.type).toLowerCase();
      let documentSpecificNote = '';
      switch (expiringDocument.type) {
        case EXPIRING_DOCUMENT_TYPES.WORK_PERMIT: {
          documentSpecificNote =
            'If their document is not renewed in time, their right to work will be at risk.';
        }
      }
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {expiringDocument.employeeName}'s {documentName} expires on{' '}
              {formatMonthDayYear(expiringDocument?.expirationDate)}
            </NotificationItemTitle>
            <NotificationItemDescription>{documentSpecificNote}</NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EXPIRING_DOCUMENT_UNRESPONSIVE: {
      const { type, employeeName, expirationDate, dataMissing } = checkAndRetrieveData(
        notificationData,
        ['type', 'employeeName', 'expirationDate']
      );
      if (dataMissing) return null;
      const documentName = getFriendlyDocumentTypeName(type).toLowerCase();
      let documentSpecificNote = '';
      switch (type) {
        case EXPIRING_DOCUMENT_TYPES.WORK_PERMIT: {
          documentSpecificNote =
            'Their right to work is at risk should the document not be renewed in time.';
        }
      }
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {employeeName}'s {documentName} is about to expire
            </NotificationItemTitle>
            <NotificationItemDescription>
              {employeeName}'s {documentName} expires on {formatMonthDayYear(expirationDate)} and
              they have not responded to our requests yet.
              {documentSpecificNote}
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.INCENTIVES_FEATURE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>You can now make incentive payments</NotificationItemTitle>
            <NotificationItemDescription>
              Now, you can pay commissions, bonuses, stipends, allowances, and other one-time
              payments beyond salary to employees.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.INTEGRATIONS_GREENHOUSE_NEW: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              New Remote + Greenhouse integration available!
            </NotificationItemTitle>
            <NotificationItemDescription>
              Securely connect your Greenhouse account with Remote now to sync new hire information
              between platforms with a single click.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.NEW_BILLING_PROCESS_MARCH_2022: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Change to Remote invoicing process begins this month
            </NotificationItemTitle>
            <NotificationItemDescription>
              To improve invoicing accuracy, predictability, and flexibility, we are pleased to
              announce a new invoicing process beginning March 2022.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMOTE_RELOCATION_UPDATE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Relocate and work from anywhere</NotificationItemTitle>
            <NotificationItemDescription>
              Announcing our new Remote Relocation service, available now in 5 countries to the
              first 50 companies that sign up.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMOTE_UNICORN_UPDATE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Remote is a 🦄!</NotificationItemTitle>
            <NotificationItemDescription>
              We raised $150M to help you scale your global team. Our CEO, Job van der Voort, shares
              what’s next on our blog.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.SEPA_DEBITOR_CHANGES: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>A change to SEPA Direct Debits</NotificationItemTitle>
            <NotificationItemDescription>
              The description you'll see on your bank statements for all SEPA direct debits from
              Remote, will appear as <strong>PPRO Payment Services SA Ltd.</strong> Inform your
              finance team of this change to ensure no payments are recalled in error.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACTOR_FREE_TRIAL_AVAILABLE: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Activate your free trial of Contractor Management
            </NotificationItemTitle>
            <NotificationItemDescription>
              Add a contractor to activate your 30-day free trial of Contractor Management!
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: NEW_EMPLOYMENT_TYPE_ROUTE.replace(
              '[employmentType]',
              employmentTypes.CONTRACTOR
            ),
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.AT_RISK: {
      const { employment, dataMissing } = checkAndRetrieveData(notificationData, ['employment']);
      if (dataMissing) return null;
      const name = employment.user?.name || 'An employee';
      const slug = employment.user?.slug;

      if (!slug) {
        catchMissingNotificationDataError(notificationData.type, ['employment.user.slug']);
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneUserMinus} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{name}'s onboarding was marked as at risk</NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_USER_DETAILS_ROUTE,
            query: { selectedTab: 'employment', userSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.MOBILITY_ASSISTANCE_STATUS_SOCIAL_SECURITY_NUMBER_APPLICATION_SUBMITTED: {
      const { employment, dataMissing } = checkAndRetrieveData(notificationData, ['employment']);
      if (dataMissing) {
        return null;
      }
      const name = employment.user?.name;
      const slug = employment.user?.slug;

      if (!slug) {
        catchMissingNotificationDataError(notificationData.type, ['employment.user.slug']);
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCheckCircle} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {name}'s social security number application was submitted.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_USER_DETAILS_ROUTE,
            query: { selectedTab: 'employment', userSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.MOBILITY_ASSISTANCE_STATUS_SOCIAL_SECURITY_NUMBER_APPLICATION_ISSUE_RESOLUTION: {
      const { employment, dataMissing } = checkAndRetrieveData(notificationData, ['employment']);
      if (dataMissing) {
        return null;
      }
      const name = employment.user?.name;
      const slug = employment.user?.slug;

      if (!slug) {
        catchMissingNotificationDataError(notificationData.type, ['employment.user.slug']);
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneAlertCircle} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {name}'s social security number application has an issue.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_USER_DETAILS_ROUTE,
            query: { selectedTab: 'employment', userSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.MOBILITY_ASSISTANCE_STATUS_PROCESS_CANCELED: {
      const { employment, dataMissing } = checkAndRetrieveData(notificationData, ['employment']);
      if (dataMissing) {
        return null;
      }
      const name = employment.user?.name;
      const slug = employment.user?.slug;

      if (!slug) {
        catchMissingNotificationDataError(notificationData.type, ['employment.user.slug']);
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneTimesFull} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {name}'s mobility assistance was canceled.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_USER_DETAILS_ROUTE,
            query: { selectedTab: 'employment', userSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.MOBILITY_ASSISTANCE_STATUS_PROCESS_COMPLETED: {
      const { employment, dataMissing } = checkAndRetrieveData(notificationData, ['employment']);
      if (dataMissing) {
        return null;
      }
      const name = employment.user?.name;
      const slug = employment.user?.slug;

      if (!slug) {
        catchMissingNotificationDataError(notificationData.type, ['employment.user.slug']);
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCheckCircle} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {name}'s social security number was added to their profile.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_USER_DETAILS_ROUTE,
            query: { selectedTab: 'employment', userSlug: slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.COST_APPROVAL_REQUEST_APPROVED: {
      const { slug, dataMissing } = checkAndRetrieveData(notificationData, ['slug']);
      if (dataMissing) {
        return null;
      }

      const beneficiary =
        notificationData.resource.employment?.user?.name ?? notificationData.resource.company?.name;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCheckCircle} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {beneficiary}'s cost approval request was approved
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_COST_APPROVAL_REQUESTS_DETAILS_ROUTE,
            query: { selectedTab: 'employment', slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.COST_APPROVAL_REQUEST_DECLINED: {
      const { slug, dataMissing } = checkAndRetrieveData(notificationData, ['slug']);
      if (dataMissing) {
        return null;
      }

      const beneficiary =
        notificationData.resource.employment?.user?.name ?? notificationData.resource.company?.name;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneMinusCircle} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {beneficiary}'s cost approval request was declined
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ADMIN_COST_APPROVAL_REQUESTS_DETAILS_ROUTE,
            query: { selectedTab: 'employment', slug },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.COMPLIANCE_PROFILE_REFERRED: {
      const legalEntityName = notificationData?.details?.legalEntityName;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneNoteText} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {legalEntityName
                ? `Your legal entity ${legalEntityName} is under review`
                : 'Your company is under review'}
            </NotificationItemTitle>
            <NotificationItemDescription>
              {legalEntityName
                ? `Please note that any new hires added to ${legalEntityName} will remain in draft until the review is complete. A reserve invoice payment may be required before hiring, depending on the outcome.`
                : `Depending on the outcome, a reserve invoice may be payable before hiring. You can add
              a new hire, but it will remain in draft until our review is complete.`}
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: 'https://support.remote.com/hc/en-us/articles/12696640919949-What-does-it-mean-if-my-company-account-is-being-reviewed-',
          target: '_blank',
          rel: 'noopener',
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.INVITE_CONTRACTOR_TO_COMPANY: {
      const { name, email, dataMissing } = checkAndRetrieveData(notificationData, [
        'name',
        'email',
      ]);

      if (dataMissing) return null;

      const params = encodeUrlParams({ name, email });

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneUserPlus} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>New contractor invitation</NotificationItemTitle>
            <NotificationItemDescription>
              {name} has invited you to collaborate with them on Remote. Set up a contractor
              agreement with them to get started.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: NEW_EMPLOYMENT_TYPE_ROUTE.replace(
              '[employmentType]',
              employmentTypes.CONTRACTOR
            ),
            query: { params },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMIND_COMPLETE_COMPANY_SIGNUP: {
      const sentAt = notificationData?.details?.completeCompanySignupReminderSentAt;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotonePaperclip} tone="purple" />,
        body: (
          <ItemContentDetails>
            {sentAt ? (
              <NotificationItemDescription>
                {`You’ve sent an email reminder to your account owner on ${formatFullDate(
                  sentAt
                )} to finish account set up`}
              </NotificationItemDescription>
            ) : (
              <NotificationItemTitle>Send a reminder to account owner</NotificationItemTitle>
            )}
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: DASHBOARD_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.TWO_FACTOR_SETUP: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneLock} tone="brand" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>Set up two-factor authentication (2FA)</NotificationItemTitle>
            <NotificationItemDescription>
              Use an authenticator app to protect sensitive information and secure your account.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: ACCOUNT_SETTINGS_ROUTE,
            query: { selectedTab: 'security' },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REJECTED_LEGAL_ENTITY_DOCUMENT: {
      const { legalEntitySlug, documentType } = checkAndRetrieveData(notificationData, [
        'legalEntitySlug',
        'documentType',
      ]);

      if (!legalEntitySlug) {
        return null;
      }

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Your {documentType} document needs to be reviewed and resubmitted.
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE,
            query: {
              slug: legalEntitySlug,
              documentType: kebabCase(documentType),
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.LEGAL_ENTITY_NEW_STATE_TAX_FORM: {
      const { slug, name } = checkAndRetrieveData(notificationData, ['slug', 'name']);

      if (!slug) {
        return null;
      }

      const state = notificationData?.details?.state;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFileText} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              {Object.keys(usStates).includes(state) ? usStates[state] : ''} tax info is required
              for {name}
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: LEGAL_ENTITIES_PROFILE_ROUTE,
            query: {
              slug,
              scroll: 'true',
              selectedTab: 'details',
            },
          },
        },
      };
    }

    case IN_APP_NOTIFICATION_TYPES.REMOTE_PAYMENTS_SETUP_APPROVED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCheckCircle} tone="greenLight" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Remote Payments is set up on your account!
            </NotificationItemTitle>
            <NotificationItemDescription>
              You can now use all types of payment methods, including Direct debit, process
              compliant contractor payments, and run payroll on the platform.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.REMOTE_PAYMENTS_SETUP_PENDING: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCoffee} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              Remote Payment details setup awaiting approval
            </NotificationItemTitle>
            <NotificationItemDescription>
              We're verifying your details for a secure, seamless financial experience.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.EOR_ANNUAL_PLAN_START: {
      const { title, description, dataMissing } = checkAndRetrieveData(notificationData, [
        'title',
        'description',
      ]);

      if (dataMissing) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneShieldCheck} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{title}</NotificationItemTitle>
            <NotificationItemDescription>{description}</NotificationItemDescription>
          </ItemContentDetails>
        ),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.MISSING_TIMESHEET: {
      const { startDate, endDate, slug } = checkAndRetrieveData(notificationData, [
        'startDate',
        'endDate',
        'slug',
      ]);

      // It's OK for these to be null.
      const employeeName = notificationData?.resource?.employeeName;
      const profilePicture = notificationData?.resource?.profilePicture;

      const notificationTitle = employeeName
        ? `${employeeName}'s timesheet for ${formatTableDateRange(
            startDate,
            endDate
          )} wasn't submitted.`
        : 'Unsubmitted timesheet';

      return {
        avatar: employeeName ? (
          <NotificationItemAvatar name={employeeName} src={profilePicture} />
        ) : (
          <NotificationItemIcon Icon={IconV2DuotoneCalendarQuestion} tone="orange" />
        ),
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>{notificationTitle}</NotificationItemTitle>
            {!employeeName && (
              <NotificationItemDescription>
                Your timesheet for {formatTableDateRange(startDate, endDate)} wasn't submitted.
              </NotificationItemDescription>
            )}
          </ItemContentDetails>
        ),
        ...(!employeeName && {
          link: {
            href: {
              pathname: TIME_TRACKING,
              query: { tab: 'tracking', slug },
            },
          },
        }),
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PAYROLL_CUSTOMER_GROSS_TO_NET_REPORT_READY: {
      const { payrollRunName } = checkAndRetrieveData(notificationData, ['payrollRunName']);
      if (!payrollRunName) return null;

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneGraph} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              You have a new gross-to-net report for {payrollRunName}
            </NotificationItemTitle>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: REPORTS_DETAILS_ROUTE,
            query: {
              slug: 'payroll-reports',
            },
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.CONTRACT_DOCUMENT_NEW_COMMENT:
    case IN_APP_NOTIFICATION_TYPES.CONTRACT_DOCUMENT_NEW_REPLY_TO_COMMENT: {
      const isEmployeeNotification = notificationData?.details?.userRole === 'employee';
      const employeeName = notificationData?.details?.employeeName;
      const documentType =
        DOCUMENT_TYPE_LABELS[notificationData?.details?.contractDocumentType].toLowerCase();
      const contractSlug = notificationData?.details?.contractDocumentSlug;
      const formattedDate = `${formatYearMonthDay(notificationData.insertedAt)} at ${formatTime(
        notificationData.insertedAt
      )}`;
      const commentSlug =
        notificationData?.resource?.parentCommentSlug || notificationData?.resource?.slug;
      const link = {
        href: {
          pathname: isEmployeeNotification ? CONTRACT_ROUTE_V2 : ADMIN_EDIT_CONTRACT_DRAFT_ROUTE,
          query: {
            [isEmployeeNotification ? 'contractDocumentSlug' : 'slug']: contractSlug,
            selectedTab: 'comments-tab',
            commentSlug,
            highlightSource: 'editor',
          },
        },
      };

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneCommentDots} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              New comment on your {documentType}
              {isEmployeeNotification ? '!' : ` for ${employeeName}`}
            </NotificationItemTitle>
            <NotificationItemDescription>
              {`You have a new comment made on ${formattedDate}. Check it out and reply as soon as possible to avoid any delays.`}
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link,
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PROJECT_MANAGEMENT_ROLE_ASSIGNED: {
      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFolderOpen} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              You now have permission to create projects
            </NotificationItemTitle>
            <NotificationItemDescription>
              You can create new projects and assign team members to work on them.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: PROJECTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PROJECT_LEAD_ASSIGNED: {
      const { projectName } = checkAndRetrieveData(notificationData, ['projectName']);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFolderOpen} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>You've been assigned as a project lead</NotificationItemTitle>
            <NotificationItemDescription>
              You've been added to a new project "{projectName}". You can see all the invoices and
              time tracking associated with the project, as well as add or remove team members.
              associated with the project, as well as add or remove team members.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: PROJECTS_ROUTE,
          },
        },
      };
    }
    case IN_APP_NOTIFICATION_TYPES.PROJECT_MEMBER_ASSIGNED: {
      const { projectName } = checkAndRetrieveData(notificationData, ['projectName']);

      return {
        avatar: <NotificationItemIcon Icon={IconV2DuotoneFolderOpen} tone="purple" />,
        body: (
          <ItemContentDetails>
            <NotificationItemTitle>
              You've been added to "{projectName}" project
            </NotificationItemTitle>
            <NotificationItemDescription>
              You can now create invoices for this project to keep track of your project-related
              services.
            </NotificationItemDescription>
          </ItemContentDetails>
        ),
        link: {
          href: {
            pathname: INVOICES_ROUTE,
          },
        },
      };
    }
    default: {
      catchMissingNotificationTypeError(notificationData.type);
      return null;
    }
  }
};
