import type { ValueOf } from 'type-fest';

import type { Currency } from '@/src/api/config/employ/shared.types';
import { friendlyMoney } from '@/src/helpers/currency';

import type { PaymentMethodUseCategory } from '../types';

export const directDebitResourceTypes = {
  /* Stripe US */
  STRIPE_ACH_DIRECT_DEBIT: 'stripe_ach',
  /* Stripe NL */
  STRIPE_SEPA_DIRECT_DEBIT: 'stripe_sepa',
  /* Stripe UK */
  STRIPE_BACS_DIRECT_DEBIT: 'stripe_bacs',
} as const;

export const creditAndDebitCardResourceTypes = {
  STRIPE_CARD: 'stripe_card',
} as const;

export const wireTransferResourceTypes = {
  WIRE_TRANSFER: 'wire_transfer',
} as const;

export const stripeBankTransferResourceTypes = {
  STRIPE_BANK_TRANSFER: 'stripe_bank_transfer',
} as const;

export const resourceTypes = {
  ...creditAndDebitCardResourceTypes,
  ...wireTransferResourceTypes,
  ...directDebitResourceTypes,
  ...stripeBankTransferResourceTypes,
} as const;

export type NonWireResourceType =
  | ValueOf<typeof directDebitResourceTypes>
  | ValueOf<typeof creditAndDebitCardResourceTypes>;

export type ResourceType = ValueOf<typeof resourceTypes>;

export type StripeResourceType = Exclude<ResourceType, 'wire_transfer'>;

export const wireChargeTypes = {
  MANUAL: 'manual',
  STRIPE_ACH_CREDIT_TRANSFER: 'ach_credit_transfer',
  STRIPE_BANK_TRANSFER: 'stripe_bank_transfer',
} as const;

export type WireChargeType = ValueOf<typeof wireChargeTypes>;

// Wire transfer equivalent of Stripe payment methods
export const wireTransferPaymentMethod = {
  resourceType: resourceTypes.WIRE_TRANSFER,
  slug: resourceTypes.WIRE_TRANSFER,
};

export const stripeCardTypes = {
  AMEX: 'amex',
  DINERS: 'diners',
  DISCOVER: 'discover',
  JCB: 'jcb',
  MASTERCARD: 'mastercard',
  UNIONPAY: 'unionpay',
  VISA: 'visa',
  UNKNOWN: 'unknown',
} as const;

export type StripeCardType = ValueOf<typeof stripeCardTypes>;

export const stripeCardBrands: Record<StripeCardType, string> = {
  [stripeCardTypes.AMEX]: 'American Express',
  [stripeCardTypes.DINERS]: 'Diners Club',
  [stripeCardTypes.DISCOVER]: 'Discover',
  [stripeCardTypes.JCB]: 'JCB',
  [stripeCardTypes.MASTERCARD]: 'MasterCard',
  [stripeCardTypes.UNIONPAY]: 'UnionPay',
  [stripeCardTypes.VISA]: 'Visa',
  [stripeCardTypes.UNKNOWN]: 'Unknown',
};

export const paymentMethodTypeLabels: Record<ResourceType, string> = {
  [resourceTypes.STRIPE_ACH_DIRECT_DEBIT]: 'ACH Direct Debit',
  [resourceTypes.STRIPE_BACS_DIRECT_DEBIT]: 'BACS Direct Debit',
  [resourceTypes.STRIPE_SEPA_DIRECT_DEBIT]: 'SEPA Direct Debit',
  [resourceTypes.STRIPE_CARD]: 'Credit/Debit Card',
  [resourceTypes.WIRE_TRANSFER]: 'Bank Transfer',
  [resourceTypes.STRIPE_BANK_TRANSFER]: 'Bank Transfer',
};

export const wireChargeTypeLabels = {
  [wireChargeTypes.MANUAL]: 'Bank Transfer - Manual',
  [wireChargeTypes.STRIPE_ACH_CREDIT_TRANSFER]: 'Bank Transfer - Stripe ACH',
  [wireChargeTypes.STRIPE_BANK_TRANSFER]: 'Bank Transfer - Stripe Bank Transfer',
};

export type StripeTransferResource = Exclude<
  ResourceType,
  'wire_transfer' | 'stripe_bank_transfer'
>;

type StripeLimitConfig = {
  [resourceType in StripeTransferResource]: {
    amount: number;
    message: (currency?: Currency) => string;
    splitDoneMessage: (transactionsNumber: number, currency?: Currency) => string;
  };
};

export const stripeLimits: StripeLimitConfig = {
  [resourceTypes.STRIPE_CARD]: {
    amount: 99999999,
    message: (currency) =>
      `We have a ${friendlyMoney(
        1_000_000_00,
        currency,
        ''
      )} transaction limit, so we'll split your payment into multiple transactions. They will appear separately in your bank account.`,
    splitDoneMessage: (transactionsNumber, currency) =>
      `Your payment was split into ${transactionsNumber} transactions because we have a ${friendlyMoney(
        1_000_000_00,
        currency,
        ''
      )} transaction limit.`,
  },
  [resourceTypes.STRIPE_ACH_DIRECT_DEBIT]: {
    amount: 999_999_99,
    message: () =>
      "We have a USD $1,000,000.00 transaction limit, so we'll split your payment into multiple transactions. They will appear separately in your bank account.",
    splitDoneMessage: (transactionsNumber: number) =>
      `Your payment was split into ${transactionsNumber} transactions because we have a USD 100,000.00 transaction limit.`,
  },
  [resourceTypes.STRIPE_SEPA_DIRECT_DEBIT]: {
    amount: 500_000_00,
    message: () =>
      "We have a EUR €500,000.00 transaction limit, so we'll split your payment into multiple transactions. They will appear separately in your bank account.",
    splitDoneMessage: (transactionsNumber: number) =>
      `Your payment was split into ${transactionsNumber} transactions because we have a EUR €500,000.00 transaction limit.`,
  },
  [resourceTypes.STRIPE_BACS_DIRECT_DEBIT]: {
    amount: 500_000_00,
    message: () =>
      "We have a GBP £500,000.00 transaction limit, so we'll split your payment into multiple transactions. They will appear separately in your bank account.",
    splitDoneMessage: (transactionsCount: number) =>
      `Your payment was split into ${transactionsCount} transactions because we have a EUR €500,000.00 transaction limit.`,
  },
};

export const achAccountHolderTypeOptions = [
  {
    value: 'Company',
    label: 'Company',
  },
];

export const achCountryOptions = [
  {
    label: 'United States',
    value: 'US',
  },
];

export const achCurrencyOptions = [
  {
    label: 'USD',
    value: 'USD',
  },
];

export const achFieldLabels = {
  accountHolderName: 'Account holder',
  accountHolderType: 'Ownership type',
  country: 'Bank country',
  currency: 'Currency',
  accountNumber: 'Account number',
  routingNumber: 'ACH routing number',
  profileType: 'Account type',
  name: 'Account name',
  last4: 'Last four digits of account',
};

export const paymentMethodStatus = {
  AWAITING_VERIFICATION: 'awaiting_verification',
  AWAITING_AUTHORIZATION: 'awaiting_authorization',
  ACTIVE: 'active',
  INVALID: 'invalid',
};

export const gustoStatus = {
  AWAITING_DEPOSITS: 'awaiting_deposits',
  READY_FOR_VERIFICATION: 'ready_for_verification',
  VERIFIED: 'verified',
} as const;

export type GustoAchStatus = ValueOf<typeof gustoStatus>;

const signatureBankRoutingNumbers = ['026013576'];

const siliconValleyBankRoutingNumbers = ['121140399', '121145145'];

export const blockedRoutingNumbers = [
  ...signatureBankRoutingNumbers,
  ...siliconValleyBankRoutingNumbers,
];

// Unicredit has stopped processing SEPA DD from Stripe so we are blocking users from adding them as a payment method.
// For more details, see https://linear.app/remote/issue/EP-288/handle-unicredit-rejecting-stripe-sepa-payments
export const blockedSepaSwiftCodes = ['UNCRITMMXXX', 'UNCRITMM'];

export const MANAGE_PAYMENT_METHOD_TABS = {
  used_for: 'Can be used to pay',
  details: 'Details',
};

export const usedForDetails: Record<
  PaymentMethodUseCategory,
  { label: string; description: string }
> = {
  remoteInvoices: {
    label: 'Remote invoices',
    description:
      'Process payments for invoices from Remote, e.g. EOR invoices, Contractor Subscriptions and Remote Payroll fees.',
  },
  contractorInvoices: {
    label: 'Contractor invoices',
    description: 'Process payments for invoices received from your own contractors.',
  },
  remotePayroll: {
    label: 'Remote payroll',
    description:
      'Send money to fund your payroll and seamlessly process employees and statutory payments.',
  },
};
