import { IconV2OutlineBilling } from '@remote-com/norma/icons/IconV2OutlineBilling';
import { IconV2OutlineDollarSign } from '@remote-com/norma/icons/IconV2OutlineDollarSign';
import { IconV2OutlineUsers } from '@remote-com/norma/icons/IconV2OutlineUsers';

import { themeV2 as theme } from '@/src/config/styles';
import { customerDataScopeTypes } from '@/src/domains/employment/constants';
import EmployerPayrollReports from '@/src/domains/payroll/employer/EmployerPayrollReports';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import SpanOfControlWidget from '@/src/domains/reports//components/ReportWidgets/SpanOfControlWidget';
import WorkforceBreakdownWidget from '@/src/domains/reports//components/ReportWidgets/WorkforceBreakdownWidget';
import ReportingLibraryDashboard from '@/src/domains/reports/components/ReportingLibraryDashboard';
import OrganizationalStructureWidget from '@/src/domains/reports/components/ReportWidgets/OrganizationalStructureWidget';
import type {
  ReportSummaryCategoryColors,
  ReportList,
  ScopedName,
} from '@/src/domains/reports/types';

export const SCOPED_NAMES = {
  reports: {
    'org-health-statistics': {
      default: 'Team health statistics',
      [customerDataScopeTypes.ENTIRE_COMPANY]: 'Org health statistics',
    } satisfies ScopedName,
  },
  widgets: {
    'organizational-structure': {
      default: 'Team structure',
      [customerDataScopeTypes.ENTIRE_COMPANY]: 'Organizational structure',
    } satisfies ScopedName,
  },
} as const;

export const ICONS = {
  CALCULATOR: IconV2OutlineDollarSign,
  BILLING: IconV2OutlineBilling,
  USERS: IconV2OutlineUsers,
};

export const REPORTS_SUMMARY_CATEGORIES_COLORS: ReportSummaryCategoryColors[] = [
  theme.colors.brand[600],
  theme.colors.brand[400],
  theme.colors.brand[200],
];

export const REPORT_CATEGORY = {
  PAYROLL: 'Payroll',
  BILLING: 'Billing',
  HR: 'HR',
} as const;

const today = new Date();

export const REPORT_LIST: ReportList[] = [
  {
    category: REPORT_CATEGORY.HR,
    permission: Resources.employer.hr_reports,
    faq: [],
    reportTemplates: [
      {
        slug: 'org-health-statistics',
        name: 'Org health statistics',
        description:
          'Insights into workforce distribution, organizational structure, and manager:employee ratios.',
        icon: ICONS.USERS,
        iconTone: 'blueLight',
        component: ReportingLibraryDashboard,
        hasSummary: false,
        widgets: [
          {
            name: 'Workforce breakdown',
            slug: 'workforce-breakdown',
            description: 'Breakdown of your workforce by department.',
            Component: WorkforceBreakdownWidget,
            path: '/api/v1/reports/people/[slug]',
          },
          {
            name: 'Organizational Structure',
            slug: 'organizational-structure',
            description: 'Breakdown of your workforce by department.',
            Component: OrganizationalStructureWidget,
            path: '/api/v1/reports/people/[slug]',
          },
          {
            name: 'Span of control',
            slug: 'span-of-control',
            description: 'How many direct reports their managers has',
            Component: SpanOfControlWidget,
            path: '/api/v1/reports/people/span-of-control',
          },
        ],
      },
      {
        slug: 'team-member-directory',
        name: 'Team Member Directory',
        description: 'Access a detailed listing of team members across departments.',
        icon: ICONS.USERS,
        iconTone: 'blueLight',
        path: '/api/v1/reports/people/employees',
        hasSummary: false,
        tableOptions: {
          globalFilterQueryKey: 'employee_name',
        },
      },
      {
        slug: 'team-addresses',
        name: 'Team Addresses',
        description: 'Quickly access team members addresses for easy reference and outreach.',
        icon: ICONS.USERS,
        iconTone: 'blueLight',
        path: '/api/v1/reports/people/employees',
        hasSummary: false,
        permission: Resources.employer.people_sensitive_details,
        permissionOperation: 'read',
        tableOptions: {
          globalFilterQueryKey: 'employee_name',
        },
      },
      {
        slug: 'emergency-contacts',
        name: 'Emergency contacts',
        description: 'Access essential emergency contact information for all team members.',
        icon: ICONS.USERS,
        iconTone: 'blueLight',
        path: '/api/v1/reports/people/employees',
        hasSummary: false,
        permission: Resources.employer.people_sensitive_details,
        permissionOperation: 'read',
        tableOptions: {
          globalFilterQueryKey: 'employee_name',
        },
      },
      {
        slug: 'probation-end-date',
        name: 'Probation end date',
        description: 'Access the end date of the probation period for all team members.',
        icon: ICONS.USERS,
        iconTone: 'blueLight',
        path: '/api/v1/reports/people/employees',
        hasSummary: false,
        permission: Resources.employer.people_sensitive_details,
        permissionOperation: 'read',
        tableOptions: {
          globalFilterQueryKey: 'employee_name',
          withQS: true,
          initialState: {
            filters: [
              { id: 'has_probation_period_end_date', value: true },
              {
                id: 'probation_period_end_date',
                value: [today.toISOString().split('T')[0], '2050-01-01'],
              },
            ],
            sortBy: [{ id: 'probation_period_end_date', desc: false }],
          },
        },
      },
      {
        slug: 'ending-contracts',
        name: 'Ending contracts',
        description: 'Access the end date list of the contracts for all team members.',
        icon: ICONS.USERS,
        iconTone: 'blueLight',
        path: '/api/v1/reports/people/employees',
        hasSummary: false,
        permission: Resources.employer.people_sensitive_details,
        permissionOperation: 'read',
        tableOptions: {
          withQS: true,
          globalFilterQueryKey: 'employee_name',
          initialState: {
            filters: [{ id: 'has_contract_end_date', value: true }],
            sortBy: [{ id: 'contract_end_date', desc: false }],
          },
        },
      },
      {
        slug: 'team-birthdays',
        name: 'Team birthdays',
        description: 'Access the birthdays of all team members.',
        icon: ICONS.USERS,
        iconTone: 'blueLight',
        path: '/api/v1/reports/people/employees',
        hasSummary: false,
        permission: Resources.employer.people_sensitive_details,
        permissionOperation: 'read',
        tableOptions: {
          globalFilterQueryKey: 'employee_name',
        },
      },
    ],
  },
  {
    category: REPORT_CATEGORY.PAYROLL,
    permission: Resources.employer.payroll_reports,
    faq: [
      {
        title: 'Will I see all my payroll runs in the report?',
        body: "You'll see all your completed payroll runs in your report.",
      },
      {
        title: 'Can I change the currency in the report?',
        body: 'Currently, we can only display the report in your billing currency.',
      },
      {
        title: 'Does this report account for all expenses?',
        body: "This report doesn't include management fees or manual invoice adjustments.",
      },
    ],
    reportTemplates: [
      {
        slug: 'gross-to-net-summary',
        name: 'Gross-to-net monthly summary',
        description: 'Breakdown of gross pay, deductions, and net pay for every employee.',
        icon: ICONS.CALCULATOR,
        iconTone: 'green',
        path: '/api/v1/reports/payroll/[slug]',
        hasSummary: true,
      },
      {
        slug: 'year-to-date-costs-summary',
        name: 'Year-to-date payroll costs',
        description: 'Month-by-month view of payroll costs for the year so far.',
        icon: ICONS.CALCULATOR,
        iconTone: 'green',
        path: '/api/v1/reports/payroll/[slug]',
        hasSummary: true,
      },
      {
        slug: 'workforce-cost-summary',
        name: 'Cost of workforce',
        permission: Resources.employer.contractor_reports,
        description: 'Breakdown of the cost of your workforce, sorted by employment type.',
        icon: ICONS.CALCULATOR,
        iconTone: 'green',
        path: '/api/v1/reports/payroll/[slug]',
        hasSummary: true,
      },
      {
        slug: 'payroll-run-reports',
        name: 'Payroll run reports',
        description: 'Breakdown of gross pay, deductions, and net pay for each payroll run.',
        icon: ICONS.CALCULATOR,
        iconTone: 'green',
        component: EmployerPayrollReports,
        hasSummary: false,
      },
    ],
  },
  {
    category: REPORT_CATEGORY.BILLING,
    permission: Resources.employer.billing_reports,
    faq: [],
    reportTemplates: [
      {
        slug: 'consolidated-invoices-eor',
        name: 'YTD Consolidated Invoices EOR',
        description: 'A list of all EOR related invoices for the current year.',
        icon: ICONS.BILLING,
        iconTone: 'green',
        path: '/api/v1/reports/billing/[slug]',
        hasSummary: false,
      },
      {
        slug: 'consolidated-invoices-contractor',
        name: 'YTD Consolidated Invoices Contractors',
        permission: Resources.employer.contractor_reports,
        description: 'A list of all Contractor related invoices for the current year.',
        icon: ICONS.BILLING,
        iconTone: 'green',
        path: '/api/v1/reports/billing/[slug]',
        hasSummary: false,
      },
    ],
  },
];
