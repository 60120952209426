import type { SelectOption } from '@remote-com/norma';
import {
  InputText,
  InputSelect,
  InputTextarea,
  InputCurrency,
  InputDatePicker,
  DEFAULT_DATE_FORMAT,
  WarningTooltip,
} from '@remote-com/norma';
import { isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { isNaN, isNil } from 'lodash/fp';
import mergeRefs from 'merge-refs';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import type { MenuProps } from 'react-select';

import { fieldTypesTransformations } from '@/src/components/Form/DynamicForm/helpers';
import type { FormField } from '@/src/components/Form/DynamicForm/types';
import type { EditCellValue, FormFieldWithFieldsetPath } from '@/src/components/Table/types';

import { CellContentWrapper, StyledMenu, DisplayValue, EllipsisText } from './EditCell.styled';
import { EditCellPortal } from './EditCellPortal';

type EditCellProps = {
  field: FormFieldWithFieldsetPath;
  value: EditCellValue;
  onValueChange: (newValue: EditCellValue) => void;
  errorMessage?: string;
};

type CellInputConfig = {
  component: React.ComponentType<any>;
  blurOnEnterKeyPress: boolean;
  isControlledValueInput: boolean;
  getProps: (
    currentValue: EditCellValue,
    handlers: {
      handleChange: (event: ChangeEvent<HTMLInputElement> | { target: { value: any } }) => void;
      handleBlur: () => void;
    },
    extra: {
      restoreCellFocus: () => void;
    }
  ) => Record<string, any>;
  wrapper?: (props: {
    cellRef: React.RefObject<HTMLDivElement>;
    children: React.ReactElement;
  }) => React.ReactElement | null;
};

/**
 * Custom hook to force a component to re-render.
 */
function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
}

/**
 * This object provides methods to transform and format cell values based on their field types.
 * It consolidates the logic for handling different field types, making it easier to maintain and extend.
 *
 * This object uses the same approach as the `fieldTypesTransformations` in the `DynamicForm` helpers.tsx`.
 *
 * When adding new field types, ensure that both methods are provided to handle the value correctly.
 * This avoids forgetting parts of the implementation.
 */
const cellFieldTypesTransformations: Record<
  string,
  {
    /**
     * Transforms the raw value before processing, fixing invalid values where necessary.
     * This function is NOT used for display purposes.
     */
    transformValue: (value: EditCellValue) => EditCellValue;

    /**
     * Formats the value for display in the cell. It should use the appropriate
     * transformation from `fieldTypesTransformations` to ensure consistency.
     */
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => string;
  }
> = {
  date: {
    transformValue: (value: EditCellValue) => {
      // new Date(null) gets parsed as the Unix epoch (1970-01-01)
      // we don't want that as a default
      if (value === null) {
        return '';
      }

      const valueAsDate = new Date(value);

      // If the value is not a valid date, return an empty string
      if (!isValid(valueAsDate)) {
        return '';
      }

      // Make sure the date is in the correct format and still in UTC, datepicker converts to local time
      // Note: why not use formatYearMonthDay? The underlying makeDateFormatFn function
      // is less permissive of different date formats, so it returns its fallback
      // for dates like 'January 5 2024'.
      return formatInTimeZone(valueAsDate, 'UTC', DEFAULT_DATE_FORMAT);
    },
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      return value?.toString() || '';
    },
  },
  money: {
    transformValue: (value: EditCellValue) => {
      // If the value is not a number, return an empty string
      if (isNaN(Number(value))) {
        return '';
      }

      return fieldTypesTransformations.money.transformValueFromAPI()(value);
    },
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      if (isNil(value)) {
        return '';
      }

      if (isNaN(Number(value))) {
        return String(value);
      }

      return fieldTypesTransformations.money.formatDisplay(field)(value);
    },
  },
  select: {
    transformValue: (value: EditCellValue) => value,
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      return fieldTypesTransformations.select.formatDisplay(field)(value);
    },
  },
  radio: {
    transformValue: (value: EditCellValue) => value,
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      return fieldTypesTransformations.radio.formatDisplay(field)(value);
    },
  },
  text: {
    transformValue: (value: EditCellValue) => value,
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      return value?.toString() || '';
    },
  },
  number: {
    transformValue: (value: EditCellValue) => {
      if (isNaN(Number(value))) {
        return '';
      }
      return value;
    },
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      return value?.toString() || '';
    },
  },
  textarea: {
    transformValue: (value: EditCellValue) => value,
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      return value?.toString() || '';
    },
  },
  default: {
    transformValue: (value: EditCellValue) => value,
    formatDisplay: (field: FormFieldWithFieldsetPath, value: EditCellValue) => {
      return value?.toString() || '';
    },
  },
};

// NOTE: This is the list of input types that we support in the bulk uploader.
// It should match the types covered in the switch/case statement in getCellInputConfig
export const supportedTypes: FormField['type'][] = [
  'text',
  'number',
  'select',
  'textarea',
  'radio',
  'money',
  'date',
];

/**
 * ! Adding a new type?
 * ! Don't forget to add it to the `supportedTypes` array above and add tests in `EditCell.test.tsx`.
 */
function getCellInputConfig<TField extends FormField>(field: TField): CellInputConfig {
  switch (field.type) {
    case 'text':
      return {
        component: InputText,
        blurOnEnterKeyPress: true,
        isControlledValueInput: false,
        getProps: (currentValue, handlers) => ({
          defaultValue: currentValue ?? '',
          onChange: handlers.handleChange,
          onBlur: handlers.handleBlur,
        }),
      };
    case 'number':
      return {
        component: InputText,
        blurOnEnterKeyPress: true,
        isControlledValueInput: false,
        getProps: (currentValue, handlers) => ({
          type: 'number',
          defaultValue: currentValue ?? '',
          onChange: handlers.handleChange,
          onBlur: handlers.handleBlur,
        }),
      };
    case 'textarea':
      return {
        component: InputTextarea,
        blurOnEnterKeyPress: false,
        isControlledValueInput: true,
        getProps: (currentValue, handlers) => ({
          // NOTE: This needs to be a controlled component as we need the value for character count.
          value: currentValue ?? '',
          onChange: handlers.handleChange,
          onBlur: handlers.handleBlur,
          // TODO: this works but we need a way to pass in the props automatically based on the json schema
          maxLength: field.maxLength,
          rows: 8,
        }),
        wrapper: ({ children, ...rest }) => <EditCellPortal {...rest}>{children}</EditCellPortal>,
      };

    case 'select':
    case 'radio':
      return {
        component: InputSelect,
        blurOnEnterKeyPress: false,
        isControlledValueInput: false,
        getProps: (currentValue, handlers) => ({
          defaultValue:
            (Array.isArray(field.options) &&
              field.options.find((option) => option.value === currentValue)) ||
            null,
          options: field.options || [],
          onChange: (selectedOption: SelectOption | null) => {
            handlers.handleChange({
              target: { value: selectedOption?.value },
            });
          },
          onBlur: handlers.handleBlur,
          openMenuOnFocus: true,
          blurInputOnSelect: false,
          menuPortalTarget: document.body,
          isClearable: !field.required,
          components: {
            Menu: ({
              children,
              innerProps,
              innerRef,
              selectProps: {
                // @ts-expect-error
                menuRef,
              },
            }: Partial<MenuProps>) => {
              const mergedMenuRef = mergeRefs(innerRef, menuRef);
              return (
                <StyledMenu data-testid="select-menu" {...innerProps} ref={mergedMenuRef}>
                  {children}
                </StyledMenu>
              );
            },
          },
        }),
      };
    case 'money':
      return {
        component: InputCurrency,
        blurOnEnterKeyPress: true,
        isControlledValueInput: true,
        getProps: (currentValue, handlers) => ({
          currency: field.currency,
          value: currentValue ?? '',
          onValueChange: (value: string) => handlers.handleChange({ target: { value } }),
          onBlur: handlers.handleBlur,
        }),
      };
    case 'date':
      return {
        component: InputDatePicker,
        blurOnEnterKeyPress: true,
        isControlledValueInput: false,
        getProps: (currentValue, handlers, extra) => ({
          minDate: field?.minDate,
          maxDate: field?.maxDate,
          hideLabel: true,
          selected: currentValue ?? null,
          onChange: (date: string) => {
            handlers.handleChange({ target: { value: date } });

            // If the value was cleared, it means the user cleared the field manually
            // or via the clear button.
            if (date === '') {
              extra.restoreCellFocus();
              handlers.handleBlur();
            }
          },
          props: {
            // Reset the value change when the Escape key is pressed.
            onKeyDown: (ev: KeyboardEvent<HTMLInputElement>) => {
              if (ev.key === 'Escape') {
                handlers.handleChange({ target: { value: currentValue } });
              }
            },
            // Same issue as the `textarea` and `select` inputs.
            // This needs to be in a portal in order to show the calendar on top of the table.
            portalId: 'root-portal',
            // If the user clicks in the calendar and then presses Escape, the `handleKeyDown` won't be called.
            // So we need to call `restoreCellFocus` manually. Without this, the focus gets lost and the tab order starts from the beginning.
            onCalendarClose: () => {
              extra.restoreCellFocus();
              handlers.handleBlur();
            },
            // When clicking the close icon, reset the value to null.
            // We do this imperatively because the onCalendarClose firing blurs the input and leaves the value uncleared.
            // This event is called before onCalendarClose, but we can't do a stopPropagation to stop it from firing.
            onClickOutside: (ev: MouseEvent) => {
              if (
                ev.target instanceof Element &&
                ev.target.classList.contains('react-datepicker__close-icon')
              ) {
                handlers.handleChange({ target: { value: null } });
              }
            },
          },
        }),
      };
    default:
      return getCellInputConfig({ type: 'text' } as FormField);
  }
}

const defaultInputWrapper = ({ children }: { children: React.ReactElement }) => children;

export function EditCell({
  field: fieldOriginal,
  value,
  onValueChange,
  errorMessage,
}: EditCellProps) {
  const field = useMemo(
    () => ({
      ...fieldOriginal,
      isVisible: fieldOriginal.isVisible || !!value,
    }),
    [fieldOriginal, value]
  );

  const [isEditing, setIsEditing] = useState(false);

  const cellRef = useRef<HTMLDivElement>(null);
  const bringCellButtonFocusRef = useRef<boolean>(false);
  const valueButtonRef = useRef<HTMLButtonElement>(null);
  const [shouldRestoreCellFocus, setShouldRestoreCellFocus] = useState<boolean>(false);
  const preventOnClickTrigger = useRef(false);
  const currentValueRef = useRef<EditCellValue>(null);

  // This is used to make sure that we can restore the previous value if the Escape key is pressed while editing.
  const didPressEscRef = useRef(false);

  const forceUpdate = useForceUpdate();

  const cellInputConfig = useMemo(() => getCellInputConfig(field), [field]);

  // Take the correct transformation function based on the field type
  const transformValueFn =
    cellFieldTypesTransformations[field.type]?.transformValue ||
    cellFieldTypesTransformations.default.transformValue;

  // Take the correct display transformation function based on the field type
  const formatDisplayFn =
    cellFieldTypesTransformations[field.type]?.formatDisplay ||
    cellFieldTypesTransformations.default.formatDisplay;

  /**
   * Set the initial value but make sure it is in the correct format
   * The reason for the `useEffect` is because the `value` can be changed by the find & replace feature.
   * When that happens, we want to make sure that the ref is updated with the new value.
   */
  useEffect(() => {
    if (currentValueRef.current !== value) {
      currentValueRef.current = transformValueFn(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Restore focus to the cell when is is blurred in some way.
  function restoreCellFocus() {
    setShouldRestoreCellFocus(true);
  }

  const isDisabled = Boolean((!field.isVisible && !errorMessage) || field.readOnly);
  const hideValueDisplay = !field.isVisible && !errorMessage;

  /**
   * Only switches to edit mode if the field is visible.
   * This prevents editing even if someone removes the disabled attribute from the button.
   */
  const handleCellClick = useCallback(() => {
    if (preventOnClickTrigger.current) {
      preventOnClickTrigger.current = false;
      return;
    }

    if (!isDisabled) {
      setIsEditing(true);
    }
  }, [isDisabled]);

  /**
   * Handles the blur event.
   * If the Escape key was pressed, reset the flag and restore the previous value.
   * If the value has changed, call the `onValueChange` callback.
   * If the value has not changed, reset the flag and return.
   */
  const handleBlur = useCallback(() => {
    if (didPressEscRef.current) {
      // Reset the flag
      // Do not call onValueChange, just reset isEditing and return
      didPressEscRef.current = false;
      currentValueRef.current = transformValueFn(value);
      setIsEditing(false);
      return;
    }

    setIsEditing(false);

    if (currentValueRef.current !== value) {
      onValueChange(currentValueRef.current ?? '');
    }
  }, [onValueChange, value, transformValueFn]);

  /**
   * Update the current value and force a re-render if the input is controlled. (textarea, money)
   */
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement> | { target: { value: any } }) => {
      currentValueRef.current = event.target.value;

      if (
        currentValueRef.current !== value &&
        (field.type === 'select' || field.type === 'radio')
      ) {
        // Select fields are immediately saved when an option is selected
        onValueChange(currentValueRef.current ?? '');
      }

      if (cellInputConfig.isControlledValueInput) {
        forceUpdate();
      }
    },
    [cellInputConfig.isControlledValueInput, field.type, forceUpdate, onValueChange, value]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        didPressEscRef.current = true;
        restoreCellFocus();
      }

      /**
       * Checking the `blurOnEnterKeyPress` flag to determine if we should blur the input.
       * This is to prevent the enter key from blurring inputs that have a different behavior. Like the `select`, `radio` and textarea inputs.
       */
      if (isEditing && event.key === 'Enter' && cellInputConfig.blurOnEnterKeyPress) {
        if (field.type === 'date') {
          const isClearButtonClick = (event.target as HTMLButtonElement).classList.contains(
            'react-datepicker__close-icon'
          );

          if (!isClearButtonClick) {
            // Prevents the datepicker popover from opening again since the cell button just regained focus.
            // This occurs when closing the calendar via "Cancel"/"Save" buttons.
            event.preventDefault();

            restoreCellFocus();
            handleBlur();
          }
        } else {
          preventOnClickTrigger.current = true;

          restoreCellFocus();
          handleBlur();
        }
      }

      /**
       * If we're editing a date field, we should allow the user to tab around in the calendar.
       * This code handles tabbing within the datepicker input and popover.
       */
      if (isEditing && event.key === 'Tab' && field.type === 'date') {
        const isDateInputFocused = document.activeElement?.tagName === 'INPUT';
        const isClearButtonFocused = document.activeElement?.classList.contains(
          'react-datepicker__close-icon'
        );
        const hasCloseButton = !!document.querySelector('.react-datepicker__close-icon');

        // Bring the focus to the datepicker popover in situations where a tab press
        // would focus on the next focusable element
        if (isClearButtonFocused || (!hasCloseButton && isDateInputFocused)) {
          // First element matches in case there's a date selected
          // Second element matches in case the input is empty
          const elementToFocus = (document.querySelector('.react-datepicker__day--selected') ||
            document.querySelector('.react-datepicker__day--keyboard-selected')) as HTMLDivElement;

          event.preventDefault();
          elementToFocus?.focus();
        }
      }

      // Textarea inputs open a portal, which would make the focus return to the document body upon tabbing.
      if (isEditing && event.key === 'Tab' && field.type === 'textarea') {
        restoreCellFocus();
      }
    },
    [cellInputConfig, isEditing, field.type, handleBlur]
  );

  const CellInputComponent = cellInputConfig.component;

  const cellInputProps = cellInputConfig.getProps(
    currentValueRef.current,
    { handleChange, handleBlur },
    { restoreCellFocus }
  );

  // NOTE: An optional wrapper that wraps the input in a portal.
  const InputWrapper = cellInputConfig.wrapper || defaultInputWrapper;

  const fieldPath = field.path || field.name;

  const displayValue = formatDisplayFn(field, value);

  useEffect(() => {
    if (shouldRestoreCellFocus) {
      cellRef.current?.querySelector('button')?.focus();
      setShouldRestoreCellFocus(false);
    }
  }, [shouldRestoreCellFocus]);

  const ValueComponent = (
    <DisplayValue
      ref={valueButtonRef}
      onClick={handleCellClick}
      disabled={isDisabled}
      // Allows keyboard navigation (tabbing) only when the field is visible.
      // When `isDisabled` is true, it's removed from the tab order and hidden from screen readers.
      // @ts-expect-error not yet part of @types/react
      inert={!isDisabled ? undefined : 'true'}
      // NOTE: `inert` works by itself without `tabIndex` but the tests don't support it so we need to add `tabIndex` as well.
      tabIndex={!isDisabled && !isEditing ? undefined : -1}
      data-testid={`cell-button-${fieldPath}`}
    >
      <EllipsisText>{hideValueDisplay ? '' : displayValue}</EllipsisText>
    </DisplayValue>
  );

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    // Once an element marked to receive button focus comes back to the viewport
    // set the focus in the button element as if it was always in the DOM
    if (inView && bringCellButtonFocusRef.current) {
      valueButtonRef.current?.focus();
      bringCellButtonFocusRef.current = false;
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      tabIndex={inView || isDisabled ? -1 : 0}
      id="cell-placeholder"
      data-testid={`cell-placeholder-${fieldPath}`}
      onFocus={(e) => {
        // When this element gains focus, it means the cell was not in view
        // and it will be brought to view by the browser
        if (e.target.id === 'cell-placeholder') {
          bringCellButtonFocusRef.current = true;
        }
      }}
    >
      {inView || isEditing ? (
        <CellContentWrapper
          ref={cellRef}
          onKeyDown={handleKeyDown}
          data-testid={`cell-${fieldPath}`}
          data-error={!!errorMessage}
          data-disabled={isDisabled}
          $hasError={!!errorMessage}
          $isDisabled={isDisabled}
        >
          {errorMessage ? (
            <WarningTooltip
              content={[{ title: 'Error', description: errorMessage }]}
              animationDelay={200}
            >
              {ValueComponent}
            </WarningTooltip>
          ) : (
            ValueComponent
          )}
          {isEditing && (
            <InputWrapper cellRef={cellRef}>
              <CellInputComponent
                data-testid={`cell-input-${fieldPath}`}
                hideLabel
                label={field.label}
                name={fieldPath}
                autoFocus
                {...cellInputProps}
              />
            </InputWrapper>
          )}
        </CellContentWrapper>
      ) : null}
    </div>
  );
}
