/**
 * NOTE: this file is automatically created by the "permissions:generate" script.
 * Do not change it manually since it will be overwritten by this command.
 *
 * If this file needs to change location, please update its path on
 * scripts/permissions/helpers.js
 */

/**
 * Permissions constant represents all resources and operations supported by tiger permission system.
 * It will make our permission check less error prone since we can programmatically check if a permission exists before using it
 * and warn the developer in case it doesn't.
 */
export const permissions = {
  account_linking: { read: 'read', update: 'update' },
  adjustments: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  administrative_details: { read: 'read', update: 'update' },
  annual_plans: { create: 'create', read: 'read', update: 'update' },
  anonymous_feedback_management: { read: 'read', update: 'update' },
  automated_reserves: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  automatic_invoice_payments: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  background_checks: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  bank_accounts: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  beneficiaries: { navigate: 'navigate', create: 'create', read: 'read' },
  benefit_cost_corrections: { create: 'create' },
  benefit_details: { create: 'create', read: 'read', update: 'update' },
  benefit_events: { read: 'read' },
  benefit_groups: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  benefit_offers: { create: 'create', read: 'read', update: 'update' },
  benefit_plans: { create: 'create', read: 'read', update: 'update' },
  benefit_renewals: { read: 'read', update: 'update' },
  benefit_tiers: { create: 'create', read: 'read', update: 'update' },
  benefits: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  billable_contractor_activities: { navigate: 'navigate', read: 'read' },
  billable_items: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  billing: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  billing_reports: { navigate: 'navigate', read: 'read' },
  calendars: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  candidate_profiles: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  carriers: { create: 'create', read: 'read', update: 'update' },
  cash_application: { read: 'read', update: 'update' },
  collection_settings: { read: 'read', update: 'update' },
  companies: { navigate: 'navigate', read: 'read', update: 'update' },
  company: { read: 'read', update: 'update' },
  company_bank_account: { read: 'read', update: 'update' },
  company_bank_details: { read: 'read' },
  company_departments: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  company_details: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  company_documents: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  company_equity: { navigate: 'navigate', read: 'read' },
  company_location: { read: 'read' },
  company_notes: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  company_payment_settings: { navigate: 'navigate', read: 'read', update: 'update' },
  company_product_tiers: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  company_profiles: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  company_settings: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
  },
  company_sso_config: { read: 'read', delete: 'delete' },
  company_time_settings: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
  },
  company_vat_number: { update: 'update' },
  compensation_benchmarking: { navigate: 'navigate', read: 'read' },
  compensations: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  compliance_hub: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  compliance_profiles: {
    create: 'create',
    navigate: 'navigate',
    read: 'read',
    update: 'update',
  },
  compliance_request_item_template: {
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  compliance_requests: {
    create: 'create',
    read: 'read',
    delete: 'delete',
    update: 'update',
  },
  compliance_reviews: { navigate: 'navigate', read: 'read', update: 'update' },
  compliance_setting: { read: 'read' },
  compliance_settings: { read: 'read', update: 'update' },
  contract_document_sign_by_procuration: { create: 'create' },
  contract_document_signatory: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  contract_document_signing: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  contract_documents: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
  },
  contract_eligibility: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  contract_pension_details: { create: 'create', update: 'update', read: 'read' },
  contract_start_date: { read: 'read', update: 'update' },
  contract_templates: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  contractor_business_information: { read: 'read', update: 'update' },
  contractor_clients: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  contractor_contract_templates: { create: 'create', read: 'read', update: 'update' },
  contractor_invoices: { create: 'create', read: 'read', update: 'update' },
  contractor_invoices_approval: { read: 'read', update: 'update' },
  contractor_notifications: { create: 'create' },
  contractor_rate: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  contractor_reports: { navigate: 'navigate', read: 'read' },
  contractor_tax_forms: { navigate: 'navigate', read: 'read', update: 'update' },
  contractors: { navigate: 'navigate', read: 'read' },
  contracts: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  cost_approval_requests: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
  },
  cost_calculator: { navigate: 'navigate', read: 'read' },
  countries: { navigate: 'navigate', read: 'read', update: 'update' },
  countries_forms_fields: { navigate: 'navigate', read: 'read' },
  country: { read: 'read' },
  country_forms: {
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  country_payroll_statuses: { read: 'read', update: 'update' },
  country_payroll_statuses_billing: { update: 'update' },
  country_product_prices: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  credit_notes: { create: 'create', read: 'read', update: 'update' },
  custom_field_definitions: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  custom_field_values: {
    read: 'read',
    create: 'create',
    update: 'update',
    navigate: 'navigate',
  },
  custom_notifications: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  dashboard: { navigate: 'navigate', read: 'read' },
  data_export_templates: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  data_exports: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  default_benefit_offers: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  departments: { update: 'update', read: 'read' },
  deposits: { read: 'read', update: 'update' },
  device_management: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  discounts: {
    read: 'read',
    update: 'update',
    delete: 'delete',
    create: 'create',
  },
  document_comments: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  document_requests: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  documents: { read: 'read' },
  documents_management: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  eligible_managers: { read: 'read' },
  employee_address: { read: 'read', update: 'update' },
  employee_administrative_details: { read: 'read', update: 'update' },
  employee_bank_accounts: { read: 'read', update: 'update' },
  employee_data_elements: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  employee_deposit: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  employee_emergency_contact: { read: 'read', update: 'update' },
  employee_files: { create: 'create', read: 'read' },
  employee_mobility_files: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  employee_pay_elements: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  employee_personal_details: { read: 'read', update: 'update' },
  employee_relationships: { update: 'update' },
  employee_sensitive_personal_details: { read: 'read', update: 'update' },
  employee_tax_tasks: { read: 'read', update: 'update' },
  employees: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  employer_managed_document_templates: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  employment: { navigate: 'navigate', read: 'read' },
  employment_agreement_templates: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  employment_agreements: { read: 'read' },
  employment_fees: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  employment_requests: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  eor_payout: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  expense_management: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  expenses: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  expiring_documents: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  extra_entitlements: {
    create: 'create',
    read: 'read',
    delete: 'delete',
    update: 'update',
  },
  files: { delete: 'delete', read: 'read', update: 'update' },
  files_benefits: { read: 'read' },
  files_company: { read: 'read' },
  files_contractor_invoices: { read: 'read' },
  files_contracts: { read: 'read' },
  files_document_scan: { read: 'read' },
  files_employment_request: { read: 'read' },
  files_expense: { read: 'read' },
  files_external_contract: { read: 'read' },
  files_mobility: { read: 'read' },
  files_offboarding: { read: 'read' },
  files_other: { read: 'read' },
  files_payroll_run: { read: 'read' },
  files_payslip: { read: 'read' },
  files_personal: { read: 'read' },
  files_remote_invoice: { read: 'read' },
  files_tax_document: { read: 'read' },
  files_timeoff: { read: 'read' },
  freelancers: { read: 'read' },
  garnishments: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  global_payroll: { read: 'read' },
  handbooks: { navigate: 'navigate', read: 'read' },
  health_checks: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  hiring: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  holidays: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  hr_reports: { navigate: 'navigate', read: 'read' },
  implementation_fees: { create: 'create', read: 'read', update: 'update' },
  in_app_notifications: { read: 'read', update: 'update' },
  incentives: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  incentives_management: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  insights: { read: 'read' },
  insights_hiring: { read: 'read' },
  insights_people: { read: 'read' },
  integrations: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  inter_company_invoice_entries: { read: 'read' },
  inter_company_invoice_entry_groups: { read: 'read' },
  inter_company_invoices: { navigate: 'navigate', read: 'read', update: 'update' },
  inter_company_remoter_markups: { navigate: 'navigate', read: 'read', create: 'create' },
  inter_company_tax_notes: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  inter_company_transactions: { navigate: 'navigate', create: 'create', read: 'read' },
  invoice_reports: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  invoice_reports_adjustments: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  invoice_reports_data: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  invoices: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  job_applications: { read: 'read' },
  job_pay: { read: 'read', update: 'update' },
  jobs: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  kdb_fact_schemas: {
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  kdb_facts: {
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  knowledge_database: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  leave_policies: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  legal_entities: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
  },
  legal_entity_administrative_details: { create: 'create', read: 'read', update: 'update' },
  legal_entity_bank_account: { create: 'create', read: 'read', update: 'update' },
  legal_entity_documents: {
    read: 'read',
    update: 'update',
    create: 'create',
    delete: 'delete',
  },
  legal_entity_notes: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  legal_entity_onboardings: { create: 'create', update: 'update' },
  legal_entity_pay_element_template_collections: {
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  legal_entity_pay_element_templates: {
    create: 'create',
    read: 'read',
    delete: 'delete',
    update: 'update',
  },
  legal_entity_pay_elements: {
    create: 'create',
    read: 'read',
    delete: 'delete',
    update: 'update',
  },
  legal_entity_payroll_config: { read: 'read', update: 'update' },
  legal_entity_pension_details: { create: 'create', update: 'update', read: 'read' },
  legal_entity_signatory_details: { read: 'read' },
  legal_entity_state_tax_requirements: { read: 'read' },
  legal_representative: { read: 'read' },
  marketplace: { read: 'read' },
  mobility_assistance_status: { read: 'read' },
  mobility_request: { read: 'read', update: 'update' },
  notifications_approve_contractor_invoices: { read: 'read', update: 'update' },
  notifications_company_management: { read: 'read', update: 'update' },
  notifications_contact_update_details: { read: 'read', update: 'update' },
  notifications_employment_contract_lifecycle: { read: 'read', update: 'update' },
  notifications_expense_management: { read: 'read', update: 'update' },
  notifications_incentives_management: { read: 'read', update: 'update' },
  notifications_onboarding_new_hires: { read: 'read', update: 'update' },
  notifications_pay_contractors: { read: 'read', update: 'update' },
  notifications_payroll_and_payments: { read: 'read', update: 'update' },
  notifications_probation_reminders: { read: 'read', update: 'update' },
  notifications_remote_work_request: { read: 'read', update: 'update' },
  notifications_requests_review: { read: 'read', update: 'update' },
  notifications_team: { read: 'read', update: 'update' },
  notifications_time_off_management: { read: 'read', update: 'update' },
  notifications_time_tracking: { read: 'read', update: 'update' },
  notifications_travel_letter_request: { read: 'read', update: 'update' },
  off_payslip_costs: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
  },
  offboarding: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  offboarding_completions: { create: 'create' },
  offboarding_payroll: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  onboarding: { navigate: 'navigate' },
  onboarding_case: { read: 'read', update: 'update' },
  onboarding_notes: { create: 'create', delete: 'delete', read: 'read' },
  onboarding_reserves_policies: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  onboarding_specialists_management: {
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  outstanding_payments_refunds: { read: 'read' },
  outstanding_refunds: { read: 'read', update: 'update' },
  paid_time_off: { read: 'read' },
  pay_schedules: { create: 'create', read: 'read', update: 'update' },
  payment_events: { navigate: 'navigate', read: 'read' },
  payment_methods: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
    navigate: 'navigate',
  },
  payment_requests: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  payments: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  payroll: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
  },
  payroll_billing_items: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  payroll_calendars: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  payroll_outputs: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  payroll_outputs_billing: { update: 'update' },
  payroll_payments: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  payroll_reports: { navigate: 'navigate', read: 'read' },
  payroll_run_comments: { read: 'read', create: 'create' },
  payroll_run_force_actions: { create: 'create', update: 'update' },
  payroll_run_report: { read: 'read' },
  payroll_runs: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  payroll_timeoff: { read: 'read', update: 'update' },
  payslip_parsing_rules: { read: 'read', update: 'update' },
  payslips: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  people: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
    navigate: 'navigate',
  },
  people_contracts: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  people_documents: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  people_employment: { read: 'read' },
  people_equity: { navigate: 'navigate', read: 'read' },
  people_list: { read: 'read' },
  people_onboarded: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  people_sensitive_details: { read: 'read', create: 'create', update: 'update' },
  performance_manage_teams: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  performance_review_cycles: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  performance_review_submission_content: { navigate: 'navigate', read: 'read' },
  performance_review_submissions: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  performance_reviews: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  persisted_config_settings: { navigate: 'navigate', read: 'read', update: 'update' },
  personal_information: { read: 'read', update: 'update' },
  prices: { read: 'read' },
  pricing: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  pricing_plans: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
  },
  product_prices: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  products: { create: 'create', read: 'read', update: 'update' },
  project_lead: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  project_management: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  recurring_contractor_invoices: { read: 'read' },
  recurring_incentives: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  recurring_invoices: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  refunds: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  remote_entities: { read: 'read' },
  remote_invoices: {
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  remote_paid_services: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  reporting_library: { navigate: 'navigate', read: 'read' },
  right_to_work_status_approval: { create: 'create', read: 'read', update: 'update' },
  right_to_work_status_approval_ops_usa_pr: { create: 'create', read: 'read', update: 'update' },
  roles: {
    navigate: 'navigate',
    create: 'create',
    read: 'read',
    update: 'update',
    delete: 'delete',
  },
  salary: { navigate: 'navigate', read: 'read' },
  scheduled_contractor_invoices: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    delete: 'delete',
    update: 'update',
  },
  scheduled_invoices: { read: 'read', update: 'update' },
  send_email_request: { create: 'create' },
  severance_reserve: { navigate: 'navigate', read: 'read' },
  severance_reserve_policies: { navigate: 'navigate', read: 'read', update: 'update' },
  severance_reserves: { create: 'create', update: 'update' },
  support_monitor_full_access: { read: 'read' },
  talent_insights: { navigate: 'navigate', read: 'read', create: 'create' },
  talent_payments: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  tce_calculator: { navigate: 'navigate', read: 'read', create: 'create' },
  team_members: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  team_plans: {
    create: 'create',
    read: 'read',
    update: 'update',
    navigate: 'navigate',
  },
  team_plans_override: { update: 'update' },
  time_off: {
    navigate: 'navigate',
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  time_off_management: { read: 'read', update: 'update', navigate: 'navigate' },
  time_tracking: { navigate: 'navigate', read: 'read', update: 'update' },
  timeoff_balances_report: { read: 'read' },
  timeoff_report: { read: 'read' },
  transaction_receipts: { read: 'read' },
  user_events: { read: 'read' },
  user_notes: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  user_password: { update: 'update' },
  users: { navigate: 'navigate', read: 'read', update: 'update' },
  users_role: { read: 'read', update: 'update' },
  vat_groups: {
    navigate: 'navigate',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete',
  },
  workflow_features: { read: 'read' },
  workflow_runs: { read: 'read' },
  workflows: {
    create: 'create',
    delete: 'delete',
    read: 'read',
    update: 'update',
  },
  employer: {
    account_linking: { read: 'read', update: 'update' },
    annual_plans: { read: 'read', update: 'update' },
    anonymous_feedback_management: { read: 'read', update: 'update' },
    automatic_invoice_payments: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    background_checks: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    beneficiaries: { navigate: 'navigate', create: 'create', read: 'read' },
    benefit_renewals: { read: 'read', update: 'update' },
    benefits: { navigate: 'navigate', read: 'read' },
    billable_contractor_activities: { navigate: 'navigate', read: 'read' },
    billing: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    billing_reports: { navigate: 'navigate', read: 'read' },
    calendars: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    candidate_profiles: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    company: { read: 'read', update: 'update' },
    company_bank_account: { read: 'read', update: 'update' },
    company_bank_details: { read: 'read' },
    company_departments: {
      navigate: 'navigate',
      create: 'create',
      read: 'read',
      update: 'update',
      delete: 'delete',
    },
    company_details: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    company_documents: { read: 'read', create: 'create' },
    company_equity: { navigate: 'navigate', read: 'read' },
    company_location: { read: 'read' },
    company_payment_settings: { navigate: 'navigate', read: 'read', update: 'update' },
    company_product_tiers: { read: 'read', update: 'update' },
    company_profiles: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    company_settings: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
    },
    company_time_settings: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
    },
    compensation_benchmarking: { navigate: 'navigate', read: 'read' },
    compliance_hub: { navigate: 'navigate', read: 'read' },
    compliance_profiles: { read: 'read' },
    compliance_setting: { read: 'read' },
    contract_document_sign_by_procuration: { create: 'create' },
    contract_document_signatory: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    contract_document_signing: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    contract_documents: { read: 'read' },
    contract_pension_details: { read: 'read', create: 'create', update: 'update' },
    contractor_contract_templates: { create: 'create', read: 'read', update: 'update' },
    contractor_invoices: { create: 'create', read: 'read', update: 'update' },
    contractor_invoices_approval: { read: 'read', update: 'update' },
    contractor_rate: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    contractor_reports: { navigate: 'navigate', read: 'read' },
    contractor_tax_forms: { navigate: 'navigate', read: 'read', update: 'update' },
    cost_approval_requests: { navigate: 'navigate', read: 'read', update: 'update' },
    cost_calculator: { navigate: 'navigate', read: 'read' },
    country: { read: 'read' },
    custom_field_definitions: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    custom_field_values: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
    },
    dashboard: { navigate: 'navigate', read: 'read' },
    device_management: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    documents_management: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    employee_address: { read: 'read', update: 'update' },
    employee_administrative_details: { read: 'read', update: 'update' },
    employee_bank_accounts: { read: 'read', update: 'update' },
    employee_pay_elements: {
      create: 'create',
      read: 'read',
      update: 'update',
      delete: 'delete',
    },
    employee_personal_details: { read: 'read', update: 'update' },
    employee_sensitive_personal_details: { read: 'read', update: 'update' },
    employee_tax_tasks: { read: 'read', update: 'update' },
    employer_managed_document_templates: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    employment_agreements: { read: 'read' },
    employment_requests: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    expense_management: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    extra_entitlements: {
      create: 'create',
      read: 'read',
      delete: 'delete',
      update: 'update',
    },
    files: { read: 'read', update: 'update' },
    files_benefits: { read: 'read' },
    files_company: { read: 'read' },
    files_contractor_invoices: { read: 'read' },
    files_contracts: { read: 'read' },
    files_document_scan: { read: 'read' },
    files_employment_request: { read: 'read' },
    files_expense: { read: 'read' },
    files_external_contract: { read: 'read' },
    files_mobility: { read: 'read' },
    files_offboarding: { read: 'read' },
    files_other: { read: 'read' },
    files_payroll_run: { read: 'read' },
    files_payslip: { read: 'read' },
    files_personal: { read: 'read' },
    files_remote_invoice: { read: 'read' },
    files_tax_document: { read: 'read' },
    files_timeoff: { read: 'read' },
    garnishments: {
      navigate: 'navigate',
      create: 'create',
      read: 'read',
      update: 'update',
      delete: 'delete',
    },
    global_payroll: { read: 'read' },
    hiring: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    hr_reports: { navigate: 'navigate', read: 'read' },
    implementation_fees: { read: 'read', update: 'update' },
    incentives_management: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    insights: { read: 'read' },
    insights_hiring: { read: 'read' },
    insights_people: { read: 'read' },
    integrations: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    job_applications: { read: 'read' },
    jobs: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    kdb_facts: { read: 'read' },
    leave_policies: {
      navigate: 'navigate',
      create: 'create',
      read: 'read',
      delete: 'delete',
      update: 'update',
    },
    legal_entities: {
      navigate: 'navigate',
      create: 'create',
      read: 'read',
      update: 'update',
    },
    legal_entity_pay_element_template_collections: {
      read: 'read',
      create: 'create',
      delete: 'delete',
      update: 'update',
    },
    legal_entity_pay_element_templates: {
      create: 'create',
      read: 'read',
      delete: 'delete',
      update: 'update',
    },
    legal_entity_pay_elements: { read: 'read' },
    legal_entity_payroll_config: { read: 'read' },
    legal_representative: { read: 'read' },
    marketplace: { read: 'read' },
    mobility_assistance_status: { read: 'read' },
    mobility_request: { read: 'read', update: 'update' },
    notifications_approve_contractor_invoices: { read: 'read', update: 'update' },
    notifications_company_management: { read: 'read', update: 'update' },
    notifications_contact_update_details: { read: 'read', update: 'update' },
    notifications_employment_contract_lifecycle: { read: 'read', update: 'update' },
    notifications_expense_management: { read: 'read', update: 'update' },
    notifications_incentives_management: { read: 'read', update: 'update' },
    notifications_onboarding_new_hires: { read: 'read', update: 'update' },
    notifications_pay_contractors: { read: 'read', update: 'update' },
    notifications_payroll_and_payments: { read: 'read', update: 'update' },
    notifications_probation_reminders: { read: 'read', update: 'update' },
    notifications_remote_work_request: { read: 'read', update: 'update' },
    notifications_requests_review: { read: 'read', update: 'update' },
    notifications_team: { read: 'read', update: 'update' },
    notifications_time_off_management: { read: 'read', update: 'update' },
    notifications_time_tracking: { read: 'read', update: 'update' },
    notifications_travel_letter_request: { read: 'read', update: 'update' },
    offboarding: {
      create: 'create',
      read: 'read',
      update: 'update',
      delete: 'delete',
    },
    outstanding_payments_refunds: { read: 'read' },
    paid_time_off: { read: 'read' },
    payment_methods: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    payment_requests: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    payroll: {
      navigate: 'navigate',
      create: 'create',
      read: 'read',
      update: 'update',
    },
    payroll_outputs: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    payroll_reports: { navigate: 'navigate', read: 'read' },
    people: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    people_contracts: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    people_documents: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    people_employment: { read: 'read' },
    people_equity: { navigate: 'navigate', read: 'read' },
    people_list: { read: 'read' },
    people_onboarded: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    people_sensitive_details: { read: 'read', create: 'create', update: 'update' },
    performance_manage_teams: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    performance_review_cycles: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      delete: 'delete',
      update: 'update',
    },
    performance_review_submission_content: { navigate: 'navigate', read: 'read' },
    performance_review_submissions: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      delete: 'delete',
      update: 'update',
    },
    prices: { read: 'read' },
    pricing_plans: {
      create: 'create',
      navigate: 'navigate',
      read: 'read',
      update: 'update',
    },
    project_lead: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    project_management: {
      navigate: 'navigate',
      read: 'read',
      create: 'create',
      update: 'update',
      delete: 'delete',
    },
    reporting_library: { navigate: 'navigate', read: 'read' },
    roles: {
      navigate: 'navigate',
      create: 'create',
      read: 'read',
      update: 'update',
      delete: 'delete',
    },
    scheduled_contractor_invoices: {
      navigate: 'navigate',
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    severance_reserve: { navigate: 'navigate', read: 'read' },
    support_monitor_full_access: { read: 'read' },
    talent_insights: { navigate: 'navigate', read: 'read', create: 'create' },
    tce_calculator: { navigate: 'navigate', read: 'read', create: 'create' },
    team_members: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
    team_plans: {
      create: 'create',
      read: 'read',
      update: 'update',
      navigate: 'navigate',
    },
    time_off_management: { navigate: 'navigate', read: 'read', update: 'update' },
    time_tracking: { navigate: 'navigate', read: 'read', update: 'update' },
    workflow_features: { read: 'read' },
    workflow_runs: { read: 'read' },
    workflows: {
      create: 'create',
      delete: 'delete',
      read: 'read',
      update: 'update',
    },
  },
} as const;

export type Permissions = typeof permissions;

/**
 * Resources object is useful for looking up supported resources from tiger permission system.
 *
 * eg: userCan('read', Resources.documents);
 */
export const Resources = {
  account_linking: 'account_linking',
  adjustments: 'adjustments',
  administrative_details: 'administrative_details',
  annual_plans: 'annual_plans',
  anonymous_feedback_management: 'anonymous_feedback_management',
  automated_reserves: 'automated_reserves',
  automatic_invoice_payments: 'automatic_invoice_payments',
  background_checks: 'background_checks',
  bank_accounts: 'bank_accounts',
  beneficiaries: 'beneficiaries',
  benefit_cost_corrections: 'benefit_cost_corrections',
  benefit_details: 'benefit_details',
  benefit_events: 'benefit_events',
  benefit_groups: 'benefit_groups',
  benefit_offers: 'benefit_offers',
  benefit_plans: 'benefit_plans',
  benefit_renewals: 'benefit_renewals',
  benefit_tiers: 'benefit_tiers',
  benefits: 'benefits',
  billable_contractor_activities: 'billable_contractor_activities',
  billable_items: 'billable_items',
  billing: 'billing',
  billing_reports: 'billing_reports',
  calendars: 'calendars',
  candidate_profiles: 'candidate_profiles',
  carriers: 'carriers',
  cash_application: 'cash_application',
  collection_settings: 'collection_settings',
  companies: 'companies',
  company: 'company',
  company_bank_account: 'company_bank_account',
  company_bank_details: 'company_bank_details',
  company_departments: 'company_departments',
  company_details: 'company_details',
  company_documents: 'company_documents',
  company_equity: 'company_equity',
  company_location: 'company_location',
  company_notes: 'company_notes',
  company_payment_settings: 'company_payment_settings',
  company_product_tiers: 'company_product_tiers',
  company_profiles: 'company_profiles',
  company_settings: 'company_settings',
  company_sso_config: 'company_sso_config',
  company_time_settings: 'company_time_settings',
  company_vat_number: 'company_vat_number',
  compensation_benchmarking: 'compensation_benchmarking',
  compensations: 'compensations',
  compliance_hub: 'compliance_hub',
  compliance_profiles: 'compliance_profiles',
  compliance_request_item_template: 'compliance_request_item_template',
  compliance_requests: 'compliance_requests',
  compliance_reviews: 'compliance_reviews',
  compliance_setting: 'compliance_setting',
  compliance_settings: 'compliance_settings',
  contract_document_sign_by_procuration: 'contract_document_sign_by_procuration',
  contract_document_signatory: 'contract_document_signatory',
  contract_document_signing: 'contract_document_signing',
  contract_documents: 'contract_documents',
  contract_eligibility: 'contract_eligibility',
  contract_pension_details: 'contract_pension_details',
  contract_start_date: 'contract_start_date',
  contract_templates: 'contract_templates',
  contractor_business_information: 'contractor_business_information',
  contractor_clients: 'contractor_clients',
  contractor_contract_templates: 'contractor_contract_templates',
  contractor_invoices: 'contractor_invoices',
  contractor_invoices_approval: 'contractor_invoices_approval',
  contractor_notifications: 'contractor_notifications',
  contractor_rate: 'contractor_rate',
  contractor_reports: 'contractor_reports',
  contractor_tax_forms: 'contractor_tax_forms',
  contractors: 'contractors',
  contracts: 'contracts',
  cost_approval_requests: 'cost_approval_requests',
  cost_calculator: 'cost_calculator',
  countries: 'countries',
  countries_forms_fields: 'countries_forms_fields',
  country: 'country',
  country_forms: 'country_forms',
  country_payroll_statuses: 'country_payroll_statuses',
  country_payroll_statuses_billing: 'country_payroll_statuses_billing',
  country_product_prices: 'country_product_prices',
  credit_notes: 'credit_notes',
  custom_field_definitions: 'custom_field_definitions',
  custom_field_values: 'custom_field_values',
  custom_notifications: 'custom_notifications',
  dashboard: 'dashboard',
  data_export_templates: 'data_export_templates',
  data_exports: 'data_exports',
  default_benefit_offers: 'default_benefit_offers',
  departments: 'departments',
  deposits: 'deposits',
  device_management: 'device_management',
  discounts: 'discounts',
  document_comments: 'document_comments',
  document_requests: 'document_requests',
  documents: 'documents',
  documents_management: 'documents_management',
  eligible_managers: 'eligible_managers',
  employee_address: 'employee_address',
  employee_administrative_details: 'employee_administrative_details',
  employee_bank_accounts: 'employee_bank_accounts',
  employee_data_elements: 'employee_data_elements',
  employee_deposit: 'employee_deposit',
  employee_emergency_contact: 'employee_emergency_contact',
  employee_files: 'employee_files',
  employee_mobility_files: 'employee_mobility_files',
  employee_pay_elements: 'employee_pay_elements',
  employee_personal_details: 'employee_personal_details',
  employee_relationships: 'employee_relationships',
  employee_sensitive_personal_details: 'employee_sensitive_personal_details',
  employee_tax_tasks: 'employee_tax_tasks',
  employees: 'employees',
  employer_managed_document_templates: 'employer_managed_document_templates',
  employment: 'employment',
  employment_agreement_templates: 'employment_agreement_templates',
  employment_agreements: 'employment_agreements',
  employment_fees: 'employment_fees',
  employment_requests: 'employment_requests',
  eor_payout: 'eor_payout',
  expense_management: 'expense_management',
  expenses: 'expenses',
  expiring_documents: 'expiring_documents',
  extra_entitlements: 'extra_entitlements',
  files: 'files',
  files_benefits: 'files_benefits',
  files_company: 'files_company',
  files_contractor_invoices: 'files_contractor_invoices',
  files_contracts: 'files_contracts',
  files_document_scan: 'files_document_scan',
  files_employment_request: 'files_employment_request',
  files_expense: 'files_expense',
  files_external_contract: 'files_external_contract',
  files_mobility: 'files_mobility',
  files_offboarding: 'files_offboarding',
  files_other: 'files_other',
  files_payroll_run: 'files_payroll_run',
  files_payslip: 'files_payslip',
  files_personal: 'files_personal',
  files_remote_invoice: 'files_remote_invoice',
  files_tax_document: 'files_tax_document',
  files_timeoff: 'files_timeoff',
  freelancers: 'freelancers',
  garnishments: 'garnishments',
  global_payroll: 'global_payroll',
  handbooks: 'handbooks',
  health_checks: 'health_checks',
  hiring: 'hiring',
  holidays: 'holidays',
  hr_reports: 'hr_reports',
  implementation_fees: 'implementation_fees',
  in_app_notifications: 'in_app_notifications',
  incentives: 'incentives',
  incentives_management: 'incentives_management',
  insights: 'insights',
  insights_hiring: 'insights_hiring',
  insights_people: 'insights_people',
  integrations: 'integrations',
  inter_company_invoice_entries: 'inter_company_invoice_entries',
  inter_company_invoice_entry_groups: 'inter_company_invoice_entry_groups',
  inter_company_invoices: 'inter_company_invoices',
  inter_company_remoter_markups: 'inter_company_remoter_markups',
  inter_company_tax_notes: 'inter_company_tax_notes',
  inter_company_transactions: 'inter_company_transactions',
  invoice_reports: 'invoice_reports',
  invoice_reports_adjustments: 'invoice_reports_adjustments',
  invoice_reports_data: 'invoice_reports_data',
  invoices: 'invoices',
  job_applications: 'job_applications',
  job_pay: 'job_pay',
  jobs: 'jobs',
  kdb_fact_schemas: 'kdb_fact_schemas',
  kdb_facts: 'kdb_facts',
  knowledge_database: 'knowledge_database',
  leave_policies: 'leave_policies',
  legal_entities: 'legal_entities',
  legal_entity_administrative_details: 'legal_entity_administrative_details',
  legal_entity_bank_account: 'legal_entity_bank_account',
  legal_entity_documents: 'legal_entity_documents',
  legal_entity_notes: 'legal_entity_notes',
  legal_entity_onboardings: 'legal_entity_onboardings',
  legal_entity_pay_element_template_collections: 'legal_entity_pay_element_template_collections',
  legal_entity_pay_element_templates: 'legal_entity_pay_element_templates',
  legal_entity_pay_elements: 'legal_entity_pay_elements',
  legal_entity_payroll_config: 'legal_entity_payroll_config',
  legal_entity_pension_details: 'legal_entity_pension_details',
  legal_entity_signatory_details: 'legal_entity_signatory_details',
  legal_entity_state_tax_requirements: 'legal_entity_state_tax_requirements',
  legal_representative: 'legal_representative',
  marketplace: 'marketplace',
  mobility_assistance_status: 'mobility_assistance_status',
  mobility_request: 'mobility_request',
  notifications_approve_contractor_invoices: 'notifications_approve_contractor_invoices',
  notifications_company_management: 'notifications_company_management',
  notifications_contact_update_details: 'notifications_contact_update_details',
  notifications_employment_contract_lifecycle: 'notifications_employment_contract_lifecycle',
  notifications_expense_management: 'notifications_expense_management',
  notifications_incentives_management: 'notifications_incentives_management',
  notifications_onboarding_new_hires: 'notifications_onboarding_new_hires',
  notifications_pay_contractors: 'notifications_pay_contractors',
  notifications_payroll_and_payments: 'notifications_payroll_and_payments',
  notifications_probation_reminders: 'notifications_probation_reminders',
  notifications_remote_work_request: 'notifications_remote_work_request',
  notifications_requests_review: 'notifications_requests_review',
  notifications_team: 'notifications_team',
  notifications_time_off_management: 'notifications_time_off_management',
  notifications_time_tracking: 'notifications_time_tracking',
  notifications_travel_letter_request: 'notifications_travel_letter_request',
  off_payslip_costs: 'off_payslip_costs',
  offboarding: 'offboarding',
  offboarding_completions: 'offboarding_completions',
  offboarding_payroll: 'offboarding_payroll',
  onboarding: 'onboarding',
  onboarding_case: 'onboarding_case',
  onboarding_notes: 'onboarding_notes',
  onboarding_reserves_policies: 'onboarding_reserves_policies',
  onboarding_specialists_management: 'onboarding_specialists_management',
  outstanding_payments_refunds: 'outstanding_payments_refunds',
  outstanding_refunds: 'outstanding_refunds',
  paid_time_off: 'paid_time_off',
  pay_schedules: 'pay_schedules',
  payment_events: 'payment_events',
  payment_methods: 'payment_methods',
  payment_requests: 'payment_requests',
  payments: 'payments',
  payroll: 'payroll',
  payroll_billing_items: 'payroll_billing_items',
  payroll_calendars: 'payroll_calendars',
  payroll_outputs: 'payroll_outputs',
  payroll_outputs_billing: 'payroll_outputs_billing',
  payroll_payments: 'payroll_payments',
  payroll_reports: 'payroll_reports',
  payroll_run_comments: 'payroll_run_comments',
  payroll_run_force_actions: 'payroll_run_force_actions',
  payroll_run_report: 'payroll_run_report',
  payroll_runs: 'payroll_runs',
  payroll_timeoff: 'payroll_timeoff',
  payslip_parsing_rules: 'payslip_parsing_rules',
  payslips: 'payslips',
  people: 'people',
  people_contracts: 'people_contracts',
  people_documents: 'people_documents',
  people_employment: 'people_employment',
  people_equity: 'people_equity',
  people_list: 'people_list',
  people_onboarded: 'people_onboarded',
  people_sensitive_details: 'people_sensitive_details',
  performance_manage_teams: 'performance_manage_teams',
  performance_review_cycles: 'performance_review_cycles',
  performance_review_submission_content: 'performance_review_submission_content',
  performance_review_submissions: 'performance_review_submissions',
  performance_reviews: 'performance_reviews',
  persisted_config_settings: 'persisted_config_settings',
  personal_information: 'personal_information',
  prices: 'prices',
  pricing: 'pricing',
  pricing_plans: 'pricing_plans',
  product_prices: 'product_prices',
  products: 'products',
  project_lead: 'project_lead',
  project_management: 'project_management',
  recurring_contractor_invoices: 'recurring_contractor_invoices',
  recurring_incentives: 'recurring_incentives',
  recurring_invoices: 'recurring_invoices',
  refunds: 'refunds',
  remote_entities: 'remote_entities',
  remote_invoices: 'remote_invoices',
  remote_paid_services: 'remote_paid_services',
  reporting_library: 'reporting_library',
  right_to_work_status_approval: 'right_to_work_status_approval',
  right_to_work_status_approval_ops_usa_pr: 'right_to_work_status_approval_ops_usa_pr',
  roles: 'roles',
  salary: 'salary',
  scheduled_contractor_invoices: 'scheduled_contractor_invoices',
  scheduled_invoices: 'scheduled_invoices',
  send_email_request: 'send_email_request',
  severance_reserve: 'severance_reserve',
  severance_reserve_policies: 'severance_reserve_policies',
  severance_reserves: 'severance_reserves',
  support_monitor_full_access: 'support_monitor_full_access',
  talent_insights: 'talent_insights',
  talent_payments: 'talent_payments',
  tce_calculator: 'tce_calculator',
  team_members: 'team_members',
  team_plans: 'team_plans',
  team_plans_override: 'team_plans_override',
  time_off: 'time_off',
  time_off_management: 'time_off_management',
  time_tracking: 'time_tracking',
  timeoff_balances_report: 'timeoff_balances_report',
  timeoff_report: 'timeoff_report',
  transaction_receipts: 'transaction_receipts',
  user_events: 'user_events',
  user_notes: 'user_notes',
  user_password: 'user_password',
  users: 'users',
  users_role: 'users_role',
  vat_groups: 'vat_groups',
  workflow_features: 'workflow_features',
  workflow_runs: 'workflow_runs',
  workflows: 'workflows',
  employer: {
    files_employment_request: 'files_employment_request',
    job_applications: 'job_applications',
    company_settings: 'company_settings',
    notifications_expense_management: 'notifications_expense_management',
    calendars: 'calendars',
    company_details: 'company_details',
    notifications_time_tracking: 'notifications_time_tracking',
    notifications_time_off_management: 'notifications_time_off_management',
    files_timeoff: 'files_timeoff',
    contractor_invoices: 'contractor_invoices',
    files_mobility: 'files_mobility',
    files_company: 'files_company',
    contract_document_sign_by_procuration: 'contract_document_sign_by_procuration',
    workflows: 'workflows',
    leave_policies: 'leave_policies',
    company_bank_account: 'company_bank_account',
    support_monitor_full_access: 'support_monitor_full_access',
    notifications_team: 'notifications_team',
    people_employment: 'people_employment',
    employer_managed_document_templates: 'employer_managed_document_templates',
    custom_field_definitions: 'custom_field_definitions',
    payment_requests: 'payment_requests',
    marketplace: 'marketplace',
    files_contracts: 'files_contracts',
    garnishments: 'garnishments',
    company_payment_settings: 'company_payment_settings',
    performance_review_submissions: 'performance_review_submissions',
    employee_address: 'employee_address',
    severance_reserve: 'severance_reserve',
    company_time_settings: 'company_time_settings',
    paid_time_off: 'paid_time_off',
    insights_hiring: 'insights_hiring',
    employment_requests: 'employment_requests',
    notifications_employment_contract_lifecycle: 'notifications_employment_contract_lifecycle',
    offboarding: 'offboarding',
    company_profiles: 'company_profiles',
    compliance_setting: 'compliance_setting',
    time_off_management: 'time_off_management',
    contract_pension_details: 'contract_pension_details',
    legal_entity_pay_element_templates: 'legal_entity_pay_element_templates',
    employment_agreements: 'employment_agreements',
    workflow_features: 'workflow_features',
    performance_manage_teams: 'performance_manage_teams',
    reporting_library: 'reporting_library',
    files_benefits: 'files_benefits',
    team_members: 'team_members',
    people_contracts: 'people_contracts',
    notifications_company_management: 'notifications_company_management',
    workflow_runs: 'workflow_runs',
    employee_administrative_details: 'employee_administrative_details',
    files_offboarding: 'files_offboarding',
    contractor_tax_forms: 'contractor_tax_forms',
    employee_tax_tasks: 'employee_tax_tasks',
    contractor_rate: 'contractor_rate',
    people_onboarded: 'people_onboarded',
    dashboard: 'dashboard',
    payroll_reports: 'payroll_reports',
    files_other: 'files_other',
    employee_personal_details: 'employee_personal_details',
    annual_plans: 'annual_plans',
    payroll: 'payroll',
    notifications_remote_work_request: 'notifications_remote_work_request',
    company_bank_details: 'company_bank_details',
    team_plans: 'team_plans',
    files_payroll_run: 'files_payroll_run',
    legal_entity_pay_element_template_collections: 'legal_entity_pay_element_template_collections',
    roles: 'roles',
    insights: 'insights',
    custom_field_values: 'custom_field_values',
    employee_sensitive_personal_details: 'employee_sensitive_personal_details',
    mobility_assistance_status: 'mobility_assistance_status',
    kdb_facts: 'kdb_facts',
    legal_entities: 'legal_entities',
    tce_calculator: 'tce_calculator',
    compensation_benchmarking: 'compensation_benchmarking',
    beneficiaries: 'beneficiaries',
    project_lead: 'project_lead',
    payroll_outputs: 'payroll_outputs',
    cost_calculator: 'cost_calculator',
    prices: 'prices',
    background_checks: 'background_checks',
    legal_entity_pay_elements: 'legal_entity_pay_elements',
    global_payroll: 'global_payroll',
    pricing_plans: 'pricing_plans',
    files_contractor_invoices: 'files_contractor_invoices',
    billing_reports: 'billing_reports',
    payment_methods: 'payment_methods',
    company_location: 'company_location',
    files_payslip: 'files_payslip',
    compliance_profiles: 'compliance_profiles',
    time_tracking: 'time_tracking',
    files_external_contract: 'files_external_contract',
    company_product_tiers: 'company_product_tiers',
    integrations: 'integrations',
    performance_review_submission_content: 'performance_review_submission_content',
    insights_people: 'insights_people',
    compliance_hub: 'compliance_hub',
    legal_representative: 'legal_representative',
    contractor_contract_templates: 'contractor_contract_templates',
    talent_insights: 'talent_insights',
    scheduled_contractor_invoices: 'scheduled_contractor_invoices',
    extra_entitlements: 'extra_entitlements',
    company_departments: 'company_departments',
    country: 'country',
    implementation_fees: 'implementation_fees',
    notifications_onboarding_new_hires: 'notifications_onboarding_new_hires',
    benefit_renewals: 'benefit_renewals',
    people_list: 'people_list',
    contractor_invoices_approval: 'contractor_invoices_approval',
    people_documents: 'people_documents',
    files_document_scan: 'files_document_scan',
    contract_document_signing: 'contract_document_signing',
    candidate_profiles: 'candidate_profiles',
    performance_review_cycles: 'performance_review_cycles',
    employee_bank_accounts: 'employee_bank_accounts',
    notifications_approve_contractor_invoices: 'notifications_approve_contractor_invoices',
    benefits: 'benefits',
    device_management: 'device_management',
    anonymous_feedback_management: 'anonymous_feedback_management',
    automatic_invoice_payments: 'automatic_invoice_payments',
    jobs: 'jobs',
    incentives_management: 'incentives_management',
    notifications_payroll_and_payments: 'notifications_payroll_and_payments',
    notifications_incentives_management: 'notifications_incentives_management',
    files: 'files',
    company: 'company',
    expense_management: 'expense_management',
    notifications_probation_reminders: 'notifications_probation_reminders',
    people_sensitive_details: 'people_sensitive_details',
    files_expense: 'files_expense',
    hiring: 'hiring',
    hr_reports: 'hr_reports',
    contract_documents: 'contract_documents',
    project_management: 'project_management',
    people: 'people',
    files_remote_invoice: 'files_remote_invoice',
    billing: 'billing',
    contractor_reports: 'contractor_reports',
    people_equity: 'people_equity',
    outstanding_payments_refunds: 'outstanding_payments_refunds',
    account_linking: 'account_linking',
    billable_contractor_activities: 'billable_contractor_activities',
    contract_document_signatory: 'contract_document_signatory',
    files_personal: 'files_personal',
    notifications_pay_contractors: 'notifications_pay_contractors',
    documents_management: 'documents_management',
    files_tax_document: 'files_tax_document',
    mobility_request: 'mobility_request',
    notifications_travel_letter_request: 'notifications_travel_letter_request',
    company_equity: 'company_equity',
    company_documents: 'company_documents',
    legal_entity_payroll_config: 'legal_entity_payroll_config',
    notifications_contact_update_details: 'notifications_contact_update_details',
    cost_approval_requests: 'cost_approval_requests',
    employee_pay_elements: 'employee_pay_elements',
    notifications_requests_review: 'notifications_requests_review',
  },
} as const;
