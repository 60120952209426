import { generateSelectOptions } from '@/src/helpers/forms';

export const CONTRACT_TEMPLATE_TYPES = {
  EMPLOYMENT_AGREEMENT: 'employee_agreement',
  EMPLOYMENT_AGREEMENT_AMENDMENT: 'ea_amendment',
  CONTRACTOR_AGREEMENT: 'contractor_agreement',
  CONTRACTOR_SERVICES_AGREEMENT: 'contractor_services_agreement',
  CONTRACTOR_STATEMENT_OF_WORK: 'contractor_statement_of_work',
  SERVICE_LEVEL_AGREEMENT: 'service_level_agreement',
  CUSTOM_CONTRACTOR_AGREEMENT: 'custom_contractor_agreement',
  CUSTOM_CONTRACTOR_AGREEMENT_V2: 'custom_contractor_agreement_v2',
  GLOBAL_OFFER_LETTER: 'global_offer_letter',
  EMPLOYMENT_VERIFICATION_LETTER: 'employment_verification_letter',
  PROBATION_CONFIRMATION_LETTER: 'probation_completion_letter',
  GENERIC_EMPLOYMENT_DOCUMENT: 'generic_employment_document',
  OFFBOARDING_DOCUMENT: 'offboarding_document',
  EMPLOYER_MANAGED_DOCUMENT: 'employer_managed_document',
  TRAVEL_LETTER: 'travel_letter',
};

export const CONTRACT_TEMPLATE_COMPANY_NONE_ASSIGNED = 'none';

export const CONTRACT_TEMPLATE_TYPE_LABELS = {
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT]: 'Employment Agreement',
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT]: 'EA Amendment',
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT]: 'Contractor Services Agreement',
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK]: 'Contractor Statement of Work',
  [CONTRACT_TEMPLATE_TYPES.SERVICE_LEVEL_AGREEMENT]: 'Service Level Agreement',
  [CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2]: 'Custom Contractor Agreement',
  [CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER]: 'Global Offer Letter',
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER]: 'Employment Letter',
  [CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER]: 'Probation Confirmation Letter',
  [CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT]: 'Generic Employment Document',
  [CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT]: 'Offboarding Document',
  [CONTRACT_TEMPLATE_TYPES.EMPLOYER_MANAGED_DOCUMENT]: 'Generic Document',
  [CONTRACT_TEMPLATE_TYPES.TRAVEL_LETTER]: 'Travel Letter',
  // Note: `CONTRACTOR_AGREEMENT` and `CUSTOM_CONTRACTOR_AGREEMENT` do not have labels
  // on purpose, because these document types are deprecated.
};

export const CONTRACTOR_CONTRACT_TEMPLATE_TYPES = {
  CUSTOM_CONTRACTOR_AGREEMENT_V2: 'custom_contractor_agreement_v2',
  CONTRACTOR_SERVICES_AGREEMENT: 'contractor_services_agreement',
  CONTRACTOR_STATEMENT_OF_WORK: 'contractor_statement_of_work',
};

export const contractTemplateTypeOptions = generateSelectOptions(CONTRACT_TEMPLATE_TYPE_LABELS);

export const PENDING_CONTRACT_TYPE_LABELS = {
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_AGREEMENT]: 'Remote Contractor Agreement',
  [CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2]: 'Your Own Contractor Agreement',
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT]: 'Contractor Services Agreement',
  [CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK]: 'Contractor Statement of Work',
  [CONTRACT_TEMPLATE_TYPES.SERVICE_LEVEL_AGREEMENT]: 'Service Level Agreement',
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT]: 'EA Amendment',
  [CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER]: 'Global Offer Letter',
  [CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER]: 'Employment Letter',
  [CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER]: 'Probation Confirmation Letter',
  [CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT]: 'Generic Employment Document',
  [CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT]: 'Offboarding Document',
  [CONTRACT_TEMPLATE_TYPES.EMPLOYER_MANAGED_DOCUMENT]: 'Generic Document',
};

export const pendingContractTypeOptions = generateSelectOptions(PENDING_CONTRACT_TYPE_LABELS);

export const NEW_EDITOR_TEMPLATE_TYPES = [
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT,
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT,
  CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER,
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT,
  CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK,
  CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT,
  CONTRACT_TEMPLATE_TYPES.EMPLOYER_MANAGED_DOCUMENT,
];

export const CONTRACT_TEMPLATE_TYPES_WITH_MULTI_COUNTRY_ALLOWED = [
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT,
  CONTRACT_TEMPLATE_TYPES.GLOBAL_OFFER_LETTER,
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT,
  CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK,
  CONTRACT_TEMPLATE_TYPES.OFFBOARDING_DOCUMENT,
  CONTRACT_TEMPLATE_TYPES.TRAVEL_LETTER,
];

export const CONTRACT_TEMPLATE_TYPES_WITH_REQUIRED_NAME = new Set([
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT_AMENDMENT,
  CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_VERIFICATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.PROBATION_CONFIRMATION_LETTER,
  CONTRACT_TEMPLATE_TYPES.GENERIC_EMPLOYMENT_DOCUMENT,
]);

export const TEMPLATE_SMART_TAGS = {
  REMOTE_CONTACT_EMAIL_INTERNAL: 'REMOTE_CONTACT_EMAIL_INTERNAL',
  REMOTE_CONTACT_EMAIL: 'REMOTE_CONTACT_EMAIL',
  EMPLOYMENT_AGREEMENT_DATE: 'EMPLOYMENT_AGREEMENT_DATE',
};

export const contactEmailSmartTagOptions = [
  {
    value: TEMPLATE_SMART_TAGS.REMOTE_CONTACT_EMAIL_INTERNAL,
    label: 'For internal employees',
    description: 'people@remote.com',
  },
  {
    value: TEMPLATE_SMART_TAGS.REMOTE_CONTACT_EMAIL,
    label: 'For external employees',
    description: 'help@remote.com',
  },
];
