// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';

import { createFeatureFlagConfig } from '../config';

export const useFetchFeatureFlagConfig = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['featureFlagsConfig'],
    queryFn: createFeatureFlagConfig,
  });
  return { data, isLoading };
};
