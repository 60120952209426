// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery, useQueries } from 'react-query';

import { useTableFetcherWithQS } from '@/src/components/Table/hooks/useTableFetcher';
import { useUserCan } from '@/src/components/UserCan';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { fetchLegalEntities, fetchLegalEntity } from '@/src/services/Admin';

export const ADMIN_LEGAL_ENTITY_QUERY_KEY = 'adminFetchLegalEntity';
export const ADMIN_LEGAL_ENTITIES_QUERY_KEY = 'adminFetchLegalEntities';

export function useLegalEntity(legalEntitySlug, options = {}) {
  const { userCan } = useUserCan();

  return useQuery(
    [ADMIN_LEGAL_ENTITY_QUERY_KEY, legalEntitySlug],
    async () => (await fetchLegalEntity({ pathParams: { slug: legalEntitySlug } })).data,
    {
      ...options,
      enabled:
        (userCan('read', Resources.legal_entities) || userCan('read', Resources.remote_entities)) &&
        // If options.enabled was sent use it, if it was not sent we assume enabled=true
        (options?.enabled ?? true),
    }
  );
}

export function useLegalEntitiesAdmin(legalEntitySlugs, options = {}) {
  const { userCan } = useUserCan();

  const results = useQueries(
    legalEntitySlugs.map((slug) => ({
      queryKey: [`${ADMIN_LEGAL_ENTITY_QUERY_KEY}-${slug}`],
      queryFn: async () => (await fetchLegalEntity({ pathParams: { slug } })).data,
      enabled:
        (userCan('read', Resources.legal_entities) || userCan('read', Resources.remote_entities)) &&
        // If options.enabled was sent use it, if it was not sent we assume enabled=true
        (options?.enabled ?? true),
    }))
  );

  return results;
}

async function fetchData(queryParams) {
  const { data } = await fetchLegalEntities({ queryParams });
  return data;
}

export default function useLegalEntitiesData(queryParams = {}, options = {}) {
  const { userCan } = useUserCan();

  const enabled = options?.enabled ?? true;

  return useQuery([ADMIN_LEGAL_ENTITIES_QUERY_KEY, queryParams], () => fetchData(queryParams), {
    ...options,
    refetchOnWindowFocus: false,
    enabled:
      enabled &&
      (userCan('read', Resources.legal_entities) || userCan('read', Resources.remote_entities)),
  });
}

export function useLegalEntitiesTableData(queryParams = {}) {
  const { userCan } = useUserCan();

  return useTableFetcherWithQS(
    [ADMIN_LEGAL_ENTITIES_QUERY_KEY, queryParams],
    async (APIQuery) => {
      const { remoteEntities, ...rest } = await fetchData({
        pageSize: 10,
        ...queryParams,
        ...APIQuery,
      });

      return {
        data: remoteEntities,
        ...rest,
      };
    },
    {
      globalFilterQueryKey: 'name',
      useQueryOptions: {
        enabled:
          userCan('read', Resources.legal_entities) || userCan('read', Resources.remote_entities),
      },
    }
  );
}
