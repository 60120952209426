import { COMPANY_ONBOARDING_TASKS_ROUTE, SETUP_BILLING_ROUTE } from '@/src/constants/routes';
import { getAddNewPaymentMethodRoute } from '@/src/domains/paymentMethods/shared/helpers';

export const onboardingTask = /** @type {const} */ ({
  USER_DETAILS: 'user_details',
  EMPLOYMENT_ELIGIBILITY: 'employment_eligibility',
  ADMINISTRATIVE_DETAILS: 'administrative_details',
  HOME_ADDRESS: 'home_address',
  EMERGENCY_CONTACT: 'emergency_contact',
  PAYMENT_DETAILS: 'payment_details',
  ADDITIONAL_DOCUMENTS: 'additional_documents',
  VERIFY_IDENTITY: 'identity_verification',
  BUSINESS_INFORMATION: 'business_information',
});

export const ADDITIONAL_DOCUMENTS_FORM_REQUIRED_TASKS = new Set([
  onboardingTask.USER_DETAILS,
  onboardingTask.ADMINISTRATIVE_DETAILS,
  onboardingTask.HOME_ADDRESS,
  onboardingTask.BUSINESS_INFORMATION,
]);

export const ADMINISTRATIVE_DETAILS_FORM_REQUIRED_TASKS = new Set([
  onboardingTask.BUSINESS_INFORMATION,
]);

export const BUSINESS_INFORMATION_FORM_REQUIRED_TASKS = new Set([onboardingTask.USER_DETAILS]);

export const taskStatus = /** @type {const} */ ({
  CREATED: 'created',
  COMPLETED: 'completed',
  // just on the UI
  FAILED: 'failed',
  INCOMPLETE: 'incomplete',
  NEEDS_REVIEW: 'needs_review',
});

/**
 * Separation between kind of tasks.
 * It is set on the tasks Context, based on the page URL
 */
export const taskTypes = /** @type {const} */ ({
  ONBOARDING: 'onboarding',
  PENDING_TASK: 'pendingTask',
  SELF_SIGN_UP_ONBOARDING: 'selfSignupOnboarding',
});

export const companyOnboardingTasks = {
  ADD_BILLING_CONTACT: 'add_billing_contact',
  ADD_PAYMENT_METHOD: 'add_payment_method',
};

export const COMPANY_TASKS_CONFIG = {
  [companyOnboardingTasks.ADD_BILLING_CONTACT]: {
    action: companyOnboardingTasks.ADD_BILLING_CONTACT,
    name: 'Add the billing contact',
    description: 'The person responsible for billing and invoices in your organization.',
    status: '',
    link: SETUP_BILLING_ROUTE,
  },
  [companyOnboardingTasks.ADD_PAYMENT_METHOD]: {
    action: companyOnboardingTasks.ADD_PAYMENT_METHOD,
    name: 'Setup a payment method',
    description: 'It will allow us to immediately pay your team when needed.',
    status: '',
    link: getAddNewPaymentMethodRoute(COMPANY_ONBOARDING_TASKS_ROUTE),
  },
};

export const remainingOnboardingTasks = {
  THREE_ONBOARDING_TASKS: 3,
  TWO_ONBOARDING_TASKS: 2,
  ONE_ONBOARDING_TASK: 1,
};
