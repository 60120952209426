import isNil from 'lodash/isNil';

const LOCALE_DEFAULT = 'en';

export const copyAdjustedNumbers = {
  en: ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'],
};

/**
 * Get text for a number in accordance with our copywriting guidelines
 * @param {number} number The number
 * @param {string} [locale=en] The locale
 * @returns {string} The text for the number
 */
export function getCopyAdjustedNumber(number, locale = LOCALE_DEFAULT) {
  const localeCopyAdjustedNumbers = copyAdjustedNumbers[locale];
  const totalLocaleCopyAdjustedNumbers = Object.keys(localeCopyAdjustedNumbers).length;

  return Number.isInteger(number) && number > 0 && number <= totalLocaleCopyAdjustedNumbers
    ? localeCopyAdjustedNumbers[number - 1]
    : number.toString();
}

/**
 * Get the singular/plural variant for a given number
 * @param {number} number The number
 * @param {string} singular The singular variant of the unit
 * @param {string} plural The plural variant of the unit
 * @param {boolean} [followCopyGuidelines=true] Should numbers be transformed according to copywriting guidelines
 * @param {boolean} [showNumber=true] Should final text display the number or just the singular/plural variant
 * @param {string} [locale=en] The locale
 * @returns {string} The text for the number
 */
export function getSingularPluralUnit(
  number,
  singular,
  plural,
  followCopyGuidelines = true,
  showNumber = true,
  locale = LOCALE_DEFAULT
) {
  if (isNil(number) || !singular || !plural) {
    return '';
  }

  const currentNumber = followCopyGuidelines
    ? getCopyAdjustedNumber(number, locale)
    : number.toString();
  const currentVariant = number === 1 || number === -1 ? singular : plural;

  return showNumber ? `${currentNumber} ${currentVariant}` : currentVariant;
}

/**
 * Join a list of strings following copy guidelines:
 * - An Oxford comma for the final item if there are multiple items
 *
 * @example
 * getFormattedList(['John', 'Jane', 'Jim']) // 'John, Jane, and Jim'
 * getFormattedList(['John', 'Jane']) // 'John and Jane'
 * getFormattedList(['John']) // 'John'
 *
 * @param {string[]} list The list of strings to join
 * @returns {string} The formatted string
 */
export function getFormattedList(list) {
  if (!list?.length) {
    return '';
  }

  if (list.length === 1) {
    return list[0];
  }

  if (list.length === 2) {
    return list.join(' and ');
  }

  return `${list.slice(0, -1).join(', ')}, and ${list[list.length - 1]}`;
}
