import { ButtonIcon, sharedTransition } from '@remote-com/norma';
import styled, { css } from 'styled-components';

type StyledFavoriteButton = {
  isFavorited: boolean;
};

export const StyledFavoriteButton = styled(ButtonIcon).attrs(() => ({
  variant: 'ghost',
  tone: 'secondary',
  size: 'sm',
}))<StyledFavoriteButton>`
  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.yellow[700]};
    }
    background-color: ${(props) => props.theme.colors.yellow[200]};
  }
  ${({ isFavorited, theme }) =>
    isFavorited &&
    css`
      &,
      &:hover {
        color: ${theme.colors.yellow[400]};
      }
    `}
`;

export const hoverableFavoriteButtonWrapperStyles = css`
  ${StyledFavoriteButton} {
    opacity: 0;
    ${sharedTransition('opacity')}
  }

  &:hover ${StyledFavoriteButton}, &:focus-within ${StyledFavoriteButton} {
    opacity: 1;
  }
`;
