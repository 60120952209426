import { Text } from '@remote-com/norma';
import { IconV2DuotoneAlarmClockCheck } from '@remote-com/norma/icons/IconV2DuotoneAlarmClockCheck';
import { IconV2DuotoneBank } from '@remote-com/norma/icons/IconV2DuotoneBank';
import { IconV2DuotoneBilling } from '@remote-com/norma/icons/IconV2DuotoneBilling';
import { IconV2DuotoneBriefcase } from '@remote-com/norma/icons/IconV2DuotoneBriefcase';
import { IconV2DuotoneBuilding } from '@remote-com/norma/icons/IconV2DuotoneBuilding';
import { IconV2DuotoneCalendarEvent } from '@remote-com/norma/icons/IconV2DuotoneCalendarEvent';
import { IconV2DuotoneCalendarMinus } from '@remote-com/norma/icons/IconV2DuotoneCalendarMinus';
import { IconV2DuotoneCalendarPlus } from '@remote-com/norma/icons/IconV2DuotoneCalendarPlus';
import { IconV2DuotoneCheckCircle } from '@remote-com/norma/icons/IconV2DuotoneCheckCircle';
import { IconV2DuotoneCreditCard } from '@remote-com/norma/icons/IconV2DuotoneCreditCard';
import { IconV2DuotoneCreditCardFailed } from '@remote-com/norma/icons/IconV2DuotoneCreditCardFailed';
import { IconV2DuotoneCreditCardPlus } from '@remote-com/norma/icons/IconV2DuotoneCreditCardPlus';
import { IconV2DuotoneDollarCircle } from '@remote-com/norma/icons/IconV2DuotoneDollarCircle';
import { IconV2DuotoneEditBox } from '@remote-com/norma/icons/IconV2DuotoneEditBox';
import { IconV2DuotoneEditUnderline } from '@remote-com/norma/icons/IconV2DuotoneEditUnderline';
import { IconV2DuotoneFileCheck } from '@remote-com/norma/icons/IconV2DuotoneFileCheck';
import { IconV2DuotoneFileScan } from '@remote-com/norma/icons/IconV2DuotoneFileScan';
import { IconV2DuotoneFilesText } from '@remote-com/norma/icons/IconV2DuotoneFilesText';
import { IconV2DuotoneFileText } from '@remote-com/norma/icons/IconV2DuotoneFileText';
import { IconV2DuotoneFolderUpload } from '@remote-com/norma/icons/IconV2DuotoneFolderUpload';
import { IconV2DuotoneGift } from '@remote-com/norma/icons/IconV2DuotoneGift';
import { IconV2DuotoneGlobe } from '@remote-com/norma/icons/IconV2DuotoneGlobe';
import { IconV2DuotoneHeart } from '@remote-com/norma/icons/IconV2DuotoneHeart';
import { IconV2DuotoneLock } from '@remote-com/norma/icons/IconV2DuotoneLock';
import { IconV2DuotoneNoteText } from '@remote-com/norma/icons/IconV2DuotoneNoteText';
import { IconV2DuotoneNotificationUnread } from '@remote-com/norma/icons/IconV2DuotoneNotificationUnread';
import { IconV2DuotonePayrollOutputs } from '@remote-com/norma/icons/IconV2DuotonePayrollOutputs';
import { IconV2DuotoneReceipt } from '@remote-com/norma/icons/IconV2DuotoneReceipt';
import { IconV2DuotoneRocket } from '@remote-com/norma/icons/IconV2DuotoneRocket';
import { IconV2DuotoneScan } from '@remote-com/norma/icons/IconV2DuotoneScan';
import { IconV2DuotoneSettings } from '@remote-com/norma/icons/IconV2DuotoneSettings';
import { IconV2DuotoneShieldCheck } from '@remote-com/norma/icons/IconV2DuotoneShieldCheck';
import { IconV2DuotoneStructure } from '@remote-com/norma/icons/IconV2DuotoneStructure';
import { IconV2DuotoneStructurePlus } from '@remote-com/norma/icons/IconV2DuotoneStructurePlus';
import { IconV2DuotoneUser } from '@remote-com/norma/icons/IconV2DuotoneUser';
import { IconV2DuotoneUserPlus } from '@remote-com/norma/icons/IconV2DuotoneUserPlus';
import { IconV2DuotoneUsers } from '@remote-com/norma/icons/IconV2DuotoneUsers';
import { IconV2DuotoneUserX } from '@remote-com/norma/icons/IconV2DuotoneUserX';
import { IconV2OutlineCalendarMinus } from '@remote-com/norma/icons/IconV2OutlineCalendarMinus';
import { IconV2OutlineInfo } from '@remote-com/norma/icons/IconV2OutlineInfo';
import { IconV2OutlineUsers } from '@remote-com/norma/icons/IconV2OutlineUsers';
import capitalize from 'lodash/capitalize';
import { useRouter } from 'next/router';
import { encodeUrlParams, openModal } from 'react-url-modal';

import { ButtonInline, StandaloneLink } from '@/src/components/Button';
import {
  ACCOUNT_SETTINGS_ROUTE,
  BENEFITS_KOTA_ROUTE,
  BENEFITS_ROUTE,
  BILLING_DETAILS_ROUTE,
  BILLING_INVOICE_DETAILS,
  BILLING_ROUTE,
  CAREER_JOURNAL_PERFORMANCE_CYCLE_FORM_ROUTE,
  CLIENTS_ROUTE,
  COMPANY_COMPLETE_ONBOARDING,
  COMPANY_INFORMATION_ROUTE,
  COMPANY_VERIFICATION_ROUTE,
  CONFIRM_ANNUAL_PLAN_ROUTE,
  CONFIRM_IMPLEMENTATION_ROUTE,
  CONFIRM_PRICING_PLAN_ROUTE,
  CONFIRM_TEAM_PLAN_ROUTE,
  CONFIRM_TIERED_PLAN_ROUTE,
  CONTRACT_ROUTE,
  CONTRACT_ROUTE_V2,
  COST_APPROVAL_REQUESTS_DETAILS_ROUTE,
  CUSTOMER_PRODUCT_ONBOARDING_RECRUIT_CHECKLIST,
  CUSTOMER_USERS_PERMISSIONS_ROUTE,
  DASHBOARD_ROUTE,
  DEPOSIT_METHODS_ROUTE,
  DOCUMENTS_1099_NEC_FORMS_ROUTE,
  DOCUMENTS_ROUTE,
  EMPLOYMENT_REQUESTS_ROUTE,
  EXPENSES_ROUTE,
  FILL_FORM_EMPLOYEE_ROUTE,
  HIRING_ROUTE,
  INTEGRATIONS_NAME_ROUTE,
  INVOICES_ROUTE,
  KYB_ONBOARDING_ROUTE,
  LEGAL_ENTITIES_ONBOARDING_TASKS_ROUTE,
  LEGAL_ENTITIES_PROFILE_ROUTE,
  LINK_ACCOUNTS_ROUTE,
  NEW_EMPLOYMENT_TYPE_SLUG_ROUTE,
  ONBOARDING_EMPLOYEE_TASKS_ROUTE,
  PAYMENT_METHODS_ROUTE,
  PAYMENT_REQUESTS_ROUTE,
  PAYROLL_RUN_DETAILS_ROUTE,
  PAYROLL_RUN_PAYOUTS_ROUTE,
  PERSONAL_PROFILE_ROUTE,
  REMOTE_TERMS_OF_SERVICE,
  TALENT_BASE_ROUTE,
  TAX_COMPLIANCE_ROUTE,
  TEAM_PERFORMANCE_CYCLE_ROUTE,
  TIME_TRACKING,
  USER_PROFILE_RIGHT_TO_WORK_ROUTE,
  USER_PROFILE_ROUTE,
  WITHDRAWAL_METHODS_ROUTE,
  START_TRIAL_PRODUCT_PLAN_ROUTE,
  CUSTOMER_PRODUCT_ONBOARDING_CHECKLIST,
} from '@/src/constants/routes';
import { invoicesTabs, scheduledInvoiceStatuses } from '@/src/domains/contractorInvoices/constants';
import { PENDING_CONTRACTOR_INVOICES_FILTER_TYPES } from '@/src/domains/contractorInvoices/employer/TableHeaderFilter';
import { getContractTemplateType } from '@/src/domains/contractTemplates/helpers';
import { COST_APPROVAL_REQUEST_SERVICE_LABELS } from '@/src/domains/costApprovalRequests/constants';
import { usStates } from '@/src/domains/countries/constants';
import {
  DOCUMENT_TYPES,
  DOCUMENTS_PAGE_TABS_FOR_EMPLOYERS,
} from '@/src/domains/documents/constants';
import { getFormattedEndOfJanuaryNextYear } from '@/src/domains/documents/employer/Contractors1099TaxFormsTable/helpers';
import { ADD_DOCUMENT_MODAL_OPTIONS } from '@/src/domains/documents/shared/AddDocumentModal/constants';
import { newOnboardingStatuses } from '@/src/domains/employment/constants';
import { getFriendlyDocumentTypeName } from '@/src/domains/expiringDocuments/employee/flows/common';
import {
  employeeInvoiceDisplayTypeLabels,
  employeeInvoiceDisplayTypes,
  invoiceReportTypeFilterValues,
} from '@/src/domains/invoices/constants';
import { titleCase } from '@/src/domains/knowledge/facts/utils';
import { remainingOnboardingTasksBannerText } from '@/src/domains/legalEntities/constants';
import { resourceTypes } from '@/src/domains/paymentMethods/shared/constants';
import {
  getAddNewPaymentMethodRoute,
  getPaymentMethodTypeLabel,
} from '@/src/domains/paymentMethods/shared/helpers';
import { FailedSubscriptionPaymentTodoBody } from '@/src/domains/payments/shared/FailedSubscriptionPaymentTodoBody';
import { PAYROLL_RUN_DETAILS_VARIANTS } from '@/src/domains/payroll/employer/EmployerPayrollRunListItem/constants';
import { useEmployerPayrollRunsBySlug } from '@/src/domains/payroll/employer/hooks';
import { content } from '@/src/domains/pricing/config';
import { productEmploymentTypes } from '@/src/domains/pricing/constants';
import { isProductGlobalPayroll, isProductRecruit } from '@/src/domains/pricing/helpers';
import { getProductTitle } from '@/src/domains/pricingPlans/common/utils';
import { TermsOfServiceDrawer } from '@/src/domains/registration/terms-of-service';
import { getTimeOffRequestsRoute } from '@/src/domains/timeoff/employer/routeHelpers';
import { TODO_TYPES_V2 } from '@/src/domains/todos/constants';
import { friendlyMoneyWithCurrencyCode } from '@/src/helpers/currency';
import {
  formatDayShortMonth,
  formatDayShortMonthYear,
  formatFullMonthDay,
  formatMonthDayYear,
  formatYearMonthDay,
  getDateNDaysBefore,
} from '@/src/helpers/date';
import { getFormattedURL } from '@/src/helpers/general';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';
import { stringifyQuery } from '@/src/helpers/queryParser';
import { TIME_TRACKING_TABS_EMPLOYEE } from '@/src/views/dashboard/TimeTracking/routeHelpers';

// TODO: Remove this type when new global navigation is fully rolled out
/**
 * @typedef {Object} TodoConfigOptions
 * @property {boolean} [isNewGlobalNavigationEnabled] Temporary flag for navigation transition
 */

/**
 * @type {Record<TODO_TYPES_V2[keyof TODO_TYPES_V2], (data: {
 *   data: TodoUpdate,
 *   options?: TodoConfigOptions
 * }) => unknown>}
 */
export const todosByType = {
  [TODO_TYPES_V2.ACCEPT_TERMS_OF_SERVICE]: ({ data }) => {
    const Body = () => (
      <>
        Our{' '}
        <ButtonInline
          href={REMOTE_TERMS_OF_SERVICE}
          target="_blank"
          rel="noopener noreferrer"
          trackNavigation={['dashboard-notification', 'remote-terms-of-service']}
        >
          Terms of Service
        </ButtonInline>{' '}
        make using Remote faster and easier. The Terms replace the Master Services Agreement,
        Service Level Agreements and Service Orders.{' '}
        <TermsOfServiceDrawer>
          {({ onOpen }) => (
            <StandaloneLink IconAfter={IconV2OutlineInfo} onClick={onOpen}>
              Learn more
            </StandaloneLink>
          )}
        </TermsOfServiceDrawer>
      </>
    );
    return {
      ...data,
      title: 'Agree to the Terms of Service',
      description: <Body />,
      link: {
        href: {
          pathname: DASHBOARD_ROUTE,
          query: {
            modal: 'accept-terms-of-service',
            params: encodeUrlParams({
              checkForTermsNotification: false,
            }),
          },
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneFileCheck,
    };
  },
  [TODO_TYPES_V2.ADD_COMPANY_PHONE_NUMBER]: ({ data }) => {
    return {
      ...data,
      title: 'Add your company phone number',
      description: 'Receive quicker support and updates.',
      link: {
        href: COMPANY_INFORMATION_ROUTE,
      },
      category: 'hr',
      Icon: IconV2DuotoneEditBox,
    };
  },

  [TODO_TYPES_V2.ANNUAL_PLAN_SHARED]: ({ data }) => {
    const productTitle = getProductTitle({
      productName: data.resource.productPrice.name,
      prefix: 'Employer of Record',
    });

    return {
      ...data,
      title: `Confirm your ${productTitle} Credit plan details`,
      description: 'After you confirm the details, you can begin onboarding employees.',
      link: {
        href: {
          pathname: CONFIRM_ANNUAL_PLAN_ROUTE.replace('[planSlug]', data.resource.slug),
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneFileText,
    };
  },
  [TODO_TYPES_V2.PRICING_PLAN_SHARED]: ({ data }) => {
    const { productPrice } = data.resource;

    let planTitle = 'pricing';
    let description = 'After you confirm the details, you can begin onboarding employees.';

    if (isProductGlobalPayroll(productPrice.product)) {
      planTitle = 'Monthly Remote Payroll';
    }

    if (isProductRecruit(productPrice.product)) {
      planTitle = `Monthly Recruit ${titleCase(productPrice.name)}`;
      description =
        'After you confirm the details, you will be redirected to the checkout page to complete payment.';
    }

    return {
      ...data,
      title: `Confirm your ${planTitle} plan details`,
      description,
      link: {
        href: {
          pathname: CONFIRM_PRICING_PLAN_ROUTE.replace('[planSlug]', data?.resource?.slug),
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneFileText,
    };
  },
  [TODO_TYPES_V2.BANK_ACCOUNT_INCOMPLETE]: ({ data }) => ({
    ...data,
    title: 'Complete your bank account information',
    description: 'We need a little more information about your existing bank account.',
    link: {
      href: getAddNewPaymentMethodRoute(),
    },
    category: 'finance',
    Icon: IconV2DuotoneCreditCard,
  }),
  [TODO_TYPES_V2.RESET_PASSWORD_REQUIRED]: ({ data }) => ({
    ...data,
    title: 'Reset your password',
    description:
      'We’re upgrading the security for your account. Resetting your password will help complete the upgrades and keep your data safe.',
    link: {
      href: {
        pathname: ACCOUNT_SETTINGS_ROUTE,
        query: { selectedTab: 'account_details' },
      },
    },
    category: 'finance',
    Icon: IconV2DuotoneLock,
  }),
  [TODO_TYPES_V2.CAREER_DEVELOPMENT_SELF_ASSESSMENT_DUE]: ({ data }) => {
    const { resource } = data;
    return {
      ...data,
      title: 'Start your self-reflection',
      description: 'It’s time to look back on your journey and reflect on how you’re doing.',
      link: {
        href: CAREER_JOURNAL_PERFORMANCE_CYCLE_FORM_ROUTE.replace('[cycleSlug]', resource.cycleSlug)
          .replace('[snapshotSlug]', resource.snapshotSlug)
          .replace('[snapshotInstanceSlug]', resource.snapshotInstanceSlug)
          .replace('[assessmentSlug]', resource.assessmentSlug),
      },
      category: 'hr',
      Icon: IconV2DuotoneCalendarEvent,
    };
  },
  [TODO_TYPES_V2.CAREER_DEVELOPMENT_MANAGER_ASSESSMENT_DUE]: ({ data }) => {
    const { resource } = data;
    return {
      ...data,
      title: 'Start your manager reflections',
      description: 'It’s time to reflect on your reports’ journeys and see how they’re doing.',
      link: {
        href: `${TEAM_PERFORMANCE_CYCLE_ROUTE.replace(
          '[cycleSlug]',
          resource.cycleSlug
        )}?selectedTab=${resource.snapshotSlug}`,
      },
      category: 'hr',
      Icon: IconV2DuotoneCalendarEvent,
    };
  },

  [TODO_TYPES_V2.CAREER_DEVELOPMENT_MANAGER_ASSESSMENT_REOPENED_CALIBRATED_SCORE]: ({ data }) => {
    const { resource } = data;
    return {
      ...data,
      title: 'A re-opened manager reflection needs your attention',
      description:
        'There’s a new calibration score, and you’ll need to update your answers to match it',
      link: {
        href: `${TEAM_PERFORMANCE_CYCLE_ROUTE.replace(
          '[cycleSlug]',
          resource.cycleSlug
        )}?selectedTab=${resource.snapshotSlug}`,
      },
      category: 'hr',
      Icon: IconV2DuotoneCalendarEvent,
    };
  },
  [TODO_TYPES_V2.PERFORMANCE_CALIBRATION_CALIBRATOR_EVENT_STARTED]: ({ data }) => {
    const { resource } = data;
    return {
      ...data,
      title: "Calibrate your team's performance",
      description: "You've been asked to help calibrate, let's get started.",
      link: {
        href: `${TEAM_PERFORMANCE_CYCLE_ROUTE.replace(
          '[cycleSlug]',
          resource.cycleSlug
        )}?selectedTab=${resource.calibrationEventSlug}`,
      },
      category: 'hr',
      Icon: IconV2DuotoneUsers,
    };
  },
  [TODO_TYPES_V2.CHECKLIST_RECRUIT]: ({ data }) => {
    return {
      ...data,
      title: 'Get started with Recruit',
      description: 'Use this checklist to make most of your free trial.',
      link: {
        href: CUSTOMER_PRODUCT_ONBOARDING_RECRUIT_CHECKLIST,
      },
      category: 'hr',
      Icon: IconV2DuotoneRocket,
    };
  },
  [TODO_TYPES_V2.RECRUIT_FREE_TRIAL]: ({ data }) => {
    return {
      ...data,
      title: 'Get started with Recruit',
      description: 'Use this checklist to make the most of your 7-day free trial.',
      category: 'hr',
      Icon: IconV2DuotoneRocket,
      link: {
        href: START_TRIAL_PRODUCT_PLAN_ROUTE.replace(
          '[productEmploymentType]',
          productEmploymentTypes.RECRUIT
        ),
      },
    };
  },
  [TODO_TYPES_V2.COMPANY_PRODUCT_TIER_SHARED]: ({ data }) => {
    const { resource } = data;

    return {
      ...data,
      title: content[resource.employmentType]?.notification.title,
      description:
        'As soon as you confirm your plan’s details, you can start onboarding employees.',
      link: {
        href: {
          pathname: CONFIRM_TIERED_PLAN_ROUTE.replace('[companyProductTierSlug]', resource.slug),
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneFileCheck,
    };
  },
  [TODO_TYPES_V2.COMPLETE_FIRST_HIRE]: ({ data }) => {
    const {
      resource: { employmentType: resourceEmploymentType, name, slug },
    } = data;

    return {
      ...data,
      link: {
        href: NEW_EMPLOYMENT_TYPE_SLUG_ROUTE.replace('[employmentSlug]', slug).replace(
          '[employmentType]',
          resourceEmploymentType
        ),
        trackNavigation: ['dashboard-notification', 'complete-first-hire'],
      },
      title: `Finish adding ${name}`,
      description:
        'Your first hire is saved in your drafts. You can pick up where you left off when you’re ready.',
      category: 'hr',
      Icon: IconV2DuotoneFileText,
    };
  },
  [TODO_TYPES_V2.COMPLIANCE_REQUEST_DOCUMENT]: ({ data }) => ({
    ...data,
    onClick: () => openModal({ name: 'compliance-request' }),
    title: 'Upload additional information',
    description: 'Additional information required to complete verification.',
    category: 'hr',
    Icon: IconV2DuotoneFolderUpload,
  }),
  [TODO_TYPES_V2.CONTRACT_CURRENCY_CHANGED]: ({ data }) => {
    const {
      resource: { compensationCurrencyCode },
    } = data;

    return {
      ...data,
      title: 'Update your payment information',
      description: `Your contract currency was changed to ${compensationCurrencyCode}. Update your payment information with a ${compensationCurrencyCode} bank account to receive your salary.`,
      link: {
        href: `${PERSONAL_PROFILE_ROUTE}?selectedTab=job-pay`,
      },
      category: 'finance',
      Icon: IconV2DuotoneBank,
    };
  },
  [TODO_TYPES_V2.CONTRACT_DOCUMENT_AWAITING_SIGNATURE]: ({ data }) => {
    const {
      resource: { contractDocumentSlug, contractType, companyName },
    } = data;

    const {
      isEmploymentAgreement,
      isEmploymentAgreementAmendment,
      isContractorAgreement,
      isCustomContractorAgreement,
      isCustomContractorAgreementV2,
      isContractorServicesAgreement,
      isContractorStatementOfWork,
      isEmployerManagedDocument,
    } = getContractTemplateType(contractType);

    const contractRoute =
      isEmploymentAgreement ||
      isEmploymentAgreementAmendment ||
      isCustomContractorAgreementV2 ||
      isContractorServicesAgreement ||
      isContractorStatementOfWork ||
      isEmployerManagedDocument
        ? CONTRACT_ROUTE_V2
        : CONTRACT_ROUTE;

    const isContractorDocument =
      isContractorAgreement ||
      isCustomContractorAgreement ||
      isCustomContractorAgreementV2 ||
      isContractorServicesAgreement ||
      isContractorStatementOfWork;

    let title;
    let bodyCopyForAgreementType = 'Then you’re finished with onboarding!';

    if (isEmploymentAgreementAmendment) {
      title = 'Review and sign an amendment to your employment agreement';
    } else if (
      isContractorAgreement ||
      isCustomContractorAgreement ||
      isCustomContractorAgreementV2 ||
      isContractorServicesAgreement
    ) {
      title = 'Review and sign your contractor agreement';
    } else if (isContractorStatementOfWork) {
      title = 'Review and sign your statement of work';
    } else if (isEmployerManagedDocument) {
      title = 'Review and sign your document';
    } else {
      title = 'Review and sign your employment agreement';
    }

    if (isContractorDocument)
      bodyCopyForAgreementType = '1 contractor agreement is pending your signature!';
    if (isEmployerManagedDocument)
      bodyCopyForAgreementType = `${companyName} shared a document for your review and signature.`;

    const description = isEmploymentAgreementAmendment
      ? 'This amendment needs your signature.'
      : bodyCopyForAgreementType;

    return {
      ...data,
      title,
      description,
      link: {
        href: {
          pathname: contractRoute,
          query: { contractDocumentSlug },
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneEditBox,
    };
  },
  [TODO_TYPES_V2.CONTRACT_DOCUMENT_AWAITING_COMPANY_SIGNATURE]: ({ data }) => {
    const { resource } = data;
    const { count, employeeName, contractDocumentSlug } = resource;

    const commonProps = {
      category: 'hr',
      Icon: IconV2DuotoneEditUnderline,
    };

    const { isEmployerManagedDocument } = getContractTemplateType(resource.contractType);
    const documentType = isEmployerManagedDocument ? 'document' : 'contract';

    if (count > 1) {
      return {
        ...data,
        ...commonProps,
        title: `${count} ${documentType}s are awaiting your signature`,
        description: `You have been assigned as the designated signatory responsible for reviewing, signing and sending ${
          isEmployerManagedDocument ? 'these documents' : 'contractor agreements'
        }.`,
        link: {
          href: {
            pathname: DOCUMENTS_ROUTE,
            query: {
              selectedTab: DOCUMENTS_PAGE_TABS_FOR_EMPLOYERS.CONTRACTS,
              'filters[0][id]': 'signature',
              'filters[0][value]': 'onlyAssigned',
            },
          },
        },
      };
    }

    return {
      ...data,
      ...commonProps,
      title: `A ${documentType} needs your signature`,
      description: `${employeeName}’s ${
        isEmployerManagedDocument ? 'document' : 'contractor agreement'
      } is currently awaiting your signature.`,
      link: {
        href: {
          pathname: CONTRACT_ROUTE,
          query: { contractDocumentSlug },
        },
      },
    };
  },
  [TODO_TYPES_V2.CONTRACTOR_ADD_WITHDRAWAL_METHOD]: ({ data }) => {
    return {
      ...data,
      title: 'Add withdrawal method to receive payments',
      description:
        'You need to add a withdrawal method to Remote in order to receive payments from your clients. Please add one as soon as you can.',
      link: {
        href: WITHDRAWAL_METHODS_ROUTE,
      },
      category: 'finance',
      Icon: IconV2OutlineCalendarMinus,
    };
  },
  [TODO_TYPES_V2.CONTRACTOR_AUTOMATIC_PAYMENT_MAXIMUM_EXCEEDED]: ({ data }) => {
    const count = data?.resource?.count || 1;

    return {
      ...data,
      title: 'Review contractor invoices that exceed the limit for automatic payment',
      description: `You have ${getSingularPluralUnit(
        count,
        'contractor invoice that exceeds',
        'contractor invoices that exceed'
      )} the limit of auto-pay. Please review and pay ${getSingularPluralUnit(
        count,
        'it',
        'them',
        false,
        false
      )} separately.`,
      link: {
        href: PAYMENT_REQUESTS_ROUTE,
      },
      category: 'finance',
      Icon: IconV2DuotoneBilling,
    };
  },
  [TODO_TYPES_V2.CONTRACTOR_AUTOMATIC_PAYMENT_PAY_IN_FAILED]: ({ data }) => {
    const count = data?.resource?.count || 1;

    return {
      ...data,
      title: 'Pay contractor invoices using bank transfer',
      description: `Automatic payment failed for ${getSingularPluralUnit(
        count,
        'contractor invoice',
        'contractor invoices'
      )}. Retry payment using bank transfer.`,
      link: { href: PAYMENT_REQUESTS_ROUTE },
      category: 'finance',
      Icon: IconV2DuotoneBilling,
    };
  },
  [TODO_TYPES_V2.CONTRACTOR_INVOICE_ISSUED]: ({ data }) => {
    const count = data?.resource?.count || 1;

    return {
      ...data,
      title: 'Process contractor invoices',
      description: `You have ${count} unpaid ${getSingularPluralUnit(
        count,
        'contractor invoice',
        'contractor invoices',
        false,
        false
      )} to review.`,
      link: {
        href: PAYMENT_REQUESTS_ROUTE,
      },
      category: 'finance',
      Icon: IconV2DuotoneBilling,
    };
  },
  [TODO_TYPES_V2.CONTRACTOR_INVOICE_APPROVED]: ({ data }) => {
    const count = data?.resource?.count || 1;
    const contractorName = data?.contractorName;
    const remainingNotifications = Array.isArray(data?.resource?.remainingNotifications)
      ? data.resource.remainingNotifications
      : [];

    const contractorSlugs = remainingNotifications
      .map(({ contractorSlug }) => contractorSlug)
      .filter(Boolean);

    const distinctContractors = new Set([data?.contractorSlug, ...contractorSlugs]);

    return {
      ...data,
      title: `Pay contractor ${getSingularPluralUnit(count, 'invoice', 'invoices', false, false)}`,
      description:
        distinctContractors.size === 1
          ? getSingularPluralUnit(
              count,
              `An invoice from ${contractorName} is ready to be paid.`,
              `There are ${count} invoices from ${contractorName} ready to be paid.`,
              false,
              false
            )
          : `Invoices from ${distinctContractors.size} contractors are ready to be paid.`,
      link: {
        href: {
          pathname: PAYMENT_REQUESTS_ROUTE,
          query: {
            selectedFilter: PENDING_CONTRACTOR_INVOICES_FILTER_TYPES.PAYMENT_DEFAULT,
          },
        },
      },
      category: 'finance',
      Icon: IconV2DuotoneBilling,
    };
  },
  [TODO_TYPES_V2.BILLING_DOCUMENT_READY]: ({ data }) => {
    const { slug: invoiceSlug, count = 1 } = data?.resource || {};

    if (data?.resource.invoiceCode === employeeInvoiceDisplayTypes.CONTRACTOR_PAYMENTS) {
      const displayLabel =
        employeeInvoiceDisplayTypeLabels[employeeInvoiceDisplayTypes.CONTRACTOR_PAYMENTS];
      const invoiceLink =
        count === 1
          ? `${BILLING_INVOICE_DETAILS}`.replace('[slug]', invoiceSlug)
          : {
              pathname: BILLING_ROUTE,
              query: stringifyQuery({
                filters: [
                  {
                    id: 'types',
                    value: [invoiceReportTypeFilterValues.CONTRACTOR_PAYMENTS_INVOICE],
                  },
                ],
              }),
            };

      return {
        ...data,
        title: `Pay  ${getSingularPluralUnit(
          count,
          displayLabel,
          `${displayLabel}s`,
          false,
          false
        )}`,
        description: `Please review and pay ${getSingularPluralUnit(
          count,
          `this invoice`,
          'these invoices',
          false,
          false
        )}.`,
        link: {
          href: invoiceLink,
        },
        category: 'finance',
        Icon: IconV2DuotoneReceipt,
      };
    }
    if (
      data?.resource.invoiceCode === employeeInvoiceDisplayTypes.CONTRACTOR_PROTECT_MANAGEMENT_FEES
    ) {
      const displayLabel =
        employeeInvoiceDisplayTypeLabels[
          employeeInvoiceDisplayTypes.CONTRACTOR_PROTECT_MANAGEMENT_FEES
        ];
      const invoiceLink =
        count === 1
          ? `${BILLING_INVOICE_DETAILS}`.replace('[slug]', invoiceSlug)
          : {
              pathname: BILLING_ROUTE,
              query: stringifyQuery({
                filters: [
                  {
                    id: 'types',
                    value: [invoiceReportTypeFilterValues.CONTRACTOR_PROTECT_MANAGEMENT_FEES],
                  },
                ],
              }),
            };
      return {
        ...data,
        title: `Pay  ${getSingularPluralUnit(
          count,
          displayLabel,
          `${displayLabel}s`,
          false,
          false
        )}`,
        description:
          'To keep your subscription running smoothly, please remember to make your payment on time',
        link: {
          href: invoiceLink,
        },
        category: 'finance',
        Icon: IconV2DuotoneReceipt,
      };
    }
    return null;
  },
  [TODO_TYPES_V2.CONTRACTOR_RE_ADD_NIUM_WITHDRAWAL_METHODS]: ({ data }) => {
    return {
      ...data,
      title: 'Re-add your withdrawal methods',
      description:
        'Your client is now using our new Remote Payments. As part of this, you need to re-add and confirm your bank account(s) to avoid any delays in payments.',
      link: {
        href: WITHDRAWAL_METHODS_ROUTE,
      },
      category: 'finance',
      Icon: IconV2DuotoneBank,
    };
  },
  [TODO_TYPES_V2.EMPLOYEE_HEALTH_INSURANCE_OFFER_ACTION_REQUIRED]: ({ data }) => {
    const { dueDate } = data;

    return {
      ...data,
      title: 'Complete your benefit enrollment',
      description: `We need a few details from you to activate your health benefits by ${formatDayShortMonthYear(
        dueDate
      )}.`,
      link: {
        pathname: BENEFITS_ROUTE,
      },
      category: 'benefits',
      Icon: IconV2DuotoneGift,
    };
  },
  [TODO_TYPES_V2.EMPLOYEE_HEALTH_INSURANCE_POLICY_ACTIVATED]: ({ data }) => {
    const { dueDate } = data;

    return {
      ...data,
      title: 'Check out your benefits details',
      description: `You are automatically enrolled without needing any action from your end. You have until ${formatDayShortMonthYear(
        dueDate
      )} to add your dependents or decline coverage.`,
      link: {
        href: {
          pathname: BENEFITS_ROUTE,
        },
      },
      category: 'benefits',
      Icon: IconV2DuotoneGift,
    };
  },
  [TODO_TYPES_V2.EMPLOYER_HEALTH_INSURANCE_QUOTE_ACTION_REQUIRED]: ({ data }) => {
    const {
      resource: { slug },
    } = data;

    return {
      ...data,
      title: 'Review your benefits quote',
      description:
        "Your quote is ready! Check the details and accept to ensure your employee's health benefits are activated instantly.",
      link: {
        href: {
          pathname: BENEFITS_KOTA_ROUTE,
          query: { legalEntitySlug: slug },
        },
      },
      category: 'benefits',
      Icon: IconV2DuotoneGift,
    };
  },
  [TODO_TYPES_V2.EMPLOYER_HEALTH_INSURANCE_QUOTE_NOT_UNDERTAKEN]: ({ data }) => {
    const {
      resource: { slug },
    } = data;

    return {
      ...data,
      title: 'Review your health insurance quotes',
      description: 'Your health insurance quotes was not undertaken. Please take a look.',
      link: {
        href: {
          pathname: BENEFITS_KOTA_ROUTE,
          query: { legalEntitySlug: slug },
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneHeart,
    };
  },
  [TODO_TYPES_V2.COMPANY_TAX_FORMS_1099_GENERATED]: ({ data }) => {
    const taxYear = getFormattedEndOfJanuaryNextYear(new Date(data.insertedAt));

    return {
      title: '1099-NEC tax forms are available for review!',
      description: `Please review 1099-NEC tax forms and send them to your U.S. contractors by ${taxYear}. Remember to also send copies of these forms to the IRS by ${taxYear}.`,
      link: {
        href: {
          pathname: DOCUMENTS_1099_NEC_FORMS_ROUTE,
        },
      },
      category: 'expenses',
      Icon: IconV2DuotoneFileText,
    };
  },
  [TODO_TYPES_V2.COST_APPROVAL_REQUEST_PENDING]: ({ data }) => {
    const { resource: costApprovalRequest } = data;

    const serviceName =
      costApprovalRequest?.serviceName ??
      COST_APPROVAL_REQUEST_SERVICE_LABELS[costApprovalRequest?.service];
    const employeeName = costApprovalRequest?.employment?.user?.name;

    return {
      ...data,
      title: `Review the cost for ${serviceName}${employeeName ? ` for ${employeeName}` : ''}`,
      description: 'Before we can continue, we need your approval for the related cost.',
      link: {
        href: {
          pathname: COST_APPROVAL_REQUESTS_DETAILS_ROUTE,
          query: {
            slug: costApprovalRequest?.slug,
          },
        },
      },
      category: 'finance',
      Icon: IconV2DuotoneCheckCircle,
    };
  },
  [TODO_TYPES_V2.DEFAULT_PAYMENT_METHOD_EXPIRED]: ({ data }) => ({
    ...data,
    title: 'Update your default payment method',
    description:
      'Your current payment method has expired. Please update it to avoid payment delays.',
    link: {
      href: PAYMENT_METHODS_ROUTE,
    },
    category: 'finance',
    Icon: IconV2DuotoneCreditCard,
  }),
  [TODO_TYPES_V2.DISABILITY_STATUS_REQUEST]: ({ data }) => {
    return {
      ...data,
      title: 'Let Remote know your disability status',
      description:
        'We understand you may not want to disclose this, but in many countries, employees with disabilities are entitled to certain protections.',
      link: {
        href: `${PERSONAL_PROFILE_ROUTE}?selectedTab=administrative-details`,
      },
      Icon: IconV2DuotoneUser,
      category: 'hr',
    };
  },
  [TODO_TYPES_V2.INVALIDATED_PAYMENT_METHOD]: ({ data }) => {
    const {
      resource: { last4, type },
    } = data;
    const account = type === resourceTypes.STRIPE_SEPA_DIRECT_DEBIT ? 'IBAN' : 'account';
    return {
      title: 'Restore your Direct Debit payment method',
      description: (
        <Text>
          Due to disputed or unauthorized payments, {getPaymentMethodTypeLabel(type)} ending in{' '}
          {last4} has been suspended. Contact <strong>paymentservices@remote.com</strong> to restore
          your {account}.
        </Text>
      ),
      link: {
        href: PAYMENT_METHODS_ROUTE,
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCardFailed,
      ...data,
    };
  },
  [TODO_TYPES_V2.DOCUMENT_REQUEST]: ({ data }) => {
    const missingDocumentCount = data?.resource?.count || 1;
    const singularOrPluralDocumentLabel = getSingularPluralUnit(
      missingDocumentCount,
      'document',
      'documents',
      false,
      false
    );

    return {
      ...data,
      title: `Upload your missing mandatory ${singularOrPluralDocumentLabel}`,
      description: `Please take a moment to upload your ${singularOrPluralDocumentLabel}.`,
      link: {
        href: DOCUMENTS_ROUTE,
      },
      category: 'hr',
      Icon: IconV2DuotoneFolderUpload,
    };
  },
  [TODO_TYPES_V2.EMPLOYEE_WITH_SKIPPED_FIELDS]: ({ notificationData }) => {
    return {
      ...notificationData,
      title: 'Add missing details required for onboarding',
      description: 'To avoid delays to your start date, please add them as soon as you can.',
      link: {
        href: `${PERSONAL_PROFILE_ROUTE}?selectedTab=administrative-details`,
      },
      category: 'hr',
      Icon: IconV2DuotoneUserPlus,
    };
  },
  [TODO_TYPES_V2.RIGHT_TO_WORK_CHECK_NEEDED]: ({ data }) => {
    const { resource } = data;
    const { count, employeeName, employmentSlug } = resource;

    if (count > 1) {
      return {
        ...data,
        category: 'hr',
        Icon: IconV2DuotoneCheckCircle,
        title: `Start right to work checks for ${count} hires`,
        description:
          'This check verifies that they have the legal right to work in their country of residence.',
        link: {
          href: {
            pathname: HIRING_ROUTE,
            query: stringifyQuery({
              selectedTab: 'hired_by_my_company',
              filters: [{ id: 'status', value: newOnboardingStatuses.RIGHT_TO_WORK }],
            }),
          },
        },
      };
    }

    return {
      ...data,
      category: 'hr',
      Icon: IconV2DuotoneCheckCircle,
      title: `Start ${employeeName}’s right to work check`,
      description:
        'This check verifies that they have the legal right to work in their country of residence.',
      link: {
        href: {
          pathname: USER_PROFILE_RIGHT_TO_WORK_ROUTE.replace('[employmentSlug]', employmentSlug),
        },
      },
    };
  },

  [TODO_TYPES_V2.EXPIRING_DOCUMENT]: ({ data }) => {
    const { resource: expiringDocument } = data;

    const documentName = getFriendlyDocumentTypeName(expiringDocument.type).toLowerCase();

    return {
      ...data,
      title: `Upload a new ${documentName}`,
      description: `The one we have on file will expire on ${formatYearMonthDay(
        expiringDocument.expirationDate
      )}`,
      link: {
        href: {
          pathname: DASHBOARD_ROUTE,
          query: {
            modal: 'expiring-document-modal',
            params: encodeUrlParams({
              slug: expiringDocument.slug,
            }),
          },
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneFileScan,
    };
  },
  [TODO_TYPES_V2.FINISH_COMPANY_VERIFICATION]: ({ data }) => {
    return {
      ...data,
      title: 'Finish company verification',
      description:
        'In order to pay employees and contractors and keep Remote secure, we’ll need a little additional information about your company.',
      link: {
        href: { pathname: COMPANY_VERIFICATION_ROUTE },
      },
      category: 'hr',
      Icon: IconV2DuotoneBriefcase,
    };
  },

  [TODO_TYPES_V2.FREELANCER_ACCEPT_CLIENT_INVITE]: ({ data }) => {
    const {
      resource: { employmentSlug, companyName, contractorProfileIndex },
    } = data;

    return {
      ...data,
      title: `Accept a contractor onboarding invite from ${companyName}.`,
      description:
        'Accept the invite and proceed to onboarding in order to start invoicing and receive payments.',
      link: {
        href: {
          pathname: '/dashboard/switch-to-account-profile',
          query: {
            redirectURL: ONBOARDING_EMPLOYEE_TASKS_ROUTE.replace(
              '[employmentSlug]',
              employmentSlug
            ),
            profileIndex: contractorProfileIndex,
          },
        },
      },
      category: 'hr',
      Icon: IconV2OutlineCalendarMinus,
    };
  },
  [TODO_TYPES_V2.GENERIC_EMPLOYMENT_DOCUMENT_AWAITING_SIGNATURE]: ({ data }) => {
    const {
      resource: { contractDocumentSlug, templateName },
    } = data;

    return {
      ...data,
      title: `Review and sign an employment document`,
      description: `Here’s the document that needs your signature: ${templateName}`,
      link: {
        href: {
          pathname: CONTRACT_ROUTE_V2,
          query: { contractDocumentSlug },
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneEditBox,
    };
  },
  [TODO_TYPES_V2.IDENTITY_VERIFICATION]: ({ data }) => {
    const {
      resource: { employeeName, ...resource },
    } = data;
    return {
      ...data,
      title: `Verify ${employeeName}’s identity`,
      description: 'This is required to ensure compliance.',
      onClick: () =>
        openModal({
          name: 'employment-verification',
          params: {
            employeeName,
            ...resource,
          },
        }),
      category: 'hr',
      Icon: IconV2DuotoneScan,
    };
  },
  [TODO_TYPES_V2.IMPLEMENTATION_FEE_SHARED]: ({ data }) => {
    const {
      resource: { slug, legalEntity },
    } = data;

    return {
      ...data,
      title: `Confirm your Global Payroll plan for ${legalEntity?.name}`,
      description: 'As soon as you confirm your plan’s details, we’ll finish setting up your plan.',
      link: {
        href: { pathname: CONFIRM_IMPLEMENTATION_ROUTE.replace('[resourceSlug]', slug) },
      },
      category: 'hr',
      Icon: IconV2DuotoneFileCheck,
    };
  },
  [TODO_TYPES_V2.ONBOARDING_LEGAL_ENTITY_TASKS]: ({ data }) => {
    const {
      resource: { slug, name },
    } = data;

    return {
      ...data,
      title: `Complete setup for ${name}`,
      description: remainingOnboardingTasksBannerText,
      link: {
        href: {
          pathname: LEGAL_ENTITIES_ONBOARDING_TASKS_ROUTE,
          query: {
            slug,
          },
        },
      },
      category: 'finance',
      Icon: IconV2DuotoneSettings,
    };
  },
  [TODO_TYPES_V2.LEGAL_ENTITY_NEW_STATE_TAX_FORM]: ({ data }) => {
    const {
      resource: { slug, name },
      details: { state },
    } = data;

    return {
      ...data,
      title: `${
        Object.keys(usStates).includes(state) ? usStates[state] : ''
      } tax info is required for ${name}`,
      description:
        'Complete the state tax info to ensure your employees can get paid without delay.',
      link: {
        href: {
          pathname: LEGAL_ENTITIES_PROFILE_ROUTE,
          query: {
            slug,
            selectedTab: 'details',
            scroll: true,
          },
        },
      },
      category: 'finance',
      Icon: IconV2DuotoneFileText,
    };
  },
  [TODO_TYPES_V2.INFORMATION_REQUEST]: ({ data }) => {
    const {
      resource: { type, ...resource },
    } = data;

    return {
      ...data,
      title: 'Finish setting up your profile',
      description:
        'We need a few more details from you. Please take a moment to add this information now.',
      onClick: () =>
        openModal({
          name: 'submit-requested-information',
          params: {
            ...resource,
          },
        }),
      category: 'hr',
      Icon: IconV2DuotoneUser,
    };
  },
  [TODO_TYPES_V2.MISSING_ETHNICITY_FIELDS]: ({ data }) => ({
    ...data,
    title: 'Complete your race/ethnicity information',
    description:
      'Your details will be used only as required by law. If you do not wish to share this information, please select “I do not wish to disclose my ethnicity.”',
    link: {
      href: `${PERSONAL_PROFILE_ROUTE}?selectedTab=personal&modal=personal-information`,
    },
    category: 'hr',
    Icon: IconV2DuotoneFileCheck,
  }),
  [TODO_TYPES_V2.MISSING_NAME_SURNAME_FIELDS]: ({ data }) => ({
    ...data,
    title: 'Update your name in your personal details',
    description: 'Your profile is missing important information.',
    link: {
      href: `${PERSONAL_PROFILE_ROUTE}?selectedTab=personal&modal=personal-information`,
    },
    category: 'hr',
    Icon: IconV2DuotoneFileCheck,
  }),
  [TODO_TYPES_V2.MISSING_PERSONAL_DETAILS_FIELDS]: ({ data }) => ({
    ...data,
    title: 'Help us make benefits more inclusive',
    description:
      "We're creating a better benefits experience at Remote. Help us out by confirming and updating your profile information.",
    link: {
      href: `${PERSONAL_PROFILE_ROUTE}?selectedTab=personal`,
    },
    category: 'hr',
    Icon: IconV2DuotoneFileCheck,
  }),
  [TODO_TYPES_V2.MISSING_W8_FORM]: ({ data }) => ({
    ...data,
    title: 'Upload your missing W-8 tax form',
    description:
      'The W-8 ensures tax compliance for contractors who are not based in the US but work for a US company.',
    link: {
      href: {
        pathname: DOCUMENTS_ROUTE,
        query: {
          modal: 'add-document',
          params: encodeUrlParams({
            type: DOCUMENT_TYPES.TAX_DOCUMENT,
            description: 'W8-ben-form',
            modalType: ADD_DOCUMENT_MODAL_OPTIONS.EMPLOYEE_UPLOADING_FOR_SELF,
          }),
        },
      },
    },
    category: 'hr',
    Icon: IconV2DuotoneFileCheck,
  }),
  [TODO_TYPES_V2.MISSING_W9_FORM]: ({ data }) => ({
    ...data,
    title: 'Upload your missing W-9 tax form',
    description: 'The W-9 ensures tax compliance for US-based contractors.',
    link: {
      href: {
        pathname: DOCUMENTS_ROUTE,
        query: {
          modal: 'add-document',
          params: encodeUrlParams({
            type: DOCUMENT_TYPES.TAX_DOCUMENT,
            description: 'W9 form',
            modalType: ADD_DOCUMENT_MODAL_OPTIONS.EMPLOYEE_UPLOADING_FOR_SELF,
          }),
        },
      },
    },
    category: 'hr',
    Icon: IconV2DuotoneFileCheck,
  }),
  [TODO_TYPES_V2.ONBOARDING_BANK_ACCOUNT_MISSING]: ({ data }) => {
    return {
      ...data,
      title: 'Add your bank details',
      description: 'We use these details to send you your pay.',
      link: {
        href: DEPOSIT_METHODS_ROUTE,
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCard,
    };
  },
  [TODO_TYPES_V2.ONBOARDING_RESERVE_INVOICE_READY]: ({ data }) => {
    const {
      resource: { employeeName, invoiceReportSlug, billingDocumentSlug },
      dueDate,
    } = data;
    const invoiceSlug = billingDocumentSlug || invoiceReportSlug;
    return {
      ...data,
      title: `Pay reserve invoice for ${employeeName}`,
      description: `Please review and pay this invoice${
        dueDate ? ` by ${formatDayShortMonth(dueDate)}` : ''
      }.`,
      link: {
        href: getFormattedURL(BILLING_INVOICE_DETAILS, {
          '[slug]': invoiceSlug,
        }),
        target: '_blank',
        rel: 'noopener',
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCard,
    };
  },
  [TODO_TYPES_V2.FIXED_TERM_CONTRACT_EXPIRING_SOON]: ({ data }) => {
    const {
      resource: { employeeName, employmentSlug, contractEndDate },
      dueDate,
    } = data;

    return {
      ...data,
      title: `Renew or terminate ${employeeName}'s employment`,
      description: `Their current contract ends${
        contractEndDate
          ? ` on ${formatDayShortMonth(
              contractEndDate
            )}. Please take action by ${formatDayShortMonth(dueDate)}`
          : ''
      }.`,
      link: {
        href: { pathname: USER_PROFILE_ROUTE, query: { employmentSlug, selectedTab: 'job' } },
        target: '_blank',
        rel: 'noopener',
      },
      category: 'hr',
      Icon: IconV2DuotoneFileText,
    };
  },
  [TODO_TYPES_V2.ONBOARDING_RESERVE_PAYMENT_OVERDUE]: ({ data }) => {
    const {
      resource: { employeeName, invoiceReportSlug, billingDocumentSlug },
    } = data;
    const invoiceSlug = billingDocumentSlug || invoiceReportSlug;

    return {
      ...data,
      title: `Pay overdue reserve invoice for ${employeeName}`,
      // Invoice due date needs to be returned
      description: `Please pay this invoice soon. Otherwise, there could be interruptions to your access to Remote services.`,
      link: {
        href: getFormattedURL(BILLING_INVOICE_DETAILS, {
          '[slug]': invoiceSlug,
        }),
        target: '_blank',
        rel: 'noopener',
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCard,
    };
  },
  [TODO_TYPES_V2.ONBOARDING_RESERVE_PAYMENT_FAILED]: ({ data }) => {
    const {
      resource: { employeeName, invoiceReportSlug, billingDocumentSlug },
    } = data;
    const invoiceSlug = billingDocumentSlug || invoiceReportSlug;

    return {
      ...data,
      title: `Retry reserve payment for ${employeeName}`,
      description: `Your payment for the reserve invoice for ${employeeName} failed to process.`,
      link: {
        href: { pathname: BILLING_INVOICE_DETAILS.replace('[slug]', invoiceSlug) },
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCardFailed,
    };
  },
  [TODO_TYPES_V2.PAYMENT_ATTEMPT_FAILED]: ({ data }) => {
    const {
      resource: { slug, contractorInvoices },
    } = data;

    const contractorInvoiceUserNames =
      contractorInvoices?.flatMap((contractorInvoice) => {
        if (contractorInvoice.user?.name) {
          return contractorInvoice.user.name;
        }

        return [];
      }) ?? [];

    return {
      ...data,
      title: 'Retry a contractor invoice payment',
      description:
        contractorInvoiceUserNames.length > 0 ? (
          <Text data-dd-action-name="Payment for contractor invoices failed">
            Your payment for{' '}
            {getSingularPluralUnit(
              contractorInvoiceUserNames.length,
              `${contractorInvoiceUserNames[0]}’s invoice`,
              `${contractorInvoiceUserNames.length} contractor’s invoices`,
              false,
              false
            )}{' '}
            failed. Please try again.
          </Text>
        ) : (
          'Your payment for a contractor’s invoice failed. Please try again.'
        ),
      link: {
        href: { pathname: BILLING_DETAILS_ROUTE.replace('[slug]', slug) },
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCardFailed,
    };
  },
  [TODO_TYPES_V2.PAYROLL_RUN_READY_FOR_APPROVAL]: ({ data }) => {
    const {
      resource: { approvalDate, payrollRunSlug, remainingNotifications },
    } = data;

    const remainingNotificationData = remainingNotifications?.length
      ? remainingNotifications.map((notification) => ({
          date: notification.resource.approvalDate,
          slug: notification.resource.payrollRunSlug,
        }))
      : [];

    const notificationData = [
      {
        date: approvalDate,
        slug: payrollRunSlug,
      },
      ...remainingNotificationData,
    ].filter(({ date }) => Boolean(date));
    const slugs = notificationData.map((run) => run.slug);
    const payrollRuns = useEmployerPayrollRunsBySlug(slugs);

    const firstPayrollRunData = payrollRuns[0]?.data;
    const firstPayrollLegalEntity = firstPayrollRunData?.legalEntity;

    return {
      ...data,
      title: `Review and approve payroll ${
        firstPayrollLegalEntity ? `for ${firstPayrollLegalEntity.name}` : ''
      } ${firstPayrollLegalEntity ? `in ${firstPayrollLegalEntity.address.country.name}` : ''}`,
      description: `Please approve ${
        approvalDate ? `by ${formatFullMonthDay(approvalDate)}` : ''
      } to ensure payroll is processed smoothly.`,
      category: 'finance',
      Icon: IconV2DuotoneDollarCircle,
      link: {
        href: {
          pathname: PAYROLL_RUN_DETAILS_ROUTE,
          query: {
            payrollSlug: data?.resource?.payrollRunSlug,
            variant: PAYROLL_RUN_DETAILS_VARIANTS.OUTPUTS,
          },
        },
      },
    };
  },
  [TODO_TYPES_V2.PENDING_EXPENSE]: ({ data }) => {
    const count = data?.resource?.count || 1;

    return {
      ...data,
      title: 'Review employee expenses',
      description: `You have ${getSingularPluralUnit(
        count,
        'pending expense',
        'pending expenses',
        false
      )} to approve or decline.`,
      link: { href: EXPENSES_ROUTE },
      category: 'expenses',
      Icon: IconV2DuotoneNoteText,
    };
  },
  [TODO_TYPES_V2.PRE_HIRE_DETAILS_AWAITING_APPROVAL]: ({ data }) => {
    const {
      resource: { employeeName, employmentSlug },
    } = data;

    return {
      ...data,
      onClick: () =>
        openModal({
          name: 'pre-hire-check-results',
          params: {
            slug: employmentSlug,
          },
        }),
      link: {
        href: {
          query: {
            modal: 'pre-hire-check-results',
            params: encodeUrlParams({
              slug: employmentSlug,
            }),
          },
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneFileCheck,
      title: `Review ${employeeName}’s pre-hire assessment result`,
      description: `Take a look and let us know how you want to proceed with this potential hire.`,
    };
  },
  [TODO_TYPES_V2.REATTEMPT_FAILED_PAYMENT]: ({ data }) => {
    const {
      resource: { slug: payrollRunSlug },
    } = data;

    return {
      title: 'Process pending employee payment',
      description: "An employee added their deposit method and it's time to process their payment.",
      Icon: IconV2DuotoneShieldCheck,
      link: {
        href: getFormattedURL(
          PAYROLL_RUN_PAYOUTS_ROUTE,
          {
            '[payrollSlug]': payrollRunSlug,
          },
          {
            selectedTab: 'unprocessed_payments',
          }
        ),
      },
      category: 'finance',
    };
  },
  [TODO_TYPES_V2.RE_ATTEMPT_DISPUTED_PAYMENT]: ({ data }) => ({
    title: 'Re-attempt disputed payment',
    description:
      'Your recent payment was not successful due to a dispute. Please re-attempt payment on the related outstanding invoice.',
    link: {
      href: {
        pathname: data?.resource?.slug
          ? BILLING_DETAILS_ROUTE.replace('[slug]', data?.resource?.slug)
          : BILLING_ROUTE,
      },
    },
    category: 'finance',
    Icon: IconV2DuotoneBilling,
  }),
  [TODO_TYPES_V2.RE_ENTER_PAYMENT_METHOD]: ({ data }) => ({
    ...data,
    title: 'Re-enter your payment methods',
    description:
      'We improved our payment network. Please re-enter your payment methods before your next invoice is due.',
    link: {
      href: PAYMENT_METHODS_ROUTE,
    },
    category: 'finance',
    Icon: IconV2DuotoneCreditCard,
  }),
  [TODO_TYPES_V2.REMOTE_WORK_DETAILS_CONFIRMATION]: ({ data }) => {
    const {
      resource: { slug: requestSlug, travelEndDate },
      slug: notificationSlug,
    } = data;
    return {
      ...data,
      title: 'Confirm your remote working details',
      description: `According to your request, you returned from remote working on ${formatMonthDayYear(
        travelEndDate
      )}. For compliance reasons, we need to confirm this with you.`,
      onClick: () =>
        openModal({
          name: 'remote-work-confirmation-drawer',
          params: {
            requestSlug,
            notificationSlug,
          },
        }),
      category: 'hr',
      Icon: IconV2DuotoneFileCheck,
    };
  },
  [TODO_TYPES_V2.REQUESTED_CANCEL_TIMEOFF]: ({ data, options }) => {
    const count = data?.resource?.count || 1;

    return {
      ...data,
      title: `Review an employee's ${getSingularPluralUnit(
        count,
        'request',
        'requests'
      )} to cancel time off`,
      description: 'Approve or decline their request.',
      link: { href: getTimeOffRequestsRoute(!!options?.isNewGlobalNavigationEnabled) },
      category: 'timeAndAttendance',
      Icon: IconV2DuotoneCalendarMinus,
    };
  },
  [TODO_TYPES_V2.REQUESTED_TIMEOFF]: ({ data, options }) => {
    const count = data?.resource?.count || 1;
    return {
      ...data,
      title: `Review ${getSingularPluralUnit(
        count,
        'time off request',
        'time off requests',
        false,
        false
      )}`,
      description: `${getSingularPluralUnit(
        count,
        'request needs',
        'requests need',
        false,
        true
      )} your attention.`,
      link: { href: getTimeOffRequestsRoute(!!options?.isNewGlobalNavigationEnabled) },
      category: 'timeAndAttendance',
      Icon: IconV2DuotoneCalendarPlus,
    };
  },
  [TODO_TYPES_V2.TIMESHEET_SENT_BACK_TODO]: ({ data }) => {
    const count = data?.resource?.count || 1;

    return {
      ...data,
      title: `Review ${getSingularPluralUnit(count, 'timesheet', 'timesheets', false, false)}`,
      description: `${count} ${getSingularPluralUnit(
        count,
        'timesheet was',
        'timesheets were',
        false,
        false
      )} returned and may require changes.`,
      link: { href: `${TIME_TRACKING}?tab=${TIME_TRACKING_TABS_EMPLOYEE.ALL.value}` },
      category: 'timeAndAttendance',
      Icon: IconV2DuotoneAlarmClockCheck,
    };
  },
  [TODO_TYPES_V2.RESIGNATION_EMPLOYMENT_REQUEST_AWAITING_VALIDATION]: ({ data }) => {
    const {
      resource: { slug: employmentRequestSlug, submittedAt },
    } = data;
    return {
      ...data,
      title: 'Review an employee’s resignation',
      description: `They submitted their resignation on ${formatDayShortMonthYear(
        getDateNDaysBefore(submittedAt, 1)
      )}. Confirm some details to begin their offboarding.`,
      link: {
        href: {
          pathname: EMPLOYMENT_REQUESTS_ROUTE,
          query: {
            selectedTab: 'employee-requests',
            modal: 'validate-resignation-request',
            params: encodeUrlParams({ employmentRequestSlug }),
          },
        },
      },
      category: 'hr',
      Icon: IconV2DuotoneUserX,
    };
  },
  [TODO_TYPES_V2.REMOTE_PAYMENTS_SETUP_ADDITIONAL_INFO]: ({ data }) => {
    return {
      ...data,
      title: 'Finish setting up Remote Payments',
      description: `We need a few more details to finalize Remote Payments for ${data?.details?.entityName}.`,
      category: 'finance',
      Icon: IconV2DuotoneFilesText,
      onClick: () =>
        openModal({
          name: 'remote-payments-rfi',
          params: {
            entityType: data?.details?.entityType,
            entitySlug: data?.details?.entitySlug,
          },
        }),
      link: {
        href: {
          query: {
            modal: 'remote-payments-rfi',
            params: encodeUrlParams({
              entityType: data?.details?.entityType,
              entitySlug: data?.details?.entitySlug,
            }),
          },
        },
      },
    };
  },
  [TODO_TYPES_V2.SCHEDULED_CONTRACTOR_INVOICE_MISSING_WITHDRAWAL_METHOD]: ({ data }) => ({
    ...data,
    title: 'Select a withdrawal method to process scheduled invoices',
    description:
      'We cannot automatically process scheduled invoices until a withdrawal method is selected.',
    link: {
      href: {
        pathname: INVOICES_ROUTE,
        query: stringifyQuery({
          tab: invoicesTabs.SCHEDULED_INVOICES.value,
          filters: [
            {
              id: 'status',
              value: scheduledInvoiceStatuses.PENDING_CONTRACTOR_ACTION,
            },
          ],
        }),
      },
    },
    category: 'finance',
    Icon: IconV2DuotonePayrollOutputs,
  }),
  [TODO_TYPES_V2.STATUTORY_BENEFICIARY_REVIEW]: ({ data }) => {
    const {
      resource: { slug },
      details: { beneficiarySlug, blueprintReference },
    } = data;

    return {
      ...data,
      title: 'Confirm updated bank details for statutory payment beneficiaries',
      description:
        'A beneficiary of your statutory payments updated their bank details. Please confirm them to avoid payment delays.',
      Icon: IconV2DuotoneCreditCard,
      category: 'finance',
      link: {
        href: {
          pathname: LEGAL_ENTITIES_PROFILE_ROUTE,
          query: {
            slug,
            selectedTab: 'statutory-payments',
            modal: 'review-statutory-beneficiary',
            params: encodeUrlParams({
              legalEntitySlug: slug,
              beneficiarySlug,
              blueprintReference,
            }),
          },
        },
      },
    };
  },
  [TODO_TYPES_V2.LEGAL_ENTITY_REMOTE_PAYMENTS_SETUP_DETAILS]: ({ data }) => {
    return {
      ...data,
      title: 'Set up Remote Payments',
      description: 'Gain access to more payment methods and more efficient payment management.',
      category: 'finance',
      Icon: IconV2DuotoneShieldCheck,
      link: {
        href: {
          pathname: KYB_ONBOARDING_ROUTE.replace('[entityType]', 'legal').replace(
            '[entitySlug]',
            data.resource?.slug
          ),
        },
      },
    };
  },
  [TODO_TYPES_V2.LEGAL_ENTITY_BANK_ACCOUNT_MISSING]: ({ data }) => {
    const { name, slug } = data.resource;
    const { amount, currency } = data.details;

    return {
      ...data,
      title: `Add bank account for ${name} to receive a refund`,
      description: `You have made an overpayment of ${friendlyMoneyWithCurrencyCode(
        amount,
        currency,
        'right'
      )} that is due to be refunded`,
      link: {
        href: {
          pathname: LEGAL_ENTITIES_PROFILE_ROUTE,
          query: {
            selectedTab: 'details',
            scroll: true,
            slug,
            modal: 'legal-entity-payment-information',
            params: encodeUrlParams({
              legalEntitySlug: slug,
              legalEntityOnboardingTaskCompleted: false,
            }),
          },
        },
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCard,
    };
  },
  [TODO_TYPES_V2.SETUP_PAYMENT_METHOD]: ({ data }) => {
    const router = useRouter();
    return {
      ...data,
      title: 'Set up a payment method',
      description: 'This will avoid delaying invoice and contractor payments.',
      link: {
        href: getAddNewPaymentMethodRoute(router.asPath),
      },
      category: 'finance',
      Icon: IconV2DuotoneCreditCardPlus,
    };
  },
  [TODO_TYPES_V2.SETUP_TALENT_COMPANY_PROFILE]: ({ data }) => {
    return {
      ...data,
      title: 'Get started with Remote Talent',
      description: 'Create your company bio and start adding jobs.',
      link: {
        href: TALENT_BASE_ROUTE,
      },
      category: 'hr',
      Icon: IconV2DuotoneBuilding,
    };
  },
  [TODO_TYPES_V2.TEAM_PLAN_SHARED]: ({ data }) => {
    const {
      resource: { slug },
    } = data;

    return {
      ...data,
      title: 'Review and confirm your TEAM plan',
      description:
        'Your TEAM plan is ready for review! If the details look good, confirm it so you can start onboarding new hires. ',
      link: {
        href: { pathname: CONFIRM_TEAM_PLAN_ROUTE.replace('[slug]', slug) },
      },
      category: 'hr',
      Icon: IconV2DuotoneStructure,
    };
  },
  [TODO_TYPES_V2.TRAVEL_LETTER_REQUEST_MANAGER_APPROVAL]: ({ data }) => {
    const {
      resource: { employeeName, slug: requestSlug },
    } = data;
    return {
      ...data,
      title: `Review ${employeeName}'s travel letter request`,
      description: `Please take a moment to review the details and approve or decline their request.`,
      onClick: () =>
        openModal({
          name: 'travel-letter-approval-drawer',
          params: {
            requestSlug,
            employeeName,
          },
        }),
      category: 'hr',
      Icon: IconV2DuotoneFileCheck,
    };
  },
  [TODO_TYPES_V2.WITHDRAWAL_METHOD_REVIEW]: ({ data }) => ({
    ...data,
    title: 'Update your withdrawal method information',
    description: 'Something went wrong. Please take a moment to review and correct any mistakes.',
    link: {
      href: WITHDRAWAL_METHODS_ROUTE,
    },
    category: 'finance',
    Icon: IconV2DuotonePayrollOutputs,
  }),
  [TODO_TYPES_V2.REMOTE_WORK_REQUEST_MANAGER_APPROVAL]: ({ data }) => {
    const {
      resource: { employeeName, slug: requestSlug },
    } = data;
    return {
      ...data,
      title: 'A request needs your approval',
      description: `${employeeName} has submitted a request for working remotely from a location different than their place of residence. Please take a moment to review the details and approve or decline their request. `,
      onClick: () =>
        openModal({
          name: 'remote-work-approval-drawer',
          params: {
            requestSlug,
            employeeName,
          },
        }),
      category: 'hr',
      Icon: IconV2DuotoneFileCheck,
    };
  },
  [TODO_TYPES_V2.COMPLETE_COMPANY_SIGNUP]: ({ data }) => {
    return {
      ...data,
      title: 'Finish setting up your account to access all features',
      description:
        "To unlock all the benefits of Remote's HR platform, complete your company details.",
      category: 'hr',
      Icon: IconV2DuotoneEditBox,
      link: {
        href: COMPANY_COMPLETE_ONBOARDING,
      },
    };
  },
  [TODO_TYPES_V2.ADD_FIRST_ADMIN]: ({ data }) => {
    return {
      ...data,
      title: 'Add a company admin',
      description: 'Invite a co-worker to help manage your team with Remote.',
      category: 'hr',
      Icon: IconV2DuotoneUserPlus,
      link: {
        href: CUSTOMER_USERS_PERMISSIONS_ROUTE,
      },
    };
  },
  [TODO_TYPES_V2.TAX_DETAILS_MISSING]: ({ data }) => {
    return {
      ...data,
      title: 'Fill your tax details',
      description: 'We need you to complete your tax related details.',
      Icon: IconV2DuotoneFileCheck,
      category: 'hr',
      link: {
        href: TAX_COMPLIANCE_ROUTE,
      },
    };
  },
  [TODO_TYPES_V2.INVITE_FIRST_CLIENT]: ({ data }) => {
    return {
      ...data,
      title: 'Invite your first client',
      description: 'Send an invitation to your client to collaborate with them on Remote.',
      Icon: IconV2DuotoneUserPlus,
      category: 'hr',
      link: {
        href: {
          pathname: CLIENTS_ROUTE,
          query: { modal: 'client-modal' },
        },
      },
    };
  },
  [TODO_TYPES_V2.BENEFITS_RENEWAL]: ({ data }) => {
    const count = data?.resource?.count || 1;

    return {
      ...data,
      title: 'Select new benefits for your employees',
      description: `${getSingularPluralUnit(
        count,
        'new benefit package is available that requires',
        'new benefit packages are available that require',
        false,
        true
      )} your decision.`,
      link: {
        href: {
          pathname: BENEFITS_ROUTE,
          query: { selectedTab: 'renewals' },
        },
      },
      Icon: IconV2DuotoneHeart,
      category: 'benefits',
    };
  },
  [TODO_TYPES_V2.ADD_INTEGRATION]: ({ data }) => {
    const { integrationName } = data.details;

    const capitalizedIntegrationName = capitalize(integrationName);
    const description =
      {
        xero: 'Sync your invoices to Xero automatically',
      }[integrationName] || '';

    return {
      ...data,
      title: `Enable the ${capitalizedIntegrationName} integration`,
      description,
      link: {
        href: {
          pathname: INTEGRATIONS_NAME_ROUTE,
          query: { name: 'xero' },
        },
      },
      Icon: IconV2DuotoneStructurePlus,
      category: 'hr',
    };
  },
  [TODO_TYPES_V2.SETUP_2FA]: () => {
    return {
      title: 'Set up two-factor authentication (2FA)',
      description: 'This is required for you to log in to your account and take certain actions.',
      link: {
        href: {
          pathname: ACCOUNT_SETTINGS_ROUTE,
          query: { selectedTab: 'security' },
        },
      },
      Icon: IconV2DuotoneLock,
      category: 'authentication',
    };
  },
  [TODO_TYPES_V2.DEPOSIT_METHOD_REVIEW]: () => {
    return {
      title: 'Review your deposit methods',
      description:
        'Your deposit methods are now protected by two-factor authentication (2FA) to enhance your account’s security. This may require you to add your preferred deposit methods again.',
      Icon: IconV2DuotoneCreditCard,
      link: {
        href: DEPOSIT_METHODS_ROUTE,
      },
      category: 'finance',
    };
  },
  [TODO_TYPES_V2.SALARY_PAYMENT_FAILED]: ({ data }) => {
    const {
      resource: { amount, currency },
    } = data;

    return {
      title: 'Update your deposit method details',
      description: `Your salary payment in the amount of ${friendlyMoneyWithCurrencyCode(
        amount,
        currency
      )} failed because your deposit method details are incorrect. We’ll initiate a new payment attempt as soon as you update your details.`,
      Icon: IconV2DuotoneCreditCard,
      link: {
        href: DEPOSIT_METHODS_ROUTE,
      },
      category: 'finance',
    };
  },
  [TODO_TYPES_V2.GLOBAL_PAYROLL_SHORT_PAYMENT]: ({ data }) => {
    const {
      resource: { payrollRunSlug },
    } = data;

    return {
      title: 'Pay in funds for a payroll run',
      description: 'A payroll run does not have enough funds to pay employee salaries.',
      Icon: IconV2DuotoneCreditCard,
      link: {
        href: getFormattedURL(PAYROLL_RUN_DETAILS_ROUTE, {
          '[payrollSlug]': payrollRunSlug,
        }),
      },
      category: 'finance',
    };
  },
  [TODO_TYPES_V2.COMPANY_ACCOUNT_LINK_PENDING]: ({ data }) => {
    const {
      details: { count },
    } = data;

    const hasMoreThanOneAccount = count > 1;
    const accounts = hasMoreThanOneAccount ? 'accounts' : 'account';
    const accountsPronoun = hasMoreThanOneAccount ? 'these accounts' : 'this account';
    const accountsArticle = hasMoreThanOneAccount ? '' : 'an';

    return {
      title: 'Link Company Admin and Employee accounts',
      description: `We found ${count} company admin ${accounts} that must be linked to ${accountsArticle} employee ${accounts}. Please link ${accountsPronoun} to continue to be able to assign them as managers.`,
      Icon: IconV2OutlineUsers,
      link: {
        href: LINK_ACCOUNTS_ROUTE,
      },
      category: 'team',
    };
  },
  [TODO_TYPES_V2.PROOF_OF_PAYMENT_APPROVED]: ({ data }) => {
    const {
      details: {
        riskReserveType,
        employmentSlug,
        employeeName,
        invoiceReportSlug,
        billingDocumentSlug,
      },
    } = data;

    const isOnboardingReserve = riskReserveType === 'onboarding';

    return {
      ...data,
      title: 'We have verified your proof of payment.',
      description: isOnboardingReserve
        ? `You can now invite ${employeeName} to the platform`
        : `Thank you for your payment for ${employeeName}`,
      link: {
        href: isOnboardingReserve
          ? NEW_EMPLOYMENT_TYPE_SLUG_ROUTE.replace('[employmentSlug]', employmentSlug).replace(
              '[employmentType]',
              'full_time'
            )
          : getFormattedURL(BILLING_INVOICE_DETAILS, {
              '[slug]': invoiceReportSlug ?? billingDocumentSlug,
            }),
      },
      Icon: IconV2DuotoneCheckCircle,
      category: 'finance',
    };
  },
  [TODO_TYPES_V2.PROOF_OF_PAYMENT_REJECTED]: ({ data }) => {
    const {
      details: { employeeName, billingDocumentSlug, invoiceReportSlug },
    } = data;
    return {
      ...data,
      title: 'Please review your proof of payment.',
      description: `We need you to upload a new proof of payment for your ${employeeName}.`,
      link: {
        href: getFormattedURL(BILLING_INVOICE_DETAILS, {
          '[slug]': billingDocumentSlug ?? invoiceReportSlug,
        }),
        target: '_blank',
        rel: 'noopener',
      },
      Icon: IconV2DuotoneCheckCircle,
      category: 'finance',
    };
  },
  [TODO_TYPES_V2.SIGN_NEW_W9_TAX_FORM]: ({ data }) => {
    return {
      ...data,
      title: 'Sign a new W-9 to keep your tax records accurate',
      description: `As you recently updated your tax details, you’ll need to sign a new W-9 tax form.`,
      link: {
        href: getFormattedURL(FILL_FORM_EMPLOYEE_ROUTE, {
          '[slug]': 'W9',
        }),
      },
      Icon: IconV2DuotoneEditUnderline,
      category: 'hr',
    };
  },
  [TODO_TYPES_V2.SIGN_NEW_W8_BEN_TAX_FORM]: ({ data }) => {
    return {
      ...data,
      title: 'Sign a new W8-BEN to keep your tax records accurate',
      description: `Review and sign your W8-BEN form to keep your records accurate ahead of the tax season.`,
      link: {
        href: getFormattedURL(FILL_FORM_EMPLOYEE_ROUTE, {
          '[slug]': 'W8BEN',
        }),
      },
      Icon: IconV2DuotoneEditUnderline,
      category: 'hr',
    };
  },
  [TODO_TYPES_V2.SIGN_NEW_W8_BEN_E_TAX_FORM]: ({ data }) => {
    return {
      ...data,
      title: 'Sign a new W8-BEN-E to keep your tax records accurate',
      description: `Review and sign your W8-BEN-E form to keep your records accurate ahead of the tax season.`,
      link: {
        href: getFormattedURL(FILL_FORM_EMPLOYEE_ROUTE, {
          '[slug]': 'W8BENE',
        }),
      },
      Icon: IconV2DuotoneEditUnderline,
      category: 'hr',
    };
  },
  [TODO_TYPES_V2.MOBILE_APP]: ({ data }) => {
    const { slug } = data;
    return {
      ...data,
      onClick: () => openModal({ name: 'get-mobile-app-dialog', params: { slug } }),
      title: 'Get the Remote mobile app',
      description:
        'Manage your HR tasks on the go with the Remote mobile app and stay up to date with real-time notifications.',
      category: 'hr',
      Icon: IconV2DuotoneNotificationUnread,
    };
  },
  [TODO_TYPES_V2.SUBSCRIPTION_FAILED_PAYMENT]: FailedSubscriptionPaymentTodoBody,
  [TODO_TYPES_V2.CHECKLIST_ALBA]: ({ data }) => {
    return {
      ...data,
      title: 'Setup PEO',
      description: 'Setup US entity, Payroll, and confirm benefits.',
      link: {
        href: getFormattedURL(
          CUSTOMER_PRODUCT_ONBOARDING_CHECKLIST,
          {
            '[checklistShortIdentifier]': 'alba',
          },
          {
            checklistSlug: data?.resource.slug,
          }
        ),
      },
      category: 'hr',
      Icon: IconV2DuotoneGlobe,
    };
  },
};
