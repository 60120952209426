import Cookies from 'js-cookie';
import getConfig from 'next/config';

import type { featureFlags } from '@/next/featureFlagNames';
import { featureFlagNames } from '@/next/featureFlagNames';
import { getFlagCookieName } from '@/src/domains/remoteControlPanel/tools/feature-flags/utils';
import { debug } from '@/src/helpers/general';

const { publicRuntimeConfig } = getConfig();

const featuresFlagNamesSet = new Set(featureFlagNames);

type FeatureName = keyof typeof featureFlags;

/**
 * @deprecated Use the new Full-Stack Feature Flag system instead (https://www.notion.so/remotecom/Full-stack-Feature-Flags-16fcb4dadab48051b034d1be13d92f98)
 * @param {String} featureName - name of the feature (should be listed in featureFlagNames.js under src/config)
 * @returns {Boolean} wether feature flag is active or not
 */
export function isFlagActive(featureName: FeatureName): boolean {
  if (!featuresFlagNamesSet.has(featureName)) {
    debug(`Warning: Invalid feature flag: ${featureName}.`);
    return false;
  }

  const isDev = !['production', 'sandbox'].includes(publicRuntimeConfig.ENVIRONMENT);

  let cookieValue = null;

  if (isDev) {
    cookieValue = Cookies.get(getFlagCookieName(featureName));
  }

  if (cookieValue) {
    return cookieValue === 'true';
  }

  return !!publicRuntimeConfig[featureName];
}
