import { useQuery } from 'react-query';

import { queryClient } from '@/src/config/queryClient';
import { fetchAllCurrencies } from '@/src/hooks/useCurrenciesFetcher';

async function fetchData() {
  const { data } = await fetchAllCurrencies();
  return data;
}

/**
 * @param {import('react-query').QueryObserverOptions} options
 * @returns {import('react-query').UseQueryResult<Currency[]>}
 */
export default function useCurrencies(options = {}) {
  return useQuery(['fetchAllCurrencies'], fetchData, options);
}

export function useSelectCurrencies(options = {}) {
  const { data = [] } = useCurrencies(options);
  return data.map((item) => ({ label: item.code, value: item.slug }));
}

export function getCurrenciesForRemoteControlPanel() {
  const qKey = 'remoteControlPanelCurrencies';
  return queryClient.getQueryData([qKey]) || queryClient.fetchQuery([qKey], fetchData);
}
