import { IconV2DuotoneStars } from '@remote-com/norma/icons/IconV2DuotoneStars';
import React from 'react';

import type { API } from '@/src/api/config/api.types';
import { Button } from '@/src/components/Button/Button';
import type { ColumnType } from '@/src/components/Table/types.ts';
import { useModalContext } from '@/src/hooks/useModalContext';

import SmartSearch from './SmartSearch';

type Filter = API.RemoteAi.GenerateSmartSearchFilter.Response['data']['filters'];

export const SmartSearchButton: React.FC<{
  apiPath: string;
  setAllFilters?: (filters: Filter) => void;
  transformFilters: (filters: Filter) => Filter;
  setGlobalFilter?: (filter: string) => void;
  columns: Array<ColumnType>;
  currentFilters: Filter;
  tableName: string;
}> = ({
  setGlobalFilter,
  apiPath,
  setAllFilters,
  transformFilters,
  columns,
  currentFilters,
  tableName,
}) => {
  const { showModal, hideModal } = useModalContext();

  return (
    <Button
      tone="secondary"
      variant="outline"
      size="sm"
      id={`smart-search-${tableName}`}
      IconBefore={IconV2DuotoneStars}
      onClick={() => {
        showModal({
          component: SmartSearch,
          modalProps: {
            onClose: hideModal,
            apiPath,
            setAllFilters,
            transformFilters,
            columns,
            setGlobalFilter,
            currentFilters,
          },
        });
      }}
    >
      Smart Search
    </Button>
  );
};
