import { InputSelect } from '@remote-com/norma';
import { Field } from 'formik';
import { useRef, forwardRef } from 'react';

import { useSelectReferenceOnRemoteControlPanel } from '@/src/components/Ui/Form/useSelectReferenceOnRemoteControlPanel';
import { error as captureError } from '@/src/helpers/general';

/**
 * SelectField
 *
 * Formik input field abstraction, containing label, description and field error
 *
 * @export
 * @type {import('@/src/components/Form/DynamicForm/types').RenderSelectFieldForwardRefType}
 * @return {JSX.Element}
 */
export const SelectField = forwardRef(
  (
    {
      name,
      label,
      description,
      multiple,
      options = [],
      transformValue,
      normalizeComparison = true,
      maxHeight,
      preventJump,
      onChange,
      withSearchIcon,
      isControlled = false,
      isClearable,
      clearValue = () => null,
      'data-testid': dataTestid,
      displayLabel,
      extra,
      exclusiveOption = 'no',
      enableSetFieldValue = true,
      ...props
    },
    ref
  ) => {
    const fallbackRef = useRef();
    const selectRef = ref || fallbackRef;

    useSelectReferenceOnRemoteControlPanel(selectRef, options, multiple);

    return (
      <Field name={name}>
        {({
          field: { value },
          form: { setFieldValue, setFieldTouched },
          meta: { error, touched },
        }) => {
          function getValueForAction(selectedOption, meta) {
            if (meta.action === 'clear') {
              return clearValue(selectedOption);
            }

            if (!multiple) {
              return selectedOption;
            }

            // If exclusiveOption was we have to make sure only it is returned,
            // Otherwise return everything BUT exclusiveOption
            return meta.option?.value === exclusiveOption
              ? (selectedOption || []).filter((option) => option.value === exclusiveOption)
              : (selectedOption || []).filter((option) => option.value !== exclusiveOption);
          }

          function setValue(selectedOption, newValue, meta) {
            const { action } = meta;

            if (action === 'clear') {
              setFieldValue(name, newValue);
            } else {
              setFieldValue(name, transformValue ? transformValue(selectedOption) : selectedOption);
            }
          }

          // Allow transforming field option before setting
          function handleChange(selectedOption, meta = {}) {
            const newValue = getValueForAction(selectedOption, meta);

            if (enableSetFieldValue) {
              setValue(selectedOption, newValue, meta);
            }

            if (onChange) {
              const previousValue = value;
              onChange(newValue, meta, previousValue);
            }
          }

          /**
           * Find default value in options list
           * There are different type of received values, as a list of objects,
           * list of strings where we need to find the full option object.
           */
          function getDefaultValue() {
            if (multiple && Array.isArray(value)) {
              return value
                .map((eachValue) => {
                  if (typeof eachValue === 'object') return eachValue;

                  return options.reduce((acc, option) => {
                    // if options are grouped, we have to check each of them to find the selected ones
                    if (Array.isArray(option.options)) {
                      const optionFound = option.options.find(
                        (groupedOption) => groupedOption.value === eachValue
                      );

                      if (optionFound) {
                        return [...acc, optionFound];
                      }
                    }
                    // otherwise, just return the found option if the case
                    if (option.value === eachValue) {
                      return [...acc, option];
                    }

                    return acc;
                  }, []);
                })
                .flatMap((v) => v); // flattening it as above a nested collection might be returned
            }

            const isOptionsArray = Array.isArray(options);

            if (options && !isOptionsArray) {
              captureError(
                new Error(
                  `SelectField error: field ${name} - options must be an Array instead of "${typeof options}". Please fix this.`
                )
              );
            }

            if (
              transformValue &&
              isOptionsArray &&
              // default to {} in case options are async (empty array)
              typeof transformValue(options[0] || {}) === 'object'
            ) {
              // We can not try to get the default value (option) if the transformedValue is an object.
              // The selected value must be a string. Related to !5667
              return value;
            }

            const hasGroupedOptions = options.some((group) => group?.options);

            if (transformValue && isOptionsArray && hasGroupedOptions) {
              let optionFound;

              // iterate over group of options
              options.forEach((group) => {
                // iterate over each option in the group
                const foundOnGroup = group?.options?.find((groupedOption) => {
                  const transformedValue = transformValue(groupedOption);

                  if (normalizeComparison && value) {
                    return (transformedValue || '').toString() === (value || '').toString();
                  }
                  return transformedValue === value;
                });

                // if found, set it to be returned
                if (foundOnGroup) {
                  optionFound = foundOnGroup;
                }
              });

              return optionFound;
            }

            return transformValue && isOptionsArray
              ? options?.find((option) => {
                  const transformedValue = transformValue(option);
                  // TODO: Why do we need this "normalization"? (1 year old).
                  // Should we take the risk and remove it? Maybe in another MR...
                  if (normalizeComparison && value) {
                    return (transformedValue || '').toString() === (value || '').toString();
                  }
                  return transformedValue === value;
                })
              : value;
          }

          const defaultValue = getDefaultValue();

          // Defines if input is controlled or not
          // Useful and relevant when displaying default value and filling options list after component is rendered
          // Without isControlled, the default value is set and is not displayed after the options list is filled
          // This could cause unintended side effects when providing an object as the default value, so that's why it exists as a new prop.
          // Also needed when used in the FieldGroupArray, so that fields value display correctly when fields are removed.
          const valueProps = isControlled
            ? {
                // undefined value doesn't clear input label
                value: typeof defaultValue === 'undefined' ? '' : defaultValue,
              }
            : { defaultValue };

          function handleOnBlur() {
            // if you have a better way of doing this, please let me know. Joe
            // manually trigger the validation error message
            setFieldTouched(name, true);
            if (props?.onBlur) {
              props.onBlur();
            }
          }

          return (
            <InputSelect
              name={name}
              label={label}
              aria-label={label}
              description={description}
              errorText={error && touched ? error : ''}
              extra={extra}
              isClearable={isClearable}
              multiple={multiple}
              clearValue={clearValue}
              onBlur={handleOnBlur}
              onChange={handleChange}
              options={options}
              ref={selectRef}
              disabled={props.isDisabled}
              readOnly={props.isDisabled}
              {...valueProps}
              {...props}
            />
          );
        }}
      </Field>
    );
  }
);
