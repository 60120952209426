import kebabCase from 'lodash/kebabCase';
import type { NextRouter } from 'next/router';
import { openModal } from 'react-url-modal';

import {
  DASHBOARD_COST_CALCULATOR_ROUTE,
  COMPENSATION_BENCHMARKING_ROUTE,
  MARKETPLACE_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  INCENTIVES_ROUTE,
} from '@/src/constants/routes';
import {
  NAVATTIC_HRIS_MOBILE_APP_FLOW_ID,
  NAVATTIC_TIME_OFF_FLOW_ID,
  NAVATTIC_EOR_EMPLOYEE_FLOW_ID,
  NAVATTIC_CONTRACTOR_ONBOARDING,
  NAVATTIC_PAYMENT_METHODS,
  NAVATTIC_DIRECT_EMPLOYEE_FLOW_ID,
  NAVATTIC_GLOBAL_PAYROLL_EMPLOYEE_FLOW_ID,
  NAVATTIC_DIRECT_BULK,
  NAVATTIC_EMPLOYER_DASHBOARD,
  NAVATTIC_ROLES,
  NAVATTIC_EOR_TOUR,
  NAVATTIC_CONTRACTOR_MANAGEMENT,
  NAVATTIC_EMPLOYEE_EXPERIENCE,
  NAVATTIC_TALENT_INSIGHTS_DEMO,
  NAVATTIC_JOBS_CURRENT_USER_DEMO,
  NAVATTIC_JOBS_NEW_USER_DEMO,
  NAVATTIC_BILLING_DEMO,
  NAVATTIC_BENEFITS_DEMO,
} from '@/src/domains/navattic/helpers';
import type { NavatticModalParams } from '@/src/domains/navattic/NavatticModal';
import {
  COST_CALCULATOR_USER_PROMPT,
  COMPENSATION_EXPLORER_PROMPT,
  VIEW_MARKETPLACE_PROMPT,
  TIME_OFF_PROMPT,
  ADD_EOR_EMPLOYEE_PROMPT,
  HRIS_MOBILE_APP_PROMPT,
  REFERRAL_PROGRAM_PROMPT,
  CONTRACTOR_ONBOARDING_PROMPT,
  CONTRACTOR_PAYMENT_METHODS_PROMPT,
  ADD_DIRECT_EMPLOYEE_PROMPT,
  ADD_GLOBAL_PAYROLL_EMPLOYEE_PROMPT,
  ADMIN_ROLES_PERMISSION,
  BOOK_GLOBAL_PAYROLL_DEMO,
  BULK_ADD_DIRECT_EMPLOYEE,
  EMPLOYER_DASHBOARD,
  VIEW_CONTRACTOR_MANAGEMENT_TOUR,
  VIEW_EMPLOYEE_EXPERIENCE_TOUR,
  VIEW_EOR_TOUR,
  VIEW_TALENT_INSIGHTS_TOUR,
  VIEW_CANDIDATES_TOUR,
  VIEW_JOBS_TOUR,
  VIEW_BILLING_DEMO,
  VIEW_BENEFITS_DEMO,
  INCENTIVES_PROMPT,
} from '@/src/domains/userPrompts/constants';
import type { NotRequiredUserPromptAction } from '@/src/domains/userPrompts/constants';
import type { CompletedViewSegment } from '@/src/domains/userPrompts/pastPromptsDrawer/PastPromptsDrawer';

type CallToActionComponent = {
  component: React.ReactNode;
  onCompletedViewClick: (router: NextRouter) => void;
};
type CallToActionButtonBase = {
  text: string;
  completePromptOnClick: boolean;
};
type CallToActionButtonClick = CallToActionButtonBase & { onClick: () => void };
type CallToActionButtonRedirect = CallToActionButtonBase & {
  href: string;
  trackNavigation: string[];
  openInNewTab?: boolean;
};
export type CallToActionButton = CallToActionButtonClick | CallToActionButtonRedirect;

export type CallToAction = CallToActionComponent | CallToActionButton;

export type UserPrompt = {
  title: string;
  subtitle?: string;
  content: React.ReactElement | string;
  canBeDismissed: boolean;
  callToAction: CallToAction;
  completedView: {
    title: string;
    description: string;
    segment: CompletedViewSegment;
  };
} & Pick<NavatticModalParams, 'updateAcrossAllProfiles'>;

export const userPromptCards: Record<NotRequiredUserPromptAction, UserPrompt> = {
  [COST_CALCULATOR_USER_PROMPT]: {
    title: 'Cost calculator',
    subtitle: 'Explore the cost to hire employees',
    content:
      'Make more effective budgets for your global team. Discover how local benefits impact an employee’s total compensation.',
    canBeDismissed: true,
    callToAction: {
      text: 'Use the cost calculator',
      href: `${DASHBOARD_COST_CALCULATOR_ROUTE}?onboarding=true`,
      trackNavigation: ['homepage', 'cost-calculator'],
      completePromptOnClick: false,
    },
    completedView: {
      title: 'Cost calculator',
      description: 'Explore the cost to hire employees',
      segment: 'employer',
    },
  },
  [COMPENSATION_EXPLORER_PROMPT]: {
    title: 'Salary explorer',
    subtitle: 'Compare global salary averages',
    content:
      'Use insights across countries, job titles, and seniority levels to make competitive offers to candidates.',
    canBeDismissed: true,
    callToAction: {
      text: 'Use the salary explorer',
      href: COMPENSATION_BENCHMARKING_ROUTE,
      trackNavigation: ['homepage', 'compensation-explorer'],
      completePromptOnClick: false,
    },
    completedView: {
      title: 'Salary explorer',
      description: 'Compare global salary averages',
      segment: 'employer',
    },
  },
  [VIEW_MARKETPLACE_PROMPT]: {
    title: 'Marketplace',
    subtitle: 'Find trusted partners for a low cost',
    content:
      'Our trusted partner apps and integrations increase team productivity, enhance the employee onboarding experience, and more.',
    canBeDismissed: true,
    callToAction: {
      text: 'Explore the Marketplace',
      href: MARKETPLACE_ROUTE,
      trackNavigation: ['homepage', 'explore-marketplace'],
      completePromptOnClick: true,
    },
    completedView: {
      title: 'Marketplace',
      description: 'Find trusted partners for a low cost',
      segment: 'employer',
    },
  },
  [TIME_OFF_PROMPT]: {
    title: 'Time off',
    subtitle: 'Handle your team’s time off with ease',
    content:
      'When employees request time off, you can easily review their requests and see who’s out at a glance.',
    canBeDismissed: true,
    callToAction: {
      text: 'See the time off tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_TIME_OFF_FLOW_ID,
            promptToComplete: TIME_OFF_PROMPT,
            onCancelTrackingData: {
              elementName: kebabCase(TIME_OFF_PROMPT),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Time off',
      description: 'Handle your team’s time off with ease',
      segment: 'employer',
    },
  },
  [HRIS_MOBILE_APP_PROMPT]: {
    title: 'Employee mobile app',
    subtitle: 'Elevate employee autonomy on the go',
    content:
      'Check out the Remote app that makes it easy for your employees to manage their expenses, time-off, time tracking on the go.',
    canBeDismissed: true,
    callToAction: {
      text: 'See the mobile app demo',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_HRIS_MOBILE_APP_FLOW_ID,
            promptToComplete: HRIS_MOBILE_APP_PROMPT,
            onCancelTrackingData: {
              elementName: kebabCase(HRIS_MOBILE_APP_PROMPT),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Employee mobile app',
      description: 'Check out the Remote app for employees',
      segment: 'employee',
    },
  },
  [REFERRAL_PROGRAM_PROMPT]: {
    title: 'Referral program',
    subtitle: 'Refer a friend, earn up to $300 USD',
    content:
      'Get a referral link and invite a friend to use Remote. After they sign up and start using the platform, you’ll earn your reward!',
    canBeDismissed: true,
    callToAction: {
      text: 'See how it works',
      href: `${ACCOUNT_SETTINGS_ROUTE}?selectedTab=referral-program`,
      trackNavigation: ['homepage', 'referral-program'],
      completePromptOnClick: true,
    },
    completedView: {
      title: 'Referral program',
      description: 'Refer a friend, earn up to $300 USD',
      segment: 'employer',
    },
  },
  [ADD_EOR_EMPLOYEE_PROMPT]: {
    title: 'Adding an EOR employee',
    subtitle: 'See how easy it is to invite your first hire',
    content: 'Learn how a person is employed by Remote’s entity in their country of residence.',
    canBeDismissed: true,
    callToAction: {
      text: 'Begin the add hire tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_EOR_EMPLOYEE_FLOW_ID,
            promptToComplete: ADD_EOR_EMPLOYEE_PROMPT,
            onCancelTrackingData: {
              elementName: kebabCase(ADD_EOR_EMPLOYEE_PROMPT),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Adding an EOR employee',
      description: 'See how easy is to invite your first hire',
      segment: 'employer',
    },
  },
  [CONTRACTOR_ONBOARDING_PROMPT]: {
    title: 'Contractor onboarding',
    subtitle: 'Effortless contractor management',
    content:
      'Onboarding international contractors in more than 200 countries and jurisdictions is simple, fast and compliant.',
    canBeDismissed: true,
    callToAction: {
      text: 'See how it works',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_CONTRACTOR_ONBOARDING,
            promptToComplete: CONTRACTOR_ONBOARDING_PROMPT,
            onCancelTrackingData: {
              elementName: kebabCase(CONTRACTOR_ONBOARDING_PROMPT),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Contractor onboarding',
      description: 'See how onboarding contractors works',
      segment: 'employer',
    },
  },
  [CONTRACTOR_PAYMENT_METHODS_PROMPT]: {
    title: 'Payments',
    subtitle: 'Simple, seamless payments',
    content:
      'Set up your payment methods so you can pay invoices with ease. Add a variety of account types based on your company’s preferences.',
    canBeDismissed: true,
    callToAction: {
      text: 'See the payments tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_PAYMENT_METHODS,
            promptToComplete: CONTRACTOR_PAYMENT_METHODS_PROMPT,
            onCancelTrackingData: {
              elementName: kebabCase(CONTRACTOR_PAYMENT_METHODS_PROMPT),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Payments',
      description: 'Set up your payment methods and pay invoices with ease',
      segment: 'employer',
    },
  },
  [ADD_DIRECT_EMPLOYEE_PROMPT]: {
    title: 'Add your first employee',
    subtitle: 'Add your first employee',
    content:
      "It's easy to add and invite your first employee(s) to join you on Remote; let us show you how.",
    canBeDismissed: true,
    callToAction: {
      text: 'View the add direct employee tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_DIRECT_EMPLOYEE_FLOW_ID,
            onCancelTrackingData: {
              elementName: kebabCase(ADD_DIRECT_EMPLOYEE_PROMPT),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Add your first employee',
      description:
        "It's easy to add and invite your first employee(s) to join you on Remote; let us show you how.",
      segment: 'employer',
    },
  },
  [ADD_GLOBAL_PAYROLL_EMPLOYEE_PROMPT]: {
    title: 'Adding an employee',
    subtitle: 'Add a payroll employee',
    content: 'See how easy it is to add your first employee for payroll.',
    canBeDismissed: true,
    callToAction: {
      text: 'Begin the add employee tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_GLOBAL_PAYROLL_EMPLOYEE_FLOW_ID,
            promptToComplete: ADD_GLOBAL_PAYROLL_EMPLOYEE_PROMPT,
            onCancelTrackingData: {
              elementName: kebabCase(ADD_GLOBAL_PAYROLL_EMPLOYEE_PROMPT),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Adding an EOR employee',
      description: 'Add a payroll employee',
      segment: 'employer',
    },
  },
  [BOOK_GLOBAL_PAYROLL_DEMO]: {
    title: 'Need payroll services?',
    subtitle: 'Manage payroll with Remote',
    content:
      'You can save time and money when you manage payroll with Remote — and it’s now available in countries around the world.',
    canBeDismissed: true,
    callToAction: {
      text: 'Book a Global Payroll Demo',
      href: `https://remote.com/?openForm=schedule-demo`,
      trackNavigation: ['homepage', 'book-global-payroll-demo'],
      completePromptOnClick: true,
      openInNewTab: true,
    },
    completedView: {
      title: 'Manage payroll with Remote',
      description: 'You can save time and money when you manage payroll with Remote',
      segment: 'employer',
    },
  },
  [BULK_ADD_DIRECT_EMPLOYEE]: {
    title: 'Add employees in bulk',
    subtitle: 'Invite several employees in bulk',
    content:
      'Are you bringing several employees to Remote from spreadsheets or another HR software? Learn how to add and invite them in bulk to save time. ',
    canBeDismissed: true,
    callToAction: {
      text: 'View the bulk employee upload tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_DIRECT_BULK,
            promptToComplete: BULK_ADD_DIRECT_EMPLOYEE,
            onCancelTrackingData: {
              elementName: kebabCase(BULK_ADD_DIRECT_EMPLOYEE),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Add employees in bulk',
      description:
        'Are you bringing several employees to Remote from spreadsheets or another HR software? Learn how to add and invite them in bulk to save time. ',
      segment: 'employer',
    },
  },
  [EMPLOYER_DASHBOARD]: {
    title: 'Employer Dashboard',
    subtitle: 'Global HR insights and to-dos',
    content:
      'After bringing your team into the Remote platform, the Dashboard becomes your single source of global HR insights and to-dos',
    canBeDismissed: true,
    callToAction: {
      text: 'View the dashboard tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_EMPLOYER_DASHBOARD,
            promptToComplete: EMPLOYER_DASHBOARD,
            onCancelTrackingData: {
              elementName: kebabCase(EMPLOYER_DASHBOARD),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Employer Dashboard',
      description: 'Global HR insighs and to-dos',
      segment: 'employer',
    },
  },
  [ADMIN_ROLES_PERMISSION]: {
    title: 'Admins and Permissions',
    subtitle: 'Structure your teams',
    content:
      'We’ve made it easier than ever to structure your teams, people managers, and their direct reports.',
    canBeDismissed: true,
    callToAction: {
      text: 'View the roles and permissions tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_ROLES,
            promptToComplete: ADMIN_ROLES_PERMISSION,
            onCancelTrackingData: {
              elementName: kebabCase(ADMIN_ROLES_PERMISSION),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Admins and permissions',
      description: 'Structure your teams, people managers and reports.',
      segment: 'employer',
    },
  },
  [VIEW_CONTRACTOR_MANAGEMENT_TOUR]: {
    title: 'Do you work with contractors?',
    subtitle: 'Manage contractors in more than 200 countries and jurisdictions',
    content:
      'Remote Contractor Management makes it effortless to onboard, manage, and pay contractors in more than 200 countries and jurisdictions around the world.',
    canBeDismissed: true,
    callToAction: {
      text: 'View the Contractor Management tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_CONTRACTOR_MANAGEMENT,
            promptToComplete: VIEW_CONTRACTOR_MANAGEMENT_TOUR,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_CONTRACTOR_MANAGEMENT_TOUR),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Do you work with contractors?',
      description:
        'Onboard, manage and pay contractors in more than 200 countries and jurisdictions',
      segment: 'contractor',
    },
  },
  [VIEW_EMPLOYEE_EXPERIENCE_TOUR]: {
    title: 'What can your employees expect?',
    subtitle: 'Your employees experience after being invited',
    content:
      'From the moment you add and invite your employees to Remote, they’ll be guided, cared for, and empowered to take action. We recommend you share this one with them as well.',
    canBeDismissed: true,
    callToAction: {
      text: 'View the employee experience tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_EMPLOYEE_EXPERIENCE,
            promptToComplete: VIEW_EMPLOYEE_EXPERIENCE_TOUR,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_EMPLOYEE_EXPERIENCE_TOUR),
            },
          },
        });
      },
    },
    completedView: {
      title: 'What can your employees expect?',
      description: 'Your employees experience after being invited',
      segment: 'employee',
    },
  },
  [VIEW_EOR_TOUR]: {
    title: 'Need to hire where you don’t have an entity?',
    subtitle: '',
    content:
      'Use Remote Employer of Record — your passport to easily hire, manage, and pay the best talent no matter where in the world they live.',
    canBeDismissed: true,
    callToAction: {
      text: 'View the Employer of Record tour',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_EOR_TOUR,
            promptToComplete: VIEW_EOR_TOUR,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_EOR_TOUR),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Hire where you don’t have an entity',
      description: 'Easily hire, manage and pay the best talent no matter where.',
      segment: 'employer',
    },
  },
  [VIEW_TALENT_INSIGHTS_TOUR]: {
    title: 'Discover the latest hiring trends',
    subtitle: '',
    content:
      'Use talent insights to compare candidate pools, estimated total costs, and salary benchmarks across different job titles and countries.',
    canBeDismissed: true,
    callToAction: {
      text: 'Watch the demo',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_TALENT_INSIGHTS_DEMO,
            promptToComplete: VIEW_TALENT_INSIGHTS_TOUR,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_TALENT_INSIGHTS_TOUR),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Talent Insights',
      description: 'Discover the latest hiring trends',
      segment: 'employer',
    },
  },
  [VIEW_JOBS_TOUR]: {
    title: 'Post jobs and track applications',
    subtitle: '',
    content:
      'Write job posts, share them online, and keep track of candidates’ progress through their application and interview process.',
    canBeDismissed: true,
    callToAction: {
      text: 'Watch the demo',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_JOBS_CURRENT_USER_DEMO,
            promptToComplete: VIEW_JOBS_TOUR,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_JOBS_TOUR),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Jobs',
      description: 'Post jobs and track applications',
      segment: 'employer',
    },
  },
  [VIEW_CANDIDATES_TOUR]: {
    title: 'Source candidates for your jobs',
    subtitle: '',
    content:
      'Use candidate sourcing tools to find people who have skills and experience to match the open jobs at your company.',
    canBeDismissed: true,
    callToAction: {
      text: 'Watch the demo',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_JOBS_NEW_USER_DEMO,
            promptToComplete: VIEW_CANDIDATES_TOUR,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_CANDIDATES_TOUR),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Candidates',
      description: 'Source candidates for your jobs',
      segment: 'employer',
    },
  },
  [VIEW_BILLING_DEMO]: {
    title: 'Manage your billing',
    subtitle: '',
    content:
      'Go to the billing hub to see all of your outgoing payments, including your future payroll runs and contractor invoices.',
    canBeDismissed: true,
    callToAction: {
      text: 'Watch the demo',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_BILLING_DEMO,
            promptToComplete: VIEW_BILLING_DEMO,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_BILLING_DEMO),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Billing',
      description: 'Manage your billing',
      segment: 'employer',
    },
  },
  [VIEW_BENEFITS_DEMO]: {
    title: 'Set up employee benefits',
    subtitle: '',
    content:
      'Choose which benefits will be available to employees working in different departments, countries, or regions.',
    canBeDismissed: true,
    callToAction: {
      text: 'Watch the demo',
      completePromptOnClick: true,
      onClick: () => {
        openModal({
          name: 'navattic-modal',
          params: {
            iframeId: NAVATTIC_BENEFITS_DEMO,
            promptToComplete: VIEW_BENEFITS_DEMO,
            onCancelTrackingData: {
              elementName: kebabCase(VIEW_BENEFITS_DEMO),
            },
          },
        });
      },
    },
    completedView: {
      title: 'Benefits',
      description: 'Set up employee benefits',
      segment: 'employer',
    },
  },
  [INCENTIVES_PROMPT]: {
    title: 'Establish hiring incentives',
    subtitle: '',
    content:
      'Create, track and approve the bonuses and other incentives that employees can receive when joining your company.',
    canBeDismissed: true,
    callToAction: {
      text: 'Watch the demo',
      href: `${INCENTIVES_ROUTE}?selectedTab=upcoming`,
      trackNavigation: ['homepage', 'incentives'],
      completePromptOnClick: true,
      openInNewTab: true,
    },
    completedView: {
      title: 'Incentives',
      description: 'Establish hiring incentives',
      segment: 'employer',
    },
  },
};

export type UserPromptCard = typeof userPromptCards;

export function isCallToActionComponent(
  callToAction: CallToAction
): callToAction is CallToActionComponent {
  return (callToAction as CallToActionComponent).component !== undefined;
}

export function isCallToActionButtonClick(
  callToAction: CallToAction
): callToAction is CallToActionButtonClick {
  return (callToAction as CallToActionButtonClick).onClick !== undefined;
}
