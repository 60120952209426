import type { AvatarTone } from '@remote-com/norma';
import { createContext, useContext } from 'react';

import type { API } from '@/src/api/config/api.types';
import type { EmployerCompany } from '@/src/api/config/employ/company.types';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import {
  isAdmin,
  isEmployee,
  isEmployer,
  isContractor,
} from '@/src/domains/registration/auth/helpers';

export type Login = {
  loginWithPassword: API.LoginAccountResponse['data']['logins'] | undefined;
  loginWithSSO: API.LoginAccountResponse['data']['logins'] | undefined;
};

export type UserAuthData = {
  email: string;
  password: string;
  isInternalUser: boolean;
  originPathname: string;
};

export type UserContextUser = UserAccountResponse & {
  companyData?: API.Employer.Company.CompanyShowResponse;
  initialsColor?: AvatarTone;
  isOnMacOS?: boolean;
  userCache?: {
    invoicesCount?: number;
    payslipsCount?: number;
    incentivesCount?: number;
    directReportsCount?: number;
    companyData?: Partial<EmployerCompany>;
    handbookId?: number | string;
  };
};

const UserContext = createContext<{
  user?: UserContextUser;
  loginMethods?: Login;
  userAuthData?: UserAuthData;
  refreshUser: () => void;
  setUserAuthData?: (data: UserAuthData) => void;
  isLoading?: boolean;
}>({
  user: undefined,
  refreshUser: () => {},
});

export const useUser = () => {
  const { user, loginMethods, refreshUser } = useContext(UserContext);
  const userIsAdmin = isAdmin(user);
  const userIsEmployer = isEmployer(user);
  const userIsEmployee = isEmployee(user);
  const userIsContractor = isContractor(user);
  return {
    user,
    userIsAdmin,
    userIsEmployer,
    userIsEmployee,
    userIsContractor,
    loginMethods,
    refreshUser,
  };
};

export default UserContext;
