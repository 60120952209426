import { useGet } from '@remote-com/data-layer';
// eslint-disable-next-line remote/prefer-using-the-data-layer
import { useQuery } from 'react-query';

import { useUserCan } from '@/src/components/UserCan';
import { isFullTime, isGlobalPayroll } from '@/src/domains/employment/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { fetchUser } from '@/src/services/Admin';

const useBasicEmployeeDetailsQueryKey = 'fetch-basic-employee-data';

export const useBasicEmployeeDetails = (userSlug, config = {}) =>
  useQuery(
    [useBasicEmployeeDetailsQueryKey, userSlug],
    () => fetchUser({ pathParams: { slug: userSlug } }),
    {
      select: ({ data }) => data,
      ...config,
      enabled: Boolean(userSlug),
    }
  );

export const usePotentialDuplicates = (user, employment) => {
  const { userCan } = useUserCan();

  const userCanReadPotentialDuplicates =
    userCan('read', Resources.employees) &&
    userCan('read', Resources.users) &&
    userCan('read', Resources.employee_personal_details) &&
    userCan('read', Resources.employee_sensitive_personal_details);

  return useGet('/api/v1/rivendell/users/[userSlug]/potential-duplicates', {
    params: {
      pathParams: {
        userSlug: user.slug,
      },
    },
    options: {
      enabled:
        !!user?.slug &&
        employment &&
        isFullTime(employment) &&
        !isGlobalPayroll(employment) &&
        userCanReadPotentialDuplicates,
      select: (res) => res.data,
    },
  });
};
