import { selfServiceReset2FAEvents, trackEvent } from '@remote-com/analytics';
import { FeedbackMessage, FEEDBACK_MESSAGE_ERROR, Text } from '@remote-com/norma';
import { useRouter } from 'next/router';

import { ButtonInline, StandaloneLink } from '@/src/components/Button';
import { useUser } from '@/src/components/UserProvider/context';
import { PERSONA_2FA_VERIFY_ROUTE } from '@/src/constants/routes';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import {
  INCORRECT_EMAIL_OR_PASSWORD,
  LOCKED_ACCOUNT,
} from '@/src/domains/registration/auth/constants';
import { ContactRemoteSupportButton } from '@/src/domains/registration/shared/ContactRemoteSupportLink';
import { useSignOut } from '@/src/hooks/useSignOut';

export const TwoFactorHelpSection = ({
  isAccountLocked = false,
  enable2FAReset = false,
  hideHelpSection = false,
}) => {
  const signout = useSignOut();
  const { query } = useRouter();
  const { user } = useUser();
  const isSelfService2FAFlagEnabled = useIsFeatureFlagEnabled('self_service_2fa_reset');
  const queryString = query?.token ? `?token=${query.token}` : '';

  // @iam-team -> We currently actively developing 2FA self service reset feature. We are allowing this feature to be used by internal Remoter users. At the moment such condition along with the feature flag check is required for this feature to be enabled.
  const selfService2FAEnabled = isSelfService2FAFlagEnabled && enable2FAReset;

  // user is SCA user setting up their 2fa for the first time, so we dont show ability to reset it. The only option here should be to contact support if they do not know what to do.
  if (!user && hideHelpSection) {
    return (
      <Text variant="sm" textAlign="center">
        <Text variant="sm">
          Need help? <ContactRemoteSupportButton is2FASetupCompleted />
        </Text>
      </Text>
    );
  }

  if (selfService2FAEnabled && !isAccountLocked) {
    return (
      <Text mb={8} variant="sm" textAlign="center">
        Don't have your authenticator app?{' '}
        <ButtonInline
          onClick={() => {
            // need to determine location of action, is it adding account or sign in. If we do not have user, assumption is made that we are in sign in flows because we have no session active, otherwise we are logged in and adding account
            if (!user) {
              trackEvent(selfServiceReset2FAEvents.RESET_2FA_CLICK('sign-in'));
            }
            trackEvent(selfServiceReset2FAEvents.RESET_2FA_CLICK('add-account'));
          }}
          href={`${PERSONA_2FA_VERIFY_ROUTE}${queryString}`}
        >
          Reset 2FA
        </ButtonInline>
      </Text>
    );
  }

  // remove this when flag is enabled in prod
  if (!isSelfService2FAFlagEnabled) {
    return (
      <Text mb={8} variant="sm" textAlign="center">
        Need help? <ContactRemoteSupportButton is2FASetupCompleted />
      </Text>
    );
  }

  return (
    <Text mb={8} variant="sm" textAlign="center">
      Don't have your authenticator app?{' '}
      <StandaloneLink onClick={() => signout()}>Log out</StandaloneLink> and reset 2FA after
      entering your credentials
    </Text>
  );
};

export const AuthErrorMessage = ({
  code,
}: {
  code: typeof LOCKED_ACCOUNT | typeof INCORRECT_EMAIL_OR_PASSWORD;
}) => {
  const errorMessages: {
    [key in typeof LOCKED_ACCOUNT | typeof INCORRECT_EMAIL_OR_PASSWORD]: {
      title: string;
      message: string;
    };
  } = {
    [LOCKED_ACCOUNT]: {
      title: 'Account locked due to failed login attempts',
      message: 'To recover your account, check your email',
    },
    [INCORRECT_EMAIL_OR_PASSWORD]: {
      title: "We don't recognize that email or password",
      message: ' Check your credentials and try again.',
    },
  };

  const errorMessage = errorMessages[code];
  if (!errorMessage) return null;

  return (
    <FeedbackMessage title={errorMessage.title} variant={FEEDBACK_MESSAGE_ERROR}>
      {errorMessage.message}
    </FeedbackMessage>
  );
};
