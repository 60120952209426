import { handleNavigationTracking } from '@remote-com/analytics';
import { Tab as NormaTab } from '@remote-com/norma';
import type { TabProps } from '@remote-com/norma';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { forwardRef } from 'react';
import type { MouseEvent, ComponentProps } from 'react';

import { getTabHref } from '@/src/components/Tabs/utils/getTabHref';
import { getDDSessionReplayAttribute } from '@/src/helpers/datadog';

interface EmployTabProps extends TabProps {
  trackNavigation?: string[];
  setQueryParamOnClick?: boolean | 'drop-query';
  linkProps?: Partial<ComponentProps<typeof Link>>;
}

const Tab = forwardRef<HTMLButtonElement, EmployTabProps>(
  (
    {
      selected,
      value,
      children,
      onClick,
      setQueryParamOnClick,
      count,
      trackNavigation,
      linkProps = {},
      ...props
    },
    ref
  ) => {
    const router = useRouter();

    const handleClick = (ev: MouseEvent<HTMLButtonElement>, tabValue: string | number) => {
      if (onClick) {
        onClick(ev, tabValue);
        handleNavigationTracking(trackNavigation);
      }
    };

    const ddActionAttribute = getDDSessionReplayAttribute(
      { ...props, children },
      { showChildContents: true }
    );

    const tabProps = {
      forwardedAs: setQueryParamOnClick ? 'a' : 'button',
      type: setQueryParamOnClick ? undefined : 'button',
      ref,
      'aria-selected': selected,
      selected,
      count,
      value,
      ...ddActionAttribute,
      ...props,
      asAnchor: !!setQueryParamOnClick,
      onClick: handleClick,
    };

    if (!setQueryParamOnClick) {
      return <NormaTab {...tabProps}>{children}</NormaTab>;
    }

    return (
      <Link
        href={getTabHref(router.asPath, value as string, setQueryParamOnClick === 'drop-query')}
        passHref
        shallow
        legacyBehavior
        {...linkProps}
      >
        <NormaTab {...tabProps}>{children}</NormaTab>
      </Link>
    );
  }
);

export default Tab;
