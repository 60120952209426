import { PRICING_PLAN_ROUTE, SELECT_PRODUCT_PLAN_ROUTE } from '@/src/constants/routes';

export const FEATURE_ACCESS = {
  APPLICANT_TRACKING_SYSTEM_BASIC: 'applicant-tracking-system-basic',
  TALENT_JOB_AD: 'talent-job-ad',
  TALENT_INSIGHT_SEARCH: 'talent-insight-search',
} as const;

export const PRODUCT_TRIAL_STATUS = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
} as const;

export const PRODUCT_USAGE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PAUSED: 'paused',
} as const;

/** Represents the overall "state" of the product. */
export const PRODUCT_ACCESS_STATE = {
  NEVER_SUBSCRIBED: 'NEVER_SUBSCRIBED',
  FREE_TRIAL_ACTIVE: 'FREE_TRIAL_ACTIVE',
  FREE_TRIAL_EXPIRED: 'FREE_TRIAL_EXPIRED',
  PLAN_ACTIVE: 'PLAN_ACTIVE',
  PLAN_EXPIRED: 'PLAN_EXPIRED',
  PLAN_PAUSED: 'PLAN_PAUSED',
  FEATURE_USAGE_EXCEEDED: 'FEATURE_USAGE_EXCEEDED',
} as const;

export const RESTRICTED_PRODUCT_STATES = [
  PRODUCT_ACCESS_STATE.FREE_TRIAL_EXPIRED,
  PRODUCT_ACCESS_STATE.PLAN_EXPIRED,
  PRODUCT_ACCESS_STATE.PLAN_PAUSED,
  PRODUCT_ACCESS_STATE.FEATURE_USAGE_EXCEEDED,
] as const;

export type RestrictedProductState = (typeof RESTRICTED_PRODUCT_STATES)[number];

export const getProductPlanRoute = (productEmploymentType: string) =>
  SELECT_PRODUCT_PLAN_ROUTE.replace('[productEmploymentType]', productEmploymentType);

export const PURCHASE_PRODUCT_URLS = {
  [PRODUCT_ACCESS_STATE.PLAN_ACTIVE]: () => PRICING_PLAN_ROUTE,
  [PRODUCT_ACCESS_STATE.PLAN_PAUSED]: () => PRICING_PLAN_ROUTE,
  [PRODUCT_ACCESS_STATE.FEATURE_USAGE_EXCEEDED]: getProductPlanRoute,
  [PRODUCT_ACCESS_STATE.PLAN_EXPIRED]: getProductPlanRoute,
  [PRODUCT_ACCESS_STATE.FREE_TRIAL_ACTIVE]: getProductPlanRoute,
  [PRODUCT_ACCESS_STATE.FREE_TRIAL_EXPIRED]: getProductPlanRoute,
};
