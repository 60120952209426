import type { ElementType } from 'react';

import NoResults from '@/src/components/Table/Components/NoResults';

type ExternalState = {
  globalFilter?: string;
};

type EmptyStateComponentProps = {
  EmptyBlock?: ElementType<{ state?: ExternalState }>;
  externalState?: ExternalState;
};

export const EmptyStateComponent = ({ externalState, EmptyBlock }: EmptyStateComponentProps) => {
  return EmptyBlock ? (
    <EmptyBlock state={externalState} />
  ) : (
    <NoResults query={externalState?.globalFilter} />
  );
};
