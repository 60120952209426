import { useGetInfinite, useInvalidateQuery } from '@remote-com/data-layer';
import type { GetRequest, GetPath, UseGetOptions } from '@remote-com/data-layer';
import { useCallback } from 'react';
import type { UseInfiniteQueryResult } from 'react-query';

import type { API } from '@/src/api/config/api.types';
import type { TodoUpdate } from '@/src/api/config/employ/shared.types';
import { useUser } from '@/src/components/UserProvider/context';
import { useIsTalentCompany } from '@/src/domains/companies/hooks/useIsTalentCompany';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { userCan } from '@/src/domains/registration/auth/helpers';
import { companyProfileIsNotCreated } from '@/src/domains/talent/employer/company/helpers/general';
import { useGetCompanyProfile } from '@/src/domains/talent/employer/company/hooks/useGetCompanyProfile';
import { TODO_TYPES_V2 } from '@/src/domains/todos/constants';

/**
 * NOTE: We need to set this unusually large default page size for two reasons:
 * 1. In our v2 designs for Things to do, we do not have support for pagination, yet.
 * 2. In some places, we need to know if a specific todo type is present but `updates/todos`
 * doesn't support filters, yet. See: `useCompanyHasTermsNotification()`
 */

type UseGetParams<KPath extends GetPath> = GetRequest<KPath>;

export type UseGetTodosProps = {
  params?: UseGetParams<'/api/v1/updates/todos'>;
  options?: Omit<UseGetOptions<'/api/v1/updates/todos'>, 'select'>;
};

type UserWithUserCache =
  | (API.UserAccountResponse['data'] & {
      userCache?: {
        companyData?: {
          insertedAt?: string;
        };
      };
    })
  | Record<string, never>;

/*
  This is a workaround. On launch (13.09.23), Boba will not have notifications setup.
  To reduce introducing Talent logic into Tiger, we need to provide a fake remote talent todo if
  - The company is a talent company
  - The company profile for Remote Talent has not been created

  TODO: #dev-team-talent-and-jobs: Remove this once we have Boba <--> Tiger Notifications. See https://www.notion.so/remotecom/392c3f1011fc4859b038484766565eec?v=92a0faabcef545bd9fa59a25295af9ab&p=e99c177c1c4143a4a2781fd308be3389&pm=s
  */
const getFakeRemoteTalentTodo = (user: UserWithUserCache): TodoUpdate => {
  // If no userCache.companyData.insertedAt exists, it will default to today.
  const companyInsertedAtDate = user?.userCache?.companyData?.insertedAt
    ? new Date(user?.userCache?.companyData?.insertedAt)
    : new Date();

  return {
    insertedAt: companyInsertedAtDate.toISOString(),
    updatedAt: companyInsertedAtDate.toISOString(),
    type: TODO_TYPES_V2.SETUP_TALENT_COMPANY_PROFILE,
    slug: 'setup_talent_company_profile_fake_todo',
    details: null,
    dueDate: null,
    endDate: null,
    resource: null,
    status: 'active',
  };
};

type GetInfiniteTodosPage = {
  data: API.Common.UpdatesTodosResponse['data'];
  status: number;
  statusText: string;
  headers: Record<string, string>;
  config: Record<string, unknown>;
  request: Record<string, unknown>;
};

type GetInfiniteTodosResponse = {
  pages: GetInfiniteTodosPage[];
  pageParams: unknown[];
};

// TODO: improve typing when useGetInfinite types are fixed (RMT-526)
type UseGetTodosResult = UseInfiniteQueryResult<API.Common.UpdatesTodosResponse['data']> & {
  data?: API.Common.UpdatesTodosResponse['data'];
};

export function useGetTodos(config?: UseGetTodosProps): UseGetTodosResult {
  const { params, options } = config || {};
  const { user } = useUser(); // User has an additional userCache that is not typed
  const isTalentCompany = useIsTalentCompany();
  const userCanReadCompanyProfiles = userCan('read', Resources.employer.company_profiles, user);
  const { isLoading, error } = useGetCompanyProfile({
    enabled: isTalentCompany && userCanReadCompanyProfiles,
  });

  // @ts-expect-error: the return of useGetInfinite is typed as UseInfiniteQueryResult<unknown, unknown>
  return useGetInfinite('/api/v1/updates/todos', {
    // @ts-expect-error: Type 'number' is not assignable to type 'string | string[]'
    params,
    options: {
      enabled: !isLoading,
      // @ts-expect-error -  Types of parameters 'data' and 'data' are incompatible.
      refetchInterval: 1000 * 60 * 5, // 5 minutes
      ...options,
      // @ts-expect-error: Type '(lastPage: GetInfiniteTodosPage) => number | undefined' is not assignable to type 'GetNextPageParamFunction<unknown>'
      getNextPageParam: (lastPage: GetInfiniteTodosPage) =>
        lastPage.data.currentPage < lastPage.data.totalPages
          ? lastPage.data.currentPage + 1
          : undefined,
      // This select extracts the data from each page, so the data is returned in the same format as if we'd use `useGet` instead of `useGetInfinite`
      select: (response) => {
        const initialItems: TodoUpdate[] = [];

        /**
         * ⚠️ ATTENTION: DO NOT REMOVE OR ADD ANY TODOS FROM THE API RESPONSE!!
         *
         * If you want items to appear only for specific users or under certain conditions,
         * create them only for relevant users. Altering which items are displayed can lead
         * to side effects that may result in customer support requests.
         *
         * If you have any questions or doubts, reach out to #dev-team-web-experience
         */

        // TODO: Remove fake notification
        // Linear: https://linear.app/remote/issue/TACO-1358/remove-setup-talent-company-profile-todo-hack
        if (isTalentCompany && userCanReadCompanyProfiles && companyProfileIsNotCreated(error)) {
          initialItems.push(getFakeRemoteTalentTodo(user as UserWithUserCache));
        }

        const data = (response as GetInfiniteTodosResponse).pages
          .map((page) => page.data)
          .reduce(
            (prev, current) => ({
              ...current,
              items: [...prev.items, ...(current.items || [])],
              totalGroupedTodos: current.totalGroupedTodos + initialItems.length,
            }),
            {
              items: initialItems,
            } as API.Common.UpdatesTodosResponse['data']
          );

        return data;
      },
    },
  });
}

type InvalidateTodosQueryReturn = {
  /** Invalidates the todos queries to ensure the latest data is fetched */
  invalidateTodosQuery: () => void;
};

/**
 * This hook is used to invalidate `todos` queries to ensure the latest data is fetched.
 * This is useful when an action that decreases/increases the number of `todos` is performed.
 */
export const useInvalidateTodosQuery = (): InvalidateTodosQueryReturn => {
  const { invalidateQuery } = useInvalidateQuery();

  const invalidateTodosQuery = useCallback(() => {
    invalidateQuery('/api/v1/updates/todos');
  }, [invalidateQuery]);

  return { invalidateTodosQuery };
};
