import type { BoxedIconTone } from '@remote-com/norma';
import {
  Box,
  BoxedIcon,
  focusRingOffsetBorder,
  focusVisible,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@remote-com/norma';
import { IconV2OutlineInfo } from '@remote-com/norma/icons/IconV2OutlineInfo';
import type { ElementType, MouseEvent } from 'react';
import styled, { css } from 'styled-components';

import { Button, ButtonInline } from '@/src/components/Button';
import { useUserCan } from '@/src/components/UserCan';
import { CUSTOMER_USERS_PERMISSIONS_ROUTE } from '@/src/constants/routes';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

export const CardStyled = styled.label<{
  $isSelected?: boolean;
}>`
  display: block;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.grey['200']};
  border-radius: ${(props) => props.theme.borderRadius.lg};
  transition: 0.2s;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.colors.background.subtle};
  }

  ${({ theme }) =>
    focusVisible(css`
      ${focusRingOffsetBorder(theme.colors.brand[600], theme.colors.blank)};
      border-color: ${theme.colors.grey[200]};
    `)}

  ${(props) =>
    props.$isSelected &&
    css`
      background-color: ${props.theme.colors.brand['50']};
      border-color: ${props.theme.colors.brand['600']};
    `}
`;

const ButtonStyled = styled(Button)`
  background-color: transparent;
`;

export const PENDING_CONTRACTOR_INVOICES_FILTER_TYPES = {
  TRANSACTIONS: 'transactions',

  /**
   * We have dynamic types for payments, like payment- usd, payment-eur, etc.
   * but we also have a hardcoded default type, mainly because we want a query
   * param that can be used for links, without forcing the link to fetch the
   * currencies to build the query param.
   *
   * This way, any link to the payments filter can use the default type.
   */
  PAYMENT_DEFAULT: 'payment-default',
  APPROVAL: 'approval',
} as const;

/**
 * We have a PAYMENTS_DEFAULT hardcoded type, but we can also have other types
 * based on different currencies, like payment-usd, payment-eur, etc.
 */
export const isPaymentFilterType = (type: string) => type.startsWith('payment-');

export const getCurrencyCodeFromPaymentFilterType = (type: string) =>
  type.replace('payment-', '').toUpperCase();

export const generatePaymentFilterTypeFromCurrencyCode = (sourceCurrencyCode: string) =>
  `payment-${sourceCurrencyCode.toUpperCase()}`;

type TableHeaderFilterProps = {
  itemsCount?: number;
  isSelected?: boolean;
  onSelected: (type: string) => void;
  onActionClick?: () => void;
  actionLabel?: string;
  isLoading: boolean;
  label: string;
  emptyLabel: string;
  icon: ElementType;
  tone: BoxedIconTone;
  tooltipLabel: string;
  id: string;
};

export const TableHeaderFilter = ({
  itemsCount,
  isSelected,
  onSelected,
  onActionClick,
  actionLabel,
  isLoading,
  label,
  emptyLabel,
  icon,
  tone,
  id,
  tooltipLabel,
}: TableHeaderFilterProps) => {
  const { userCan } = useUserCan();

  const loadingContent = (
    <Stack gap={4}>
      <Skeleton variant="rect" width={120} height={14} />
      <Skeleton variant="rect" width={85} height={18} />
    </Stack>
  );

  const finalContent = itemsCount ? (
    <>
      <Box display="flex" gap={2} alignItems="center">
        <Text variant="smMedium" color="grey.700">
          {label}
        </Text>
        {tooltipLabel ? (
          <Tooltip
            label={
              <Stack>
                {tooltipLabel}
                {id !== PENDING_CONTRACTOR_INVOICES_FILTER_TYPES.TRANSACTIONS &&
                  userCan('create', Resources.employer.roles) && (
                    <ButtonInline
                      href={{
                        pathname: CUSTOMER_USERS_PERMISSIONS_ROUTE,
                        query: { selectedTab: 'roles' },
                      }}
                    >
                      Manage permissions
                    </ButtonInline>
                  )}
              </Stack>
            }
          >
            <IconV2OutlineInfo aria-label="info" width="12px" height="12px" />
          </Tooltip>
        ) : null}
      </Box>
      <Text variant="lgMedium" color="grey.900">
        {`${getSingularPluralUnit(
          itemsCount,
          id === PENDING_CONTRACTOR_INVOICES_FILTER_TYPES.TRANSACTIONS ? 'transaction' : 'invoice',
          id === PENDING_CONTRACTOR_INVOICES_FILTER_TYPES.TRANSACTIONS
            ? 'transactions'
            : 'invoices',
          false
        )}`}
      </Text>
    </>
  ) : (
    <Text variant="lgMedium" color="grey.900">
      {emptyLabel}
    </Text>
  );

  return (
    <CardStyled
      tabIndex={0}
      htmlFor={`${id}-button`}
      data-dd-action-name={`${id}-button`}
      onClick={(e) => {
        onSelected(id);
        // This is needed to prevent triggering onClick event on child button
        e.preventDefault();
      }}
      $isSelected={isSelected}
    >
      <Stack width="100%" height="100%" direction="row" alignItems="center" px={5} py={4}>
        <BoxedIcon Icon={icon} tone={tone} size="lg" />
        <Stack flex="1 1 auto" mx={5}>
          {isLoading ? loadingContent : finalContent}
        </Stack>
        {itemsCount && !isLoading ? (
          <Box flex="0 0 auto">
            {onActionClick ? (
              <ButtonStyled
                variant="outline"
                size="xs"
                id={`${id}-button`}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onActionClick();
                }}
              >
                {actionLabel}
              </ButtonStyled>
            ) : null}
          </Box>
        ) : null}
      </Stack>
    </CardStyled>
  );
};
