import { Box, Stack, Text } from '@remote-com/norma';
import { IconV2SolidTimes } from '@remote-com/norma/icons/IconV2SolidTimes';
import type { PropsWithChildren, ReactNode } from 'react';

import { WIDGET_CARD_DEFAULT_VERTICAL_PADDING } from './constants';
import {
  StyledWidgetCardBody,
  StyledWidgetCardHeader,
  StyledWidgetCardTitle,
  StyledWidgetDismissButton,
} from './WidgetCard.styled';

export type WidgetCardProps = PropsWithChildren<{
  /**
   * Displays one or more actions in the card header.
   */
  action?: ReactNode;
  /**
   * Displays tabs in the card header. If provided, the `action` and `onDismiss` props will be ignored.
   */
  tabs?: ReactNode;
  /**
   * Displays an item count next to the title.
   */
  count?: number;
  /**
   * The maximum count for the item. If provided, the count will be displayed as `count/maxCount`.
   */
  maxCount?: number;
  /**
   * Controls whether we want to add default padding to the body.
   *
   * To maintain consistent spacing, import `WIDGET_CARD_DEFAULT_VERTICAL_PADDING` in your child components.
   */
  hasBodyPadding?: boolean;
  /**
   * The widget card title.
   */
  title: string;
  /**
   * Displays an "x"-button and performs an action on click
   */
  onDismiss?: () => void;
  /**
   * Specifies the component or HTML element type to render, allowing for dynamic component or element substitution.
   */
  as?: React.ElementType;

  description?: string;
}>;

export function WidgetCard({
  action,
  tabs,
  children,
  count,
  maxCount,
  description,
  hasBodyPadding = true,
  title,
  onDismiss,
  as,
}: WidgetCardProps) {
  const hasCount = !!count && count >= 1;

  return (
    /**
     * TODO: These styles don't match our current card styles. Once the `Card` component
     * has been updated to a `v2` design, we should use it here for consistency.
     */
    <Stack borderRadius="16px" bg="blank" overflow="hidden" as={as} flexGrow={1}>
      <StyledWidgetCardHeader>
        <Box py={WIDGET_CARD_DEFAULT_VERTICAL_PADDING}>
          <StyledWidgetCardTitle
            aria-label={
              hasCount
                ? `${title}: ${maxCount ? `${count} of ${maxCount}` : `${count} total`}`
                : title
            }
          >
            {title}
            {hasCount && (
              <Text as="span" variant="sm" color="grey.600" aria-hidden>
                ({maxCount ? `${count}/${maxCount}` : count})
              </Text>
            )}
          </StyledWidgetCardTitle>

          {description && (
            <Text as="span" variant="sm" color="grey.600">
              {description}
            </Text>
          )}
        </Box>
        {tabs ? (
          <Stack direction="row" flexShrink="0" gap={4} alignSelf="flex-end">
            {tabs}
          </Stack>
        ) : (
          <Stack direction="row" flexShrink="0" gap={4}>
            {action}
            {onDismiss && (
              <StyledWidgetDismissButton onClick={onDismiss} aria-label="Dismiss">
                <IconV2SolidTimes height={16} width={16} />
              </StyledWidgetDismissButton>
            )}
          </Stack>
        )}
      </StyledWidgetCardHeader>
      <StyledWidgetCardBody $hasPadding={hasBodyPadding}>{children}</StyledWidgetCardBody>
    </Stack>
  );
}
