import type { ListItem, PillTone, SelectOption } from '@remote-com/norma';
import { HTMLRendered } from '@remote-com/norma';
import camelcaseKeys from 'camelcase-keys';
import { yupToFormErrors } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';
import replace from 'lodash/replace';
import { number } from 'yup';

import type { JSONSchema } from '@/src/api/config/employ/jsonSchemas';
import {
  supportedTypes,
  yesNoBooleanLabels,
  yesNoValues,
} from '@/src/components/Form/DynamicForm/constants';
import { JSFHelpCenterDrawer } from '@/src/components/Ui/Form/HelpCenterDrawer/JSFHelpCenterDrawer';
import { displayFieldInfoBlock as workScheduleFieldDisplayFieldInfoBlock } from '@/src/components/Ui/Form/WorkScheduleField/utils/displayFieldInfoBlock';
import {
  apiPayloadToState as workWeekScheduleTableFieldApiPayloadToState,
  displayFieldInfoBlock as workWeekScheduleTableFieldDisplayFieldInfoBlock,
  stateToApiPayload as workWeekScheduleTableFieldStateToApiPayload,
} from '@/src/components/Ui/Form/WorkWeekScheduleTableField';
import { benefitsFieldDisplayFieldInfoBlock } from '@/src/domains/benefits/helpers';
import { DocumentButton } from '@/src/domains/documents/shared/DocumentButton';
import { getPreviewPageUrl } from '@/src/domains/files/helpers';
import { convertFromCents, convertToCents, friendlyMoney } from '@/src/helpers/currency';
import { camelCaseKeepDots, error, isDev, pickKey } from '@/src/helpers/general';
import type { $TSFixMe } from '@/types';

import type {
  AckCheckField,
  CheckBoxField,
  CountriesField,
  ExtraField,
  FieldsetField,
  HiddenField,
  MoneyField,
  RadioField,
  SelectField,
  SupportedFieldTypes,
  TextField,
  WorkWeekScheduleField,
  BaseFieldType,
  SelectFieldJSF,
  RadioOption,
  YesNoValue,
  FormField,
  WorkScheduleItem,
} from './types';

type YesNoKeys = keyof typeof yesNoBooleanLabels;
type FileFieldValue = { slug: string; name: string; description: string }[];
type CurrencyFieldValue = { code: string };
type CountryFieldValue = { name: string } & { value: string; label: string };
type HelpCenterLocation = 'statement' | 'description';

type ApiValue = {
  startTime: string;
  endTime: string;
  lunchStartTime: string;
  lunchEndTime: string;
  workingHours: number;
};

type Time = {
  hours?: number;
  minutes?: number;
};

type TimeState = {
  value: string;
  parsed: Time;
};

type WorkWeekScheduleTableState = {
  startTime: TimeState;
  endTime: TimeState;
  lunchStartTime: TimeState;
  lunchEndTime: TimeState;
};

function castFieldTo<T>(field: FormField) {
  return field as unknown as T;
}

function fieldSanityCheckerOfOptions(field: RadioField) {
  // To bypass this error, you have two solutions:
  // - Ideal: Refactor the field to make the options an Array [{ name, value }].
  // - Workaround: Bypass the error by setting "dangerousOptionsAreNotArray: true"
  //   in the field. This will help us to identify these fields in the future
  //   if we need to migrate them to JSON Schema forms.
  if (!field.dangerousOptionsAreNotArray) {
    error(
      new Error(
        `DynamicForm warning: ${field.type} - The field "${
          field.name
        }" has unexpected options of type "${typeof field.options}". Check the codebase for further guidance.`
      )
    );
  }
}

/**
 * Returns a single value from the options list
 * @param {String} value - One (string) option selected
 * @param {Object[]} field.options - Object structure with options config and the currency value
 */
function findOption(value: string, field: SelectField | RadioField) {
  if ('options' in field && Array.isArray(field.options)) {
    const option = field.options?.find((o) => o.value === value);
    if ('currency' in field && field.currency) {
      if (typeof field.currency === 'string') {
        return friendlyMoney(option?.value || null, { code: field.currency });
      }

      return friendlyMoney(option?.value || null, null);
    }
    return option?.label || value;
  }

  return '';
}

/**
 * Returns the respective label of a given option,
 * based on this.options provided by the field object config.
 * @param {String|Array} value - One (string) or multiple (array) options selected
 * @this {Object[]} this.options - Object structure with options config
 * @this {String} this.options[].value - Key for the API
 * @this {Array} this.options[].label - friendly label
 *
 * @returns {String} - The respective value label.
 *
 * @example
 * Assuming this.options = [{ value: 'black', label: 'Black' }, { value: 'dark_pink', label: 'Dark Pink' }]
 * ['black'] -> "Black"
 * ['black', 'dark_pink'] -> "Black, Dark Pink"
 *
 */
export function formatDisplayForOptions(value: string | string[]) {
  if (Array.isArray(value)) {
    return value
      .map((unit) => {
        // @ts-expect-error
        // "this" must be used to pass the scope of where formatDisplayForOptions() is used aka, the `field` object itself.
        // Please do not refactor .map() callback from arrow function to a regular function,
        // otherwise you will be passing the wrong scope.
        return findOption(unit, this);
      })
      .join(', ');
  }

  // @ts-expect-error similar comment to the one above
  return findOption(value, this);
}

export const fieldTypesTransformations: Record<string, any> = {
  [supportedTypes.COUNTRIES]: {
    formatDisplay: () => (value: { name: string }[] | { name: string } | { label: string }[]) => {
      if (Array.isArray(value)) {
        // eg [{ name: 'Portugal' }, { name: 'Germany' }] // legacy data
        if (value.every((countryValue) => (countryValue as { name: string })?.name)) {
          return value.map((countryValue) => (countryValue as { name: string }).name).join(', ');
        }

        // eg [{label: 'Americas'}, {label: 'Australia and New Zealand'}] // tax servicing countries
        if (value.every((countryValue) => (countryValue as { label: string })?.label)) {
          return value.map((countryValue) => (countryValue as { label: string }).label).join(', ');
        }

        // eg ['Portugal', 'Germany']
        return value.join(', ');
      }

      if (typeof value?.name === 'string') {
        return value.name;
      }

      return value;
    },
    /**
     *
     * @param {Object} field - Field config that must contain field.countries
     * @param {String[]|String} value - Selected country
     *  - Current data (multi): an array of country names - eg: ['Peru', 'Germany']
     *  - Legacy data (multi): a string of country names - eg: "Peru,Germany"
     * @returns {String[]} Eg [{ label: 'PER', name: 'Peru' }]
     */
    transformValueFromAPI: (field: CountriesField) => (value: string) => {
      if (!field.multiple) {
        return value ?? '';
      }

      let countryNames;

      if (typeof value === 'string') {
        // support legacy data (when the fields were open text fields before)
        countryNames = value.split(',');
      } else {
        countryNames = value || [];
      }

      const countryValues = countryNames.map(
        // Return { name: countryName } as fallback to legacy data
        // The "name" is used at react-select, to connect to the country flag.
        (countryName) =>
          field.countries?.find?.(
            (country) => 'name' in country && country.name === countryName
          ) || {
            name: countryName,
          }
      );

      return countryValues;
    },
    /**
     * @param {String[] | { name: String }[]} value
     *  - Excepted: array of strings.
     *  - Edge cases: array of objects. (when using dangerousTransformValue)
     * @returns {String[]} - List of countries
     * @example expected: ['Peru', 'Germany'] -> ['Peru', 'Germany']
     * @example edge cases: [{name: 'Peru'}, {name: 'Germany'}] -> ['Peru', 'Germany']
     */
    transformValueToAPI:
      (field: CountriesField) => (selectedCountries: string[] | { name: string }[]) => {
        if (!field.multiple || typeof selectedCountries === 'string') {
          return selectedCountries;
        }
        // NOTE: The value should be an array of strings, however legacy data can come as
        // an array of country objects. So, we always send an array of strings to normalize
        // the data (eg old form values being modified) until DB migration is done !5667
        return selectedCountries.map((option) =>
          typeof option === 'string' ? option : option.name
        );
      },
    /**
     * Used for react-select, where the country selected is transformed
     * before saving on Formik state. Supports both solo and multi select
     * @param {Object|Object[]} selectedCountry[] - Current selected options
     * @param {String} selectedCountry[].value
     * @param {String} selectedCountry[].name
     * @param {String} selectedCountry[].label
     * @returns {String[]} - List of countries selected
     * @example
     * [{ value: 'Hungria' }] -> ['Hungria']
     */
    transformValue: (selectedCountry: CountryFieldValue | CountryFieldValue[]) => {
      // name or label are used in dragon. value is used in json-schema-form
      // TODO: it should be the same everywhere — read more at !5667
      const getCountryValue = (opt: CountryFieldValue) => opt?.name || opt?.value || opt?.label;
      return Array.isArray(selectedCountry)
        ? selectedCountry.map(getCountryValue) // support multi countries
        : getCountryValue(selectedCountry) || ''; // Fallback to '' in case user removes all countries
    },
  },
  [supportedTypes.NUMBER]: {
    transformValueToAPI: () => (value: string) => {
      // this prevents values with letters such as "2r" from being considered valid
      // if the input is invalid, number().cast will return NaN
      const castValue = number().cast(value, {
        assert: false,
      });

      if (Number.isNaN(castValue)) {
        return value;
      }

      return castValue;
    },
  },
  [supportedTypes.MONEY]: {
    transformValueFromAPI: () => (value: string | number) => convertFromCents(value) ?? '',
    transformValueToAPI: () => convertToCents,
    /**
     * @param {String} value - the value from API (in cents)
     * @param {Object} formValues - all the entered form values
     * @param {Object} field.currency
     */
    formatDisplay:
      (field: MoneyField) => (value: string | number | null, formValues?: Record<string, any>) => {
        // in some cases, the currency is dynamically determined by user selection
        const fieldProps =
          field.calculateDynamicProperties && formValues
            ? field.calculateDynamicProperties(formValues)
            : field;
        return friendlyMoney(value, { code: fieldProps?.currency });
      },
  },
  [supportedTypes.RADIO]: {
    formatDisplay: (field: RadioField) => (selectedOption: string) => {
      if (Array.isArray(field.options)) {
        // We shouldn't need to use bind along this. It makes it harder to read and
        // more importantly makes formatDisplayForOptions more error prone because it relies on this
        // which could be a complete different thing depending if we use a regular function or an arrow function.
        // Linear: https://linear.app/remote/issue/RMT-773/refactor-formatdisplayforoptions-function
        return formatDisplayForOptions.bind(field)(selectedOption);
      }

      fieldSanityCheckerOfOptions(field);

      // Fallback to field with dynamic options (Function)
      // @TODO analyze how to support this with JSON Schemas
      return selectedOption;
    },
    transformValueToAPI: (field: RadioField) => (value: string) => {
      if (field.transformToBool) {
        return value === yesNoValues.YES;
      }
      return value;
    },
  },
  [supportedTypes.RADIO_CARD]: {
    formatDisplay: (field: RadioField) => (selectedOption: string) => {
      if (Array.isArray(field.options)) {
        return formatDisplayForOptions.bind(field)(selectedOption);
      }

      fieldSanityCheckerOfOptions(field);

      // Fallback to field with dynamic options (Function)
      // @TODO analyze how to support this with JSON Schemas
      return selectedOption;
    },
  },
  [supportedTypes.SELECT]: {
    /**
     * @param {{value: String, label: String }[]|Function} field.options - The list of all options available.
     *  - Edge cases: Function that returns the list of options (e.g. IND contract details - benefits)
     * @param {String[]} selectedOptions - List of selected options
     */
    formatDisplay: (field: SelectField) => (selectedOptions: string[]) => {
      if (Array.isArray(field.options)) {
        return formatDisplayForOptions.bind(field)(selectedOptions);
      }

      // Fallback to field with dynamic options (Function)
      // @TODO analyze how to support this with JSON Schemas
      if (Array.isArray(selectedOptions)) {
        return selectedOptions.join(', ');
      }

      return selectedOptions;
    },
    /**
     * Used for react-select, where the value is transformed
     * before saving on Formik state.
     * @param {Object | Object[]} option - Object structure with options config
     * @param {String} option[].value - Key for the API
     * @param {Array} this.options[].label - Friendly label
     * @example
     * [{ value: '1', label: 'One' }, { value: '2', label: 'Two' }] -> ["One", "Two"]
     * { value: '1', label: 'One' } -> "One"
     * {} -> ""
     */
    transformValue: (option: SelectOption | SelectOption[]) =>
      Array.isArray(option)
        ? option.map((opt) => opt.value) // multi-options
        : option?.value ?? '', // Fallback to '' in case user removes all options,
  },
  [supportedTypes.ACK_CHECK]: {
    /**
     * @param {String} value - the value from API (in cents)
     * @param {Object} field.currency
     */
    formatDisplay: (field: AckCheckField) => (value: string | number | null) => {
      // @ts-expect-error
      if (field.currency) {
        // @ts-expect-error
        return friendlyMoney(value, { code: field.currency });
      }
      return value;
    },
  },
  [supportedTypes.CHECKBOX]: {
    formatDisplay: (field: CheckBoxField) => (value: YesNoKeys) => {
      return typeof field.checkboxValue === 'boolean' ? yesNoBooleanLabels[value] : value;
    },
  },
  [supportedTypes.WORK_WEEK_SCHEDULE]: {
    transformValueFromAPI: (field: WorkWeekScheduleField) => (apiValue: ApiValue) =>
      workWeekScheduleTableFieldApiPayloadToState(apiValue || {}, field),
    transformValueToAPI:
      (field: WorkWeekScheduleField) => (fieldState: WorkWeekScheduleTableState) =>
        workWeekScheduleTableFieldStateToApiPayload(fieldState || {}, field),
  },
  [supportedTypes.EXTRA]: {
    transformValueFromAPI: (field: ExtraField) => (value: string | number) => {
      if (field.currency) return convertFromCents(value) ?? '';
      return value;
    },
    transformValueToAPI:
      (field: ExtraField) => (value: string | number | boolean | string[] | null | undefined) => {
        if (field.currency) return convertToCents(value);
        return value;
      },
    formatDisplay: (field: ExtraField) => (value: string) => {
      if (field.currency) return friendlyMoney(value, { code: field.currency });
      return value;
    },
  },
  [supportedTypes.FILE]: {
    formatDisplay: () => (value: FileFieldValue) => {
      if (Array.isArray(value)) {
        if (isEmpty(value)) return null;
        return (
          // Fragment to prevent rendering as fieldset
          <>
            {value.map((file) => (
              <DocumentButton
                href={file.slug ? getPreviewPageUrl({ fileSlug: file.slug }) : undefined}
                key={file.slug ?? file.name}
              >
                {file.description ?? file.name}
              </DocumentButton>
            ))}
          </>
        );
      }
      return value;
    },
  },
  [supportedTypes.CURRENCIES]: {
    formatDisplay: () => (value: CurrencyFieldValue) => {
      return value.code;
    },
  },
};

/**
 * Check if a form value is null, acounting with empty arrays and numbers
 * @example
 *  '' -> true
 *  [] -> true
 *  'hello' -> false
 *  0 -> false
 * @returns {Boolean}
 */
export const isValueEmpty = (val: string | any[] | undefined) =>
  typeof val === 'undefined' || val === '' || (Array.isArray(val) && val.length === 0);

function getDefaultValueForType(type: BaseFieldType['type']) {
  switch (type) {
    case supportedTypes.FILE:
      return undefined; // Allows fallback values in function declarations to be used
    default:
      return '';
  }
}

/**
 * Logs an error if required complimentary parameters are missing.
 *
 * @param {Object} field - The field object that contains the parameters.
 * @throws {Error} Throws an error if required parameters are missing.
 */
const logErrorOnMissingComplimentaryParams = (field: FormField) => {
  /*
  If a transformation on both To/From is not needed, you can follow this example to avoid the error
  const fields = [
 {
   name: 'multiSelect',
   transformValueToAPI: () => (v) => v.join(' '),
   transformValueFromApi: () => () => {  not needed because <reason-here>}
  }
];
  */
  if (isDev() && field?.transformValueToAPI && !field.transformValueFromAPI) {
    throw Error(
      `The field "${field.name}" has included "transformValueToApi" without a matching "transformValueFromApi"`
    );
  }
  if (isDev() && !field?.transformValueToAPI && field.transformValueFromAPI) {
    throw Error(
      `The field "${field.name}" has included "transformValueFromApi" without a matching "transformValueToApi"`
    );
  }
};

/**
 * Support pre-defined values for an input
 * @param {*} defaultValues
 * @param {*} field
 */
export function getInitialDefaultValue(
  defaultValues: Record<string, any>,
  field: FormField,
  options: { strictFieldNames?: boolean } = { strictFieldNames: false }
) {
  // lodash get is needed because some values could be nested object, like billing address
  // use camelCase to support forms with fields in snake_case or kebab_case.
  const defaultFieldValue = get(
    defaultValues,
    options?.strictFieldNames ? field.name : camelCaseKeepDots(field.name)
  );
  const fieldTransformValueFromAPI =
    field?.transformValueFromAPI || fieldTypesTransformations[field.type]?.transformValueFromAPI;

  logErrorOnMissingComplimentaryParams(field);

  if (fieldTransformValueFromAPI) {
    return fieldTransformValueFromAPI(field)(defaultFieldValue);
  }

  // TODO: We need to get rid of value as fn for json-schema. Related !5560
  const generatedValue =
    typeof field.value === 'function' ? field.value(defaultFieldValue, defaultValues) : null;

  // field.value is deprecated. should use "default" instead.
  const defaultValueDeprecated = typeof field.value !== 'function' ? field.value : null;
  const initialValueForCheckboxAsBool =
    castFieldTo<CheckBoxField>(field).checkboxValue === true ? defaultFieldValue || false : null;

  // nullish coalescing but excluding empty strings. (to support 0 (zero) as valid numbers)
  const excludeString = (val: any) => (val === '' ? undefined : val);

  return (
    excludeString(generatedValue) ??
    excludeString(defaultFieldValue) ??
    excludeString(defaultValueDeprecated) ??
    excludeString(field.default) ??
    initialValueForCheckboxAsBool ??
    getDefaultValueForType(field.type)
  );
}

/**
 * Get initial values for sub fields within fieldsets
 * @param {Object} field The form field
 * @param {Object} defaultValues The form default values
 * @param {String=} parentFieldKeyPath The path to the parent field using dot-notation
 * @returns {Object} The initial values for a fieldset
 */
function getInitialSubFieldValues(
  field: FieldsetField,
  defaultValues: Object,
  options: { strictFieldNames?: boolean } = { strictFieldNames: false },
  parentFieldKeyPath?: string
) {
  const initialValue: Record<string, Record<string, unknown>> = {};

  let fieldKeyPath = options?.strictFieldNames ? field.name : camelCaseKeepDots(field.name);

  if (parentFieldKeyPath) {
    fieldKeyPath = fieldKeyPath ? `${parentFieldKeyPath}.${fieldKeyPath}` : parentFieldKeyPath;
  }

  const subFields = field.fields;

  if (Array.isArray(subFields)) {
    const subFieldValues = {};

    subFields.forEach((subField) => {
      Object.assign(
        subFieldValues,
        getInitialSubFieldValues(subField, defaultValues, options, fieldKeyPath)
      );
    });

    if (field.type === supportedTypes.FIELDSET && field.valueGroupingDisabled) {
      Object.assign(initialValue, subFieldValues);
    } else {
      initialValue[field.name!] = subFieldValues;
    }
  } else {
    initialValue[field.name!] = getInitialDefaultValue(
      defaultValues,
      {
        ...field,
        // NOTE: To utilize the `get` function from `lodash` in `getInitialDefaultValue` correctly
        // we need to use the field path instead of just its name.
        name: fieldKeyPath,
      },
      options
    );
  }

  return initialValue;
}

function applyFieldDynamicProperties<TField extends FormField>(
  field: TField,
  values: Record<string, unknown> | Record<string, unknown>[]
): TField {
  if (field.calculateDynamicProperties) {
    return {
      ...field,
      ...(field.calculateDynamicProperties(values) || {}),
    };
  }

  return field;
}

/**
 * Given a fields config array and default values (ex from API),
 * build initialValues to be consumed by Formik
 * // NOTE: Should match json-schema-form helpers/getDefaultValues() (except the from API) part.
 */
export function getInitialValues(
  fields: FormField[],
  defaultValues: Record<string, unknown> | Record<string, unknown>[],
  options: { strictFieldNames?: boolean } = { strictFieldNames: false }
) {
  const initialValues: Record<string, any> = {};
  // use camelcaseKeys to support forms with fields in snake_case or kebab_case.
  const defaultFieldValues = options?.strictFieldNames
    ? defaultValues
    : camelcaseKeys(defaultValues || {}, { deep: true });
  // loop over fields array
  // if prop does not exit in the initialValues object,
  // pluck off the name and value props and add it to the initialValues object;
  fields
    .map((field) => applyFieldDynamicProperties(field, defaultFieldValues))
    .forEach((field) => {
      switch (field.type) {
        case supportedTypes.GROUP_ARRAY: {
          initialValues[field.name] = pickKey(defaultFieldValues, field.name)?.map(
            (subFieldValues: Record<string, unknown>) =>
              getInitialValues(field?.fields?.() as FormField[], subFieldValues)
          );
          break;
        }

        case supportedTypes.FIELDSET: {
          if (field.valueGroupingDisabled) {
            Object.assign(
              initialValues,
              getInitialValues(field.fields, defaultFieldValues, options)
            );
          } else {
            const subFieldValues = getInitialSubFieldValues(field, defaultFieldValues, options);
            Object.assign(initialValues, subFieldValues);
          }
          break;
        }

        case supportedTypes.EXTRA: {
          if (field.includeValueToApi || field.includeInitialValue) {
            initialValues[field.name] = getInitialDefaultValue(defaultFieldValues, field, options);
          }
          break;
        }

        case supportedTypes.FILE: {
          initialValues[field.name] =
            pickKey(defaultValues, field.name) ||
            getInitialDefaultValue(defaultValues, field, options);
          break;
        }

        default: {
          if (!initialValues[field.name]) {
            initialValues[field.name] = getInitialDefaultValue(defaultFieldValues, field, options);
          }
          break;
        }
      }
    });

  return initialValues;
}

/**
 * Checks whether the given field is visible or not. Works with client-side dynamic form fields,
 * as well as with JSON Schema fields.
 * @param {Object} field - Field definition
 * @param {Object} formValues - all the entered form values
 * @returns {Boolean}
 *
 * Field visibility can be set in several ways:
 * - field definitions for client-side forms can provide `visibilityCondition` predicate;
 * - client-side forms can return `isVisible` flag from `calculateDynamicProperties` function;
 * - JSON Schema parser can set `isVisible` flag conditionally.
 *
 * All these scenarios are accounted for below.
 */
export function isFieldVisible(field: FormField, formValues: Record<string, unknown>) {
  if (field.visibilityCondition) {
    return field.visibilityCondition(formValues);
  }

  if (typeof field.isVisible !== 'undefined') {
    return Boolean(field.isVisible);
  }

  return true;
}

type DisplayFieldsOptions = {
  parentName?: string;
  keepFieldName: boolean;
  keepFieldDescription?: boolean;
  pillTone?: PillTone;
  keepDeprecated?: boolean;
};
/**
 * Display field values on the UI in a human way
 * @param {Array} fields — Fields configuration
 * @param {Object} values - Form fields values
 * @param {String} options.parentName - Name of parent field. Used in nested filters (eg fieldset)
 * @param {Boolean} options.keepFieldName - In the output it includes the field name
 * @param {Boolean} options.keepFieldDescription - In the output it includes the field name
 * @param {String} options.pillTone - In case of required field error, display pillTone
 * @param {Boolean} options.keepDeprecated - In case of deprecated fields, get the flag
 */
export const getDisplayFields = (
  fields: FormField[],
  values?: Record<string, unknown> | Record<string, unknown>[],
  opts?: DisplayFieldsOptions
): ListItem[] => {
  const options = opts || ({} as DisplayFieldsOptions);
  const { parentName, keepFieldName, keepFieldDescription, pillTone, keepDeprecated } = options;
  const formValues = values || {};
  const defaultValues = getInitialValues(fields, formValues);
  const formikValues = parentName ? { [parentName]: defaultValues } : defaultValues;

  const display = fields
    .map((fieldItem) => applyFieldDynamicProperties(fieldItem, formikValues))
    .filter(
      (fieldItem) =>
        isFieldVisible(fieldItem, formikValues) &&
        (fieldItem.type !== supportedTypes.EXTRA ||
          castFieldTo<HiddenField>(fieldItem).includeValueToApi) &&
        !(fieldItem.deprecated && !formikValues[fieldItem.name!]) && // hide empty deprecated fields
        fieldItem.type !== supportedTypes.HIDDEN
    )
    // Map through the fields to keep the original fields sorting
    .map((field) => {
      let value = null;
      const formFieldValue = pickKey(formValues, field.name!);

      switch (field.type) {
        case supportedTypes.FIELDSET: {
          const fieldset = field;
          if (fieldset.visualGroupingDisabled) {
            return getDisplayFields(fieldset.fields, formFieldValue, {
              parentName: field.name,
              keepFieldName,
              keepFieldDescription,
            });
          }

          if (fieldset.valueGroupingDisabled) {
            value = getDisplayFields(fieldset.fields, formValues, {
              keepFieldName,
              keepFieldDescription,
            });
          } else {
            value = getDisplayFields(fieldset.fields, formFieldValue, {
              parentName: field.name,
              keepFieldName,
              keepFieldDescription,
            });
          }
          break;
        }
        case supportedTypes.GROUP_ARRAY: {
          return {
            title: `${field.label}`,
            name: field.name,
            value:
              formFieldValue?.length > 0
                ? formFieldValue?.map((nthValues: Record<string, any>, index: number) => ({
                    title: `Entry #${index + 1}`,
                    value: getDisplayFields(field?.fields?.(index) as FormField[], nthValues, {
                      parentName: field.name,
                      keepFieldName,
                      keepFieldDescription,
                    }),
                  }))
                : 'Total: 0',
          };
        }
        case supportedTypes.WORK_WEEK_SCHEDULE: {
          return workWeekScheduleTableFieldDisplayFieldInfoBlock({
            field,
            formFieldValue,
            keepFieldName,
          });
        }
        case supportedTypes.WORK_SCHEDULE: {
          return workScheduleFieldDisplayFieldInfoBlock({
            field,
            formFieldValue: formFieldValue as WorkScheduleItem[],
            keepFieldName,
          });
        }
        // TODO-benefits: it's important to keep it in order to display benefits section in read-only contract details view
        // @ts-expect-error this field is deprecated
        case supportedTypes.BENEFITS: {
          const deprecatedReadOnlyBenefitsField = field as $TSFixMe;
          return benefitsFieldDisplayFieldInfoBlock({
            name: keepFieldName ? deprecatedReadOnlyBenefitsField.name : undefined,
            title: deprecatedReadOnlyBenefitsField.label,
            value: formFieldValue,
          });
        }
        default: {
          const fieldFormatDisplay = fieldTypesTransformations[field.type]?.formatDisplay;

          if (fieldFormatDisplay) {
            const fieldValue =
              ([supportedTypes.MONEY] as unknown as SupportedFieldTypes).includes(field.type) ||
              castFieldTo<MoneyField>(field).currency // sometimes fields in EXTRA type may have money inputs, the currency attr will be set in that case
                ? formFieldValue // for money values, we take the API value (in cents)
                : pickKey(defaultValues, field.name);

            value = fieldFormatDisplay(field)(fieldValue, values);
            break;
          }

          value = defaultValues[field.name];
          break;
        }
      }

      // This is specific for BEL experience_level field as we want the deprecated pill only for this particular field in first iteration.
      // To have the Pill working for other deprecated fields, remove the conditional
      const isBelgiumExperienceLevelField =
        field.name === 'experience_level' &&
        fields.find((f) => f.name === 'experience_level_class');

      return {
        title: field.label,
        ...(keepFieldName && {
          name: field.name,
        }),
        ...(keepFieldDescription &&
          field.description && {
            description: field.description,
          }),
        value,
        ...(castFieldTo<TextField>(field).maskSecret && {
          maskSecret: castFieldTo<TextField>(field).maskSecret,
        }),
        ...(!field.required && {
          isOptional: !field.required,
        }),
        ...(keepDeprecated &&
          field.deprecated &&
          isBelgiumExperienceLevelField && {
            isDeprecated: true,
          }),
        ...(castFieldTo<TextField>(field).copyValue && {
          copyValue: castFieldTo<TextField>(field).copyValue,
        }),
        pillTone,
      };
    })
    .flat();

  return display;
};

/**
 * Recursively extracts fieldsets' fields values and maps them to the field name
 * For nested fields that are fieldsets with valueGroupingDisabled, the values
 * are extracted one level up
 *
 * @param {Array} fields - Fieldset fields configuration.
 * @param {Object} formValues - List with form values { name: value }.
 * @return {Object} – Raw form values mapped to the field name
 */
function extractFieldsetFieldsValues(fields: FormField[], formValues: Record<string, unknown>) {
  return fields.reduce<Record<string, any>>((nestedAcc, subField) => {
    const isFieldsetValueGroupingDisabled =
      subField.type === supportedTypes.FIELDSET && subField.valueGroupingDisabled;

    if (isFieldsetValueGroupingDisabled) {
      Object.assign(nestedAcc, extractFieldsetFieldsValues(subField.fields, formValues));
    } else if (Object.prototype.hasOwnProperty.call(formValues, subField.name!)) {
      nestedAcc[subField.name!] = formValues[subField.name!];
    }

    return nestedAcc;
  }, {});
}

/**
 * Convert form fields values to have the correct format for the API.
 * @param {Object} formValues - List with form values { name: value }.
 * @param {Array} fields - Respective form fields configuration.
 * @returns {Object} values - The values for the API.
 * @example (if MONEY field) 500.50 -> 50050
 */
export function parseFormValuesToAPI(formValues: Record<string, any> = {}, fields: FormField[]) {
  const filteredFields = fields.filter(
    (field) =>
      formValues[field.name!] ||
      (field.type === supportedTypes.FIELDSET && field.valueGroupingDisabled)
  );

  const parsedFormValues = filteredFields.reduce(
    (acc, field) => {
      switch (field.type) {
        case supportedTypes.FIELDSET: {
          const fieldset = field;
          if (fieldset.valueGroupingDisabled) {
            const nestedFormValues = extractFieldsetFieldsValues(fieldset.fields, formValues);

            Object.assign(acc, parseFormValuesToAPI(nestedFormValues, fieldset.fields));
          } else {
            acc[field.name!] = parseFormValuesToAPI(formValues[field.name!], fieldset.fields);
          }
          break;
        }

        case supportedTypes.TEXTAREA:
        case supportedTypes.TEXT: {
          // Attempt to remove null bytes from form values - https://gitlab.com/remote-com/employ-starbase/tracker/-/issues/10670
          acc[field.name] = replace(formValues[field.name], /\0/g, '');
          break;
        }

        case supportedTypes.GROUP_ARRAY: {
          // NOTE: The field `name` in group arrays represents a path, but we only
          // need the last part of it which is represented by `nameKey`.

          const transformedFields = field?.fields?.().map((subField) => ({
            ...subField,
            name: subField.nameKey || '',
          }));

          // Null check necessary for case where no fields are set due to optional check
          const parsedFieldValues = formValues[field.name]?.map(
            (fieldValues: Record<string, any>) =>
              parseFormValuesToAPI(fieldValues, transformedFields as FormField[])
          );

          acc[field.name] = parsedFieldValues;
          break;
        }
        case supportedTypes.EXTRA: {
          const extraField = field;
          if (extraField.includeValueToApi !== false) {
            const formValue = formValues[extraField.name];
            const fieldTransformValueToAPI =
              extraField?.transformValueToAPI ||
              fieldTypesTransformations[extraField.type]?.transformValueToAPI;

            logErrorOnMissingComplimentaryParams(field);

            if (fieldTransformValueToAPI) {
              acc[extraField.name] = fieldTransformValueToAPI(field)(formValue);
              break;
            }

            acc[extraField.name] = formValue;
            break;
          }
          acc[extraField.name] = undefined;
          break;
        }
        default: {
          const formValue = formValues[field.name];
          const fieldTransformValueToAPI =
            field?.transformValueToAPI ||
            fieldTypesTransformations[field.type]?.transformValueToAPI;
          logErrorOnMissingComplimentaryParams(field);
          if (fieldTransformValueToAPI) {
            acc[field.name] = fieldTransformValueToAPI(field)(formValue);
            break;
          }

          acc[field.name] = formValue;
          break;
        }
      }

      // this occurs when const === default in a JSON Schema for a given field.
      // without this, values such as money types won't use the correct value.
      if (field.forcedValue !== undefined) {
        acc[field.name!] = field.forcedValue;
      }

      return acc;
    },
    { ...formValues }
  );

  return parsedFormValues;
}

/**
 * Common attributes for supportedTypes.MONEY
 * @deprecated - use composeFieldMoney instead
 */
export const moneyFieldBase = {
  type: supportedTypes.MONEY,
};

/**
 * Given a list of form values, returns the ones that were really asked
 * In other words, it excludes the values which the respective field
 * was hidden, based on its visibilityCondition config.
 * @param {Object} values - List with form values { name: value }.
 * @param {Array} fields - Respective form fields configuration.
 * @param {Boolean} keepTruthyInvisibleValues - If true, keep invisible values that are truthy. Useful for preserving conditional fields.
 * @param {String} parentFieldKeyPath - The key path of the parent field. Used recursively for nested fields.
 */
export function excludeValuesInvisible(
  values: any,
  fields: FormField[],
  keepTruthyInvisibleValues?: boolean,
  parentFieldKeyPath?: string
) {
  const valuesAsked: Record<string, any> = {};

  fields
    .map((field) => applyFieldDynamicProperties(field, values))
    .forEach((field) => {
      let fieldKeyPath = field.name;
      if (parentFieldKeyPath) {
        fieldKeyPath = fieldKeyPath ? `${parentFieldKeyPath}.${field.name}` : parentFieldKeyPath;
      }

      const valueOfField = get(values, fieldKeyPath!);

      // keepTruthyInvisibleValues: false/undefined -> remove invisible field
      // keepTruthyInvisibleValues: true -> keep invisible field if it has a value
      if (!isFieldVisible(field, values) && !(keepTruthyInvisibleValues && !!valueOfField)) {
        return;
      }

      if (field.meta?.ignoreValue) {
        return;
      }

      if (field.type === supportedTypes.FIELDSET && field.valueGroupingDisabled) {
        Object.assign(
          valuesAsked,
          excludeValuesInvisible(values, field.fields, keepTruthyInvisibleValues, fieldKeyPath)
        );
      } else if (Array.isArray(castFieldTo<FieldsetField>(field).fields)) {
        valuesAsked[field.name!] = excludeValuesInvisible(
          values,
          castFieldTo<FieldsetField>(field).fields,
          keepTruthyInvisibleValues,
          fieldKeyPath
        );
      } else {
        if (isUndefined(valueOfField)) {
          return;
        }
        valuesAsked[field.name!] = valueOfField;
      }
    });

  return valuesAsked;
}

/**
 * Given a list of form values, transform values that are empty strings to null.
 * This ensures it matches EOR API JSON Schema for optional fields.
 * It's critical that we send `null` to ensure it overrides
 * any existing value already stored in the DB.
 * @param {Object} values - List with form values (eg { bonus: 'none' }.
 */
export function convertEmptyStringsToNull(values: Record<string, any>) {
  const result: Record<string, any> = {};

  Object.entries(values).forEach(([key, value]) => {
    if (isPlainObject(value)) {
      result[key] = convertEmptyStringsToNull(value);
    } else if (Array.isArray(value)) {
      result[key] = value.map((val) => (isPlainObject(val) ? convertEmptyStringsToNull(val) : val));
    } else {
      result[key] = value === '' ? null : value;
    }
  });

  return result;
}

// Yup's trim transformation does not work with Formik
// This is a work around, see https://github.com/jaredpalmer/formik/issues/473
/**
 * Given a list of form values, transform values that are strings to trim them.
 * @param {Object} values - List with form values (eg { bonus: 'none' }.
 */
export const trimStringValues = (values: Record<string, any>) =>
  Object.entries(values || {}).reduce<Record<string, any>>((result, [key, value]) => {
    if (isPlainObject(value)) {
      result[key] = trimStringValues(value || {});
    } else result[key] = typeof value === 'string' ? value.trim() : value;
    return result;
  }, {});

/**
 * Given a list of form values, modify the ones that are readOnly,
 * based on their field config, by adding its defaultValue.
 * This is needed to support readOnly fields that are also conditional
 * based on the "pivotName" workaround.
 * @param {Object} values - List with form values { name: value }.
 * @param {Array} fields - Respective form fields configuration.
 */
function prefillReadOnlyFields(values: Record<string, any>, fields: FormField[]) {
  const newValues: Record<string, any> = {};

  fields.forEach((field) => {
    const fieldName = field.name;

    if (
      !Object.prototype.hasOwnProperty.call(values, fieldName!) &&
      !(field.type === supportedTypes.FIELDSET && field.valueGroupingDisabled)
    )
      return;

    if (field.type === supportedTypes.FIELDSET && field.valueGroupingDisabled) {
      Object.assign(newValues, prefillReadOnlyFields(values, field.fields));
      return;
    }

    if (field.readOnly && field.defaultValue) {
      newValues[fieldName!] = field.defaultValue;
    } else {
      newValues[fieldName!] = values[fieldName!];
    }
  });

  return newValues;
}

/**
 * Given a list of form values, returns the ones that were asked and not informational.
 * In other words, it excludes the values which the respective field is an `EXTRA`
 * and was not explicitly defined to be included for the Api with `includeValueToApi`.
 * @param {Object} formValues - List with form values { name: value }.
 * @param {Array} fields - Respective form fields configuration.
 * @returns {Object} formValues - List with form values { name: value }.
 */
export function excludeNonApiExtraFields(formValues: Record<string, any>, fields: FormField[]) {
  const values = { ...formValues };
  fields.forEach((field) => {
    if (field.type === supportedTypes.EXTRA && !field.includeValueToApi) {
      delete values[field.name];
    }
  });

  return values;
}

/**
 * Modifies the form values when we have a creatableOn field,
 * it sets the value of the creatableOn field and clears the value of the field that has the creatableOn property
 * @param {Object} values - form values
 * @param {Array} fields - form fields
 * @returns {Object} formValues - modified form values
 */
function parseCreatableOn(values: Record<string, any>, fields: FormField[]) {
  const newValues = merge({}, values);

  fields.forEach((field) => {
    const fieldName = field.name;
    const fieldType = field.type;
    if (
      !(
        [supportedTypes.SELECT, supportedTypes.FIELDSET] as unknown as SupportedFieldTypes
      ).includes(fieldType)
    ) {
      return;
    }
    if (fieldType === supportedTypes.FIELDSET) {
      if (!values[fieldName!]) return;
      newValues[fieldName!] = parseCreatableOn(values[fieldName!], field.fields);
    }

    const { creatableOn } = castFieldTo<SelectFieldJSF>(field);
    if (!creatableOn) return;
    const selectOptions = castFieldTo<SelectFieldJSF>(field).options;
    const isNewOption = !selectOptions?.find((option) => option.value === values[fieldName!]);
    if (isNewOption) {
      newValues[creatableOn] = values[fieldName!];
      newValues[fieldName!] = null;
    } else {
      newValues[creatableOn] = null;
    }
  });

  return newValues;
}

/**
 * Ensure the form fields from Formik are saved properly to the API
 * @param {Object} formValues - List with form values { name: value }.
 * @param {Array} fields - Respective form fields configuration.
 * @param {Boolean=} config.keepInvisibleValues
 * @returns {Object} - The correct values to the API.
 */
export function parseSubmitValues(
  formValues: Record<string, any>,
  fields: FormField[],
  config?: { keepInvisibleValues?: boolean }
) {
  const visibleFormValues = config?.keepInvisibleValues
    ? formValues
    : excludeValuesInvisible(formValues, fields);
  const convertedFormValues = parseFormValuesToAPI(visibleFormValues, fields);
  const formValuesWithTrimmedStrings = trimStringValues(convertedFormValues);
  const formValuesWithUndefined = convertEmptyStringsToNull(formValuesWithTrimmedStrings);
  const valuesWithReadOnly = prefillReadOnlyFields(formValuesWithUndefined, fields);
  const valuesWithCreatableOn = parseCreatableOn(valuesWithReadOnly, fields);
  return valuesWithCreatableOn;
}

/**
 * Parse values before they are passed to validateYupToFormik()
 * @param {Object} formValues
 * @param {Object[]} fields
 * @param {Object=} config
 * @param {Boolean=} config.isPartialValidation
 */
export function parseJSFToValidateFormik(
  formValues: Record<string, any>,
  fields: FormField[],
  config: { isPartialValidation: boolean }
) {
  const valuesParsed = parseSubmitValues(formValues, fields, {
    /* We cannot exclude invisible fields (excludeValuesInvisible) because
      they are needed for conditional fields validations. Know more at MR !19134 */
    keepInvisibleValues: config?.isPartialValidation,
  });
  return valuesParsed;
}

/**
 * Parse composeField* values before they are passed to validateYupToFormik()
 * @param {Object} values
 */
export function parseComposeToValidateFormik(values: Record<string, any>) {
  // Handle empty optional fields — we need to convert string to null
  // so that existing schemas work properly. Detailed explanations at https://gitlab.com/remote-com/employ-starbase/dragon/-/merge_requests/9433#note_1080888676
  const valuesParsed = convertEmptyStringsToNull(values);
  return valuesParsed;
}

/**
 * Returns a function that given the initial value, returns it if not null, if null, returns an empty string.
 * @return { (initialValue:String) => String | any}
 */
export function fieldDefaultValueFunction() {
  return function value(initialValue: string) {
    if (initialValue == null) return '';
    return initialValue;
  };
}

/**
 * Returns an object of all keys: true for Formik initialTouched prop
 * @param {Object} initialValues - Contains all fields mapped to values (from getInitialValues)
 * @example { username: 'xpto', address: 'Porto' } -> { username: true, city: true }
 */
export function setFieldsAsTouched(initialValues: Record<string, any>) {
  const result: Record<string, boolean | Record<string, boolean>> = {};
  Object.keys(initialValues).forEach((key) => {
    // Fieldsets are objects. If the property is an object, set the inner keys as touched. If we don't do this fieldset properties don't get set as touched.
    if (typeof initialValues[key] === 'object') {
      result[key] = result[key] || {};
      Object.keys(initialValues[key]).forEach((innerKey) => {
        (result[key] as Record<string, boolean>)[innerKey] = true;
      });
    } else {
      result[key] = true;
    }
  });
  return result;
}

/**
 * Transforms a select option object to support both json-schema and react-select signatures
 * @example { disabled:true } => { isDisabled:true }
 * @param {Object} option
 * @return {Object}
 */
export function transformSelectOption<
  TOption extends {
    [Property in keyof TOption]: TOption[Property];
  } & {
    disabled?: boolean;
  }
>(option: TOption) {
  if (!option.disabled) {
    return option;
  }

  const { disabled, ...optionRest } = option;

  return {
    ...optionRest,
    isDisabled: disabled,
  };
}

/**
 * Transforms a radio option object to support json-schema api
 * @example { recommended:true } => { pill:'Recommended' }
 * @param {Object} option
 * @return {Object}
 */
export function transformRadioOption(option: RadioOption) {
  if (!option.recommended) {
    return option;
  }

  const { recommended, ...optionRest } = option;
  return {
    ...optionRest,
    pill: 'Recommended',
  };
}

/**
 * Use to validate a Formik using "Formik.validate" props
 * @param {Object} yupSchema - formik yup validation
 * @param {Object} values - formik values, they must be passed with parseToValidateFormik(values) first
 * @returns
 */
export function validateYupToFormik(
  yupSchema: { validateSync: (val: Record<string, any>, config: Record<string, any>) => void },
  values: Record<string, any>
) {
  let errors;

  try {
    yupSchema.validateSync(values, {
      abortEarly: false,
    });
  } catch (err) {
    if (err instanceof Error) {
      // Simplified Formik internals - https://github.com/jaredpalmer/formik/blob/e677bea8181f40e6762fc7e7fb009122384500c6/packages/formik/src/Formik.tsx
      // BONUS/LATER: With this granular control, we can even "filter" errors if needed.
      if (err.name === 'ValidationError') {
        errors = yupToFormErrors(err);
      } else {
        /* eslint-disable-next-line no-console */
        console.warn(`Warning: An unhandled error was caught during validationSchema`, err);
      }
    }
  }

  return errors;
}

export type HideFieldsOptions = Partial<{
  pickedFields: string[];
  omittedFields: string[];
  isJSF: boolean;
}>;
/**
 * Given a list of fields, it "hides" the ones based on the configuration.
 * Note: Fields are hidden instead of removed to ensure conditional fields based on hidden fields keep working
 * @param {Object[]} fields - list of fields - composeField or JSF
 * @param {Object} config -
 * @param {String[]=} config.pickedFields - Same as useCreateHeadlessForm.hookOptions
 * @param {String[]=} config.omittedFields - Same as useCreateHeadlessForm.hookOptions
 * @param {Boolean} config.isJSF - If the fields are from json-schema-form
 */
export function hideFields(
  fields: FormField[],
  { pickedFields, omittedFields, isJSF }: HideFieldsOptions
) {
  if (!pickedFields && !omittedFields) {
    return fields;
  }

  function getIsHidden(field: FormField) {
    let isHidden = false;
    if (omittedFields) {
      isHidden = omittedFields.includes(field.name!);
    }
    // If both omittedFields and pickedFields are used, pickedFields takes precedence as it's exclusive by default
    if (pickedFields) {
      isHidden = !pickedFields.includes(field.name!);
    }
    return isHidden;
  }

  if (isJSF) {
    fields.forEach((field) => {
      if (getIsHidden(field)) {
        // NOTE: Mutation needed. Read/watch MR!10487 to understand why.
        field.visibilityCondition = () => false;
        // field.schema = null; // removing schema does not work because it's dynamic from JSF. The error is ignored at extendedHandleValidation()
      }
    });
    return true;
  }

  return fields.map((field) => {
    if (getIsHidden(field)) {
      return {
        ...field,
        visibilityCondition: () => false,
        schema: null, // make composeField* optional - ensure any "required" validation is removed
      };
    }
    return field;
  });
}

/**
 * flattens an object e.g {name: "Joe", details: { age: 29 } } -> {"age": 29, "name": "Joe"}
 * @param {Object} obj
 * @returns Object
 */
export const flattenObject = (obj: Record<string, any>) => {
  const flattened: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });
  return flattened;
};

/** Given a JSON Schema, check if it has snake_keys fields */
export function hasSomeSnakeFields(schema: JSONSchema): boolean {
  return Object.entries(schema?.properties).some(([fieldName, field]) => {
    if (fieldName.includes('_')) {
      return true;
    }
    if (field?.properties) {
      return hasSomeSnakeFields(field as JSONSchema);
    }
    return false;
  });
}

/**
 *
 * @param {Object} formValues - formvalues from API or elsewhere
 * @returns Boolean
 */
export function checkFormIsEmpty(formValues: any) {
  if (!formValues || isEmpty(formValues)) {
    return true;
  }
  const flattenedData = flattenObject(formValues);
  return Object.values(flattenedData).every(isValueEmpty);
}

/**
 * Get fields with flat fieldsets from a fields and fieldsets configuration
 * @param {Array} fields - Respective form fields configuration.
 * @param {Object} fieldsets - Respective flat fieldsets configuration.
 * @returns {Array} - Fields configuration including flat fieldsets
 */

export function getFieldsWithFlatFieldsets({
  fields = [],
  fieldsets = {},
}: {
  fields: FormField[];
  fieldsets: Record<string, { propertiesByName: string[] }>;
}) {
  const flatFieldsetsKeys = Object.keys(fieldsets);

  if (!flatFieldsetsKeys?.length) {
    return fields;
  }

  const flatFieldsetsFieldNames = new Set(
    flatFieldsetsKeys.flatMap(
      (flatFieldsetKey) => fieldsets[flatFieldsetKey]?.propertiesByName ?? []
    )
  );

  const flatFieldsetsWithFields = flatFieldsetsKeys.map((flatFieldsetKey) => {
    const { propertiesByName: flatFieldsetFields = [], ...rest } = fieldsets[flatFieldsetKey];

    const childFields = flatFieldsetFields
      .map((name) => fields.find((f) => f.name === name))
      .filter((field): field is FormField => !!field);

    return {
      ...rest,
      id: flatFieldsetKey,
      type: supportedTypes.FIELDSET_FLAT,
      fields: childFields,
      // Hide the fieldset if none of the children fields are visible.
      visibilityCondition: (formValues: Record<string, unknown>) =>
        childFields
          .map((childField) => applyFieldDynamicProperties(childField, formValues))
          .some((childField) => isFieldVisible(childField, formValues)),
    };
  });

  const sortedFields = flatFieldsetsWithFields.reduce((accumulator, field) => {
    const accumulatedFieldsSorted = [...accumulator];

    /**
     * We place the flat fieldset at the original position of its first field.
     * If no field is found, we move it to the end.
     */
    const fieldsetPosition = field.fields[0]
      ? accumulator.findIndex((accumulatorItem) => accumulatorItem.name === field.fields[0].name)
      : accumulator.length;

    accumulatedFieldsSorted.splice(fieldsetPosition, 0, field as unknown as FormField);

    return accumulatedFieldsSorted;
  }, fields);

  const filteredFields = sortedFields.filter((field) => !flatFieldsetsFieldNames.has(field.name!));

  return filteredFields;
}

/**
 * Check if a string contains HTML tags
 * @param {string} str
 * @returns {boolean}
 */
export function containsHTML(str = '') {
  return /<[a-z][\s\S]*>/i.test(str);
}

/**
 * Wraps the given content with a <span> element and applies the specified className.
 *
 * @param {any} content - The content to be wrapped.
 * @param {string} className - The class name to be applied to the <span> element.
 * @return {ReactElement} - The wrapped content as a React element.
 */
export function wrapWithSpan(content: string, className: string) {
  return (
    <HTMLRendered Tag="span" className={className}>
      {content}
    </HTMLRendered>
  );
}

/**
 * If a field description contains HTML tags,
 * wrap it with <span>
 */
export function maybeGetDescriptionAsHtml(field: FormField) {
  return containsHTML(field.description as string)
    ? wrapWithSpan(field.description as string, 'jsf-description')
    : field.description;
}

/**
 * Maybe augments a description with a help center component which loads a Zendesk article.
 * If there is no proivded help center, check if there is a shared one from places like apps/employ/src/domains/employment/employer/contractDetails/jsfOptions.jsx
 * Else, just return the text.
 *
 * @param {Object=} field - same as json-schema-form form field
 * @returns {JSFHelpCenterDrawer} -
 */
export function maybeGetHelpCenterWithDescription(field: FormField, location?: HelpCenterLocation) {
  const description = maybeGetDescriptionAsHtml(field);

  if (field.meta?.helpCenter) {
    const {
      meta: { helpCenter },
      statement,
    } = field;

    // if the location is 'statement', then we use the statement's description
    // instead of the field's description
    const descriptionText = location === 'statement' ? statement?.description : description;

    return (
      <JSFHelpCenterDrawer
        articleId={helpCenter.id}
        articleTitle={helpCenter.title}
        articleContent={helpCenter.content}
        articleCta={helpCenter.callToAction}
        articleError={helpCenter.error}
        description={descriptionText}
      />
    );
  }
  if (field.sharedContent?.helpCenter) {
    return (
      <>
        {description} {field.sharedContent.helpCenter()}
      </>
    );
  }

  return description;
}

export function booleanToYesNo(value: null): null;
export function booleanToYesNo(value: boolean): YesNoValue;
export function booleanToYesNo(value: boolean | null): YesNoValue | null {
  if (value === true) {
    return yesNoValues.YES;
  }

  if (value === false) {
    return yesNoValues.NO;
  }

  return null;
}

export function yesNoToBoolean(value: null): null;
export function yesNoToBoolean(value: YesNoValue): boolean;
export function yesNoToBoolean(value: YesNoValue | null): boolean | null {
  if (value === yesNoValues.YES) {
    return true;
  }
  if (value === yesNoValues.NO) {
    return false;
  }

  return null;
}

/**
 * Gets a form field's details from the field path
 * @param fieldPath - The field path to get the form field information from (e.g. field1.field2.field3)
 * @param fields - The list of form fields to search through
 * @returns The form field details
 */
export const getFieldByPath = (fieldPath: string, fields: FormField[]): FormField | undefined => {
  const fieldNames = fieldPath.split('.');

  let currentFields = fields;
  let field: FormField | undefined;

  for (const fieldName of fieldNames) {
    const foundField = currentFields?.find((f) => f.name === fieldName);
    if (!foundField) {
      field = undefined;
      break;
    }

    field = foundField;
    currentFields = (foundField as FieldsetField)?.fields;
  }

  return field;
};
