import type { DefaultTheme, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components';

import { DatePickerCalendarBasicContainer, focusRing } from './BasicContainer.styled';

const dateRangeConnectorStyles = css`
  /*
   * IMPORTANT: The ::after pseudo-element is reserved for range connectors
   * throughout this component.
   * Use ::before for other styling purposes to avoid conflicts with the range
   * selection visuals.
   */
  content: '';
  position: absolute;
  z-index: -1;
  /* We extend the connector to bridge the gap between days */
  width: calc(var(--date-picker-grid-cell-width) + var(--date-picker-column-gap));
  height: 100%;
  background-color: ${({ theme }) => theme.colors.brand[50]};
  /*
   * The background (this pseudo-element) needs to be larger than the date circle
   * to create the continuous range effect. However, this could cause the hover
   * state to rapidly flicker on/off when the mouse is near the edge of the circle.
   * pointer-events: none prevents this by making the background non-interactive.
   */
  pointer-events: none;
`;

const getBorderRadiusForEdge = ({
  theme,
  edgePosition,
}: ThemeProps<DefaultTheme> & {
  edgePosition: 'leftEdge' | 'rightEdge';
}) => {
  const radius = theme.borderRadius.sm;
  const rule = edgePosition === 'leftEdge' ? `${radius} 0 0 ${radius}` : `0 ${radius} ${radius} 0`;

  return css`
    border-radius: ${rule};
  `;
};

export const DatePickerRangeContainer = styled(DatePickerCalendarBasicContainer)`
  ${({ theme }) => css`
    .react-datepicker__month {
      overflow: hidden;
    }

    .react-datepicker__week {
      /* Fix for ::after selector(gray background) being rendered behind main content (circle)
       Source: https://stackoverflow.com/a/10156261 */
      z-index: 0;
    }

    .react-datepicker__day {
      /* Circle colors for selected start/end dates in range. */
      &--selecting-range-start,
      &--range-start,
      &--range-end,
      &--selected {
        &:not(.react-datepicker__day--disabled) {
          background-color: ${theme.colors.brand[600]};
          color: ${theme.colors.white};

          --date-picker-marker-color: ${theme.colors.white};

          :hover {
            background-color: ${theme.colors.brand[700]};
          }
        }
      }

      /*
        This adds a connector to the left of the last date in the range.
      */
      &--in-range + .react-datepicker__day--range-end,
      &--in-selecting-range
        + .react-datepicker__day--in-selecting-range:is(:hover, .focus-visible) {
        &::after {
          ${dateRangeConnectorStyles}
          right: 50%;
        }
      }

      /*
        This adds a rounded corner connector to the right of the last date in
        the range when it's the first day of a week/row.
      */
      &--range-end:not(.react-datepicker__day--range-start),
      &--in-selecting-range:not(.react-datepicker__day--selecting-range-start) {
        &:first-child::after {
          ${dateRangeConnectorStyles}
          left: 50%;
          transform: translateX(-50%);
          background: linear-gradient(to right, ${theme.colors.brand[50]} 50%, transparent 50%);
          ${getBorderRadiusForEdge({ theme, edgePosition: 'leftEdge' })};
        }
      }

      /*
        This adds a connector to the right of the first date in the range.
      */
      &--selecting-range-start,
      &--range-start {
        &:has(+ :is(.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range)) {
          &::after {
            ${dateRangeConnectorStyles}
            left: 50%;
          }
        }
      }

      /*
        This adds a rounded corner connector to the left of the first date in
        the range when it's the last day of a row.
      */
      &--range-start:not(.react-datepicker__day--range-end),
      &--selecting-range-start:not(:hover, .focus-visible) {
        &:last-child::after {
          ${dateRangeConnectorStyles}
          right: 50%;
          transform: translateX(50%);
          background: linear-gradient(to left, ${theme.colors.brand[50]} 50%, transparent 50%);
          ${getBorderRadiusForEdge({ theme, edgePosition: 'rightEdge' })};
        }
      }

      /*
        This adds a connector to the middle dates in the range.
      */
      &--in-range,
      &--in-selecting-range:not(:hover, :focus),
      &--in-selecting-range.react-datepicker__day--disabled:hover {
        &:not(
            .react-datepicker__day--range-start,
            .react-datepicker__day--range-end,
            .react-datepicker__day--selecting-range-start
          ) {
          &::after {
            ${dateRangeConnectorStyles}
            left: 50%;
            transform: translateX(-50%);
          }

          /* Adding radius if start of a row */
          &:first-child::after {
            ${getBorderRadiusForEdge({ theme, edgePosition: 'leftEdge' })};
          }

          /* Adding radius if end of a row */
          &:last-child::after {
            ${getBorderRadiusForEdge({ theme, edgePosition: 'rightEdge' })};
          }
        }
      }

      /* This adds the "selecting" style to the last date in the range. */
      &--in-selecting-range:is(:hover, :focus):not(:is(&--selecting-range-start, &--disabled)) {
        background-color: ${theme.colors.white};
        border: 1px dashed ${theme.colors.brand[600]};
        color: ${theme.colors.brand[600]};

        &::after {
          /* The dashed border steals 2px from the colored background, so we add them back */
          height: calc(100% + 2px);
        }
      }

      /* When disabled days are in the selection, we want to display a yellow circle */
      &--in-range.react-datepicker__day--disabled {
        color: ${theme.colors.grey[900]};

        ::before {
          content: '';
          position: absolute;
          background-color: ${theme.colors.yellow[400]};
          border-radius: ${theme.borderRadius.full};
          height: var(--date-picker-circle-size);
          width: var(--date-picker-circle-size);
          z-index: var(--date-picker-z-index-disabled);
        }

        &:hover::before {
          background-color: ${theme.colors.yellow[400]};
        }

        &.focus-visible {
          box-shadow: none;

          &::before {
            ${focusRing({ theme, outerColor: theme.colors.yellow[700] })}
            background-color: ${theme.colors.yellow[400]};
          }
        }
      }

      &.focus-visible:is(&--range-start, &--range-end) {
        ${focusRing}
      }

      /* Focused days within the range but not at the start or end */
      &.focus-visible:is(&--in-range):not(:is(&--range-start, &--range-end, &--disabled)) {
        box-shadow: none;
      }
    }
  `}
`;
