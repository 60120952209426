import type { UseGetOptions } from '@remote-com/data-layer';
import { useGet } from '@remote-com/data-layer';

export function useGetUserSettingsFavorites({
  options,
}: {
  options?: UseGetOptions<'/api/v1/account/settings/favorites'>;
} = {}) {
  return useGet('/api/v1/account/settings/favorites', {
    options: {
      ...options,
      enabled: options?.enabled,
      select: (response) => {
        return response.data;
      },
    },
  });
}
