import { generateSelectOptions } from '@/src/helpers/forms';

export const REPORT_TYPE = {
  AUTOMATED_GROSS_TO_NET: 'automated_gross_to_net',
  GROSS_TO_NET: 'gross_to_net',
  CUSTOMER_GROSS_TO_NET: 'customer_gross_to_net',
} as const;

export type ReportType = (typeof REPORT_TYPE)[keyof typeof REPORT_TYPE];

export const REPORT_TYPE_LABELS = {
  [REPORT_TYPE.AUTOMATED_GROSS_TO_NET]: 'Automated gross to net',
  [REPORT_TYPE.GROSS_TO_NET]: 'Gross to net',
  [REPORT_TYPE.CUSTOMER_GROSS_TO_NET]: 'Customer gross to net',
};

export const employmentCompanyType = {
  internal: 'true',
  external: 'false',
};

export const employmentCompanyTypeLabels = {
  [employmentCompanyType.internal]: 'Remote',
  [employmentCompanyType.external]: 'My company',
};

export const employmentCompanyTypeOptions = generateSelectOptions(employmentCompanyTypeLabels);

export const columnAccessorExclusionSet = new Set([
  'name',
  'type',
  'files',
  'slug',
  'legalEntity.name',
  'legalEntity.address.state',
  'legalEntity.address.address',
  'legalEntity.address.country.code',
  'legalEntity.address.country.name',
  'legalEntity.address.country.features',
  'legalEntity.address.country.slug',
  'legalEntity.address.country.alpha2Code',
  'legalEntity.address.addressLine2',
  'legalEntity.address.city',
  'legalEntity.address.postalCode',
  'legalEntity.slug',
  'legalEntity.company',
  'legalEntity.payElements',
  'legalEntity.allowedContractTypes',
  'legalEntity.onboardingTasks',
  'createdAt',
  'periodStart',
  'periodEnd',
  'productType',
]);
