import {
  Avatar,
  Badge,
  Box,
  CopyToClipboard,
  Media,
  MediaBody,
  MediaObject,
  Pill,
  Stack,
  Tooltip,
  Text,
  EMPTY_INDICATOR,
  DropdownMenu,
} from '@remote-com/norma';
import { IconArrowUpRight } from '@remote-com/norma/icons/IconArrowUpRight';
import { IconCheckCircle } from '@remote-com/norma/icons/IconCheckCircle';
import { IconClock } from '@remote-com/norma/icons/IconClock';
import { IconInfo } from '@remote-com/norma/icons/IconInfo';
import { IconUploadBase } from '@remote-com/norma/icons/IconUploadBase';
import { IconV2OutlineChevronRight } from '@remote-com/norma/icons/IconV2OutlineChevronRight';
import { IconV2OutlineEye } from '@remote-com/norma/icons/IconV2OutlineEye';
import { isFuture, parseISO } from 'date-fns';
import isFunction from 'lodash/isFunction';
import isInteger from 'lodash/isInteger';
import isObject from 'lodash/isObject';
import PropTypes from 'prop-types';
import { isValidElement, forwardRef } from 'react';
import styled from 'styled-components';

import { Button, ButtonIcon, ButtonInline } from '@/src/components/Button';
import { MenuItem } from '@/src/components/DropdownMenu';
import { usePersonEventsCellPill } from '@/src/components/Table/helpers/PersonEventsCell.helpers';
import Timestamp from '@/src/components/Timestamp';
import { Checkbox } from '@/src/components/Ui/Form/Checkbox';
import { CheckboxState } from '@/src/components/Ui/Form/InputCheckbox.styled';
import UserCan from '@/src/components/UserCan';
import { useUser } from '@/src/components/UserProvider/context';
import {
  ADMIN_COMPANIES_PROFILE_ROUTE,
  ADMIN_INVOICE_DETAILS_ROUTE,
  ADMIN_USER_DETAILS_ROUTE,
  BILLING_INVOICE_DETAILS,
  USER_PROFILE_ROUTE,
} from '@/src/constants/routes';
import {
  adminRoutePermissionsMap,
  dashboardRoutePermissionsMap,
} from '@/src/constants/routesPermissions';
import { AccountLockedIndicator } from '@/src/domains/account/AccountLockedIndicator';
import CountryLabel from '@/src/domains/countries/CountryLabel';
import { generateAndDownloadDocument, getPreviewPageUrl } from '@/src/domains/files/helpers';
import {
  employeeInvoiceStatusBadges,
  employeeInvoiceStatusLabels,
} from '@/src/domains/invoices/constants';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { isAdmin } from '@/src/domains/registration/auth/helpers';
import {
  friendlyMoney,
  friendlyMoneyPair,
  friendlyMoneyWithCurrencyCode,
  friendlyMoneyWithCurrencyCodeAndSymbol,
  friendlyMoneyWithoutCurrency,
} from '@/src/helpers/currency';
import {
  formatMonthDayYear,
  formatDateAsLocalizedTimeAgo,
  formatLastUpdatedDate,
  displayDuration,
} from '@/src/helpers/date';
import { multilineTruncate } from '@/src/helpers/general';

import { Error } from './ErrorCell';
import { isTextAlignedRight, TextCell, AlignRight } from './TextCell';

const Strong = styled.strong`
  ${({ theme }) => theme.typography.smMedium}
  color: ${({ theme }) => theme.colors.grey[900]};
`;

export const AlignCenter = styled(Box)`
  text-align: center;
`;

export const AlignedSvg = styled.span`
  svg {
    vertical-align: middle;
  }
`;

export const BaseTextCell = styled(Text).attrs({
  color: 'grey.900',
  variant: 'smMedium',
})``;

export const ExtraSmallTextCell = styled(Text).attrs({
  color: 'grey.900',
  variant: 'xs',
})``;

export const LinkTextCell = styled(Text).attrs({
  color: 'grey.900',
  variant: 'sm',
})`
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-color: ${({ theme }) => theme.colors.grey[300]};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TableCheckBox = styled(Checkbox)`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  justify-content: center;
  align-items: center;

  ${CheckboxState} {
    margin: 0 auto;
  }
`;

const Wrap = styled(Box)`
  overflow-wrap: break-word;
  white-space: normal;
`;

export const EllipsesMedia = styled(MediaBody).attrs({ direction: 'row', gap: 2 })`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const EmptyCell = () => <div aria-hidden="true">{EMPTY_INDICATOR}</div>;

const RightCell = forwardRef((props, ref) => (
  <Box {...props} display="flex" justifyContent="flex-end" ref={ref} />
));

/** Returns a comma-separated string of array values formatted with a given formatter, if present */
export const ArrayCell = ({ value: array, formatter, rowData }) =>
  array ? (
    <>{array.map((value) => (formatter ? formatter(value, rowData) : value)).join(', ')}</>
  ) : (
    <EmptyCell />
  );

/** Returns a currency type cell, e.g. "United States Dollar (USD)" */
export const CurrencyTypeCell = ({ value }) =>
  value ? <>{`${value.name} (${value.code})`}</> : <EmptyCell />;

export const StatusCell = ({ value }) => {
  if (value) {
    const { label, accountLocked = false, ...props } = value;

    return (
      <Stack direction="row" gap={3}>
        {label ? <Badge label={label} {...props} /> : EMPTY_INDICATOR}
        <AccountLockedIndicator accountLocked={accountLocked} />
      </Stack>
    );
  }

  return <EmptyCell />;
};
export const PrimaryTextCell = ({ value }) => <Strong>{value}</Strong>;

export const DateCell = ({ value }) => {
  return <>{formatMonthDayYear(value)}</>;
};

export const TimeTrackingTotalHoursCell = ({ value }) => {
  return <>{displayDuration(value, { friendlyLabel: true })}</>;
};

export const DateWithTimeCell = ({ value }) => {
  return <>{formatLastUpdatedDate(value)}</>;
};

export const DateCellLocalizedTimeAgo = ({ value }) => {
  return <>{formatDateAsLocalizedTimeAgo(value)}</>;
};

/**
 *
 * shows a clock icon next to formatted date if it is in the future
 */
export const MaybeFutureDateCell = ({ value }) => {
  const isFutureDate = isFuture(parseISO(value));

  return (
    <Stack direction="row" gap={3} alignItems="center">
      {isFutureDate && <IconClock width={16} height={16} />}
      <DateCell value={value} />
    </Stack>
  );
};

export const WrapCell = ({ value }) => <Wrap>{value}</Wrap>;

export const AutoCell = (props) => {
  const propsOverride = { ...props };

  if (isObject(props.value)) {
    propsOverride.value = JSON.stringify(props.value);
  }

  if (typeof props.value === 'boolean') {
    propsOverride.value = props.value.toString();
  }

  return <TextCell {...propsOverride} />;
};

export const MoneyCell = ({ value }) => {
  const { amount, currency, amountTaxType } = value || {};
  if (isInteger(Number(amount)) && currency) {
    return (
      <AlignRight>
        {friendlyMoney(amount, currency)}
        {amountTaxType && ` (${amountTaxType})`}
      </AlignRight>
    );
  }

  return (
    <AlignRight>
      <EmptyCell />
    </AlignRight>
  );
};

export const MoneyPairCell = ({ value, ...rest }) => (
  <TextCell {...rest} value={friendlyMoneyPair(value)} />
);

/**
 * Like MoneyPairCell unless source and converted currencies are the same,
 * which use MoneyCell.
 *
 * Important: this expects a money pair value!
 */
export const MoneyPairOrMoneyCell = ({ value, ...rest }) => {
  // We technically doesn't need to explicitly return an EmptyCell here as both
  // MoneyPair and MoneyPairCell have good fallback. However it's better to
  // explicitly handle here so the type works well if we migrate this to
  // TypeScript later
  if (!value) {
    return (
      <AlignRight>
        <EmptyCell />
      </AlignRight>
    );
  }

  if (value.sourceCurrency.code === value.convertedCurrency.code) {
    return (
      <MoneyCell
        {...rest}
        value={{ amount: value.convertedAmount, currency: value.convertedCurrency }}
      />
    );
  }

  return <MoneyPairCell {...rest} value={value} />;
};

export const MoneyWithCurrencyCell = ({ value }) => {
  const { amount, currency } = value || {};
  if (isInteger(Number(amount)) && currency && currency.code) {
    return <AlignRight>{friendlyMoneyWithCurrencyCodeAndSymbol(amount, currency)}</AlignRight>;
  }

  return <AlignRight>{friendlyMoneyWithoutCurrency(amount)}</AlignRight>;
};

export const MoneyWithCurrencyCodeCell = ({ value }) => {
  const { amount, currency } = value || {};
  if (isInteger(Number(amount)) && currency && currency.code) {
    return <AlignRight>{friendlyMoneyWithCurrencyCode(amount, currency, 'right')}</AlignRight>;
  }

  return <AlignRight>{friendlyMoneyWithoutCurrency(amount)}</AlignRight>;
};

export const CountryCell = ({ value, ...props }) => (
  <>{value?.name ? <CountryLabel name={value?.name} {...props} /> : EMPTY_INDICATOR}</>
);
export const CountryErrorCell = ({ value, errors }) => {
  if (!value) {
    return <Pill tone="error">required</Pill>;
  }

  return (
    <>
      {errors ? (
        <Tooltip label={errors}>
          <Error variant="raw">
            {value ? <CountryLabel name={value} /> : value}
            <Box marginLeft={2} alignItems="center" display="flex">
              <IconInfo width="15px" height="15px" />
            </Box>
          </Error>
        </Tooltip>
      ) : (
        <CountryLabel name={value} />
      )}
    </>
  );
};

/**
 * Renders a cell with a person's avatar.
 *
 * @param {Object} props - The component props.
 * @param {Object|null|undefined} props.value - The user object containing person information.
 * @param {any} [props.extra] - Optional extra information to be displayed.
 * @param {boolean} [props.wrapNameInLink=true] - Whether to wrap the name in a link.
 * @returns {JSX.Element} The rendered cell component.
 */
export const PersonCell = ({ value: user, extra, wrapNameInLink = true }) => {
  if (user) {
    const personName = user.name || user.fullName;

    if (!personName) {
      return <EmptyCell />;
    }

    return (
      <Media
        $center
        style={{
          '--mediaObjectSpacing': '10px',
          '--flexWrap': 'nowrap',
          '--alignItems': 'center',
        }}
        // Prevent user name from leaking to Datadog RUM:
        data-dd-action-name={user.slug ? `Person cell, slug ${user.slug}` : 'Person cell'}
      >
        <MediaObject $preserveSize>
          <Avatar
            aria-hidden
            src={user.profilePicture ?? user.photo}
            fallback={personName}
            size="xs"
          />
        </MediaObject>
        <EllipsesMedia>
          {wrapNameInLink ? (
            <LinkTextCell>{personName}</LinkTextCell>
          ) : (
            <BaseTextCell>{personName}</BaseTextCell>
          )}
          {!!extra && extra}
        </EllipsesMedia>
      </Media>
    );
  }

  return <EmptyCell />;
};

export const PersonEventsCell = ({
  user,
  payrollRun = null,
  isPayrollRunEvents = false,
  ...rowData
}) => {
  const pill = usePersonEventsCellPill({ data: rowData, payrollRun, isPayrollRunEvents });

  return (
    <PersonLinkCell
      value={user}
      extra={pill ? <Pill tone={pill?.tone}>{pill?.label}</Pill> : null}
    />
  );
};

export const PersonLinkCell = ({
  value: user,
  employment = {},
  trackNavigation = [],
  pill = undefined,
  wrapNameInLink = true,
  queryParams = undefined,
  extra = undefined,
  ...rest
}) => {
  const { user: currentUser } = useUser();

  const personCellProps = {
    value: user,
    extra,
  };

  // `PersonLinkCell` will fallback to `PersonCell` if the current user does not
  // have the required permissions to view the user's profile page (both from the
  // Remote admin and employer perspectives).
  const restrictedLinkCell = isAdmin(currentUser) ? (
    <UserCan
      permissions={adminRoutePermissionsMap[ADMIN_USER_DETAILS_ROUTE].permissions}
      operator={adminRoutePermissionsMap[ADMIN_USER_DETAILS_ROUTE].operator}
      additionalCondition={!!user?.slug}
    >
      {({ hasAccess }) => {
        if (hasAccess) {
          return (
            <Button
              href={{
                pathname: ADMIN_USER_DETAILS_ROUTE,
                query: {
                  userSlug: user.slug,
                  ...queryParams,
                },
              }}
              onClick={(e) => e.stopPropagation()}
              trackNavigation={trackNavigation}
              {...rest}
              variant="raw"
            >
              <PersonCell {...personCellProps} wrapNameInLink={wrapNameInLink} />
            </Button>
          );
        }

        // No permission to view the details page, so we just display the name.
        return <PersonCell {...personCellProps} wrapNameInLink={false} />;
      }}
    </UserCan>
  ) : (
    <UserCan
      permissions={dashboardRoutePermissionsMap[USER_PROFILE_ROUTE].permissions}
      operator={dashboardRoutePermissionsMap[USER_PROFILE_ROUTE].operator}
      additionalCondition={!!employment?.slug}
    >
      {({ hasAccess }) => {
        if (hasAccess) {
          return (
            <Button
              href={{
                pathname: USER_PROFILE_ROUTE,
                query: {
                  employmentSlug: employment.slug,
                  ...queryParams,
                },
              }}
              onClick={(e) => e.stopPropagation()}
              trackNavigation={trackNavigation}
              {...rest}
              variant="raw"
            >
              <PersonCell {...personCellProps} wrapNameInLink={wrapNameInLink} />
            </Button>
          );
        }

        // No permission to view the profile page, so we just display the name.
        return <PersonCell {...personCellProps} wrapNameInLink={false} />;
      }}
    </UserCan>
  );

  if (pill) {
    return (
      <Box display="inline-flex" alignItems="center" gap={3}>
        {restrictedLinkCell}
        {isValidElement(pill) ? pill : <Pill tone={pill.tone}>{pill.label}</Pill>}
      </Box>
    );
  }

  return restrictedLinkCell;
};

PersonLinkCell.propTypes = {
  value: PropTypes.object.isRequired,
  employment: PropTypes.object,
  trackNavigation: PropTypes.array,
  wrapNameInLink: PropTypes.bool,
  pill: PropTypes.node,
  queryParams: PropTypes.object,
};

const StyledArrowUpRightIcon = styled(IconArrowUpRight)`
  width: 20px;
  margin-left: 8px;
`;

const StyledIconUploadBase = styled(IconUploadBase)`
  width: 20px;
  margin-left: 8px;
`;

const StyledIconCheckCircle = styled(IconCheckCircle)`
  width: 20px;
  margin-left: 8px;
`;

export const CELL_ACTION_TYPES = {
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
  OPT_OUT: 'optOut',
};

const FileCellIcon = ({ isUpload, isOptOut }) => {
  if (isOptOut) return <StyledIconCheckCircle />;
  if (isUpload) return <StyledIconUploadBase />;
  return <StyledArrowUpRightIcon />;
};

export const FileCell = ({ value: file, column: { cellProps = {} }, onUploadClick }) => {
  const { label = file.name, actionType } = cellProps;
  const isDownload = actionType === CELL_ACTION_TYPES.DOWNLOAD;
  const isUpload = actionType === CELL_ACTION_TYPES.UPLOAD;
  const isOptOut = actionType === CELL_ACTION_TYPES.OPT_OUT;

  if (!label || (isDownload && !file.slug)) {
    return <EmptyCell />;
  }

  const buttonProps = {};
  if (isDownload) {
    buttonProps.onClick = (event) => {
      event.stopPropagation();
      generateAndDownloadDocument(file);
    };
  } else if (isUpload || isOptOut) {
    buttonProps.onClick = (event) => {
      event.stopPropagation();
      onUploadClick(event);
    };
  } else {
    buttonProps.href = getPreviewPageUrl({ fileSlug: file?.slug });
  }

  return (
    <ButtonInline {...buttonProps}>
      {label}
      {!isDownload && (
        <AlignedSvg>
          <FileCellIcon isUpload={isUpload} isOptOut={isOptOut} />
        </AlignedSvg>
      )}
    </ButtonInline>
  );
};

export const CheckboxCell = ({
  row,
  disabled = false,
  onRowSelectableChange,
  disabledCheckboxTooltip,
}) => {
  // The `indeterminate` attribute isn't supported by browsers yet
  // eslint-disable-next-line no-unused-vars
  const { indeterminate, checked, onChange, ...props } = row.getToggleRowSelectedProps();

  function handleOnChange(ev) {
    onChange(ev);
    if (isFunction(onRowSelectableChange)) {
      onRowSelectableChange(row, ev.target.checked);
    }
  }

  const checkbox = (
    <TableCheckBox
      as="label"
      onClick={(e) => {
        // we are stopping event propagation here to
        // prevent onClick event on the table row(if rows are clickable)
        e.stopPropagation();
      }}
    >
      <input
        type="checkbox"
        {...props}
        data-testid="table-select-row-button"
        onChange={handleOnChange}
        checked={checked && !disabled}
        disabled={disabled}
        readOnly={disabled}
      />
      <CheckboxState $size="sm" disabled={disabled} />
    </TableCheckBox>
  );

  if (disabled && disabledCheckboxTooltip) {
    return (
      <Tooltip
        label={disabledCheckboxTooltip}
        styles={{ wrapper: ['transform: translateY(18px)'] }}
      >
        {checkbox}
      </Tooltip>
    );
  }

  return checkbox;
};

export function ToggleAllCheckbox(props) {
  const {
    toggleAllPageRowsSelected,
    isAllPageRowsSelected,
    disabled = false,
    onAllRowsSelectableChange,
    disabledCheckboxTooltip,
    page,
    isCheckboxDisabled,
  } = props;
  const checkbox = (
    <TableCheckBox as="label" $disabled={disabled}>
      <input
        type="checkbox"
        checked={isAllPageRowsSelected}
        onChange={() => {
          if (isFunction(onAllRowsSelectableChange)) {
            const selectedRows =
              typeof isCheckboxDisabled === 'function'
                ? page.filter((row) => !isCheckboxDisabled(row))
                : page;
            onAllRowsSelectableChange(selectedRows, !isAllPageRowsSelected);
          }
          toggleAllPageRowsSelected(!isAllPageRowsSelected);
        }}
        data-testid="table-header-select-page-rows-toggle"
        disabled={disabled}
        readOnly={disabled}
      />
      <CheckboxState $size="sm" disabled={disabled} readOnly={disabled} />
    </TableCheckBox>
  );

  if (disabled && disabledCheckboxTooltip) {
    return (
      <Tooltip
        label={disabledCheckboxTooltip}
        styles={{ wrapper: ['transform: translateY(18px)'] }}
      >
        {checkbox}
      </Tooltip>
    );
  }

  return checkbox;
}

export const CurrencyCells = new Set([MoneyCell, MoneyWithCurrencyCell, MoneyWithCurrencyCodeCell]);

/**
 * We want to align some of the TableRows to the right. This is
 * done automatically for Number and Money cells, for custom
 * cells this can be done by using align property on the column.
 */
export const CellsAlignedToTheRight = CurrencyCells;

const stopAndPrevent = (event) => {
  if (event.currentTarget.dataset.stopPropagation) event.stopPropagation();
  if (event.currentTarget.dataset.preventDefault) event.preventDefault();
};

const BaseLinkCell = ({
  value,
  column: { cellProps },
  trackNavigation,
  stopPropagation,
  preventDefault,
}) => {
  const buttonProps = { ...cellProps, ...value, trackNavigation };
  const Wrapper = ({ children }) =>
    stopPropagation || preventDefault ? (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <span
        onClick={stopAndPrevent}
        data-stop-propagation={stopPropagation}
        data-prevent-default={preventDefault}
      >
        {children}
      </span>
    ) : (
      children
    );

  return (
    <Wrapper>
      <ButtonInline {...buttonProps}>
        <LinkTextCell>{value.children}</LinkTextCell>
      </ButtonInline>
    </Wrapper>
  );
};

export const LinkCell = ({ ...rest }) => {
  const { column: { align } = {} } = rest;
  if (isTextAlignedRight(align)) {
    return (
      <RightCell>
        <BaseLinkCell {...rest} />
      </RightCell>
    );
  }
  return <BaseLinkCell {...rest} />;
};

/**
 * @param {Object} props
 * @param {any} props.value
 * @param {Object} props.column
 * @param {unknown} [props.column.cellProps]
 * @param {any} [props.trackNavigation]
 */
export const AdminCompanyProfileLinkCell = ({
  value: company,
  column: { cellProps },
  trackNavigation,
}) => {
  if (!company) return <EmptyCell />;

  return (
    <UserCan permissions={[['navigate', Resources.companies]]}>
      {({ hasAccess }) =>
        hasAccess ? (
          <ButtonInline
            {...cellProps}
            href={{
              pathname: ADMIN_COMPANIES_PROFILE_ROUTE,
              query: {
                slug: company.slug,
              },
            }}
            onClick={(e) => e.stopPropagation()}
            trackNavigation={trackNavigation}
          >
            <LinkTextCell>{company.name}</LinkTextCell>
          </ButtonInline>
        ) : (
          company.name
        )
      }
    </UserCan>
  );
};

export const TimestampCell = ({ value }) => <Timestamp datetimeString={value} />;

/**
 * We're setting the sort type to 'datetime' for some specific cells.
 * This is done automatically for date-related cells, for custom
 * cells this can be done by using `sortType: 'datetime'` on the column definition.
 */
export const CellsWithDateTimeSorting = new Set([TimestampCell, DateCell]);

/**
 *
 * @param param.value
 * @param param.dark Whether the tooltip should be dark or light. Dark tooltips truncate to single line
 */
export const TextPreviewCell = ({ value, dark = false, truncateLength, tooltipProps = {} }) => {
  if (!value) {
    return <EmptyCell />;
  }

  const preview = multilineTruncate(value, truncateLength);

  // we don't want to show tooltip, if the value is not truncated
  const label = preview !== value ? value : undefined;

  return (
    <Tooltip type={dark ? 'dark' : 'light'} label={label} {...tooltipProps}>
      {preview}
    </Tooltip>
  );
};

export const ShortSlugCell = (props) => <TextCell {...props} value={props.value.shortSlug} />;

const StyledCopyToClipboard = styled(CopyToClipboard)`
  svg {
    /* Removes added padding when ShortSlugCellWithCopy is used */
    padding: 0;
    margin-top: -6px;
    margin-bottom: -6px;
  }
`;

export const ShortSlugCellWithCopy = ({ color, iconPosition, value }) =>
  value.shortSlug ? (
    <Box onClick={(e) => e.stopPropagation()} display="inline-block">
      <StyledCopyToClipboard value={value.shortSlug} iconPosition={iconPosition} color={color}>
        {value.shortSlug}
      </StyledCopyToClipboard>
    </Box>
  ) : (
    <EmptyCell />
  );

export const ActionsViewCell = (props) => {
  const handleClick = (e) => {
    e.stopPropagation();
    props.onClick?.();
  };

  const defaultIcon = props.href ? IconV2OutlineChevronRight : IconV2OutlineEye;

  return (
    <ButtonIcon
      Icon={props.Icon || defaultIcon}
      label={props.label || 'View'}
      onClick={handleClick}
      size="sm"
      tone="secondary"
      variant="ghost"
      {...props}
    />
  );
};

export const ROW_ACTIONS_MENU_TEST_ID = 'row-actions-menu';
export const ROW_ACTIONS_MENU_TOGGLE_NAME = 'Row actions';

export const RowActionsMenu = (props) => {
  const { children, menuButtonProps, ...rest } = props;

  const defaultMenuButtonProps = {
    tone: 'secondary',
    'aria-label': ROW_ACTIONS_MENU_TOGGLE_NAME,
  };
  return (
    <DropdownMenu
      buttonVariant="ghost"
      menuButtonProps={{
        ...defaultMenuButtonProps,
        ...menuButtonProps,
      }}
      dataTestid={ROW_ACTIONS_MENU_TEST_ID}
      {...rest}
    >
      {children}
    </DropdownMenu>
  );
};

export const RowAction = MenuItem;
/**
 * Should only be used for complementary functionality.
 * This reduces visual noise on devices whose primary input mechanism
 * can hover over table rows (eg. mouse), using the (@media hover:hover).
 * On touchscreens the default styling applies.
 * See implementation of `RowStyled` for the remaining CSS bound
 * to this component.
 */
export const QuickActionsRow = styled(Box)`
  display: inline-block;

  @media (hover: hover) {
    svg {
      fill: var(--colors-lynch);
      transform: scale(0.9);
    }
  }
`;

QuickActionsRow.defaultProps = {
  onClick: (e) => e.stopPropagation(),
};

export const YesNoCell = (props) => <TextCell {...props} value={props.value ? 'Yes' : 'No'} />;

export function InvoiceCell({ value, trackNavigation }) {
  const { user } = useUser();
  const isAdminUser = isAdmin(user);

  if (!value || !value.slug || !value.status) return <EmptyCell />;

  const href = isAdminUser ? ADMIN_INVOICE_DETAILS_ROUTE : BILLING_INVOICE_DETAILS;
  const type = employeeInvoiceStatusBadges[value.status];
  const label = `${employeeInvoiceStatusLabels[value.status]} - ${value.slug
    .slice(0, 6)
    .toUpperCase()}`;

  const invoiceStatusCell = (
    <StatusCell
      value={{
        type,
        label,
      }}
    />
  );

  return (
    <Stack direction="row" gap="2">
      <UserCan permissions={[['navigate', Resources.invoices]]}>
        {({ hasAccess }) => {
          return hasAccess ? (
            <ButtonInline
              href={href.replace('[slug]', value.slug)}
              trackNavigation={trackNavigation}
              onClick={(e) => e.stopPropagation()}
            >
              {invoiceStatusCell}
            </ButtonInline>
          ) : (
            invoiceStatusCell
          );
        }}
      </UserCan>
    </Stack>
  );
}
