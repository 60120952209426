import type { BadgeType, PillToneV2 } from '@remote-com/norma';
import { IconV2DuotoneAi } from '@remote-com/norma/icons/IconV2DuotoneAi';
import { IconV2DuotoneCheck } from '@remote-com/norma/icons/IconV2DuotoneCheck';
import { IconV2DuotoneTimes } from '@remote-com/norma/icons/IconV2DuotoneTimes';
import type { ValueOf } from 'type-fest';

import type { SafetyWingMembershipStatus } from '@/src/api/config/employ/safetyWing.types';
import { BENEFITS_KOTA_ROUTE, BENEFITS_SIMPLY_INSURED_ROUTE } from '@/src/constants/routes';
import { generateSelectOptions } from '@/src/helpers/forms';

export const editabilityStatus = {
  FULLY_EDITABLE: 'fully_editable',
  PARTIALLY_EDITABLE: 'partially_editable',
  READ_ONLY: 'read_only',
} as const;

// Benefits
export const benefitStatus = {
  ENROLLED: 'enrolled',
  INACTIVE: 'inactive',
  OFFERED: 'offered',
  WAIVED: 'waived',
  DELETED: 'deleted',
} as const;

export const benefitEnrollmentStatusStyles = {
  [benefitStatus.ENROLLED]: {
    icon: IconV2DuotoneCheck,
    color: 'success',
  },
  [benefitStatus.INACTIVE]: {
    icon: IconV2DuotoneTimes,
    color: 'error',
  },
  [benefitStatus.OFFERED]: {
    icon: IconV2DuotoneAi,
    color: 'purple',
  },
  [benefitStatus.WAIVED]: {
    icon: IconV2DuotoneTimes,
    color: 'neutralLight',
  },
  [benefitStatus.DELETED]: {
    icon: IconV2DuotoneTimes,
    color: 'error',
  },
};

export const benefitStatusLabels = {
  [benefitStatus.ENROLLED]: 'Enrolled',
  [benefitStatus.INACTIVE]: 'Inactive',
  [benefitStatus.OFFERED]: 'Offered',
  [benefitStatus.WAIVED]: 'Waived',
  [benefitStatus.DELETED]: 'Deleted',
};

export const benefitStatusBadges = {
  [benefitStatus.ENROLLED]: 'active',
  [benefitStatus.INACTIVE]: 'inactive',
  [benefitStatus.OFFERED]: 'pending',
  [benefitStatus.WAIVED]: 'closed',
  [benefitStatus.DELETED]: 'deleted',
};

export const benefitStatusOptions = generateSelectOptions(benefitStatusLabels);

export const voluntaryDeductionPayor = {
  EMPLOYER: 'employer',
  EMPLOYEE: 'employee',
  CONTRACTOR: 'contractor',
} as const;

export const voluntaryDeductionPayorLabels = {
  [voluntaryDeductionPayor.EMPLOYER]: 'Employer',
  [voluntaryDeductionPayor.EMPLOYEE]: 'Employee',
  [voluntaryDeductionPayor.CONTRACTOR]: 'Contractor',
};

export const voluntaryDeductionPayorOptions = generateSelectOptions(voluntaryDeductionPayorLabels);

// Benefit Plans
export const benefitPlanStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted',
} as const;

export const benefitPlanStatusBadges = {
  [benefitPlanStatus.ACTIVE]: 'active',
  [benefitPlanStatus.INACTIVE]: 'inactive',
  [benefitPlanStatus.DELETED]: 'deleted',
};

export const benefitPlanStatusLabels = {
  [benefitPlanStatus.ACTIVE]: 'Active',
  [benefitPlanStatus.INACTIVE]: 'Inactive',
  [benefitPlanStatus.DELETED]: 'Deleted',
};

export const benefitPlanStatusOptions = generateSelectOptions(benefitPlanStatusLabels);

export const benefitPlanInsuranceTypes = {
  ADD: 'ad&d',
  INCOME: 'income-protection',
  LIFE: 'life',
  LIFE_PLUS_ADD: 'life-plus-ad&d',
  DISABILITY: 'disability',
};

export const benefitPlanType = {
  ALLOWANCE: 'allowance',
  CHILDCARE: 'childcare',
  CRITICAL_ILLNESS: 'critical-illness',
  DENTAL: 'dental',
  EMPLOYEE_ASSISTANCE_PROGRAM: 'employee-assistance-program',
  FUNERAL_COVERAGE: 'funeral-coverage',
  HEALTH: 'health',
  HEALTH_AND_DENTAL: 'health-and-dental',
  HOSPITAL_COVERAGE: 'hospital-coverage',
  MEAL: 'meal',
  PENSION: 'pension',
  PERSONAL_ACCIDENT: 'personal-accident',
  TRANSPORTATION: 'transportation',
  TRAVEL: 'travel',
  VISION: 'vision',
  WCI: 'workers-compensation',
  WORKPLACE_ACCIDENT: 'workplace-accident',
  MENTAL_WELLBEING: 'mental-wellbeing',
  HEALTH_SAVINGS_ACCOUNT: 'health-savings-account',
  FLEXIBLE_SPENDING_ACCOUNT: 'flexible-spending-account',
  ...benefitPlanInsuranceTypes,
};

export const benefitPlanTypeLabels = {
  [benefitPlanType.ADD]: 'AD&D',
  [benefitPlanType.ALLOWANCE]: 'Allowance',
  [benefitPlanType.CHILDCARE]: 'Childcare',
  [benefitPlanType.CRITICAL_ILLNESS]: 'Critical Illness',
  [benefitPlanType.DENTAL]: 'Dental',
  [benefitPlanType.EMPLOYEE_ASSISTANCE_PROGRAM]: 'Employee Assistance Program',
  [benefitPlanType.FUNERAL_COVERAGE]: 'Funeral Coverage',
  [benefitPlanType.HEALTH]: 'Health',
  [benefitPlanType.HEALTH_AND_DENTAL]: 'Health & Dental',
  [benefitPlanType.HOSPITAL_COVERAGE]: 'Hospital Coverage',
  [benefitPlanType.INCOME]: 'Income protection',
  [benefitPlanType.LIFE]: 'Life',
  [benefitPlanType.LIFE_PLUS_ADD]: 'Life + AD&D',
  [benefitPlanType.DISABILITY]: 'Disability',
  [benefitPlanType.MEAL]: 'Meal',
  [benefitPlanType.PENSION]: 'Pension',
  [benefitPlanType.PERSONAL_ACCIDENT]: 'Personal Accident',
  [benefitPlanType.TRANSPORTATION]: 'Transportation',
  [benefitPlanType.TRAVEL]: 'Travel',
  [benefitPlanType.VISION]: 'Vision',
  [benefitPlanType.WCI]: `Worker's Compensation`,
  [benefitPlanType.WORKPLACE_ACCIDENT]: 'Workplace Accident',
  [benefitPlanType.MENTAL_WELLBEING]: 'Mental Wellbeing',
  [benefitPlanType.HEALTH_SAVINGS_ACCOUNT]: 'Health Savings Account',
  [benefitPlanType.FLEXIBLE_SPENDING_ACCOUNT]: 'Flexible Spending Account',
};

export const benefitPlanTypeOptions = generateSelectOptions(benefitPlanTypeLabels);

export const benefitPlanCategory = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
};

export const benefitPlanCategoryLabels = {
  [benefitPlanCategory.REQUIRED]: 'Required',
  [benefitPlanCategory.OPTIONAL]: 'Optional',
};

export const benefitPlanCategoryOptions = generateSelectOptions(benefitPlanCategoryLabels);

export const benefitPlanCostTypes = {
  FLAT: 'flat',
  PERCENTAGE: 'percentage',
} as const;

export const benefitPlanCostTypeLabels = {
  [benefitPlanCostTypes.FLAT]: 'Flat cost',
  [benefitPlanCostTypes.PERCENTAGE]: 'Percentage of salary',
};

export const benefitPlanCostTypeOptions = generateSelectOptions(benefitPlanCostTypeLabels);

export const benefitPlanCoverageStart = {
  EMPLOYEE_START_MONTH: 'employee_start_month',
  EMPLOYEE_START_DATE: 'employee_start_date',
  START_NEXT_MONTH: 'start_next_month',
  FIRST_OF_MONTH: 'first_of_the_month',
  HALF_OF_MONTH: 'half_of_the_month',
  LAST_THIRD_OF_MONTH: 'last_third_of_the_month',
};

export const benefitPlanCoverageStartLabels = {
  [benefitPlanCoverageStart.EMPLOYEE_START_MONTH]: "On the beginning of the employee's start month",
  [benefitPlanCoverageStart.EMPLOYEE_START_DATE]: "On the employee's start date",
  [benefitPlanCoverageStart.START_NEXT_MONTH]: 'On the month after the employee starts',
  [benefitPlanCoverageStart.FIRST_OF_MONTH]:
    'On first of next month if start date after 1st of current month',
  [benefitPlanCoverageStart.HALF_OF_MONTH]:
    'On first of next month if start date after 15th of current month',
  [benefitPlanCoverageStart.LAST_THIRD_OF_MONTH]:
    'On first of next month if start date after 20th of current month',
};

export const benefitPlanCoverageStartOptions = generateSelectOptions(
  benefitPlanCoverageStartLabels
);

export const benefitPlanCoverageEnd = {
  EMPLOYMENT_END_MONTH: 'employment_end_month',
  EMPLOYMENT_END_DATE: 'employment_end_date',
};

export const benefitPlanCoverageEndLabels = {
  [benefitPlanCoverageEnd.EMPLOYMENT_END_MONTH]: "On the end of the employee's last month",
  [benefitPlanCoverageEnd.EMPLOYMENT_END_DATE]: "On the employee's end date",
};

export const benefitPlanCoverageEndOptions = generateSelectOptions(benefitPlanCoverageEndLabels);

export const benefitPlanBillingSource = {
  INCENTIVES: 'incentive',
  PAYROLL_OUTPUT: 'payroll_output',
};

export const benefitPlanBillingSourceLabels = {
  [benefitPlanBillingSource.PAYROLL_OUTPUT]: 'Payroll Output',
  [benefitPlanBillingSource.INCENTIVES]: 'Incentives',
};

export const benefitPlanBillingSourceOptions = generateSelectOptions(
  benefitPlanBillingSourceLabels
);

export const benefitPlanCostStart: Record<string, string> = {
  AFTER_30_DAYS: 'after_30_days',
  ON_COVERAGE_START_DATE: 'on_coverage_start_date',
};

export const benefitPlanCostStartLabels: Record<string, string> = {
  [benefitPlanCostStart.ON_COVERAGE_START_DATE]: 'Upon on employee’s coverage start date',
  [benefitPlanCostStart.AFTER_30_DAYS]: 'After the 30-day enrollment period',
};

export const benefitPlanCostStartOptions = generateSelectOptions(benefitPlanCostStartLabels);

// Tiers
export const tierStatus = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const benefitTiersStatusBadges = {
  [tierStatus.ACTIVE]: 'active',
  [tierStatus.DRAFT]: 'inactive',
  [tierStatus.ARCHIVED]: 'archived',
};

export const tierStatusLabels = {
  [tierStatus.DRAFT]: 'Draft',
  [tierStatus.ACTIVE]: 'Active',
  [tierStatus.ARCHIVED]: 'Archived',
};

export const tierStatusOptions = generateSelectOptions(tierStatusLabels);

export const groupEmployerOptedOut = 'opted_out';

export const groupStatus = {
  DRAFT: 'draft',
  ACTIVATED: 'activated',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
} as const;

export const groupStatusLabels = {
  [groupStatus.ACTIVATED]: 'Activated',
  [groupStatus.DRAFT]: 'Draft',
  [groupStatus.ARCHIVED]: 'Archived',
  [groupStatus.DELETED]: 'Deleted',
};

export const groupLifecycleStatus = {
  COVERAGE_ENDED: 'coverage_ended',
  COVERAGE_SCHEDULED: 'coverage_scheduled',
  IN_COVERAGE: 'in_coverage',
  IN_RENEWAL_SELECTION: 'in_renewal_selection',
  RENEWAL_SELECTION_SCHEDULED: 'renewal_selection_scheduled',
} as const;

export const groupLifecycleStatusLabels = {
  [groupLifecycleStatus.COVERAGE_ENDED]: 'Coverage ended',
  [groupLifecycleStatus.COVERAGE_SCHEDULED]: 'Coverage scheduled',
  [groupLifecycleStatus.IN_COVERAGE]: 'In coverage',
  [groupLifecycleStatus.IN_RENEWAL_SELECTION]: 'In renewal selection',
  [groupLifecycleStatus.RENEWAL_SELECTION_SCHEDULED]: 'Renewal selection scheduled',
};

// Don't include the deleted status
export const groupStatusOptions = generateSelectOptions(
  Object.fromEntries(
    Object.entries(groupStatusLabels).filter(([key]) => key !== groupStatus.DELETED)
  )
);

export const groupLifecycleStatusOptions = generateSelectOptions(groupLifecycleStatusLabels);

// Benefit cost correction
export const benefitCostCorrectionType = {
  CHARGE: 'charge',
  REIMBURSEMENT: 'reimbursement',
} as const;

export const benefitCostCorrectionTypeLabels = {
  [benefitCostCorrectionType.CHARGE]: 'Additional charge',
  [benefitCostCorrectionType.REIMBURSEMENT]: 'Reimbursement',
};

export const benefitCostCorrectionTypeOptions = generateSelectOptions(
  benefitCostCorrectionTypeLabels
);

// Surveys
export const employeeSurveyBenefitsPage = {
  url: 'https://a.sprig.com/TWRITFh5UklyUld4fnNpZDpmM2ZkMzcyZC1lZDExLTQ4MDEtOWRkNS0wYzY3Yjc5YTgwOTg=',
  cta: 'Give Remote feedback on benefits',
};

// Default Benefit Offers
export const defaultBenefitOfferStatus: Record<string, string> = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const defaultBenefitOfferStatusLabels: Record<string, string> = {
  [defaultBenefitOfferStatus.ACTIVE]: 'Active',
  [defaultBenefitOfferStatus.ARCHIVED]: 'Archived',
};

export const defaultBenefitOfferStatusBadges: Record<string, BadgeType> = {
  [defaultBenefitOfferStatus.ACTIVE]: 'active',
  [defaultBenefitOfferStatus.ARCHIVED]: 'archived',
};

// Benefit Offers
export const benefitOfferStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
} as const;

export const benefitOfferStatusLabels = {
  [benefitOfferStatus.ACTIVE]: 'Active',
  [benefitOfferStatus.ARCHIVED]: 'Archived',
};

export const benefitOfferStatusOptions = generateSelectOptions(benefitOfferStatusLabels);

// Benefit Costs
export const costCategories = {
  PREMIUM: 'premium',
  VOLUNTARY_DEDUCTION: 'voluntary_deduction',
  SERVICE_FEE: 'service_fee',
  COVERAGE_START_PRO_RATA: 'coverage_start_pro_rata',
  COVERAGE_END_PRO_RATA: 'coverage_end_pro_rata',
  TAX: 'tax',
  CORRECTION: 'correction',
  TAX_RELIEF: 'tax_relief',
  PRE_TAX: 'pre_tax',
  POST_TAX: 'post_tax',
  IMPUTED_FLAT: 'imputed_flat',
} as const;

export const costCategoriesLabels: Record<ValueOf<typeof costCategories>, string> = {
  [costCategories.PREMIUM]: 'Premium',
  [costCategories.VOLUNTARY_DEDUCTION]: 'Voluntary Deduction',
  [costCategories.SERVICE_FEE]: 'Service Fee',
  [costCategories.COVERAGE_START_PRO_RATA]: '[START DATE] Pro-rata',
  [costCategories.COVERAGE_END_PRO_RATA]: '[END DATE] Pro-rata',
  [costCategories.TAX]: 'Tax',
  [costCategories.CORRECTION]: 'Correction',
  [costCategories.TAX_RELIEF]: 'Tax Relief',
  [costCategories.PRE_TAX]: 'Pre-tax',
  [costCategories.POST_TAX]: 'Post-tax',
  [costCategories.IMPUTED_FLAT]: 'Imputed Flat',
};

export const costCategoriesOptions = generateSelectOptions(costCategoriesLabels);

export const costCategoriesPills: Record<ValueOf<typeof costCategories>, PillToneV2> = {
  [costCategories.PREMIUM]: 'blue',
  [costCategories.VOLUNTARY_DEDUCTION]: 'success',
  [costCategories.SERVICE_FEE]: 'fuchsia',
  [costCategories.COVERAGE_START_PRO_RATA]: 'pink',
  [costCategories.COVERAGE_END_PRO_RATA]: 'pink',
  [costCategories.TAX]: 'purple',
  [costCategories.CORRECTION]: 'warning',
  [costCategories.TAX_RELIEF]: 'purple',
  [costCategories.PRE_TAX]: 'purple',
  [costCategories.POST_TAX]: 'purple',
  [costCategories.IMPUTED_FLAT]: 'purple',
};

export const costPayor = {
  EMPLOYEE: 'employee',
  EMPLOYER: 'employer',
} as const;

export const costPayorLabels = {
  [costPayor.EMPLOYEE]: 'Employee',
  [costPayor.EMPLOYER]: 'Employer',
};

export const costPayorOptions = generateSelectOptions(costPayorLabels);

export const companyTypeLabels: Record<string, string> = {
  internal: 'Internal',
  external: 'External',
};

export const costCorrectionType = {
  ADDITIONAL_CHARGE: 'additional_charge',
  REIMBURSEMENT: 'reimbursement',
} as const;

export const costCorrectionTypeLabels = {
  [costCorrectionType.ADDITIONAL_CHARGE]: 'Additional Charge',
  [costCorrectionType.REIMBURSEMENT]: 'Reimbursement',
};

export const costCorrectionTypeOptions = generateSelectOptions(costCorrectionTypeLabels);

// Benefit Group family
export const benefitGroupFamily = {
  CUSTOMIZED_BENEFITS: 'customized_benefits',
  EMPLOYEE_ASSISTANCE_PROGRAM: 'employee_assistance_program',
  HEALTH: 'health',
  LIFE: 'life',
  MEAL: 'meal',
  MENTAL_WELLBEING: 'mental_wellbeing',
  PENSION: 'pension',
  TRAVEL: 'travel',
} as const;

export type BenefitGroupFamily = ValueOf<typeof benefitGroupFamily>;

export const benefitGroupFamilyLabels: Record<BenefitGroupFamily, string> = {
  [benefitGroupFamily.CUSTOMIZED_BENEFITS]: 'Customized Benefits',
  [benefitGroupFamily.EMPLOYEE_ASSISTANCE_PROGRAM]: 'Employee Assistance Program',
  [benefitGroupFamily.HEALTH]: 'Health',
  [benefitGroupFamily.LIFE]: 'Life',
  [benefitGroupFamily.MEAL]: 'Meal',
  [benefitGroupFamily.MENTAL_WELLBEING]: 'Mental Wellbeing',
  [benefitGroupFamily.PENSION]: 'Pension',
  [benefitGroupFamily.TRAVEL]: 'Travel',
};

export const benefitGroupFamilyOptions = generateSelectOptions(benefitGroupFamilyLabels);

// Mapping the payroll run status due to inconsistencies
export const imaginaryPayrollRunStatus = {
  PREPARING: 'preparing',
  PROCESSING: 'processing',
  NOT_AVAILABLE: 'not_available',
  PAID: 'paid',
} as const;

export const imaginaryPayrollRunStatusLabels = {
  [imaginaryPayrollRunStatus.PREPARING]: 'Preparing',
  [imaginaryPayrollRunStatus.PROCESSING]: 'Processing',
  [imaginaryPayrollRunStatus.NOT_AVAILABLE]: 'Not available',
  [imaginaryPayrollRunStatus.PAID]: 'Paid',
};

export const imaginaryPayrollRunStatusOptions = generateSelectOptions(
  imaginaryPayrollRunStatusLabels
);

export const activeSafetyWingMembershipStatuses = new Set<SafetyWingMembershipStatus>(['active']);
export const inProgressSafetyWingMembershipStatuses = new Set<SafetyWingMembershipStatus>([
  'invited',
  'processing',
  'onboarding',
]);

export const benefitIntegrationPartners = {
  KOTA: 'kota',
  SIMPLY_INSURED: 'simply_insured',
} as const;

export const benefitIntegrationPartnersLabels = {
  [benefitIntegrationPartners.KOTA]: 'Kota',
  [benefitIntegrationPartners.SIMPLY_INSURED]: 'SimplyInsured',
};

export const benefitIntegrationPartnerRoutes = {
  [benefitIntegrationPartners.KOTA]: {
    url: BENEFITS_KOTA_ROUTE,
  },
  [benefitIntegrationPartners.SIMPLY_INSURED]: {
    url: BENEFITS_SIMPLY_INSURED_ROUTE,
  },
};

export const countriesWithHiddenPensionCosts = ['DEU', 'USA', 'CAN'];
