let endpointsConfig = null;

export function setupDataLayer(endpoints) {
  if (!endpointsConfig) {
    endpointsConfig = endpoints;
  }
}

const sanitizeServiceOptions = (options = {}, service) => {
  // Disallow overwriting method, path, and version options when creating a new service.
  // Removes `service` from options when service was already set in the path.
  const { method, path, version, [service ? 'service' : '']: _, ...rest } = options;
  return rest;
};

const buildServiceOptions = (defaultOptions = {}, customOptions = {}) => {
  const { method = null, path = null, version = null, service = null } = defaultOptions;

  return {
    ...(method ? { method } : {}),
    ...(path ? { path } : {}),
    ...(version ? { version } : {}),
    ...(service ? { service } : {}),
    ...sanitizeServiceOptions(customOptions, !!service),
  };
};

const getPathInfo = (path) => {
  const pathStructure = /^(?:\/(.+))*\/(api|dev)\/(v(?!0)\d+)\/(.+)/.exec(path);
  const devPathStructure = /(dev)\/(.+)/.exec(path);

  if (!pathStructure && !devPathStructure) {
    throw new Error(
      `Path ${path} does not follow the endpoint definition guidelines.` +
        ` Endpoints must start with \`/api/v[n]/\` where \`n\` is a number > 0.` +
        ` The service name is allowed as optional first path segment \`/:service/api/v[n]\`.`
    );
  }

  if (devPathStructure) {
    const [, dev, pathName] = devPathStructure;
    return {
      pathName: `/${pathName}`,
      version: `/${dev}`,
    };
  }

  const [, service, api, versionNumber, pathName] = pathStructure;
  return {
    pathName: `/${pathName}`,
    version: `/${api}/${versionNumber}`,
    service: service?.toUpperCase(),
  };
};

const getPathConfig = (path, method) => {
  let pathConfig = null;
  const { GET, POST, PUT, PATCH, DELETE } = endpointsConfig;

  switch (method) {
    case 'GET':
      pathConfig = GET[path];
      break;
    case 'POST':
      pathConfig = POST[path];
      break;
    case 'PUT':
      pathConfig = PUT[path];
      break;
    case 'PATCH':
      pathConfig = PATCH[path];
      break;
    case 'DELETE':
      pathConfig = DELETE[path];
      break;
    default:
      throw new Error(`The method ${method} is currently not supported!`);
  }

  if (!pathConfig) {
    throw new Error(`Path ${path} not found in data-layer/${method}.endpoints.js file`);
  }

  return pathConfig;
};

const validateEndpointsConfig = () => {
  if (!endpointsConfig) {
    console.error('Please set data-layer config. Check link (storybook)');
    return false;
  }
  return true;
};

export const createServiceOptions = (path, method) => {
  if (!validateEndpointsConfig()) {
    return null;
  }

  const config = getPathConfig(path, method);

  const { version, pathName, service } = getPathInfo(path);
  const defaultOptions = { method, path: pathName, version, service };

  return buildServiceOptions(defaultOptions, config.serviceOptions);
};

/**
 * @param {string} path A GET path
 * @param {object} params The query params
 * @param {object} [defaultParams.pathParams]
 * @param {object} [defaultParams.queryParams]
 * @param {object} [defaultParams.queryKeyOptions]
 * @returns
 */
export const createQueryKey = (path, { pathParams, queryParams, queryKeyOptions } = {}) => {
  if (!validateEndpointsConfig()) {
    return [];
  }
  // Validate path config exists
  getPathConfig(path, 'GET');

  return [
    path,
    ...(pathParams ? [{ pathParams }] : []),
    ...(queryParams ? [{ queryParams }] : []),
    ...(queryKeyOptions ? [{ queryKeyOptions }] : []),
  ];
};
