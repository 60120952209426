import { useGet, useInvalidateQuery, usePost, useDelete } from '@remote-com/data-layer';
import { isFuture } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { useUserCan } from '@/src/components/UserCan';
import { useUserContext } from '@/src/components/UserProvider';
import { canUserManageContractorAgreements } from '@/src/domains/contracts/helpers';
import { PURCHASE_TYPES } from '@/src/domains/employment/newEmployment/constants';
import { PURCHASE_OPTION_CONTENT } from '@/src/domains/employment/newEmployment/steps/PricingPlan/constants';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { contractorSupportedPayments } from '@/src/domains/paidContractorProduct/constants';
import { sortContractorSubscriptions } from '@/src/domains/paidContractorProduct/helpers';
import useCompanyDetailsData from '@/src/domains/paymentMethods/shared/hooks/useCompanyDetailsData';
import { CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE } from '@/src/domains/pricing/constants';
import { isProductContractorStandard } from '@/src/domains/pricing/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { convertUtcStringToDateObj } from '@/src/helpers/date';
import { useCustomMutation } from '@/src/hooks/useCustomMutation';

function noop() {}

export function usePaidContractorSubscriptionProduct() {
  const { userCan } = useUserCan();

  return useCompanyDetailsData({
    select: (data) =>
      data?.company?.products.find(
        (product) => product.active && isProductContractorStandard(product)
      ),
    enabled: userCan('read', Resources.employer.company_details),
  });
}

export function useIsCountrySupportedForContractorOnboarding(legalEntitySlug) {
  const useLegalEntity = useIsFeatureFlagEnabled('contractors_multi_entity');

  const {
    data: availableCountriesForCompany = [],
    isLoading: isLoadingAvailableCountriesForCompany,
  } = useGet('/api/v1/employer/contractor-countries', {
    options: {
      select: (res) => res.data,
      enabled: !useLegalEntity,
    },
  });

  const {
    data: availableCountriesForLegalEntity = [],
    isLoading: isLoadingAvailableCountriesForLegalEntity,
  } = useGet('/api/v1/employer/legal-entities/[legalEntitySlug]/contractor-countries', {
    params: {
      pathParams: {
        legalEntitySlug,
      },
    },
    options: {
      select: (res) => res.data,
      enabled: useLegalEntity,
    },
  });

  const availableCountries = useLegalEntity
    ? availableCountriesForLegalEntity
    : availableCountriesForCompany;
  const isLoading = useLegalEntity
    ? isLoadingAvailableCountriesForLegalEntity
    : isLoadingAvailableCountriesForCompany;

  const availableCountriesMapped = availableCountries?.reduce(
    (acc, country) => ({ ...acc, [country.code]: country }),
    {}
  );

  // Return true if it's allowed to invite a contractor from the specified country
  const isCountrySupportedForContractorOnboarding = useCallback(
    (country) => {
      if (isLoading) return false;

      return availableCountriesMapped[country?.code]?.supportedPayments?.length > 0;
    },
    [availableCountriesMapped, isLoading]
  );

  // Return true if country only supports Stripe as a payment
  const isStripeOnlyCountryPaymentSupported = useCallback(
    (country) => {
      if (isLoading) {
        return false;
      }

      // Verify if Stripe is the only supported payment
      const supportedPayments = availableCountriesMapped[country?.code]?.supportedPayments;
      return (
        supportedPayments?.length === 1 &&
        supportedPayments[0] === contractorSupportedPayments.STRIPE
      );
    },
    [availableCountriesMapped, isLoading]
  );

  // Return true if country only supports Wise virtual as
  // a payment
  const shouldSetupWiseVirtualAccount = useCallback(
    (country) => {
      if (isLoading) {
        return false;
      }

      // Verify if Wise virtual is the only supported payment
      const supportedPayments = availableCountriesMapped[country?.code]?.supportedPayments;
      return (
        supportedPayments?.length === 1 &&
        supportedPayments[0] === contractorSupportedPayments.WISE_VIRTUAL
      );
    },
    [availableCountriesMapped, isLoading]
  );

  const supportedPaymentProviders = useMemo(() => {
    return new Set(
      Object.values(availableCountriesMapped).flatMap((country) => country.supportedPayments)
    );
  }, [availableCountriesMapped]);

  return {
    isCountrySupportedForContractorOnboarding,
    isLoading,
    isStripeOnlyCountryPaymentSupported,
    shouldSetupWiseVirtualAccount,
    supportedPaymentProviders,
    contractorCountries: {
      countriesList: availableCountries,
      countriesBySlug: availableCountries?.reduce((acc, country) => {
        acc[country.slug] = country;
        return acc;
      }, {}),
    },
  };
}

/**
 * Fetches employer contractor subscriptions.
 *
 * @param {Object} params
 * @returns {import('react-query').UseQueryResult<import('@/src/api/config/employ/contractorSubscription.types').ContractorSubscription[]>}
 */
export function useFetchEmployerContractorSubscriptions({ employmentSlug, options = {} }) {
  return useGet('/api/v1/employer/contractor-subscriptions', {
    params: { queryParams: { employmentSlug } },
    options: {
      select: (res) =>
        sortContractorSubscriptions(res.data)?.map((subscription) => {
          const { currency, companyProductDiscount } = subscription;

          return {
            ...subscription,
            price: { ...subscription.price, currency },
            companyProductDiscount: isFuture(
              convertUtcStringToDateObj(companyProductDiscount?.expirationDate)
            )
              ? companyProductDiscount
              : null,
          };
        }),
      ...options,
    },
  });
}

export function useFetchContractorPricingPlan({ employmentSlug, enabled = true }) {
  return useGet('/api/v1/employer/employments/[employmentSlug]/pricing-plan', {
    params: {
      pathParams: {
        employmentSlug,
      },
    },
    options: {
      select: (res) => res.data,
      enabled: enabled && !!employmentSlug,
    },
  });
}

function useInvalidateQueriesAfterPlanChange() {
  const { invalidateQuery } = useInvalidateQuery();

  return ({ employmentSlug, isDowngrade }) =>
    Promise.all([
      invalidateQuery('/api/v1/employer/employments/[employmentSlug]/pricing-plan', {
        params: { pathParams: { employmentSlug } },
      }),
      // Creating or cancelling the upgrade intent affects the types of templates that
      // are available for the contractor.
      invalidateQuery('/api/v1/employer/contract-templates/available', {
        params: { queryParams: { employmentSlug } },
      }),
      invalidateQuery(
        '/api/v1/employer/employments/[employmentSlug]/contract-document-form-details',
        {
          params: { pathParams: { employmentSlug } },
        }
      ),
      // Invalidate the JSON schema that's used for the Statement of Work form: when changing between
      // standard and CoR and Plus certain validation rules change. Note that `invalidateQuery` is
      // called without passing the query params (`countryCode`, `employmentSlug`) -- this is done
      // to simplify the invalidation and not have to keep track of the country code.
      invalidateQuery('/api/v1/countries/[countryCode]/contract-details/contractor'),
      isDowngrade ? invalidateQuery('/api/v1/employer/contract-documents') : Promise.resolve(),
    ]);
}

export function useContractorPlusIntentChange({ employmentSlug, onSuccess = noop }) {
  const { user } = useUserContext();
  const invalidateQueries = useInvalidateQueriesAfterPlanChange();

  const { mutateAsync } = usePost(
    '/api/v1/employer/employments/[employmentSlug]/contractor-plus-subscription',
    {
      onSuccess: async (res, params) => {
        const isDowngrade =
          params.bodyParams.operation === CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE.DOWNGRADE;

        await invalidateQueries({ employmentSlug, isDowngrade });
      },
    }
  );

  return useCustomMutation(
    ({ operation }) => {
      if (
        operation === CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE.UPGRADE &&
        !canUserManageContractorAgreements(user)
      ) {
        throw new Error(
          `You do not have the permission to create and manage contractor agreements, which is required to switch the contractor to the ${PURCHASE_OPTION_CONTENT.CONTRACTOR_PLUS.title} plan. Please ask your company owner to grant you the permission to manage existing or onboard new hires.`
        );
      }

      return mutateAsync({
        pathParams: { employmentSlug },
        bodyParams: { operation },
      });
    },
    { onSuccess }
  );
}

export function useCreateContractorProtectIntent() {
  const invalidateQueries = useInvalidateQueriesAfterPlanChange();
  return usePost('/api/v1/employer/employments/[employmentSlug]/aor-subscription-intent', {
    onSuccess: async (res, params) => {
      const { employmentSlug } = params.pathParams;
      await invalidateQueries({ employmentSlug, isDowngrade: false });
    },
  });
}

export function useDeleteContractorProtectIntent() {
  const invalidateQueries = useInvalidateQueriesAfterPlanChange();
  return useDelete('/api/v1/employer/employments/[employmentSlug]/aor-subscription-intent', {
    onSuccess: async (res, params) => {
      const { employmentSlug } = params.pathParams;
      await invalidateQueries({ employmentSlug, isDowngrade: true });
    },
  });
}

export function useChangeContractorPricingPlanMutation({ employmentSlug }) {
  const contractorProtectIntentCreateMutation = useCreateContractorProtectIntent();
  const contractorProtectIntentDeleteMutation = useDeleteContractorProtectIntent();
  const plusPlanChangeMutation = useContractorPlusIntentChange({
    employmentSlug,
  });

  return useCustomMutation(
    async ({ previousProductSlugPurchaseOption, productSlugPurchaseOption }) => {
      // If choice on form differs from the existing product intent, clear up related plan resources first
      if (previousProductSlugPurchaseOption === PURCHASE_TYPES.CONTRACTOR_PROTECT) {
        await contractorProtectIntentDeleteMutation.mutateAsync({ pathParams: { employmentSlug } });
      } else if (previousProductSlugPurchaseOption === PURCHASE_TYPES.CONTRACTOR_PLUS) {
        await plusPlanChangeMutation.mutateAsync({
          operation: CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE.DOWNGRADE,
        });
      }

      // Then handle latest choice
      if (productSlugPurchaseOption === PURCHASE_TYPES.CONTRACTOR_PROTECT) {
        await contractorProtectIntentCreateMutation.mutateAsync({ pathParams: { employmentSlug } });
      } else if (productSlugPurchaseOption === PURCHASE_TYPES.CONTRACTOR_PLUS) {
        await plusPlanChangeMutation.mutateAsync({
          operation: CONTRACTOR_PLUS_SUBSCRIPTION_OPERATION_TYPE.UPGRADE,
        });
      }
    }
  );
}
