import type { BoxedIconProps } from '@remote-com/norma';
import { IconV2OutlineAscending } from '@remote-com/norma/icons/IconV2OutlineAscending';
import { IconV2OutlineCalendar } from '@remote-com/norma/icons/IconV2OutlineCalendar';
import { IconV2OutlineDescending } from '@remote-com/norma/icons/IconV2OutlineDescending';

import type { TodoCategory, TodosSortOption, TodosTab } from '@/src/domains/todos/types';

export const TODO_TYPES_V2 = {
  ACCEPT_TERMS_OF_SERVICE: 'accept_terms_of_service',
  ADD_COMPANY_PHONE_NUMBER: 'add_company_phone_number',
  ADD_FIRST_ADMIN: 'add_first_admin',
  ADD_INTEGRATION: 'add_integration',
  ANNUAL_PLAN_SHARED: 'annual_plan_shared',
  BENEFITS_RENEWAL: 'benefits_renewal',
  BANK_ACCOUNT_INCOMPLETE: 'bank_account_incomplete',
  CAREER_DEVELOPMENT_SELF_ASSESSMENT_DUE: 'career_development_self_assessment_due',
  RESET_PASSWORD_REQUIRED: 'reset_password_required',
  CAREER_DEVELOPMENT_MANAGER_ASSESSMENT_DUE: 'career_development_manager_assessment_due',
  CAREER_DEVELOPMENT_MANAGER_ASSESSMENT_REOPENED_CALIBRATED_SCORE:
    'career_development_manager_assessment_reopened_calibrated_score',
  CHECKLIST_RECRUIT: 'checklist_recruit',
  COMPANY_PRODUCT_TIER_SHARED: 'company_product_tier_shared',
  COMPLETE_FIRST_HIRE: 'complete_first_hire',
  COMPLIANCE_REQUEST_DOCUMENT: 'compliance_request_document',
  CONTRACT_CURRENCY_CHANGED: 'contract_currency_changed',
  CONTRACT_DOCUMENT_AWAITING_COMPANY_SIGNATURE: 'contract_document_awaiting_company_signature',
  CONTRACT_DOCUMENT_AWAITING_SIGNATURE: 'contract_document_awaiting_signature',
  CONTRACTOR_AUTOMATIC_PAYMENT_MAXIMUM_EXCEEDED: 'contractor_automatic_payment_maximum_exceeded',
  CONTRACTOR_AUTOMATIC_PAYMENT_PAY_IN_FAILED: 'contractor_automatic_payment_pay_in_failed',
  CONTRACTOR_INVOICE_ISSUED: 'contractor_invoice_issued',
  CONTRACTOR_INVOICE_APPROVED: 'contractor_invoice_approved',
  CONTRACTOR_PAYMENTS_INVOICE_READY: 'contractor_payments_invoice_ready',
  BILLING_DOCUMENT_READY: 'billing_document_ready',
  COMPANY_TAX_FORMS_1099_GENERATED: 'company_tax_forms_1099_generated',
  CONTRACTOR_ADD_WITHDRAWAL_METHOD: 'contractor_add_withdrawal_method',
  CONTRACTOR_RE_ADD_NIUM_WITHDRAWAL_METHODS: 'contractor_re_add_nium_withdrawal_methods',
  EMPLOYEE_HEALTH_INSURANCE_OFFER_ACTION_REQUIRED:
    'employee_health_insurance_offer_action_required',
  EMPLOYEE_HEALTH_INSURANCE_POLICY_ACTIVATED: 'employee_health_insurance_policy_activated',
  EMPLOYER_HEALTH_INSURANCE_QUOTE_ACTION_REQUIRED:
    'employer_health_insurance_quote_action_required',
  EMPLOYER_HEALTH_INSURANCE_QUOTE_NOT_UNDERTAKEN: 'employer_health_insurance_quote_not_undertaken',
  COMPLETE_COMPANY_SIGNUP: 'complete_company_signup',
  COST_APPROVAL_REQUEST_PENDING: 'cost_approval_request_pending',
  DEFAULT_PAYMENT_METHOD_EXPIRED: 'default_payment_method_expired',
  DISABILITY_STATUS_REQUEST: 'disability_status_request',
  INVALIDATED_PAYMENT_METHOD: 'invalidated_payment_method',
  INVITE_FIRST_CLIENT: 'invite_first_client',
  DOCUMENT_REQUEST: 'document_request',
  EMPLOYEE_WITH_SKIPPED_FIELDS: 'employee_with_skipped_fields',
  EXPIRING_DOCUMENT: 'expiring_document',
  FINISH_COMPANY_VERIFICATION: 'finish_company_verification',
  FIXED_TERM_CONTRACT_EXPIRING_SOON: 'fixed_term_contract_expiring_soon',
  FIXED_TERM_CONTRACT_EXPIRING_SOON_EMPLOYEE: 'fixed_term_contract_expiring_soon_employee',
  FREELANCER_ACCEPT_CLIENT_INVITE: 'freelancer_accept_client_invite',
  GENERIC_EMPLOYMENT_DOCUMENT_AWAITING_SIGNATURE: 'generic_employment_document_awaiting_signature',
  IDENTITY_VERIFICATION: 'identity_verification',
  IMPLEMENTATION_FEE_SHARED: 'implementation_fee_shared',
  INFORMATION_REQUEST: 'information_request',
  LEGAL_ENTITY_NEW_STATE_TAX_FORM: 'legal_entity_new_state_tax_form',
  LEGAL_ENTITY_REMOTE_PAYMENTS_SETUP_DETAILS: 'legal_entity_remote_payments_setup_details',
  LEGAL_ENTITY_BANK_ACCOUNT_MISSING: 'legal_entity_bank_account_missing',
  MISSING_ETHNICITY_FIELDS: 'missing_ethnicity_fields',
  MISSING_NAME_SURNAME_FIELDS: 'missing_name_surname_fields',
  MISSING_PERSONAL_DETAILS_FIELDS: 'missing_personal_details_fields',
  MISSING_W8_FORM: 'missing_w8_form',
  MISSING_W9_FORM: 'missing_w9_form',
  MOBILE_APP: 'mobile_app',
  ONBOARDING_BANK_ACCOUNT_MISSING: 'onboarding_bank_account_missing',
  ONBOARDING_LEGAL_ENTITY_TASKS: 'onboarding_legal_entity_tasks',
  ONBOARDING_RESERVE_INVOICE_READY: 'onboarding_reserve_invoice_ready',
  ONBOARDING_RESERVE_PAYMENT_OVERDUE: 'onboarding_reserve_payment_overdue',
  ONBOARDING_RESERVE_PAYMENT_FAILED: 'onboarding_reserve_payment_failed',
  PAYMENT_ATTEMPT_FAILED: 'payment_attempt_failed',
  PAYROLL_RUN_READY_FOR_APPROVAL: 'payroll_run_ready_for_approval',
  PENDING_EXPENSE: 'pending_expense',
  PERFORMANCE_CALIBRATION_CALIBRATOR_EVENT_STARTED:
    'performance_calibration_calibrator_event_started',
  PRE_HIRE_DETAILS_AWAITING_APPROVAL: 'pre_hire_details_awaiting_approval',
  PRICING_PLAN_SHARED: 'pricing_plan_shared',
  PROOF_OF_PAYMENT_APPROVED: 'proof_of_payment_approved',
  PROOF_OF_PAYMENT_REJECTED: 'proof_of_payment_rejected',
  RE_ATTEMPT_DISPUTED_PAYMENT: 're_attempt_disputed_payment',
  RE_ENTER_PAYMENT_METHOD: 're_enter_payment_method',
  RECRUIT_FREE_TRIAL: 'recruit_free_trial',
  REMOTE_WORK_DETAILS_CONFIRMATION: 'remote_work_details_confirmation',
  REMOTE_WORK_REQUEST_MANAGER_APPROVAL: 'remote_work_request_manager_approval',
  REQUESTED_CANCEL_TIMEOFF: 'requested_cancel_timeoff',
  REQUESTED_TIMEOFF: 'requested_timeoff',
  RESIGNATION_EMPLOYMENT_REQUEST_AWAITING_VALIDATION:
    'resignation_employment_request_awaiting_validation',
  RIGHT_TO_WORK_CHECK_NEEDED: 'right_to_work_check_needed',
  SCHEDULED_CONTRACTOR_INVOICE_MISSING_WITHDRAWAL_METHOD:
    'scheduled_contractor_invoice_missing_withdrawal_method',
  REMOTE_PAYMENTS_SETUP_ADDITIONAL_INFO: 'remote_payments_setup_additional_info',
  SETUP_PAYMENT_METHOD: 'setup_payment_method',
  SETUP_TALENT_COMPANY_PROFILE: 'setup_talent_company_profile',
  SETUP_2FA: 'two_factor_setup',
  SIGN_NEW_W9_TAX_FORM: 'sign_new_w9_tax_form',
  SIGN_NEW_W8_BEN_TAX_FORM: 'sign_new_w8_ben_tax_form',
  SIGN_NEW_W8_BEN_E_TAX_FORM: 'sign_new_w8_ben_e_tax_form',
  STATUTORY_BENEFICIARY_REVIEW: 'statutory_beneficiary_review',
  SUBSCRIPTION_FAILED_PAYMENT: 'subscription_failed_payment',
  TAX_DETAILS_MISSING: 'tax_details_missing',
  TEAM_PLAN_SHARED: 'team_plan_shared',
  TRAVEL_LETTER_REQUEST_MANAGER_APPROVAL: 'travel_letter_request_manager_approval',
  WITHDRAWAL_METHOD_REVIEW: 'withdrawal_method_review',
  DEPOSIT_METHOD_REVIEW: 'deposit_method_review',
  SALARY_PAYMENT_FAILED: 'salary_payment_failed',
  GLOBAL_PAYROLL_SHORT_PAYMENT: 'global_payroll_short_payment',
  COMPANY_ACCOUNT_LINK_PENDING: 'company_account_link_pending',
  REATTEMPT_FAILED_PAYMENT: 'reattempt_failed_payment',
  TIMESHEET_SENT_BACK_TODO: 'timesheet_sent_back_todo',
  CHECKLIST_ALBA: 'checklist_alba',
} as const;

export const todoCategoryTone: {
  [key in TodoCategory]: NonNullable<BoxedIconProps['tone']>;
} = {
  benefits: 'fuchsia',
  expenses: 'green',
  finance: 'purple',
  hr: 'brand',
  timeAndAttendance: 'orange',
  authentication: 'cyan',
  team: 'brand',
};

export const todosTabs: TodosTab[] = [
  {
    id: 'all',
    label: 'All tasks',
    emptyState: {
      title: 'You’re all caught up',
      description: 'Tasks that need attention appear here.',
    },
    queryParams: { overdue: undefined, new: undefined },
  },
  {
    id: 'new',
    label: 'New',
    emptyState: {
      title: 'What a peaceful, easy feeling',
      description: 'No new tasks need your attention. When something comes up, we’ll let you know.',
    },
    queryParams: { overdue: undefined, new: true },
  },
  {
    id: 'overdue',
    label: 'Overdue',
    emptyState: {
      title: 'You’re on top of it!',
      description: 'None of your tasks are overdue. If one ever is, we’ll let you know here.',
    },
    queryParams: { overdue: true, new: undefined },
  },
];

export const todosSortOptions: TodosSortOption[] = [
  {
    id: 'dueDateAscending',
    label: 'Urgency',
    icon: IconV2OutlineCalendar,
    queryParams: { sortBy: undefined, order: undefined },
  },
  {
    id: 'updatedAtDescending',
    label: 'Newest first',
    icon: IconV2OutlineDescending,
    queryParams: { sortBy: 'updated_at', order: 'desc' },
  },
  {
    id: 'updatedAtAscending',
    label: 'Oldest first',
    icon: IconV2OutlineAscending,
    queryParams: { sortBy: 'updated_at', order: 'asc' },
  },
];

export const todosDefaultParams = {
  queryParams: { pageSize: 4 },
};
