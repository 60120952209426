import Cookies from 'js-cookie';
import getConfig from 'next/config';

import type { featureFlags as oldRecord } from '@/next/featureFlagNames';
import { featureFlagNames as oldFlagNames } from '@/next/featureFlagNames';
import { FEATURE_FLAG_COOKIE_PREFIX } from '@/src/domains/remoteControlPanel/tools/feature-flags/utils';
import { isPublicEnvironment } from '@/src/helpers/general';

import type { FeatureFlagAudience } from './audience';
import { fetchFeatureFlagConfig } from './fetch';
import type { FeatureFlagName as NewName } from './types';

// Heads up! This "config" file merges the 2 systems of feature flags:
// 1. Old, legacy flags from the next.js's public runtime config
// 2. New, full-stack flags fetched from server
// into a single config object.
//
// The imports in this file are often renamed to emphasize the 2 systems.

// Old, legacy flag names, statically typed
type OldName = keyof typeof oldRecord;

/**
 * @public
 *
 * Name of a feature flag. This supports both old and new flags.
 * It also has auto-completion for old flags.
 *
 * Note that we intentionally will not use this type for the config object,
 * because it's too strict and make the internal code here unnecessarily complex.
 * We should use this in external code, like when using the hooks.
 */
export type FeatureFlagName = OldName | NewName;

/**
 * @public
 *
 * Detail of a feature flag, both old and new.
 *
 * This is a static config for all users.
 * It's not the final result for a single user.
 * For the final, calculated result for a single user, see "./context".
 */
export interface FeatureFlagDetail {
  // This name can actually be a loose "string" like in the config object.
  // However, unlike the config object,
  // we never need to construct the detail incrementally (i.e., via reduce),
  // so it can use the strict typing just fine.
  name: FeatureFlagName;
  enabled: boolean;
  /**
   * The audiences that this flag is targeted to.
   * If the user attributes match any of these audiences, the flag will be enabled.
   * This is only available for flags in the new system, so it's optional.
   */
  audiences?: FeatureFlagAudience[];
  /**
   * This is only available for flags in the new system, so it's optional.
   */
  devMode?: boolean;
}

export type FeatureFlagAttributeValue = number | string | boolean | null | undefined;

export interface FeatureFlagUserAttributes extends Record<string, FeatureFlagAttributeValue> {}

/**
 * @private
 *
 * The config for all feature flags.
 * This is useful for the initial setup of the system.
 * Most of us should not need to use this directly.
 */
export type FeatureFlagConfig = Record<
  // The key here is intentionally loose (instead of FeatureFlagName).
  // It makes the internal implementation here simpler.
  // This object is not widely used, so it's fine to be loose here.
  string,
  // Because the key could be just "string" (for now, see NewName's comment),
  // we could never guarantee that we would get a detail here,
  // thus the "undefined".
  FeatureFlagDetail | undefined
>;

/**
 * @private
 *
 * Return old, legacy flag config from the next.js's public runtime config.
 */
function getOldConfig(): FeatureFlagConfig {
  const nextConfig = getConfig().publicRuntimeConfig;

  const oldConfig = oldFlagNames.reduce<FeatureFlagConfig>((acc, name) => {
    acc[name] = {
      // Warning: "name" is "string" in type system,
      // because "oldFlagNames" is a string array defined in JS.
      // However, in practice, this is always a valid "OldName",
      // as "OldName" comes from the same source as "oldFlagNames".
      name: name as OldName,
      enabled: Boolean(nextConfig[name]),
    };
    return acc;
  }, {});

  return oldConfig;
}

type CreateConfigResult = {
  config: FeatureFlagConfig;
  error?: Error;
};

export function parseForcedFeatureFlagCookies(cookies: Record<string, string>): FeatureFlagConfig {
  return Object.entries(cookies).reduce((acc, [key, value]) => {
    if (key?.startsWith(FEATURE_FLAG_COOKIE_PREFIX) && value) {
      acc[key.replace(FEATURE_FLAG_COOKIE_PREFIX, '') as FeatureFlagName] = {
        name: key.replace(FEATURE_FLAG_COOKIE_PREFIX, '') as FeatureFlagName,
        enabled: value === 'true',
        devMode: true,
      };
    }
    return acc;
  }, {} as FeatureFlagConfig);
}
/**
 * @private
 *
 *  Returns the final, merged config of all feature flags.
 */
export async function createFeatureFlagConfig(): Promise<CreateConfigResult> {
  const isInternalEnvironment = !isPublicEnvironment();

  const oldConfig = getOldConfig();
  let newConfig: FeatureFlagConfig = {};
  let error: Error | undefined;

  try {
    const config = await fetchFeatureFlagConfig();
    newConfig = config;
  } catch (e) {
    error = e as Error;
  }

  if (isInternalEnvironment) {
    const forcedFeatureFlagCookies = parseForcedFeatureFlagCookies(Cookies.get() || {});
    return {
      config: {
        ...oldConfig,
        ...newConfig,
        ...forcedFeatureFlagCookies,
      },
      error,
    };
  }

  return {
    config: {
      ...oldConfig,
      ...newConfig,
    },
    error,
  };
}
