import type { PermissionRule, UserCanOperators } from '@/src/components/UserCan/UserCan';
import { userCanOperators } from '@/src/components/UserCan/UserCan';
import {
  ADMIN_COUNTRIES_ROUTE,
  ADMIN_COMPANIES_ROUTE,
  ADMIN_LEGAL_ENTITIES_ROUTE,
  ADMIN_PEOPLE_ROUTE,
  ADMIN_ONBOARDING_ROUTE,
  ADMIN_BILLING_ROUTE,
  ADMIN_INTERCOMPANY_TRANSACTIONS_ROUTE,
  ADMIN_PAYSLIPS_ROUTE,
  ADMIN_ACCOUNTS_ROUTE,
  ADMIN_PAYROLL_RUNS_ROUTE,
  ADMIN_CONTRACTORS_ROUTE,
  ADMIN_BENEFITS_SETUP_ROUTE,
  ADMIN_BENEFITS_COSTS_ROUTE,
  ADMIN_PAYMENT_EVENTS_ROUTE,
  ADMIN_TIME_OFF_ROUTE,
  ADMIN_TIME_TRACKING_ROUTE,
  ADMIN_EXPENSES_ROUTE,
  ADMIN_INCENTIVES_ROUTE,
  ADMIN_ADJUSTMENTS_ROUTE,
  ADMIN_DATA_CHANGES_ROUTE,
  ADMIN_PRICING_ROUTE,
  ADMIN_CONTRACTS_ROUTE,
  ADMIN_EMPLOYMENT_FEES_ROUTE,
  ADMIN_OFFPAYSLIP_COSTS_ROUTE,
  ADMIN_CUSTOM_NOTIFICATIONS_ROUTE,
  ADMIN_NEW_CUSTOM_NOTIFICATION_ROUTE,
  ADMIN_COST_CALCULATOR_ROUTE,
  ADMIN_COMPENSATION_BENCHMARK_ROUTE,
  ADMIN_KDB_ROUTE,
  ADMIN_PAYROLL_OUTPUTS_ROUTE,
  ADMIN_MOBILITY_ROUTE,
  ADMIN_FRAUD_CONTROLS_ROUTE,
  ADMIN_EMPLOYMENT_REQUESTS_ROUTE,
  ADMIN_KYB_DASHBOARD_ROUTE,
  ADMIN_INTERCOMPANY_INVOICES_ROUTE,
  ADMIN_HANDBOOK_ROUTE,
  ADMIN_INTERCOMPANY_INVOICE_VAT_GROUPS,
  ADMIN_BULK_PAYROLL_ROUTE,
  ADMIN_COUNTRY_ROUTE,
  ADMIN_PAYROLL_CALENDARS_ROUTE,
  ADMIN_PAYROLL_CALENDAR_ROUTE,
  ADMIN_CREATE_PAYROLL_CALENDAR_ROUTE,
  ADMIN_PAYROLL_REPORTS_ROUTE,
  ADMIN_COST_APPROVAL_REQUESTS_ROUTE,
  ADMIN_TALENT_ROUTE,
  DOCUMENTS_ROUTE,
  EXPENSES_ROUTE,
  USER_PROFILE_ROUTE,
  TIME_OFF_ROUTE,
  TEAM_ROUTE,
  HIRING_ROUTE,
  HIRING_PROGRESS_BOARD_ROUTE,
  LINK_ACCOUNTS_ROUTE,
  INCENTIVES_ROUTE,
  PAYROLL_LANDING_ROUTE,
  PAYROLL_RUN_DETAILS_ROUTE,
  PAYMENT_REQUESTS_ROUTE,
  BILLING_ROUTE,
  PAYMENT_METHODS_ROUTE,
  COMPANY_INFORMATION_ROUTE,
  // This route is a list of links to other company settings pages.
  // If a user doesn't have access to any of the sub pages, we are also restricting access to this page.
  // We are using `useCompanySettingsConfig()` to check if there are any company settings pages to be displayed.
  //
  // ⚠️ DO NOT ADD ANY INDIVIDUAL PERMISSIONS FOR THIS ROUTE
  //
  // COMPANY_SETTINGS_ROUTE,
  COMPANY_ACTIVE_PRODUCTS_ROUTE,
  COMPANY_INACTIVE_PRODUCTS_ROUTE,
  COMPANY_LEGAL_ENTITIES_ROUTE,
  COMPANY_DEPARTMENTS_ROUTE,
  COMPANY_DEPARTMENT_ROUTE,
  COMPANY_TEAM_PLANS_ROUTE,
  COMPANY_TIME_TRACKING_ROUTE,
  COMPANY_SSO_ROUTE,
  INTEGRATIONS_ROUTE,
  MARKETPLACE_ROUTE,
  COMPANY_CUSTOM_FIELDS_ROUTE,
  DASHBOARD_COST_CALCULATOR_ROUTE,
  CUSTOMER_USERS_PERMISSIONS_ROUTE,
  CLIENTS_ROUTE,
  PERSONAL_PROFILE_ROUTE,
  EMPLOYMENT_REQUESTS_ROUTE,
  DEPOSIT_METHODS_ROUTE,
  DEPOSITS_ROUTE,
  WITHDRAWAL_METHODS_ROUTE,
  BENEFITS_ROUTE,
  BENEFITS_RENEWALS_ROUTE,
  ADMIN_SERVICE_COSTS_ROUTE,
  FEEDBACK_ROUTE,
  ADMIN_OFFBOARDING_ROUTE,
  ADMIN_OFFBOARDING_EMPLOYEE_ROUTE,
  COST_APPROVAL_REQUESTS_DETAILS_ROUTE,
  TIME_TRACKING,
  ADMIN_TEAM_PLANS_ROUTE,
  ADMIN_USER_DETAILS_ROUTE,
  PAYMENT_METHOD_CARDS_UPDATE_ROUTE,
  ADMIN_BULK_CONTRACT_AMENDMENT_ROUTE,
  TALENT_BASE_ROUTE,
  EDIT_TALENT_COMPANY_PROFILE_ROUTE,
  ADD_NEW_JOB_ROUTE,
  JOB_CHECKOUT_ROUTE,
  VIEW_JOB_ROUTE,
  EMPLOYER_PAY_ELEMENTS_CREATE_ROUTE,
  ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE,
  PAYROLL_SETTINGS_ROUTE,
  ADMIN_FREELANCERS_ROUTE,
  EMPLOYER_PAY_ELEMENTS_CREATE_INLINE_ROUTE,
  ADMIN_PAY_ELEMENTS_CREATE_ROUTE,
  ADMIN_PAY_ELEMENTS_CREATE_INLINE_ROUTE,
  KYB_ONBOARDING_ROUTE,
  USER_PROFILE_OFFBOARDING_ROUTE,
  ADMIN_CONTRACTS_AUTOMATIONS_ROUTE,
  OFFBOARDING_ROUTE,
  TALENT_SUBSCRIPTION_PLANS_ROUTE,
  PRICING_PLAN_ROUTE,
  ADMIN_PERSISTED_CONFIG_SETTINGS,
  EMPLOYER_PAY_ELEMENTS_EDIT_ROUTE,
  ADMIN_PAY_ELEMENTS_EDIT_ROUTE,
  CAREER_JOURNAL_ROUTE,
  ADMIN_TALENT_JOB_DETAILS_ROUTE,
  ADMIN_TALENT_COMPANY_PROFILE_ROUTE,
  SUBSCRIPTION_INFO_ROUTE,
  KNOWLEDGE_ROUTE,
  ADMIN_BILLABLES_ROUTE,
  REPORTS_ROUTE,
  REPORTS_DETAILS_ROUTE,
  WORKFLOWS_HOME_ROUTE,
  ADMIN_SEVERANCE_RESERVES_POLICY_ROUTE,
  ADMIN_BENEFITS_OFFERS_ROUTE,
  CONFIRM_ANNUAL_PLAN_ROUTE,
  ADMIN_DATA_EXPORTS_ROUTE,
  DASHBOARD_PERFORMANCE_ROUTE,
  REMOTE_EQUITY_ROUTE,
  JOB_PROSPECTS_ROUTE,
  TEAM_PERFORMANCE_ROUTE,
  SUPPORT_MONITOR_ROUTE,
  ADD_REPORTING_LINES_ROUTE,
  COMPANY_CONTRACTOR_SUBSCRIPTIONS_ROUTE,
  TIME_OFF_REQUESTS_ROUTE,
  PAYROLL_EMPLOYEE_PAYMENTS_ROUTE,
  TEAM_ORG_CHART_ROUTE,
  COMPANY_PAYMENTS_ROUTE,
  TEAM_WORLD_MAP_ROUTE,
  DOCUMENTS_FILES_ROUTE,
  DOCUMENTS_TAX_MANAGEMENT_ROUTE,
  DOCUMENTS_1099_NEC_FORMS_ROUTE,
  COMPANY_CUSTOM_LEAVE_POLICIES_ROUTE,
  SUPPORT_MONITOR_TICKET_DETAILS_ROUTE,
  ADMIN_TALENT_INSIGHTS_ROUTE,
  COMPENSATION_BENCHMARKING_ROUTE,
  COMPANY_TALENT_PLANS_ROUTE,
  COMPANY_BIO_ROUTE,
  TALENT_INSIGHTS_ROUTE,
  COMPANY_ADMIN_USERS_ROUTE,
  COMPANY_ROLES_PERMISSIONS_ROUTE,
  PROJECTS_ROUTE,
  PROJECTS_EDIT_ROUTE,
  PAYMENT_REQUESTS_TRANSACTIONS_ROUTE,
  PAYMENT_REQUESTS_SCHEDULES_ROUTE,
  ADMIN_BLOCK_EMAIL_DOMAINS_ROUTE,
  ADD_CONTRACTOR_PREFUNDING_ROUTE,
  EMPLOYER_EXPENSES_BULK_UPLOADER_ROUTE,
  ADMIN_POLICY_FOR_INDUSTRY_SECTORS_ROUTE,
  ADMIN_BALANCE_STATEMENTS_ROUTE,
  COMPANY_ORG_CHART_SETTINGS_ROUTE,
  ADMIN_COUNTRY_POLICIES_ROUTE,
  SELECT_PRODUCT_PLAN_ROUTE,
  PAY_FOR_PLAN_ROUTE,
  PRODUCT_PLAN_CHANGES_ROUTE,
  PAY_FOR_PLAN_STATUS_ROUTE,
  TALENT_SOURCING_ROUTE,
  ADD_PROSPECT_TO_JOB_ROUTE,
  COMPLIANCE_HUB_ROUTE_CUSTOMERS,
} from '@/src/constants/routes';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';

export type RoutePermissionsMap = {
  [route: string]: {
    permissions: PermissionRule[];
    operator?: UserCanOperators;
  };
};

export const adminRoutePermissionsMap: RoutePermissionsMap = {
  [ADMIN_PEOPLE_ROUTE]: { permissions: [['navigate', Resources.employees]] },
  [ADMIN_ONBOARDING_ROUTE]: { permissions: [['navigate', Resources.onboarding]] },
  [ADMIN_COMPANIES_ROUTE]: { permissions: [['navigate', Resources.companies]] },
  [ADMIN_LEGAL_ENTITIES_ROUTE]: { permissions: [['navigate', Resources.legal_entities]] },
  [ADMIN_BALANCE_STATEMENTS_ROUTE]: {
    permissions: [['read', Resources.cash_application]],
  },
  [ADMIN_FRAUD_CONTROLS_ROUTE]: {
    // Important: Please check "FraudControls" component to ensure the
    // permissions here match the actual functionalities there
    permissions: [
      ['navigate', Resources.companies], // KYB Dashboard, see below
      ['update', Resources.collection_settings], // Bulk update collection
    ],
    // Important: This must always be "SOME" as this is a dashboard-like page
    // where we render as much functionalities/cards as the current user's
    // permission allows
    operator: userCanOperators.SOME,
  },
  [ADMIN_SEVERANCE_RESERVES_POLICY_ROUTE]: {
    permissions: [['read', Resources.severance_reserve_policies]],
  },
  [ADMIN_COUNTRY_POLICIES_ROUTE]: {
    permissions: [['navigate', Resources.onboarding_reserves_policies]],
  },
  [ADMIN_POLICY_FOR_INDUSTRY_SECTORS_ROUTE]: {
    permissions: [['navigate', Resources.onboarding_reserves_policies]],
  },
  [ADMIN_KYB_DASHBOARD_ROUTE]: {
    permissions: [['navigate', Resources.companies]],
  },
  [ADMIN_BLOCK_EMAIL_DOMAINS_ROUTE]: {
    // !TODO: add proper permissions for this route
    permissions: [['navigate', Resources.companies]],
  },
  [ADMIN_CUSTOM_NOTIFICATIONS_ROUTE]: {
    permissions: [['navigate', Resources.custom_notifications]],
    operator: userCanOperators.SOME,
  },
  [ADMIN_NEW_CUSTOM_NOTIFICATION_ROUTE]: {
    permissions: [['navigate', Resources.custom_notifications]],
    operator: userCanOperators.SOME,
  },
  [ADMIN_COUNTRIES_ROUTE]: {
    permissions: [
      ['navigate', Resources.countries],
      ['read', Resources.country_forms],
    ],
    operator: userCanOperators.SOME,
  },
  [ADMIN_BENEFITS_SETUP_ROUTE]: { permissions: [['navigate', Resources.benefits]] },
  [ADMIN_BENEFITS_COSTS_ROUTE]: { permissions: [['navigate', Resources.benefits]] },
  [ADMIN_BENEFITS_OFFERS_ROUTE]: { permissions: [['navigate', Resources.benefits]] },
  [ADMIN_CONTRACTS_ROUTE]: {
    permissions: [
      ['navigate', Resources.contracts],
      ['navigate', Resources.contract_documents],
      ['navigate', Resources.contract_templates],
    ],
    operator: userCanOperators.SOME,
  },
  [ADMIN_CONTRACTS_AUTOMATIONS_ROUTE]: {
    permissions: [['navigate', Resources.contract_templates]],
  },
  [ADMIN_MOBILITY_ROUTE]: { permissions: [['navigate', Resources.contract_eligibility]] },
  [ADMIN_EMPLOYMENT_REQUESTS_ROUTE]: { permissions: [['navigate', Resources.employment_requests]] },
  [ADMIN_KDB_ROUTE]: { permissions: [['navigate', Resources.knowledge_database]] },
  [ADMIN_COST_CALCULATOR_ROUTE]: { permissions: [['navigate', Resources.tce_calculator]] },
  [ADMIN_TALENT_INSIGHTS_ROUTE]: {
    permissions: [
      // Temporarily disabling until we have permissions in production. See https://remote-com.slack.com/archives/C05LFH4JR3P/p1737587030580649?thread_ts=1737556217.205679&cid=C05LFH4JR3P
      // ['navigate', Resources.talent_insights],
      // ['read', Resources.talent_insights],
    ],
  },
  [ADMIN_COMPENSATION_BENCHMARK_ROUTE]: {
    permissions: [['read', Resources.compensation_benchmarking]],
  },
  [ADMIN_PRICING_ROUTE]: { permissions: [['navigate', Resources.pricing]] },
  [ADMIN_TEAM_PLANS_ROUTE]: { permissions: [['read', Resources.team_plans]] },
  [ADMIN_FREELANCERS_ROUTE]: { permissions: [['read', Resources.freelancers]] },
  [ADMIN_EMPLOYMENT_FEES_ROUTE]: { permissions: [['navigate', Resources.employment_fees]] },
  [ADMIN_OFFPAYSLIP_COSTS_ROUTE]: { permissions: [['navigate', Resources.off_payslip_costs]] },
  [ADMIN_BILLABLES_ROUTE]: { permissions: [['navigate', Resources.billable_items]] },
  [ADMIN_BILLING_ROUTE]: { permissions: [['navigate', Resources.billing]] },
  [ADMIN_CONTRACTORS_ROUTE]: { permissions: [['navigate', Resources.contractors]] },
  [ADMIN_PAYMENT_EVENTS_ROUTE]: { permissions: [['navigate', Resources.payment_events]] },
  [ADMIN_INTERCOMPANY_TRANSACTIONS_ROUTE]: {
    permissions: [['navigate', Resources.inter_company_transactions]],
  },
  [ADMIN_PAYROLL_RUNS_ROUTE]: { permissions: [['navigate', Resources.payroll]] },
  [ADMIN_PAYROLL_CALENDARS_ROUTE]: { permissions: [['read', Resources.payroll_calendars]] },
  [ADMIN_PAYROLL_CALENDAR_ROUTE]: { permissions: [['read', Resources.payroll_calendars]] },
  [ADMIN_CREATE_PAYROLL_CALENDAR_ROUTE]: {
    permissions: [['create', Resources.payroll_calendars]],
  },
  [ADMIN_PAYROLL_REPORTS_ROUTE]: { permissions: [['navigate', Resources.payroll]] },
  [ADMIN_PAYROLL_OUTPUTS_ROUTE]: { permissions: [['navigate', Resources.payroll]] },
  [ADMIN_PAYSLIPS_ROUTE]: { permissions: [['navigate', Resources.payslips]] },
  [ADMIN_ADJUSTMENTS_ROUTE]: { permissions: [['navigate', Resources.adjustments]] },
  [ADMIN_DATA_CHANGES_ROUTE]: {
    permissions: [
      ['navigate', Resources.employee_data_elements],
      ['read', Resources.employee_data_elements],
    ],
    operator: userCanOperators.ALL,
  },
  [ADMIN_EXPENSES_ROUTE]: { permissions: [['navigate', Resources.expense_management]] },
  [ADMIN_INCENTIVES_ROUTE]: {
    permissions: [
      ['navigate', Resources.incentives],
      ['navigate', Resources.incentives_management],
    ],
    operator: userCanOperators.SOME,
  },
  [ADMIN_TIME_OFF_ROUTE]: { permissions: [['navigate', Resources.time_off]] },
  [ADMIN_TIME_TRACKING_ROUTE]: { permissions: [['navigate', Resources.time_tracking]] },
  [ADMIN_ACCOUNTS_ROUTE]: {
    permissions: [
      ['navigate', Resources.users],
      ['navigate', Resources.roles],
    ],
    operator: userCanOperators.SOME,
  },
  [ADMIN_INTERCOMPANY_INVOICES_ROUTE]: {
    permissions: [['navigate', Resources.inter_company_invoices]],
  },
  [ADMIN_INTERCOMPANY_INVOICE_VAT_GROUPS]: {
    permissions: [['navigate', Resources.vat_groups]],
  },
  [ADMIN_HANDBOOK_ROUTE]: {
    permissions: [['navigate', Resources.handbooks]],
  },
  [ADMIN_BULK_PAYROLL_ROUTE]: {
    permissions: [
      ['create', Resources.payroll],
      ['create', Resources.expenses],
      ['create', Resources.time_off],
      ['create', Resources.off_payslip_costs],
      ['create', Resources.employment_fees],
      ['create', Resources.incentives],
      ['create', Resources.employees],
    ],
  },
  [ADMIN_COUNTRY_ROUTE]: {
    permissions: [
      ['update', Resources.benefit_details],
      ['read', Resources.countries],
    ],
    operator: userCanOperators.SOME,
  },
  [ADMIN_USER_DETAILS_ROUTE]: {
    permissions: [
      ['read', Resources.users],
      ['read', Resources.employees],
      ['read', Resources.team_members],
      ['navigate', Resources.employees],
    ],
    operator: userCanOperators.SOME,
  },
  [ADMIN_SERVICE_COSTS_ROUTE]: {
    permissions: [['navigate', Resources.remote_paid_services]],
  },
  [ADMIN_COST_APPROVAL_REQUESTS_ROUTE]: {
    permissions: [['navigate', Resources.cost_approval_requests]],
  },
  [ADMIN_OFFBOARDING_ROUTE]: {
    permissions: [['navigate', Resources.offboarding]],
  },
  [ADMIN_OFFBOARDING_EMPLOYEE_ROUTE]: {
    permissions: [
      ['create', Resources.offboarding],
      ['update', Resources.offboarding],
    ],
    operator: userCanOperators.SOME,
  },
  [ADMIN_CONTRACT_AMENDMENTS_BULK_ROUTE]: {
    permissions: [
      ['create', Resources.contract_documents],
      ['create', Resources.contracts],
    ],
  },
  [ADMIN_BULK_CONTRACT_AMENDMENT_ROUTE]: {
    permissions: [['navigate', Resources.contracts]],
  },
  [ADMIN_TALENT_ROUTE]: {
    permissions: [
      ['read', Resources.jobs],
      ['read', Resources.company_profiles],
    ],
  },
  [ADMIN_TALENT_COMPANY_PROFILE_ROUTE]: {
    permissions: [['read', Resources.company_profiles]],
  },
  [ADMIN_TALENT_JOB_DETAILS_ROUTE]: {
    permissions: [['read', Resources.jobs]],
  },
  [ADMIN_PERSISTED_CONFIG_SETTINGS]: {
    permissions: [['navigate', Resources.persisted_config_settings]],
  },
  [KNOWLEDGE_ROUTE]: { permissions: [['navigate', Resources.knowledge_database]] },
  [ADMIN_DATA_EXPORTS_ROUTE]: {
    permissions: [['read', Resources.data_export_templates]],
  },
};

export const dashboardRoutePermissionsMap: RoutePermissionsMap = {
  [ADD_NEW_JOB_ROUTE]: {
    permissions: [['create', Resources.employer.jobs]],
  },
  [ADD_REPORTING_LINES_ROUTE]: {
    permissions: [
      ['read', Resources.employer.people],
      ['navigate', Resources.employer.people],
      ['create', Resources.employer.people],
    ],
  },
  [ADMIN_PAY_ELEMENTS_CREATE_INLINE_ROUTE]: {
    permissions: [
      ['read', Resources.employee_pay_elements],
      ['create', Resources.employee_pay_elements],
    ],
  },
  [ADMIN_PAY_ELEMENTS_CREATE_ROUTE]: {
    permissions: [
      ['read', Resources.employee_pay_elements],
      ['create', Resources.employee_pay_elements],
    ],
  },
  [ADMIN_PAY_ELEMENTS_EDIT_ROUTE]: {
    permissions: [
      ['read', Resources.employee_pay_elements],
      ['update', Resources.employee_pay_elements],
    ],
  },
  [BENEFITS_ROUTE]: {
    permissions: [
      ['navigate', Resources.benefits],
      ['navigate', Resources.employer.benefits],
    ],
    operator: userCanOperators.SOME,
  },
  [BENEFITS_RENEWALS_ROUTE]: {
    permissions: [
      ['navigate', Resources.benefits],
      ['navigate', Resources.employer.benefits],
    ],
    operator: userCanOperators.SOME,
  },
  [BILLING_ROUTE]: {
    permissions: [['read', Resources.employer.billing]],
  },
  [CAREER_JOURNAL_ROUTE]: {
    permissions: [], // TODO: Add proper permission check when permissions are properly set up
  },
  [CLIENTS_ROUTE]: {
    permissions: [['read', Resources.contractor_clients]],
  },
  [COMPANY_ACTIVE_PRODUCTS_ROUTE]: {
    permissions: [
      ['navigate', Resources.employer.company_details],
      ['read', Resources.company_details],
      ['read', Resources.company_bank_details],
    ],
  },
  [COMPANY_INACTIVE_PRODUCTS_ROUTE]: {
    permissions: [
      ['navigate', Resources.employer.company_details],
      ['read', Resources.company_details],
      ['read', Resources.company_bank_details],
    ],
  },
  [COMPANY_CONTRACTOR_SUBSCRIPTIONS_ROUTE]: {
    permissions: [
      ['read', Resources.employer.automatic_invoice_payments],
      ['read', Resources.employer.hiring],
    ],
  },
  [COMPANY_INFORMATION_ROUTE]: {
    permissions: [['navigate', Resources.employer.company_details]],
  },
  [COMPANY_LEGAL_ENTITIES_ROUTE]: {
    permissions: [['navigate', Resources.employer.legal_entities]],
  },
  [COMPANY_DEPARTMENTS_ROUTE]: {
    permissions: [
      ['create', Resources.employer.company_departments],
      ['update', Resources.employer.company_departments],
      ['delete', Resources.employer.company_departments],
    ],
    operator: userCanOperators.SOME,
  },
  [COMPANY_DEPARTMENT_ROUTE]: {
    permissions: [
      ['update', Resources.employer.company_departments],
      ['update', Resources.employer.people],
    ],
  },
  [COMPANY_SSO_ROUTE]: {
    permissions: [['navigate', Resources.company_details]],
  },
  [COMPANY_TEAM_PLANS_ROUTE]: {
    permissions: [['read', Resources.employer.team_plans]],
  },
  [COMPANY_TIME_TRACKING_ROUTE]: {
    permissions: [['read', Resources.employer.company_time_settings]],
  },
  [COMPANY_PAYMENTS_ROUTE]: {
    permissions: [
      ['read', Resources.employer.payment_methods],
      ['read', Resources.employer.people_list],
      ['create', Resources.employer.automatic_invoice_payments],
      ['read', Resources.employer.company_payment_settings],
      ['update', Resources.employer.company_payment_settings],
    ],
    operator: userCanOperators.SOME,
  },
  [COMPANY_ADMIN_USERS_ROUTE]: {
    permissions: [['read', Resources.employer.roles]],
  },
  [COMPANY_ROLES_PERMISSIONS_ROUTE]: {
    permissions: [['read', Resources.employer.roles]],
  },
  [CONFIRM_ANNUAL_PLAN_ROUTE]: {
    permissions: [['navigate', Resources.employer.company_details]],
  },
  [COMPENSATION_BENCHMARKING_ROUTE]: {
    permissions: [['read', Resources.employer.compensation_benchmarking]],
  },
  [COST_APPROVAL_REQUESTS_DETAILS_ROUTE]: {
    permissions: [['read', Resources.employer.cost_approval_requests]],
  },
  [COMPANY_CUSTOM_FIELDS_ROUTE]: {
    permissions: [['read', Resources.employer.custom_field_definitions]],
  },
  [COMPANY_ORG_CHART_SETTINGS_ROUTE]: {
    permissions: [['update', Resources.employer.company_settings]],
  },
  [COMPANY_CUSTOM_LEAVE_POLICIES_ROUTE]: {
    permissions: [['navigate', Resources.employer.leave_policies]],
  },
  [CUSTOMER_USERS_PERMISSIONS_ROUTE]: {
    permissions: [['read', Resources.employer.roles]],
  },
  [COMPLIANCE_HUB_ROUTE_CUSTOMERS]: {
    permissions: [['read', Resources.employer.compliance_hub]],
  },
  [DASHBOARD_COST_CALCULATOR_ROUTE]: {
    permissions: [['read', Resources.employer.cost_calculator]],
  },
  [DASHBOARD_PERFORMANCE_ROUTE]: {
    permissions: [['navigate', Resources.employer.performance_review_cycles]],
  },

  [DEPOSIT_METHODS_ROUTE]: {
    permissions: [['read', Resources.bank_accounts]],
  },
  [DEPOSITS_ROUTE]: {
    permissions: [['update', Resources.bank_accounts]],
  },
  [DOCUMENTS_ROUTE]: {
    permissions: [
      // TODO: remove everything but Resources.employer.people_contracts once new_global_navigation flag is removed
      ['read', Resources.documents],
      ['read', Resources.employer.contractor_tax_forms],
      ['read', Resources.employer.documents_management],
      ['read', Resources.employer.people_contracts],
    ],
    operator: userCanOperators.SOME,
  },
  [DOCUMENTS_FILES_ROUTE]: {
    permissions: [['read', Resources.employer.documents_management]],
  },
  [DOCUMENTS_TAX_MANAGEMENT_ROUTE]: {
    permissions: [['read', Resources.employer.documents_management]],
  },
  [DOCUMENTS_1099_NEC_FORMS_ROUTE]: {
    permissions: [['read', Resources.employer.contractor_tax_forms]],
  },
  [EDIT_TALENT_COMPANY_PROFILE_ROUTE]: {
    permissions: [['update', Resources.employer.company_profiles]],
  },
  [EMPLOYER_PAY_ELEMENTS_CREATE_INLINE_ROUTE]: {
    permissions: [
      ['read', Resources.employer.employee_pay_elements],
      ['create', Resources.employer.employee_pay_elements],
    ],
  },
  [EMPLOYER_PAY_ELEMENTS_CREATE_ROUTE]: {
    permissions: [
      ['read', Resources.employer.employee_pay_elements],
      ['create', Resources.employer.employee_pay_elements],
    ],
  },
  [EMPLOYER_PAY_ELEMENTS_EDIT_ROUTE]: {
    permissions: [
      ['read', Resources.employer.employee_pay_elements],
      ['update', Resources.employer.employee_pay_elements],
    ],
  },
  [EMPLOYMENT_REQUESTS_ROUTE]: {
    permissions: [
      ['navigate', Resources.employer.employment_requests],
      ['navigate', Resources.employment_requests], // in near future this should be Resources.employee.employment_requests
    ],
    operator: userCanOperators.SOME,
  },
  [EXPENSES_ROUTE]: {
    permissions: [
      ['read', Resources.expenses],
      ['read', Resources.employer.expense_management],
    ],
    operator: userCanOperators.SOME,
  },
  [EMPLOYER_EXPENSES_BULK_UPLOADER_ROUTE]: {
    permissions: [['read', Resources.employer.expense_management]],
  },
  [FEEDBACK_ROUTE]: {
    permissions: [['read', Resources.employer.anonymous_feedback_management]],
  },
  [HIRING_ROUTE]: {
    permissions: [['read', Resources.employer.hiring]],
  },
  [HIRING_PROGRESS_BOARD_ROUTE]: {
    permissions: [['read', Resources.employer.hiring]],
  },
  [INCENTIVES_ROUTE]: {
    permissions: [
      ['read', Resources.employer.incentives_management], // For employers
      ['read', Resources.incentives], // For employees
    ],
    operator: userCanOperators.SOME,
  },
  [INTEGRATIONS_ROUTE]: {
    permissions: [['read', Resources.employer.integrations]],
  },
  [JOB_CHECKOUT_ROUTE]: {
    permissions: [['create', Resources.employer.jobs]],
  },
  // Heads up: it's intentional that this route is behind an "update" instead
  // of the usual "navigate" permission due to its functionality. See
  // https://gitlab.com/remote-com/employ-starbase/tiger/-/blob/5268b1b725931afde1a79bb5690a1cc551fd4049/lib/tiger_web/controllers/api/v1/employer/compliance/kyb_controller.ex#L22-31
  [KYB_ONBOARDING_ROUTE]: {
    permissions: [['update', Resources.employer.company]],
  },
  [LINK_ACCOUNTS_ROUTE]: {
    permissions: [
      ['read', Resources.employer.people],
      ['navigate', Resources.employer.people],
    ],
  },
  [MARKETPLACE_ROUTE]: {
    permissions: [['read', Resources.employer.marketplace]],
  },
  [OFFBOARDING_ROUTE]: {
    permissions: [['read', Resources.employer.offboarding]],
  },
  [PAYMENT_METHOD_CARDS_UPDATE_ROUTE]: {
    permissions: [['create', Resources.employer.payment_methods]],
  },
  [PAYMENT_METHODS_ROUTE]: {
    permissions: [['navigate', Resources.employer.payment_methods]],
  },
  [PAYMENT_REQUESTS_ROUTE]: {
    permissions: [
      ['read', Resources.employer.payment_requests],
      ['read', Resources.employer.scheduled_contractor_invoices],
      ['read', Resources.employer.billing],
    ],
    operator: userCanOperators.SOME,
  },
  [ADD_CONTRACTOR_PREFUNDING_ROUTE]: {
    permissions: [['create', Resources.employer.payment_requests]],
  },
  [PAY_FOR_PLAN_ROUTE]: {
    permissions: [
      ['update', Resources.employer.pricing_plans],
      ['create', Resources.employer.pricing_plans],
      ['read', Resources.employer.outstanding_payments_refunds],
      ['create', Resources.employer.payment_requests],
      ['create', Resources.employer.payment_methods],
    ],
  },
  [PAY_FOR_PLAN_STATUS_ROUTE]: {
    permissions: [
      ['read', Resources.employer.pricing_plans],
      ['read', Resources.employer.outstanding_payments_refunds],
      ['read', Resources.employer.payment_methods],
    ],
  },
  [PAYMENT_REQUESTS_TRANSACTIONS_ROUTE]: {
    permissions: [['read', Resources.employer.outstanding_payments_refunds]],
  },
  [PAYMENT_REQUESTS_SCHEDULES_ROUTE]: {
    permissions: [['read', Resources.employer.scheduled_contractor_invoices]],
  },
  [PAYROLL_LANDING_ROUTE]: {
    permissions: [['read', Resources.employer.payroll]],
  },
  [PAYROLL_RUN_DETAILS_ROUTE]: {
    permissions: [['read', Resources.employer.payroll]],
  },
  [PAYROLL_EMPLOYEE_PAYMENTS_ROUTE]: {
    permissions: [['read', Resources.employer.payroll]],
  },
  [PAYROLL_SETTINGS_ROUTE]: {
    permissions: [['update', Resources.employer.legal_entities]],
  },
  [PERSONAL_PROFILE_ROUTE]: {
    permissions: [['read', Resources.personal_information]],
  },
  [PRICING_PLAN_ROUTE]: {
    permissions: [['navigate', Resources.employer.company_details]],
  },
  [REMOTE_EQUITY_ROUTE]: {
    permissions: [['navigate', Resources.employer.company_equity]],
  },
  [REPORTS_DETAILS_ROUTE]: {
    permissions: [['navigate', Resources.employer.reporting_library]],
  },
  [REPORTS_ROUTE]: {
    permissions: [['navigate', Resources.employer.reporting_library]],
  },
  [SELECT_PRODUCT_PLAN_ROUTE]: {
    permissions: [
      ['update', Resources.employer.pricing_plans],
      ['create', Resources.employer.pricing_plans],
    ],
  },
  [PRODUCT_PLAN_CHANGES_ROUTE]: {
    permissions: [
      ['read', Resources.employer.pricing_plans],
      ['update', Resources.employer.pricing_plans],
      ['create', Resources.employer.pricing_plans],
    ],
  },
  [SUBSCRIPTION_INFO_ROUTE]: {
    permissions: [['navigate', Resources.employer.company_details]],
  },
  [SUPPORT_MONITOR_ROUTE]: {
    permissions: [['read', Resources.employer.people]],
  },
  [SUPPORT_MONITOR_TICKET_DETAILS_ROUTE]: {
    permissions: [['read', Resources.employer.people]],
  },
  [TALENT_BASE_ROUTE]: {
    permissions: [
      ['navigate', Resources.employer.company_profiles],
      ['navigate', Resources.employer.jobs],
    ],
  },
  [TALENT_INSIGHTS_ROUTE]: {
    permissions: [
      // Temporarily disabling until we have permissions in production. See https://remote-com.slack.com/archives/C05LFH4JR3P/p1737587030580649?thread_ts=1737556217.205679&cid=C05LFH4JR3P
      // ['navigate', Resources.talent_insights],
      // ['read', Resources.talent_insights],
    ],
  },
  [TALENT_SOURCING_ROUTE]: {
    permissions: [
      ['navigate', Resources.employer.company_profiles],
      ['navigate', Resources.employer.jobs],
    ],
  },
  [ADD_PROSPECT_TO_JOB_ROUTE]: {
    permissions: [['create', Resources.employer.jobs]],
  },
  [COMPANY_TALENT_PLANS_ROUTE]: {
    permissions: [
      ['navigate', Resources.employer.company_profiles],
      ['navigate', Resources.employer.jobs],
    ],
  },
  [COMPANY_BIO_ROUTE]: {
    permissions: [
      ['navigate', Resources.employer.company_profiles],
      ['navigate', Resources.employer.jobs],
    ],
  },
  [TALENT_SUBSCRIPTION_PLANS_ROUTE]: {
    permissions: [['navigate', Resources.employer.jobs]],
  },
  [TEAM_ROUTE]: {
    permissions: [['navigate', Resources.employer.people]],
  },
  [TEAM_ORG_CHART_ROUTE]: {
    permissions: [['read', Resources.employer.dashboard]],
  },
  [TEAM_WORLD_MAP_ROUTE]: {
    permissions: [['navigate', Resources.employer.people]],
  },
  [TEAM_PERFORMANCE_ROUTE]: {
    permissions: [['navigate', Resources.employer.performance_manage_teams]],
  },
  [TIME_OFF_ROUTE]: {
    permissions: [
      ['read', Resources.time_off],
      ['read', Resources.employer.time_off_management],
    ],
    operator: userCanOperators.SOME,
  },
  [TIME_OFF_REQUESTS_ROUTE]: {
    permissions: [['read', Resources.employer.time_off_management]],
  },
  [TIME_TRACKING]: {
    permissions: [
      ['read', Resources.time_tracking],
      ['read', Resources.employer.time_tracking],
    ],
    operator: userCanOperators.SOME,
  },
  [PROJECTS_ROUTE]: {
    permissions: [['read', Resources.project_management]],
  },
  [PROJECTS_EDIT_ROUTE]: {
    permissions: [['update', Resources.project_management]],
  },
  [USER_PROFILE_ROUTE]: {
    permissions: [
      ['read', Resources.employer.people],
      ['read', Resources.employer.hiring],
    ],
    operator: userCanOperators.SOME,
  },
  [USER_PROFILE_OFFBOARDING_ROUTE]: {
    permissions: [
      ['create', Resources.employer.offboarding],
      ['update', Resources.employer.offboarding],
    ],
    operator: userCanOperators.SOME,
  },
  [VIEW_JOB_ROUTE]: {
    permissions: [['read', Resources.employer.jobs]],
  },
  [JOB_PROSPECTS_ROUTE]: {
    permissions: [['read', Resources.employer.candidate_profiles]],
  },
  [WITHDRAWAL_METHODS_ROUTE]: {
    permissions: [['read', Resources.bank_accounts]],
  },
  [WORKFLOWS_HOME_ROUTE]: {
    permissions: [['read', Resources.workflows]],
  },
};
