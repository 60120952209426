import type { GetResponse } from '@remote-com/data-layer';

import type { AssignedRole as BaseAssignedRole } from '@/src/api/config/api.types';
import type { ReportsApi } from '@/src/api/config/employ/reports.types';
import type { Amount } from '@/src/api/config/employ/shared.types';
import { columnDefinitions } from '@/src/domains/reports/components/ReportTable/columnDefinitions/columnDefinitions';
import { REPORT_LIST, REPORTS_SUMMARY_CATEGORIES_COLORS } from '@/src/domains/reports/constants';
import type { GroupedSummary } from '@/src/domains/reports/types';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';

import { customFieldColumn, leaveColumn } from './components/ReportTable/columnDefinitions/columns';

// Function to generate summary segments with grouping and color assignment
export const generateSummarySegments = (totals: ReportsApi.ReportSummaryItem[]): GroupedSummary => {
  // Grouping the summaries by currency
  const grouped = totals.reduce<{ [key: string]: ReportsApi.ReportSummaryItem[] }>(
    (result, item) => {
      const { code } = item.amount.convertedCurrency;
      if (!result[code]) {
        result[code] = [];
      }
      result[code].push(item);
      return result;
    },
    {}
  );

  // Creating the final structure with color added
  return Object.keys(grouped).map((currencyCode, index) => {
    const currency = grouped[currencyCode][0].amount.convertedCurrency;
    const coloredSummary = grouped[currencyCode].map((elem) => ({
      ...elem,
      color: REPORTS_SUMMARY_CATEGORIES_COLORS[index % REPORTS_SUMMARY_CATEGORIES_COLORS.length],
    }));
    return {
      currency,
      totals: coloredSummary,
    };
  });
};

function isAmount(value: unknown): value is Amount {
  return !!value && typeof value === 'object' && 'convertedCurrency' in value;
}

export function currencyForRow(row: ReportsApi.ReportData) {
  const currencyCodes = Object.values(row)
    .filter(isAmount)
    .map((value) => value.convertedCurrency.code);

  const uniqueCurrencies = [...new Set(currencyCodes)];

  return uniqueCurrencies.length === 1 ? uniqueCurrencies[0] : null;
}

export const generateDynamicColumns = (
  customFieldDefinitions: GetResponse<'/api/v1/employer/custom-field-definitions'>['data']['customFieldDefinitions'],
  reportSlug: string
) => {
  let columns = columnDefinitions[reportSlug];
  if (customFieldDefinitions?.length === 0) return columns;

  customFieldDefinitions.forEach((definition) => {
    // This matches Primer's custom field
    // We add it at the 10th position (after the country of employment column)
    if (definition.slug === '64c445c6-4eb2-44b3-8fca-1a3fc4c56d40') {
      columns = [
        ...columns.slice(0, 10),
        customFieldColumn({
          definition,
          options: {
            disableExport: false,
            disableInView: false,
          },
        }),
        ...columns.slice(10),
      ];
    } else {
      columns = [
        ...columns,
        customFieldColumn({
          definition,
        }),
      ];
    }
  });

  return columns;
};

export const generateLeaveAndAbsenceColumns = (
  responseColumns: GetResponse<'/api/v1/reports/leave-and-absence'>['data']['report_data']
) => {
  let columns = columnDefinitions['leave-and-absence'];
  responseColumns?.[0]?.leaves?.forEach((leave: { name: string; totalDaysTaken: number }) => {
    columns = [...columns, leaveColumn({ definition: leave })];
  });

  return columns;
};

interface ExtendedAssignedRole extends BaseAssignedRole {
  dataScope: string;
}

function hasDataScope(role: BaseAssignedRole): role is ExtendedAssignedRole {
  return 'dataScope' in role;
}

export function getScopesFromRoles(roles?: BaseAssignedRole[]): string[] {
  if (!roles) return [];

  return roles.filter(hasDataScope).map((role) => role.dataScope);
}

export function getReportCategory(slug: string) {
  return REPORT_LIST.find((report) => report.reportTemplates.some((r) => r.slug === slug))
    ?.category;
}

export const isReportUsingEmployeePeopleEndpoint = (endpoint: string) =>
  endpoint.includes('api/v1/reports/people') && !endpoint.includes('leave-and-absence');

export const formatTimeTaken = (daysTaken: number, hoursTaken: number) => {
  const dayDuration = getSingularPluralUnit(daysTaken, 'day', 'days', false);
  const hourDuration =
    hoursTaken > 0 ? getSingularPluralUnit(hoursTaken, 'hour', 'hours', false) : '';

  return `${dayDuration} ${hourDuration}`;
};
