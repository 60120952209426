import type { GetResponse } from '@remote-com/data-layer';
import { Pill, Stack, Tooltip } from '@remote-com/norma';
import { IconDownload } from '@remote-com/norma/icons/IconDownload';

export const RowActionContent = ({
  file,
}: {
  file: GetResponse<'/api/v1/employer/payroll-reports'>['data']['payrollReports'][number]['files'][number];
}) => {
  const RowActionComponent = ({
    pillText,
    tooltipText,
  }: {
    pillText: string;
    tooltipText: string;
  }) => (
    <Tooltip label={tooltipText} position="right" zIndex={100000000}>
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Stack direction="row" gap={4} alignItems="flex-start">
          <IconDownload width="20px" height="20px" />
          Download {file.format ? file.format?.toUpperCase() : ''}
        </Stack>
        <Pill tone="neutralLight">{pillText}</Pill>
      </Stack>
    </Tooltip>
  );

  if (file.subType === 'customer_gross_to_net_billing_currency') {
    return (
      <RowActionComponent
        pillText="Invoice"
        tooltipText="All currency amounts are shown in the invoice currency set for your account at the time the payroll was billed."
      />
    );
  }

  return (
    <RowActionComponent
      pillText="Local"
      tooltipText="All currency amounts are shown in the local currencies of the respective employees."
    />
  );
};
