import type { BadgeType } from '@remote-com/norma';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import type { ExpenseStatus } from '@/src/api/config/employ/expenses.types';
import { PAYROLL_RUN_STATUSES } from '@/src/domains/payroll/constants';
import { generateSelectOptions } from '@/src/helpers/forms';
import type { ScreamingSnakeCaseMapping } from '@/src/types/case';

export const expenseStatus: ScreamingSnakeCaseMapping<ExpenseStatus> = {
  DRAFT: 'draft',
  PENDING: 'pending',
  PROCESSING: 'processing',
  APPROVED: 'approved',
  REIMBURSED: 'reimbursed',
  DECLINED: 'declined',
  CANCELED: 'canceled',
  ARCHIVED: 'archived',
};

export const expenseLabels: Record<ExpenseStatus, string> = {
  [expenseStatus.DRAFT]: 'Draft',
  [expenseStatus.PENDING]: 'Pending',
  [expenseStatus.APPROVED]: 'Approved',
  [expenseStatus.PROCESSING]: 'Processing',
  [expenseStatus.DECLINED]: 'Declined',
  [expenseStatus.REIMBURSED]: 'Reimbursed',
  [expenseStatus.CANCELED]: 'Canceled',
  [expenseStatus.ARCHIVED]: 'Archived',
};

export const expenseBadges: Record<ExpenseStatus, BadgeType> = {
  [expenseStatus.DRAFT]: 'inactive',
  [expenseStatus.PENDING]: 'pending',
  [expenseStatus.APPROVED]: 'active',
  [expenseStatus.PROCESSING]: PAYROLL_RUN_STATUSES.PROCESSING.badge,
  [expenseStatus.DECLINED]: 'error',
  [expenseStatus.REIMBURSED]: PAYROLL_RUN_STATUSES.COMPLETED.badge,
  [expenseStatus.CANCELED]: 'inactive',
  [expenseStatus.ARCHIVED]: 'inactive',
};

// Processing and Reimbursed status can't be updated manually, they are referenced from the payroll run
export const editableExpenseStatusOptions = generateSelectOptions(
  omit(expenseLabels, [expenseStatus.PROCESSING, expenseStatus.DRAFT])
);
export const expenseStatusOptions = generateSelectOptions(expenseLabels);
export const expenseStatusOptionsForProfile = generateSelectOptions(
  omit(expenseLabels, [expenseStatus.DRAFT])
);
export const employerExpenseStatusOptions = generateSelectOptions(
  pick(expenseLabels, [expenseStatus.APPROVED, expenseStatus.DECLINED])
);
export const employerExpenseApprovedTabStatusOptions = generateSelectOptions(
  pick(expenseLabels, [expenseStatus.APPROVED, expenseStatus.PROCESSING, expenseStatus.REIMBURSED])
);

export const employeeExpenseVisibleStatuses = [
  expenseStatus.PENDING,
  expenseStatus.APPROVED,
  expenseStatus.PROCESSING,
  expenseStatus.REIMBURSED,
  expenseStatus.DECLINED,
  expenseStatus.CANCELED,
];

export const expenseCategories = {
  HOME_OFFICE: 'home_office', // deprecated
  COWORKING: 'coworking', // deprecated
  TRAVEL: 'travel', // deprecated
  PHONE_UTILITIES: 'phone_utilities', // deprecated
  CAR_RENTAL: 'car_rental',
  COWORKING_OFFICE: 'coworking_office',
  ENTERTAINMENT: 'entertainment',
  EQUIPMENT: 'tech_equipment',
  FLIGHT: 'flight',
  FUEL: 'fuel',
  GIFTS: 'gifts',
  INSURANCE: 'insurance',
  TELECOMMUNICATION: 'telecommunication',
  LODGING: 'lodging',
  MEALS: 'meals',
  OTHER: 'other',
  PARKING_TOLL: 'parking_toll',
  SUBSCRIPTION: 'subscription',
  TRAINING: 'education_training',
  TRANSPORT: 'transport',
  UTILITIES: 'utilities',
  VACCINATION_TESTING: 'vaccination_testing',
  VISA: 'visa',
  WELLNESS: 'wellness',
  PER_DIEM: 'per_diem',
  MILEAGE: 'mileage',
};

export const expenseCategoryLabels = {
  [expenseCategories.CAR_RENTAL]: 'Car Rental',
  [expenseCategories.COWORKING]: 'Coworking', // deprecated
  [expenseCategories.COWORKING_OFFICE]: 'Coworking / Office',
  [expenseCategories.ENTERTAINMENT]: 'Entertainment',
  [expenseCategories.EQUIPMENT]: 'Tech/Work Equipment',
  [expenseCategories.FLIGHT]: 'Flight',
  [expenseCategories.FUEL]: 'Fuel',
  [expenseCategories.GIFTS]: 'Gifts',
  [expenseCategories.HOME_OFFICE]: 'Home Office', // deprecated
  [expenseCategories.INSURANCE]: 'Insurance',
  [expenseCategories.TELECOMMUNICATION]: 'Telecommunication',
  [expenseCategories.LODGING]: 'Lodging',
  [expenseCategories.MEALS]: 'Meals',
  [expenseCategories.OTHER]: 'Other',
  [expenseCategories.PARKING_TOLL]: 'Parking / Toll',
  [expenseCategories.PER_DIEM]: 'Per diem',
  [expenseCategories.PHONE_UTILITIES]: 'Phone / Utilities', // deprecated
  [expenseCategories.SUBSCRIPTION]: 'Subscription',
  [expenseCategories.TRAINING]: 'Education & Training',
  [expenseCategories.TRANSPORT]: 'Transport',
  [expenseCategories.TRAVEL]: 'Travel', // deprecated
  [expenseCategories.UTILITIES]: 'Utilities',
  [expenseCategories.VACCINATION_TESTING]: 'Vaccination / Testing',
  [expenseCategories.VISA]: 'Visa',
  [expenseCategories.WELLNESS]: 'Wellness',
};

const deprecatedExpenseCategoryOptions = [
  expenseCategories.HOME_OFFICE,
  expenseCategories.COWORKING,
  expenseCategories.TRAVEL,
  expenseCategories.PHONE_UTILITIES,
];
export const filteredExpenseCategoryLabels = omit(
  expenseCategoryLabels,
  deprecatedExpenseCategoryOptions
);
export const expenseCategoryOptions = generateSelectOptions(filteredExpenseCategoryLabels, true);

const invoicedStatus = {
  INVOICED: 'true',
  NON_INVOICED: 'false',
};

const invoicedStatusLabels = {
  [invoicedStatus.INVOICED]: 'Invoiced',
  [invoicedStatus.NON_INVOICED]: 'Non invoiced',
};

export const invoicedStatusOptions = generateSelectOptions(invoicedStatusLabels);

export const EXPENSE_ADMIN_QUERY = 'FETCH-ADMIN-EXPENSE';
export const EXPENSES_ADMIN_QUERY = 'FETCH-ADMIN-EXPENSES';
export const EXPENSE_COUNTRY_CATEGORY_QUERY = 'FETCH-COUNTRY-EXPENSE-CATEGORY';
export const EMPLOYMENT_COUNTRY_CODE = 'FETCH-EMPLOYMENT-COUNTRY-CODE';

export const EXPENSE_ORIGIN = {
  WEB_UPLOAD: 'web_upload',
  BULK_UPLOAD: 'bulk_upload',
};

export const CONFIDENCE_LEVEL = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};
export const CONFIDENCE_LEVEL_LABELS = {
  [CONFIDENCE_LEVEL.HIGH]: 'High',
  [CONFIDENCE_LEVEL.MEDIUM]: 'Medium',
  [CONFIDENCE_LEVEL.LOW]: 'Low',
};

export const confidenceLevelOptions = generateSelectOptions(CONFIDENCE_LEVEL_LABELS);
