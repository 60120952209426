import { FeedbackMessage, Stack, Text, themeV2 as theme, Tooltip, toast } from '@remote-com/norma';
import { IconTimes } from '@remote-com/norma/icons/IconTimes';
import { useEffect, useState, memo } from 'react';

import type { TableViewsApi } from '@/src/api/config/employ/tableViews.types';
import { Button, ButtonIcon } from '@/src/components/Button';
import { SetTableViewNameModal } from '@/src/components/Table/Components/TableViewsDrawer/SetTableViewNameModal';
import { buildTableView } from '@/src/components/Table/helpers/tableViewHelpers';
import type { useTableViews } from '@/src/components/Table/hooks/useTableViews';
import { DEFAULT_VIEW } from '@/src/components/Table/hooks/useTableViews';
import { useUpdateTableView } from '@/src/components/Table/hooks/useUpdateTableView';
import { getSingularPluralUnit } from '@/src/helpers/i18n/copy';
import { useModalContext } from '@/src/hooks/useModalContext';

export type TableViewFloatingActionsProps = {
  setListenToClickOutside?: (listen: boolean) => void;
  tableViews: ReturnType<typeof useTableViews>;
  setGlobalFilter: (filter: string) => void;
};

export const TableViewFloatingActions = memo(
  ({
    setListenToClickOutside = () => {},
    tableViews,
    setGlobalFilter,
  }: TableViewFloatingActionsProps) => {
    const {
      tableStateFilters,
      tableStateOrder,
      tableStateVisibility,
      tableStateSortBy,
      activeView,
      applyView,
      setActiveView,
      numberOfTableStateChanges,
    } = tableViews;

    const [visible, setVisible] = useState(!!numberOfTableStateChanges);
    const { showModal } = useModalContext();
    const { updateTableView } = useUpdateTableView();

    const hide = () => setVisible(false);
    const show = () => setVisible(true);

    useEffect(() => {
      if (numberOfTableStateChanges) show();
      if (!numberOfTableStateChanges) hide();
    }, [numberOfTableStateChanges]);

    if (!tableViews.shouldShowFloatingActions || !visible) return null;

    const canUpdateView = activeView?.slug !== DEFAULT_VIEW.slug && numberOfTableStateChanges > 0;

    const numberOfTableStateChangesLabel = getSingularPluralUnit(
      numberOfTableStateChanges,
      'change applied to the table',
      'changes applied to the table',
      false
    );

    const handleSaveTableView = () => {
      setListenToClickOutside(false);

      showModal({
        component: SetTableViewNameModal,
        modalProps: {
          messages: {
            title: 'Save table view',
          },
          setListenToClickOutside,
          filters: tableStateFilters,
          order: tableStateOrder,
          sortBy: tableStateSortBy,
          applyView,
          visibility: tableStateVisibility,
          persistenceKey: tableViews.persistenceKey,
          contentBeforeForm: (
            <FeedbackMessage variant="info">
              Saving this view will save the columns, filter and sorts that you've applied.
            </FeedbackMessage>
          ),
        },
      });
    };

    const handleUpdateTableView = () => {
      const tableView = buildTableView({
        name: activeView.name,
        columnsConfiguration: {
          name: activeView.name,
          customizations: {
            order: tableStateOrder,
            visibility: tableStateVisibility,
          },
        },
        persistenceKey: activeView.persistenceKey,
        filters: tableStateFilters,
        sortBy: tableStateSortBy,
      });

      hide();

      const onSuccess = (view?: TableViewsApi.TableView) => {
        if (view) setActiveView(view);
        toast.success('Table view saved.');
      };

      const onError = () => {
        show();
        toast.error('Failed to save table view.');
      };

      updateTableView({ tableView, tableViewSlug: activeView.slug, onSuccess, onError });
    };

    const handleResetTableView = () => {
      applyView(activeView);
      setGlobalFilter('');
    };

    const label = (
      <Stack direction="row" gap={2} alignItems="center" data-testid="table-view-floating-actions">
        <Text color="grey.500">{numberOfTableStateChangesLabel}</Text>
        {canUpdateView && (
          <Button variant="ghost" size="xs" onClick={handleUpdateTableView}>
            Update current view
          </Button>
        )}
        <Button variant="ghost" size="xs" onClick={handleSaveTableView}>
          Save new view
        </Button>
        {activeView.slug === DEFAULT_VIEW.slug ? (
          <Button variant="ghost" size="xs" tone="secondary" onClick={handleResetTableView}>
            Reset to default
          </Button>
        ) : (
          <Button variant="ghost" size="xs" tone="secondary" onClick={handleResetTableView}>
            Reset changes
          </Button>
        )}
        <ButtonIcon
          variant="ghost"
          size="xs"
          tone="secondary"
          Icon={IconTimes}
          aria-label="Close"
          onClick={hide}
          data-testid="close-floating-actions"
        />
      </Stack>
    );

    return (
      <Tooltip
        styles={{
          wrapper: {
            padding: `${theme.space['3']} ${theme.space['2']} ${theme.space['3']}, ${theme.space['4']}`,
            borderRadius: theme.radii.md,
          },
        }}
        label={label}
        visible={visible}
        arrow={false}
        maxWidth={700}
        position="top-end"
        zIndex={99}
      >
        <span />
      </Tooltip>
    );
  },
  ({ tableViews: prevTableViews }, { tableViews: nextTableViews }) => {
    const { numberOfTableStateChanges: prevChanges, shouldShowFloatingActions: prevShow } =
      prevTableViews;

    const { numberOfTableStateChanges: nextChanges, shouldShowFloatingActions: nextShow } =
      nextTableViews;

    return prevChanges === nextChanges && prevShow === nextShow;
  }
);
