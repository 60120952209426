import { PRODUCT_PRICING_FREQUENCY, PRODUCT_TIERS } from '@/src/domains/pricing/constants';
import {
  eorPlanProductTypeIdentifiers,
  recruitPlanTypes,
} from '@/src/domains/pricingPlans/common/constants';
import type { PricingPlanType } from '@/src/domains/pricingPlans/common/PricingPlans.types';
import {
  isModifiedPricingPlanContractor,
  isModifiedPricingPlanContractorOfRecord,
  isModifiedPricingPlanEOR,
  isModifiedPricingPlanRecruit,
  isModifiedPricingPlanRemotePayroll,
  isModifiedPricingPlanRemoteTalent,
} from '@/src/domains/pricingPlans/common/utils';

export const getPricingPlanAttributes = (planType: PricingPlanType) => {
  const isEOR = isModifiedPricingPlanEOR(planType);

  const isEORAnnual = isModifiedPricingPlanEOR(planType, {
    frequency: PRODUCT_PRICING_FREQUENCY.ANNUAL,
  });

  const isEORAnnualCore = isModifiedPricingPlanEOR(planType, {
    frequency: PRODUCT_PRICING_FREQUENCY.ANNUAL,
    productType: eorPlanProductTypeIdentifiers.CORE,
  });

  const isEORAnnualLowIncome = isModifiedPricingPlanEOR(planType, {
    frequency: PRODUCT_PRICING_FREQUENCY.ANNUAL,
    productType: eorPlanProductTypeIdentifiers.LOW_INCOME,
  });

  const isEORMonthly = isModifiedPricingPlanEOR(planType, {
    frequency: PRODUCT_PRICING_FREQUENCY.MONTHLY,
  });

  const isEORMonthlyCore = isModifiedPricingPlanEOR(planType, {
    frequency: PRODUCT_PRICING_FREQUENCY.MONTHLY,
    productType: eorPlanProductTypeIdentifiers.CORE,
  });

  const isEORMonthlyLowIncome = isModifiedPricingPlanEOR(planType, {
    frequency: PRODUCT_PRICING_FREQUENCY.MONTHLY,
    productType: eorPlanProductTypeIdentifiers.LOW_INCOME,
  });

  const isRemotePayroll = isModifiedPricingPlanRemotePayroll(planType);

  const isContractorPlus = isModifiedPricingPlanContractor(planType, {
    tier: PRODUCT_TIERS.PLUS,
  });

  const isContractorOfRecord = isModifiedPricingPlanContractorOfRecord(planType);

  const isContractor = isModifiedPricingPlanContractor(planType);

  const isRemoteTalent = isModifiedPricingPlanRemoteTalent(planType);

  const isRecruit = isModifiedPricingPlanRecruit(planType);

  const isRecruitFreeTrial = isModifiedPricingPlanRecruit(planType, {
    type: recruitPlanTypes.FREE_TRIAL,
  });

  const isRecruitEssential = isModifiedPricingPlanRecruit(planType, {
    type: recruitPlanTypes.ESSENTIAL,
  });

  const isRecruitGrowth = isModifiedPricingPlanRecruit(planType, {
    type: recruitPlanTypes.GROWTH,
  });

  const isRecruitStrategic = isModifiedPricingPlanRecruit(planType, {
    type: recruitPlanTypes.STRATEGIC,
  });

  return {
    isEOR,
    isEORAnnual,
    isEORAnnualCore,
    isEORAnnualLowIncome,
    isEORMonthly,
    isEORMonthlyCore,
    isEORMonthlyLowIncome,
    isRemotePayroll,
    isContractorOfRecord,
    isContractorPlus,
    isContractor,
    isRemoteTalent,
    isRecruit,
    isRecruitFreeTrial,
    isRecruitEssential,
    isRecruitGrowth,
    isRecruitStrategic,
  };
};
