import getConfig from 'next/config';

import { SIGN_IN_ROUTE } from '@/src/constants/routes';
import { fetchZendeskSsoJWT } from '@/src/services/Zendesk';

export async function getZendeskSSOUrl(returnTo) {
  const {
    publicRuntimeConfig: { ZENDESK_SSO_URL },
  } = getConfig() || {};

  // eslint-disable-next-line no-console
  console.log('DEBUG: Zendesk SSO URL - start');

  try {
    const {
      data: { jwt },
    } = await fetchZendeskSsoJWT();

    const params = new URLSearchParams({ jwt, return_to: returnTo });

    // eslint-disable-next-line no-console
    console.log('DEBUG: Zendesk SSO URL - success');
    return `${ZENDESK_SSO_URL}/access/jwt?${params.toString()}`;
  } catch (e) {
    console.error('DEBUG: Zendesk SSO URL - error:', e?.response?.data?.message);
    if (typeof e === 'object' && e?.response?.data?.message === 'sso-domain-unverified') {
      return `${SIGN_IN_ROUTE}?reason=sso-domain-unverified`;
    }
    return SIGN_IN_ROUTE;
  }
}
