import { toast } from '@remote-com/norma';

import { useUserContext } from '@/src/components/UserProvider';
import { useFetchFeatureFlagConfig } from '@/src/domains/feature-flag/host/useFetchFeatureFlagConfig';
import { error as errorHandler } from '@/src/helpers/general';

import type { FeatureFlagAttributeValue } from '../config';
import { FeatureFlagProvider } from '../context';

// These are the attributes supported  by the SDK
const ATTRIBUTE = {
  UserCountry: 'userCountry',
  UserType: 'userType',
  CompanySlug: 'companySlug',
} as const;

type SupportedAttribute = (typeof ATTRIBUTE)[keyof typeof ATTRIBUTE];

export interface EmployUserAttributes
  extends Record<SupportedAttribute, FeatureFlagAttributeValue> {}

interface EmployFeatureFlagProviderProps {
  /** Error that occurred while fetching the feature flag config */
  error?: Error;
}

/**
 * EmployFeatureFlagProvider is a wrapper around the FeatureFlagProvider that provides a pre-defined set of
 * user attributes (see `EmployUserAttributes`) based on the account object.
 * It also provides a userId, which can be the account slug OR any other user identifier (an URL/Query param, a random ID, etc.).
 */
export const EmployFeatureFlagProvider: React.FC<EmployFeatureFlagProviderProps> = ({
  children,
}) => {
  const { user: account } = useUserContext();
  const { data, isLoading } = useFetchFeatureFlagConfig();

  if (isLoading) {
    return null;
  }

  if (data?.error !== undefined) {
    errorHandler(data.error, { ownership: ['Development Experience'] });
    toast.error(
      'There has been an error fetching app configuration. Some functionality may be limited.'
    );
  }

  const config = data?.config;

  return (
    <FeatureFlagProvider
      config={config ?? {}}
      userId={account?.slug ?? null}
      attributes={account?.userAttributes ?? {}}
    >
      {children}
    </FeatureFlagProvider>
  );
};
