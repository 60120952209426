import type { ValueOf } from 'type-fest';

import { generateSelectOptions } from '@/src/helpers/forms';

export const CONTRACT_DOCUMENT_ENTITY_TYPE = {
  COMPANY: 'company',
  USER: 'user',
} as const;

export const CONTRACT_DOCUMENT_SIGNATURE = {
  ONLY_ASSIGNED: 'only_assigned',
  ONLY_SIGNED_FOR_USER_BY_PROCURATION: 'only_signed_for_user_by_procuration',
};

export const CONTRACT_DOCUMENT_SIGNATURE_LABELS = {
  [CONTRACT_DOCUMENT_SIGNATURE.ONLY_ASSIGNED]: 'Contracts assigned to me',
  [CONTRACT_DOCUMENT_SIGNATURE.ONLY_SIGNED_FOR_USER_BY_PROCURATION]:
    'Contracts signed on my behalf',
};

export const CONTRACT_DOCUMENT_SIGNATURE_OPTIONS = generateSelectOptions(
  CONTRACT_DOCUMENT_SIGNATURE_LABELS
);

export const CONTRACT_DOCUMENT_STATUS = {
  DRAFT: 'draft',
  AWAITING_SIGNATURES: 'awaiting_signatures',
  AWAITING_CUSTOMER_APPROVAL: 'TODO_this_is_a_placeholder_for_awaiting_customer_approval',
  APPROVED_BY_CUSTOMER: 'TODO_this_is_a_placeholder_for_approved_by_customer',
  REJECTED_BY_CUSTOMER: 'TODO_this_is_a_placeholder_for_rejected_by_customer',
  FINISHED: 'finished',
  ARCHIVED: 'archived',
  REVISED: 'revised',
} as const;

export const CONTRACT_DOCUMENT_STATUS_OPTIONS = generateSelectOptions({
  [CONTRACT_DOCUMENT_STATUS.DRAFT]: 'Draft',
  [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: 'Awaiting signatures',
  [CONTRACT_DOCUMENT_STATUS.FINISHED]: 'Signed/Sent',
  [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: 'Archived',
  [CONTRACT_DOCUMENT_STATUS.REVISED]: 'Revised',
});

export type ContractDocumentStatusBadges = Partial<{
  [key in ValueOf<typeof CONTRACT_DOCUMENT_STATUS>]: {
    label: string;
    type: 'active' | 'inactive' | 'pending' | 'closed';
  };
}> & {
  other: {
    label: string;
    type: 'inactive';
  };
  sent: {
    label: string;
    type: 'active';
  };
};

export const CONTRACT_DOCUMENT_STATUS_BADGES: ContractDocumentStatusBadges = {
  [CONTRACT_DOCUMENT_STATUS.DRAFT]: {
    label: 'Draft',
    type: 'inactive',
  },
  [CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES]: {
    label: 'Awaiting signatures',
    type: 'pending',
  },
  [CONTRACT_DOCUMENT_STATUS.FINISHED]: {
    label: 'Signed',
    type: 'active',
  },
  [CONTRACT_DOCUMENT_STATUS.ARCHIVED]: {
    label: 'Archived',
    type: 'inactive',
  },
  [CONTRACT_DOCUMENT_STATUS.REVISED]: {
    label: 'Revised',
    type: 'closed',
  },
  other: {
    label: 'Other',
    type: 'inactive',
  },
  sent: {
    label: 'Sent',
    type: 'active',
  },
};

export const EDITABLE_CONTRACT_STATUSES = new Set([
  CONTRACT_DOCUMENT_STATUS.DRAFT,
  CONTRACT_DOCUMENT_STATUS.AWAITING_SIGNATURES,
]);
