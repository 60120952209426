import getConfig from 'next/config';
import Head from 'next/head';

import { defaultIcons } from './constants';

const {
  publicRuntimeConfig: { ENVIRONMENT },
} = getConfig();

const currentEnvironment = ENVIRONMENT !== 'not-set' ? ENVIRONMENT : 'development';

function getFavicons(temporaryIcon = {}) {
  return {
    faviconPNG:
      temporaryIcon.faviconPNG ||
      defaultIcons[currentEnvironment]?.faviconPNG ||
      defaultIcons.production.faviconPNG,
    faviconSVG:
      temporaryIcon.faviconSVG ||
      defaultIcons[currentEnvironment]?.faviconSVG ||
      defaultIcons.production.faviconSVG,
    faviconAppleTouchIcon:
      temporaryIcon.appleTouchIcon ||
      defaultIcons[currentEnvironment]?.appleTouchIcon ||
      defaultIcons.production.appleTouchIcon,
  };
}

export function Favicon() {
  const favicons = getFavicons();

  return (
    <Head>
      <link rel="icon" href={favicons.faviconPNG} sizes="any" />
      <link rel="icon" href={favicons.faviconSVG} type="image/svg+xml" />
      <link rel="apple-touch-icon" href={favicons.faviconAppleTouchIcon} />
    </Head>
  );
}
