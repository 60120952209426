import getConfig from 'next/config';

function isNACURL(parsedURL) {
  const {
    publicRuntimeConfig: { NEO_ADMIN_URL },
  } = getConfig() || {};

  const neoAdminURL = new URL(NEO_ADMIN_URL);

  return parsedURL.origin === neoAdminURL.origin;
}

// The EOR Authorize URL is accessed through the EOR API Gateway. The page
// is rendered in the EOR API service and is not accessible through Tiger.
//
// The page is used during the OAuth2 authorization process. After the user
// has logged in, the user should be able to be redirected back to the authorize page
// which then will be accessible.
function isEORAuthorizeURL(url) {
  const {
    publicRuntimeConfig: { EOR_API_AUTHORIZE_URL, BASE_URL },
  } = getConfig() || {};

  const EORAuthorizeURL = new URL(EOR_API_AUTHORIZE_URL);
  const parsedURL = new URL(url, BASE_URL);

  return (
    parsedURL.origin === EORAuthorizeURL.origin && parsedURL.pathname === EORAuthorizeURL.pathname
  );
}

export function isInternalURL(url) {
  const {
    publicRuntimeConfig: { BASE_URL },
  } = getConfig() || {};
  // Check README.md for an explanation for getConfig usage

  // This in conjunction with url.href.startsWith(BASE_URL) below protects against open redirect
  // attacks. The URL constructor will use BASE_URL as a prefix for the url object if a relative
  // URL is passed as a first argument. If an absolute URL is passed as a first argument, BASE_URL
  // will be discarded instead. We then make sure we're not redirecting the user to another domain
  // by checking if the URL starts with our BASE_URL.
  const parsedURL = new URL(url, BASE_URL);
  const baseURL = new URL(BASE_URL);

  return parsedURL.origin === baseURL.origin || isEORAuthorizeURL(url) || isNACURL(parsedURL);
}

export function isZendeskURL(url) {
  if (!url) {
    return false;
  }

  const {
    publicRuntimeConfig: { ZENDESK_URL },
  } = getConfig() || {};

  const parsedURL = new URL(url, ZENDESK_URL);
  const baseURL = new URL(ZENDESK_URL);
  return parsedURL.origin === baseURL.origin;
}
