// Required
export const TAKE_QUICK_EMPLOYER_TOUR_PROMPT = 'take_quick_employer_tour';

// Not required
export const COST_CALCULATOR_USER_PROMPT = 'perform_cost_calculation';
export const COMPENSATION_EXPLORER_PROMPT = 'use_compensation_explorer';
export const VIEW_MARKETPLACE_PROMPT = 'view_marketplace';
export const TIME_OFF_PROMPT = 'time_off';
export const ADD_EOR_EMPLOYEE_PROMPT = 'add_eor_employee';
export const ADD_DIRECT_EMPLOYEE_PROMPT = 'add_direct_employee';
export const ADD_GLOBAL_PAYROLL_EMPLOYEE_PROMPT = 'add_global_payroll_employee';
export const HRIS_MOBILE_APP_PROMPT = 'hris_mobile_app';
export const REFERRAL_PROGRAM_PROMPT = 'referral_program';
export const CONTRACTOR_ONBOARDING_PROMPT = 'contractor_onboarding';
export const CONTRACTOR_PAYMENT_METHODS_PROMPT = 'contractor_payment_methods';
export const TAKE_FREELANCER_HUB_TOUR = 'take_freelancer_hub_tour';
export const TAKE_INVOICING_FROM_TIME_TRACKING_TOUR = 'take_invoicing_from_time_tracking_tour';
export const ADMIN_ROLES_PERMISSION = 'admin_roles_permission';
export const BOOK_GLOBAL_PAYROLL_DEMO = 'book_global_payroll_demo';
export const BULK_ADD_DIRECT_EMPLOYEE = 'bulk_add_direct_employee';
export const EMPLOYER_DASHBOARD = 'employer_dashboard';
export const VIEW_CONTRACTOR_MANAGEMENT_TOUR = 'view_contractor_management_tour';
export const VIEW_EMPLOYEE_EXPERIENCE_TOUR = 'view_employee_experience_tour';
export const VIEW_EOR_TOUR = 'view_eor_tour';
export const BENEFITS_SAFETY_WING = 'benefits_safety_wing';
export const HOW_TO_CREATE_AN_INVOICE = 'how_to_create_an_invoice';
export const SUBMIT_INVOICES_ON_THE_GO = 'submit_invoices_on_the_go';
export const DUPLICATE_AN_EXISTING_INVOICE = 'duplicate_an_existing_invoice';
export const EXPLORE_NEW_OPPORTUNITIES = 'explore_new_opportunities';
export const VIEW_TALENT_INSIGHTS_TOUR = 'view_talent_insights_tour';
export const VIEW_JOBS_TOUR = 'view_jobs_tour';
export const VIEW_CANDIDATES_TOUR = 'view_candidates_tour';
export const VIEW_BILLING_DEMO = 'view_billing_demo';
export const VIEW_BENEFITS_DEMO = 'view_benefits_demo';
export const INCENTIVES_PROMPT = 'incentives';

export type RequiredUserPromptAction = typeof TAKE_QUICK_EMPLOYER_TOUR_PROMPT;
export type NotRequiredUserPromptAction =
  | typeof COST_CALCULATOR_USER_PROMPT
  | typeof COMPENSATION_EXPLORER_PROMPT
  | typeof VIEW_MARKETPLACE_PROMPT
  | typeof TIME_OFF_PROMPT
  | typeof ADD_EOR_EMPLOYEE_PROMPT
  | typeof ADD_DIRECT_EMPLOYEE_PROMPT
  | typeof ADD_GLOBAL_PAYROLL_EMPLOYEE_PROMPT
  | typeof HRIS_MOBILE_APP_PROMPT
  | typeof REFERRAL_PROGRAM_PROMPT
  | typeof CONTRACTOR_ONBOARDING_PROMPT
  | typeof CONTRACTOR_PAYMENT_METHODS_PROMPT
  | typeof ADMIN_ROLES_PERMISSION
  | typeof BOOK_GLOBAL_PAYROLL_DEMO
  | typeof BULK_ADD_DIRECT_EMPLOYEE
  | typeof EMPLOYER_DASHBOARD
  | typeof VIEW_CONTRACTOR_MANAGEMENT_TOUR
  | typeof VIEW_EMPLOYEE_EXPERIENCE_TOUR
  | typeof VIEW_EOR_TOUR
  | typeof VIEW_TALENT_INSIGHTS_TOUR
  | typeof VIEW_JOBS_TOUR
  | typeof VIEW_CANDIDATES_TOUR
  | typeof VIEW_BILLING_DEMO
  | typeof VIEW_BENEFITS_DEMO
  | typeof INCENTIVES_PROMPT;

export type ContractorOnlyUserPromptAction =
  | typeof BENEFITS_SAFETY_WING
  | typeof HOW_TO_CREATE_AN_INVOICE
  | typeof TAKE_FREELANCER_HUB_TOUR
  | typeof TAKE_INVOICING_FROM_TIME_TRACKING_TOUR
  | typeof SUBMIT_INVOICES_ON_THE_GO
  | typeof DUPLICATE_AN_EXISTING_INVOICE
  | typeof EXPLORE_NEW_OPPORTUNITIES;

export type UserPromptAction =
  | ContractorOnlyUserPromptAction
  | NotRequiredUserPromptAction
  | RequiredUserPromptAction;
