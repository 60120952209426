import invert from 'lodash/invert';
import getConfig from 'next/config';

import { isFlagActive } from '@/src/components/FeatureFlag';

const {
  publicRuntimeConfig: {
    ZENDESK_FIELD_ID_EMPLOYEE_COUNTRY_CODE,
    ZENDESK_FIELD_ID_USER_TYPE,
    ZENDESK_FIELD_ID_COMPANY_NAME,
    ZENDESK_FIELD_ID_EMPLOYEE_FULL_NAME,
    ZENDESK_FIELD_ID_LIFECYCLE_RESIGNATION_REASON,
    ZENDESK_FIELD_ID_LIFECYCLE_TERMINATION_BY_CUSTOMER_REASON,
    ZENDESK_FIELD_ID_LIFECYCLE_END_OF_FIXED_TERM_CONTRACT_CUSTOMER_REASON,
    ZENDESK_FIELD_ID_EMPLOYEE_LINK,
    ZENDESK_FIELD_ID_EMPLOYEE_TICKET_TOPIC,
    ZENDESK_FIELD_ID_LIFECYCLE_TICKET_TOPIC,
    ZENDESK_FIELD_ID_LIFECYCLE_OFFBOARDING_RESIGNATION,
    ZENDESK_FIELD_ID_TASKS_TO_PERFORM_BY_LIFECYCLE,
    ZENDESK_FIELD_ID_LIFECYCLE_OFFBOARDING_CONVERSIONS_TERMINATION,
    ZENDESK_FIELD_ID_LIFECYCLE_CONTRACTS_MANAGEMENT_CONTRACT_PROBATION,
    ZENDESK_FIELD_ID_LIFECYCLE_CONTRACTS_MANAGEMENT_CONTRACT_RENEWAL,
    ZENDESK_FIELD_ID_MOBILITY_TICKET_TOPIC,
    ZENDESK_FIELD_ID_RESIGNATION_ACCEPTANCE_CUSTOMER_REP_NAME,
    ZENDESK_FIELD_ID_RESIGNATION_ACCEPTANCE_CUSTOMER_EMAIL,
    ZENDESK_FIELD_ID_PROBATION_PERIOD_END_DATE,
  },
} = getConfig();

export const metadataName = {
  linkToEmployeeAccount: 'linkToEmployeeAccount',
  isEmployeeInProbationPeriod: 'isEmployeeInProbationPeriod',
  resignationDate: 'resignationDate',
  employeeCountry: 'employeeCountry',
  remoteEntityName: 'remoteEntityName',
  remoteEntityAddress: 'remoteEntityAddress',
  employmentType: 'employmentType',
  employmentStartDate: 'employmentStartDate',
  jobTitle: 'jobTitle',
};

export const startRequestOriginOptions = {
  requestHub: 'request-hub',
  employeeProfile: 'employee-profile',
  offboardingDashboard: 'offboarding-dashboard',
};

/**
 * Metadata is any data that does not have a matching form field. Not all of these
 * labels are included in every single ticket. However, they are used in more than
 * one ticket, and because there is no other logical place to put them (e.g.,
 * isEmployeeInProbationPeriod is used both in /employee and /employer), we group
 * them here. Due to how we parse data in the ticket formatter, we only include those
 * that are defined within each Modal component.
 */
export const metadataLabels = {
  [metadataName.linkToEmployeeAccount]: 'Admin link to employee account',
  [metadataName.isEmployeeInProbationPeriod]: 'Employee in probation period',
  [metadataName.resignationDate]: 'Resignation date',
  [metadataName.employeeCountry]: 'Employee country',
  [metadataName.remoteEntityName]: 'Remote entity name',
  [metadataName.remoteEntityAddress]: 'Remote entity address',
  [metadataName.employmentType]: 'Employment type',
  [metadataName.employmentStartDate]: 'Employment start date',
  [metadataName.jobTitle]: 'Job title',
};

export const requestTypes = {
  AMENDMENT: 'contract_amendment',
  TERMINATION: 'termination',
  CANCELATION: 'cancelation',
  RENEWAL: 'renewal',
  EXTENSION: 'extension',
  RESIGNATION: 'resignation',
  VERIFICATION_LETTER: 'employment_letter',
  PROBATION_EXTENSION: 'probation_extension',
  PROBATION_PASSED_LETTER: 'probation_passed_letter',
  PUBLIC_HOLIDAY: 'public_holiday',
  ANNUAL_LEAVE: 'annual_leave',
  SPECIAL_LEAVE: 'special_leave',
  FAMILY_LEAVE: 'family_leave',
  SICK_LEAVE: 'sick_leave',
  TIMESHEETS: 'timesheets_and_working_hours',
  TRAVEL: 'travel_letter',
  MOBILITY_FOLLOW_UP: 'mobility_follow_up_inquiry',
  REMOTE_WORK: 'remote_work',
};

export const requestTypesLabels = {
  [requestTypes.AMENDMENT]: 'Employment agreement amendment',
  [requestTypes.TERMINATION]: 'Employee termination',
  [requestTypes.CANCELATION]: 'Cancel hiring before start date',
  [requestTypes.RENEWAL]: 'Employment agreement renewal',
  [requestTypes.EXTENSION]: 'Employment agreement extension',
  [requestTypes.RESIGNATION]: 'Resignation',
  [requestTypes.VERIFICATION_LETTER]: 'Employment letter',
  [requestTypes.PROBATION_EXTENSION]: 'Probation extension',
  [requestTypes.PROBATION_PASSED_LETTER]: 'Probation confirmation letter',
  [requestTypes.PUBLIC_HOLIDAY]: 'Public holiday query',
  [requestTypes.ANNUAL_LEAVE]: 'Annual leave query',
  [requestTypes.SPECIAL_LEAVE]: 'Special leave query',
  [requestTypes.FAMILY_LEAVE]: 'Family leave query',
  [requestTypes.SICK_LEAVE]: 'Sick leave query',
  [requestTypes.TIMESHEETS]: 'Working hours and timesheets query',
  [requestTypes.TRAVEL]: 'Travel letter',
  [requestTypes.REMOTE_WORK]: 'Remote work authorization',
  [requestTypes.MOBILITY_FOLLOW_UP]: 'Mobility follow-up question',
};

export const newRequestFlowEnabledTypes = [
  requestTypes.AMENDMENT,
  requestTypes.TERMINATION,
  requestTypes.RESIGNATION,
];

export const requestTypePerUser = {
  employee: [
    requestTypes.RESIGNATION,
    requestTypes.VERIFICATION_LETTER,
    requestTypes.PUBLIC_HOLIDAY,
    requestTypes.ANNUAL_LEAVE,
    requestTypes.SPECIAL_LEAVE,
    requestTypes.FAMILY_LEAVE,
    requestTypes.SICK_LEAVE,
    requestTypes.TIMESHEETS,
    requestTypes.TRAVEL,
  ],
  employer: [
    requestTypes.AMENDMENT,
    requestTypes.TERMINATION,
    requestTypes.RENEWAL,
    requestTypes.EXTENSION,
    requestTypes.PROBATION_EXTENSION,
    requestTypes.PROBATION_PASSED_LETTER,
    requestTypes.PUBLIC_HOLIDAY,
    requestTypes.ANNUAL_LEAVE,
    requestTypes.SPECIAL_LEAVE,
    requestTypes.SICK_LEAVE,
    requestTypes.FAMILY_LEAVE,
    requestTypes.TIMESHEETS,
    requestTypes.MOBILITY_FOLLOW_UP,
  ],
};

const LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX = 'lifecycle_contractmanagement';
const LIFECYCLE_OFFBOARDING_PREFIX = 'lifecycle_offboarding';
const LIFECYCLE_TIMEANDATTENDANCE_PREFIX = 'lifecycle_timeandattendance';

export const lifecycleTicketTopics = {
  LIFECYCLE_CONTRACT_MANAGEMENT: {
    CONTRACT_PROBATION: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_contract_probation`,
    CONTRACT_RENEWAL: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_contract_renewal`,
    CONTRACT_AMENDMENT: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_contract_amendment`,
    EMPLOYMENT_LETTER: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_letter`,
    EMPLOYMENT_LETTER_EMPLOYMENT_VERIFICATION: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_letter_employment_verification`,
    EMPLOYMENT_LETTER_EMPLOYMENT_VERIFICATION_BENEFITS_VERIFICATION: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_letter_benefits_verification`,
    EMPLOYMENT_LETTER_CHILDCARE_ALLOWANCE_APPLICATION: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_letter_childcare_allowance_application`,
    EMPLOYMENT_LETTER_SPECIAL_TAX_REGIME_APPLICATION: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_letter_special_taxregime_application`,
    EMPLOYMENT_LETTER_LOAN_APPLICATION: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_letter_loan_application`,
    EMPLOYMENT_LETTER_OTHER: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_letter_other`,
    EMPLOYMENT_VERIFICATION: `${LIFECYCLE_CONTRACT_MANAGEMENT_PREFIX}_employment_verification`,
  },
  LIFECYCLE_OFFBOARDING: {
    TERMINATION: `${LIFECYCLE_OFFBOARDING_PREFIX}_termination`,
    RESIGNATION: `${LIFECYCLE_OFFBOARDING_PREFIX}_resignation`,
  },
  TIME_OFF: {
    [requestTypes.PUBLIC_HOLIDAY]: `${LIFECYCLE_TIMEANDATTENDANCE_PREFIX}_public_holidays`,
    [requestTypes.ANNUAL_LEAVE]: `${LIFECYCLE_TIMEANDATTENDANCE_PREFIX}_annual_leave`,
    [requestTypes.SPECIAL_LEAVE]: `${LIFECYCLE_TIMEANDATTENDANCE_PREFIX}_occasional_leave`,
    [requestTypes.FAMILY_LEAVE]: `${LIFECYCLE_TIMEANDATTENDANCE_PREFIX}_family_leave`,
    [requestTypes.SICK_LEAVE]: `${LIFECYCLE_TIMEANDATTENDANCE_PREFIX}_sick_leave`,
    [requestTypes.TIMESHEETS]: `${LIFECYCLE_TIMEANDATTENDANCE_PREFIX}_working_hours`,
  },
};

export const mobilityTicketTopics = {
  [requestTypes.TRAVEL]: 'mob_visa_letter',
  [requestTypes.MOBILITY_FOLLOW_UP]: 'mobility_team',
};

export const zendeskTicketFieldsIds = {
  EMPLOYEE_COUNTRY_CODE: ZENDESK_FIELD_ID_EMPLOYEE_COUNTRY_CODE || 1900003322787, // Employee Country Code
  USER_TYPE: ZENDESK_FIELD_ID_USER_TYPE || 1900005091107, // User Type
  COMPANY_NAME: ZENDESK_FIELD_ID_COMPANY_NAME || 360039618711, // Company Name
  EMPLOYEE_FULL_NAME: ZENDESK_FIELD_ID_EMPLOYEE_FULL_NAME || 360039669752, // Customer's Employee's Full Name
  LIFECYCLE_RESIGNATION_REASON: ZENDESK_FIELD_ID_LIFECYCLE_RESIGNATION_REASON || 6557995261581, // Lifecycle:: Resignation reason
  LIFECYCLE_TERMINATION_BY_CUSTOMER_REASON:
    ZENDESK_FIELD_ID_LIFECYCLE_TERMINATION_BY_CUSTOMER_REASON || 6558669171853, // Lifecycle:: Termination by Customer reason
  LIFECYCLE_END_OF_FIXED_TERM_CONTRACT_CUSTOMER_REASON:
    ZENDESK_FIELD_ID_LIFECYCLE_END_OF_FIXED_TERM_CONTRACT_CUSTOMER_REASON || 8062403334669, // Lifecycle:: End of Fixed Term Contract customer reason
  EMPLOYEE_LINK: ZENDESK_FIELD_ID_EMPLOYEE_LINK || 5221734343181, // Employee Link
  EMPLOYEE_TICKET_TOPIC: ZENDESK_FIELD_ID_EMPLOYEE_TICKET_TOPIC || 360039652711, // Employee Ticket Topic
  LIFECYCLE_TICKET_TOPIC: ZENDESK_FIELD_ID_LIFECYCLE_TICKET_TOPIC || 6029623567757, // Lifecycle Ticket Topic
  LIFECYCLE_OFFBOARDING_RESIGNATION:
    ZENDESK_FIELD_ID_LIFECYCLE_OFFBOARDING_RESIGNATION || 6556786819469, // Lifecycle::Offboarding & Conversions::Resignation
  TASKS_TO_PERFORM_BY_LIFECYCLE: ZENDESK_FIELD_ID_TASKS_TO_PERFORM_BY_LIFECYCLE || 360051309091, // Number of tasks to perform by Lifecycle
  LIFECYCLE_OFFBOARDING_CONVERSIONS_TERMINATION:
    ZENDESK_FIELD_ID_LIFECYCLE_OFFBOARDING_CONVERSIONS_TERMINATION || 6556753618189, // Lifecycle::Offboarding & Conversions::Termination
  LIFECYCLE_CONTRACTS_MANAGEMENT_CONTRACT_PROBATION:
    ZENDESK_FIELD_ID_LIFECYCLE_CONTRACTS_MANAGEMENT_CONTRACT_PROBATION || 360051242612, // Lifecycle::Contracts Management::Contract Probation
  LIFECYCLE_CONTRACTS_MANAGEMENT_CONTRACT_RENEWAL:
    ZENDESK_FIELD_ID_LIFECYCLE_CONTRACTS_MANAGEMENT_CONTRACT_RENEWAL || 360051242772, // Lifecycle::Contracts Management:: Contract Renewal
  MOBILITY_TICKET_TOPIC: ZENDESK_FIELD_ID_MOBILITY_TICKET_TOPIC || 8380358258573, // Mobility Ticket Topic
  RESIGNATION_ACCEPTANCE_CUSTOMER_REP_NAME:
    ZENDESK_FIELD_ID_RESIGNATION_ACCEPTANCE_CUSTOMER_REP_NAME || 9982161250957, // Resignation Acceptance - Customer Rep Name
  RESIGNATION_ACCEPTANCE_CUSTOMER_EMAIL:
    ZENDESK_FIELD_ID_RESIGNATION_ACCEPTANCE_CUSTOMER_EMAIL || 9982123899661, // Resignation Acceptance - Customer Email
  PROBATION_PERIOD_END_DATE: ZENDESK_FIELD_ID_PROBATION_PERIOD_END_DATE || 12806180737037,
};

const timeoffModals = {
  timeoffPublicHoliday: 'timeoffPublicHoliday',
  timeoffAnnualLeave: 'timeoffAnnualLeave',
  timeoffSpecialLeave: 'timeoffSpecialLeave',
  timeoffFamilyLeave: 'timeoffFamilyLeave',
  timeoffSickLeave: 'timeoffSickLeave',
  timeoffTimesheetsAndWorkingHours: 'timeoffTimesheetsAndWorkingHours',
};

const employeeMobilityModals = {
  travelLetter: 'travelLetter',
  remoteWorking: 'remoteWorking',
};

const employerMobilityModals = {
  mobilityFollowUp: 'mobilityFollowUp',
};

export const employerRequestsModals = {
  probationLetter: 'probationLetter',
  contractAmendment: 'contractAmendment',
  employeeTermination: 'employeeTermination',
  contractExtension: 'contractExtension',
  contractRenewal: 'contractRenewal',
  probationExtension: 'probationExtension',
  mobilityFollowUp: 'mobilityFollowUp',
  ...timeoffModals,
};

export const mobilityModalsToRequestType = {
  [employeeMobilityModals.travelLetter]: requestTypes.TRAVEL,
  [employeeMobilityModals.remoteWorking]: requestTypes.REMOTE_WORK,
};

export const generalMobilityModalsToRequestType = {
  [employerMobilityModals.mobilityFollowUp]: requestTypes.MOBILITY_FOLLOW_UP,
};

export const timeoffModalsToRequestType = {
  [timeoffModals.timeoffPublicHoliday]: requestTypes.PUBLIC_HOLIDAY,
  [timeoffModals.timeoffAnnualLeave]: requestTypes.ANNUAL_LEAVE,
  [timeoffModals.timeoffSpecialLeave]: requestTypes.SPECIAL_LEAVE,
  [timeoffModals.timeoffFamilyLeave]: requestTypes.FAMILY_LEAVE,
  [timeoffModals.timeoffSickLeave]: requestTypes.SICK_LEAVE,
  [timeoffModals.timeoffTimesheetsAndWorkingHours]: requestTypes.TIMESHEETS,
};

export const employerModalsToRequestType = {
  [employerRequestsModals.probationLetter]: requestTypes.PROBATION_PASSED_LETTER,
  [employerRequestsModals.contractAmendment]: requestTypes.AMENDMENT,
  [employerRequestsModals.employeeTermination]: requestTypes.TERMINATION,
  [employerRequestsModals.contractExtension]: requestTypes.EXTENSION,
  [employerRequestsModals.contractRenewal]: requestTypes.RENEWAL,
  [employerRequestsModals.probationExtension]: requestTypes.PROBATION_EXTENSION,
  ...timeoffModalsToRequestType,
  ...generalMobilityModalsToRequestType,
};

export const requestTypesToEmployerModal = invert(employerModalsToRequestType);

export const employeeRequestsModals = {
  employeeResignation: 'employeeResignation',
  employmentVerificationLetter: 'employmentVerificationLetter',
  ...timeoffModals,
  ...employeeMobilityModals,
};

export const employeeModalsToRequestType = {
  [employeeRequestsModals.employeeResignation]: requestTypes.RESIGNATION,
  [employeeRequestsModals.employmentVerificationLetter]: requestTypes.VERIFICATION_LETTER,
  ...timeoffModalsToRequestType,
  ...mobilityModalsToRequestType,
};

export const employerRequestTypes = {
  [requestTypes.TERMINATION]: {
    label: requestTypesLabels[requestTypes.TERMINATION],
    description: 'Start the process here before speaking to your employee.',
  },
  [requestTypes.CANCELATION]: {
    label: requestTypesLabels[requestTypes.CANCELATION],
    description: 'Cancels the hiring of an employee before their start date.',
  },
  [requestTypes.AMENDMENT]: {
    label: requestTypesLabels[requestTypes.AMENDMENT],
    description: 'Changes employment terms like salary, job title, benefits, and more.',
  },
  [requestTypes.EXTENSION]: {
    label: requestTypesLabels[requestTypes.EXTENSION],
    description: 'Extends an existing employment agreement for a period of time.',
  },
  [requestTypes.RENEWAL]: {
    label: requestTypesLabels[requestTypes.RENEWAL],
    description: 'Starts a new employment agreement after an existing one ends.',
  },
  [requestTypes.PROBATION_PASSED_LETTER]: {
    label: requestTypesLabels[requestTypes.PROBATION_PASSED_LETTER],
    description: 'Confirms an employee successfully completed their probation.',
  },
  [requestTypes.PROBATION_EXTENSION]: {
    label: requestTypesLabels[requestTypes.PROBATION_EXTENSION],
    description: 'Extends an existing probation for a period of time.',
  },
  [requestTypes.PUBLIC_HOLIDAY]: {
    label: requestTypesLabels[requestTypes.PUBLIC_HOLIDAY],
    description:
      'Also called bank holidays, these are national, regional, or local holidays when most businesses are closed.',
  },
  [requestTypes.ANNUAL_LEAVE]: {
    label: requestTypesLabels[requestTypes.ANNUAL_LEAVE],
    description:
      'Sometimes called vacation or holiday. This is the paid time off employees are entitled to every year.',
  },
  [requestTypes.SPECIAL_LEAVE]: {
    label: requestTypesLabels[requestTypes.SPECIAL_LEAVE],
    description:
      'Time off used for specific events like military duty, compassionate leave, jury duty, wedding leave, and more.',
  },
  [requestTypes.SICK_LEAVE]: {
    label: requestTypesLabels[requestTypes.SICK_LEAVE],
    description: 'Leave for illness, disability, or occupational injury.',
  },
  [requestTypes.FAMILY_LEAVE]: {
    label: requestTypesLabels[requestTypes.FAMILY_LEAVE],
    description: 'Time off to care for a dependant.',
  },
  [requestTypes.TIMESHEETS]: {
    label: requestTypesLabels[requestTypes.TIMESHEETS],
    description: 'Time spent at work and required documents to log working hours.',
  },
  [requestTypes.MOBILITY_FOLLOW_UP]: {
    label: requestTypesLabels[requestTypes.MOBILITY_FOLLOW_UP],
    description: 'Questions related to ongoing immigration assistance or pre-hire checks.',
  },
};

export const employeeRequestTypes = {
  [requestTypes.RESIGNATION]: {
    label: requestTypesLabels[requestTypes.RESIGNATION],
    description: 'Start your resignation process.',
  },
  [requestTypes.VERIFICATION_LETTER]: {
    label: requestTypesLabels[requestTypes.VERIFICATION_LETTER],
    description:
      'Request letters for verifying your employment, opening a bank account, asking for a loan, etc.',
  },
  [requestTypes.PUBLIC_HOLIDAY]: {
    label: requestTypesLabels[requestTypes.PUBLIC_HOLIDAY],
    description:
      'Also called bank holidays, these are national, regional, or local holidays when most businesses are closed.',
  },
  [requestTypes.ANNUAL_LEAVE]: {
    label: requestTypesLabels[requestTypes.ANNUAL_LEAVE],
    description:
      'Sometimes called vacation or holiday. This is the paid time off employees are entitled to every year.',
  },
  [requestTypes.SPECIAL_LEAVE]: {
    label: requestTypesLabels[requestTypes.SPECIAL_LEAVE],
    description:
      'Time off used for specific events like military duty, compassionate leave, jury duty, wedding leave, and more.',
  },
  [requestTypes.SICK_LEAVE]: {
    label: requestTypesLabels[requestTypes.SICK_LEAVE],
    description: 'Leave for illness, disability, or occupational injury.',
  },
  [requestTypes.FAMILY_LEAVE]: {
    label: requestTypesLabels[requestTypes.FAMILY_LEAVE],
    description: 'Time off to care for a dependant.',
  },
  [requestTypes.TIMESHEETS]: {
    label: requestTypesLabels[requestTypes.TIMESHEETS],
    description: 'Time spent at work and required documents to log working hours.',
  },
  [requestTypes.TRAVEL]: {
    label: requestTypesLabels[requestTypes.TRAVEL],
    description: 'Request travel letter for business related travel',
  },
  ...(isFlagActive('MOBILITY_REMOTE_WORK_REQUEST_ENABLED')
    ? {
        [requestTypes.REMOTE_WORK]: {
          label: requestTypesLabels[requestTypes.REMOTE_WORK],
          description: 'Obtaining permission to work remotely from a foreign country.',
        },
      }
    : {}),
};

export const requestUserTypes = {
  COMPANY: 'cs_type_company',
  EMPLOYEE: 'cs_type_employee',
};

// Request types that can't be requested again while a pending request exists for the same employee
export const pendingAmendmentRequestTypes = [
  requestTypes.AMENDMENT,
  requestTypes.EXTENSION,
  requestTypes.RENEWAL,
];

export const employerSingleRequestTypes = [
  requestTypes.TERMINATION,
  ...pendingAmendmentRequestTypes,
];

export const employeeSingleRequestTypes = [requestTypes.RESIGNATION];

export const isEmployeeInProbationPeriodTags = {
  YES: 'lifecycle_offboarding_probation_period_yes',
  NO: 'lifecycle_offboarding_probation_period_no',
};

export const automationTypes = {
  SALARY_INCREASE: 'salary_increase',
  PROMOTION: 'promotion',
};

export const automationTypeDescription = {
  [automationTypes.SALARY_INCREASE]:
    'This employment agreement amendment was created by an automated salary increase.',
  [automationTypes.PROMOTION]:
    'This employment agreement amendment was created by an automated promotion.',
};
