import { IconV2OutlineSettings } from '@remote-com/norma/icons/IconV2OutlineSettings';
import type { ComponentProps } from 'react';

import { PageHeaderQuickLinks } from '@/src/components/Ui/Layout/PageHeader/PageHeaderQuickLinks';

type MenuItems = ComponentProps<typeof PageHeaderQuickLinks>['items'];

export type PageHeaderSettingsProps = {
  items?: MenuItems;
};

export const PageHeaderSettings = ({ items }: PageHeaderSettingsProps) => {
  return (
    <PageHeaderQuickLinks
      data-appcues-page-header-settings
      icon={IconV2OutlineSettings}
      items={items}
      label="Settings"
    />
  );
};
