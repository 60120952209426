import { FeedbackMessage, ButtonInlineStyles, Text } from '@remote-com/norma';
import { IconLock } from '@remote-com/norma/icons/IconLock';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import ContentContainer from '@/src/components/Ui/ContentContainer';
import { PageHeader } from '@/src/components/Ui/Layout/PageHeader';
import { useUserContext } from '@/src/components/UserProvider';
import {
  COMPANY_COMPLETE_ONBOARDING,
  START_TRIAL_PRODUCT_PLAN_ROUTE,
} from '@/src/constants/routes';
import { companyStatus } from '@/src/domains/companies/constants';
import { useIsFeatureFlagEnabled } from '@/src/domains/feature-flag/context';
import { BookACallButton } from '@/src/domains/marketing/bookit/BookACallButton';
import { nodeNames } from '@/src/domains/marketing/bookit/constants';
import { productEmploymentTypes } from '@/src/domains/pricing/constants';
import {
  useProductUsageAndAccessContext,
  PRODUCT_ACCESS_STATE,
} from '@/src/domains/productUsageAndAccess';

import { PaywallPage } from './PaywallPage';
import type { PageHeaderProps, PaywallPageProps, BaseContentProps } from './PaywallPage';
import { PaywallPageSkeleton } from './PaywallPageSkeleton';

const StyledBookACallButton = styled(BookACallButton).attrs({
  $variant: 'default',
})`
  ${ButtonInlineStyles}
`;

const SIGN_UP_PART_TWO_CONTENT: Omit<
  PaywallPageProps,
  'title' | 'description' | 'img' | 'pageHeader'
> = {
  cta: {
    text: 'Finish setting up account',
    href: COMPANY_COMPLETE_ONBOARDING,
  },
  pill: {
    icon: IconLock,
    tone: 'warning' as const,
    text: 'Finish account setup to access',
  },
};

const FREE_TRIAL_CONTENT: Omit<PaywallPageProps, 'title' | 'description' | 'img' | 'pageHeader'> = {
  cta: {
    text: 'Get started with Recruit',
    href: START_TRIAL_PRODUCT_PLAN_ROUTE.replace(
      '[productEmploymentType]',
      productEmploymentTypes.RECRUIT
    ),
  },
  pill: { text: 'Subscribe to access', icon: IconLock, tone: 'warning' },
  Footer: () => {
    const { asPath } = useRouter();
    return (
      <>
        <Text variant="sm" color="grey.600" as="p">
          7-day free trial, no credit card needed.
        </Text>
        <Text variant="sm" color="grey.600">
          Want more details?{' '}
          <StyledBookACallButton
            variant="raw"
            $tone="primary"
            url="https://employ.remote.com"
            instanceConfig={{
              nodeName: nodeNames.TALK_TO_SALES,
              redirectURL: `https://employ.remote.com${asPath}`,
            }}
          >
            Book a demo
          </StyledBookACallButton>
        </Text>
      </>
    );
  },
};

type Props = {
  /** React elements to render when access is granted */
  children: ReactNode;

  /**
   * Configuration for the scenario where the user has not completed the second part of the signup process.
   */
  signUpPartTwo: BaseContentProps;

  /**
   * Configuration for the scenario where the user has not yet started a free trial.
   * */
  freeTrial: BaseContentProps;

  /**
   * Optional configuration for a custom paywall page with its own visibility condition.
   */
  customPaywall?: {
    visibilityCondition: () => boolean;
  } & Omit<PaywallPageProps, 'pageHeader' | 'Callout'>;

  /** Page header and site title. */
  pageHeader: PageHeaderProps;

  /** Loading state for the page */
  isLoading?: boolean;
};

function InvalidRoleFeedback() {
  return (
    <FeedbackMessage variant="warning">
      Based on your role, you don't have permissions to start the free trial of Recruit. Please
      reach out to your account admin to subscribe.
    </FeedbackMessage>
  );
}

/**
 * A component that implements access control for Recruit plan features based on various conditions.
 * Renders paywall pages for:
 * - Incomplete sign up (when company status is pending)
 * - Users who have never subscribed to the Recruit plan
 * - Custom paywall conditions
 *
 * @example
 * ```tsx
 * <PaywallPageRecruit
 *   signUpPartTwo={{
 *     title: "Complete Your Setup",
 *     description: "...",
 *     img: "..."
 *   }}
 *   freeTrial={{
 *     title: "Start Your Free Trial",
 *     description: "...",
 *     img: "..."
 *   }}
 *   pageHeader={{ title: "Recruit Features" }}
 * >
 *   <ProtectedFeature />
 * </PaywallPageRecruit>
 * ```
 */
export function PaywallPageRecruit({
  children,
  signUpPartTwo,
  freeTrial,
  customPaywall,
  isLoading,
  pageHeader,
}: Props) {
  const isRecruitPackageEnabled = useIsFeatureFlagEnabled('recruit_package');
  const { user } = useUserContext();

  const {
    query: { isLoading: isLoadingProductAccessState, error },
    getProductAndAccessState,
    canUserCreatePricingPlan,
  } = useProductUsageAndAccessContext();

  const { productAccessState } = getProductAndAccessState({
    productEmploymentType: productEmploymentTypes.RECRUIT,
  });

  const isLoadingData = !!isLoading || isLoadingProductAccessState;

  if (isLoadingData || error) {
    return <PaywallPageSkeleton pageHeader={pageHeader} isLoading={isLoadingData} error={error} />;
  }

  if (!isRecruitPackageEnabled) {
    return <>{children}</>;
  }

  if (user?.companyStatus === companyStatus.PENDING) {
    return (
      <PaywallPage
        title={signUpPartTwo.title}
        description={signUpPartTwo.description}
        cta={SIGN_UP_PART_TWO_CONTENT.cta}
        pill={SIGN_UP_PART_TWO_CONTENT.pill}
        img={signUpPartTwo.img}
        Footer={signUpPartTwo.Footer}
        pageHeader={pageHeader}
      />
    );
  }

  if (productAccessState === PRODUCT_ACCESS_STATE.NEVER_SUBSCRIBED) {
    return (
      <PaywallPage
        title={freeTrial.title}
        description={freeTrial.description}
        cta={{ ...FREE_TRIAL_CONTENT.cta, isHidden: !canUserCreatePricingPlan }}
        pill={FREE_TRIAL_CONTENT.pill}
        img={freeTrial.img}
        Footer={FREE_TRIAL_CONTENT.Footer}
        Callout={!canUserCreatePricingPlan ? InvalidRoleFeedback : undefined}
        pageHeader={pageHeader}
      />
    );
  }

  if (customPaywall && customPaywall?.visibilityCondition() === true) {
    return (
      <PaywallPage
        title={customPaywall.title}
        description={customPaywall.description}
        cta={customPaywall.cta}
        pill={customPaywall.pill}
        img={customPaywall.img}
        Footer={customPaywall.Footer}
        pageHeader={pageHeader}
      />
    );
  }

  return (
    <ContentContainer data-dd-privacy="allow">
      {pageHeader && <PageHeader {...pageHeader} siteTitle={pageHeader.siteTitle || null} />}
      {children}
    </ContentContainer>
  );
}
