import type { ElementType, MouseEventHandler, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';
import type { DefaultTheme } from 'styled-components';
import type { SpaceProps } from 'styled-system';

import {
  PillDefault,
  PillDefaultIcon,
  PillDefaultText,
  PillSubtle,
  PillSubtleSignal,
  PillSubtleText,
} from './Pill.styled';

const PILL_TONES_V1 = [
  'bayoux',
  'chambray',
  'deYork',
  'info',
  'irisBlue',
  'primary',
  'radiance',
  'turquoise',
] as const;

export const PILL_TONES_V2 = [
  'blue',
  'blueLight',
  'cyan',
  'error',
  'fuchsia',
  'neutralDark',
  'neutralLight',
  'pink',
  'purple',
  'success',
  'warning',
] as const;

export type PillToneV1 = (typeof PILL_TONES_V1)[number];

export type PillToneV2 = (typeof PILL_TONES_V2)[number];

export type PillTone = PillToneV1 | PillToneV2;

interface SharedPillProps extends SpaceProps<DefaultTheme, number | 'auto'> {
  /**
   * The pill label text.
   * */
  children?: ReactNode;
  /**
   * Fires when a user clicks on the pill.
   *
   * **Avoid:** Pills are not meant to have actions attached to them.
   * */
  onClick?: MouseEventHandler<HTMLDivElement>;
  /**
   * Changes the appearance of a pill. Can be used to convey a specific meaning.
   * */
  tone?: PillTone;
  /**
   * Useful to make pills focusable
   */
  tabIndex?: number;
  /**
   * Screen reader only text, required when children is not provided
   * */
  'aria-label'?: string;
}

type DefaultPillProps = {
  /**
   * Displays an icon next to the pill text. Can only be used in combination with `default` type.
   * */
  Icon?: ElementType;
  type?: 'default';
} & SharedPillProps;

export type SubtlePillProps = {
  type?: 'subtle';
} & SharedPillProps;

export type PillProps = DefaultPillProps | SubtlePillProps;

const v1ToneCompatibilityMap: { [key in PillToneV1]: PillToneV2 } = {
  bayoux: 'neutralLight',
  chambray: 'purple',
  deYork: 'success',
  info: 'blue',
  irisBlue: 'blue',
  primary: 'blue',
  radiance: 'blueLight',
  turquoise: 'cyan',
};

function isV1Tone(tone: PillTone): tone is PillToneV1 {
  return Object.keys(v1ToneCompatibilityMap).includes(tone);
}

function getV2CompatibleTone(tone: PillTone): PillToneV2 {
  if (isV1Tone(tone)) {
    return v1ToneCompatibilityMap[tone];
  }

  return tone;
}

export const Pill = forwardRef((props: PillProps, ref: Ref<HTMLDivElement>) => {
  const {
    children,
    'aria-label': ariaLabel,
    onClick,
    tone = 'blue',
    type = 'default',
    ...rest
  } = props;
  const v2CompatibleTone = getV2CompatibleTone(tone);

  if (type === 'subtle') {
    return (
      <PillSubtle ref={ref} onClick={onClick} {...rest}>
        <PillSubtleSignal $tone={v2CompatibleTone} />
        <PillSubtleText>{children}</PillSubtleText>
      </PillSubtle>
    );
  }

  const { Icon, ...defaultRest } = rest as Pick<DefaultPillProps, 'Icon'>;

  return (
    <PillDefault
      ref={ref}
      $tone={v2CompatibleTone}
      onClick={onClick}
      {...defaultRest}
      aria-label={ariaLabel}
    >
      {!!Icon && <PillDefaultIcon as={Icon} />}
      {children && <PillDefaultText>{children}</PillDefaultText>}
    </PillDefault>
  );
});

export const PILL_TONES: PillTone[] = [...PILL_TONES_V1, ...PILL_TONES_V2];
