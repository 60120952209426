import { Box, Text, sharedTransition } from '@remote-com/norma';
import isNaN from 'lodash/isNaN';
import styled, { css, keyframes } from 'styled-components';

function getColorForProgress(progressPercent, colors) {
  if (progressPercent >= 100) {
    return colors.green[600];
  }
  if (progressPercent >= 40) {
    return colors.yellow[400];
  }
  if (progressPercent >= 1) {
    return colors.orange[400];
  }

  return colors.red[400];
}

// Intermediate state animation
const ProgressBarBackAndForth = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(400%);
  }
`;

export const ProgressBarTrack = styled(Box)`
  width: 100%;
  height: ${({ $height }) => $height};
  border-radius: ${({ $height }) => $height};
  background-color: ${({ theme }) => theme.colors.grey[200]};
`;

export const ProgressBarValue = styled(Box)`
  border-radius: inherit;
  height: 100%;
  min-width: ${({ $minWidth }) => $minWidth};
  ${({ $percentValue }) =>
    !isNaN($percentValue) &&
    css`
      width: ${$percentValue}%;
    `};
  ${sharedTransition('width')};

  ${({ theme, $variant }) => {
    switch ($variant) {
      case 'auto':
        return css`
          /* Calculate progress hue from 0 to 81 */
          background-color: ${theme.colors.brand[900]};
          ${({ $percentValue }) =>
            !isNaN($percentValue) &&
            css`
              background-color: ${getColorForProgress($percentValue, theme.colors)};
            `};
        `;
      case 'primary':
        return css`
          background-color: var(--colors-primary);
        `;
      case 'secondary':
        return css`
          background-color: ${theme.colors.cyan[500]};
        `;
      default:
        return null;
    }
  }}

  ${({ $isIndeterminate }) =>
    $isIndeterminate &&
    css`
      width: 20%;
      animation: ${ProgressBarBackAndForth} 1s alternate-reverse infinite;
    `}
`;

export const ProgressLabel = styled(Text).attrs({
  forwardedAs: 'label',
  variant: 'smSemiBold',
  color: 'bayoux',
  ml: 4,
})``;
