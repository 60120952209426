import type { GetResponse } from '@remote-com/data-layer';
import type { BadgeType } from '@remote-com/norma';
import type { JSONContent } from '@tiptap/react';
import type { ValueOf } from 'type-fest';

import type { EmploymentType } from '@/src/api/config/api.types';
import type {
  ContractDocumentSignatory,
  ContractDocumentStatusValue,
} from '@/src/api/config/employ/contractDocument.types';
import type { ContractTemplateTypesValue } from '@/src/api/config/employ/contractTemplates.types';
import type { EmployeeEmployment } from '@/src/api/config/employ/employeeEmployment.types';
import type { Company, Country, Currency } from '@/src/api/config/employ/shared.types';
import type { UserAccount } from '@/src/api/config/employ/userAccount.types';
import type { UserAccountResponse } from '@/src/components/UserProvider';
import {
  baseEditorExtensions,
  getViewerEditorExtensions,
  getGlobalEditorExtensions,
} from '@/src/domains/contracts/ContractsEditor/config';
import { CONTRACT_EDITOR_TYPE, EDITOR_FEATURES } from '@/src/domains/contracts/shared/constants';
import {
  CONTRACT_TEMPLATE_TYPE_LABELS,
  CONTRACT_TEMPLATE_TYPES,
} from '@/src/domains/contractTemplates/contractTemplates/constants';
import { employmentType } from '@/src/domains/employment/constants';
import { getActiveContractWithFallback } from '@/src/domains/employment/helpers';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { isEmployer, userCan } from '@/src/domains/registration/auth/helpers';
import { friendlyLabel } from '@/src/helpers/general';
import type { $TSFixMe } from '@/types';

import { CONTRACT_WORK_SCHEDULES_LABELS, type CONTRACT_WORK_SCHEDULES } from './constants';

type ContractProperties = GetResponse<'/api/v1/employer/contract-documents/[slug]'>['data'];

export function templateTypeFromEmploymentType(
  type: EmploymentType
): ContractTemplateTypesValue | null {
  switch (type) {
    case employmentType.FULL_TIME:
      return CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT;
    case employmentType.CONTRACTOR:
      return CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT;
    default:
      return null;
  }
}

type RemoteTemplateType = {
  type: ContractTemplateTypesValue;
  country?: Country;
  countries?: Country[];
  name?: string;
  company?: Company;
};

export const formatRemoteTemplateName = ({
  template,
  countryName,
}: {
  template: RemoteTemplateType;
  countryName: string;
}) => {
  const templateType = CONTRACT_TEMPLATE_TYPE_LABELS?.[template?.type]
    ? `${CONTRACT_TEMPLATE_TYPE_LABELS[template.type]}`
    : '';

  const countryData =
    template?.country ??
    template?.countries?.find((countryItem) => countryItem.name === countryName);

  const countryCode = countryData ? ` (${countryData?.code})` : '';

  const templateName = template?.name ? `${templateType ? ': ' : ''}${template.name}` : '';

  const companyName = template?.company?.name
    ? `${template.company.name}${templateName ? ': ' : ''}`
    : '';

  return `${companyName}${templateType}${countryCode}${templateName}`;
};

export const templateTypeFromJuroType = (type: string) => {
  switch (type) {
    case 'ea':
      return CONTRACT_TEMPLATE_TYPE_LABELS?.[CONTRACT_TEMPLATE_TYPES.EMPLOYMENT_AGREEMENT];
    default:
      return type;
  }
};

export const getErrorsFromResponse = (response: $TSFixMe) => {
  if (!response?.data?.errors && !response?.data?.message) {
    return '';
  }

  const allErrors = Object.values(response?.data?.errors || {}).map(
    (error: $TSFixMe) => error[0].message || error[0]
  );

  return response?.data?.message || allErrors.join('; ');
};

/* The employee currency is the currency of the employee's compensation when it exists (SSOT of non-local currencies);
if not available, it should be the currency of the employee's contract country */
export const getEmployeeCurrency = ({
  employment,
  currencies,
  isAdminRole,
}: {
  employment: EmployeeEmployment;
  currencies: Currency[];
  isAdminRole: boolean;
}) => {
  const employmentContract = employment?.contract || getActiveContractWithFallback(employment);

  const compensationCurrency = isAdminRole
    ? currencies.find(
        (el) => employmentContract?.contractDetails?.details?.compensationCurrencyCode === el.code
      )
    : employment?.grossSalary?.currency;

  const contractCountryCurrency = employmentContract?.country?.currency;

  return { compensationCurrency, contractCountryCurrency };
};

export const buildEditorState = ({
  contentForSaving,
  isBilingual,
}: {
  contentForSaving: ContentForSaving;
  isBilingual: boolean;
}) => {
  const { primaryContent, secondaryContent } = contentForSaving || {};

  // Content can be undefined when a template is created, toggled as bilingual, not edited, and published blank.
  if (!primaryContent && !secondaryContent) {
    return [];
  }

  const editorState = {} as {
    // eslint-disable-next-line camelcase
    primary_content?: JSONContent;
    // eslint-disable-next-line camelcase
    translated_content?: JSONContent | null;
  };

  if (primaryContent) {
    editorState.primary_content = primaryContent;
  }

  if (secondaryContent) {
    editorState.translated_content = secondaryContent;
  }

  // `translated_content` needs to always be `null` if the document is not bilingual, since Tiger uses it to determine if a document is bilingual. `secondaryContent` won't be `null` when we toggle a document from bilingual to non-bilingual (we set TipTap content to '' but it will still result in a non-empty JSON object), so we need to handle it explicitly.

  if (!isBilingual) {
    editorState.translated_content = null;
  }

  return { editorState };
};

type ContentForSaving = { primaryContent?: JSONContent; secondaryContent?: JSONContent };
export const buildTemplateUpdateValues = ({
  contractTemplateSlug,
  contentForSaving,
  documentSmartFields,
  isBilingual,
}: {
  contractTemplateSlug: string;
  contentForSaving: ContentForSaving;
  documentSmartFields: {
    primaryContentSmartFields: string[] | undefined;
    secondaryContentSmartFields: string[] | undefined;
  };
  isBilingual: boolean;
}) => {
  const smartFieldsSet = new Set(documentSmartFields?.primaryContentSmartFields ?? []);

  if (isBilingual && Array.isArray(documentSmartFields?.secondaryContentSmartFields)) {
    documentSmartFields.secondaryContentSmartFields.forEach(smartFieldsSet.add, smartFieldsSet);
  }

  const deduplicatedSmartFields = Array.from(smartFieldsSet);

  return {
    contractTemplateSlug,
    fields: deduplicatedSmartFields,
    ...buildEditorState({ contentForSaving, isBilingual }),
  };
};

export const buildDocumentUpdateParams = ({
  contentForSaving,
  isBilingual,
  contractSlug,
}: {
  contentForSaving: ContentForSaving;
  isBilingual: boolean;
  contractSlug: string;
}) => {
  return {
    pathParams: { slug: contractSlug },
    bodyParams: {
      ...buildEditorState({ contentForSaving, isBilingual }),
    },
  };
};

export const isRecipientSignatory = (signatory?: ContractDocumentSignatory) =>
  signatory?.type === 'employee';
export const isCompanySignatory = (signatory?: ContractDocumentSignatory) =>
  signatory?.type === 'company';

export const getEditorExtensions = ({
  editorType,
  enabledFeatures,
  signatories = [],
  handleOnClickRecipientSignature,
  handleOnClickCompanySignature,
  showUnavailableSmartfieldsAsPending,
  company = null,
  status,
  recipientSignatureValue,
}: {
  editorType: ValueOf<typeof CONTRACT_EDITOR_TYPE>;
  enabledFeatures: string[];
  signatories: ContractDocumentSignatory[];
  handleOnClickRecipientSignature?: () => void;
  handleOnClickCompanySignature?: () => void;
  showUnavailableSmartfieldsAsPending?: boolean;
  company: Company | null;
  status: ContractDocumentStatusValue | null;
  recipientSignatureValue?: string;
}) => {
  switch (editorType) {
    case CONTRACT_EDITOR_TYPE.TEMPLATE:
    case CONTRACT_EDITOR_TYPE.DOCUMENT:
      return getGlobalEditorExtensions({
        editorType,
        enabledFeatures,
        signatories,
        handleOnClickCompanySignature,
        showUnavailableSmartfieldsAsPending,
        company,
        status,
      });
    case CONTRACT_EDITOR_TYPE.VIEWER:
      return getViewerEditorExtensions({
        enabledFeatures,
        signatories,
        handleOnClickRecipientSignature,
        company,
        recipientSignatureValue,
      });
    default:
      return baseEditorExtensions;
  }
};

export const isPendingEmployeeSignature = (signatories?: ContractDocumentSignatory[]) => {
  const employeeSignatory = signatories?.find(isRecipientSignatory);
  return employeeSignatory && employeeSignatory.status === 'pending';
};

export const isPendingCompanySignature = (signatories: ContractDocumentSignatory[]) => {
  const companySignatory = signatories?.find(isCompanySignatory);
  return companySignatory && companySignatory.status === 'pending';
};

export const isPendingUserSignature = (
  signatories: ContractDocumentSignatory[],
  user?: UserAccountResponse
) =>
  signatories?.some(
    (signatory) => signatory.user.slug === user?.slug && signatory.status === 'pending'
  );

export const getAwaitingSignatoriesBadge = (
  contractTemplateType: ContractTemplateTypesValue,
  signatories: ContractDocumentSignatory[]
): {
  label: string;
  type: BadgeType;
} => {
  const pendingCompanySignature = isPendingCompanySignature(signatories);
  const pendingEmployeeSignature = isPendingEmployeeSignature(signatories);

  if (pendingCompanySignature && pendingEmployeeSignature) {
    return {
      label: `Awaiting both parties' signatures`,
      type: 'pending',
    };
  }

  if (pendingCompanySignature) {
    return {
      label: 'Awaiting company signature',
      type: 'pending',
    };
  }

  if (pendingEmployeeSignature) {
    const isContractorDocument = (
      [
        CONTRACT_TEMPLATE_TYPES.CONTRACTOR_AGREEMENT,
        CONTRACT_TEMPLATE_TYPES.CUSTOM_CONTRACTOR_AGREEMENT_V2,
        CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT,
        CONTRACT_TEMPLATE_TYPES.CONTRACTOR_STATEMENT_OF_WORK,
      ] as ContractTemplateTypesValue[]
    ).includes(contractTemplateType);

    return {
      label: `Awaiting ${isContractorDocument ? 'contractor' : 'employee'} signature`,
      type: 'pending',
    };
  }

  return {
    label: `Awaiting both parties' signatures`,
    type: 'pending',
  };
};

export const canUserManageContractorAgreements = (user?: UserAccount) =>
  user &&
  userCan('create', Resources.employer.people_contracts, user) &&
  userCan('update', Resources.employer.people_contracts, user);

// CM-1113: Temporary fix to prevent showing `is_unavailable` smartfields as
// errors: for contractor services agreements some smartfields are only filled by
// the contractor when signing, so the employer will not be able to do anything
// about these smartfields. This is a temporary change and will be removed once
// BE is configuring such fields differently in CM-1161.
//
// When cleaning up, look for `unavailableAsPending` as well.
export const areUnavailableSmartfieldsPending = (
  contractProperties: ContractProperties,
  user?: UserAccount
) =>
  contractProperties.type === CONTRACT_TEMPLATE_TYPES.CONTRACTOR_SERVICES_AGREEMENT &&
  isEmployer(user);

export const getWorkScheduleDescription = (
  workSchedule?: ValueOf<typeof CONTRACT_WORK_SCHEDULES>
) => (workSchedule && CONTRACT_WORK_SCHEDULES_LABELS[workSchedule]) || friendlyLabel(workSchedule);

export function isDocumentEditEnabled(enabledFeatures: Record<string, boolean>) {
  return (
    enabledFeatures[EDITOR_FEATURES.EDITING_CONTENT] ||
    enabledFeatures[EDITOR_FEATURES.EDITING_MANUALLY_ENTERED_FIELDS]
  );
}
