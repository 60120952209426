import type { ReportsApi } from '@/src/api/config/employ/reports.types';
import { customerDataScopeTypes } from '@/src/domains/employment/constants';
import { SCOPED_NAMES } from '@/src/domains/reports/constants';
import type {
  ScopedName,
  ScopedNames,
  ReportList,
  ReportWidget,
} from '@/src/domains/reports/types';

type GetScopedNameParams = {
  slug: string;
  scopes: string[];
  type?: keyof ScopedNames;
};

export const getScopedName = ({ slug, scopes, type = 'reports' }: GetScopedNameParams) => {
  const section = SCOPED_NAMES[type];
  const scopedNames = (section as Record<string, ScopedName>)[slug];

  if (!scopedNames) {
    return null;
  }

  const hasOverrideScope = scopes.some((scope): scope is keyof ScopedName => scope in scopedNames);
  return hasOverrideScope
    ? scopedNames[customerDataScopeTypes.ENTIRE_COMPANY]
    : scopedNames.default;
};

const getScopedWidgets = (widgets: ReportWidget[] = [], scopes: string[]): ReportWidget[] => {
  return (
    widgets?.map((widget) => ({
      ...widget,
      name:
        getScopedName({
          slug: widget.slug,
          scopes,
          type: 'widgets',
        }) || widget.name,
    })) || []
  );
};

export const useScopedReportList = (
  reportList: ReportList[],
  scopes: string[],
  reports: ReportsApi.ReportsData['reports']
): ReportList[] => {
  if (!scopes.length) {
    return [];
  }

  return reportList.map((category) => {
    const filteredReportList = category.reportTemplates.filter((report) => {
      if (!reports?.length) return false;
      return reports.some((visibleReport) => {
        return visibleReport.slug === report.slug;
      });
    });

    return {
      ...category,
      reports: filteredReportList.map((report) => ({
        ...report,
        name:
          getScopedName({
            slug: report.slug,
            scopes,
          }) || report.name,
        widgets: getScopedWidgets(report.widgets, scopes),
      })),
    };
  });
};
