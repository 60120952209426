import { add } from 'date-fns';

import { resourceTypes } from '@/src/domains/paymentMethods/shared/constants';
import type { ResourceType } from '@/src/domains/paymentMethods/shared/constants';
import { PAYABLE_STATUSES } from '@/src/domains/payments/employer/OutstandingPaymentDetails/constants';
import { isContractorReceiptPaymentType } from '@/src/domains/payments/shared/helpers';
import { formatDateRange } from '@/src/helpers/date';

import {
  contractorPayOutMethods,
  type ContractorPayOutMethod,
  type PaymentStatus,
} from '../constants';
import type { ContractorOutstandingPayment } from '../types';

type DaysRange = [number, number];

const payInEstimatedProcessingTimes: Record<ResourceType, DaysRange> = {
  [resourceTypes.STRIPE_CARD]: [1, 2],
  [resourceTypes.STRIPE_SEPA_DIRECT_DEBIT]: [1, 3],
  [resourceTypes.STRIPE_ACH_DIRECT_DEBIT]: [3, 5],
  [resourceTypes.STRIPE_BACS_DIRECT_DEBIT]: [3, 5],
  [resourceTypes.WIRE_TRANSFER]: [0, 3],
  [resourceTypes.STRIPE_BANK_TRANSFER]: [0, 3],
};

const payOutEstimatedProcessingTimes: Record<ContractorPayOutMethod, DaysRange> = {
  [contractorPayOutMethods.WISE]: [0, 3],
  [contractorPayOutMethods.NIUM]: [0, 3],
  [contractorPayOutMethods.STRIPE_CONNECT]: [0, 0],
};

const daysRangeToString = (range: DaysRange): string => `${range[0]} - ${range[1]} days`;

// Returns a new range that is the result of one range happening after another. For example pay-in range + payout range
const concatenateRange = (a: DaysRange, b: DaysRange): DaysRange => [a[0] + b[0], a[1] + b[1]];

// Merges two ranges so the result has the minimum and the maximum of both. Useful for we don't know what range will apply. For example if we don't know the payout method.
const mergeRange = (a: DaysRange, b: DaysRange): DaysRange => [
  Math.min(a[0], b[0]),
  Math.max(a[1], b[1]),
];

/**
 * Returns the estimated days range that a payment takes from the moment the
 * employer initiates the payment.
 */
const paymentEstimatedProcessingTime = ({
  payInResourceType,
  includeContractorPayoutTime,
  contractorPayOutMethod,
}: {
  payInResourceType: ResourceType;

  /**
   * For non-contractor payments, this value should be false.
   *
   * For contractor payments, both true and false make sense, but it's usually
   * more informative to the user to include both the pay in and the pay out
   * times.
   */
  includeContractorPayoutTime: boolean;
  contractorPayOutMethod?: ContractorPayOutMethod;
}): DaysRange => {
  if (!includeContractorPayoutTime) {
    return payInEstimatedProcessingTimes[payInResourceType];
  }

  const payInRange = payInEstimatedProcessingTimes[payInResourceType];

  if (!payInRange) {
    // If for some reason we don't have the payInRange, take worst case scenario
    return concatenateRange(
      payInEstimatedProcessingTimes[resourceTypes.STRIPE_ACH_DIRECT_DEBIT],
      mergeRange(
        payOutEstimatedProcessingTimes[contractorPayOutMethods.WISE],
        payOutEstimatedProcessingTimes[contractorPayOutMethods.STRIPE_CONNECT]
      )
    );
  }

  const payOutRange = contractorPayOutMethod
    ? payOutEstimatedProcessingTimes[contractorPayOutMethod]
    : null;

  if (!payOutRange) {
    // If we don't know the payOut range, we take the best and worst case from both.
    return concatenateRange(
      payInRange,
      mergeRange(
        payOutEstimatedProcessingTimes[contractorPayOutMethods.WISE],
        payOutEstimatedProcessingTimes[contractorPayOutMethods.STRIPE_CONNECT]
      )
    );
  }

  return concatenateRange(payInRange, payOutRange);
};

/**
 * Returns a string in format of "X - X days" representing the total time it
 * might take for contractors to receive money if the outstanding payment is
 * paid in with a given payment method.
 *
 * This is meant to be shown when the employer is choosing between different
 * payment methods to pay a contractor outstanding payment.
 */
export const getContractorTotalPaymentTimesForPaymentMethod = (
  payInResourceType: ResourceType
): string =>
  daysRangeToString(
    paymentEstimatedProcessingTime({ payInResourceType, includeContractorPayoutTime: true })
  );

/**
 * Returns a string in the format of "X - X days" that represents how long might
 * it take for Remote to receive the money if an outstanding payment is paid
 * with that payment method. This is meant to be shown when the employer has to
 * choose between different payment methods to pay an outstanding payment.
 *
 * This is not very useful for contractor outstanding payments, as the more
 * important date is when the contractor receives the money.
 */
export const getPayInTimesForPaymentMethod = (payInResourceType: ResourceType): string =>
  daysRangeToString(
    paymentEstimatedProcessingTime({ payInResourceType, includeContractorPayoutTime: false })
  );

/**
 * Returns start date from which to calculate contractor invoice payout date range
 */
const getContractorPaymentInitiationDate = (outstandingPayment: ContractorOutstandingPayment) => {
  // Start date 'today' for payments that haven't been made yet
  if (
    !outstandingPayment ||
    (PAYABLE_STATUSES as Array<PaymentStatus>).includes(outstandingPayment.status)
  ) {
    return new Date();
  }

  // or return date of latest attempted pay in
  return outstandingPayment.latestPayInAttempt
    ? new Date(outstandingPayment.latestPayInAttempt)
    : null;
};

function getContractorPaymentExpectedPayoutFromDateRange({
  fromDate,
  paymentMethodResourceType,
  separator = 'to',
}: {
  fromDate: Date;
  paymentMethodResourceType: ResourceType;
  /**
   * Character for date range, defaults to 'to'
   */
  separator: string;
}) {
  const range = paymentEstimatedProcessingTime({
    payInResourceType: paymentMethodResourceType,
    includeContractorPayoutTime: true,
  });

  return formatDateRange(
    add(fromDate, { days: range[0] }),
    add(fromDate, { days: range[1] }),
    separator
  );
}

/**
 * Returns string describing date range e.g. 'Mar 3 to Apr 30, 2022' or empty string.
 */
export function getContractorPaymentExpectedPayoutDateRange({
  outstandingPayment,
  paymentMethodResourceType,
  separator = 'to',
}: {
  outstandingPayment: ContractorOutstandingPayment;
  paymentMethodResourceType: ResourceType;
  /**
   * String to add between the two dates. Defaults to 'to'.
   */
  separator: string;
}) {
  const fromDate = getContractorPaymentInitiationDate(outstandingPayment);

  // only apply to contractor payments and show no date range at all if no accurate start date exists
  if (!isContractorReceiptPaymentType(outstandingPayment.type) || !fromDate) return '';

  return getContractorPaymentExpectedPayoutFromDateRange({
    fromDate,
    paymentMethodResourceType,
    separator,
  });
}

/**
 * Returns a string in the format of "May 22 and Jun 1" that represents the
 * estimated date where contractors will receive the money.
 * This only requires the paymentMethodResourceType as it assumes that the
 * payment has just been initiated. This is useful if for some reason we don't
 * have the outstanding payment object, but know it has just been paid. For
 * example in the payment success screen.
 */
export function getContractorSuccessExpectedPayoutDateRange({
  paymentMethodResourceType,
}: {
  paymentMethodResourceType: ResourceType;
}) {
  return getContractorPaymentExpectedPayoutFromDateRange({
    fromDate: new Date(),
    paymentMethodResourceType,
    separator: 'and',
  });
}
