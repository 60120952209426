import { IconChevronDown } from '@remote-com/norma/icons/IconChevronDown';
import { IconChevronUp } from '@remote-com/norma/icons/IconChevronUp';
import React, { forwardRef, useState } from 'react';

import { Box, Stack } from '../../layout';

import { RADIO_CARD_VARIANTS } from './constants';
import type { Direction, RadioCardVariant, Size } from './constants';
import {
  Decorator,
  Description,
  Fieldset,
  IllustrationContainer,
  Input,
  Label,
  Isolate,
  ToggleButton,
  Wrapper,
  ContentWrapper,
  RadioStateInCard,
  RadioCardsRowContainer,
} from './RadioCard.styled';

const disabledProps = {
  readOnly: true,
  'aria-disabled': 'true',
  onChange: (e: React.ChangeEvent) => {
    // aria-disabled does not prevent interaction
    // as the disabled attr
    e.preventDefault();
  },
};

export type RadioCardProps = {
  /** Whether the radio card is focused when the page loads */
  autoFocus?: boolean;
  /** Used to associate the label with the input using the "for" attribute */
  id: string;
  /** Whether the radio card is checked */
  checked?: boolean;
  /** The name of the field */
  name: string;
  /** An optional class name for the Wrapper */
  className?: string;
  /** The optional illustration for the radio card */
  Illustration?: React.ElementType;
  /** @deprecated - use readOnly instead */
  disabled?: boolean;
  /** This props allows inverting the order of the label and description in the radio card */
  direction?: Direction;
  /** The component to use as the radio card field */
  FieldComponent?: React.ElementType;
  /** Whether the radio card input is native */
  isNative?: boolean;
  /** Controls input paddings and text size */
  size?: Size;
  /** When provided, the radio-box is moved as prefix */
  /** @deprecated - use BeforeInput instead */
  suffix?: React.ReactNode;
  /** The variant of the radio card */
  variant?: RadioCardVariant;
  /** optional onChange handler */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** optional onClick handler */
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  /** The radio card's label */
  label?: string | React.ReactNode;
  /** The radio card's description */
  description?: string | React.ReactNode;
  /** The radio card's children */
  children?: React.ReactNode;
  /** Whether the radio card group is readOnly */
  readOnly?: boolean;
  /** The radio card's value */
  value?: string;
  /** Optional prop to add a React Node immediately before the input e.g. to support a Pill */
  beforeInput?: React.ReactNode;
};

type RadioCardType = React.ForwardRefExoticComponent<
  RadioCardProps & React.RefAttributes<HTMLInputElement>
> & {
  IllustrationContainer: typeof IllustrationContainer;
  ContentWrapper: typeof ContentWrapper;
  Decorator: typeof Decorator;
  RadioStateInCard: typeof RadioStateInCard;
  Description: typeof Description;
  Wrapper: typeof Wrapper;
  Input: typeof Input;
};

/**
 * RadioCard
 *
 * Renders a radio card element within a RadioCardGroup
 */
export const RadioCard = forwardRef<HTMLInputElement, RadioCardProps>(function RadioCard(
  {
    children,
    className,
    description,
    disabled,
    direction,
    FieldComponent,
    id,
    Illustration,
    isNative = false,
    label,
    readOnly,
    size = 'sm',
    suffix,
    variant = RADIO_CARD_VARIANTS.CARD,
    beforeInput,
    ...props
  },
  ref
) {
  const isExpandable = variant === RADIO_CARD_VARIANTS.CARD_EXPANDABLE;
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  const isDisabled = !!disabled || !!readOnly;

  const WRONG_PROP_COMBO =
    (suffix && isExpandable) || (size === 'lg' && isExpandable) || (Illustration && isExpandable);

  if (process.env.NODE_ENV === 'development' && WRONG_PROP_COMBO) {
    // eslint-disable-next-line no-console
    console.warn(
      'The RadioCard component does not support the isExpandable prop when used with the suffix, size="lg" or Illustration props. Please refer to the documentation for more info.'
    );
  }

  return (
    <Wrapper
      className={className}
      hasSuffix={!!suffix}
      isDisabled={isDisabled}
      size={size}
      variant={variant}
      $direction={direction}
    >
      <Stack
        direction={!!suffix || variant === 'card-expandable' ? 'row' : 'row-reverse'}
        alignItems="center"
        gap={4}
      >
        <Input
          as={isNative ? 'input' : FieldComponent}
          id={id}
          type="radio"
          ref={ref}
          disabled={isDisabled}
          {...props}
          {...(isDisabled && disabledProps)}
        />

        <Decorator isDisabled={isDisabled} size={size} />
        <RadioStateInCard
          hasSuffix={!!suffix}
          isDisabled={isDisabled}
          isExpanded={isExpanded}
          size={size}
          variant={variant}
        />
        {beforeInput && <Isolate>{beforeInput}</Isolate>}

        <ContentWrapper $direction={direction} isDisabled={isDisabled} variant={variant}>
          <Label htmlFor={id} isDisabled={isDisabled} isExpanded={isExpanded} size={size}>
            {label}
          </Label>
          {children}
          {description && !isExpandable && (
            <Description size={size} variant={variant}>
              {description}
            </Description>
          )}
        </ContentWrapper>
        {Illustration && (
          <IllustrationContainer>
            <Illustration />
          </IllustrationContainer>
        )}
        {suffix && <Isolate>{suffix}</Isolate>}
        {isExpandable && (
          <ToggleButton isExpanded={isExpanded} onClick={toggleIsExpanded}>
            {isExpanded ? <IconChevronUp /> : <IconChevronDown />}
          </ToggleButton>
        )}
      </Stack>
      {description && isExpandable && isExpanded && (
        <Description size={size} variant={variant}>
          {description}
        </Description>
      )}
    </Wrapper>
  );
}) as RadioCardType;

export type RadioCardGroupProps = {
  /** The list of radio card elements */
  children: React.ReactNode;
  /** The description of the radio card group */
  description?: string | React.ReactNode;
  /** @deprecated - use readOnly instead */
  disabled?: boolean;
  /** The direction of the radio cards */
  direction?: Direction;
  /** Whether to display the error message */
  displayErrorMessage?: boolean;
  /** The formik error message */
  error?: string;
  /** The formik error component that gets rendered (i.e. FormFieldError) */
  ErrorComponent?: React.ElementType;
  /** Extra content to render */
  extra?: React.ReactNode;
  /** Whether to hide the label */
  hideLabel?: boolean;
  /** The radio card group's label */
  label?: string | React.ReactNode;
  /** The radio card group's name. Used to show the error message */
  name: string;
  /** The size of the radio cards */
  size?: Size;
  /** The formik touched state */
  touched?: boolean;
  /** Whether the radio card group is readOnly */
  readOnly?: boolean;
};

/**
 * RadioCardGroup
 *
 * Renders a group of RadioCard elements passed as children
 */
export function RadioCardGroup({
  label,
  name,
  children,
  description,
  direction,
  disabled,
  size,
  displayErrorMessage = true,
  ErrorComponent,
  extra,
  hideLabel = false,
  error,
  readOnly,
  touched,
  ...props
}: RadioCardGroupProps) {
  const hasText = !!(label || description);

  const isDirectionRow = direction === 'row';
  const hasError = error && touched;

  return (
    <Fieldset
      {...(typeof label === 'string' ? { 'aria-label': label } : {})}
      {...props}
      $direction={direction}
      $hasError={hasError}
      $ErrorComponent={ErrorComponent}
    >
      {hasText && !hideLabel ? (
        <Box
          mt={isDirectionRow ? 0 : 8}
          mb={isDirectionRow ? 6 : 8}
          flex={isDirectionRow ? '1 0 100%' : undefined}
        >
          {label ? <Label>{label}</Label> : null}
          {description ? <Description>{description}</Description> : null}
        </Box>
      ) : null}
      {isDirectionRow ? (
        <>
          <RadioCardsRowContainer>{children}</RadioCardsRowContainer>
          {displayErrorMessage && ErrorComponent ? (
            <Box flex="1 0 100%">
              <ErrorComponent name={name} />
            </Box>
          ) : null}
        </>
      ) : (
        <>
          {displayErrorMessage && ErrorComponent ? <ErrorComponent name={name} /> : null}
          {children}
        </>
      )}
      {extra && extra}
    </Fieldset>
  );
}

// Assigning the inner components as properties of RadioCard
RadioCard.IllustrationContainer = IllustrationContainer;
RadioCard.Decorator = Decorator;
RadioCard.ContentWrapper = ContentWrapper;
RadioCard.RadioStateInCard = RadioStateInCard;
RadioCard.Description = Description;
RadioCard.Wrapper = Wrapper;
RadioCard.Input = Input;
