import { Badge, Box } from '@remote-com/norma';
import styled from 'styled-components';

import type { ReportsApi } from '@/src/api/config/employ/reports.types';
import type { Amount } from '@/src/api/config/employ/shared.types';
import { DateCellText } from '@/src/domains/reports/components/ReportTable/ReportTable.styled';
import { formatTimeTaken } from '@/src/domains/reports/helpers';
import {
  friendlyMoneyWithCurrencySymbol,
  friendlyMoneyWithoutCurrency,
} from '@/src/helpers/currency';

// todo: replace this with MoneyCell from @remote-com/norma when we return the currency from API
export const AlignRight = styled(Box)`
  text-align: right;
`;

export const MoneyCell = ({ value }: { value: Amount }) =>
  value && (
    <AlignRight>
      {friendlyMoneyWithCurrencySymbol(value.convertedAmount, value.convertedCurrency)}
    </AlignRight>
  );

export const MoneyWithoutCurrencyCell = ({ value }: { value: number }) => (
  <AlignRight>{friendlyMoneyWithoutCurrency(value)}</AlignRight>
);

export const exportMoneyValue = (value: string) => value || '0.00';

export const exportFriendlyMoneyWithoutCurrency = (amount: Amount) => {
  if (!amount.convertedAmount) return '0.00';

  return friendlyMoneyWithoutCurrency(amount.convertedAmount, {
    impliedCurrencyCode: amount.convertedCurrency?.code,
  });
};

export const StatusCell = ({
  value,
  row,
}: {
  value: string;
  row: { original: ReportsApi.ReportData };
}) => {
  const pendingStatusNode = row?.original?.nodes?.statuses.find(
    (status) => status.nodeStatus === 'pending' && status.count > 0
  );

  return (
    <DateCellText>
      <Badge
        label={value}
        labelExtra={pendingStatusNode?.message ?? ''}
        type={pendingStatusNode ? 'pending' : 'active'}
      />
    </DateCellText>
  );
};

export const TimeTakenCell = ({ value }: { value: { daysTaken: number; hoursTaken: number } }) => {
  return formatTimeTaken(value.daysTaken, value.hoursTaken);
};
