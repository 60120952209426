import { badgeTypesMapper, Skeleton, Stack } from '@remote-com/norma';

import { ButtonInline } from '@/src/components/Button';
import { DateCell, EmptyCell, MonthYearFilter, StatusCell } from '@/src/components/Table';
import { employmentType } from '@/src/domains/employment/constants';
import { lastBillableActivityColumnId } from '@/src/domains/paidContractorProduct/constants';
import {
  isContractorSubscriptionFreeTrialActive,
  isCurrentBillingPeriod,
} from '@/src/domains/paidContractorProduct/employer/helpers';
import { useFetchEmployerContractorSubscriptions } from '@/src/domains/paidContractorProduct/hooks';
import { Resources } from '@/src/domains/registration/auth/constants/permissions';
import { isEmployer, userCan } from '@/src/domains/registration/auth/helpers';
import { friendlyYearMonth } from '@/src/helpers/date';

function FairPricingLearnMore() {
  return (
    <ButtonInline
      href="https://support.remote.com/hc/en-us/sections/10170986813197-Contractor-Subscription-Billing"
      target="_blank"
      rel="noopener noreferrer"
      // Stop propagation to prevent clicking on "Learn more" leading current tab
      // to the contractor profile.
      onClick={(e) => e.stopPropagation()}
    >
      Learn more
    </ButtonInline>
  );
}

export function BillableActivityTooltipContent({
  contractorName,
  performedInCurrentMonth,
  userIsEmployer,
  currentDate = new Date(),
}) {
  const { data: contractorSubscriptions, isLoading } = useFetchEmployerContractorSubscriptions({
    options: {
      enabled: userIsEmployer,
    },
  });

  if (isLoading) {
    return (
      <Stack gap={3} mx={3} alignItems="center">
        <Skeleton variant="rect" width={190} />
        <Skeleton variant="rect" width={160} />
      </Stack>
    );
  }

  if (performedInCurrentMonth) {
    if (contractorSubscriptions.some(isContractorSubscriptionFreeTrialActive)) {
      return <>Activities performed while the trial period is active are free.</>;
    }

    const currentBillingPeriod = friendlyYearMonth(currentDate);
    return (
      <>
        {contractorName} performed activity in the current billing period. They will be included in
        your contractor subscription bill for {currentBillingPeriod}. <FairPricingLearnMore />
      </>
    );
  }

  return (
    <>
      {contractorName} didn’t perform activity in the current billing period. They won’t be included
      in your next contractor subscription bill. <FairPricingLearnMore />
    </>
  );
}

// Used both from employer and Remote admin PoVs.
export const getLastBillableActivityColumn = (user) => ({
  Header: 'Last billable activity',
  id: lastBillableActivityColumnId,
  accessor: (employment) => employment,
  exportData: (employment) => employment.latestBillableContractorActivity?.performedAt ?? '-',
  Filter: function BillableActivityPeriodFilter(props) {
    return <MonthYearFilter {...props} label="Billable in" maxDate={new Date()} />;
  },
  Cell: function BillableActivityCell({ value: employment }) {
    const contractorName = employment.name;
    const performedAt = employment.latestBillableContractorActivity?.performedAt;
    const performedInCurrentMonth = isCurrentBillingPeriod(performedAt);

    if (employment.type !== employmentType.CONTRACTOR) {
      return <EmptyCell />;
    }

    return (
      <StatusCell
        value={{
          type: performedInCurrentMonth ? badgeTypesMapper.ACTIVE : badgeTypesMapper.INACTIVE,
          label: <DateCell value={performedAt} />,
          labelExtra: (
            <BillableActivityTooltipContent
              contractorName={contractorName}
              performedInCurrentMonth={performedInCurrentMonth}
              userIsEmployer={isEmployer(user)}
            />
          ),
        }}
      />
    );
  },
  hiddenColumn: !userCan('read', Resources.employer.billable_contractor_activities, user),
});
