import { Stack, Text, Box } from '@remote-com/norma';
import styled from 'styled-components';

import { Modal, Header as ModalHeader, Body as ModalBody } from '@/src/components/Modal';

export const ModalStyled = styled(Modal)`
  --modal-outer-spacing: 24px;
  --icon-size: 16px;
  --gap-size: 12px;

  ${ModalHeader} {
    display: none;
  }

  ${ModalBody} {
    height: auto;
    backdrop-filter: blur(4px);
    background: ${({ theme }) => theme.colors.blank};
    border-radius: var(--modalRadius);
    color: hsla(211, 34%, 75%, 1);
    overflow: hidden;
    padding: 0;

    @media not screen and (pointer: fine) and (hover: hover) {
      margin: ${({ theme }) => theme.space[4]}px;
    }
  }
`;

export const Body = styled(Stack).attrs({
  direction: 'column',
})`
  flex-direction: column;
  height: auto;
  overflow: auto;
`;
export const ErrorMessage = styled(Text).attrs({
  color: 'bayoux',
  variant: 'base',
  py: 5,
})`
  display: grid;
  place-items: center;
`;

export const Wrapper = styled(Box)`
  position: relative;
  margin-bottom: -${({ theme }) => theme.space[2]}px;
`;

export const Icon = styled.svg`
  left: var(--modal-outer-spacing);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: var(--icon-size);
  fill: ${({ theme }) => theme.colors.bayoux};
`;

export const Input = styled.input`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.grey[900]};
  font-size: 18px;
  height: 64px;
  padding: 0 var(--modal-outer-spacing) 0
    calc(var(--modal-outer-spacing) + var(--icon-size) + var(--gap-size));
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.lynch};
  }

  &:focus {
    outline: 0;
  }
`;
