import { usePost } from '@remote-com/data-layer';
import {
  Box,
  FeedbackMessage,
  FEEDBACK_MESSAGE_ERROR,
  Stack,
  Text,
  toast,
} from '@remote-com/norma';
import { Formik } from 'formik';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { object } from 'yup';

import { Modal } from '@/src/components/Modal';
import { InputPassword } from '@/src/components/Ui/Form';
import { Verify2FACodeModal } from '@/src/domains/account/Verify2FACodeModal';
import { TWO_FACTOR_AUTHENTICATION_ENABLED } from '@/src/domains/registration/auth/constants';
import { requiredPasswordSchema } from '@/src/helpers/validationSchema';
import { useMutationWithCustomState } from '@/src/hooks/useMutationWithCustomState';

import { ReCAPTCHA, useReCAPTCHA } from './ReCAPTCHA';

type ProfileAuthModalProps = {
  visible: boolean;
  email: string;
  onSuccessAuth: () => void;
  onClose: () => void;
};

const validationSchema = object().shape({
  password: requiredPasswordSchema,
});

export function ProfileAuthModal({
  visible,
  onSuccessAuth,
  email,
  onClose,
}: ProfileAuthModalProps) {
  const { mutate, isLoading } = useMutationWithCustomState(usePost('/api/v1/session'));
  const [errorMessage, setErrorMessage] = useState('');
  const { executeRecaptcha } = useReCAPTCHA();
  const [verify2FACodeModalVisibility, setVerify2FACodeModalVisibility] = useState(false);
  const [savedPassword, setSavedPassword] = useState('');
  async function onHandleSubmit(data: { totp?: number; password?: string }) {
    setErrorMessage('');
    const token = await executeRecaptcha({ action: 'submit', twofactor: undefined });
    const payload = {
      bodyParams: {
        user: {
          email,
          password: savedPassword,
          ...(data?.totp && { totp: data?.totp }),
        },
        recaptchaToken: token,
      },
    };
    mutate(
      {
        ...payload,
      },
      {
        onSuccess: () => {
          onSuccessAuth();
        },
        onError: (error: any) => {
          const errorData = error?.response;

          const hasTwoFactorAuthentication =
            errorData.status === 401 && errorData?.data?.code === TWO_FACTOR_AUTHENTICATION_ENABLED;
          if (hasTwoFactorAuthentication) {
            setVerify2FACodeModalVisibility(true);
          } else {
            setErrorMessage(error?.response?.data?.message);
          }
        },
      }
    );
  }

  if (verify2FACodeModalVisibility) {
    return (
      <Verify2FACodeModal
        enable2FAReset
        handleSubmit={({ totp }: { totp: number }) => onHandleSubmit({ totp })}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        isLoading={isLoading}
        onClose={() => {
          setVerify2FACodeModalVisibility(false);
          toast.error({
            title: 'Profile switch has been cancelled',
          });
          onClose();
        }}
      />
    );
  }

  return (
    <Modal
      visible={visible}
      headerTitle="Switch profiles"
      formName="auth-email-password"
      saveButtonText="Continue"
      onDismiss={() => {
        setErrorMessage('');
        onClose();
      }}
      confirmLoading={isLoading}
    >
      <Text variant="baseMedium">Enter your password</Text>
      <Text variant="sm" mb={7}>
        For security, we need to verify your identity before switching profiles.
      </Text>
      <Formik
        initialValues={{
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onHandleSubmit}
      >
        {({ handleSubmit, touched, errors, values, handleChange }) => {
          return (
            <Stack gap={3}>
              {errorMessage && (
                <Box mt={6}>
                  <FeedbackMessage variant={FEEDBACK_MESSAGE_ERROR}>
                    <Box dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  </FeedbackMessage>
                </Box>
              )}
              <ReCAPTCHA />
              <form onSubmit={handleSubmit} noValidate id="auth-email-password">
                <InputPassword
                  id="auth-email-password"
                  name="password"
                  label="Password"
                  value={values.password}
                  autoComplete="password"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleChange(event);
                    setSavedPassword(event.target.value);
                  }}
                  errorText={touched.password && errors.password ? errors.password : ''}
                  required
                />
              </form>
            </Stack>
          );
        }}
      </Formik>
    </Modal>
  );
}
