import PropTypes from 'prop-types';

import type { ContractorSubscription } from '@/src/api/config/employ/contractorSubscription.types';
import { PRODUCT_PRICING_FREQUENCY } from '@/src/domains/pricing/constants';
import { isProductContractorPlus, isProductContractorProtect } from '@/src/domains/pricing/helpers';
import { convertUtcStringToDateObj, formatFullMonth } from '@/src/helpers/date';

// Extracting prop type so that can be reused. Also, if we decide to move to TS, extracted types can help in the transition
export const contractorSubscriptionPropType = PropTypes.shape({
  currency: PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
  }),
  nextInvoiceDate: PropTypes.string,
  price: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      symbol: PropTypes.string.isRequired,
    }),
  }).isRequired,
  product: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    employmentType: PropTypes.string.isRequired,
    frequency: PropTypes.oneOf([
      PRODUCT_PRICING_FREQUENCY.ANNUAL,
      PRODUCT_PRICING_FREQUENCY.MONTHLY,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  companyProductDiscount: PropTypes.shape({
    percent: PropTypes.number,
  }),
  summaryPeriod: PropTypes.string,
  totalAmount: PropTypes.number.isRequired,
  activeContractorsCount: PropTypes.number,
  billableContractorsCount: PropTypes.number,
  billableContractors: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.shape({
        name: PropTypes.string.isRequired,
        photo: PropTypes.string,
        slug: PropTypes.string.isRequired,
      }),
    })
  ),
});

// The passed `summaryPeriod` is in `MM-yyyy` format.
export function formatSubscriptionSummaryPeriod(summaryPeriod: string) {
  return formatFullMonth(convertUtcStringToDateObj(summaryPeriod));
}

// The passed `summaryPeriod` is in `MM-yyyy` format. Prorate period is the previous month of the summary period.
export function formatSubscriptionSummaryProratedPeriod(summaryPeriod: string) {
  const previousMonth = new Date(summaryPeriod);
  previousMonth.setMonth(previousMonth.getMonth() - 1);

  return formatFullMonth(convertUtcStringToDateObj(`${previousMonth}`));
}

function getContractorSubscriptionOrderPosition(subscription: ContractorSubscription) {
  if (isProductContractorProtect(subscription.product)) {
    return 2;
  }
  if (isProductContractorPlus(subscription.product)) {
    return 1;
  }
  return 0;
}

export function sortContractorSubscriptions(subscriptions: ContractorSubscription[]) {
  return subscriptions.slice().sort((a, b) => {
    const subscriptionAPosition = getContractorSubscriptionOrderPosition(a);
    const subscriptionBPosition = getContractorSubscriptionOrderPosition(b);

    return subscriptionAPosition - subscriptionBPosition;
  });
}
