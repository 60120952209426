import type { PillToneV2 } from '@remote-com/norma';

import type { Status as TimesheetStatus } from '@/src/api/config/employ/timesheet.types';
import type {
  TimeTrackingHourType,
  TimeTrackingSystemType,
  TimeTrackingType,
} from '@/src/api/config/employ/timeTracking.types';
import { generateSelectOptions } from '@/src/helpers/forms';

export const TIMETRACKING_TYPE = {
  REGULAR_HOURS: 'regular_hours',
  OVERTIME: 'overtime',
  BREAK: 'break',
  ON_CALL: 'on_call',
  UNPAID_BREAK: 'unpaid_break',
} as const satisfies Record<string, TimeTrackingType>;

export const TIMETRACKING_TYPE_LABELS: Record<TimeTrackingType, string> = {
  [TIMETRACKING_TYPE.REGULAR_HOURS]: 'Regular hours',
  [TIMETRACKING_TYPE.OVERTIME]: 'Overtime',
  [TIMETRACKING_TYPE.BREAK]: 'Break',
  [TIMETRACKING_TYPE.UNPAID_BREAK]: 'Break (unpaid)',
  [TIMETRACKING_TYPE.ON_CALL]: 'On-call',
};

export const TIMETRACKING_TYPE_CONTRACTOR_LABELS: Partial<Record<TimeTrackingType, string>> = {
  [TIMETRACKING_TYPE.REGULAR_HOURS]: 'Regular hours',
  [TIMETRACKING_TYPE.BREAK]: 'Break',
  [TIMETRACKING_TYPE.ON_CALL]: 'On-call',
};

export const TIMETRACKING_TYPE_LABELS_USER_SETTINGS = {
  [TIMETRACKING_TYPE.REGULAR_HOURS]: 'Regular hours',
  [TIMETRACKING_TYPE.BREAK]: 'Break',
};

export const TIMETRACKING_HOURS_REQUIRING_APPROVAL_TYPES: Record<TimeTrackingHourType, string> = {
  overtime_hours: 'Overtime',
  on_call_hours: 'On-call',
  night_hours: 'Night Hours',
  weekend_hours: 'Weekend Hours',
  holiday_hours: 'Holiday Hours',
};

export const SYSTEM_TIMETRACKING_TYPE = {
  TIME_OFF: 'time_off',
  PUBLIC_HOLIDAY: 'public_holiday',
} satisfies Record<Uppercase<TimeTrackingSystemType>, TimeTrackingSystemType>;

export const TIMESHEET_STATUS = {
  OPEN: 'open',
  SUBMITTED: 'submitted',
  APPROVED: 'approved',
  IN_CALIBRATION: 'in_calibration',
  PROCESSED: 'processed',
} as const satisfies Record<string, TimesheetStatus>;

export const TIMESHEET_STATUS_LABELS: Record<TimesheetStatus, string> = {
  [TIMESHEET_STATUS.OPEN]: 'Open',
  [TIMESHEET_STATUS.SUBMITTED]: 'Submitted',
  [TIMESHEET_STATUS.APPROVED]: 'Approved',
  [TIMESHEET_STATUS.IN_CALIBRATION]: 'Reopened',
  [TIMESHEET_STATUS.PROCESSED]: 'Processed',
};

export const TIMESHEET_STATUS_OPTIONS = generateSelectOptions(TIMESHEET_STATUS_LABELS);

export const TIMESHEET_STATUS_BADGES = {
  [TIMESHEET_STATUS.OPEN]: 'pending',
  [TIMESHEET_STATUS.SUBMITTED]: 'info',
  [TIMESHEET_STATUS.APPROVED]: 'active',
  [TIMESHEET_STATUS.IN_CALIBRATION]: 'pending',
  [TIMESHEET_STATUS.PROCESSED]: 'active',
};

export const MISSING_TIMESHEET = {
  label: 'Unsubmitted',
  badge: 'error',
};

export const TIMESHEET_STATUS_PILL_TONE = {
  [TIMESHEET_STATUS.SUBMITTED]: 'neutralLight',
  [TIMESHEET_STATUS.APPROVED]: 'success',
  [TIMESHEET_STATUS.IN_CALIBRATION]: 'warning',
  [TIMESHEET_STATUS.PROCESSED]: 'success',
};

export const SENT_BACK = {
  label: 'Changes requested',
  badge: 'pending',
};

export const EMPTY_TIME_TRACKING_ENTRY = {
  clockIn: '',
  clockOut: '',
  duration: 0,
  notes: '',
  type: TIMETRACKING_TYPE.REGULAR_HOURS,
  hasNightHours: false,
  hasWeekendHours: false,
  hasHolidayHours: false,
  project: null,
};

export const DEFAULT_TIME_TRACKING_ENTRY = {
  ...EMPTY_TIME_TRACKING_ENTRY,
  clockIn: '09:00',
  clockOut: '17:00',
  duration: 480,
};

export const ACTION_TYPES = {
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  RESET_TO_DEFAULTS: 'RESET_TO_DEFAULTS',
  ADD_HOURS: 'ADD_HOURS',
  BUILD_DAYS: 'BUILD_DAYS',
  DELETE_ROW: 'DELETE_ROW',
  COPY_ROW: 'COPY_ROW',
  RESTORE_DRAFT: 'RESTORE_DRAFT',
  APPLY_PREFERENCES: 'APPLY_PREFRENCES',
  RESET_TO_INITIAL_STATE: 'RESET_TO_INITIAL_STATE',
};

export const TIME_TRACKING_CALCULATION_MODES = {
  NEGATIVE_DEFAULTS_TO_ZERO: 'NEGATIVE_DEFAULTS_TO_ZERO',
  ALLOW_CLOCK_OUT_NEXT_DAY: 'ALLOW_CLOCK_OUT_NEXT_DAY',
};

export const PROJECT_PILL_TONES = [
  'pink',
  'error',
  'warning',
  'success',
  'neutralDark',
  'purple',
  'blueLight',
  'fuchsia',
  'blue',
] satisfies PillToneV2[];
