import type { BoxedIconTone } from '@remote-com/norma';
import { IconV2DuotoneBank } from '@remote-com/norma/icons/IconV2DuotoneBank';
import { IconV2DuotoneBankHouse } from '@remote-com/norma/icons/IconV2DuotoneBankHouse';
import { IconV2DuotoneBuilding } from '@remote-com/norma/icons/IconV2DuotoneBuilding';
import { IconV2DuotoneCalendarDates } from '@remote-com/norma/icons/IconV2DuotoneCalendarDates';
import { IconV2DuotoneCreditCard } from '@remote-com/norma/icons/IconV2DuotoneCreditCard';
import { IconV2DuotoneDollarCircle } from '@remote-com/norma/icons/IconV2DuotoneDollarCircle';
import { IconV2DuotoneFileCheck } from '@remote-com/norma/icons/IconV2DuotoneFileCheck';
import { IconV2DuotoneFileInvoice } from '@remote-com/norma/icons/IconV2DuotoneFileInvoice';
import { IconV2DuotoneFilePlus } from '@remote-com/norma/icons/IconV2DuotoneFilePlus';
import { IconV2DuotoneFileText } from '@remote-com/norma/icons/IconV2DuotoneFileText';
import { IconV2DuotoneUser } from '@remote-com/norma/icons/IconV2DuotoneUser';
import { IconV2DuotoneUserCheck } from '@remote-com/norma/icons/IconV2DuotoneUserCheck';
import { IconV2DuotoneUserPlus } from '@remote-com/norma/icons/IconV2DuotoneUserPlus';
import { IconV2DuotoneUsers } from '@remote-com/norma/icons/IconV2DuotoneUsers';
import type { ElementType } from 'react';
import type { ValueOf } from 'type-fest';

import {
  ADMIN_COMPANIES_PROFILE_ROUTE,
  ADMIN_CREATE_USER_ROUTE,
  ADMIN_LEGAL_ENTITIES_ADD_PENSION_ROUTE,
  ADMIN_LEGAL_ENTITIES_ADD_SIGNATORY_ROUTE,
  ADMIN_LEGAL_ENTITIES_EMPLOYEE_UPLOAD_DOCUMENTS_ROUTE,
  ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE,
  ADMIN_LEGAL_ENTITIES_SETUP_PAY_SCHEDULE_ROUTE,
  ADMIN_LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE,
  ADMIN_ONBOARDING_ROUTE,
  HIRING_ROUTE,
  KYB_ONBOARDING_ROUTE,
  LEGAL_ENTITIES_ADD_PENSION_ROUTE,
  LEGAL_ENTITIES_ADD_SIGNATORY_ROUTE,
  LEGAL_ENTITIES_EMPLOYEE_UPLOAD_DOCUMENTS_ROUTE,
  LEGAL_ENTITIES_PROFILE_ROUTE,
  LEGAL_ENTITIES_SETUP_PAY_SCHEDULE_ROUTE,
  LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE,
  PAYMENT_METHODS_ROUTE,
  PAYROLL_SETTINGS_ROUTE,
  TEAM_ROUTE,
} from '@/src/constants/routes';

import { openVerifyDepositModal } from './onboardingTasklistAction';

export const allowedContractType = {
  MSA: 'msa',
  EMPLOYMENT_AGREEMENT: 'employment_agreement',
  CONTRACTOR_AGREEMENT: 'contractor_agreement',
} as const;

export const allowedContractTypeLabels = {
  [allowedContractType.MSA]: 'MSA',
  [allowedContractType.EMPLOYMENT_AGREEMENT]: 'Employment Agreement',
  [allowedContractType.CONTRACTOR_AGREEMENT]: 'Contractor Agreement',
};

export const legalEntityPayrollStatus = {
  ACTIVE: 'active',
  TESTING: 'testing',
  VERIFYING: 'verifying',
  INACTIVE: 'inactive',
} as const;

export const legalEntityPayrollStatusLabels = {
  [legalEntityPayrollStatus.ACTIVE]: 'Active',
  [legalEntityPayrollStatus.TESTING]: 'Setting up',
  [legalEntityPayrollStatus.VERIFYING]: 'Final checking',
  [legalEntityPayrollStatus.INACTIVE]: 'Inactive',
};

export const legalEntityStatus = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
} as const;

export const legalEntityStatusLabels = {
  [legalEntityStatus.ACTIVE]: 'Active',
  [legalEntityStatus.ARCHIVED]: 'Archived',
};

export type AllowedContractType = ValueOf<typeof allowedContractType>;
export type PayrollStatusType = ValueOf<typeof legalEntityPayrollStatus>;
export type StatusType = ValueOf<typeof legalEntityStatus>;

export const legalEntityOnboardingTasks = {
  ADD_BASIC_INFO: 'add_basic_info',
  ADD_ADMINISTRATIVE_DETAILS: 'add_administrative_details',
  ADD_BANK_INFO: 'add_bank_info',
  ADD_EMPLOYEES: 'add_employees',
  SETUP_PAY_SCHEDULE: 'setup_pay_schedule',
  SETUP_STATE_TAX_DETAILS: 'setup_state_tax_details',
  VERIFY_BANK_INFO: 'verify_bank_info',
  ADD_SIGNATORY: 'add_signatory',
  SIGN_ALL_FORMS: 'sign_all_forms',
  SETUP_REMOTE_PAYMENTS: 'setup_remote_payments',
  SETUP_STATUTORY_PAYMENTS: 'setup_statutory_payments',
  UPLOAD_LEGAL_ENTITY_DOCUMENTS: 'upload_legal_entity_documents',
  UPLOAD_EMPLOYEE_DOCUMENTS: 'upload_employee_documents',
  ADD_PENSION_SCHEME: 'add_pension_scheme',
} as const;

export type LegalEntityOnboardingTasks = ValueOf<typeof legalEntityOnboardingTasks>;
export type LegalEntityOnboardingTasksKeys = keyof typeof legalEntityOnboardingTasks;
export type GustoOnboardingTasks = Exclude<
  LegalEntityOnboardingTasks,
  | 'setup_remote_payments'
  | 'setup_statutory_payments'
  | 'upload_legal_entity_documents'
  | 'upload_employee_documents'
>;
export type GustoOnboardingTasksKeys = Exclude<
  keyof typeof legalEntityOnboardingTasks,
  | 'SETUP_REMOTE_PAYMENTS'
  | 'SETUP_STATUTORY_PAYMENTS'
  | 'UPLOAD_LEGAL_ENTITY_DOCUMENTS'
  | 'UPLOAD_EMPLOYEE_DOCUMENTS'
>;

export const legalEntityOnboardingTaskGroups = {
  SETUP_ENTITY: 'setup_entity',
  SETUP_EMPLOYEES: 'setup_employees',
  SETUP_PAYMENTS: 'setup_payments',
} as const;

export const legalEntityOnboardingTaskGroupIcons = {
  [legalEntityOnboardingTaskGroups.SETUP_ENTITY]: IconV2DuotoneBuilding,
  [legalEntityOnboardingTaskGroups.SETUP_EMPLOYEES]: IconV2DuotoneUsers,
  [legalEntityOnboardingTaskGroups.SETUP_PAYMENTS]: IconV2DuotoneDollarCircle,
};

export const legalEntityOnboardingTaskGroupTitles = {
  [legalEntityOnboardingTaskGroups.SETUP_ENTITY]: 'Set up entity',
  [legalEntityOnboardingTaskGroups.SETUP_EMPLOYEES]: 'Set up employees',
  [legalEntityOnboardingTaskGroups.SETUP_PAYMENTS]: 'Set up payments',
};

export type OnboardingTaskConfig = {
  title: string;
  description?: string;
  route?: string;
  routeCompleted?: string;
  icon?: ElementType;
  iconTone?: BoxedIconTone;
  tooltipText?: string;
  action?: (props?: any) => void;
  editInModal?: boolean;
};

export type OnboardingTasksConfigMap = {
  [K in LegalEntityOnboardingTasks]?: OnboardingTaskConfig;
};

export const onboardingTasksTitle = {
  [legalEntityOnboardingTasks.ADD_BASIC_INFO]: 'Add basic information',
  [legalEntityOnboardingTasks.ADD_ADMINISTRATIVE_DETAILS]: 'Add administrative information',
  [legalEntityOnboardingTasks.ADD_BANK_INFO]: 'Add payment information',
  [legalEntityOnboardingTasks.ADD_EMPLOYEES]: 'Add employees to entity',
  [legalEntityOnboardingTasks.SETUP_PAY_SCHEDULE]: 'Set up pay schedule',
  [legalEntityOnboardingTasks.SETUP_STATE_TAX_DETAILS]: 'Add State tax information',
  [legalEntityOnboardingTasks.VERIFY_BANK_INFO]: 'Verify bank account',
  [legalEntityOnboardingTasks.ADD_SIGNATORY]: 'Add signatory',
  [legalEntityOnboardingTasks.SIGN_ALL_FORMS]: 'Sign documents',
  [legalEntityOnboardingTasks.SETUP_REMOTE_PAYMENTS]: 'Set up remote payments',
  [legalEntityOnboardingTasks.SETUP_STATUTORY_PAYMENTS]: 'Enable statutory payments',
  [legalEntityOnboardingTasks.UPLOAD_LEGAL_ENTITY_DOCUMENTS]: 'Upload entity documents',
  [legalEntityOnboardingTasks.UPLOAD_EMPLOYEE_DOCUMENTS]: 'Upload employee documents',
  [legalEntityOnboardingTasks.ADD_PENSION_SCHEME]: 'Add pension scheme',
} as const;

export const onboardingTasksDescription = {
  [legalEntityOnboardingTasks.ADD_EMPLOYEES]: 'To help us know who you want to pay',
  [legalEntityOnboardingTasks.SETUP_PAY_SCHEDULE]: 'To let us know when to pay your employees',
  [legalEntityOnboardingTasks.SETUP_STATE_TAX_DETAILS]:
    'To ensure state specific tax obligations are met',
  [legalEntityOnboardingTasks.VERIFY_BANK_INFO]: 'To make payments to your employees securely',
  [legalEntityOnboardingTasks.ADD_SIGNATORY]: 'To ensure that your documents can be signed',
  [legalEntityOnboardingTasks.SIGN_ALL_FORMS]: 'Sign documents for authorization and tax purposes',
  [legalEntityOnboardingTasks.SETUP_REMOTE_PAYMENTS]:
    'So that you can make payments to your employees securely',
  [legalEntityOnboardingTasks.SETUP_STATUTORY_PAYMENTS]:
    'Confirm you want Remote to process statutory payments on your behalf',
  [legalEntityOnboardingTasks.UPLOAD_LEGAL_ENTITY_DOCUMENTS]:
    'Provide company policies and legal documents to inform payroll',
  [legalEntityOnboardingTasks.UPLOAD_EMPLOYEE_DOCUMENTS]:
    'Provide employee documents needed for payroll calculations',
  [legalEntityOnboardingTasks.ADD_PENSION_SCHEME]:
    'The retirement savings plans you provide for your employees.',
} as const;

export const onboardingTasksConfig: OnboardingTasksConfigMap = {
  [legalEntityOnboardingTasks.ADD_BASIC_INFO]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.ADD_BASIC_INFO],
    route: undefined,
    iconTone: 'blueLight',
    icon: IconV2DuotoneUserCheck,
    editInModal: true,
  },
  [legalEntityOnboardingTasks.ADD_ADMINISTRATIVE_DETAILS]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.ADD_ADMINISTRATIVE_DETAILS],
    route: undefined,
    iconTone: 'greenLight',
    icon: IconV2DuotoneFileCheck,
    editInModal: true,
  },
  [legalEntityOnboardingTasks.ADD_BANK_INFO]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.ADD_BANK_INFO],
    route: undefined,
    iconTone: 'fuchsia',
    icon: IconV2DuotoneCreditCard,
    editInModal: true,
  },
  [legalEntityOnboardingTasks.ADD_EMPLOYEES]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.ADD_EMPLOYEES],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.ADD_EMPLOYEES],
    route: `${TEAM_ROUTE}/new/full_time`,
    routeCompleted: HIRING_ROUTE,
    icon: IconV2DuotoneUserPlus,
    iconTone: 'pink',
  },
  [legalEntityOnboardingTasks.ADD_PENSION_SCHEME]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.ADD_PENSION_SCHEME],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.ADD_PENSION_SCHEME],
    route: LEGAL_ENTITIES_ADD_PENSION_ROUTE,
    routeCompleted: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
    icon: IconV2DuotoneFileText,
    iconTone: 'yellow',
  },
  [legalEntityOnboardingTasks.SETUP_PAY_SCHEDULE]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.SETUP_PAY_SCHEDULE],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.SETUP_PAY_SCHEDULE],
    route: LEGAL_ENTITIES_SETUP_PAY_SCHEDULE_ROUTE,
    routeCompleted: `${PAYROLL_SETTINGS_ROUTE}?legalEntitySlug=[slug]`,
    icon: IconV2DuotoneCalendarDates,
    iconTone: 'yellow',
  },
  [legalEntityOnboardingTasks.SETUP_STATE_TAX_DETAILS]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.SETUP_STATE_TAX_DETAILS],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.SETUP_STATE_TAX_DETAILS],
    route: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll&scroll=true`,
    routeCompleted: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll&scroll=true`,
    icon: IconV2DuotoneFileInvoice,
    iconTone: 'cyan',
    tooltipText: 'State taxes can only be setup once employees have been added.',
  },
  [legalEntityOnboardingTasks.VERIFY_BANK_INFO]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.VERIFY_BANK_INFO],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.VERIFY_BANK_INFO],
    route: PAYMENT_METHODS_ROUTE,
    routeCompleted: PAYMENT_METHODS_ROUTE,
    icon: IconV2DuotoneBank,
    iconTone: 'purple',
    action: openVerifyDepositModal,
  },
  [legalEntityOnboardingTasks.ADD_SIGNATORY]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.ADD_SIGNATORY],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.ADD_SIGNATORY],
    route: LEGAL_ENTITIES_ADD_SIGNATORY_ROUTE,
    routeCompleted: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
    icon: IconV2DuotoneUser,
    iconTone: 'blueLight',
  },
  [legalEntityOnboardingTasks.SIGN_ALL_FORMS]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.SIGN_ALL_FORMS],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.SIGN_ALL_FORMS],
    route: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
    routeCompleted: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
    icon: IconV2DuotoneFileText,
    iconTone: 'greenLight',
    tooltipText: 'Documents can only be signed after all other tasks are completed.',
  },
  [legalEntityOnboardingTasks.SETUP_REMOTE_PAYMENTS]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.SETUP_REMOTE_PAYMENTS],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.SETUP_REMOTE_PAYMENTS],
    route: KYB_ONBOARDING_ROUTE.replace('[entityType]', 'legal').replace('[entitySlug]', '[slug]'),
    routeCompleted: LEGAL_ENTITIES_PROFILE_ROUTE,
    icon: IconV2DuotoneBank,
    iconTone: 'blueLight',
  },
  [legalEntityOnboardingTasks.SETUP_STATUTORY_PAYMENTS]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.SETUP_STATUTORY_PAYMENTS],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.SETUP_STATUTORY_PAYMENTS],
    route: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=statutory-payments`,
    routeCompleted: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=statutory-payments`,
    icon: IconV2DuotoneBankHouse,
    iconTone: 'blueLight',
    tooltipText: 'You can enable statutory payments after Remote Payments setup is complete.',
  },
  [legalEntityOnboardingTasks.UPLOAD_LEGAL_ENTITY_DOCUMENTS]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.UPLOAD_LEGAL_ENTITY_DOCUMENTS],
    description:
      onboardingTasksDescription[legalEntityOnboardingTasks.UPLOAD_LEGAL_ENTITY_DOCUMENTS],
    route: LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE,
    routeCompleted: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=upload-documents`,
    icon: IconV2DuotoneFilePlus,
    iconTone: 'greenLight',
  },
  [legalEntityOnboardingTasks.UPLOAD_EMPLOYEE_DOCUMENTS]: {
    title: onboardingTasksTitle[legalEntityOnboardingTasks.UPLOAD_EMPLOYEE_DOCUMENTS],
    description: onboardingTasksDescription[legalEntityOnboardingTasks.UPLOAD_EMPLOYEE_DOCUMENTS],
    route: LEGAL_ENTITIES_EMPLOYEE_UPLOAD_DOCUMENTS_ROUTE,
    routeCompleted: `${LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=upload-documents`,
    icon: IconV2DuotoneFilePlus,
    iconTone: 'greenLight',
  },
} as const;

export const adminOnboardingRouteTasks = {
  ADD_BASIC_INFO: 'add_basic_info',
  ADD_ADMINISTRATIVE_DETAILS: 'add_administrative_details',
  ADD_BANK_INFO: 'add_bank_info',
  ADD_EMPLOYEES: 'add_employees',
  SETUP_PAY_SCHEDULE: 'setup_pay_schedule',
  SETUP_STATE_TAX_DETAILS: 'setup_state_tax_details',
  VERIFY_BANK_INFO: 'verify_bank_info',
  ADD_SIGNATORY: 'add_signatory',
  SIGN_ALL_FORMS: 'sign_all_forms',
  SETUP_REMOTE_PAYMENTS: 'setup_remote_payments',
  SETUP_STATUTORY_PAYMENTS: 'setup_statutory_payments',
  UPLOAD_LEGAL_ENTITY_DOCUMENTS: 'upload_legal_entity_documents',
  UPLOAD_EMPLOYEE_DOCUMENTS: 'upload_employee_documents',
  ADD_PENSION_SCHEME: 'add_pension_scheme',
} as const;

export type AdminLegalEntityOnboardingRouteTasks = ValueOf<typeof adminOnboardingRouteTasks>;

export type AdminOnboardingRouteConfig = {
  [K in AdminLegalEntityOnboardingRouteTasks]: {
    route?: string;
    routeCompleted?: string;
  };
};

export const adminOnboardingRouteConfig: AdminOnboardingRouteConfig = {
  [legalEntityOnboardingTasks.ADD_BASIC_INFO]: {
    route: undefined,
    routeCompleted: undefined,
  },
  [legalEntityOnboardingTasks.ADD_ADMINISTRATIVE_DETAILS]: {
    route: undefined,
    routeCompleted: undefined,
  },
  [legalEntityOnboardingTasks.ADD_BANK_INFO]: {
    route: undefined,
    routeCompleted: undefined,
  },
  [legalEntityOnboardingTasks.ADD_EMPLOYEES]: {
    route: ADMIN_CREATE_USER_ROUTE,
    routeCompleted: ADMIN_ONBOARDING_ROUTE,
  },
  [legalEntityOnboardingTasks.SETUP_PAY_SCHEDULE]: {
    route: ADMIN_LEGAL_ENTITIES_SETUP_PAY_SCHEDULE_ROUTE,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
  },
  [legalEntityOnboardingTasks.SETUP_STATE_TAX_DETAILS]: {
    route: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
  },
  [legalEntityOnboardingTasks.VERIFY_BANK_INFO]: {
    route: `${ADMIN_COMPANIES_PROFILE_ROUTE}?selectedTab=payment-methods`,
    routeCompleted: `${ADMIN_COMPANIES_PROFILE_ROUTE}?selectedTab=payment-methods`,
  },
  [legalEntityOnboardingTasks.ADD_SIGNATORY]: {
    route: ADMIN_LEGAL_ENTITIES_ADD_SIGNATORY_ROUTE,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
  },
  [legalEntityOnboardingTasks.SIGN_ALL_FORMS]: {
    route: ADMIN_LEGAL_ENTITIES_ADD_SIGNATORY_ROUTE,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
  },
  [legalEntityOnboardingTasks.SETUP_REMOTE_PAYMENTS]: {
    route: ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE,
    routeCompleted: ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE,
  },
  [legalEntityOnboardingTasks.SETUP_STATUTORY_PAYMENTS]: {
    route: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=statutory-payments`,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=statutory-payments`,
  },
  [legalEntityOnboardingTasks.UPLOAD_LEGAL_ENTITY_DOCUMENTS]: {
    route: ADMIN_LEGAL_ENTITIES_UPLOAD_DOCUMENTS_ROUTE,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=upload-documents`,
  },
  [legalEntityOnboardingTasks.UPLOAD_EMPLOYEE_DOCUMENTS]: {
    route: ADMIN_LEGAL_ENTITIES_EMPLOYEE_UPLOAD_DOCUMENTS_ROUTE,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=upload-documents`,
  },
  [legalEntityOnboardingTasks.ADD_PENSION_SCHEME]: {
    route: ADMIN_LEGAL_ENTITIES_ADD_PENSION_ROUTE,
    routeCompleted: `${ADMIN_LEGAL_ENTITIES_PROFILE_ROUTE}?selectedTab=payroll`,
  },
} as const;

export const remainingOnboardingTasksTooltipText =
  'There are still some outstanding tasks to complete.';

export const remainingOnboardingTasksBannerText =
  'There are still some outstanding tasks to complete before you start running your payroll.';

export const allTaskCompletedBannerText =
  'We’re conducting a final check for the entity setup, which may take up to 2 business days. We’ll notify you via email and on the Remote platform once it’s done.';

export const unableToAddEmployeesText =
  'There are a few tasks you need to complete before you can use this entity.';
export const entityIsBeingVerifiedText = 'Entity is being verified.';
export const entityIsNotCompliantText =
  'You cannot onboard employees to the legal entities listed below at this time';

export const documentStatus = {
  CREATED: 'created',
  COMPLETED: 'completed',
  PROCESSING: 'processing',
} as const;

export const documentStatusLabels = {
  [documentStatus.CREATED]: 'Incomplete',
  [documentStatus.COMPLETED]: 'Completed',
  [documentStatus.PROCESSING]: 'Processing',
} as const;

export const legalEntityOnboardingTaskStatus = {
  CREATED: 'created',
  COMPLETED: 'completed',
} as const;
